import { useFetchBasic } from "services/useFetch";

export function useCityByUf<Data = any, Error = any>(
  stateByUserSelected: string
) {
  const url: string = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${
    stateByUserSelected ? stateByUserSelected : "SP"
  }/municipios`;

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}
