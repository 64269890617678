import React from "react";
import {
  RiAccountCircleLine,
  RiBankCard2Line,
  RiCalendarCheckFill,
  RiCoupon2Line,
  RiExchangeDollarLine,
  RiShoppingCartLine,
} from "react-icons/ri";

import {
  Container,
  Section,
  CardQuestion,
  CardsContainer,
  IconContainer,
  CardQuestionHeader,
  CardQuestionBody,
} from "./styles";

interface Props {
  handleCards: (card: any) => void;
}

const GridCards: React.FC<Props> = ({ handleCards }) => {
  const cards = [
    {
      category: "4",
      description: "Solucione suas dúvidas em relação à ingressos de eventos.",
      title: "Ingressos",
      icon: <RiCoupon2Line />,
    },
    {
      category: "5",
      description:
        "Criou ou quer criar um evento? Solucione suas dúvidas sobre a plataforma aqui.",
      title: "Eventos",
      icon: <RiCalendarCheckFill />,
    },
    {
      category: "6",
      description:
        "Problemas de acesso à sua conta, troca de senha entre outros.",
      title: "Minha conta",
      icon: <RiAccountCircleLine />,
    },
    {
      category: "1",
      description:
        "Tire todas as suas dúvidas relacionadas à compras realizadas em nosso site ou app.",
      title: "Compras",
      icon: <RiShoppingCartLine />,
    },
    {
      category: "2",
      description:
        "Houve algum problema com o pagamento? Nós te ajudamos a solucionar.",
      title: "Pagamento",
      icon: <RiBankCard2Line />,
    },
    {
      category: "3",
      description:
        "Veja as principais dúvidas sobre reembolsos de compras realizadas na EventX.",
      title: "Reembolso",
      icon: <RiExchangeDollarLine />,
    },
  ];
  return (
    <Container className="first-container">
      <Section>
        <div className="content">
          <CardsContainer>
            {cards.map((card, index) => {
              return (
                <CardQuestion
                  key={index}
                  onClick={() => {
                    handleCards(card);
                  }}
                >
                  <CardQuestionHeader>
                    <IconContainer>{card.icon}</IconContainer>
                    <p className="card-title">{card.title}</p>
                  </CardQuestionHeader>
                  <CardQuestionBody>
                    <p>{card.description}</p>
                  </CardQuestionBody>
                </CardQuestion>
              );
            })}
          </CardsContainer>
        </div>
      </Section>
    </Container>
  );
};

export default GridCards;
