import { useState, useEffect } from "react";

import { Button, Divider, Input } from "antd";
import { PrimaryButton } from "components/Button";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { MdAdd, MdRemove } from "react-icons/md";
import { RiArrowDownSLine } from "react-icons/ri";

import {
  ActionButtonGroup,
  BottomCollapseContainer,
  DropdownMenu,
  MenuHeader,
  PurchaseMenu,
  TicketContainer,
} from "./styles";

import { limitNotification } from "components/Notification";

interface Props {
  cart: any;
  changeCart: any;
  quantityTotal: number;
  valueTotal: number;
  openLogin: any;
  openSteps: any;
  rate: number;
}

const BottomCollapse: React.FC<Props> = ({
  cart,
  changeCart,
  quantityTotal,
  valueTotal,
  openLogin,
  openSteps,
  rate,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const RowTickets = ({ item, index, rate }: any) => {
    function countMore() {
      if (item.user_quantity_max === item.quantity) {
        limitNotification(
          "Você atingiu a quantidade limite!",
          `O ingresso selecionado está limitado a quantidade de ${item.user_quantity_max} por venda, não é possível adicionar mais.`,
          4.5
        );

        return;
      }
      changeCart(item.ticket_id, item.quantity + 1);
    }
    function countLess() {
      changeCart(item.ticket_id, item.quantity > 0 ? item.quantity - 1 : 0);
    }

    return (
      <>
        <TicketContainer key={item?.id}>
          <div className="icon-container">
            <div className="name-and-price">
              <p>{item?.name}</p>
              <p>
                {formatter.format(item?.price)}
                {!item.absorb_rate ? (
                  <span style={{ color: "#999", fontSize: 12 }}>
                    {" "}
                    + taxa{" "}
                    {formatter.format(
                      ((item.discount != 0
                        ? item.price - item.discount
                        : item.price) /
                        100) *
                        rate
                    )}
                  </span>
                ) : null}
              </p>
            </div>
          </div>
          <ActionButtonGroup>
            <Input
              style={{ width: 60, height: 30 }}
              readOnly
              value={item.quantity + "x"}
            />
            <Button className="remove" onClick={countLess}>
              <MdRemove size={32} />
            </Button>
            <Button className="add" onClick={countMore}>
              <MdAdd size={32} />
            </Button>
          </ActionButtonGroup>
        </TicketContainer>
      </>
    );
  };

  const checkNextAction = () => {
    if (quantityTotal < 1) {
      alert("Seu carrinho está vazio!");
    } else {
      var user_token = localStorage.getItem("@ventX-user_token");

      if (user_token) {
        openSteps();
      } else {
        openLogin();
      }
    }
  };

  useEffect(() => {
    isVisible
      ? disableBodyScroll(document.body)
      : enableBodyScroll(document.body);
  }, [isVisible]);

  return (
    <>
      <BottomCollapseContainer>
        <div className="price-info">
          <p className="price">Total: {formatter.format(valueTotal)}</p>
          {quantityTotal > 0 ? (
            <p className="resume-purchase" onClick={() => setIsVisible(true)}>
              Resumo da compra
            </p>
          ) : null}
        </div>
        <PrimaryButton onClick={() => checkNextAction()}>
          Continuar
        </PrimaryButton>
      </BottomCollapseContainer>
      {isVisible && (
        <PurchaseMenu>
          <MenuHeader>
            <RiArrowDownSLine onClick={() => setIsVisible(false)} />
            <p>Seu pedido</p>
            <div style={{ width: "24px" }} />
          </MenuHeader>

          <DropdownMenu>
            <div>
              <p className="your-order">Seu pedido</p>
              <div className="tickets-container">
                {cart.map((item: any, index: number) => {
                  if (item.quantity > 0)
                    return (
                      <>
                        <RowTickets item={item} index={index} rate={rate} />
                        <Divider style={{ margin: "8px 0" }} />
                      </>
                    );
                })}
              </div>
            </div>
            <div className="bottom-info">
              <div className="subtotal">
                <p className="label">Subtotal</p>
                <p className="value">{formatter.format(valueTotal)}</p>
              </div>
              <Button
                className="confirm-button"
                onClick={() => checkNextAction()}
              >
                Confirmar compra
              </Button>
            </div>
          </DropdownMenu>
        </PurchaseMenu>
      )}
    </>
  );
};

export default BottomCollapse;
