import styled from "styled-components";
import { Modal as AntdModal } from "antd";
import { Colors } from "constants/colors";

import AppStore from "assets/selo_app_store.svg";
import GooglePlay from "assets/selo_google_play.svg";


export const StyledModal = styled(AntdModal)`
  // .ant-modal-mask class -> global style
  .ant-modal-close-x{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px;
  }
  .custom-close-icon{
    color: #11142D;
    opacity: .3;
    font-size: 32px;
  }
  .ant-modal-content {
    width: 100%;
    background-color: ${Colors.white};
    padding: 32px;
    border-radius: 20px;
    .ant-modal-close-x {
      color: ${Colors.black};
    }
    .ant-modal-header {
      .ant-modal-title {
        color: ${Colors.black};
        text-align: center;
        P {
          margin-top: 32px;
        }
      }
      background-color: ${Colors.white};
    }
    .ant-modal-body {
      padding: 30px 22px;
      text-align: center;
      color: ${Colors.black};
    }
    .ant-modal-footer {
      border-top: none;
      display: flex;
      justify-content: center;
      button {
        margin: 0 12px;
      }
    }
  }
  @media screen and (max-width: 450px){
    .ant-modal-content {
      padding: 32px 16px;
    }
  }
`;
export const Illustration = styled.img`
  width: 164px;
  height: 164px;
`;
export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  button {
  }
`;
export const DownloadSection = styled.div`
    position: relative;
    text-align: center;
    .download {
      color: ${Colors.black};
      filter: drop-shadow(0px 4px 10px hsla(0, 0%, 0%, 0.25));
      padding-left: 12px;
    }
    .container-download {
      display: flex;
      padding-left: 12px;
      justify-content: space-between;
      padding: 12px 24px;
    }
    .download-button {
      width: 26rem;
      height: 9rem;
      background-color: #fff;
    }
    .download-button.app-store {
      background: url(${AppStore});
      background-repeat: no-repeat;
      background-size: contain;
    }
    .download-button.google-play {
      background: url(${GooglePlay});
      background-repeat: no-repeat;
      background-size: contain;
    }
`;
