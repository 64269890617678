export const QuestionsList = [
  {
    question: "O que é a EventX ",
    answer:
      "EventX é uma plataforma completa que reúne as principais ferramentas e serviços para o organizador do evento e o usuário, tudo no mesmo ambiente, com um dashboard atualizado em real-time, simples, elegante e funcional, facilitando a venda e compra, e pensada para que qualquer pessoa possa criar, vender e comprar o seu evento. Não somos uma bilheteria online, somos um ecossistema global feito para eventos. ",
  },
  {
    question: "O que a EventX faz? ",
    answer:
      "As ferramentas do mercado são complexas, tem falta de informações e apresentam ambientes confusos, os organizadores precisam de ferramentas simples, flexíveis e que atendam a demanda do evento em apenas um lugar. ",
  },
  {
    question: "Quem é o parceiro da EventX?",
    answer: (
      <ul>
        O parceiro da EventX é o organizador que quer realmente proporcionar uma
        experiência única que gera verdadeiros resultados para os seus clientes:
        ele não se preocupa apenas em faturar, mas também em criar uma
        experiência através da conexão entre o seu cliente e o espetáculo. Quem
        busca a Eventx, em sua maioria, já é um parceiro expert com experiência
        e muitas vezes consolidado no mercado que busca ampliar a divulgação do
        seu evento e levar facilidade em um único ambiente, aumentar o seu
        engajamento pós-venda, melhorar a experiência e relacionamento com os
        seus clientes. O parceiro EventX tem a melhor taxa do mercado para
        alavancar os seus resultados. O nosso parceiro que realmente se preocupa
        com a entrega do seu espetáculo ao nível do <i>Cirque du Soleil</i> não
        busca outra solução a não ser nós.
      </ul>
    ),
  },
  {
    question:
      "Quais benefícios a EventX promete trazer de forma direta e indireta ao cliente e/ou Parceiro?",
    answer: (
      <ul>
        Facilidade em ferramentas integradas, dashboard atualizado com todos os
        dados, sistema global - desde a criação da venda do espetáculo, a venda
        do bilhete, check-in, parceiros e serviços -, entrega do conteúdo em um
        ambiente interativo, dinâmico, bonito e fácil de usar, e a entrega de
        uma área completa, dinâmica e funcional.
      </ul>
    ),
  },
  {
    question: "Quais serviços são oferecidos pela EventX?",
    answer: (
      <ul>
        <li>Facilidade em criação e gerenciamento dos eventos;</li>
        <li>
          Bilhetes e check-out com imagens e textos: pensado especialmente para
          o organizador e gestor de eventos;{" "}
        </li>
        <li>Assinatura on demand;</li>
        <li>
          Colaboração entre time com acompanhamento de tarefas e lista de
          parceiros para contratação de serviços;
        </li>
        <li>Transparência em taxas: não temos taxas escondidas;</li>
        <li>Pacotes de assinatura para a área do GESTOR DE EVENTO;</li>
        <li>
          Ferramentas extras (Addons) que facilitam a vida do organizador;
        </li>
        <li>
          O único do Brasil com sistema de check-in e check-out: maior controle
          para o seu evento, congresso e palestras presenciais;
        </li>
        <li>
          Serviço próprio de streaming e espaço para upload de vídeos (Panda);{" "}
        </li>
        <li>
          Relatório com informações, fáceis de visualizar, e segmentadas;{" "}
        </li>
        <li>
          Integração de colaboradores, com gerenciamento de tarefas, demandas e
          acesso ao sistema;
        </li>
        <li>Diversas integrações e possibilidade de integrar via API;</li>
        <li>
          Na EventX o seu evento pode acontecer de forma: presencial, online ou
          em formato híbrido, transmissão online e presencial do seu evento;
        </li>
        <li>
          Compartilhe o seu evento com simplicidade, com Google agenda e Wallet
          do IOS;
        </li>
        <li>
          Recebimento total em d+30 (receba em 30 dias após a venda do seu
          evento);
        </li>
        <li>
          Checkout transparente com vários tipos de pagamentos (boleto,
          múltiplos cartões, cartão único, internacional).
        </li>
      </ul>
    ),
  },
  {
    question: "A Eventx já atendeu algum cliente? Se sim, quais?",
    answer:
      "Pablo Marçal, Marcos Paulo, Junior Neves, Keila Neves, Plataforma Internacional, Jonathan Erick Crispim, Allan Bomfim, Dr. Rodrigo Albertini, Leonardo Leandro, Pedro Stivalli, são alguns clientes que nós atendemos.",
  },
  {
    question:
      "Qual é o maior diferencial que faz a EventX se destacar no seu ramo de atuação?",
    answer:
      "Gerar a verdadeira mudança através de uma nova experiência de como criar, vender e atender um evento, seja ele presencial ou online, transformando a vida em um evento.",
  },
  {
    question: "Qual é a missão da EventX?",
    answer:
      "Nossa missão é conectar pessoas através dos eventos, facilitar o acesso aos melhores eventos do país, com transparência e agilidade, e nos tornar a maior plataforma global de entretenimento.",
  },
  {
    question: "Quais são os valores da EventX?",
    answer:
      "Nossos valores estão baseados e firmados em princípios, aqui valorizamos pessoas, nosso maior patrimônio.",
  },
  {
    question: "Qual é a visão da EventX?",
    answer:
      "Nos tornar a maior e melhor ferramenta de experiência de divulgação de eventos do mundo, unificar a experiência do organizador e do cliente final, aprimorando a experiência com branding, exponencialidade, agilidade e facilidade.",
  },
  {
    question: "Qual é o manifesto da EventX?",
    answer:
      "A experiência premium para você e seus parceiros. Uma nova forma de gerenciar e agregar valor para seu evento. Ao entrar para a família EventX, a venda se torna uma consequência.",
  },
];
