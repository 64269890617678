import React from "react";

const OrganizerDataObligation = () => {
  return (
    <>
      <p>Ol&aacute; Organizador!</p>
      <p>&nbsp;</p>
      <p>
        Seja bem-vindo &agrave; Plataforma XGROW - EVENTX um produto exclusivo
        da empresa XGROW!
      </p>
      <p>
        O &ldquo;Acordo de Processamento de Dados&rdquo; a seguir &eacute;
        baseado em nossos princ&iacute;pios e valores. Levamos muito a
        s&eacute;rio a privacidade dos nossos usu&aacute;rios e, por isso,
        protegemos os dados pessoais tratados atrav&eacute;s da nossa
        plataforma.
      </p>
      <p>
        Uma vez que, os Organizadores poder&atilde;o realizar atividades de
        tratamento de dados pessoais por meio da plataforma XGROW - EVENTX,
        criamos o presente documento, cujo objetivo &eacute; estabelecer as
        regras e condi&ccedil;&otilde;es para tal tratamento. O presente
        documento &eacute; parte integrante e indissoci&aacute;vel das demais
        pol&iacute;ticas legais da XGROW - EVENTX.
      </p>
      <p>
        Como condi&ccedil;&atilde;o para acesso e utiliza&ccedil;&atilde;o da
        plataforma XGROW - EVENTX e suas funcionalidades, voc&ecirc; declara que
        realizou a leitura completa e atenta das regras deste documento, estando
        plenamente ciente e de acordo com elas.
      </p>
      <p>&nbsp;</p>
      <h2>Resumo</h2>
      <p>
        Sabemos que seu tempo &eacute; valioso e, por isso, elencamos abaixo os
        principais t&oacute;picos que, em nossa opini&atilde;o, s&atilde;o
        absolutamente indispens&aacute;veis para o uso da nossa plataforma.
        Por&eacute;m, n&atilde;o deixe de ler o documento por completo,
        combinado?
      </p>
      <p>
        O Organizador, na condi&ccedil;&atilde;o de controlador de dados
        pessoais, poder&aacute; realizar o tratamento dos dados pessoais dos
        Consumidores t&atilde;o somente em conformidade com a Lei n&deg;
        13.709/2018 (&ldquo;Lei Geral de Prote&ccedil;&atilde;o de Dados&rdquo;)
        e outras legisla&ccedil;&otilde;es, nacionais e internacionais
        aplic&aacute;veis, sendo respons&aacute;vel por eventuais
        descumprimentos.
      </p>
      <p>
        O Organizador ser&aacute; respons&aacute;vel por atender &agrave;s
        solicita&ccedil;&otilde;es dos titulares dos dados pessoais tratados
        atrav&eacute;s da plataforma XGROW - EVENTX, quando relacionadas ao
        exerc&iacute;cio de algum direito previsto na legisla&ccedil;&atilde;o
        aplic&aacute;vel (ex.: acesso, exclus&atilde;o, portabilidade, etc.),
        podendo a XGROW - EVENTX auxili&aacute;-lo nestas
        solicita&ccedil;&otilde;es, mediante requerimento expresso,
        pr&eacute;vio e, nos limites de suas capacidades t&eacute;cnicas.
      </p>
      <p>&nbsp;</p>
      <h2>1. Gloss&aacute;rio</h2>
      <p>
        <em>Consumidores:&nbsp;</em>termo utilizado para designar pessoas que
        adquirem ingressos para os eventos criados pelos pr&oacute;prios
        organizadores.
      </p>
      <p>
        <em>Controlador de dados pessoais</em>: pessoa natural ou
        jur&iacute;dica, de direito p&uacute;blico ou privado, a quem competem
        as decis&otilde;es referentes ao tratamento de dados pessoais.
      </p>
      <p>
        <em>Cookies</em>: arquivos que t&ecirc;m a finalidade de identificar um
        computador e obter dados de acesso, como p&aacute;ginas navegadas
        ou&nbsp;<em>links</em>&nbsp;clicados, permitindo, desta forma,
        personalizar a navega&ccedil;&atilde;o destes, de acordo com o perfil do
        usu&aacute;rio.
      </p>
      <p>
        <em>Dado Pessoal:&nbsp;</em>informa&ccedil;&atilde;o relacionada a
        pessoa natural identificada ou identific&aacute;vel.
      </p>
      <p>
        <em>Dado N&atilde;o Pessoal:&nbsp;</em>quaisquer
        informa&ccedil;&otilde;es que n&atilde;o se relacionem com uma pessoa
        e/ou n&atilde;o possam ser usados para identificar uma pessoa.
      </p>
      <p>
        <em>Operador de dados pessoais</em>: pessoa natural ou jur&iacute;dica,
        de direito p&uacute;blico ou privado, que realiza o tratamento de dados
        pessoais em nome do controlador.
      </p>
      <p>
        <em>Organizador(es):&nbsp;</em>termo utilizado para designar pessoas
        (f&iacute;sicas ou jur&iacute;dicas) que criam eventos pr&oacute;prios
        atrav&eacute;s da Plataforma XGROW - EVENTX e os divulgam para
        consumidores.
      </p>
      <p>
        <em>Plataforma XGROW - EVENTX:&nbsp;</em>designa a plataforma
        tecnol&oacute;gica dispon&iacute;vel para organizadores e casas de
        eventos, facilitando a venda de ingressos, inscri&ccedil;&atilde;o para
        eventos, cobran&ccedil;a de contribui&ccedil;&otilde;es para eventos e a
        gest&atilde;o de participantes, acess&iacute;vel no endere&ccedil;o
        eletr&ocirc;nico<strong>&nbsp;</strong>
        <a href="https://eventx.com.br/">
          <strong>
            <u>https://XGROW - EVENTX.com.br/</u>
          </strong>
        </a>
        .
      </p>
      <p>
        <em>Participantes</em>: termo utilizado para designar pessoas que
        usufruem dos ingressos adquiridos pelos Consumidores. Caso os
        Consumidores adquiram ingressos em nome pr&oacute;prio ser&atilde;o
        tamb&eacute;m Participantes. Por outro lado, caso o Consumidor adquira
        ingressos em proveito de terceiros, estes ser&atilde;o considerados
        t&atilde;o somente Participantes, e n&atilde;o Consumidores.
      </p>
      <p>
        <em>XGROW - EVENTX:&nbsp;</em>&Eacute; um produto da empresa XGROW
        TECNOLOGIA LTDA, pessoa jur&iacute;dica de direito privado, inscrita no
        CNPJ/ME sob o n&ordm;. 40.190.903/0001-05, sediada em Barueri, Estado de
        S&atilde;o Paulo, na Alameda Tocantins, n&ordm; 956, Alphaville
        Industrial, CEP 06455-020.
      </p>
      <p>
        <em>Tratamento de dados pessoais</em>: toda opera&ccedil;&atilde;o
        realizada com dados pessoais, como as que se referem a coleta,
        produ&ccedil;&atilde;o, recep&ccedil;&atilde;o,
        classifica&ccedil;&atilde;o, utiliza&ccedil;&atilde;o, acesso,
        reprodu&ccedil;&atilde;o, transmiss&atilde;o,
        distribui&ccedil;&atilde;o, processamento, arquivamento, armazenamento,
        elimina&ccedil;&atilde;o, avalia&ccedil;&atilde;o ou controle da
        informa&ccedil;&atilde;o, modifica&ccedil;&atilde;o,
        comunica&ccedil;&atilde;o, transfer&ecirc;ncia, difus&atilde;o ou
        extra&ccedil;&atilde;o.
      </p>
      <p>&nbsp;</p>
      <h2>2. Do tratamento de dados pessoais</h2>
      <p>
        2.1. Ao utilizar a plataforma XGROW - EVENTX, o Organizador, na
        condi&ccedil;&atilde;o de Controlador de dados pessoais, fica ciente que
        somente poder&aacute; realizar o tratamento dos dados pessoais dos
        Consumidores e Participantes em conformidade com a Lei n&deg;
        13.709/2018 (&ldquo;Lei Geral de Prote&ccedil;&atilde;o de Dados&rdquo;)
        e outras legisla&ccedil;&otilde;es, nacionais e internacionais,
        aplic&aacute;veis.
      </p>
      <p>
        2.2. A XGROW - EVENTX, na condi&ccedil;&atilde;o de Operadora de dados
        pessoais, realizar&aacute; o tratamento desses dados em nome dos
        Organizadores quando, por exemplo: (i) coletar e armazenar dados
        pessoais de Consumidores para presta&ccedil;&atilde;o dos
        servi&ccedil;os de gest&atilde;o de eventos e venda de ingressos; (ii)
        disponibilizar para os Organizadores ferramenta para envio de e-mails
        aos Consumidores e outros usu&aacute;rios; (iii) fornecer
        relat&oacute;rios de eventos para an&aacute;lise dos Organizadores.
      </p>
      <p>
        2.3. O Organizador fica ciente que o presente documento constitui a
        totalidade das instru&ccedil;&otilde;es l&iacute;citas para o tratamento
        de dados pessoais realizado pela XGROW - EVENTX, em nome do Organizador,
        e que quaisquer instru&ccedil;&otilde;es adicionais dever&atilde;o ser
        devidamente formalizadas, e depender&atilde;o de pr&eacute;via e
        expressa concord&acirc;ncia da XGROW - EVENTX.
      </p>
      <p>
        2.4. Caso o Organizador entenda que alguma opera&ccedil;&atilde;o
        envolvendo de tratamento de dados pessoais contrarie algum dispositivo
        legal, dever&aacute; informar imediatamente &agrave; XGROW - EVENTX, por
        escrito, para que as medidas cab&iacute;veis possam ser adotadas. &nbsp;
      </p>
      <br />

      <h2>3. Das Responsabilidades do Organizador</h2>
      <p>
        3.1. O Organizador reconhece que, no exerc&iacute;cio de suas atividades
        de tratamento, ser&aacute; integral e exclusivamente respons&aacute;vel,
        tanto perante a XGROW - EVENTX, quanto os titulares dos dados pessoais
        tratados, em caso de descumprimento da legisla&ccedil;&atilde;o
        aplic&aacute;vel, devendo ser diligente ao coletar dados pessoais (ex.:
        compra de listas de email), compartilhar dados com terceiros (ex.:
        patrocinadores de eventos, artistas, fornecedores, etc.), ou armazenar
        tais dados em sua pr&oacute;pria infraestrutura tecnol&oacute;gica ou da
        de terceiro.
      </p>
      <p>
        3.2. Caso a XGROW - EVENTX seja questionada (administrativa ou
        judicialmente) sobre a legalidade de qualquer atividade de tratamento de
        dados pessoais realizada pelo Organizador, caber&aacute; a este
        imediatamente: (a) identificar-se publicamente como exclusivo
        respons&aacute;vel pela atividade questionada; (b) tomar toda e qualquer
        medida ao seu alcance para excluir a XGROW - EVENTX do questionamento em
        quest&atilde;o; e (c) isentar a XGROW - EVENTX de qualquer
        responsabilidade neste sentido, nos termos deste documento.
      </p>
      <p>
        3.3. Caso n&atilde;o seja poss&iacute;vel a exclus&atilde;o da XGROW -
        EVENTX, o Organizador permitir&aacute; &agrave; XGROW - EVENTX, caso
        esta assim deseje, o acesso e a oportunidade de contribuir com quaisquer
        pe&ccedil;as de defesa apresentadas e reuni&otilde;es com autoridades
        e/ou outra(s) parte(s), incluindo aquelas de negocia&ccedil;&atilde;o de
        acordo a ser firmado com a autoridade competente, entidade(s)
        reclamante(s) ou titular(es), conforme o caso.
      </p>
      <p>
        3.4. O Organizador concorda e reconhece, contudo, que a
        participa&ccedil;&atilde;o da XGROW - EVENTX no processo relacionado a
        qualquer atividade de tratamento de dados pessoais questionada nos
        termos desta cl&aacute;usula em nada alterar&aacute; a aus&ecirc;ncia de
        responsabilidade da XGROW - EVENTX por tais fatos, cabendo ao
        Organizador reembolsar a XGROW - EVENTX de quaisquer valores
        eventualmente despendidos por esta no curso do processo, incluindo
        honor&aacute;rios advocat&iacute;cios e demais custos processuais
        envolvidos.
      </p>
      <br />

      <h2>4. Das obriga&ccedil;&otilde;es da XGROW - EVENTX</h2>
      <p>
        4.1. Ao realizar o tratamento de dados pessoais em nome dos
        Organizadores, a XGROW - EVENTX dever&aacute;:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          a) Adotar as medidas de seguran&ccedil;a, t&eacute;cnicas e
          administrativas, aptas a proteger os dados pessoais de acessos
          n&atilde;o autorizados e de situa&ccedil;&otilde;es acidentais ou
          il&iacute;citas de destrui&ccedil;&atilde;o, perda,
          altera&ccedil;&atilde;o, comunica&ccedil;&atilde;o ou qualquer forma
          de tratamento inadequado ou il&iacute;cito (&ldquo;incidentes&rdquo;),
          observados os limites de sua capacidade t&eacute;cnica e
          econ&ocirc;mica;
        </li>
        <li>
          b) Garantir que qualquer pessoa, f&iacute;sica ou jur&iacute;dica,
          contratada ou autorizada a realizar o tratamento de dados pessoais, em
          nome da XGROW - EVENTX (&ldquo;sub-processadores), estar&aacute;
          sujeita &agrave; obriga&ccedil;&otilde;es legais e de
          confidencialidade em rela&ccedil;&atilde;o a tais dados;
        </li>
        <li>
          c) Comunicar ao Organizador sobre incidentes relevantes envolvendo a
          perda, altera&ccedil;&atilde;o ou comunica&ccedil;&atilde;o
          n&atilde;o-autorizada dos dados pessoais sob responsabilidade do
          Organizador ou, sobre os quais exer&ccedil;a poder de decis&atilde;o.
        </li>
        <li>
          d) Auxiliar o Organizador na elabora&ccedil;&atilde;o ou
          condu&ccedil;&atilde;o de relat&oacute;rio de impacto &agrave;
          prote&ccedil;&atilde;o de dados pessoais (DPIA), observados os limites
          de sua capacidade t&eacute;cnica e econ&ocirc;mica, bem como o contido
          na cl&aacute;usula &ldquo;6&rdquo;.
        </li>
      </ol>
      <br />

      <h2>5. Dos direitos dos titulares de dados pessoais</h2>
      <p>
        5.1. O Organizador ser&aacute; integral e exclusivamente
        respons&aacute;vel por atender &agrave;s solicita&ccedil;&otilde;es dos
        titulares dos dados pessoais tratados atrav&eacute;s da plataforma XGROW
        - EVENTX, referentes: (i) &agrave; confirma&ccedil;&atilde;o da
        exist&ecirc;ncia de tratamento de dados pessoais; (ii) o acessos aos
        dados pessoais; (iii) &agrave; corre&ccedil;&atilde;o de dados
        incompletos, inexatos ou desatualizados; (iv)
        anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o dos
        dados desnecess&aacute;rios ou excessivos; (v) portabilidade dos dados,
        dentro dos limites legais; (vi) informa&ccedil;&atilde;o sobre a
        possibilidade de n&atilde;o fornecer consentimento e as respectivas
        consequ&ecirc;ncias, e; (vii) revoga&ccedil;&atilde;o do consentimento.
      </p>
      <p>
        5.2. A XGROW - EVENTX, quando requerida pelo Organizador, poder&aacute;
        auxili&aacute;-lo no atendimento a tais solicita&ccedil;&otilde;es, nos
        limites de sua capacidade t&eacute;cnica e econ&ocirc;mica, e a seu
        exclusivo crit&eacute;rio. Nos casos em que os titulares dos dados
        pessoais solicitarem diretamente &agrave; XGROW - EVENTX o
        exerc&iacute;cio de algum dos direitos acima previstos, a XGROW - EVENTX
        informar&aacute;, imediatamente, o Organizador para que este possa
        atender tal solicita&ccedil;&atilde;o ou, requerer o devido
        aux&iacute;lio &agrave; XGROW - EVENTX.
      </p>
      <h2>&nbsp;</h2>
      <h2>
        6. Da solicita&ccedil;&atilde;o de informa&ccedil;&otilde;es &agrave;
        XGROW - EVENTX
      </h2>
      <p>
        6.1. &nbsp;O Organizador, mediante requerimento expresso, poder&aacute;
        solicitar &agrave; XGROW - EVENTX o envio de informa&ccedil;&otilde;es
        relacionadas ao tratamento de dados pessoais realizado em seu nome. O
        Organizador dever&aacute; especificar quais informa&ccedil;&otilde;es
        dever&atilde;o constar no relat&oacute;rio, podendo a XGROW - EVENTX se
        negar a prestar quaisquer informa&ccedil;&otilde;es que, a seu exclusivo
        crit&eacute;rio, possam configurar segredo comercial ou industrial. O
        relat&oacute;rio ser&aacute; entregue pela XGROW - EVENTX no prazo de 30
        (trinta) dias corridos, contados do recebimento da
        solicita&ccedil;&atilde;o.
      </p>
      <br />

      <h2>7. Disposi&ccedil;&otilde;es Gerais</h2>
      <p>
        7.1. Ao utilizar a plataforma XGROW - EVENTX e suas funcionalidades, o
        Organizador aceita todo o disposto neste &ldquo;Acordo de Processamento
        de Dados&rdquo; e demais pol&iacute;ticas legais que se encontram
        vigentes na data de acesso. Por isso, &eacute; recomend&aacute;vel que
        se mantenha atualizado.
      </p>
      <p>
        7.2. O presente documento est&aacute; sujeito a constante melhoria e
        aprimoramento. Assim, a XGROW - EVENTX se reserva o direito de
        modific&aacute;-lo a qualquer momento, conforme a finalidade da
        plataforma XGROW - EVENTX, tal qual para adequa&ccedil;&atilde;o e
        conformidade legal de disposi&ccedil;&atilde;o de lei ou norma que tenha
        for&ccedil;a jur&iacute;dica equivalente, cabendo ao Organizador
        verific&aacute;-lo sempre que efetuar o acesso &agrave; plataforma XGROW
        - EVENTX.
      </p>
      <p>
        7.3. A eventual toler&acirc;ncia quanto a qualquer
        viola&ccedil;&atilde;o dos termos e condi&ccedil;&otilde;es contidos
        neste documento ser&aacute; considerada mera liberalidade e n&atilde;o
        ser&aacute; interpretada como nova&ccedil;&atilde;o, precedente
        invoc&aacute;vel, ren&uacute;ncia a direitos, altera&ccedil;&atilde;o
        t&aacute;cita dos termos contratuais, direito adquirido ou
        altera&ccedil;&atilde;o contratual.
      </p>
      <p>
        7.4. Caso alguma disposi&ccedil;&atilde;o deste Acordo for julgada
        inaplic&aacute;vel ou sem efeito, o restante das normas continuam a
        viger, sem a necessidade de medida judicial que declare tal assertiva.
        Os termos aqui descritos ser&atilde;o interpretados segundo a
        legisla&ccedil;&atilde;o brasileira.
      </p>
      <p>
        7.5. A comunica&ccedil;&atilde;o entre a XGROW - EVENTX e o Organizador
        dever&aacute; ser realizada pelos canais de atendimento indicados e
        disponibilizados na plataforma XGROW - EVENTX, sobretudo atrav&eacute;s
        do endere&ccedil;o de e-mail&nbsp;<strong>contato@eventx.com.br</strong>
      </p>
      <p>
        7.6. Sempre que poss&iacute;vel, potenciais diverg&ecirc;ncias entre o
        Organizador e a XGROW - EVENTX ser&atilde;o resolvidas de forma
        amig&aacute;vel. Quando todos os esfor&ccedil;os neste sentido forem
        esgotados, voc&ecirc; concorda, desde j&aacute;, que fica eleito o foro
        da comarca de Barueri, no Estado de S&atilde;o Paulo, para resolver
        controv&eacute;rsias ou queixas oriundas da utiliza&ccedil;&atilde;o de
        nossa plataforma ou relacionadas a este Acordo.
      </p>
    </>
  );
};

export default OrganizerDataObligation;
