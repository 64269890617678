import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useSWRConfig } from "swr";

import api from "services/api";

import { useEventSearch } from "services/Hook/Event";

import { paramsToString } from "utils/paramsToString";

import { FeaturedEvent } from "types";

type Meta = {
  total: number;
  last_page: number;
};

interface EventType {
  meta: Meta;
  data: FeaturedEvent[];
}

export function useSearchEvent() {
  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );
  const [pageMobile, setPageMobile] = useState(0);

  const [loadingMoreItems, setLoadingMoreItems] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(false);

  const price = searchParams.get("price") ?? "";
  const data = searchParams.get("data") ?? "";
  const address_city = searchParams.get("address_city") ?? "";
  const address_state = searchParams.get("address_state") ?? "";
  const name = searchParams.get("name") ?? "";
  const categories = searchParams.get("categories") ?? "";

  const [paramsString, setParamsString] = useState<URLSearchParams>();

  const { mutate } = useSWRConfig();

  const { data: eventsData, partialUrl } = useEventSearch<EventType>({
    paramsString: String(paramsString),
    page: String(page),
  });

  useEffect(() => {
    const paramsString = new URLSearchParams(
      paramsToString({
        id,
        name,
        price,
        data,
        categories,
        address_city,
        address_state,
      })
    );

    setParamsString(paramsString);
  }, [address_city, address_state, categories, data, id, name, price]);

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: String(currentPage),
      });

      window.scrollTo({ top: 660, behavior: "smooth" });
    },
    [searchParams, setSearchParams]
  );

  const handleLoadMoreItemsMobile = () => {
    setPageMobile(pageMobile + 1);

    mutate(
      [`${partialUrl}?${paramsString}`, 1],
      async () => {
        setLoadingMoreItems(true);

        const response = await api.get(
          `${partialUrl}?categories=${id}&page=${
            pageMobile + 1
          }&${paramsString}`
        );

        const last_page = response.data?.meta?.last_page;

        if (pageMobile + 1 === last_page) {
          setHasMoreItems(false);
        }

        setLoadingMoreItems(false);

        const mapEvents = response.data.data.map((event: Event) => ({
          ...event,
          isOpen: false,
        }));

        let newMapEvents = eventsData?.data;

        const mobile = eventsData?.data;

        if (mobile) {
          newMapEvents = [...mobile, ...mapEvents];
        }

        return {
          meta: response.data.meta,
          data: newMapEvents,
        };
      },
      false
    );
  };

  return {
    eventsData,
    page,
    onChange,
    handleLoadMoreItemsMobile,
    loadingMoreItems,
    hasMoreItems,
  };
}
