import React from "react";

const OrganizerTerms = () => {
  return (
    <>
      <h2>Introdu&ccedil;&atilde;o</h2>
      <p>&nbsp;</p>
      <p>Ol&aacute; Organizador!</p>
      <p>
        Seja bem-vindo &agrave; Plataforma XGROW - EVENTX um produto exclusivo
        da empresa XGROW!
      </p>
      <p>
        Os Termos de Uso a seguir s&atilde;o baseados em nossos
        princ&iacute;pios e valores, e t&ecirc;m o objetivo de reger nosso
        relacionamento. Como condi&ccedil;&atilde;o para acesso e
        utiliza&ccedil;&atilde;o da Plataforma XGROW - EVENTX e suas
        funcionalidades, voc&ecirc; declara que realizou a leitura completa e
        atenta das regras deste documento, estando plenamente ciente e de acordo
        com elas.
      </p>
      <br />
      <h2>Resumo</h2>
      <p>
        Sabemos que seu tempo &eacute; valioso e, por isso, elencamos abaixo os
        principais t&oacute;picos que, em nossa opini&atilde;o, s&atilde;o
        absolutamente indispens&aacute;veis para o uso da nossa plataforma.
        Por&eacute;m, n&atilde;o deixe de ler o documento por completo,
        combinado?
      </p>
      <p>
        A XGROW - EVENTX &eacute; uma plataforma que facilita a venda de
        ingressos, inscri&ccedil;&atilde;o para eventos, cobran&ccedil;a de
        contribui&ccedil;&otilde;es para eventos e a gest&atilde;o de
        participantes.
      </p>
      <p>
        Todos os eventos publicados na plataforma est&atilde;o sob
        responsabilidade de seus organizadores, uma vez que a XGROW - EVENTX
        n&atilde;o produz tais eventos.
      </p>
      <p>
        &Eacute; de inteira responsabilidade dos Organizadores definirem as
        quantidades e valores dos ingressos disponibilizados para venda, regras
        para acesso aos eventos, pol&iacute;ticas de reembolso, troca de
        titularidade, cumprimento da legisla&ccedil;&atilde;o de meia-entrada,
        etc;
      </p>
      <p>
        A plataforma XGROW - EVENTX permite que o Organizador solicite &agrave;
        XGROW - EVENTX, at&eacute; o 2&ordm; dia &uacute;til ap&oacute;s o
        t&eacute;rmino do evento, o cancelamento de uma venda e o consequente
        reembolso do valor pago pelo Consumidor.
      </p>
      <p>
        &Eacute; de responsabilidade do Organizador comunicar a seus potenciais
        consumidores sua pr&oacute;pria pol&iacute;tica de cancelamento, que
        dever&aacute; ser compat&iacute;vel com o per&iacute;odo m&aacute;ximo
        de reembolso permitido pela XGROW - EVENTX, e solicitar, quando
        considerar necess&aacute;rio, o cancelamento da transa&ccedil;&atilde;o
        atrav&eacute;s das ferramentas presentes na plataforma XGROW - EVENTX.
      </p>
      <p>
        Caso o Consumidor exer&ccedil;a seu direito de arrependimento nos termos
        do C&oacute;digo de Defesa do Consumidor, o reembolso do valor pago
        dever&aacute; ser efetuado pelo Organizador, no prazo m&aacute;ximo de 7
        (sete) dias, a contar da data da compra.
      </p>
      <p>
        O Organizador informar&aacute; seus dados para repasse via conta
        banc&aacute;ria, o valor total vendido, descontada a taxa de
        servi&ccedil;o cobrada pela XGROW - EVENTX, ser&aacute; repassado no
        3&ordm; dia &uacute;til ap&oacute;s o t&eacute;rmino real do evento, por
        meio de transfer&ecirc;ncia banc&aacute;ria, a uma conta indicada pelo
        Organizador na plataforma XGROW - EVENTX.
      </p>
      <br />
      <h2>1.Gloss&aacute;rio</h2>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <u>Anti-Spam</u>
          <em>:</em>sistema que evita correspond&ecirc;ncias n&atilde;o
          desejadas, como publicidade em massa, pelo bloqueio de mensagens ou as
          movendo para pasta espec&iacute;fica.
        </li>
        <li>
          <u>Chargeback</u>
          <em>:</em>cancelamento de uma compra online realizada atrav&eacute;s
          de cart&atilde;o de d&eacute;bito ou cr&eacute;dito, que pode
          acontecer em virtude do n&atilde;o reconhecimento da compra pelo
          titular do cart&atilde;o ou ainda pelo fato de a
          transa&ccedil;&atilde;o n&atilde;o obedecer &agrave;s
          regulamenta&ccedil;&otilde;es previstas nos contratos, termos,
          aditivos e manuais editados pelas administradoras de cart&atilde;o.
        </li>
        <li>
          <u>Consumidor</u>
          <em>:</em>termo utilizado para designar pessoas que adquirem
          ingressos, pagos ou gratuitos, para os eventos criados pelos
          Organizadores.
        </li>
        <li>
          <u>Contesta&ccedil;&atilde;o</u>
          <em>:</em>reclama&ccedil;&atilde;o de cobran&ccedil;a indevida,
          solicitada pelo titular do cart&atilde;o de cr&eacute;dito junto
          &agrave; operadora de seu cart&atilde;o, podendo ser iniciada por
          diversas raz&otilde;es, tais como o esquecimento de que a compra foi
          realizada, a utiliza&ccedil;&atilde;o do cart&atilde;o por outros
          membros da fam&iacute;lia, ou ainda, resultado de uma compra
          fraudulenta realizada por terceiros.
        </li>
        <li>
          <u>Crawler/Spider</u>
          <em>:</em>programas desenvolvidos para atuar ou obter
          informa&ccedil;&otilde;es de modo automatizado em sites.
        </li>
        <li>
          <u>Estorno</u>
          <em>:</em>a&ccedil;&atilde;o de estornar cr&eacute;dito ou
          d&eacute;bito indevidamente lan&ccedil;ado.
        </li>
        <li>
          <u>Empresas Homologadas</u>
          <em>:</em>Empresas que possuem tecnologia de integra&ccedil;&atilde;o
          reconhecida pelo time de produto da XGROW - EVENTX, conhecem e seguem
          nossas melhores pr&aacute;ticas operacionais, tem compromisso com a
          Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais e possuem
          acompanhamento recorrente do n&iacute;vel de servi&ccedil;o prestado.
        </li>
        <li>
          <u>Link</u>
          <em>:</em>terminologia para endere&ccedil;o de internet.
        </li>
        <li>
          <u>Look and feel</u>
          <em>:</em>conceito visual geral de uma obra protegida por direito de
          propriedade intelectual.
        </li>
        <li>
          <u>Organizador:</u>termo utilizado para designar pessoas que criam
          eventos atrav&eacute;s da Plataforma XGROW - EVENTX e os divulgam para
          consumidores.
        </li>
        <li>
          <u>Participantes</u>
          <em>:</em>termo utilizado para designar pessoas que usufruem dos
          ingressos adquiridos pelos Consumidores. Caso os Consumidores adquiram
          ingressos em nome pr&oacute;prio ser&atilde;o tamb&eacute;m
          Participantes. Por outro lado, caso o Consumidor adquira ingressos em
          proveito de terceiros, estes ser&atilde;o considerados Participantes,
          e n&atilde;o Consumidores.
        </li>
        <li>
          <u>Plataforma XGROW - EVENTX</u>
          <em>:</em>designa a plataforma tecnol&oacute;gica dispon&iacute;vel
          para Organizadores e casas de eventos, facilitando a venda de
          ingressos, inscri&ccedil;&atilde;o para eventos, cobran&ccedil;a de
          contribui&ccedil;&otilde;es para eventos e a gest&atilde;o de
          participantes, acess&iacute;vel no endere&ccedil;o eletr&ocirc;nico{" "}
          <a href="https://eventx.com.br/">
            <u>https://XGROW - EVENTX.com.br/</u>
          </a>
          &nbsp;ou por meio dos nossos aplicativos oficiais.
        </li>
        <li>
          XGROW - EVENTX<em>: </em>&Eacute; um produto da empresa XGROW
          TECNOLOGIA LTDA, pessoa jur&iacute;dica de direito privado, inscrita
          no CNPJ/ME sob o n&ordm;. 40.190.903/0001-05, sediada em Barueri,
          Estado de S&atilde;o Paulo, na Alameda Tocantins, n&ordm; 956,
          Alphaville Industrial, CEP 06455-020.
        </li>
        <li>
          <u>Usu&aacute;rio</u>
          <em>:</em>termo utilizado para designar, quando referidos em conjunto,
          organizadores, consumidores e pessoas que navegam na Plataforma XGROW
          - EVENTX.
        </li>
        <li>
          <u>Webmastering</u>
          <em>:</em>compreende a cria&ccedil;&atilde;o,
          programa&ccedil;&atilde;o, desenvolvimento, controle e disponibilidade
          de p&aacute;ginas de internet.
        </li>
      </ul>
      <br />

      <h2>2. Informa&ccedil;&otilde;es Gerais</h2>
      <p>
        2.1. Os presentes Termos regulam os servi&ccedil;os oferecidos na
        plataforma XGROW - EVENTX, que consistem em:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          disponibilizar uma solu&ccedil;&atilde;o tecnol&oacute;gica
          (plataforma XGROW - EVENTX) para que Organizadores possam criar,
          organizar, gerir e divulgar seus pr&oacute;prios eventos;
        </li>
        <li>
          viabilizar, por meio da plataforma XGROW - EVENTX, as
          opera&ccedil;&otilde;es de venda de ingressos cadastrados por
          Organizadores;
        </li>
      </ol>
      <ul style={{ marginLeft: "3em" }}>
        <li>hospedar os eventos criados pelos Organizadores.</li>
      </ul>
      <p>
        2.2. Os eventos criados e divulgados atrav&eacute;s da plataforma XGROW
        - EVENTX s&atilde;o de <strong>responsabilidade exclusiva</strong>
        &nbsp;dos pr&oacute;prios Organizadores, os quais s&atilde;o os
        &uacute;nicos respons&aacute;veis por estabelecer as
        condi&ccedil;&otilde;es de venda, realiza&ccedil;&atilde;o e
        cancelamento dos eventos. A XGROW - EVENTX n&atilde;o &eacute; criadora,
        organizadora ou propriet&aacute;ria dos eventos hospedados em sua
        plataforma, por&eacute;m poder&aacute; monitorar os eventos criados, a
        fim de que estes cumpram suas pol&iacute;ticas legais.
      </p>
      <p>
        2.2.1. O Organizador isenta a XGROW - EVENTX de qualquer
        responsabilidade civil, de natureza moral ou material, inclusive lucros
        cessantes e danos emergentes, advinda dos atos referentes &agrave;
        produ&ccedil;&atilde;o e realiza&ccedil;&atilde;o de eventos. O
        Organizador fica ciente, desde j&aacute;, que havendo
        condena&ccedil;&atilde;o judicial contra a XGROW - EVENTX, essa
        poder&aacute; efetuar, imediatamente, a cobran&ccedil;a do valor que for
        obrigada a pagar, sem a necessidade de ajuizamento de a&ccedil;&atilde;o
        de regresso. Caso n&atilde;o haja composi&ccedil;&atilde;o
        amig&aacute;vel e a XGROW - EVENTX seja obrigada a arcar com
        preju&iacute;zos decorrentes de situa&ccedil;&otilde;es advindas de
        processos judiciais, a XGROW - EVENTX ajuizar&aacute; a&ccedil;&atilde;o
        de regresso contra o Organizador, e esse restar&aacute; obrigado a arcar
        com as taxas judiciais, custas e honor&aacute;rios contratuais de
        advogado no valor de 20% (vinte por cento) sobre o valor da causa.
      </p>
      <p>
        2.3. A plataforma XGROW - EVENTX e seus funcionalidades s&atilde;o
        apresentadas aos usu&aacute;rios da maneira como est&atilde;o
        dispon&iacute;veis, podendo passar por constantes aprimoramentos e
        atualiza&ccedil;&otilde;es, obrigando-se a XGROW - EVENTX a:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          a) Preservar as funcionalidades da plataforma XGROW - EVENTX, com
          links n&atilde;o-quebrados, utilizando-se de layouts que respeitem e
          privilegiam a usabilidade e navegabilidade;
        </li>
        <li>
          b) Exibir as funcionalidades de maneira clara, completa, precisa e
          suficiente, de modo que possibilite a exata percep&ccedil;&atilde;o
          das opera&ccedil;&otilde;es realizadas.
        </li>
      </ol>
      <p>
        2.4. A XGROW - EVENTX envida seus esfor&ccedil;os para a disponibilidade
        cont&iacute;nua e permanente de sua plataforma. No entanto,
        poder&aacute; ocorrer, eventualmente, alguma indisponibilidade
        tempor&aacute;ria decorrente de manuten&ccedil;&atilde;o
        necess&aacute;ria ou mesmo gerada por motivo de for&ccedil;a maior, como
        desastres naturais, falhas ou colapsos nos sistemas centrais de
        comunica&ccedil;&atilde;o e acesso &agrave; internet ou, fatos de
        terceiro que fogem de sua esfera de vigil&acirc;ncia e responsabilidade.
      </p>
      <p>
        2.4.1. Em caso de indisponibilidade, a XGROW - EVENTX far&aacute; tudo
        que estiver ao seu alcance para restabelecer, o mais breve
        poss&iacute;vel, o acesso &agrave; plataforma, dentro das
        limita&ccedil;&otilde;es t&eacute;cnicas de seus servi&ccedil;os
        pr&oacute;prios e dos de terceiros.
      </p>
      <p>
        2.4.2. O Organizador isenta a XGROW - EVENTX de qualquer dano nos casos
        de paralisa&ccedil;&otilde;es, parciais ou totais, dos seus
        servi&ccedil;os, decorrentes de falta tempor&aacute;ria de energia
        el&eacute;trica, de pane nos servi&ccedil;os das concession&aacute;rias,
        de falhas nos servi&ccedil;os oferecidos pelas redes telef&ocirc;nicas e
        outros provedores, inclusive provedores de hospedagem, de greves,
        tumultos ou quaisquer outros fatos que n&atilde;o estejam diretamente
        ligados ao servi&ccedil;o. A XGROW - EVENTX tamb&eacute;m n&atilde;o
        ser&aacute; respons&aacute;vel por qualquer dano causado por motivo de
        caso fortuito ou for&ccedil;a maior, bem como fato de terceiro.
      </p>
      <p>
        2.5. Eventuais procedimentos de manuten&ccedil;&atilde;o
        necess&aacute;ria da plataforma XGROW - EVENTX ser&atilde;o informados,
        sempre que poss&iacute;vel, com a devida anteced&ecirc;ncia e,
        atrav&eacute;s dos canais oficiais de comunica&ccedil;&atilde;o da XGROW
        - EVENTX.
      </p>
      <br />
      <h2>3. Cadastro de usu&aacute;rios</h2>
      <p>
        3.1. O cadastro na plataforma XGROW - EVENTX &eacute; gratuito, no
        entanto, os servi&ccedil;os oferecidos ser&atilde;o cobrados de acordo
        com o estabelecido pela XGROW - EVENTX em seus Termos.
      </p>
      <p>
        3.1.2. Ao se cadastrar, o usu&aacute;rio se obriga a utilizar a
        plataforma XGROW - EVENTX de maneira &eacute;tica e adequada aos
        prop&oacute;sitos estabelecidos nestes Termos, atendendo ao disposto nas
        leis brasileiras, em especial a Lei n&ordm; 9.613 de 1998 (&ldquo;Lei de
        Lavagem de Dinheiro&rdquo;), comprometendo-se a se abster de qualquer
        atividade que constitua viola&ccedil;&atilde;o das referidas normas.
      </p>
      <p>
        3.2. Os usu&aacute;rios devem fornecer informa&ccedil;&otilde;es exatas
        e verdadeiras, assumindo o compromisso de atualizar seus dados pessoais
        e dados de identifica&ccedil;&atilde;o da pessoa, f&iacute;sica ou
        jur&iacute;dica, respons&aacute;vel pelo evento, perante a XGROW -
        EVENTX e terceiros, sempre que necess&aacute;rio ou quando requerido.
      </p>
      <p>
        3.2.1. Se tivermos motivos que indiquem que quaisquer
        informa&ccedil;&otilde;es cadastradas n&atilde;o s&atilde;o verdadeiras,
        teremos o direito de suspender ou encerrar a conta do usu&aacute;rio,
        bem como nos recusar a prover quaisquer de nossos servi&ccedil;os.
      </p>
      <p>
        3.2.2. A XGROW - EVENTX n&atilde;o se responsabiliza pela veracidade ou
        assertividade dos dados inseridos no cadastro, sendo que a
        cria&ccedil;&atilde;o de perfis falsos na plataforma XGROW - EVENTX
        poder&aacute; ser considerado crime de falsa identidade, falsidade
        ideol&oacute;gica ou estelionato.
      </p>
      <p>
        3.3. Caso a XGROW - EVENTX verifique a exist&ecirc;ncia de atividade
        suspeita, ou transa&ccedil;&atilde;o que apresente consider&aacute;vel
        risco de fraude, poder&aacute;, a qualquer tempo e a seu exclusivo
        crit&eacute;rio, confirmar a identidade e os dados pessoais do
        usu&aacute;rio, podendo, assim, solicitar documentos pessoais ou outras
        formas de comprova&ccedil;&atilde;o, por telefone, e-mail ou outro meio
        h&aacute;bil.
      </p>
      <p>
        3.3.1. Qualquer erro ou atraso no processo de envio ou
        confirma&ccedil;&atilde;o da identidade do usu&aacute;rio que gere
        preju&iacute;zo ou dano de qualquer natureza ser&aacute; de
        responsabilidade do usu&aacute;rio.
      </p>
      <p>
        3.4. Sem preju&iacute;zo de outras medidas, a XGROW - EVENTX
        poder&aacute; advertir, moderar, suspender ou cancelar o cadastro dos
        usu&aacute;rios, bem como os eventos associados, negando-se a prestar os
        servi&ccedil;os descritos nestes Termos, caso o usu&aacute;rio:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          a) Descumpra com qualquer disposi&ccedil;&atilde;o destes Termos ou
          qualquer outra pol&iacute;tica da XGROW - EVENTX;
        </li>
        <li>b) Pratique atos fraudulentos ou dolosos;</li>
        <li>
          c) D&ecirc; causa a qualquer tipo de dano ou preju&iacute;zo a
          terceiro ou &agrave; pr&oacute;pria XGROW - EVENTX, na
          utiliza&ccedil;&atilde;o da plataforma XGROW - EVENTX.
        </li>
      </ol>
      <br />
      <h2>4. Acesso &agrave; Plataforma XGROW - EVENTX</h2>
      <p>
        4.1. Os usu&aacute;rios ter&atilde;o acesso a sua conta atrav&eacute;s
        de login e senha pessoais e intransfer&iacute;veis, ou atrav&eacute;s de
        contas pessoais providas por terceiros, como Facebook. De qualquer
        maneira, os usu&aacute;rios se comprometem a n&atilde;o informar a
        terceiros tais dados, responsabilizando-se integralmente pelo uso que
        deles seja feito, se comprometendo a notificar a XGROW - EVENTX,
        imediatamente, acerca de quaisquer usos n&atilde;o autorizados de sua
        conta ou quaisquer outras viola&ccedil;&otilde;es de seguran&ccedil;a. A
        XGROW - EVENTX n&atilde;o ser&aacute; respons&aacute;vel por quaisquer
        perdas e danos resultantes de acessos n&atilde;o autorizados ou uso da
        conta.
      </p>
      <p>
        4.2. Apenas o titular da conta na &nbsp;XGROW - EVENTX poder&aacute; ter
        acesso aos dados relativos &agrave; mesma, tendo em vista seu
        car&aacute;ter pessoal. Eventuais altera&ccedil;&otilde;es em dados
        cadastrados somente poder&atilde;o ser feitas pelo pr&oacute;prio
        usu&aacute;rio, devidamente <em>logado</em>. Em regra, a &nbsp;XGROW -
        EVENTX n&atilde;o realiza esse tipo de altera&ccedil;&atilde;o,
        independentemente da raz&atilde;o alegada ou circunst&acirc;ncia.
      </p>
      <br />
      <h2>
        5. Responsabilidades e obriga&ccedil;&otilde;es dos Organizadores na
        cria&ccedil;&atilde;o de eventos
      </h2>
      <p>&nbsp;</p>
      <p>
        5.1. Ao criarem eventos na Plataforma XGROW - EVENTX, os Organizadores
        dever&atilde;o:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          Publicar eventos ou quaisquer conte&uacute;dos que sejam verdadeiros e
          com informa&ccedil;&otilde;es completas, sendo responsabilidade do
          Organizador comunicar com exatid&atilde;o informa&ccedil;&otilde;es
          como: local, data, hor&aacute;rio, valores, atra&ccedil;&otilde;es,
          servi&ccedil;os oferecidos, contato, pol&iacute;tica de cancelamento,
          pol&iacute;tica de meia-entrada para fins de atendimento &agrave; Lei
          n&ordm; 12.933/13 e o Decreto n&ordm; 8.537/2015,
          restri&ccedil;&atilde;o de faixa et&aacute;ria e qualquer outra
          informa&ccedil;&atilde;o que seja relevante para os Consumidores e
          Participantes, ou ainda, para a valida&ccedil;&atilde;o da
          realiza&ccedil;&atilde;o dos eventos;
        </li>
        <li>
          Ao publicar eventos ou quaisquer conte&uacute;dos com "Local a
          definir", o Organizador se responsabiliza por atualiz&aacute;-lo
          definindo o local no prazo de 7 (sete) dias antes da ocorr&ecirc;ncia
          do evento. Caso n&atilde;o o fa&ccedil;a, a XGROW - EVENTX
          poder&aacute; suspender, excluir ou moder&aacute;-lo.
        </li>
        <li>
          Publicar eventos ou quaisquer conte&uacute;dos que o Organizador tenha
          o direito e capacidade de cumprir, sendo que o Organizador assume que
          os eventos ou conte&uacute;dos publicados t&ecirc;m todos os seus
          tr&acirc;mites finalizados junto a atra&ccedil;&otilde;es, local,
          patrocinadores, fornecedores, &oacute;rg&atilde;os p&uacute;blicos e
          qualquer outra parte interessada, nos termos de suas obrigatoriedades
          legais, e que &eacute; capaz e ir&aacute; entregar tudo exatamente
          como foi anunciado, sob pena de publicidade enganosa. Al&eacute;m
          disso, o Organizador confirma que tem direitos sobre o evento ou
          conte&uacute;do publicado e que n&atilde;o est&aacute; infringindo
          direito de propriedade de terceiro.
        </li>
        <li>
          Autorizar que a &nbsp;XGROW - EVENTX entre em contato e solicite,
          junto ao local do evento, fornecedores ou demais partes interessadas,
          informa&ccedil;&otilde;es e documenta&ccedil;&otilde;es que considere
          necess&aacute;rias para verifica&ccedil;&atilde;o da legitimidade do
          evento.
        </li>
        <li>
          N&atilde;o agir como qualquer outra pessoa ou entidade, sob pena de
          responder, criminalmente e civilmente, pelos crimes de estelionato,
          falsidade ideol&oacute;gica e outros il&iacute;citos tipificados na
          legisla&ccedil;&atilde;o brasileira. Isso inclui n&atilde;o publicar
          eventos ou conte&uacute;dos caso o usu&aacute;rio da plataforma seja
          um promoter, comiss&aacute;rio ou afim. Para os promoters oficiais do
          evento (pr&eacute;via e devidamente autorizados pelo Organizador)
          fazerem suas vendas, a &nbsp;XGROW - EVENTX disponibiliza a
          funcionalidade &ldquo;Programa de afiliados&rdquo;.
        </li>
        <li>
          N&atilde;o publicar eventos ou quaisquer conte&uacute;dos que sejam
          ilegais, racistas, preconceituosos, amea&ccedil;adores, terroristas,
          difamat&oacute;rios, obscenos, vulgares, que incitem
          manifesta&ccedil;&otilde;es de &oacute;dio ou invasivos &agrave;
          privacidade alheia;
        </li>
        <li>
          Ter total responsabilidade sobre os links e redirecionamentos postados
          na descri&ccedil;&atilde;o da p&aacute;gina p&uacute;blica do evento.
          A XGROW - EVENTX n&atilde;o tem responsabilidade sobre os mesmos e
          n&atilde;o pode atestar sua veracidade ou confiabilidade;
        </li>
        <li>
          Deixar seus sistemas de anti-spam, filtros, ou similares, de modo que
          n&atilde;o interfiram no recebimento dos comunicados e materiais da
          XGROW - EVENTX, n&atilde;o sendo aceit&aacute;vel nenhuma escusa caso
          n&atilde;o tenha tido acesso a algum e-mail ou mensagem
          eletr&ocirc;nica em virtude dos recursos mencionados.
        </li>
        <li>
          Cadastrar corretamente, na aba &ldquo;Financeiro&rdquo;, e antes do
          in&iacute;cio das vendas, os dados para o repasse dos valores
          arrecadados com as vendas de ingressos.
        </li>
        <li>
          Utilizar a &nbsp;XGROW - EVENTX somente para a
          comercializa&ccedil;&atilde;o de ingressos para eventos hospedados na
          plataforma, sendo vedada a comercializa&ccedil;&atilde;o de produtos
          ou servi&ccedil;os de outra natureza, ou ainda, eventos hospedados em
          outros sites e plataformas. A &nbsp;XGROW - EVENTX disponibiliza sua
          plataforma tendo como &uacute;nico intuito operacionalizar a venda de
          ingressos a gest&atilde;o de eventos por parte do Organizador.
        </li>
      </ol>
      <p>
        5.2. Em todos os casos, a &nbsp;XGROW - EVENTX, a seu exclusivo
        crit&eacute;rio, poder&aacute; se recusar a prestar quaisquer
        servi&ccedil;os, independentemente da motiva&ccedil;&atilde;o, sem que
        isso constitua limita&ccedil;&atilde;o de direitos.
      </p>
      <br />
      <h2>6. Divulga&ccedil;&atilde;o dos eventos</h2>
      <p>
        6.1. A &nbsp;XGROW - EVENTX possui ferramenta pr&oacute;pria de apoio a
        divulga&ccedil;&atilde;o do evento que permite que o Organizador envie
        convites personalizados por e-mail. Contudo, se verificarmos que o
        mailing utilizado pelo Organizador n&atilde;o &eacute; confi&aacute;vel,
        a &nbsp;XGROW - EVENTX poder&aacute; limitar ou bloquear o envio de
        e-mails sem comunica&ccedil;&atilde;o pr&eacute;via. Por isso, o
        Organizador deve verificar o mailing e se certificar que os
        endere&ccedil;os eletr&ocirc;nicos utilizados em
        divulga&ccedil;&otilde;es s&atilde;o confi&aacute;veis.
      </p>
      <p>
        6.2. O Organizador tamb&eacute;m &eacute; respons&aacute;vel pela
        divulga&ccedil;&atilde;o das informa&ccedil;&otilde;es do evento, seja
        na p&aacute;gina do evento, ou por qualquer outro meio. Para que as
        informa&ccedil;&otilde;es e instru&ccedil;&otilde;es sobre o evento
        sejam divulgadas satisfatoriamente, a &nbsp;XGROW - EVENTX fornece
        ferramentas para o envio de e-mails aos participantes. Essa ferramenta
        deve ser utilizada apenas para o fim de disseminar
        informa&ccedil;&otilde;es sobre o evento e instru&ccedil;&otilde;es aos
        participantes. Se a &nbsp;XGROW - EVENTX identificar o uso da mesma para
        outras finalidades, poder&aacute; bloquear o acesso do Organizador
        &agrave; ferramenta.
      </p>
      <p>
        6.2.1. O Organizador tamb&eacute;m &eacute; respons&aacute;vel por tirar
        d&uacute;vidas ou instruir os Consumidores ou Participantes, por
        exemplo, atrav&eacute;s da ferramenta existente na p&aacute;gina do
        evento. O Organizador dever&aacute; responder tais questionamentos, em
        tempo h&aacute;bil e de maneira clara.
      </p>
      <br />
      <h2>7. Venda de Ingressos</h2>
      <p>
        7.1. Geralmente, a venda de ingressos para eventos inicia-se na
        p&aacute;gina do evento publicado pelo Organizador. O consumidor
        escolher&aacute; os ingressos desejados, bem como o m&eacute;todo de
        pagamento, considerando as op&ccedil;&otilde;es dispon&iacute;veis, e em
        seguida, ir&aacute; finalizar o pedido.
      </p>
      <p>
        7.1.1. O Organizador dever&aacute; utilizar somente os meios de
        pagamento dispon&iacute;veis na plataforma para
        comercializa&ccedil;&atilde;o de ingressos pagos. Caso o Organizador
        indique outros meios de pagamento (ex.: transfer&ecirc;ncia entre contas
        banc&aacute;rias), ou crie &ldquo;ingressos gr&aacute;tis&rdquo; com
        posterior cobran&ccedil;a do Consumidor, a &nbsp;XGROW - EVENTX
        poder&aacute; excluir o evento ou moderar seu conte&uacute;do, a seu
        exclusivo crit&eacute;rio.
      </p>
      <p>
        7.2. Ap&oacute;s a confirma&ccedil;&atilde;o da aprova&ccedil;&atilde;o
        do pagamento, feita por intermedi&aacute;rio financeiro, a &nbsp;XGROW -
        EVENTX recolher&aacute; o valor correspondente, deduzir&aacute; a taxa
        de servi&ccedil;o e repassar&aacute; ao Organizador o valor restante, de
        acordo com o modelo de recebimento escolhido.
      </p>
      <p>
        7.3. Todas as transa&ccedil;&otilde;es realizadas atrav&eacute;s da
        &nbsp;XGROW - EVENTX passam por processos, internos e automatizados, de
        an&aacute;lise de risco que s&atilde;o complementados pelos nossos
        processadores de pagamentos.
      </p>
      <p>
        7.4. Por seguran&ccedil;a, as compras realizadas utilizando
        cart&atilde;o de cr&eacute;dito que necessitem de
        confirma&ccedil;&atilde;o ou valida&ccedil;&atilde;o adicional
        poder&atilde;o passar pelo processo de an&aacute;lise aprofundada,
        podendo a aprova&ccedil;&atilde;o ficar pendente at&eacute; o
        t&eacute;rmino dessa an&aacute;lise, cujo prazo &eacute; de 48 (quarenta
        e oito) horas ap&oacute;s a compra.
      </p>
      <p>
        7.4.1. Caso a &nbsp;XGROW - EVENTX identifique alguma
        inconsist&ecirc;ncia ap&oacute;s a an&aacute;lise acima, a compra
        poder&aacute; ser submetida a um novo processo de
        verifica&ccedil;&atilde;o, a qualquer momento. O Consumidor ser&aacute;
        notificado por e-mail, no mesmo endere&ccedil;o associado a compra, caso
        o pedido seja suspenso e/ou cancelado pela XGROW - EVENTX.
      </p>
      <p>
        7.5. Especificamente em casos de compras com ind&iacute;cios de fraude,
        o setor de An&aacute;lise de Risco da &nbsp;XGROW - EVENTX poder&aacute;
        solicitar ao Consumidor a c&oacute;pia digitalizada dos documentos do
        titular do cart&atilde;o de cr&eacute;dito utilizado nas compras, bem
        como do pr&oacute;prio cart&atilde;o utilizado, visando
        legitim&aacute;-las. Neste caso, o contato ser&aacute; feito
        atrav&eacute;s dos nossos canais oficiais ou colaboradores autorizados,
        que se identificaram logo no in&iacute;cio do atendimento.
      </p>
      <p>
        7.5.1. O prazo de envio de tal documenta&ccedil;&atilde;o ser&aacute;
        estabelecido pela &nbsp;XGROW - EVENTX, de acordo com a data e
        hor&aacute;rio em que o evento ocorrer&aacute;, de forma que, remetido o
        documento pelo Consumidor, a &nbsp;XGROW - EVENTX far&aacute; a
        an&aacute;lise do mesmo e dar&aacute; um retorno atrav&eacute;s do mesmo
        e-mail utilizado para a compra.
      </p>
      <p>
        7.5.2. Caso os documentos n&atilde;o sejam enviados pelo Consumidor no
        prazo determinado, ficar&aacute; a cargo da &nbsp;XGROW - EVENTX
        cancelar/suspender, ou n&atilde;o, a transa&ccedil;&atilde;o, a seu
        exclusivo crit&eacute;rio. A &nbsp;XGROW - EVENTX, ainda, poder&aacute;
        solicitar ao Consumidor que seja apresentado, na portaria do evento, um
        documento de identidade, com foto do titular do cart&atilde;o ou, o
        pr&oacute;prio cart&atilde;o utilizado na compra, para fins de
        valida&ccedil;&atilde;o da compra. Em todo o caso, se o Consumidor
        n&atilde;o enviar as informa&ccedil;&otilde;es requeridas, a &nbsp;XGROW
        - EVENTX poder&aacute; cancelar a transa&ccedil;&atilde;o sem que tal
        fato gere expectativa de direito ou indeniza&ccedil;&atilde;o de
        qualquer esp&eacute;cie.
      </p>
      <p>
        7.6. O Organizador deve zelar pela n&atilde;o ocorr&ecirc;ncia de fraude
        nas compras e auxiliar a &nbsp;XGROW - EVENTX, sempre que solicitado, na
        apura&ccedil;&atilde;o de eventual suspeita de fraude na venda de
        ingressos.
      </p>
      <br />
      <h2>8.Entrega dos ingressos</h2>
      <p>
        8.1. Assim que o pagamento for aprovado, os ingressos comprados
        atrav&eacute;s da plataforma &nbsp;XGROW - EVENTX ser&atilde;o enviados
        ao e-mail indicado pelo consumidor.
      </p>
      <p>
        8.1.1. Cabe ao Consumidor deixar seus sistemas de <em>anti-spam</em>,
        filtros ou similares, de modo que n&atilde;o interfiram no recebimento
        dos comunicados e materiais da &nbsp;XGROW - EVENTX, n&atilde;o sendo
        aceit&aacute;vel nenhuma escusa caso n&atilde;o tenha tido acesso a
        algum e-mail ou mensagem eletr&ocirc;nica em virtude dos recursos
        mencionados, o que n&atilde;o gerar&aacute; qualquer tipo de expectativa
        de direito ou indeniza&ccedil;&atilde;o ao Consumidor.
      </p>
      <p>
        8.2. Pagamentos aprovados em tempo real habilitam que os ingressos
        tamb&eacute;m sejam baixados na pr&oacute;pria tela de
        confirma&ccedil;&atilde;o da compra.
      </p>
      <p>
        8.2. Al&eacute;m das formas de entrega descritas acima, os ingressos
        tamb&eacute;m podem ser acessados na &aacute;rea autenticada (
        <em>logada</em>) do site ou aplicativo. Para isso, o consumidor deve
        criar um usu&aacute;rio, gratuitamente, na plataforma &nbsp;XGROW -
        EVENTX, fornecendo o mesmo e-mail utilizado no ato da compra.
      </p>
      <br />
      <h2>9. Uso dos ingressos e participa&ccedil;&atilde;o em eventos</h2>
      <p>
        9.1. Os ingressos adquiridos pelo Consumidor garantem sua
        participa&ccedil;&atilde;o no respectivo evento, conforme as
        condi&ccedil;&otilde;es estabelecidas pelo Organizador. O Organizador
        deve respeitar essa premissa, garantindo a entrada dos Participantes ao
        evento sempre que um ingresso v&aacute;lido for apresentado.
      </p>
      <p>
        9.2. A plataforma &nbsp;XGROW - EVENTX possui as ferramentas
        necess&aacute;rias para o correto controle de entrada dos Participantes
        nos eventos. A n&atilde;o utiliza&ccedil;&atilde;o dessas ferramentas,
        bem como os problemas decorrentes dessa decis&atilde;o, s&atilde;o de
        exclusiva responsabilidade do Organizador. O uso dessas ferramentas
        evita o acesso ao evento de Participantes com ingressos
        inv&aacute;lidos, quando, por exemplo,&nbsp;c&oacute;pias indevidas de
        ingressos, um ingresso &eacute; invalidado por suspeita de fraude,
        ingresso &eacute; invalidado devido a uma contesta&ccedil;&atilde;o da
        compra, ingresso &eacute; invalidado por ter sido feita uma troca de
        titularidade, ingresso &eacute; invalidado por ter tido a compra
        cancelada e/ou &nbsp;&nbsp;&nbsp;&nbsp;trata-se de um ingresso falso ou
        adulterado.
      </p>
      <p>
        9.3. Em casos de eventos que ocorram virtualmente, o Organizador se
        responsabiliza pelo conte&uacute;do transmitido, bem como a qualidade da
        transmiss&atilde;o, sendo ainda respons&aacute;vel garantir os acessos
        v&aacute;lidos aos Participantes, em tempo h&aacute;bil.
      </p>
      <br />
      <h2>10. Cancelamento e reembolso de compras</h2>
      <p>
        10.1. Caso seja necess&aacute;rio reembolsar os consumidores, o
        Organizador dever&aacute;, necessariamente, faz&ecirc;-lo atrav&eacute;s
        da plataforma &nbsp;XGROW - EVENTX, sendo vedada a
        realiza&ccedil;&atilde;o do reembolso por meios diversos.
      </p>
      <p>
        10.2. A plataforma &nbsp;XGROW - EVENTX permite que o Organizador
        solicite &agrave; &nbsp;XGROW - EVENTX, at&eacute; o 2&ordm; dia
        &uacute;til ap&oacute;s o t&eacute;rmino do evento, o cancelamento de
        uma venda e o consequente reembolso do valor pago pelo Consumidor.
      </p>
      <p>
        10.3. &Eacute; de responsabilidade do Organizador comunicar a seus
        potenciais consumidores sua pr&oacute;pria pol&iacute;tica de
        cancelamento, que dever&aacute; ser compat&iacute;vel com o
        per&iacute;odo m&aacute;ximo de reembolso permitido pela &nbsp;XGROW -
        EVENTX, conforme item acima, e solicitar, quando considerar
        necess&aacute;rio, o cancelamento da transa&ccedil;&atilde;o
        atrav&eacute;s das ferramentas presentes na plataforma &nbsp;XGROW -
        EVENTX.
      </p>
      <p>
        10.4. Caso o Consumidor exer&ccedil;a seu direito de arrependimento, nos
        termos do C&oacute;digo de Defesa do Consumidor, o reembolso do valor
        pago dever&aacute; ser efetuado pelo Organizador, no prazo m&aacute;ximo
        de 7 (sete) dias, a contar da data compra.
      </p>
      <br />
      <h2>11. Eventos Cancelados ou Substancialmente Alterados</h2>
      <p>
        11.1. O Organizador se obriga a comunicar imediatamente &agrave;
        &nbsp;XGROW - EVENTX os casos de cancelamento de evento, cancelamento de
        atra&ccedil;&atilde;o, altera&ccedil;&atilde;o de data,
        altera&ccedil;&atilde;o de local ou qualquer outra
        altera&ccedil;&atilde;o relevante referente ao seu evento.
      </p>
      <p>
        11.2. &Eacute; obriga&ccedil;&atilde;o do Organizador arcar com o
        reembolso de todos os Consumidores caso o evento, independentemente do
        motivo, n&atilde;o ocorra, ou mesmo quando este n&atilde;o for entregue
        conforme anunciado. De igual modo, &eacute; assegurado &agrave;
        &nbsp;XGROW - EVENTX o direito de regresso contra o Organizador por
        quaisquer valores que seja compelida a pagar aos Consumidores em virtude
        de a&ccedil;&atilde;o, reclama&ccedil;&atilde;o ou processo
        administrativo, seja judicial ou extrajudicial.
      </p>
      <p>
        11.3. Se identificarmos que algum evento n&atilde;o foi realizado ou foi
        realizado de maneira distinta ao anunciado, a &nbsp;XGROW - EVENTX se
        resguarda o direito de reembolsar todas as compras ou mesmo suspender,
        provisoriamente, o valor do repasse at&eacute; que a
        situa&ccedil;&atilde;o esteja regularizada entre todas as partes
        envolvidas, quais sejam, XGROW - EVENTX, Organizadores e Consumidores.
      </p>
      <p>
        11.4. A &nbsp;XGROW - EVENTX reserva o direito de deduzir os valores do
        repasse de vendas ao Organizador e/ou realizar posteriores
        cobran&ccedil;as ao mesmo, podendo ainda reter os valores de repasse de
        vendas de outros eventos criados pelo mesmo Organizador.
      </p>
      <br />
      <h2>12. Contesta&ccedil;&otilde;es, disputas e estornos de compras.</h2>
      <p>
        12.1. As contesta&ccedil;&otilde;es reportadas ser&atilde;o analisadas
        tanto pela operadora de cart&atilde;o de cr&eacute;dito, quanto pelos
        processadores de pagamentos utilizados pela &nbsp;XGROW - EVENTX, ou
        ainda, pela pr&oacute;pria &nbsp;XGROW - EVENTX.
      </p>
      <p>
        12.2. Os estornos ocorridos nas vendas realizadas de quaisquer eventos
        vendidos atrav&eacute;s da plataforma &nbsp;XGROW - EVENTX
        ficar&atilde;o a cargo do Organizador, independentemente da raz&atilde;o
        da contesta&ccedil;&atilde;o.
      </p>
      <p>
        12.2.1. &Eacute; de responsabilidade da &nbsp;XGROW - EVENTX e de nossos
        processadores de pagamentos, tomar as devidas precau&ccedil;&otilde;es
        para minimizar potenciais fraudes nas compras realizadas para quaisquer
        eventos e, assim, minimizar a ocorr&ecirc;ncia de
        contesta&ccedil;&otilde;es.
      </p>
      <p>
        12.2.2. O Organizador, desde j&aacute;, fica ciente que diferentes
        perfis de eventos podem gerar um maior ou menor n&uacute;mero de
        contesta&ccedil;&otilde;es, devendo-se levar em conta tamb&eacute;m a
        abordagem utilizada pelo Organizador para o controle de entrada de
        Participantes.
      </p>
      <p>
        12.3. A &nbsp;XGROW - EVENTX ir&aacute; conduzir as disputas abertas
        pelos Consumidores, tais como, mas n&atilde;o somente,{" "}
        <em>chargebacks </em>(contesta&ccedil;&otilde;es),
        reclama&ccedil;&otilde;es diversas, cancelamento e estornos. Assim,
        ir&aacute; iniciar e processar cancelamentos sem a necessidade da
        interven&ccedil;&atilde;o ou aprova&ccedil;&atilde;o do Organizador, nos
        casos de:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          a) n&atilde;o cumprimento da pol&iacute;tica de reembolsos do evento
          e/ou do C&oacute;digo de Defesa do Consumidor, por parte do
          Organizador;
        </li>
        <li>
          b) detec&ccedil;&atilde;o de ind&iacute;cios de fraude em compras
          realizadas, estejam elas pendentes de aprova&ccedil;&atilde;o ou
          j&aacute; aprovadas, sejam tais riscos provenientes do Consumidor ou
          do Organizador;
        </li>
        <li>c) cancelamento do evento;</li>
        <li>
          d) erro t&eacute;cnico no processamento da transa&ccedil;&atilde;o;
        </li>
        <li>
          e) verificarmos um n&uacute;mero elevado de queixas referentes a um
          determinado evento, por parte dos Consumidores.
        </li>
      </ol>
      <br />
      <h2>13. Recebimento dos valores das vendas e taxa de servi&ccedil;o</h2>
      <p>
        13.1. O Organizador informar&aacute; seus dados para repasse via conta
        banc&aacute;ria. O valor total vendido, descontada a taxa de
        servi&ccedil;o cobrada pela &nbsp;XGROW - EVENTX, ser&aacute; repassado
        no 3&ordm; dia &uacute;til ap&oacute;s o t&eacute;rmino real do evento,
        por meio de transfer&ecirc;ncia banc&aacute;ria, a uma conta indicada
        pelo Organizador na plataforma &nbsp;XGROW - EVENTX.
      </p>
      <p>
        13.2. A &nbsp;XGROW - EVENTX esclarece que o repasse leva em conta a
        data em que o evento ir&aacute; realmente ocorrer, e n&atilde;o a data
        cadastrada na plataforma &nbsp;XGROW - EVENTX. Caso a data indicada para
        o evento na plataforma &nbsp;XGROW - EVENTX n&atilde;o corresponda
        &agrave; data real do evento, o repasse poder&aacute; ser postergado
        at&eacute; o 3&ordm; dia &uacute;til ap&oacute;s a data em que o evento,
        de fato, for finalizado.
      </p>
      <p>
        13.3. Ao cadastrar a conta banc&aacute;ria para repasse, o Organizador
        dever&aacute; indicar como &ldquo;Favorecido&rdquo; o titular do
        cadastro de usu&aacute;rio na plataforma &nbsp;XGROW - EVENTX, nos
        termos do item 5.1, &ldquo;h&rdquo;.
      </p>
      <p>
        13.4. O Organizador dever&aacute; cadastrar os dados banc&aacute;rios
        para repasse dos valores arrecadados at&eacute; a data de
        realiza&ccedil;&atilde;o do evento. O Organizador ser&aacute;
        respons&aacute;vel por qualquer equ&iacute;voco ou atraso no
        cadastramento dos dados banc&aacute;rios para repasse dos valores
        arrecadados.
      </p>
      <p>
        13.5. Em caso de reembolsos pendentes, reclama&ccedil;&otilde;es de
        Consumidores, cancelamento, adiamento, n&atilde;o
        realiza&ccedil;&atilde;o, dificuldade ou impedimento de acesso ao evento
        ou qualquer altera&ccedil;&atilde;o substancial das
        caracter&iacute;sticas do evento, ou ainda, alega&ccedil;&otilde;es de
        fraude, a XGROW - EVENTX, na condi&ccedil;&atilde;o de custodiante dos
        valores arrecadados, se reserva o direito de ret&ecirc;-los pelo tempo
        considerado necess&aacute;rio, visando &agrave; ado&ccedil;&atilde;o das
        medidas cab&iacute;veis.
      </p>
      <p>
        13.5.1. Caso o Organizador n&atilde;o tome as medidas necess&aacute;rias
        para reembolsar os Consumidores, a &nbsp;XGROW - EVENTX se reserva no
        direito de faz&ecirc;-lo, sem necessidade de autoriza&ccedil;&atilde;o
        expressa ou comunica&ccedil;&atilde;o pr&eacute;via.
      </p>
      <p>
        13.5.2. Caso a &nbsp;XGROW - EVENTX necessite fazer quaisquer reembolsos
        de compras, ap&oacute;s o recebimento de valores por parte do
        Organizador, tais reembolsos ser&atilde;o posteriormente cobrados do
        mesmo, podendo, inclusive, ocasionar reten&ccedil;&atilde;o de valores
        de vendas de outros eventos criados pelo mesmo Organizador. &nbsp;
      </p>
      <p>
        13.6. Ser&aacute; cobrada uma taxa de servi&ccedil;o para o uso da
        plataforma &nbsp;XGROW - EVENTX, calculada a partir das vendas online
        processadas atrav&eacute;s dela. A taxa cobrada &eacute; de 10% (dez por
        cento) sobre o valor do ingresso pago, com um valor m&iacute;nimo de
        taxa de R$ 2,50 (dois reais e cinquenta centavos) por ingresso pago.
        N&atilde;o ser&aacute; cobrada taxa de servi&ccedil;o na
        comercializa&ccedil;&atilde;o de ingressos gratuitos.
      </p>
      <br />
      <h2>14. Emiss&atilde;o de nota fiscal</h2>
      <p>
        14.1. Para os eventos em que houver incid&ecirc;ncia de taxa de
        servi&ccedil;o, ser&aacute; emitida uma nota fiscal, no valor referente
        ao total cobrado em taxas pela XGROW - EVENTX, na primeira semana do
        m&ecirc;s seguinte &agrave; data de pagamento do evento em nome do
        Organizador,
      </p>
      <p>
        14.1.1. &Eacute; responsabilidade do Organizador cadastrar na plataforma
        &nbsp;XGROW - EVENTX os dados necess&aacute;rios para emiss&atilde;o da
        nota fiscal em tempo h&aacute;bil.
      </p>
      <p>
        14.2. Os impostos referentes ao montante total vendido pelo Organizador
        do evento atrav&eacute;s da plataforma &nbsp;XGROW - EVENTX devidos
        &agrave; administra&ccedil;&atilde;o p&uacute;blica s&atilde;o de
        responsabilidade exclusiva do Organizador do evento.
      </p>
      <br />
      <h2>15. Direitos de Propriedade Intelectual</h2>
      <p>
        15.1. O uso comercial da express&atilde;o &ldquo;XGROW - EVENTX&rdquo;
        &nbsp;como marca, nome empresarial ou nome de dom&iacute;nio, bem como
        os conte&uacute;dos das telas relativas aos servi&ccedil;os da
        &nbsp;XGROW - EVENTX, assim como os programas, &ldquo;look and
        feel&rdquo; dos sites, bancos de dados, redes, arquivos que permitem ao
        usu&aacute;rio acessar e usar a sua conta, s&atilde;o de titularidade da
        &nbsp;XGROW - EVENTX e est&atilde;o protegidos pelas leis e tratados
        internacionais de direito autoral, marcas, patentes, modelos e desenhos
        industriais. O uso indevido e a reprodu&ccedil;&atilde;o total ou
        parcial dos referidos conte&uacute;dos s&atilde;o proibidos, salvo a
        autoriza&ccedil;&atilde;o pr&eacute;via e expressa por escrito da
        &nbsp;XGROW - EVENTX.
      </p>
      <p>
        15.2. Ao disponibilizar qualquer conte&uacute;do na plataforma
        &nbsp;XGROW - EVENTX, os usu&aacute;rios nos concedem uma licen&ccedil;a
        gratuita, n&atilde;o exclusiva e irrevog&aacute;vel para a
        publica&ccedil;&atilde;o deste conte&uacute;do.
      </p>
      <p>
        15.3. Os sites podem manter links com outros sites, o que n&atilde;o
        significa que esses sites sejam de propriedade ou operados pela
        &nbsp;XGROW - EVENTX, que n&atilde;o possui controle sobre sites de
        terceiros, raz&atilde;o pela qual n&atilde;o ser&aacute;
        respons&aacute;vel pelos seus conte&uacute;dos, pr&aacute;ticas e
        servi&ccedil;os ofertados. A presen&ccedil;a de links para outros sites
        n&atilde;o implica rela&ccedil;&atilde;o de sociedade, de
        supervis&atilde;o, de cumplicidade ou solidariedade da &nbsp;XGROW -
        EVENTX para com esses sites e seus conte&uacute;dos.
      </p>
      <p>
        15.4. O usu&aacute;rio declara e garante que &eacute; titular, possui a
        devida autoriza&ccedil;&atilde;o do(s) titular(es) de direito(s) de
        propriedade intelectual ou que, de outra forma, pode anunciar na
        &nbsp;XGROW - EVENTX, oferecer os servi&ccedil;os anunciados ou,
        declarar-se titular de determinada marca, sendo o &uacute;nico
        respons&aacute;vel pelo conte&uacute;do das suas
        publica&ccedil;&otilde;es.
      </p>
      <p>
        15.5. O usu&aacute;rio assume total responsabilidade por todos os
        preju&iacute;zos, diretos e indiretos, inclusive
        indeniza&ccedil;&atilde;o, lucros cessantes, honor&aacute;rios
        advocat&iacute;cios e demais encargos judiciais e extrajudiciais que
        &nbsp;XGROW - EVENTX seja obrigada a incorrer em virtude de ato ou
        omiss&atilde;o do usu&aacute;rio.
      </p>
      <p>
        15.6. Se a &nbsp;XGROW - EVENTX receber alguma reclama&ccedil;&atilde;o
        ou questionamento de terceiros (por exemplo, dos titulares de marca),
        poder&aacute; remover ou suspender seu evento e aplicar as
        san&ccedil;&otilde;es cab&iacute;veis, nos termos de sua
        &ldquo;Pol&iacute;tica de Propriedade Intelectual&rdquo;.
      </p>
      <br />
      <h2>16. Seguran&ccedil;a da plataforma XGROW - EVENTX</h2>
      <p>
        16.1. N&atilde;o &eacute; permitido o acesso &agrave;s &aacute;reas de
        programa&ccedil;&atilde;o da plataforma &nbsp;XGROW - EVENTX, seu banco
        de dados, ou qualquer outro conjunto de informa&ccedil;&otilde;es que
        fa&ccedil;a parte da atividade de <em>Webmastering</em>.
      </p>
      <p>
        16.2. N&atilde;o est&atilde;o os usu&aacute;rios autorizados a realizar
        ou permitir engenharia reversa, nem traduzir, decompilar, copiar,
        modificar, reproduzir, alugar, sublicenciar, publicar, divulgar,
        transmitir, emprestar, distribuir ou, de outra maneira, dispor das
        ferramentas de consulta da plataforma &nbsp;XGROW - EVENTX e de suas
        funcionalidades.
      </p>
      <p>
        16.3. Na plataforma &nbsp;XGROW - EVENTX &eacute; proibida a
        utiliza&ccedil;&atilde;o de aplicativos <em>spider</em>&nbsp;ou de
        minera&ccedil;&atilde;o de dados, de qualquer tipo ou esp&eacute;cie,
        al&eacute;m de outro aqui n&atilde;o tipificado, mas que atue de modo
        automatizado, tanto para realizar opera&ccedil;&otilde;es massificadas,
        quanto para quaisquer outras finalidades.
      </p>
      <br />
      <h2>17. Privacidade dos usu&aacute;rios</h2>
      <p>
        17.1. A &nbsp;XGROW - EVENTX possui documento pr&oacute;prio, denominado
        &ldquo;Pol&iacute;tica de Privacidade&rdquo;, que regula o tratamento
        dado &agrave;s informa&ccedil;&otilde;es e dados pessoais coletados
        atrav&eacute;s da plataforma &nbsp;XGROW - EVENTX e suas
        funcionalidades.
      </p>
      <p>
        17.1.1. A &ldquo;Pol&iacute;tica de Privacidade&rdquo; &eacute; parte
        integrante e insepar&aacute;vel dos Termos de Uso da plataforma
        &nbsp;XGROW - EVENTX e pode ser acessada em link espec&iacute;fico.
      </p>
      <p>
        17.1.2. Caso alguma disposi&ccedil;&atilde;o da &ldquo;Pol&iacute;tica
        de Privacidade&rdquo; conflitar com qualquer outra do presente
        documento, dever&aacute; prevalecer o descrito na norma mais
        espec&iacute;fica.
      </p>
      <br />
      <h2>
        18. Prote&ccedil;&atilde;o de registros, informa&ccedil;&otilde;es e
        comunica&ccedil;&otilde;es privadas
      </h2>
      <p>
        18.1. A &nbsp;XGROW - EVENTX somente ser&aacute; obrigada a
        disponibilizar registros de acesso, informa&ccedil;&otilde;es pessoais
        ou comunica&ccedil;&otilde;es privadas armazenadas em sua plataforma
        mediante ordem judicial ou requisi&ccedil;&atilde;o de autoridade
        policial ou administrativa competente.
      </p>
      <p>
        18.2. Em todos os casos, a &nbsp;XGROW - EVENTX comunicar&aacute; o
        Organizador da exist&ecirc;ncia do pedido de revela&ccedil;&atilde;o,
        ficando desde j&aacute; autorizada a responder a
        requisi&ccedil;&atilde;o recebida.
      </p>
      <br />
      <h2>19. Limita&ccedil;&atilde;o de responsabilidade</h2>
      <p>
        19.1. FICA EXPRESSAMENTE ESTABELECIDO QUE A &nbsp;XGROW - EVENTX
        N&Atilde;O TEM OBRIGA&Ccedil;&Atilde;O DE CONTROLAR TODAS AS
        A&Ccedil;&Otilde;ES EXECUTADAS PELOS USU&Aacute;RIOS NO USO DA SUA
        PLATAFORMA, POR CONSEGUINTE, N&Atilde;O PODER&Aacute; SER
        RESPONSABILIZADA PELOS ATOS DE SEUS USU&Aacute;RIOS, INCLUSIVE AQUELES
        DE CAR&Aacute;TER IL&Iacute;CITO, IMORAL OU ANTI&Eacute;TICOS,
        PORVENTURA PERPETRADOS, CABENDO &Agrave; ESTES RESPONDEREM PESSOAL E
        EXCLUSIVAMENTE POR EVENTUAIS RECLAMA&Ccedil;&Otilde;ES DE TERCEIROS OU
        DEMANDAS JUDICIAIS, DEVENDO MANTER A XGROW - EVENTX LIVRE DE QUALQUER
        RESPONSABILIDADE OU &Ocirc;NUS.
      </p>
      <p>
        19.2. A &nbsp;XGROW - EVENTX n&atilde;o se responsabiliza por qualquer
        dano, direto ou indireto, ocasionado por eventos de terceiros, como
        ataque de <em>hackers</em>, falhas no sistema, no servidor ou na
        conex&atilde;o &agrave; internet, inclusive por a&ccedil;&otilde;es de
        programas maliciosos como v&iacute;rus, cavalos de tr&oacute;ia, e
        outros que possam, de algum modo, danificar o equipamento ou a
        conex&atilde;o dos usu&aacute;rios em decorr&ecirc;ncia do acesso,
        utiliza&ccedil;&atilde;o ou navega&ccedil;&atilde;o na plataforma
        &nbsp;XGROW - EVENTX, salvo se tal fato decorrer de dolo ou culpa da
        &nbsp;XGROW - EVENTX.
      </p>
      <p>
        19.3. A &nbsp;XGROW - EVENTX n&atilde;o se responsabiliza por eventuais
        grava&ccedil;&otilde;es de &aacute;udio, v&iacute;deo ou quaisquer dados
        transmitidos ou compartilhados durante a realiza&ccedil;&atilde;o de um
        evento online. O Participante &eacute; o &uacute;nico respons&aacute;vel
        pelo cumprimento da legisla&ccedil;&atilde;o aplic&aacute;vel na
        utiliza&ccedil;&atilde;o de alguma funcionalidade ou ferramenta que
        possibilite a grava&ccedil;&atilde;o do conte&uacute;do transmitido pelo
        Organizador.
      </p>
      <br />
      <h2>20. Disposi&ccedil;&otilde;es gerais</h2>
      <p>
        20.1. As negocia&ccedil;&otilde;es feitas pessoalmente pela &nbsp;XGROW
        - EVENTX com os Consumidores ou Organizadores, presencialmente ou a
        dist&acirc;ncia, prevalecem sobre estes Termos de Uso, restando-o como
        fonte subsidi&aacute;ria de interpreta&ccedil;&atilde;o e preenchimento
        de lacunas.
      </p>
      <p>
        20.2. A &nbsp;XGROW - EVENTX n&atilde;o se responsabiliza por qualquer
        dano ou preju&iacute;zo com envio de informa&ccedil;&otilde;es ou de
        conte&uacute;do publicit&aacute;rio que n&atilde;o tenham sido
        disparados pela pr&oacute;pria XGROW - EVENTX, tamb&eacute;m n&atilde;o
        se responsabiliza pelo conte&uacute;do das opini&otilde;es postadas na
        p&aacute;gina dos eventos.
      </p>
      <p>
        20.3. Ao navegar pela plataforma XGROW - EVENTX e utilizar suas
        funcionalidades, os usu&aacute;rios aceitam todo o disposto nos Termos
        de Uso e demais pol&iacute;ticas legais que se encontram vigentes na
        data de acesso. Por isso, &eacute; recomend&aacute;vel que os
        usu&aacute;rios se mantenham atualizados.
      </p>
      <p>
        20.4. Nossos servi&ccedil;os s&atilde;o direcionados para maiores de 18
        (dezoito) anos, com documento pessoal e endere&ccedil;o de e-mail
        v&aacute;lidos, ou ainda, para menores e incapazes, desde que
        devidamente autorizados ou assistidos por seus respons&aacute;veis ou
        representantes legais.
      </p>
      <p>
        20.5. Os presentes Termos de Uso est&atilde;o sujeitos a constante
        melhoria e aprimoramento. Assim, a XGROW - EVENTX se reserva o direito
        de modific&aacute;-los a qualquer momento, conforme a finalidade da
        plataforma XGROW - EVENTX, tal qual para adequa&ccedil;&atilde;o e
        conformidade legal de disposi&ccedil;&atilde;o de lei ou norma que tenha
        for&ccedil;a jur&iacute;dica equivalente, cabendo aos usu&aacute;rios
        verific&aacute;-los sempre que efetuar o acesso &agrave; plataforma
        XGROW - EVENTX.
      </p>
      <p>
        20.6. A eventual toler&acirc;ncia quanto a qualquer
        viola&ccedil;&atilde;o dos termos e condi&ccedil;&otilde;es do contido
        neste dom&iacute;nio ser&aacute; considerada mera liberalidade e
        n&atilde;o ser&aacute; interpretada como nova&ccedil;&atilde;o,
        precedente invoc&aacute;vel, ren&uacute;ncia a direitos,
        altera&ccedil;&atilde;o t&aacute;cita dos termos contratuais, direito
        adquirido ou altera&ccedil;&atilde;o contratual.
      </p>
      <p>
        20.7. Caso alguma disposi&ccedil;&atilde;o destes Termos de Uso for
        julgada inaplic&aacute;vel ou sem efeito, o restante das normas
        continuam a viger, sem a necessidade de medida judicial que declare tal
        assertiva. Os Termos aqui descritos ser&atilde;o interpretados segundo a
        legisla&ccedil;&atilde;o brasileira.
      </p>
      <p>
        20.8. A comunica&ccedil;&atilde;o entre a XGROW - EVENTX e o
        usu&aacute;rio dever&aacute; ser realizada pelos canais de atendimento
        indicados e disponibilizados na plataforma XGROW - EVENTX.
      </p>
      <p>
        20.9. Sempre que poss&iacute;vel, potenciais diverg&ecirc;ncias entre o
        usu&aacute;rio e a XGROW - EVENTX ser&atilde;o resolvidas de forma
        amig&aacute;vel. Quando todos os esfor&ccedil;os neste sentido forem
        esgotados, voc&ecirc; concorda, desde j&aacute;, que fica eleito o foro
        da comarca de Barueri/ S&atilde;o Paulo, para resolver
        controv&eacute;rsias ou queixas oriundas da utiliza&ccedil;&atilde;o de
        nossa plataforma ou relacionadas a estes Termos de Uso.
      </p>
    </>
  );
};

export default OrganizerTerms;
