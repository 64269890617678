import { BiChevronLeft } from "react-icons/bi";

import { useIntegrations } from "contexts/IntegrationsContext";

import { Colors } from "constants/colors";

import { Container, ContainerItems } from "./styles";

export function HeaderIntegration() {
  const {
    handleChangeActiveIntegrations,
    handleChangeNewIntegration,
    handleChangeEditIntegration,
    activeIntegrations,
  } = useIntegrations();

  const handleCloseIntegrations = () => {
    handleChangeActiveIntegrations(false);
    handleChangeNewIntegration(false);
    handleChangeEditIntegration(false);
  };

  return (
    <Container>
      <ContainerItems>
        <div className="header-items">
          <BiChevronLeft
            onClick={handleCloseIntegrations}
            size={24}
            color={Colors.white}
          />

          <h3 className="item">
            {activeIntegrations ? "Nova integração" : "Configurar integração"}
          </h3>
          <div></div>
        </div>
      </ContainerItems>
    </Container>
  );
}
