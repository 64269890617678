import React, { useRef, useEffect, useState } from "react";

import { RiSearch2Line } from "react-icons/ri";

import api from "services/api";

import { ActionButton, SearchContainer } from "./styles";

interface Props {
  handleSearching: (search: boolean) => void;
  handleSearchData: (search: any) => void;
  handleLoading: (loading: boolean) => void;
}

const SearchSelect: React.FC<Props> = ({
  handleSearching,
  handleSearchData,
  handleLoading,
}) => {
  const [search, setSearch] = useState<any>();
  const [questions, setQuestions] = useState<any>();
  const searchInput = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleValues = async () => {
    try {
      const response = await api.get("/faq");
      const responseData = response;
      setQuestions(responseData.data.data);
    } catch (err) {}
  };

  const handleSearch = async (event: any) => {
    setTimeout(() => {}, 200);
    setSearch(event.target.value.toLowerCase());
    handleLoading(true);
    if (search?.length > 0) {
      let results_title = questions?.filter(
        (questions: any) =>
          questions?.title.toLowerCase().indexOf(search) !== -1
      );
      let results_description = questions?.filter(
        (questions: any) =>
          questions?.description.toLowerCase().indexOf(search) !== -1
      );
      let results = [...results_title, ...results_description];
      handleSearchData(results);
    } else {
      let results = false;
      handleSearchData(results);
    }
    handleSearching(true);
    handleLoading(false);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch(e);
    }
  };
  useEffect(() => {
    handleValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SearchContainer>
      <label htmlFor="search-input">
        <RiSearch2Line />
        <input
          onKeyDown={_handleKeyDown}
          ref={searchInput}
          onChange={handleSearch}
          placeholder="Pesquise por perguntas ou tópicos..."
          type="search"
          name="search-input"
          id="search-input"
        />
      </label>
      <ActionButton onClick={handleSearch}>Pesquisar</ActionButton>
    </SearchContainer>
  );
};

export default SearchSelect;
