import React from "react";

const UserTerms: React.FC = () => {
  return (
    <>
      <h2>Introdu&ccedil;&atilde;o</h2>
      <p>Ol&aacute; Consumidor!</p>
      <p>
        Seja bem-vindo &agrave; Plataforma XGROW - EVENTX um produto exclusivo
        da XGROW!
      </p>
      <p>
        Os Termos de Uso a seguir s&atilde;o baseados em nossos
        princ&iacute;pios e valores, e t&ecirc;m o objetivo de reger nosso
        relacionamento. Como condi&ccedil;&atilde;o para acesso e
        utiliza&ccedil;&atilde;o da plataforma XGROW - EVENTX e suas
        funcionalidades, voc&ecirc; declara que realizou a leitura completa e
        atenta das regras deste documento, estando plenamente ciente e de acordo
        com elas.
      </p>
      <br />
      <h2>Resumo</h2>
      <p>
        Sabemos que seu tempo &eacute; valioso e, por isso, elencamos abaixo os
        principais t&oacute;picos que, em nossa opini&atilde;o, s&atilde;o
        absolutamente indispens&aacute;veis para o uso da nossa plataforma.
        Por&eacute;m, n&atilde;o deixe de ler o documento por completo,
        combinado?
      </p>
      <br />
      <p>
        A XGROW - EVENTX &eacute; uma plataforma que facilita a venda de
        ingressos, inscri&ccedil;&atilde;o para eventos, cobran&ccedil;a de
        contribui&ccedil;&otilde;es para eventos e a gest&atilde;o de
        participantes.
      </p>
      <p>
        Todos os eventos publicados na plataforma est&atilde;o sob
        responsabilidade de seus organizadores, uma vez que a XGROW - EVENTX
        n&atilde;o produz tais eventos.
      </p>
      <p>
        &Eacute; de inteira responsabilidade dos Organizadores definirem as
        quantidades e valores dos ingressos disponibilizados para venda, regras
        para acesso aos eventos, pol&iacute;ticas de reembolso, troca de
        titularidade, cumprimento da legisla&ccedil;&atilde;o de meia-entrada,
        etc;
      </p>
      <p>
        A XGROW - EVENTX n&atilde;o se responsabiliza por ingressos adquiridos
        fora da sua plataforma;
      </p>
      <p>
        Todas as compras realizadas na plataforma XGROW - EVENTX passam por um
        processo de an&aacute;lise, seja para confer&ecirc;ncia dos dados
        preenchidos durante a compra (cart&atilde;o de cr&eacute;dito) ou para o
        recebimento da confirma&ccedil;&atilde;o de pagamento (boleto
        banc&aacute;rio). Se alguma inconsist&ecirc;ncia for encontrada ou se o
        pagamento n&atilde;o for confirmado, a compra ficar&aacute;
        automaticamente cancelada;
      </p>
      <p>
        A pol&iacute;tica de troca de titularidade &eacute; definida pelo
        Organizador do evento, podendo essa op&ccedil;&atilde;o ser
        disponibilizada ou n&atilde;o. Cabe ao Consumidor informar-se sobre a
        pol&iacute;tica espec&iacute;fica do evento que deseja participar;
      </p>
      <p>
        Caso o Participante receba um ingresso via troca de titularidade, fique
        ciente de que o Consumidor, titular da compra, poder&aacute;, a qualquer
        momento, e desde que autorizado pelo Organizador, cancelar a compra ou
        efetuar uma nova troca de titularidade, inviabilizando seu acesso ao
        evento;
      </p>
      <p>
        A pol&iacute;tica de reembolso &eacute; definida pelo Organizador do
        evento. Cabe a voc&ecirc; informar-se sobre a pol&iacute;tica
        espec&iacute;fica do evento que deseja participar e, caso
        necess&aacute;rio, entrar em contato com o Organizador para solicitar o
        cancelamento de sua compra e o consequente reembolso do ingresso.
      </p>
      <p>
        Os ingressos, inscri&ccedil;&otilde;es, ou contribui&ccedil;&otilde;es
        comprados atrav&eacute;s da plataforma XGROW - EVENTX ser&atilde;o
        enviados ao e-mail indicado por voc&ecirc; t&atilde;o logo o pagamento
        seja aprovado;
      </p>
      <p>
        Para receber nossas mensagens corretamente, voc&ecirc; concorda em
        verificar as configura&ccedil;&otilde;es do sistema anti-spam, ou
        similares, na sua caixa de e-mails. O n&atilde;o recebimento do ingresso
        no e-mail, causado por erros nas configura&ccedil;&otilde;es citadas
        acima, n&atilde;o gerar&aacute; qualquer tipo de expectativa de direito
        ou indeniza&ccedil;&atilde;o;
      </p>
      <br />
      <h2>Vers&atilde;o completa</h2>
      <br />
      <h1>1.Gloss&aacute;rio</h1>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <u>Anti-Spam</u>
          <em>:</em>sistema que evita correspond&ecirc;ncias n&atilde;o
          desejadas, como publicidade em massa, pelo bloqueio de mensagens ou as
          movendo para pasta espec&iacute;fica.
        </li>
        <li>
          <u>Chargeback</u>
          <em>:</em>cancelamento de uma compra online realizada atrav&eacute;s
          de cart&atilde;o de d&eacute;bito ou cr&eacute;dito, que pode
          acontecer em virtude do n&atilde;o reconhecimento da compra pelo
          titular do cart&atilde;o ou ainda pelo fato de a
          transa&ccedil;&atilde;o n&atilde;o obedecer &agrave;s
          regulamenta&ccedil;&otilde;es previstas nos contratos, termos,
          aditivos e manuais editados pelas administradoras de cart&atilde;o.
        </li>
        <li>
          <u>Consumidor</u>
          <em>:</em>termo utilizado para designar pessoas que adquirem
          ingressos, pagos ou gratuitos, para os eventos criados pelos
          Organizadores.
        </li>
        <li>
          <u>Contesta&ccedil;&atilde;o</u>
          <em>:</em>reclama&ccedil;&atilde;o de cobran&ccedil;a indevida,
          solicitada pelo titular do cart&atilde;o de cr&eacute;dito junto
          &agrave; operadora de seu cart&atilde;o, podendo ser iniciada por
          diversas raz&otilde;es, tais como o esquecimento de que a compra foi
          realizada, a utiliza&ccedil;&atilde;o do cart&atilde;o por outros
          membros da fam&iacute;lia, ou ainda, resultado de uma compra
          fraudulenta realizada por terceiros.
        </li>
        <li>
          <u>Crawler/Spider</u>
          <em>:</em>programas desenvolvidos para atuar ou obter
          informa&ccedil;&otilde;es de modo automatizado em sites.
        </li>
        <li>
          <u>Estorno</u>
          <em>:</em>a&ccedil;&atilde;o de estornar cr&eacute;dito ou
          d&eacute;bito indevidamente lan&ccedil;ado.
        </li>
        <li>
          <u>Empresas Homologadas</u>
          <em>:</em>Empresas que possuem tecnologia de integra&ccedil;&atilde;o
          reconhecida pelo time de produto da XGROW - EVENTX, conhecem e seguem
          nossas melhores pr&aacute;ticas operacionais, tem compromisso com a
          Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais e possuem
          acompanhamento recorrente do n&iacute;vel de servi&ccedil;o prestado.
        </li>
        <li>
          <u>Link</u>
          <em>:</em>terminologia para endere&ccedil;o de internet.
        </li>
        <li>
          <u>Look and feel</u>
          <em>:</em>conceito visual geral de uma obra protegida por direito de
          propriedade intelectual.
        </li>
        <li>
          <u>Organizador:</u>termo utilizado para designar pessoas que criam
          eventos atrav&eacute;s da Plataforma XGROW - EVENTX e os divulgam para
          consumidores.
        </li>
        <li>
          <u>Participantes</u>
          <em>:</em>termo utilizado para designar pessoas que usufruem dos
          ingressos adquiridos pelos Consumidores. Caso os Consumidores adquiram
          ingressos em nome pr&oacute;prio ser&atilde;o tamb&eacute;m
          Participantes. Por outro lado, caso o Consumidor adquira ingressos em
          proveito de terceiros, estes ser&atilde;o considerados Participantes,
          e n&atilde;o Consumidores.
        </li>
        <li>
          <u>Plataforma XGROW - EVENTX</u>
          <em>:</em>designa a plataforma tecnol&oacute;gica dispon&iacute;vel
          para Organizadores e casas de eventos, facilitando a venda de
          ingressos, inscri&ccedil;&atilde;o para eventos, cobran&ccedil;a de
          contribui&ccedil;&otilde;es para eventos e a gest&atilde;o de
          participantes.
        </li>
        <li>
          <u>Usu&aacute;rio</u>
          <em>:</em>termo utilizado para designar, quando referidos em conjunto,
          organizadores, consumidores e pessoas que navegam na Plataforma XGROW
          - EVENTX.
        </li>
        <li>
          <u>Webmastering</u>
          <em>:</em>compreende a cria&ccedil;&atilde;o,
          programa&ccedil;&atilde;o, desenvolvimento, controle e disponibilidade
          de p&aacute;ginas de internet.
        </li>
      </ul>
      <br />
      <h2>2. Informa&ccedil;&otilde;es Gerais</h2>
      <p>
        2.1. Os presentes Termos regulam os servi&ccedil;os oferecidos na
        plataforma XGROW - EVENTX, que consistem em:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          disponibilizar uma solu&ccedil;&atilde;o tecnol&oacute;gica
          (plataforma XGROW - EVENTX) para que Organizadores possam criar,
          organizar, gerir e divulgar seus pr&oacute;prios eventos;
        </li>
        <li>
          viabilizar, por meio da plataforma XGROW - EVENTX, as
          opera&ccedil;&otilde;es de venda de ingressos cadastrados por
          Organizadores;
        </li>
      </ol>
      <ul style={{ marginLeft: "3em" }}>
        <li>hospedar os eventos criados pelos Organizadores.</li>
      </ul>
      <p>
        2.2. Os eventos criados e divulgados atrav&eacute;s da plataforma XGROW
        - EVENTX s&atilde;o de <strong>responsabilidade exclusiva</strong>
        &nbsp;dos pr&oacute;prios Organizadores, os quais s&atilde;o os
        &uacute;nicos respons&aacute;veis por estabelecer as
        condi&ccedil;&otilde;es de venda, realiza&ccedil;&atilde;o e
        cancelamento dos eventos. A XGROW - EVENTX n&atilde;o &eacute; criadora,
        organizadora ou propriet&aacute;ria dos eventos hospedados em sua
        plataforma, por&eacute;m poder&aacute; monitorar os eventos criados, a
        fim de que estes cumpram suas pol&iacute;ticas legais.
      </p>
      <p>
        2.3. A plataforma XGROW - EVENTX e seus funcionalidades s&atilde;o
        apresentadas aos usu&aacute;rios da maneira como est&atilde;o
        dispon&iacute;veis, podendo passar por constantes aprimoramentos e
        atualiza&ccedil;&otilde;es, obrigando-se a XGROW - EVENTX a:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          a) Preservar as funcionalidades da plataforma XGROW - EVENTX, com
          links n&atilde;o-quebrados, utilizando-se de layouts que respeitem e
          privilegiam a usabilidade e navegabilidade;
        </li>
        <li>
          b) Exibir as funcionalidades de maneira clara, completa, precisa e
          suficiente, de modo que possibilite a exata percep&ccedil;&atilde;o
          das opera&ccedil;&otilde;es realizadas.
        </li>
      </ol>
      <p>
        2.4. A XGROW - EVENTX envida seus esfor&ccedil;os para a disponibilidade
        cont&iacute;nua e permanente de sua plataforma. No entanto,
        poder&aacute; ocorrer, eventualmente, alguma indisponibilidade
        tempor&aacute;ria decorrente de manuten&ccedil;&atilde;o
        necess&aacute;ria ou mesmo gerada por motivo de for&ccedil;a maior, como
        desastres naturais, falhas ou colapsos nos sistemas centrais de
        comunica&ccedil;&atilde;o e acesso &agrave; internet ou, fatos de
        terceiro que fogem de sua esfera de vigil&acirc;ncia e responsabilidade.
      </p>
      <p>
        2.4.1. Em caso de indisponibilidade, a XGROW - EVENTX far&aacute; tudo
        que estiver ao seu alcance para restabelecer, o mais breve
        poss&iacute;vel, o acesso &agrave; plataforma, dentro das
        limita&ccedil;&otilde;es t&eacute;cnicas de seus servi&ccedil;os
        pr&oacute;prios e dos de terceiros.
      </p>
      <p>
        2.4.2. O Consumidor isenta a XGROW - EVENTX de qualquer dano nos casos
        de paralisa&ccedil;&otilde;es, parciais ou totais, dos seus
        servi&ccedil;os, decorrentes de falta tempor&aacute;ria de energia
        el&eacute;trica, de pane nos servi&ccedil;os das concession&aacute;rias,
        de falhas nos servi&ccedil;os oferecidos pelas redes telef&ocirc;nicas e
        outros provedores, inclusive provedores de hospedagem, de greves,
        tumultos ou quaisquer outros fatos que n&atilde;o estejam diretamente
        ligados ao servi&ccedil;o. A XGROW - EVENTX tamb&eacute;m n&atilde;o
        ser&aacute; respons&aacute;vel por qualquer dano causado por motivo de
        caso fortuito ou for&ccedil;a maior, bem como fato de terceiro.
      </p>
      <p>
        2.5. Eventuais procedimentos de manuten&ccedil;&atilde;o
        necess&aacute;ria da plataforma XGROW - EVENTX ser&atilde;o informados,
        sempre que poss&iacute;vel, com a devida anteced&ecirc;ncia e,
        atrav&eacute;s dos canais oficiais de comunica&ccedil;&atilde;o da XGROW
        - EVENTX.
      </p>
      <br />
      <h2>3. Cadastro de usu&aacute;rios</h2>
      <p>
        3.1. O cadastro na plataforma XGROW - EVENTX &eacute; gratuito, no
        entanto, os servi&ccedil;os oferecidos ser&atilde;o cobrados de acordo
        com o estabelecido pela XGROW - EVENTX em seus Termos.
      </p>
      <p>
        3.1.2. Ao se cadastrar, o usu&aacute;rio se obriga a utilizar a
        plataforma XGROW - EVENTX de maneira &eacute;tica e adequada aos
        prop&oacute;sitos estabelecidos nestes Termos, atendendo ao disposto nas
        leis brasileiras, em especial a Lei n&ordm; 9.613 de 1998 (&ldquo;Lei de
        Lavagem de Dinheiro&rdquo;), comprometendo-se a se abster de qualquer
        atividade que constitua viola&ccedil;&atilde;o das referidas normas.
      </p>
      <p>
        3.2. Os usu&aacute;rios devem fornecer informa&ccedil;&otilde;es exatas
        e verdadeiras, assumindo o compromisso de atualizar seus dados pessoais
        e dados de identifica&ccedil;&atilde;o da pessoa, f&iacute;sica ou
        jur&iacute;dica, respons&aacute;vel pelo evento, perante a XGROW -
        EVENTX e terceiros, sempre que necess&aacute;rio ou quando requerido.
      </p>
      <p>
        3.2.1. Se tivermos motivos que indiquem que quaisquer
        informa&ccedil;&otilde;es cadastradas n&atilde;o s&atilde;o verdadeiras,
        teremos o direito de suspender ou encerrar a conta do usu&aacute;rio,
        bem como nos recusar a prover quaisquer de nossos servi&ccedil;os.
      </p>
      <p>
        3.2.2. A XGROW - EVENTX n&atilde;o se responsabiliza pela veracidade ou
        assertividade dos dados inseridos no cadastro, sendo que a
        cria&ccedil;&atilde;o de perfis falsos na plataforma XGROW - EVENTX
        poder&aacute; ser considerado crime de falsa identidade, falsidade
        ideol&oacute;gica ou estelionato.
      </p>
      <p>
        3.3. Caso a XGROW - EVENTX verifique a exist&ecirc;ncia de atividade
        suspeita, ou transa&ccedil;&atilde;o que apresente consider&aacute;vel
        risco de fraude, poder&aacute;, a qualquer tempo e a seu exclusivo
        crit&eacute;rio, confirmar a identidade e os dados pessoais do
        usu&aacute;rio, podendo, assim, solicitar documentos pessoais ou outras
        formas de comprova&ccedil;&atilde;o, por telefone, e-mail ou outro meio
        h&aacute;bil.
      </p>
      <p>
        3.3.1. Qualquer erro ou atraso no processo de envio ou
        confirma&ccedil;&atilde;o da identidade do usu&aacute;rio que gere
        preju&iacute;zo ou dano de qualquer natureza ser&aacute; de
        responsabilidade do usu&aacute;rio.
      </p>
      <p>
        3.4. Sem preju&iacute;zo de outras medidas, a XGROW - EVENTX
        poder&aacute; advertir, moderar, suspender ou cancelar o cadastro dos
        usu&aacute;rios, bem como os eventos associados, negando-se a prestar os
        servi&ccedil;os descritos nestes Termos, caso o usu&aacute;rio:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          a) Descumpra com qualquer disposi&ccedil;&atilde;o destes Termos ou
          qualquer outra pol&iacute;tica da XGROW - EVENTX;
        </li>
        <li>b) Pratique atos fraudulentos ou dolosos;</li>
        <li>
          c) D&ecirc; causa a qualquer tipo de dano ou preju&iacute;zo a
          terceiro ou &agrave; pr&oacute;pria XGROW - EVENTX, na
          utiliza&ccedil;&atilde;o da plataforma XGROW - EVENTX.
        </li>
      </ol>
      <br />
      <h2>4. Acesso &agrave; Plataforma XGROW - EVENTX</h2>
      <p>
        4.1. &nbsp;Os usu&aacute;rios acessar&atilde;o sua conta atrav&eacute;s
        de login e senha pessoais e intransfer&iacute;veis, ou atrav&eacute;s de
        contas pessoais providas por terceiros, como Facebook. De qualquer
        maneira, os usu&aacute;rios se comprometem a n&atilde;o informar a
        terceiros tais dados, responsabilizando-se integralmente pelo uso que
        deles seja feito, se comprometendo a notificar a XGROW - EVENTX,
        imediatamente, acerca de quaisquer usos n&atilde;o autorizados de sua
        conta ou quaisquer outras viola&ccedil;&otilde;es de seguran&ccedil;a. A
        XGROW - EVENTX n&atilde;o ser&aacute; respons&aacute;vel por quaisquer
        perdas e danos resultantes de acessos n&atilde;o autorizados ou uso da
        conta.
      </p>
      <p>
        4.2. Apenas o titular da conta na XGROW - EVENTX poder&aacute; ter
        acesso aos dados relativos &agrave; mesma, tendo em vista seu
        car&aacute;ter pessoal. Eventuais altera&ccedil;&otilde;es em dados
        cadastrados somente poder&atilde;o ser feitas pelo pr&oacute;prio
        usu&aacute;rio, devidamente <em>logado</em>. A XGROW - EVENTX n&atilde;o
        tem prerrogativa de fazer esse tipo de altera&ccedil;&atilde;o,
        independentemente da raz&atilde;o alegada ou circunst&acirc;ncia.
      </p>
      <br />
      <h2>5. Responsabilidades e obriga&ccedil;&otilde;es dos Consumidores</h2>
      <p>
        5.1. Os Consumidores se obrigam a utilizar a plataforma XGROW - EVENTX
        de maneira &eacute;tica e adequada aos prop&oacute;sitos estabelecidos
        nestes Termos, atendendo ao disposto nas leis brasileiras,
        comprometendo-se a se absterem de qualquer atividade que constitua
        viola&ccedil;&atilde;o das referidas normas.
      </p>
      <p>
        5.2. Ao utilizarem a plataforma XGROW - EVENTX e suas funcionalidade, os
        Consumidores se obrigam a:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          a) Inserir dados corretos, completos e atualizados na
          aquisi&ccedil;&atilde;o (onerosa ou gratuita) dos ingressos. Caso
          contr&aacute;rio, a aquisi&ccedil;&atilde;o poder&aacute; ser
          invalidada e o Consumidor, caso queira, ter&aacute; que fazer uma nova
          aquisi&ccedil;&atilde;o, estando sujeito &agrave;s
          condi&ccedil;&otilde;es e disponibilidade de ingressos,
          inscri&ccedil;&otilde;es ou contribui&ccedil;&otilde;es nesse segundo
          momento;
        </li>
        <li>
          b) Arcar com os valores cobrados na fatura de cart&atilde;o de
          cr&eacute;dito, caso essa seja a forma de pagamento escolhida pelo
          consumidor. A cobran&ccedil;a poder&aacute; vir em nome da XGROW -
          EVENTX, ou algum de nossos processadores de pagamentos;
        </li>
        <li>
          c) Arcar com o valor total e integral do boleto e pag&aacute;-lo
          at&eacute; a data do seu vencimento, caso o Consumidor tenha optado
          por esse m&eacute;todo de pagamento. Ap&oacute;s a data limite de
          pagamento, o boleto deixa de ser v&aacute;lido para a compra e o
          ingresso n&atilde;o estar&aacute; mais reservado para o consumidor.
        </li>
        <li>
          d) Comprar os ingressos apenas atrav&eacute;s da plataforma XGROW -
          EVENTX, ou em pontos de venda autorizados. A XGROW - EVENTX n&atilde;o
          se responsabiliza e n&atilde;o atesta a veracidade ou confiabilidade
          de ingressos adquiridos de terceiros, outros sites ou qualquer ponto
          de venda n&atilde;o autorizado, ficando o Consumidor ciente que ao
          fazer tal aquisi&ccedil;&atilde;o estar&aacute; correndo o risco de
          ser v&iacute;tima de esquema de fraude ou engenharia social;
        </li>
        <li>
          e) Participar do processo de apura&ccedil;&atilde;o de eventuais
          fraudes nas transa&ccedil;&otilde;es efetuadas, realizado por meio de
          sistema de an&aacute;lise de risco da XGROW - EVENTX e de seus
          parceiros. A an&aacute;lise de risco da transa&ccedil;&atilde;o
          &eacute; feita com base em padr&otilde;es hist&oacute;ricos,
          confer&ecirc;ncia dos dados cadastrais e bases externas, podendo
          resultar numa probabilidade de que determinada transa&ccedil;&atilde;o
          seja fraudulenta. Feita a avalia&ccedil;&atilde;o de risco
          transacional, a compra poder&aacute; ser aprovada ou n&atilde;o,
          segundo crit&eacute;rios pr&eacute;-estabelecidos. A n&atilde;o
          aprova&ccedil;&atilde;o n&atilde;o gerar&aacute; expectativa de
          direito, tampouco indeniza&ccedil;&atilde;o de qualquer
          esp&eacute;cie;
        </li>
        <li>
          f) Fornecer todas as informa&ccedil;&otilde;es e documentos que forem
          solicitados atrav&eacute;s dos canais oficiais da XGROW - EVENTX, caso
          seja necess&aacute;ria alguma valida&ccedil;&atilde;o da compra por
          parte da XGROW - EVENTX ou de nossos processadores de pagamentos.
        </li>
        <li>
          g) Apresentar, sempre que requerido, na portaria do evento, um
          documento de identidade com a foto do titular do cart&atilde;o e/ou o
          cart&atilde;o utilizado na compra, caso tenha adquirido ingressos via
          cart&atilde;o de cr&eacute;dito, sob pena de ter o direito de acesso
          ao evento negado, sem que tal fato gere direito a
          indeniza&ccedil;&atilde;o de qualquer esp&eacute;cie;
        </li>
        <li>
          h) Promover a seguran&ccedil;a do ingresso a partir do momento em que
          o receber, n&atilde;o divulgando-o publicamente, sobretudo em redes
          sociais, ou permitindo o acesso de terceiros a ele;
        </li>
        <li>
          i) Arcar com a taxa eventualmente cobrada pela processadora de
          pagamentos, para fins de saque do cr&eacute;dito decorrente do
          estorno, no caso de cancelamento da compra por parte do Consumidor;
        </li>
        <li>
          j) Isentar a XGROW - EVENTX de qualquer responsabilidade sobre a
          realiza&ccedil;&atilde;o dos eventos para os quais foram adquiridos
          ingressos. A XGROW - EVENTX n&atilde;o &eacute; Organizadora de
          eventos. Fornecemos a plataforma para que os Organizadores vendam seus
          ingressos e gerenciem seus Participantes.
        </li>
      </ol>
      <p>
        5.3. Em todos os casos, a XGROW - EVENTX, a seu exclusivo
        crit&eacute;rio, poder&aacute; ainda se recusar a prestar quaisquer
        servi&ccedil;os, independentemente da motiva&ccedil;&atilde;o, sem que
        isso constitua limita&ccedil;&atilde;o de direitos.
      </p>
      <br />
      <h2>6. Entrega e Utiliza&ccedil;&atilde;o de Ingressos</h2>
      <p>
        6.1. Os ingressos comprados atrav&eacute;s da plataforma XGROW - EVENTX
        ser&atilde;o enviados ao e-mail indicado pelo Consumidor t&atilde;o logo
        o pagamento seja aprovado. Pagamentos aprovados em tempo real habilitam
        que os ingressos tamb&eacute;m sejam baixados na pr&oacute;pria tela de
        confirma&ccedil;&atilde;o da compra.
      </p>
      <p>
        6.1.1. Cabe ao Consumidor deixar seus sistemas de <em>anti-spam</em>,
        filtros ou similares, de modo que n&atilde;o interfiram no recebimento
        dos comunicados e materiais da XGROW - EVENTX, n&atilde;o sendo
        aceit&aacute;vel nenhuma escusa caso n&atilde;o tenha tido acesso a
        algum e-mail ou mensagem eletr&ocirc;nica em virtude dos recursos
        mencionados, o que n&atilde;o gerar&aacute; qualquer tipo de expectativa
        de direito ou indeniza&ccedil;&atilde;o ao Consumidor.
      </p>
      <p>
        6.2. Al&eacute;m da entrega por meio de mensagem eletr&ocirc;nica, os
        ingressos tamb&eacute;m poder&atilde;o ser acessados na &aacute;rea
        autenticada (<em>logada</em>) do site. Para isso, o Consumidor deve
        criar um usu&aacute;rio, gratuitamente, na plataforma XGROW - EVENTX,
        fornecendo o mesmo e-mail utilizado no ato da compra.
      </p>
      <p>
        6.3. Os ingressos s&atilde;o associados a um c&oacute;digo
        alfanum&eacute;rico &uacute;nico, representado em algarismos, &ldquo;QR
        Code&rdquo; e C&oacute;digo de Barras (exclusivamente no formato PDF).
        Ap&oacute;s o recebimento, caber&aacute; ao Consumidor mant&ecirc;-los
        em seguran&ccedil;a, n&atilde;o divulg&aacute;-los publicamente,
        sobretudo em redes sociais, ou permitindo o acesso de terceiros a ele.
      </p>
      <p>
        6.4. Os ingressos comprados atrav&eacute;s da plataforma XGROW - EVENTX
        s&atilde;o nominais, cabendo ao Organizador decidir pela
        confer&ecirc;ncia dessa informa&ccedil;&atilde;o no momento de acesso ao
        evento. A n&atilde;o confer&ecirc;ncia dessa informa&ccedil;&atilde;o
        n&atilde;o gerar&aacute; qualquer tipo de expectativa de direito ou
        indeniza&ccedil;&atilde;o.
      </p>
      <p>
        6.5. Os ingressos adquiridos na plataforma XGROW - EVENTX podem ser
        usados para acesso ao evento uma &uacute;nica vez. O
        <em>&nbsp;check-in </em>do Participante, ou seja, a leitura e
        valida&ccedil;&atilde;o do ingresso do Consumidor, atrav&eacute;s do
        identificador &uacute;nico, &eacute; de responsabilidade do Organizador.
        O acesso do Participante ao evento n&atilde;o ser&aacute; liberado caso
        o <em>check-in</em>&nbsp;do ingresso j&aacute; tenha sido realizado
        anteriormente, salvo por decis&atilde;o exclusiva do Organizador.
      </p>
      <br />
      <h2>7. Vendas de Ingressos</h2>
      <p>
        7.1. O processamento dos pagamentos das vendas de ingressos para eventos
        inicia-se, geralmente, na p&aacute;gina do evento publicado pelo
        Organizador. O consumidor escolher&aacute; o tipo de ingresso desejado,
        bem como o m&eacute;todo de pagamento, considerando as
        op&ccedil;&otilde;es dispon&iacute;veis, e em seguida, finalizar&aacute;
        o pedido.
      </p>
      <p>
        7.1.1. Ap&oacute;s a confirma&ccedil;&atilde;o de
        aprova&ccedil;&atilde;o do pagamento, que poder&aacute; ser feita por
        intermedi&aacute;rio financeiro, a XGROW - EVENTX recolher&aacute; o
        valor correspondente, deduzir&aacute; a taxa de servi&ccedil;o e
        repassar&aacute; ao Organizador o valor restante, de acordo com o modelo
        de recebimento escolhido. Todas as transa&ccedil;&otilde;es realizadas
        atrav&eacute;s da XGROW - EVENTX passam por processos internos,
        automatizados, de an&aacute;lise de risco que poder&atilde;o ser
        complementados pelos nossos processadores de pagamentos.
      </p>
      <p>
        7.1.2. Por seguran&ccedil;a, as compras realizadas utilizando
        cart&atilde;o de cr&eacute;dito que necessitem de
        confirma&ccedil;&atilde;o ou valida&ccedil;&atilde;o adicional
        poder&atilde;o passar pelo processo de an&aacute;lise aprofundada. Neste
        procedimento, a XGROW - EVENTX poder&aacute; requerer &nbsp;o envio de
        documentos pessoais para verifica&ccedil;&atilde;o da identidade do
        usu&aacute;rio.
      </p>
      <p>
        7.1.3. Caso a XGROW - EVENTX identifique alguma inconsist&ecirc;ncia no
        procedimento de pagamento, a compra poder&aacute; ser submetida a um
        novo processo de verifica&ccedil;&atilde;o, a qualquer momento. O
        Consumidor ser&aacute; notificado por e-mail, no mesmo endere&ccedil;o
        associado a compra, caso o pedido seja suspenso ou cancelado pela XGROW
        - EVENTX.
      </p>
      <br />
      <h2>8. Cancelamento e reembolso de transa&ccedil;&otilde;es</h2>
      <p>
        8.1. A plataforma XGROW - EVENTX permite que o Organizador solicite
        &agrave; XGROW - EVENTX, at&eacute; o 2&ordm; dia &uacute;til
        ap&oacute;s o t&eacute;rmino do evento, o cancelamento de uma venda e o
        consequente reembolso do valor do ingresso para o Consumidor, descontada
        a taxa de servi&ccedil;o. &Eacute; de responsabilidade do Organizador
        comunicar a seus potenciais Consumidores e Participantes sua
        pr&oacute;pria pol&iacute;tica de cancelamento, que dever&aacute; ser
        compat&iacute;vel com o per&iacute;odo m&aacute;ximo de reembolso
        permitido pela XGROW - EVENTX e solicitar, quando considerar
        necess&aacute;rio, o cancelamento da transa&ccedil;&atilde;o
        atrav&eacute;s das ferramentas presentes na plataforma XGROW - EVENTX.
      </p>
      <p>
        8.1.1. &Eacute; de responsabilidade do Consumidor informar-se sobre a
        pol&iacute;tica espec&iacute;fica do evento que deseja participar e,
        caso necess&aacute;rio, entrar em contato com o Organizador para
        solicitar o cancelamento de sua compra e o consequente reembolso do
        valor do ingresso.
      </p>
      <p>
        8.1.2. O Participante n&atilde;o poder&aacute; solicitar reembolso de
        seus ingressos, o que poder&aacute; ser feito t&atilde;o somente pelo
        Consumidor (pessoa que realizou a compra na plataforma XGROW - EVENTX),
        salvo quando Participante e Consumidor forem a mesma pessoa.
      </p>
      <p>
        8.2. Caso seja necess&aacute;rio reembolsar os Consumidores, o
        Organizador dever&aacute;, necessariamente, faz&ecirc;-lo atrav&eacute;s
        da plataforma XGROW - EVENTX, sendo vedada a realiza&ccedil;&atilde;o do
        reembolso por meios diversos.
      </p>
      <p>
        8.2.1. Ap&oacute;s o in&iacute;cio do procedimento de reembolso pelo
        Organizador, nas compras realizadas por cart&atilde;o de cr&eacute;dito,
        o Consumidor receber&aacute; um comunicado confirmando o cancelamento da
        compra e o reembolso ser&aacute; realizado de maneira imediata e
        autom&aacute;tica, salvo na exist&ecirc;ncia de dispositivo legal que
        autorize o diferimento. Nas compras feitas por boleto, o consumidor
        receber&aacute; um e-mail contendo uma solicita&ccedil;&atilde;o de
        preenchimento de dados banc&aacute;rios para que o reembolso seja
        realizado.
      </p>
      <p>
        8.2.2. O Consumidor ser&aacute; respons&aacute;vel por qualquer
        equ&iacute;voco ou atraso no cadastramento dos dados banc&aacute;rios
        para reembolso dos valores.
      </p>
      <p>
        8.3. Se identificarmos que algum evento n&atilde;o foi realizado ou, foi
        realizado de maneira distinta ao anunciado, a XGROW - EVENTX se
        resguarda o direito de reembolsar todas as compras ou mesmo suspender,
        provisoriamente, o valor do repasse at&eacute; que a
        situa&ccedil;&atilde;o esteja regularizada entre todas as partes
        envolvidas na transa&ccedil;&atilde;o, quais sejam, XGROW - EVENTX,
        Organizador e Consumidores.
      </p>
      <p>
        8.4. Especificamente em casos de compras com ind&iacute;cios de fraude,
        o setor de An&aacute;lise de Risco da XGROW - EVENTX poder&aacute;
        solicitar ao Consumidor a c&oacute;pia digitalizada dos documentos do
        titular do cart&atilde;o de cr&eacute;dito utilizado nas compras, bem
        como do pr&oacute;prio cart&atilde;o utilizado, visando
        legitim&aacute;-las. Neste caso, o contato ser&aacute; feito
        atrav&eacute;s dos nossos canais oficiais.
      </p>
      <p>
        8.4.1. O prazo de envio de tal documenta&ccedil;&atilde;o ser&aacute;
        estabelecido pela XGROW - EVENTX, de acordo com a data e hor&aacute;rio
        em que o evento ocorrer&aacute;, de forma que, remetido o documento pelo
        Consumidor, a XGROW - EVENTX far&aacute; a an&aacute;lise do mesmo e
        dar&aacute; um retorno atrav&eacute;s do mesmo e-mail utilizado para a
        compra, ou outro canal de contato oficial.
      </p>
      <p>
        8.4.2. Caso os documentos n&atilde;o sejam enviados pelo Consumidor no
        prazo determinado, ficar&aacute; a cargo da XGROW - EVENTX
        cancelar/suspender, ou n&atilde;o, a transa&ccedil;&atilde;o, a seu
        exclusivo crit&eacute;rio. A XGROW - EVENTX, ainda, poder&aacute;
        solicitar ao Consumidor que seja apresentado, obrigatoriamente, na
        portaria do evento, um documento de identidade, com foto do titular do
        cart&atilde;o ou o pr&oacute;prio cart&atilde;o utilizado na compra,
        para fins de valida&ccedil;&atilde;o da compra. Em todo o caso, se o
        Consumidor n&atilde;o enviar as informa&ccedil;&otilde;es requeridas a
        XGROW - EVENTX poder&aacute; cancelar a transa&ccedil;&atilde;o sem que
        tal fato gere expectativa de direito ou indeniza&ccedil;&atilde;o de
        qualquer esp&eacute;cie.
      </p>
      <p>
        8.5. Em caso de arrependimento da compra, o reembolso do valor do
        ingresso, descontada a taxa de servi&ccedil;o, estar&aacute; garantido.
        A solicita&ccedil;&atilde;o seja feita ao Organizador, no prazo de
        at&eacute; 7 (sete) dias, a contar da data compra. O pedido de
        devolu&ccedil;&atilde;o dever&aacute; ser realizado, preferencialmente,
        no prazo de 48 (quarenta e oito) horas antes do hor&aacute;rio de
        in&iacute;cio do evento.
      </p>
      <br />
      <h2>9. Transfer&ecirc;ncia de Titularidades dos Ingressos</h2>
      <p>
        9.1. Somente o Consumidor, titular da compra, poder&aacute; solicitar a
        transfer&ecirc;ncia dos ingressos para outro usu&aacute;rio ou receber
        informa&ccedil;&otilde;es detalhadas sobre a transa&ccedil;&atilde;o
        efetuada na plataforma XGROW - EVENTX. Os dados associados ao
        Consumidor, titular da compra, n&atilde;o podem ser alterados
        ap&oacute;s a confirma&ccedil;&atilde;o desta compra.
      </p>
      <p>
        9.1.1. O Participante n&atilde;o poder&aacute; solicitar
        altera&ccedil;&otilde;es em seus ingressos, as quais poder&atilde;o ser
        feitas somente pelo Consumidor, salvo quando Participante e Consumidor
        forem a mesma pessoa.
      </p>
      <p>
        9.2. &nbsp;A XGROW - EVENTX oferece ao consumidor a possibilidade de
        trocar a titularidade do ingresso diretamente na plataforma XGROW -
        EVENTX, bastando o usu&aacute;rio efetuar login com a conta associada ao
        e-mail informado durante a compra; localizar a se&ccedil;&atilde;o meus
        ingressos e, clicar em &ldquo;Troca de titularidade&rdquo;.
      </p>
      <p>
        9.2.1. &nbsp;Em alguns eventos, a troca de titularidade dever&aacute;
        ser solicitada por e-mail, atrav&eacute;s do endere&ccedil;o
        contato@eventx.com.br. Nesses casos, as instru&ccedil;&otilde;es para
        encaminhar a solicita&ccedil;&atilde;o estar&atilde;o dispon&iacute;veis
        na p&aacute;gina do evento na plataforma XGROW - EVENTX.
      </p>
      <p>
        9.3. Em regra, &nbsp;a troca de titularidade poder&aacute; ser realizada
        apenas 01 (uma) vez, com 24 (vinte e quatro) horas de anteced&ecirc;ncia
        do hor&aacute;rio previsto para in&iacute;cio do evento, conforme
        informa&ccedil;&otilde;es contidas na p&aacute;gina deste evento. Os
        Organizadores, entretanto, podem, a seu exclusivo crit&eacute;rio, fazer
        altera&ccedil;&otilde;es nessas regras, cabendo a eles esclarecer
        quaisquer d&uacute;vidas que possam surgir sobre a pol&iacute;tica de
        troca de titularidade em seus eventos.
      </p>
      <p>
        9.4. &nbsp;Ao receber o ingresso via troca de titularidade, o Consumidor
        declara-se ciente de que o titular da compra poder&aacute;, a qualquer
        momento, e desde que autorizado pelo Organizador, cancelar a compra ou
        efetuar uma nova troca de titularidade, inviabilizando, assim, seu
        acesso ao evento. A XGROW - EVENTX n&atilde;o se responsabiliza por
        compras efetuadas fora da plataforma XGROW - EVENTX ou em seus pontos de
        venda oficiais.
      </p>
      <br />
      <h2>10. Contesta&ccedil;&otilde;es e estornos</h2>
      <p>
        10.1. As contesta&ccedil;&otilde;es reportadas ser&atilde;o analisadas
        pela operadora de cart&atilde;o de cr&eacute;dito, por nossos
        processadores de pagamentos, ou pela pr&oacute;pria XGROW - EVENTX.
      </p>
      <p>
        10.2. A XGROW - EVENTX ir&aacute; conduzir as disputas abertas pelos
        Consumidores, tais como, mas n&atilde;o somente, <em>chargebacks </em>
        (contesta&ccedil;&otilde;es), reclama&ccedil;&otilde;es diversas,
        cancelamento e estornos. Assim, ir&aacute; iniciar e processar
        cancelamentos sem a necessidade da interven&ccedil;&atilde;o ou
        aprova&ccedil;&atilde;o do Organizador, nos casos de:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          a) n&atilde;o cumprimento da pol&iacute;tica de reembolsos do evento
          e/ou do C&oacute;digo de Defesa do Consumidor, por parte do
          Organizador;
        </li>
        <li>
          b) detec&ccedil;&atilde;o de ind&iacute;cios de fraude em compras
          realizadas, estejam elas pendentes de aprova&ccedil;&atilde;o ou
          j&aacute; aprovadas, sejam tais riscos provenientes do Consumidor ou
          do Organizador;
        </li>
        <li>c) cancelamento do evento;</li>
        <li>
          d) erro t&eacute;cnico no processamento da transa&ccedil;&atilde;o;
        </li>
        <li>
          e) verificarmos um n&uacute;mero elevado de queixas referentes a um
          determinado evento, por parte dos Consumidores.
        </li>
      </ol>
      <br />
      <h2>11. Taxa de servi&ccedil;o e emiss&atilde;o de nota fiscal</h2>
      <p>
        11.1. Ser&aacute; cobrada do Organizador uma taxa de servi&ccedil;o para
        o uso da plataforma XGROW - EVENTX, calculada a partir das vendas online
        processadas atrav&eacute;s dela. A taxa cobrada &eacute; de 09% (nove
        por cento) sobre o valor do ingresso, com um valor m&iacute;nimo de taxa
        de R$ 2,50 (dois reais e cinquenta centavos) por ingresso, tendo o
        Organizador a op&ccedil;&atilde;o de repass&aacute;-la diretamente ao
        Consumidor.
      </p>
      <p>
        11.2. Caso o organizador queira antecipar, poder&aacute; solicitar
        at&eacute; 60% (sessenta por cento) do valor, e sobre isso ser&aacute;
        cobrado uma taxa de 1 % (um por cento), independentemente da taxa
        prevista na cl&aacute;usula anterior.
      </p>
      <p>
        11.2. A XGROW - EVENTX somente emite Nota Fiscal para Organizadores,
        tendo em vista serem estes os tomadores dos servi&ccedil;os prestados.
      </p>
      <br />
      <h2>12. Direitos de Propriedade Intelectual</h2>
      <p>
        12.1. O uso comercial da express&atilde;o "XGROW - EVENTX" como marca,
        nome empresarial ou nome de dom&iacute;nio, bem como os conte&uacute;dos
        das telas relativas aos servi&ccedil;os da XGROW - EVENTX, assim como os
        programas, &ldquo;look and feel&rdquo; dos sites, bancos de dados,
        redes, arquivos que permitem ao usu&aacute;rio acessar e usar a sua
        conta, s&atilde;o de titularidade da XGROW - EVENTX e est&atilde;o
        protegidos pelas leis e tratados internacionais de direito autoral,
        marcas, patentes, modelos e desenhos industriais. O uso indevido e a
        reprodu&ccedil;&atilde;o total ou parcial dos referidos conte&uacute;dos
        s&atilde;o proibidos, salvo a autoriza&ccedil;&atilde;o pr&eacute;via e
        expressa por escrito da XGROW - EVENTX.
      </p>
      <p>
        12.2. As p&aacute;ginas dos eventos poder&atilde;o conter links para
        outros sites, o que n&atilde;o significa que esses sites sejam de
        propriedade ou operados pela XGROW - EVENTX, que n&atilde;o possui
        controle sobre esses sites de terceiros, raz&atilde;o pela qual
        n&atilde;o ser&aacute; respons&aacute;vel pelos seus conte&uacute;dos,
        pr&aacute;ticas e servi&ccedil;os ofertados. A presen&ccedil;a de links
        para outros sites n&atilde;o implica rela&ccedil;&atilde;o de sociedade,
        de supervis&atilde;o, de cumplicidade ou solidariedade da XGROW - EVENTX
        para com esses sites e seus conte&uacute;dos.
      </p>
      <br />
      <h2>13. Seguran&ccedil;a da plataforma XGROW - EVENTX</h2>
      <p>
        13.1. N&atilde;o &eacute; permitido o acesso &agrave;s &aacute;reas de
        programa&ccedil;&atilde;o da plataforma XGROW - EVENTX, seu banco de
        dados, ou qualquer outro conjunto de informa&ccedil;&otilde;es que
        fa&ccedil;a parte da atividade de <em>Webmastering</em>.
      </p>
      <p>
        13.2. Os usu&aacute;rios n&atilde;o est&atilde;o &nbsp;autorizados a
        realizar ou permitir engenharia reversa, nem traduzir, decompilar,
        copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar,
        transmitir, emprestar, distribuir ou, de outra maneira, dispor das
        ferramentas de consulta da plataforma XGROW - EVENTX e de suas
        funcionalidades.
      </p>
      <p>
        13.3. Na plataforma XGROW - EVENTX &eacute; proibida a
        utiliza&ccedil;&atilde;o de aplicativos <em>spider</em>
        &nbsp;ou de minera&ccedil;&atilde;o de dados, de qualquer tipo ou
        esp&eacute;cie, al&eacute;m de outro aqui n&atilde;o tipificado, mas que
        atue de modo automatizado, tanto para realizar opera&ccedil;&otilde;es
        massificadas, quanto para quaisquer outras finalidades.
      </p>
      <br />
      <h2>14. Privacidade dos usu&aacute;rios</h2>
      <p>
        14.1. A XGROW - EVENTX possui documento pr&oacute;prio, denominado
        &ldquo;Pol&iacute;tica de Privacidade&rdquo;, que regula o tratamento
        dado &agrave;s informa&ccedil;&otilde;es e dados pessoais coletados
        atrav&eacute;s da plataforma XGROW - EVENTX e suas funcionalidades.
      </p>
      <p>
        14.1.1. A &ldquo;Pol&iacute;tica de Privacidade&rdquo; &eacute; parte
        integrante e insepar&aacute;vel dos Termos de Uso da plataforma XGROW -
        EVENTX e pode ser acessada no link encontrado em seu rodap&eacute;.
      </p>
      <p>
        14.1.2. Caso alguma disposi&ccedil;&atilde;o da &ldquo;Pol&iacute;tica
        de Privacidade&rdquo; conflitar com qualquer outra do presente
        documento, dever&aacute; prevalecer o descrito na norma mais
        espec&iacute;fica.
      </p>
      <br />
      <h2>
        15. Prote&ccedil;&atilde;o de registros, informa&ccedil;&otilde;es e
        comunica&ccedil;&otilde;es privadas
      </h2>
      <p>
        15.1. A XGROW - EVENTX somente ser&aacute; obrigada a disponibilizar
        registros de acesso, informa&ccedil;&otilde;es pessoais ou
        comunica&ccedil;&otilde;es privadas armazenadas em sua plataforma
        mediante ordem judicial ou requisi&ccedil;&atilde;o de autoridade
        policial ou administrativa competente.
      </p>
      <p>
        15.1.1. Em todos os casos, a XGROW - EVENTX comunicar&aacute; o
        Organizador da exist&ecirc;ncia do pedido de revela&ccedil;&atilde;o,
        ficando desde j&aacute; autorizada a responder a
        requisi&ccedil;&atilde;o recebida.
      </p>
      <br />
      <h2>16. Limita&ccedil;&atilde;o de responsabilidade</h2>
      <p>
        16.1. FICA EXPRESSAMENTE ESTABELECIDO QUE A XGROW - EVENTX N&Atilde;O
        TEM OBRIGA&Ccedil;&Atilde;O DE CONTROLAR TODAS AS A&Ccedil;&Otilde;ES
        EXECUTADAS PELOS USU&Aacute;RIOS NO USO DA PLATAFORMA XGROW - EVENTX E,
        POR CONSEGUINTE, A XGROW - EVENTX N&Atilde;O PODER&Aacute; SER
        RESPONSABILIZADA PELOS ATOS DE SEUS USU&Aacute;RIOS, INCLUSIVE AQUELES
        DE CAR&Aacute;TER IL&Iacute;CITO, IMORAL OU ANTI&Eacute;TICOS,
        PORVENTURA PERPETRADOS, CABENDO &Agrave; ESTES RESPONDEREM PESSOAL E
        EXCLUSIVAMENTE POR EVENTUAIS RECLAMA&Ccedil;&Otilde;ES DE TERCEIROS OU
        DEMANDAS JUDICIAIS, DEVENDO MANTER A XGROW - EVENTX LIVRE DE QUALQUER
        RESPONSABILIDADE OU &Ocirc;NUS.
      </p>
      <p>
        16.2. A XGROW - EVENTX n&atilde;o se responsabiliza por qualquer dano,
        direto ou indireto, ocasionado por eventos de terceiros, como ataque de
        hackers, falhas no sistema, no servidor ou na conex&atilde;o &agrave;
        internet, inclusive por a&ccedil;&otilde;es de programas maliciosos como
        v&iacute;rus, cavalos de tr&oacute;ia, e outros que possam, de algum
        modo, danificar o equipamento ou a conex&atilde;o dos usu&aacute;rios em
        decorr&ecirc;ncia do acesso, utiliza&ccedil;&atilde;o ou
        navega&ccedil;&atilde;o na plataforma XGROW - EVENTX, salvo se tal fato
        decorrer de dolo ou culpa da XGROW - EVENTX.
      </p>
      <p>
        &nbsp;16.3. A XGROW - EVENTX n&atilde;o se responsabiliza por eventuais
        grava&ccedil;&otilde;es de &aacute;udio, v&iacute;deo ou quaisquer dados
        transmitidos ou compartilhados durante a realiza&ccedil;&atilde;o de um
        evento online. O Participante &eacute; o &uacute;nico respons&aacute;vel
        pelo cumprimento da legisla&ccedil;&atilde;o aplic&aacute;vel na
        utiliza&ccedil;&atilde;o de alguma funcionalidade ou ferramenta que
        possibilite a grava&ccedil;&atilde;o do conte&uacute;do transmitido pelo
        Organizador.
      </p>
      <br />
      <h2>17. Disposi&ccedil;&otilde;es gerais</h2>
      <p>
        17.1. A XGROW - EVENTX n&atilde;o se responsabiliza por qualquer dano ou
        preju&iacute;zo com envio de informa&ccedil;&otilde;es ou de
        conte&uacute;do publicit&aacute;rio que n&atilde;o tenham sido
        disparados pela pr&oacute;pria XGROW - EVENTX, e tamb&eacute;m
        n&atilde;o se responsabiliza pelo conte&uacute;do das opini&otilde;es
        postadas na p&aacute;gina dos eventos.
      </p>
      <p>
        17.2. Ao navegar pela plataforma XGROW - EVENTX e utilizar suas
        funcionalidades, os usu&aacute;rios aceitam todo o disposto nos Termos
        de Uso e demais pol&iacute;ticas legais que se encontram vigentes na
        data de acesso. Por isso, &eacute; recomend&aacute;vel que os
        usu&aacute;rios se mantenham atualizados.
      </p>
      <p>
        17.2.1. Nossos servi&ccedil;os s&atilde;o direcionados para pessoas
        maiores de 18 (dezoito) anos, com documento pessoal e endere&ccedil;o de
        e-mail v&aacute;lidos, ou ainda, para menores e incapazes, desde que
        devidamente autorizados ou assistidos por seus representantes legais.
      </p>
      <p>
        17.3. Os presentes Termos de Uso est&atilde;o sujeitos a constante
        melhoria e aprimoramento. Assim, a XGROW - EVENTX se reserva o direito
        de modific&aacute;-los a qualquer momento, conforme a finalidade da
        plataforma XGROW - EVENTX, tal qual para adequa&ccedil;&atilde;o e
        conformidade legal de disposi&ccedil;&atilde;o de lei ou norma que tenha
        for&ccedil;a jur&iacute;dica equivalente, cabendo aos usu&aacute;rios
        verific&aacute;-la sempre que efetuar o acesso &agrave; plataforma XGROW
        - EVENTX.
      </p>
      <p>
        17.4. A eventual toler&acirc;ncia quanto a qualquer
        viola&ccedil;&atilde;o dos termos e condi&ccedil;&otilde;es do contido
        neste dom&iacute;nio ser&aacute; considerada mera liberalidade e
        n&atilde;o ser&aacute; interpretada como nova&ccedil;&atilde;o,
        precedente invoc&aacute;vel, ren&uacute;ncia a direitos,
        altera&ccedil;&atilde;o t&aacute;cita dos termos contratuais, direito
        adquirido ou altera&ccedil;&atilde;o contratual.
      </p>
      <p>
        17.5. Caso alguma disposi&ccedil;&atilde;o destes Termos de Uso for
        julgada inaplic&aacute;vel ou sem efeito, o restante das normas
        continuam a viger, sem a necessidade de medida judicial que declare tal
        assertiva. Os Termos aqui descritos ser&atilde;o interpretados segundo a
        legisla&ccedil;&atilde;o brasileira.
      </p>
      <p>
        17.6. A comunica&ccedil;&atilde;o entre a XGROW - EVENTX e o
        usu&aacute;rio dever&aacute; ser realizada pelos canais de atendimento
        indicados e disponibilizados na plataforma da XGROW - EVENTX.
      </p>
      <p>
        17.7. Sempre que poss&iacute;vel, potenciais diverg&ecirc;ncias entre o
        usu&aacute;rio e a XGROW - EVENTX ser&atilde;o resolvidas de forma
        amig&aacute;vel. Quando todos os esfor&ccedil;os neste sentido forem
        esgotados, voc&ecirc; concorda, desde j&aacute;, que fica eleito o foro
        da comarca de Barueri, no Estado de S&atilde;o Paulo, para resolver
        controv&eacute;rsias ou queixas oriundas da utiliza&ccedil;&atilde;o de
        nossa plataforma ou relacionadas a estes Termos de Uso.
      </p>
    </>
  );
};

export default UserTerms;
