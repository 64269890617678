import { useFetchAuth, useFetchBasic } from "services/useFetch";

import { Event, TicketEvent } from "types";

export function useTicketEvent<Data = any, Error = any>(id: number) {
  const url: string = `/tickets/events/${id}`;

  const { data, error, mutate } = useFetchAuth<Data, Error>({
    method: "get",
    url,
  });

  const dataResponse = data as unknown;

  const newData = dataResponse as Event;

  const mapTickets = newData?.tickets?.map((ticket: TicketEvent) => ({
    ...ticket,
    isOpen: false,
  }));

  const newResponse = { ...newData, tickets: mapTickets };

  return { data: newResponse, error, mutate };
}

interface useTicketCodeResponse {
  ticket_code: number;
  ticket_name: string;
}

const filterCode = (ticket: number) => {
  return ("0000000000000000" + Number(ticket)).slice(-16);
};

export function useTicketCode<Data = any, Error = any>(id: string) {
  const url: string = `/tickets/ticket-code/${id}`;

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  const dataResponse = data as unknown;

  const newData = dataResponse as useTicketCodeResponse;

  const newResponse = {
    ticket_code: filterCode(newData?.ticket_code),
    ticket_name: newData?.ticket_name,
  };

  return { data: newResponse, error, mutate };
}
