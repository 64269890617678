import { useEffect, useState } from "react";

import { Spin, Switch } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import { BiCodeAlt } from "react-icons/bi";
import { MdSave } from "react-icons/md";
import { RiCloseCircleLine, RiKeyLine } from "react-icons/ri";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { authClient } from "services/authClient";
import { Colors } from "constants/colors";

import {
  ButtonCancel,
  ButtonSave,
  ContainerActiveIntegration,
  ContainerButtonsModal,
  ContainerDateIntegration,
  ContainerDetailIntegration,
  ContainerHeaderIntegration,
  ContainerModal,
  FormFull,
  FormLabel,
  ModalStyled,
} from "./styles";

import { successNotification } from "components/Notification";

type Integration = {
  id: number;
  label: string;
  short: string;
  description: string;
  thumb: string;
  link: string;
  integrationData: {
    event_id: string;
    id: number;
    key: string;
    status: true;
    token: string;
    type: number;
    user_id: number;
  } | null;
};

type IntegrationFormData = {
  key: string;
  token: string;
};

const integrationFormSchema = yup.object().shape({
  key: yup.string().required("Key obrigatória"),
  token: yup.string().required("Token obrigatório"),
});

interface ModalAddIntegrationProps {
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
  setEventIntegrationUpdate(value: Integration | null): void;
  eventIntegrationUpdate: Integration | null;
  selectedIntegration: Integration | null;
  event_id: string | number;
  getIntegrations(): void;
}

export function ModalUpdateIntegration({
  isModalVisible,
  handleOk,
  handleCancel,
  setEventIntegrationUpdate,
  eventIntegrationUpdate,
  selectedIntegration,
  event_id,
  getIntegrations,
}: ModalAddIntegrationProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<IntegrationFormData>({
    resolver: yupResolver(integrationFormSchema),
  });

  const [activeSwitch, setActiveSwitch] = useState(true);

  useEffect(() => {
    reset({
      key: eventIntegrationUpdate?.integrationData?.key,
      token: eventIntegrationUpdate?.integrationData?.token,
    });

    setActiveSwitch(eventIntegrationUpdate?.integrationData?.status ?? true);
  }, [eventIntegrationUpdate, reset]);

  const updatedIntegration: SubmitHandler<IntegrationFormData> = async ({
    key,
    token,
  }) => {
    try {
      const formData = {
        type: selectedIntegration?.id,
        event_id: event_id,
        key,
        token,
        status: activeSwitch,
      };

      await authClient.put(
        `/integration/edit/${eventIntegrationUpdate?.integrationData?.id}`,
        {
          ...formData,
        }
      );

      await getIntegrations();

      reset();

      handleOk();

      setEventIntegrationUpdate(null);

      successNotification(
        "Configurações salvas! ",
        `As configurações da integração ${selectedIntegration?.label} foram salvas com sucesso.`,
        2
      );
    } catch (err) {
      alert("erro ao adicionar a integração");
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  return (
    <ModalStyled
      className="modal-event"
      width={1000}
      visible={isModalVisible}
      onOk={handleOk}
      style={{ borderRadius: "20px" }}
      onCancel={() => {
        setEventIntegrationUpdate(null);
        handleCancel();
      }}
      bodyStyle={{ borderRadius: 20 }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "34px", marginLeft: "-34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal onSubmit={handleSubmit(updatedIntegration)}>
        <h2 className="title">Configurar integração</h2>

        <ContainerDetailIntegration>
          <ContainerHeaderIntegration>
            <div className="logo">
              <img
                src={selectedIntegration?.thumb}
                width={50}
                height={50}
                alt="thumb"
              />

              <h2 className="title-logo">{selectedIntegration?.label}</h2>
            </div>

            <p>
              {selectedIntegration?.description}{" "}
              <a
                target="_blank"
                href={selectedIntegration?.link}
                rel="noreferrer"
              >
                Saiba mais sobre o {selectedIntegration?.label}.
              </a>
            </p>
          </ContainerHeaderIntegration>

          <ContainerActiveIntegration>
            <Switch
              // defaultChecked={eventIntegrationUpdate?.status}
              checked={activeSwitch}
              onChange={(e) => {
                setActiveSwitch(e);
              }}
            />

            <h5>Ativar integração</h5>
          </ContainerActiveIntegration>

          <ContainerDateIntegration>
            <h3>Informe os dados abaixo</h3>

            <FormFull>
              <div className="all-input">
                <FormLabel>
                  <RiKeyLine size={23} color={Colors.secondary40} />
                  <h2>ID do facebook pixel</h2>
                </FormLabel>

                <input type="text" {...register("key")} />

                {errors.key && <span>{errors.key.message}</span>}
              </div>

              <div className="all-input">
                <FormLabel>
                  <BiCodeAlt size={23} color={Colors.secondary40} />
                  <h2>Token de acesso</h2>
                </FormLabel>

                <input type="text" {...register("token")} />

                {errors.token && <span>{errors.token.message}</span>}
              </div>
            </FormFull>
          </ContainerDateIntegration>
        </ContainerDetailIntegration>

        <ContainerButtonsModal>
          <ButtonCancel
            onClick={() => {
              setEventIntegrationUpdate(null);
              handleCancel();
            }}
          >
            <span>Cancelar</span>
          </ButtonCancel>

          <ButtonSave type="submit">
            {isSubmitting ? (
              <Spin spinning={isSubmitting} indicator={antIcon} />
            ) : null}
            {!isSubmitting ? <MdSave size={28} color={Colors.white} /> : null}
            <span>Salvar</span>
          </ButtonSave>
        </ContainerButtonsModal>
      </ContainerModal>
    </ModalStyled>
  );
}
