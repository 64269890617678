import { useDisclosure } from "hooks/useDisclosure";
import { createContext, useContext } from "react";

type SearchCityAndStateContextData = {
  handleCancelSearch(): void;
  handleOkSearch(): void;
  isModalVisibleSearch: boolean;
  showModalSearch(): void;
};

const SearchCityAndStateContext = createContext<SearchCityAndStateContextData>(
  {} as SearchCityAndStateContextData
);

export const SearchCityAndStateProvider: React.FC = ({ children }) => {
  const {
    handleCancel: handleCancelSearch,
    handleOk: handleOkSearch,
    isModalVisible: isModalVisibleSearch,
    showModal: showModalSearch,
  } = useDisclosure();

  return (
    <SearchCityAndStateContext.Provider
      value={{
        handleCancelSearch,
        handleOkSearch,
        isModalVisibleSearch,
        showModalSearch,
      }}
    >
      {children}
    </SearchCityAndStateContext.Provider>
  );
};

export function useSearchCityAndState(): SearchCityAndStateContextData {
  const context = useContext(SearchCityAndStateContext);

  if (!context) {
    throw new Error(
      `useSearchCityAndState it depends SearchCityAndStateProvider`
    );
  }

  return context;
}
