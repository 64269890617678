import { Colors } from "constants/colors";
import { Modal } from "antd";

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface ContainerItemsProps {
  haveSliders?: boolean;
}

export const ContainerItems = styled.div<ContainerItemsProps>`
  max-width: 1280px;
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
  margin-top: ${(props) => (props.haveSliders ? "4.26rem" : "9rem")};

  @media (max-width: 800px) {
    margin-top: 38px;
    padding-right: 0px;
    width: 100%;
  }

  div.header {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @media (max-width: 800px) {
      padding-right: 28px;
      margin-bottom: 26px;
    }

    h3 {
      font-family: "Cairo";
      color: ${Colors.black100};
      font-weight: 900;
      font-size: 2.344rem;
      margin-bottom: 0px !important;

      @media (max-width: 800px) {
        font-weight: bold;
        font-size: 16px;
      }
    }

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      div.dots {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 21px;
      }
    }
  }

  .img-download-app {
    position: fixed;
    bottom: -60px;
    right: 0px;
    padding: 5px;
    z-index: 88;

    img {
      cursor: pointer;
    }

    @media (max-width: 800px) {
      display: none;
    }
  }
`;

export const ListItems = styled.div`
  margin-top: 40px;
`;

export const Item = styled.div`
  cursor: pointer;
  width: 10.73rem;
  min-height: 15.04rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;

  @media (max-width: 800px) {
    max-width: 98px;
    min-height: 144px;
    height: 144px;
    margin-left: 20px;
  }

  div.title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 10.73rem;
    height: 15.04rem;
    border-radius: 0.93rem !important;
    object-fit: scale-down;

    @media (max-width: 800px) {
      height: 98.62px;
      width: 70.336px;
      border-radius: 10px;
    }
  }

  h4.name {
    max-width: 78px;
    color: ${Colors.secondary100};
    margin-right: 3.6rem;
    font-weight: bold;
    font-size: 0.93rem;
    margin-top: 17px;

    @media (max-width: 800px) {
      font-size: 12px;
      margin-top: 10px;
      margin-right: 32px;
    }
  }
`;

export const CardArrowLeft = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 24px;
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  background-color: ${Colors.secondary10};

  @media (max-width: 800px) {
    display: none;
  }
`;

export const CardArrowRight = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  width: 32px;
  border-radius: 50%;

  background-color: ${Colors.secondary10};
  border: 0;

  @media (max-width: 800px) {
    height: 24px;
    width: 24px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

interface DotProps {
  active: boolean;
}

export const Dot = styled.div<DotProps>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? Colors.secondary100 : Colors.secondary20};
  margin-right: 16px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 94px 109px 64px 109px;

  div.logo-qr-code {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.content {
    margin-top: 51px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 14px;

      text-align: center;

      color: ${Colors.black90} #292c42;

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90} #292c42;
      }
    }
  }

  /* div.or {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  div.or {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    span {
      padding: 0px 11px;
    }

    div.line {
      min-width: 160px;
      width: 100%;
      border: 1px solid ${Colors.secondary100};
      opacity: 0.2;
    }
  }

  div.download-app {
    width: 100%;
    margin-top: 36px;
    align-items: center;
    justify-content: center;

    padding-left: 10px;
    padding-right: 10px;

    h5 {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      text-align: center;
      color: ${Colors.black90};
    }

    div.row {
      width: 100%;
      display: flex;
      align-items: center;
      /* justify-content: space-between; */
      justify-content: center;

      img {
        cursor: pointer;
        min-width: 189px;
        min-height: 128px;
      }
    }
  }
`;

export const Center = styled.div`
  width: 100%;
  height: 400px;

  /* margin-top: 170px; */
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    height: 90px;
  }
`;
