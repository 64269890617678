import React from "react";

import { RiArrowDropDownLine, RiQuestionLine } from "react-icons/ri";

import {
  Container,
  Section,
  FlexTitle,
  Collapse,
  IconContainer,
  Header,
  QuestionsContainer,
} from "./styles";

import { QuestionsList } from "./questions_answers";

const { Panel } = Collapse;

const Questions: React.FC = () => {
  const genExtra = () => (
    <RiArrowDropDownLine color={"#9187AB"} fontSize={32} />
  );
  const header = (question: any) => (
    <Header>
      <IconContainer>
        <RiQuestionLine />
      </IconContainer>
      <p>{question.question}</p>
    </Header>
  );
  return (
    <Container className="first-container">
      <Section>
        <div className="content">
          <QuestionsContainer>
            <FlexTitle>
              <p className="title">Dúvidas frequentes</p>
            </FlexTitle>
            <div className="collapse-wrapper">
              <Collapse bordered={false} expandIconPosition={"right"}>
                {QuestionsList.map((question, key) => {
                  return (
                    <Panel
                      showArrow={false}
                      header={header(question)}
                      key={key}
                      extra={genExtra()}
                    >
                      <p>{question.answer}</p>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </QuestionsContainer>
        </div>
      </Section>
    </Container>
  );
};

export default Questions;
