import styled from "styled-components";
import { Modal as AntdModal } from "antd";
import { Colors } from "constants/colors";

import AppStore from "assets/selo_app_store.svg";
import GooglePlay from "assets/selo_google_play.svg";

export const StyledModal = styled(AntdModal)`
  // .ant-modal-mask class -> global style
  width: calc(100vw - 100px) !important;
  top: 30px;
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px;
  }
  .custom-close-icon {
    color: #11142d;
    opacity: 0.3;
    font-size: 32px;
  }
  .ant-modal-content {
    width: 100%;
    background-color: ${Colors.white};
    padding: 32px;
    border-radius: 20px;
    .ant-modal-close-x {
      color: ${Colors.black};
    }
    .ant-modal-header {
      .ant-modal-title {
        color: ${Colors.black};
        text-align: center;
        P {
          margin-top: 32px;
        }
      }
      background-color: ${Colors.white};
    }
    .ant-modal-body {
      padding: 30px 0;
      color: ${Colors.black};
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .ant-modal-footer {
      border-top: none;
      display: flex;
      justify-content: center;
      button {
        margin: 0 12px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw  !important;
  }
  @media screen and (max-width: 450px) {
    .ant-modal-content {
      padding: 32px 16px;
    }
  }
  .title{
    font: 700 3.2rem, sans-serif;
    margin-top: 20px;
    color: ${Colors.secondary100};
  }
  .description{
    font: 400 2.0rem, sans-serif;
    text-align: center;
    margin-top: 12px;
  }
`;

export const DownloadSection = styled.div`
  position: relative;
  text-align: center;
  .download {
    color: ${Colors.black};
    filter: drop-shadow(0px 4px 10px hsla(0, 0%, 0%, 0.25));
    padding-left: 12px;
  }
  .container-download {
    display: flex;
    padding-left: 12px;
    justify-content: space-around;
    padding: 12px 24px;
  }
  .download-button {
    width: 26rem;
    height: 9rem;
    background-color: #fff;
  }
  .download-button.app-store {
    background: url(${AppStore});
    background-repeat: no-repeat;
    background-size: contain;
  }
  .download-button.google-play {
    background: url(${GooglePlay});
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media screen and (max-width: 450px) {
    .download {
      padding-left: 0;
    }
    .download-button {
      margin: 12px;
    }
    .container-download {
      align-items: center;
      padding: 0;
      flex-direction: column;
    }
  }
`;
export const StyledIframe = styled.iframe`
  height: 600px;
  width: 100%;
  border: 1px solid #aaa;
  border-radius: 12px;
`
