import React from "react";

const OrganizerObligations = () => {
  return (
    <>
      <h2>1.&nbsp;Introdu&ccedil;&atilde;o</h2>
      <p>
        A XGROW - EVENTX &eacute; uma plataforma tecnol&oacute;gica
        dispon&iacute;vel para organizadores e casas de eventos, facilitando a
        venda de ingressos, inscri&ccedil;&atilde;o para eventos,
        cobran&ccedil;a de contribui&ccedil;&otilde;es para eventos e a
        gest&atilde;o de participantes, acess&iacute;vel no endere&ccedil;o
        eletr&ocirc;nico{" "}
        <a href="https://eventx.com.br/">
          <u>https://XGROW - EVENTX.com.br</u>
        </a>
        &nbsp;, ou por meio dos nossos aplicativos oficiais.
      </p>
      <p>
        Fica ressaltado que a XGROW - EVENTX n&atilde;o se responsabiliza por
        quest&otilde;es de hor&aacute;rios, lugares, fiscaliza&ccedil;&atilde;o,
        quantidade de pessoas, aplica&ccedil;&atilde;o das leis locais, e
        qualquer outros quesitos relacionados diretamente com o evento, sendo
        apenas um meio de facilita&ccedil;&atilde;o para os Organizadores e
        casas de eventos &nbsp;referente a venda de ingressos,
        inscri&ccedil;&atilde;o de eventos, cobran&ccedil;as de
        contribui&ccedil;&otilde;es para eventos e a gest&atilde;o de
        participantes.
      </p>
      <p>
        Assim, &eacute; de total responsabilidade dos organizadores buscarem
        informa&ccedil;&otilde;es sobre as regras das leis locais relacionados a
        eventos, bem como a sua aplicabilidade, ficando a XGROW - EVENTX
        totalmente isenta de qualquer responsabilidade diretamente relacionada
        ao evento.
      </p>
      <br />
      <h2>
        2.&nbsp;Alvar&aacute; de Autoriza&ccedil;&atilde;o para Eventos
        Presenciais
      </h2>
      <p>
        Para produzir um evento, voc&ecirc; deve estar atento a diversos itens
        legais e documenta&ccedil;&otilde;es obrigat&oacute;rias para a
        realiza&ccedil;&atilde;o do mesmo. Um deles &eacute; um alvar&aacute; do
        local onde a produ&ccedil;&atilde;o ser&aacute; realizada! Este
        alvar&aacute; para evento &eacute; expedido para a
        autoriza&ccedil;&atilde;o de eventos tempor&aacute;rios (a cada evento
        um novo alvar&aacute; deve ser emitido). Sem ele, voc&ecirc; corre o
        risco de ser multado e n&atilde;o oferecer seguran&ccedil;a aos
        participantes, al&eacute;m de acabar com o evento antes mesmo dele
        come&ccedil;ar. A solicita&ccedil;&atilde;o do alvar&aacute; &eacute;
        gratuita e em diversas cidades do Brasil &eacute; poss&iacute;vel
        realiz&aacute;-la online.
      </p>
      <br />
      <h2>
        ●&nbsp;<strong>Requerimento:</strong>
      </h2>
      <p>
        Para fazer o requerimento do alvar&aacute; &eacute; necess&aacute;rio
        entrar em contato com a prefeitura da cidade e fazer o preenchimento do
        mesmo. A solicita&ccedil;&atilde;o &eacute; gratuita e algumas
        prefeituras at&eacute; disponibilizam o requerimento online. Ap&oacute;s
        o preenchimento, entregue-o na prefeitura juntamente com os documentos
        necess&aacute;rios.
      </p>
      <p>
        Mesmo sendo algo burocr&aacute;tico, n&atilde;o &eacute; nenhum bicho de
        sete cabe&ccedil;as conseguir um alvar&aacute; de funcionamento. Tenha a
        plena convic&ccedil;&atilde;o que &eacute; muito melhor estar dentro da
        lei e realizar o seu evento com seguran&ccedil;a do que ver que toda a
        sua produ&ccedil;&atilde;o foi em v&atilde;o.
      </p>
      <br />

      <h2>
        ●&nbsp;<strong>Documentos necess&aacute;rios:</strong>
      </h2>
      <p>&nbsp;</p>
      <p>
        A lista de documentos vai depender do tipo de evento que voc&ecirc;
        produzir&aacute;, da quantidade de convidados, do local e da
        presen&ccedil;a de menores (ou n&atilde;o). Para o licenciamento
        s&atilde;o necess&aacute;rios:
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>Termo de responsabilidade preenchido e assinado;</li>
        <li>
          Contrato e certificado da empresa de seguran&ccedil;a contratada com
          as medidas que ser&atilde;o feitas por ela para deixar o local dentro
          das normas;
        </li>
        <li>
          Contrato de loca&ccedil;&atilde;o do local (se for um local privado);
        </li>
        <li>
          Laudo T&eacute;cnico de Seguran&ccedil;a e a Anota&ccedil;&atilde;o de
          Responsabilidade T&eacute;cnica;
        </li>
        <li>
          C&oacute;pia de comunica&ccedil;&atilde;o &agrave; Pol&iacute;cia e ao
          Corpo de Bombeiro da cidade.
        </li>
      </ul>
      <p>
        Independentemente do tipo do evento, o produtor, no momento de solicitar
        o alvar&aacute;, deve levar os seguintes documentos:
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>C&oacute;pias do RG;</li>
        <li>CPF;</li>
        <li>Comprovante de resid&ecirc;ncia;</li>
        <li>
          C&oacute;pia de IPTU do im&oacute;vel ocupado pelo solicitante
          (&uacute;ltima parcela quitada).
        </li>
      </ul>
      <p>&nbsp;</p>
      <h2>
        ●&nbsp;<strong>Classifica&ccedil;&atilde;o do evento:</strong>
      </h2>
      <p>&nbsp;</p>
      <p>
        Os documentos complementares v&atilde;o depender do tamanho do evento,
        das atra&ccedil;&otilde;es, se vai haver alto-falantes, aparatos como
        fogos de artif&iacute;cio (verifique antes se na cidade do evento
        h&aacute; o decreto municipal proibindo o uso de fogos de
        artif&iacute;cio) ou guindastes, palco girat&oacute;rio, trio
        el&eacute;trico, etc. Os eventos podem ser classificados em:
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>Pequeno porte: at&eacute; 25 mil pessoas</li>
        <li>M&eacute;dio porte: de 25 at&eacute; 100 mil pessoas</li>
        <li>Grande porte: mais de 100 mil pessoas</li>
      </ul>
      <p>&nbsp;</p>
      <h2>
        ●&nbsp;
        <strong>
          Outras leis e documentos que voc&ecirc; deve ficar atento ao fazer um
          evento:
        </strong>
      </h2>
      <p>&nbsp;</p>
      <p>
        A{" "}
        <a href="http://www.planalto.gov.br/ccivil_03/leis/L6533.htm">
          <u>Lei n&ordm; 6.533</u>
        </a>
        , regulamenta a apresenta&ccedil;&atilde;o de artistas e t&eacute;cnicos
        em espet&aacute;culos e shows. Essa lei obriga que sejam contratadas
        pessoas f&iacute;sicas e/ou jur&iacute;dicas que estejam inscritas no
        Minist&eacute;rio do Trabalho para que possam assessorar e produzir tais
        eventos. Para &nbsp;isso, &eacute; necess&aacute;rio que seja feito um
        contrato com estas pessoas.
      </p>
      <p>
        H&aacute; tamb&eacute;m o ECAD (Escrit&oacute;rio Central de
        Arrecada&ccedil;&atilde;o e Distribui&ccedil;&atilde;o). Este
        &oacute;rg&atilde;o &eacute; respons&aacute;vel por fiscalizar e
        arrecadar dinheiro pelos direitos autorais de artistas.
      </p>
      <p>
        Enfim, a{" "}
        <a href="http://www.planalto.gov.br/ccivil_03/leis/L10098.htm">
          <u>Lei n&ordm; 10.098</u>
        </a>
        , que &ldquo;estabelece normas gerais e crit&eacute;rios b&aacute;sicos
        para a promo&ccedil;&atilde;o da acessibilidade das pessoas portadoras
        de defici&ecirc;ncia ou com mobilidade reduzida&rdquo;. Esta lei
        estimula a adequa&ccedil;&atilde;o do local para as pessoas portadoras
        de defici&ecirc;ncia ou com mobilidade reduzida.
      </p>
      <br />

      <h2>
        ●&nbsp;
        <strong>
          Como expedir o alvar&aacute; para evento nas principais capitais
        </strong>
      </h2>
      <p>
        Cada prefeitura &eacute; respons&aacute;vel pela emiss&atilde;o de
        alvar&aacute;s para eventos. Portanto, a a&ccedil;&atilde;o varia de
        acordo com a regi&atilde;o. A seguir, fizemos uma lista com os sites que
        redirecionam para instru&ccedil;&otilde;es de expedi&ccedil;&atilde;o
        dos alvar&aacute;s em algumas capitais do Brasil. Confira:
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>RIO DE JANEIRO:</strong>
          <a href="http://www.rio.rj.gov.br/web/riomaisfacileventos">
            <u>http://www.rio.rj.gov.br/web/riomaisfacileventos</u>
          </a>
        </li>
        <li>
          <strong>S&Atilde;O PAULO: </strong>
          <a href="https://www.prefeitura.sp.gov.br/cidade/secretarias/licenciamento/servicos/index.php?p=153088">
            <u>
              https://www.prefeitura.sp.gov.br/cidade/secretarias/licenciamento/servicos/index.php?p=153088
            </u>
          </a>
          -
        </li>
        <li>
          <strong>VIT&Oacute;RIA:</strong>
          <a href="https://www.vitoria.es.gov.br/imprensa/eventos-de-pequeno-porte-devem-ter-permissao-da-prefeitura">
            <u>
              https://www.vitoria.es.gov.br/imprensa/eventos-de-pequeno-porte-devem-ter-permissao-da-prefeitura
            </u>
          </a>
        </li>
        <li>
          <strong>BELO HORIZONTE:</strong>
          <a href="https://prefeitura.pbh.gov.br/empreendedor/licencas#:~:text=O%20Alvar%C3%A1%20de%20Localiza%C3%A7%C3%A3o%20e,da%20Consulta%20Pr%C3%A9via%20de%20Viabilidade">
            <u>
              https://prefeitura.pbh.gov.br/empreendedor/licencas#:~:text=O%20Alvar%C3%A1%20de%20Localiza%C3%A7%C3%A3o%20e,da%20Consulta%20Pr%C3%A9via%20de%20Viabilidade
            </u>
          </a>
          .
        </li>
        <li>
          <strong>RECIFE:</strong>
          <a href="http://www2.recife.pe.gov.br/servico/alvaras-certidoes-e-licencas">
            <u>
              http://www2.recife.pe.gov.br/servico/alvaras-certidoes-e-licencas
            </u>
          </a>
        </li>
        <li>
          <strong>GOI&Acirc;NIA &ndash;</strong>
          <a href="https://www.goiania.go.gov.br/sing_servicos/licenca-provisoria-para-eventos/">
            <u>htt; ps://</u>
          </a>
          <a href="http://www.goiania.go.gov.br/sing_servicos/licenca-provisoria-para-eventos/">
            <u>
              goiania.go.gov.br/sing_servicos/licenca-provisoria-para-eventos/
            </u>
          </a>
        </li>
        <li>
          <strong>PORTO ALEGRE:</strong>
          <a href="https://www2.portoalegre.rs.gov.br/spm/default.php?p_secao=352">
            <u>
              https://www2.portoalegre.rs.gov.br/spm/default.php?p_secao=352
            </u>
          </a>
        </li>
      </ul>
      <p>&nbsp;</p>
      <h2>
        ●&nbsp;
        <strong>
          Prepara&ccedil;&atilde;o para conseguir um alvar&aacute; para evento
        </strong>
      </h2>
      <p>&nbsp;</p>
      <p>
        O primeiro passo para conseguir o seu alvar&aacute; deve ser a
        prepara&ccedil;&atilde;o, visto que o documento costuma demorar um tempo
        para ficar pronto.
      </p>
      <p>
        O prazo ideal para iniciar o processo &eacute; de 40 dias ANTES de
        eventos grandes, como feiras e exposi&ccedil;&otilde;es, e de 15 dias
        ANTES, em caso de eventos menores, como festas e casamentos.
      </p>
      <p>
        O prazo costuma ser diferente de uma cidade para outra &ndash; portanto,
        verifique como isso &eacute; feito no munic&iacute;pio onde
        acontecer&aacute; o evento e esteja preparado para imprevistos.
      </p>
      <p>
        Por fim, caso tenha alguma d&uacute;vida sobre como retirar o
        alvar&aacute;, nossa dica &eacute; procurar a Prefeitura do
        munic&iacute;pio onde o evento ser&aacute; realizado.
      </p>
      <h2>3.&nbsp;Eventos Tempor&aacute;rios em S&atilde;o Paulo</h2>
      <p>
        Na cidade de S&atilde;o Paulo, as obriga&ccedil;&otilde;es legais
        v&atilde;o depender do evento, pois para cada evento o procedimento
        &eacute; diferente, assim &eacute; recomendamos que cada Organizador ao
        ter inten&ccedil;&atilde;o de realizar um &nbsp;evento em qualquer
        cidade do Estado de S&atilde;o Paulo busque informa&ccedil;&otilde;es
        nas Subprefeituras, inclusive nas quest&otilde;es de
        restri&ccedil;&otilde;es por conta do COVID-19.
      </p>
      <br />

      <h2>
        ●&nbsp;
        <strong>
          Eventos em vias p&uacute;blicas na cidade de S&atilde;o Paulo:
        </strong>
      </h2>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        De acordo com a Lei 14.072, de 18 de outubro de 2005, regulamentada pelo
        Decreto 46.942, para realiza&ccedil;&atilde;o de eventos e obras nas
        vias p&uacute;blicas &eacute; preciso fazer uma
        Solicita&ccedil;&atilde;o para Autoriza&ccedil;&atilde;o de Eventos
        (SAE).
      </p>
      <p>&nbsp;</p>
      <p>
        Conforme a legisla&ccedil;&atilde;o publicada, excetuam-se do pagamento
        do pre&ccedil;o correspondente aos custos operacionais e dos valores
        referentes aos equipamentos de sinaliza&ccedil;&atilde;o utilizados os
        eventos exclusivamente de car&aacute;ter: &nbsp;
      </p>
      <p>- religioso;</p>
      <p>- pol&iacute;tico-partid&aacute;rio;</p>
      <p>
        - social, quando promovido por entidade declarada de utilidade
        p&uacute;blica, conforme legisla&ccedil;&atilde;o em vigor;
      </p>
      <p>
        - manifesta&ccedil;&otilde;es p&uacute;blicas, por meio de passeatas,
        desfiles ou concentra&ccedil;&otilde;es populares que tragam uma
        express&atilde;o p&uacute;blica de opini&atilde;o sobre determinado
        fato;
      </p>
      <p>
        - manifesta&ccedil;&otilde;es de car&aacute;ter c&iacute;vico de
        not&oacute;rio reconhecimento social.
      </p>
      <p>&nbsp;</p>
      <p>
        N&atilde;o far&atilde;o jus &agrave; gratuidade mencionada as atividades
        que envolvam comercializa&ccedil;&atilde;o de bens ou servi&ccedil;os,
        shows art&iacute;sticos e exposi&ccedil;&atilde;o de marcas e/ou
        logotipos visando &agrave; divulga&ccedil;&atilde;o comercial de
        produtos ou servi&ccedil;os.
      </p>
      <p>&nbsp;</p>
      <p>
        Para imprimir o formul&aacute;rio de Autoriza&ccedil;&atilde;o n e obter
        mais informa&ccedil;&otilde;es,{" "}
        <a href="http://www.cetsp.com.br/consultas/eventos,-obras-e-servicos-como-obter-autorizacao/definicoes-e-responsabilidades.aspx">
          <u>clique aqui</u>
        </a>
        .
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <br />

      <h2>
        4.&nbsp;Locais de Reuni&atilde;o e Eventos Tempor&aacute;rios em
        S&atilde;o Paulo
      </h2>
      <p>&nbsp;</p>
      <h2>
        ●&nbsp;
        <strong>
          Alvar&aacute; de Funcionamento para Locais de Reuni&atilde;o
        </strong>
      </h2>
      <p>&nbsp;</p>
      <p>
        O Alvar&aacute; de Funcionamento para Local de Reuni&atilde;o &eacute;
        um documento que comprova que o estabelecimento atende &agrave;s normas
        e exig&ecirc;ncias necess&aacute;rias para funcionamento de atividades
        geradoras de p&uacute;blico, considerando todos os recintos abertos ou
        fechados destinados a reuni&otilde;es, com lota&ccedil;&atilde;o
        superior a 250 pessoas, conforme Art. 4&deg;e 23&deg; do Decreto{" "}
        <a href="http://legislacao.prefeitura.sp.gov.br/leis/decreto-49969-de-28-de-agosto-de-2008">
          49.969/2008
        </a>
        .
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h2>
        ●&nbsp;
        <strong>
          Alvar&aacute; de Autoriza&ccedil;&atilde;o para Eventos
          Tempor&aacute;rios
        </strong>
      </h2>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        O Alvar&aacute; de Autoriza&ccedil;&atilde;o para Eventos
        Tempor&aacute;rios &eacute; a licen&ccedil;a para funcionamento de
        atividades geradoras de p&uacute;blico para um determinado local, com
        dura&ccedil;&atilde;o limitada ao m&aacute;ximo de 6 (seis) meses,
        renov&aacute;vel por igual per&iacute;odo, por mais uma &uacute;nica
        vez, conforme Art. 5&ordm; e 24&ordm; do{" "}
        <a href="http://legislacao.prefeitura.sp.gov.br/leis/decreto-49969-de-28-de-agosto-de-2008">
          Decreto n&ordm; 49.969/2008
        </a>
        &nbsp;e{" "}
        <a href="http://legislacao.prefeitura.sp.gov.br/leis/portaria-secretaria-municipal-de-licenciamento-sel-39-de-9-de-setembro-de-2020">
          Portaria 039/2020/SEL.G
        </a>
        .
      </p>
      <p>&nbsp;</p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Para mais informa&ccedil;&otilde;es consulte: </strong>
          <a href="https://www.prefeitura.sp.gov.br/cidade/secretarias/licenciamento/segur/index.php?p=150485">
            <u>
              https://www.prefeitura.sp.gov.br/cidade/secretarias/licenciamento/segur/index.php?p=150485
            </u>
          </a>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h2>5.&nbsp;Realiza&ccedil;&atilde;o de Eventos na Cidade de Barueri</h2>
      <p>&nbsp;</p>
      <h2>
        ●&nbsp;
        <strong>
          Autoriza&ccedil;&atilde;o de uso de vias e logradouros p&uacute;blicos
          para eventos:
        </strong>
      </h2>
      <p>
        Pelo Decreto Municipal 8.423, 22 de setembro de 2016, fica a Secretaria
        Municipal de Seguran&ccedil;a e Mobilidade Urbana facultada a outorgar,
        nos termos do art. 95, &sect;4&ordm;, da{" "}
        <a href="https://leismunicipais.com.br/a1/lei-organica-barueri-sp">
          <strong>
            <u>Lei Org&acirc;nica</u>
          </strong>
        </a>
        &nbsp;do Munic&iacute;pio de Barueri, autoriza&ccedil;&atilde;o de uso
        de vias e logradouros p&uacute;blicos para realiza&ccedil;&atilde;o de
        eventos.
      </p>
      <p>&nbsp;</p>
      <p>
        A autoriza&ccedil;&atilde;o de uso de que trata este artigo somente
        poder&aacute; ser outorgada a entidades culturais, religiosas,
        esportivas e promotoras de espet&aacute;culos art&iacute;sticos.
      </p>
      <p>&nbsp;</p>
      <p>
        Est&aacute; autoriza&ccedil;&atilde;o de uso dever&aacute; ser
        solicitada mediante requerimento dirigido &agrave; Secretaria Municipal
        de Seguran&ccedil;a e Mobilidade Urbana, instru&iacute;do com a
        comprova&ccedil;&atilde;o da exist&ecirc;ncia legal da entidade, do qual
        conste:
      </p>
      <p>&nbsp;</p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          A identifica&ccedil;&atilde;o da autoridade &agrave; qual &eacute;
          dirigida;
        </li>
        <li>
          Nome, endere&ccedil;o, telefone e demais dados da entidade
          interessada;
        </li>
        <li>
          Nome, qualifica&ccedil;&atilde;o, telefone e endere&ccedil;o do
          representante legal da entidade;
        </li>
        <li>
          As datas e respectivos hor&aacute;rios de utiliza&ccedil;&atilde;o;
        </li>
        <li>
          As vias e/ou logradouros p&uacute;blicos a serem objeto da
          autoriza&ccedil;&atilde;o de uso, com as respectivas
          delimita&ccedil;&otilde;es;
        </li>
        <li>
          A natureza e especifica&ccedil;&atilde;o dos eventos a serem
          realizados;
        </li>
        <li>O n&uacute;mero estimado de participantes de cada evento.</li>
      </ul>
      <p>&nbsp;</p>
      <p>
        O requerimento dever&aacute; ser protocolado na Secretaria Municipal de
        Seguran&ccedil;a e Mobilidade Urbana com anteced&ecirc;ncia
        m&iacute;nima de 90 (noventa) dias.
      </p>
      <p>&nbsp;</p>
      <p>
        Para mais informa&ccedil;&otilde;es recomendamos que entre em contato
        diretamente com a Prefeitura de Barueri e consulte:{" "}
        <a href="https://leismunicipais.com.br/a1/sp/b/barueri/decreto/2016/843/8423/decreto-n-8423-2016-dispoe-sobre-autorizacao-de-uso-de-vias-e-logradouros-publicos-para-eventos?q=Autoriza%C3%A7%C3%A3o+realiza%C3%A7%C3%A3o+eventos">
          <u>
            https://leismunicipais.com.br/a1/sp/b/barueri/decreto/2016/843/8423/decreto-n-8423-2016-dispoe-sobre-autorizacao-de-uso-de-vias-e-logradouros-publicos-para-eventos?q=Autoriza%C3%A7%C3%A3o+realiza%C3%A7%C3%A3o+eventos
          </u>
        </a>
      </p>
      <p>&nbsp;</p>
      <h2>
        6.&nbsp;Como funciona a taxa de servi&ccedil;o para eventos no estado do
        Acre?
      </h2>
      <p>&nbsp;</p>
      <p>
        A{" "}
        <a href="https://www.legisweb.com.br/legislacao/?id=388674">
          <strong>Lei Estadual n&ordm; 3602</strong>
        </a>
        , publicada em 10 de janeiro de 2020, regulamenta a cobran&ccedil;a de
        &ldquo;taxa de conveni&ecirc;ncia, de servi&ccedil;o, taxa
        administrativa ou similar&rdquo; em eventos que ocorrem no estado do
        Acre. Confira abaixo alguns pontos importantes sobre a Lei que j&aacute;
        est&aacute; em vigor:
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          Fica proibida, dentro do Estado, a cobran&ccedil;a de taxa de
          conveni&ecirc;ncia, de servi&ccedil;o, taxa administrativa ou similar
          por sites e/ou aplicativos m&oacute;veis na compra de ingressos on
          line em geral, como shows art&iacute;sticos, eventos esportivos,
          espet&aacute;culos culturais, pe&ccedil;as de teatro, cinemas ou
          qualquer outro similar, feita pela internet;
        </li>
        <li>
          Considera-se taxa de conveni&ecirc;ncia, de servi&ccedil;o, taxa
          administrativa ou similar, toda aquela cobran&ccedil;a de um
          percentual de valor ou um valor fixo predeterminado dos ingressos, na
          venda online feita por sites e/ou aplicativos de dispositivo
          m&oacute;vel na internet;
        </li>
        <li>
          O descumprimento do dispositivo na presente lei sujeitar&aacute; o
          infrator &agrave;s san&ccedil;&otilde;es previstas na Lei Federal
          n&ordm; 8.078, de 11 de setembro de 1990;
        </li>
        <li>
          As penalidades descritas no artigo anterior dever&atilde;o ser
          aplicadas ap&oacute;s o tr&acirc;nsito em julgado do devido processo
          ou administrativo, respeitando o contradit&oacute;rio e a ampla
          defesa.
        </li>
      </ul>
      <p>
        Cabe aos organizadores do estado do Acre, com eventos publicados a
        partir da publica&ccedil;&atilde;o da Lei, que operem com a
        absor&ccedil;&atilde;o da taxa.
      </p>
      <br />

      <h2>
        7.&nbsp;Como funciona a taxa de servi&ccedil;o para eventos no estado de
        Roraima?
      </h2>
      <p>&nbsp;</p>
      <p>
        A{" "}
        <a href="https://www.legisweb.com.br/legislacao/?id=382747">
          <strong>Lei Estadual n&ordm; 1337</strong>
        </a>
        , publicada em 24 de setembro de 2019, regulamenta a cobran&ccedil;a de
        &ldquo;taxa de conveni&ecirc;ncia/servi&ccedil;o&rdquo; em eventos que
        ocorrem em Roraima. Confira abaixo alguns pontos importantes sobre a Lei
        que j&aacute; est&aacute; em vigor:
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          Fica vedada a cobran&ccedil;a de taxa de conveni&ecirc;ncia pelas
          empresas que ofere&ccedil;am a venda de ingressos on-line no Estado de
          Roraima. Para efeitos desta Lei, considera-se taxa de
          conveni&ecirc;ncia o valor cobrado pela presta&ccedil;&atilde;o de
          servi&ccedil;os de venda de ingressos para shows, teatros, cinemas e
          outros eventos e servi&ccedil;os via internet;
        </li>
        <li>
          Fica vedada a cobran&ccedil;a de quaisquer taxas na hip&oacute;tese de
          o consumidor optar por retirar o ingresso nas bilheterias ou em pontos
          de vendas oficiais;
        </li>
        <li>
          O n&atilde;o cumprimento desta Lei implicar&aacute; ao infrator a
          imposi&ccedil;&atilde;o de multa no valor de R$ 1.000,00 (mil reais),
          cobrada em dobro no caso de reincid&ecirc;ncia at&eacute; o limite de
          R$ 10.000,00 (dez mil reais). A multa ser&aacute; revertida para o
          Fundo Estadual de Defesa do Consumidor criado pela Lei n&ordm; 1.193,
          de 10 de julho de 2017.
        </li>
      </ul>
      <p>&nbsp;</p>
      <h2>
        8.&nbsp;Como funciona a taxa de servi&ccedil;o para eventos no
        Esp&iacute;rito Santo?
      </h2>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        A{" "}
        <a href="http://www3.al.es.gov.br/Arquivo/Documents/legislacao/html/LEI109862019.pdf">
          <strong>Lei Estadual n&ordm; 10.986/19</strong>
        </a>
        , publicada em 16 de abril de 2019, regulamenta a cobran&ccedil;a de
        &ldquo;taxa de conveni&ecirc;ncia/servi&ccedil;o&rdquo; em eventos que
        ocorrem no Esp&iacute;rito Santo. E no dia 23 de julho de 2019, a Lei
        foi atualizada e publicada no Di&aacute;rio Oficial do Estado. Confira
        abaixo alguns pontos importantes sobre a Lei que j&aacute; est&aacute;
        em vigor:
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          Fica proibida a cobran&ccedil;a da &ldquo;taxa de
          conveni&ecirc;ncia&rdquo; na venda de ingressos para eventos de
          entretenimento, que aconte&ccedil;am no Estado do Esp&iacute;rito
          Santo, exceto em casos em que o produtor ofere&ccedil;a uma
          alternativa para que os clientes possam comprar sem a cobran&ccedil;a
          de &ldquo;taxa de conveni&ecirc;ncia&rdquo;;
        </li>
        <li>
          O produtor deve informar atrav&eacute;s da divulga&ccedil;&atilde;o do
          seu evento - por meio de afixa&ccedil;&atilde;o de cartazes de
          divulga&ccedil;&atilde;o nos estabelecimentos dos agentes
          terceirizados, ou por meio de informa&ccedil;&atilde;o publicada na
          mesma p&aacute;gina de divulga&ccedil;&atilde;o das
          informa&ccedil;&otilde;es de venda do ingresso, por meio
          eletr&ocirc;nico - quais s&atilde;o as formas de compra em que
          n&atilde;o h&aacute; cobran&ccedil;a da &ldquo;taxa de
          conveni&ecirc;ncia&rdquo;, dando ao consumidor a possibilidade de
          escolha para efetuar sua compra com ou sem a taxa;
        </li>
        <li>
          Ficam dispensados do cumprimento da lei os fornecedores que promoverem
          eventos cujo p&uacute;blico n&atilde;o ultrapasse 200 (duzentas)
          pessoas.
        </li>
      </ul>
      <p>
        Refor&ccedil;ando, cabe ao organizador seguir as
        <strong>&nbsp;obrigatoriedades legais</strong>&nbsp;conforme Leis
        aplicadas ao seu evento, ficando sujeito a multa e/ou penalidades pelo
        &oacute;rg&atilde;o governamental respons&aacute;vel em caso de
        descumprimento.
      </p>
      <p>&nbsp;</p>
      <h2>
        9. Meia-entrada: confira tudo o que voc&ecirc; precisa saber para
        produzir seu evento
      </h2>
      <p>
        <strong>
          Lei de meia-entrada v&aacute;lida para todo o pa&iacute;s:
        </strong>
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          De acordo com a Lei Federal 12.852 (Estatuto da Juventude) e 12.933 de
          2013, o estudante, jovem de baixa renda e pessoa com defici&ecirc;ncia
          (e um acompanhante) t&ecirc;m direito ao benef&iacute;cio da
          meia-entrada, limitada a 40% do total de ingressos dispon&iacute;veis
          para cada evento, nos eventos expressamente previstos em Lei:
        </li>
        <li>
          1&ordm; &Eacute; assegurado aos estudantes o acesso a salas de cinema,
          cineclubes, teatros, espet&aacute;culos musicais e circenses e eventos
          educativos, esportivos, de lazer e de entretenimento, em todo o
          territ&oacute;rio nacional, promovidos por quaisquer entidades e
          realizados em estabelecimentos p&uacute;blicos ou particulares,
          mediante pagamento da metade do pre&ccedil;o do ingresso efetivamente
          cobrado do p&uacute;blico em geral.
        </li>
        <li>
          <strong>Benefici&aacute;rios:</strong>
        </li>
      </ul>
      <p>
        <strong>
          I- Estudantes - altera&ccedil;&atilde;o na documenta&ccedil;&atilde;o:
        </strong>
        &nbsp;Apresentar a Carteira de Identifica&ccedil;&atilde;o Estudantil -
        CIE - documento que comprova a condi&ccedil;&atilde;o de estudante
        regularmente matriculado nos n&iacute;veis e modalidades de
        educa&ccedil;&atilde;o e ensino previstos no T&iacute;tulo V da Lei
        n&ordm; 9.394, de 1996, conforme modelo &uacute;nico nacionalmente
        padronizado, com certifica&ccedil;&atilde;o digital e que dever&aacute;
        conter: nome completo, data de nascimento do estudante, foto recente,
        nome da institui&ccedil;&atilde;o de ensino, grau de escolaridade e data
        de validade, conforme Se&ccedil;&atilde;o I do Decreto 8.537/2015.
        Ser&aacute; exigida a apresenta&ccedil;&atilde;o da CIE no acesso ao
        evento. Nos termos do artigo 1-A, da Lei 12.933 de 2013, a CIE pode ser
        emitida pelo Minist&eacute;rio da Educa&ccedil;&atilde;o, ANPG, UNE,
        Ubes, bem como pelas entidades estudantis estaduais e municipais, pelos
        Diret&oacute;rios Centrais dos Estudantes (DCE&acute;s), pelos Centros e
        Diret&oacute;rios Acad&ecirc;micos e por outras entidades de ensino e
        associa&ccedil;&otilde;es representativas dos estudantes.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>
            Cabe ressaltar que boleto banc&aacute;rio n&atilde;o &eacute;
            documenta&ccedil;&atilde;o h&aacute;bil para comprovar a
            condi&ccedil;&atilde;o de estudante.
          </strong>
        </li>
        <li>
          <strong>
            II- Jovens de 15 a 29 anos pertencentes a fam&iacute;lias de baixa
            renda:
          </strong>
          V&aacute;lido a partir de 31 de mar&ccedil;o de 2016 inscritos no
          Cadastro &Uacute;nico para Programas Sociais do Governo Federal
          &ndash; Cad&Uacute;nico cuja renda mensal seja de at&eacute; 2 (dois)
          sal&aacute;rios m&iacute;nimos. Os jovens de baixa renda ter&atilde;o
          direito ao benef&iacute;cio da meia-entrada mediante a
          apresenta&ccedil;&atilde;o, no momento da aquisi&ccedil;&atilde;o do
          ingresso e na portaria ou na entrada do local de
          realiza&ccedil;&atilde;o do evento, da Identidade Jovem acompanhada de
          documento de identifica&ccedil;&atilde;o com foto expedido por
          &oacute;rg&atilde;o p&uacute;blico e v&aacute;lido em todo o
          territ&oacute;rio nacional.
        </li>
        <li>
          <strong>III- Deficiente e seu acompanhante:</strong>(este, se
          necess&aacute;rio) Mediante apresenta&ccedil;&atilde;o de documento
          que comprove a condi&ccedil;&atilde;o, de acordo com a Lei Federal
          12.933 de 2013. Apenas um acompanhante, por pessoa com
          defici&ecirc;ncia, ter&aacute; direito ao benef&iacute;cio da
          meia-entrada. De acordo com a Lei Federal n&ordm; 10.741/2003
          (Estatuto do Idoso), os benefici&aacute;rios mencionados abaixo tem
          direito a compra de meia-entrada (conforme previsto na lei, este
          benef&iacute;cio n&atilde;o &eacute; limitado):
        </li>
        <li>
          <strong>IV- Idosos (com idade igual ou superior a 60 anos):</strong>De
          acordo com a Lei Federal n&ordm; 10.741/2003 (Estatuto do Idoso), os
          idosos t&ecirc;m direito ao benef&iacute;cio da meia-entrada (conforme
          previsto na Lei, este benef&iacute;cio n&atilde;o &eacute; limitado).
          Apresentar documento de identidade oficial com foto que comprove a
          condi&ccedil;&atilde;o.
        </li>
        <li>
          <strong>Aten&ccedil;&atilde;o:</strong>Para Pontos de Venda e
          Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do
          direito ao benef&iacute;cio da 1/2 entrada no ato da compra e no
          acesso ao evento. Para vendas pela Internet e Telefone &eacute;
          necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao
          benef&iacute;cio da 1/2 entrada no acesso ao evento.
        </li>
      </ul>
      <p>
        Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador
        dever&aacute; complementar o valor do ingresso adquirido para o valor do
        ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o
        ser&aacute; permitido.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>S&atilde;o Paulo: </strong>
        </li>
        <li>
          Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de
          baixa renda, idoso e deficiente e seu acompanhante.
        </li>
        <li>
          Diretor, Coordenador Pedag&oacute;gico, Supervisor e titular do quadro
          de apoio de escolar Estadual e Municipal Lei Estadual SP 15.298/14.
        </li>
      </ul>
      <p>
        Apresentar documento de identidade oficial com foto e carteira funcional
        da Secretaria de Educa&ccedil;&atilde;o ou Holerite que comprove a
        condi&ccedil;&atilde;o.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>
            Professor da rede p&uacute;blica Estadual e Municipal Lei Estadual
            SP 14.729/2012:
          </strong>
          Apresentar documento de identidade oficial com foto e carteira
          funcional da Secretaria de Educa&ccedil;&atilde;o ou Holerite que
          comprove a condi&ccedil;&atilde;o.
        </li>
        <li>
          <strong>
            Exclusivamente para o munic&iacute;pio de S&atilde;o Paulo:
          </strong>
        </li>
        <li>
          <strong>Aposentados:</strong>Lei Municipal SP n&ordm; 12.325/1997;
          Apresentar documento de identidade oficial com foto e cart&atilde;o de
          benef&iacute;cio do INSS que comprove a condi&ccedil;&atilde;o.
        </li>
        <li>
          <strong>Aten&ccedil;&atilde;o:</strong>Para Pontos de Venda e
          Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do
          direito ao benef&iacute;cio da 1/2 entrada no ato da compra e no
          acesso ao evento. Para vendas pela Internet e Telefone &eacute;
          necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao
          benef&iacute;cio da 1/2 entrada no acesso ao evento.
        </li>
      </ul>
      <p>
        Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador
        dever&aacute; complementar o valor do ingresso adquirido para o valor do
        ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o
        ser&aacute; permitido.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Rio de janeiro</strong>
        </li>
        <li>
          Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de
          baixa renda, idoso e deficiente e seu acompanhante.
        </li>
        <li>
          <strong>Menores de 21 anos: </strong>Lei Estadual RJ n&ordm;
          3.364/2000; Apresentar documento de identidade oficial com foto que
          comprove idade inferior a 21 anos.
        </li>
        <li>
          <strong>Exclusivamente no munic&iacute;pio Rio de Janeiro:</strong>
        </li>
        <li>
          Professores e Profissionais da rede p&uacute;blica Municipal de
          ensino: Lei Municipal RJ n&ordm; 3.424/2002;
          Apresenta&ccedil;&atilde;o da carteira funcional emitida pela
          Secretaria Municipal de Educa&ccedil;&atilde;o
        </li>
        <li>
          <strong>Aten&ccedil;&atilde;o:</strong>Para Pontos de Venda e
          Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do
          direito ao benef&iacute;cio da 1/2 entrada no ato da compra e no
          acesso ao evento. Para vendas pela Internet e Telefone &eacute;
          necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao
          benef&iacute;cio da 1/2 entrada no acesso ao evento.
        </li>
      </ul>
      <p>
        Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador
        dever&aacute; complementar o valor do ingresso adquirido para o valor do
        ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o
        ser&aacute; permitido.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Minas Gerais:</strong>
        </li>
        <li>
          Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de
          baixa renda, idoso e deficiente e seu acompanhante.
        </li>
        <li>
          <strong>Menores de 21 anos:</strong>Lei Municipal de Belo Horizonte
          n&ordm; 9.070/2005; Apresentar documento de identidade oficial com
          foto que comprove a condi&ccedil;&atilde;o.
        </li>
        <li>
          <strong>Aten&ccedil;&atilde;o:</strong>Para Pontos de Venda e
          Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do
          direito ao benef&iacute;cio da 1/2 entrada no ato da compra e no
          acesso ao evento. Para vendas pela Internet e Telefone &eacute;
          necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao
          benef&iacute;cio da 1/2 entrada no acesso ao evento.
        </li>
      </ul>
      <p>
        Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador
        dever&aacute; complementar o valor do ingresso adquirido para o valor do
        ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o
        ser&aacute; permitido.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Rio Grande do Sul</strong>
        </li>
        <li>
          Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de
          baixa renda, idoso e deficiente e seu acompanhante.
        </li>
        <li>
          <strong>Em Porto Alegre:</strong>Menores de 15 anos; Descontos nos
          termos das leis municipais 9989/2006 e 11.211/2012.
        </li>
        <li>
          <strong>Aposentados ou Pensionistas do INSS</strong>(que recebam
          at&eacute; tr&ecirc;s sal&aacute;rios m&iacute;nimos): Lei Municipal
          de Porto Alegre n&ordm; 7.366/1993;
        </li>
      </ul>
      <p>
        Apresentar documento fornecido pela Federa&ccedil;&atilde;o dos
        Aposentados e Pensionistas do Estado do Rio Grande do Sul ou outras
        Associa&ccedil;&otilde;es de Classe devidamente registradas ou filiadas
        &agrave; citada Federa&ccedil;&atilde;o. Deve-se comprovar o
        preenchimento dos requisitos previstos na lei.
      </p>
      <p>
        Para os espet&aacute;culos circenses, Aposentados ou Pensionistas do
        INSS que estiverem enquadrados nos requisitos t&ecirc;m direito &agrave;
        meia-entrada nas Ter&ccedil;as e nas Quintas.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Doadores regulares de sangue</strong>(registrados no
          hemocentro e nos bancos de sangue dos hospitais do Estado): Lei
          Estadual RS n&ordm; 13.891/2012;
        </li>
      </ul>
      <p>
        Apresentar documento de identidade oficial com foto e carteira de
        controle das doa&ccedil;&otilde;es expedida pela Secretaria de Estado da
        Sa&uacute;de ou pelos hemocentros e bancos de sangue do Estado do Rio
        Grande do Sul, dentro do prazo de validade.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Aten&ccedil;&atilde;o:</strong>Para Pontos de Venda e
          Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do
          direito ao benef&iacute;cio da 1/2 entrada no ato da compra e no
          acesso ao evento. Para vendas pela Internet e Telefone &eacute;
          necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao
          benef&iacute;cio da 1/2 entrada no acesso ao evento.
        </li>
      </ul>
      <p>
        Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador
        dever&aacute; complementar o valor do ingresso adquirido para o valor do
        ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o
        ser&aacute; permitido.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Rio Grande do Norte</strong>
        </li>
        <li>
          Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de
          baixa renda, idoso e deficiente e seu acompanhante.
        </li>
        <li>
          <strong>Exclusivamente para o munic&iacute;pio de Natal:</strong>
        </li>
        <li>
          MENORES DE 7 ANOS - Lei Municipal de Natal n&ordm;6.503/93 e 4.743/96;
        </li>
      </ul>
      <p>
        Apresentar documento de identidade oficial com foto que comprove idade
        inferior a 7 anos.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Aten&ccedil;&atilde;o:</strong>Para Pontos de Venda e
          Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do
          direito ao benef&iacute;cio da 1/2 entrada no ato da compra e no
          acesso ao evento. Para vendas pela Internet e Telefone &eacute;
          necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao
          benef&iacute;cio da 1/2 entrada no acesso ao evento.
        </li>
      </ul>
      <p>
        Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador
        dever&aacute; complementar o valor do ingresso adquirido para o valor do
        ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o
        ser&aacute; permitido.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Paran&aacute;</strong>
        </li>
        <li>
          Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de
          baixa renda, idoso e deficiente e seu acompanhante.
        </li>
        <li>
          <strong>Doadores regulares de sangue</strong>(registrados no
          hemocentro e nos bancos de sangue dos hospitais do Estado): Lei
          Estadual PR n&ordm; 13.964/2002;
        </li>
      </ul>
      <p>
        Apresentar documento de identidade oficial com foto e documento oficial
        expedido pela Secretaria de Estado da Sa&uacute;de que comprove a
        condi&ccedil;&atilde;o.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>
            Professores da rede p&uacute;blica e particular Estadual
          </strong>
          :
        </li>
        <li>Lei Estadual PR n&ordm; 15.876/2008;</li>
      </ul>
      <p>
        Apresentar documento de identidade oficial com foto e comprovante de
        v&iacute;nculo empregat&iacute;cio com a institui&ccedil;&atilde;o de
        ensino que comprove a condi&ccedil;&atilde;o.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Aten&ccedil;&atilde;o:</strong>Para Pontos de Venda e
          Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do
          direito ao benef&iacute;cio da 1/2 entrada no ato da compra e no
          acesso ao evento. Para vendas pela Internet e Telefone &eacute;
          necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao
          benef&iacute;cio da 1/2 entrada no acesso ao evento.
        </li>
      </ul>
      <p>
        Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador
        dever&aacute; complementar o valor do ingresso adquirido para o valor do
        ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o
        ser&aacute; permitido.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Santa Catarina</strong>
        </li>
        <li>
          Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de
          baixa renda, idoso e deficiente e seu acompanhante.
        </li>
        <li>
          <strong>Menores de 18 anos</strong>: Lei Estadual SC n&ordm;
          12.570/2003;
        </li>
      </ul>
      <p>
        Apresentar documento de identidade oficial com foto que comprove idade
        inferior a 18 anos.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Doadores de Sangue</strong>(fundamenta&ccedil;&atilde;o legal
          - Lei Estadual 14.132/2007):
        </li>
      </ul>
      <p>
        Documento oficial de doador de sangue, emitido pelos hemocentros e
        bancos de sangue do Estado de Santa Catarina, devidamente registrados.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>
            Professores de educa&ccedil;&atilde;o infantil, de ensino
            fundamental, m&eacute;dio e superior das institui&ccedil;&otilde;es
            de ensino p&uacute;blicas e particulares do Munic&iacute;pio de
            Florian&oacute;polis e Professores da educa&ccedil;&atilde;o
            b&aacute;sica (ensino infantil, fundamental e m&eacute;dio) do
            Estado de Santa Catarina
          </strong>
          (fundamenta&ccedil;&atilde;o legal - Lei Estadual 16.448/2014 e Lei
          Municipal 8.019/2009):
        </li>
      </ul>
      <p>
        Comprovante de recebimento salarial atualizado e/ou contracheque que
        identifique o &oacute;rg&atilde;o e/ou estabelecimento de ensino
        empregador, funcion&aacute;rio e o cargo que ocupa, al&eacute;m da
        apresenta&ccedil;&atilde;o do documento de identidade oficial com foto
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Aten&ccedil;&atilde;o:</strong>Para Pontos de Venda e
          Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do
          direito ao benef&iacute;cio da 1/2 entrada no ato da compra e no
          acesso ao evento. Para vendas pela Internet e Telefone &eacute;
          necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao
          benef&iacute;cio da 1/2 entrada no acesso ao evento.
        </li>
      </ul>
      <p>
        Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador
        dever&aacute; complementar o valor do ingresso adquirido para o valor do
        ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o
        ser&aacute; permitido.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Goi&aacute;s</strong>
        </li>
        <li>
          <strong>
            Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de
            baixa renda, idoso e deficiente e seu acompanhante.
          </strong>
        </li>
        <li>
          <strong>
            Professores da rede p&uacute;blica e Estadual e Municipal de ensino:
          </strong>
          Lei Estadual GO n&ordm; 14.975/2004, 17.396/2011 e 17.575/2012;
        </li>
      </ul>
      <p>
        Apresentar documento de identidade oficial com foto e comprovante de
        v&iacute;nculo empregat&iacute;cio com a institui&ccedil;&atilde;o de
        ensino que comprove a condi&ccedil;&atilde;o.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>
            Exclusivamente no munic&iacute;pio de Goi&acirc;nia: Doadores
            regulares de sangue (registrados perante a Secretaria Municipal de
            Sa&uacute;de/banco de sangue): Lei Municipal n&ordm; 8.558/2007:{" "}
          </strong>
          Apresentar documento de identidade oficial com foto e documento
          oficial expedido pela Secretaria de Estado da Sa&uacute;de/banco de
          sangue, v&aacute;lido e vigente.
        </li>
        <li>
          <strong>
            Exclusivamente em Distrito Federal &ndash; Bras&iacute;lia:
          </strong>
        </li>
        <li>
          Professores (do sistema de ensino da rede p&uacute;blica e particular
          - em atividade e aposentados) - Lei Distrital n&ordm; 3.516/2004:
          Apresentar documento de identidade oficial com foto e holerite
          (contracheque) que comprove a condi&ccedil;&atilde;o.
        </li>
        <li>
          <strong>Aten&ccedil;&atilde;o:</strong>Para Pontos de Venda e
          Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do
          direito ao benef&iacute;cio da 1/2 entrada no ato da compra e no
          acesso ao evento. Para vendas pela Internet e Telefone &eacute;
          necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao
          benef&iacute;cio da 1/2 entrada no acesso ao evento.
        </li>
      </ul>
      <p>
        Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador
        dever&aacute; complementar o valor do ingresso adquirido para o valor do
        ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o
        ser&aacute; permitido.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Bahia</strong>
        </li>
        <li>
          <strong>
            Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de
            baixa renda, idoso e deficiente e seu acompanhante.
          </strong>
        </li>
        <li>
          <strong>Doadores regulares de sangue</strong>(Aqueles registrados nos
          hemocentros e bancos de sangue dos hospitais p&uacute;blicos ou
          privados do Estado da Bahia): Lei Estadual BA n&ordm; 13.183/2014;
        </li>
      </ul>
      <p>
        Apresentar documento que comprove a condi&ccedil;&atilde;o no acesso ao
        evento e na compra presencial.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Aten&ccedil;&atilde;o</strong>: Para Pontos de Venda e
          Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do
          direito ao benef&iacute;cio da 1/2 entrada no ato da compra e no
          acesso ao evento. Para vendas pela Internet e Telefone &eacute;
          necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao
          benef&iacute;cio da 1/2 entrada no acesso ao evento.
        </li>
      </ul>
      <p>
        Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador
        dever&aacute; complementar o valor do ingresso adquirido para o valor do
        ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o
        ser&aacute; permitido.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Cear&aacute;</strong>
        </li>
        <li>
          <strong>
            Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de
            baixa renda, idoso e deficiente e seu acompanhante.
          </strong>
        </li>
        <li>
          <strong>
            Professores da rede p&uacute;blica Municipal&nbsp;(apenas para
            cinemas, teatros e exposi&ccedil;&otilde;es art&iacute;sticas)
          </strong>
          : Lei Municipal de Fortaleza n&ordm; 9.214/2007;
        </li>
      </ul>
      <p>
        Apresentar documento de identidade oficial com foto e carteira expedida
        pela Secretaria Municipal de Educa&ccedil;&atilde;o e Assist&ecirc;ncia
        Social (SEDAS).
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Aten&ccedil;&atilde;o</strong>: Para Pontos de Venda e
          Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do
          direito ao benef&iacute;cio da 1/2 entrada no ato da compra e no
          acesso ao evento. Para vendas pela Internet e Telefone &eacute;
          necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao
          benef&iacute;cio da 1/2 entrada no acesso ao evento.
        </li>
      </ul>
      <p>
        Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador
        dever&aacute; complementar o valor do ingresso adquirido para o valor do
        ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o
        ser&aacute; permitido.
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <strong>Mato Grosso do Sul</strong>
        </li>
        <li>
          <strong>
            Exclusivamente para o Munic&iacute;pio de Campo Grande:
          </strong>
        </li>
        <li>
          <strong>
            Professores da rede Municipal p&uacute;blica e privada de ensino -
            Lei n&ordm; 4341 de 29 de novembro de 2005:
          </strong>
          Apresentar a carteira funcional emitida pelo respectivo sindicato da
          categoria.
        </li>
      </ul>
      <p>
        Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a
        comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da 1/2 entrada
        no ato da compra e no acesso ao evento. Para vendas pela Internet e
        Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do
        direito ao benef&iacute;cio da 1/2 entrada no acesso ao evento.
      </p>
      <p>
        Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador
        dever&aacute; complementar o valor do ingresso adquirido para o valor do
        ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o
        ser&aacute; permitido.
      </p>
      <br />

      <h2>10. Como funciona a lei da meia-entrada?</h2>
      <p>
        De acordo com a Lei Federal n&ordm;12.933/2013, &eacute; assegurada a
        meia-entrada para acesso a salas de cinema, cineclubes, teatros,
        espet&aacute;culos musicais e circenses e eventos educativos,
        esportivos, de lazer e de entretenimento, em todo o territ&oacute;rio
        nacional, promovidos por quaisquer entidades e realizados em
        estabelecimentos p&uacute;blicos ou particulares, mediante pagamento da
        metade do pre&ccedil;o do ingresso efetivamente cobrado do
        p&uacute;blico em geral. Al&eacute;m de eventos
        art&iacute;stico-culturais e esportivos, conforme Decreto
        n&ordm;8.537/2015.
      </p>
      <p>
        Produtores podem limitar em 40% a venda de ingressos de meia-entrada
        para o p&uacute;blico previsto na legisla&ccedil;&atilde;o nacional e
        local do seu estado e/ou cidade.
      </p>
      <p>
        Para mais informa&ccedil;&otilde;es, acesse a{" "}
        <a href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2013/lei/l12933.htm">
          <strong>
            <u>Lei Federal da Meia-Entrada</u>
          </strong>
        </a>
        &nbsp;e o{" "}
        <a href="http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2015/Decreto/D8537.htm">
          <strong>
            <u>Decreto</u>
          </strong>
        </a>
        &nbsp;que a regulamenta.
      </p>
      <br />

      <h2>●&nbsp;Posso ultrapassar o limite de 40%?</h2>
      <p>
        Sim! Se voc&ecirc; desejar, pode extrapolar o limite estabelecido por
        lei e vender al&eacute;m dessa porcentagem. S&oacute; n&atilde;o pode
        vender menos, ok?
      </p>
      <br />

      <h2>●&nbsp;Como funcionam as Leis Regionais?</h2>
      <p>
        Existem leis de meia-entrada publicadas em alguns Estados e
        Munic&iacute;pios brasileiros, que s&atilde;o as Leis Regionais.
        Ent&atilde;o, caso seu evento ocorra em algum estado que possui uma Lei
        de meia-entrada espec&iacute;fica, voc&ecirc; deve considerar a Lei
        Federal e a Lei Estadual. E o mesmo vale para as leis Municipais, caso
        seu evento ocorra em alguma cidade com a pol&iacute;tica vigente. Para
        te ajudar, separamos alguns exemplos de leis Estaduais e Municipais.
        Confira:
      </p>
      <h5>
        ➔&nbsp;<strong>Minas Gerais (MG): </strong>Estudantes regularmente
        matriculados em estabelecimentos de ensino de 1&ordm;, 2&ordm; e 3&ordm;
        graus em casas de divers&atilde;o, de espet&aacute;culos teatrais,
        musicais e circenses, em casas de exibi&ccedil;&atilde;o
        cinematogr&aacute;fica, em pra&ccedil;as esportivas e similares das
        &aacute;reas de esporte, cultura e lazer do Estado de Minas Gerais (Lei
        Estadual 11052, de 24/03/1993). &Eacute; necess&aacute;rio apresentar
        documento do respectivo estabelecimento de ensino e emitida pela
        Uni&atilde;o Nacional dos Estudantes (UNE), Uni&atilde;o Brasileira dos
        Estudantes Secundaristas (Ubes) ou Uni&atilde;o Colegial de Minas Gerais
        (UCMG) e distribu&iacute;da pelas respectivas entidades filiadas, tais
        como Uni&atilde;o Estadual dos Estudantes, uni&otilde;es municipais,
        diret&oacute;rios centrais de estudantes, diret&oacute;rios
        acad&ecirc;micos, centros acad&ecirc;micos e gr&ecirc;mios estudantis.
      </h5>
      <h5>
        ➔&nbsp;<strong>Belo Horizonte (BH):</strong>&nbsp;Os menores de 21 anos
        t&ecirc;m direito &agrave; meia-entrada, de acordo com a Lei Municipal
        de Belo Horizonte 9.070/2005.
      </h5>
      <h5>
        ➔&nbsp;<strong>S&atilde;o Paulo (SP):</strong>&nbsp;Diretores,
        coordenadores pedag&oacute;gicos, supervisores e titulares do quadro de
        apoio de escolar estadual e municipal (Lei Estadual SP 15.298/14) e
        Professores da rede p&uacute;blica estadual e municipal (Lei Estadual SP
        14.729/2012). &Eacute; necess&aacute;rio apresentar documento de
        identidade oficial com foto e carteira funcional da Secretaria de
        Educa&ccedil;&atilde;o ou Holerite que comprove a
        condi&ccedil;&atilde;o.
      </h5>
      <h5>
        ➔&nbsp;<strong>Rio de Janeiro (RJ):</strong>&nbsp;Os menores de 21 anos
        t&ecirc;m direito &agrave; meia-entrada, de acordo com a Lei Estadual RJ
        n&ordm; 3.364/2000.
      </h5>
      <h5>
        ➔&nbsp;<strong>Rio de Janeiro (cidade):</strong>&nbsp;Os professores e
        profissionais da rede p&uacute;blica municipal de ensino t&ecirc;m
        direito &agrave; meia-entrada de acordo com a Lei Municipal do Rio de
        Janeiro n&ordm; 3.424/2002, mediante apresenta&ccedil;&atilde;o da
        carteira funcional emitida pela Secretaria Municipal de
        Educa&ccedil;&atilde;o.
      </h5>
      <h5>
        ➔&nbsp;<strong>Rio Grande do Sul (RS): </strong>Doadores regulares de
        sangue, desde que registrados no hemocentro e nos bancos de sangue dos
        hospitais do Estado, de acordo com a Lei Estadual do Rio Grande do Sul
        &nbsp;n&ordm; 13.891/2012, apresentando documento de identidade oficial
        com foto e carteira de controle das doa&ccedil;&otilde;es expedida pela
        Secretaria de Estado da Sa&uacute;de ou pelos hemocentros e bancos de
        sangue do Estado do Rio Grande do Sul, dentro do prazo de validade.
      </h5>
      <h5>
        ➔&nbsp;<strong>Porto Alegre (RS):</strong>&nbsp;Os menores de 15 anos
        t&ecirc;m direito &agrave; meia-entrada, de acordo com as Leis
        Municipais de Porto Alegre 9.989/2006 e 11.211/2012. Aposentados ou
        pensionistas do INSS, desde que recebam at&eacute; tr&ecirc;s
        sal&aacute;rios m&iacute;nimos, de acordo com a Lei Municipal de Porto
        Alegre 7.366/1993, devem apresentar documento fornecido pela
        Federa&ccedil;&atilde;o dos Aposentados e Pensionistas do Estado do Rio
        Grande do Sul ou outras Associa&ccedil;&otilde;es da Classe, devidamente
        registradas ou filiadas &agrave; citada Federa&ccedil;&atilde;o.
      </h5>
      <h5>
        ➔&nbsp;<strong>Goi&aacute;s (GO):</strong>&nbsp;Os professores e
        profissionais da rede p&uacute;blica municipal e estadual de ensino
        t&ecirc;m direito &agrave; meia-entrada de acordo com a Lei Estadual de
        Goi&aacute;s n&ordm; 14.975/2004, 17.396/2011 e 17.575/2012, mediante
        apresenta&ccedil;&atilde;o de documento de identidade oficial com foto e
        comprovante de v&iacute;nculo de emprego com a institui&ccedil;&atilde;o
        de ensino.
      </h5>
      <h5>
        ➔&nbsp;<strong>Goi&acirc;nia (GO):</strong>&nbsp;Doadores regulares de
        sangue, desde que registrados perante a Secretaria Municipal de
        Sa&uacute;de ou banco de sangue, de acordo com a Lei Municipal n&ordm;
        8.558/2007, apresentando documento de identidade oficial com foto e
        documento oficial expedido pela Secretaria de Estado da Sa&uacute;de ou
        banco de sangue, v&aacute;lido e vigente.
      </h5>
      <h5>
        ➔&nbsp;<strong>Pernambuco (PE): </strong>Os professores vinculados a
        institui&ccedil;&otilde;es de ensino publicamente reconhecidos no Estado
        de Pernambuco t&ecirc;m direito &agrave; meia-entrada de acordo com a
        Lei Estadual 12.258, de 22 de agosto de 2002, mediante
        apresenta&ccedil;&atilde;o obrigat&oacute;ria de carteira funcional
        emitida pela Secretaria Estadual de Educa&ccedil;&atilde;o, Carteira
        Profissional, documento de comprova&ccedil;&atilde;o de
        filia&ccedil;&atilde;o a institui&ccedil;&atilde;o representativa de
        professores ou servidores de institui&ccedil;&otilde;es de ensino ou
        qualquer outro documento p&uacute;blico que comprove o preenchimento dos
        requisitos previstos na Lei, al&eacute;m de Documento de Identidade
        oficial com foto, expedido por &oacute;rg&atilde;o p&uacute;blico e
        v&aacute;lido em todo territ&oacute;rio nacional, original ou
        c&oacute;pia autenticada. Os portadores de c&acirc;ncer e seu
        acompanhante (quando comprovada a necessidade de acompanhamento)
        t&ecirc;m direito a meia-entrada, conforme Lei Estadual 15.724/2016,
        &nbsp;mediante apresenta&ccedil;&atilde;o obrigat&oacute;ria do atestado
        m&eacute;dico contendo a classifica&ccedil;&atilde;o internacional da
        doen&ccedil;a (CID) fornecido por um profissional cadastrado no Sistema
        &Uacute;nico de Sa&uacute;de (SUS), expedido em at&eacute; um ano antes
        de sua apresenta&ccedil;&atilde;o e Documento de Identidade oficial com
        foto, expedido por &oacute;rg&atilde;o p&uacute;blico e v&aacute;lido em
        todo territ&oacute;rio nacional.
      </h5>
      <h5>
        ➔&nbsp;<strong>Recife (PE): </strong>Os professores da rede municipal de
        ensino t&ecirc;m direito &agrave; meia-entrada de acordo com a Lei
        Municipal de Recife n&ordm; 16.902/2003, mediante
        apresenta&ccedil;&atilde;o de documento de identidade oficial com foto e
        holerite.
      </h5>
      <p>
        Para informa&ccedil;&otilde;es sobre pol&iacute;ticas de outros Estados
        ou Munic&iacute;pios, indicamos que verifique a legisla&ccedil;&atilde;o
        no{" "}
        <a href="http://www4.planalto.gov.br/legislacao">
          <strong>
            <u>site do Planalto</u>
          </strong>
        </a>
        .
      </p>
      <br />

      <h2>
        11. Como definir a lota&ccedil;&atilde;o m&aacute;xima do meu evento?
      </h2>
      <p>
        Ao realizar um evento, &eacute; indispens&aacute;vel respeitar as regras
        de lota&ccedil;&atilde;o estabelecidas pela casa ou realizar o
        c&aacute;lculo corretamente de pessoas por metro quadrado de acordo com
        o espa&ccedil;o onde o evento se realizar&aacute;.
      </p>
      <p>
        Devido ao COVID-19, deixamos aqui ressaltado que &eacute;
        important&iacute;ssimo o organizador buscar informa&ccedil;&otilde;es
        das restri&ccedil;&otilde;es diretamente com as prefeituras dos estados
        que pretendem organizar o evento.
      </p>
      <p>
        Em m&eacute;dia, o c&aacute;lculo de pessoas por metro quadrado em
        lugares onde v&atilde;o estar sentadas varia entre uma a duas pessoas
        por metro quadrado, enquanto para eventos onde as pessoas ficar&atilde;o
        em p&eacute;, &eacute; poss&iacute;vel acomodar de 3 a 9 pessoas.
      </p>
      <p>
        A maioria das casas de show j&aacute; possui o n&uacute;mero de pessoas
        para estipula&ccedil;&atilde;o de lota&ccedil;&atilde;o m&aacute;xima do
        evento, que &eacute; requisito necess&aacute;rio na
        obten&ccedil;&atilde;o do alvar&aacute; e &eacute; muito importante
        n&atilde;o ultrapassar esse n&uacute;mero, j&aacute; que ele &eacute;
        estabelecido em conjunto com o Corpo de Bombeiros para prezar pela
        seguran&ccedil;a e conforto dos participantes.
      </p>
      <p>
        A permiss&atilde;o da entrada de um n&uacute;mero maior de pessoas do
        que o estabelecido na lota&ccedil;&atilde;o m&aacute;xima do evento
        sujeita o organizador a multas administrativas pela Prefeitura e embargo
        do evento pelo Corpo de Bombeiros, al&eacute;m de aumentar o risco de
        que algum acidente aconte&ccedil;a em seu evento.
      </p>
      <p>
        Recomendamos, no caso de qualquer d&uacute;vida, que verifique com a
        Prefeitura da cidade de seu evento qual o c&aacute;lculo
        utiliz&aacute;vel para lota&ccedil;&atilde;o m&aacute;xima.
      </p>
    </>
  );
};

export default OrganizerObligations;
