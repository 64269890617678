export const Colors = {
  background: "#ffffff",
  primary100: "#D40050",
  primary90: "#D81A62",
  primary80: "#DD3373",
  primary70: "#E14D85",
  primary60: "#E56696",
  primary50: "#E980A7",
  primary40: "#EE99B9",
  primary30: "#F2B3CB",
  primary20: "#F6CCDC",
  primary10: "#FBE5ED",
  primary5: "#FDE8EC",

  secondary100: "#241058",
  secondary90: "#3A2869",
  secondary80: "#504079",
  secondary70: "#66588A",
  secondary60: "#7C709B",
  secondary50: "#9187AB",
  secondary40: "#A79FBC",
  secondary30: "#BDB7CD",
  secondary20: "#D3CFDE",
  secondary10: "#E9E7EE",

  tertiary100: "#A2004C",
  tertiary60: "#C76694",
  tertiary40: "#DA99B7",
  tertiary20: "#ECCCDB",
  tertyary10: "#FFF8FA",

  black100: "#11142D",
  black90: "#292C42",
  black80: "#414357",
  black70: "#585B6C",
  black60: "#707281",
  black50: "#888996",
  black40: "#A0A1AB",
  black30: "#B8B9C0",
  black20: "#CFD0D5",
  black10: "#E7E7EA",
  black5: "#f8f8f8",
  black2: "#f8f8fa",

  white: "#ffffff",
  white10: " #f9f9f9",
  white20: "#f4f3f4",
  white30: "#f6f6f6",

  error: "#E05E5E",
  error10: "#FF4D4F",
  error20: "#e14848",

  red10: "#DE665E",
  red0: "#FCEFEF",

  success: "#93C77B",

  green10: "#8BD17F",
  green20: "#93c77b",
  success100: "#71e083",

  purple10: "#ad9bdb",
  purple20: "#66588A",

  pink30: "#e91a44",

  youtubeBackground: "#FF2B2B",
  twitterBackground: "#3CB5DB",
  linkedinBackground: "#286FA3",
  instagramBackground: "#FD3E77",

  bgWhatsapp: "rgba(37, 211, 102, 0.1)",
  bgTwitter: "rgba(60, 181, 219, 0.1)",
  bgFacebook: "rgba(59, 89, 152, 0.1)",
  bgInstagram: "rgba(253, 62, 119, 0.1)",
  bgEmail: "rgba(219, 74, 57, 0.1)",
  bgLinkedin: "rgba(14, 118, 168, 0.1)",

  black: "#000000",

  yellow10: "#FDFCEE",
  yellow100: "#E7E04D",

  backgroundGreen: "#f5faf2",
  backgroundPink: "#fcfaff",

  lightgray: "#eae8ef",

  border: "#e1e1e1",
  border10: "#f4f3f7",
  border20: "#f0f0f0",
  border30: "#f0eeff",
  border40: "#e9e7ee",
  border50: "#e7e7ea",
  border60: "#f1f1f1",
  border70: "#eaeaea",

  gray: "#aaaaaa",

  gray10: "#636173",
  gray15: "#848287",
  gray20: "#adadad",
  gray30: "#C4C4C4",
  gray40: "#888996",
  gray50: "#D0D0D0",
  gray55: "#e2e2e2",
  gray60: "#e7e7e7",
};
