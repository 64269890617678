import { Button } from "antd";
import styled from "styled-components";
import { Colors } from "constants/colors";

export const PrimaryButton = styled(Button)`
  width: 150px;
  height: 38px;
  border-radius: 8px;
  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: 0;
  :hover,
  :active,
  :focus {
    background-color: ${Colors.primary100};
    color: #fff;
    border-color: ${Colors.tertiary100};
  }
`;
export const SecondaryButton = styled(Button)`
  width: 150px;
  height: 38px;
  border-radius: 8px;
  background-color: #FBE5ED;
  color: ${Colors.primary100};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: 0;
  :hover,
  :active,
  :focus {
    background-color: #FBE5ED;
    color: ${Colors.primary100};
    border-color: ${Colors.tertiary100};
  }
  :disabled{
    background-color: #E7E7EA;
    color: #B8B9C0;
  }
`;
