import { Colors } from "constants/colors";
import styled from "styled-components";

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
  isFull?: boolean;
}

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: ${(props) => (props.isFull ? "100%" : "49%")};
  display: flex;
  flex-direction: column;

  margin-bottom: 25px;

  div.container-password {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};

    svg {
      cursor: pointer;
    }

    input {
      width: 100%;
      outline: 0;
      border: 0;
      background: transparent;
      padding: 0px 0px 0px 23px;
      color: ${Colors.secondary70};
      font-size: 20px;

      @media (max-width: 800px) {
        width: 100%;
        font-size: 12px;
      }
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-left: 13px;
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: normal;
    font-size: ${true ? "14px" : "20px"};
    line-height: 160%;

    /* @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    } */
  }

  /* @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  } */
`;
