import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import {
  Container,
  ContainerItems,
  ButtonPrev,
  ButtonNext,
  Number,
} from "./styles";

export function PaginationEmpty() {
  return (
    <Container>
      <ContainerItems>
        <div className="pages">
          <h2>Mostrando 2 de 2 integrações</h2>
        </div>
        <div className="buttons">
          <ButtonPrev>
            <BiChevronLeft size={24} color="#B8B9C0" />
            <span>Anterior</span>
          </ButtonPrev>

          <Number active={true}>
            <h2>1</h2>
          </Number>

          <ButtonNext>
            <span>Próximo</span>
            <BiChevronRight size={24} color="#B8B9C0" />
          </ButtonNext>
        </div>
      </ContainerItems>
    </Container>
  );
}
