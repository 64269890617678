import styled from "styled-components";
import {
  Container as DefaultContainer,
  Section as DefaultSection,
} from "../styles";

import FirstSectionBg from "assets/background-landing.svg";
// import BigDots from "assets/big_dots.svg";
// import SmallDots from "assets/small_dots.svg";
// import Evento from "assets/evento_heading.svg";
import AppStore from "assets/selo_app_store.svg";
import GooglePlay from "assets/selo_google_play.svg";

export const Section = styled(DefaultSection)`
  display: flex;
  flex-direction: row;

  &.first-section {
    padding-top: 210px;
    padding-left: 80px;
    padding-right: 80px;

    display: flex;

    .content {
      width: 59%;
    }
    .photo {
      position: relative;
      margin-top: -114px;
    }

    .life-is {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 1.57rem;
      margin-bottom: 0px;
      color: ${(props) => props.theme.colors.white};
      filter: drop-shadow(0px 4px 10px hsla(0, 0%, 0%, 0.25));
      padding-left: 12px;
    }

    .evento-heading {
      width: 642px;
      height: auto;
      margin: 12px 0;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 12px;
    }

    .description {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: medium;
      font-size: 1.3rem;
      /* line-height: 160%; */
      color: ${(props) => props.theme.colors.white};
      filter: drop-shadow(0px 4px 10px hsla(0, 0%, 0%, 0.25));
      width: 90%;
      margin-bottom: 52px;
      padding-left: 12px;
    }

    .download-section {
      position: relative;
    }

    .download {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      /* line-height: 160%; */
      color: ${(props) => props.theme.colors.white};
      margin-bottom: 0px;
      filter: drop-shadow(0px 4px 10px hsla(0, 0%, 0%, 0.25));
      width: 800px;
      padding-left: 12px;
    }
    .container-download {
      display: flex;
      padding-left: 12px;

      @media (max-width: 440px) {
        padding-left: 0px;
      }
    }

    .download-button {
      width: 166px;
      height: 57px;
      margin-top: 20px;
      margin-right: 20px;
    }

    /* .download-button.app-store {
      background: url(${AppStore});
      background-repeat: no-repeat;
      background-size: contain;
    }
    .download-button.google-play {
      background: url(${GooglePlay});
      background-repeat: no-repeat;
      background-size: contain;
    } */
    .woman-phone {
      width: 120%;
      height: 100%;
      margin-left: -100px;
    }
  }

  @media screen and (max-width: 1366px) {
    &.first-section {
      padding-left: 30px;
      padding-right: 30px;

      /* .evento-heading {
        width: 70%;
      } */

      .description {
        width: 100%;
        text-align: left;
      }

      .content {
        width: 50%;
        margin-right: 6em;
      }

      .photo {
        position: relative;
        margin-top: -34px;
      }

      .woman-phone {
        width: 120%;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &.first-section {
      flex-direction: column;
      height: fit-content;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 104px;
      .download {
        width: 100%;
        text-align: left;
      }
      .description {
        margin-bottom: 12px;
      }
      .container-download {
        justify-content: flex-start;
        flex-wrap: no-wrap;
      }

      .content {
        width: 100%;
        margin-right: 0;
      }

      .download-section {
        justify-content: flex-start;
      }

      .photo {
        position: relative;
        margin-top: 50px;
        align-self: center;
        text-align: center;

        /* width: 50%; */
      }

      .woman-phone {
        width: 100%;
        height: 100%;
        transform: translate(0%, 0%);
      }
    }
  }

  @media screen and (max-width: 440px) {
    &.first-section {
      padding: 0.8rem;
      padding-top: 104px;

      .woman-phone {
        transform: translate(0%, 0%);
        margin: 0 auto;
        /* margin-top: -8px; */
        margin-bottom: -14px;
      }

      .description {
        font-size: 12px;
      }

      .download {
        font-size: 12px;
      }

      .life-is {
        font-size: 16px;
      }

      .evento-heading {
        width: 182px;
        height: 39px;
      }

      .download-button {
        width: 98%;
        height: 48px;
      }
    }
  }
`;

export const Container = styled(DefaultContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.first-container {
    background: url(${FirstSectionBg});
    background-position: 70% 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 740px;

    .dots:nth-child(1) {
      width: 404px;
      height: 200px;
      position: absolute;
      top: 120px;
      right: 0;
    }
    .dots:nth-child(2) {
      width: 504px;
      position: absolute;
      top: 600px;
      left: -120px;
      height: 124px;
    }
  }

  @media screen and (max-width: 1024px) {
    &.first-container {
      height: fit-content;

      .dots:nth-child(1) {
        width: auto;
        height: 77px;
        top: calc(100% - 108px);
        right: -16px;
      }

      .dots:nth-child(2) {
        width: auto;
        height: 40px;
        top: calc(39vh + 150px);
        left: -50px;
      }
    }
  }
`;
