import { useEffect, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { useSWRConfig } from "swr";

import { Spin, Checkbox, Drawer, Select } from "antd";

import ReactToPrint, { useReactToPrint } from "react-to-print";
import Html2Pdf from "js-html2pdf";
import QRCode from "react-qr-code";

import { LoadingOutlined } from "@ant-design/icons";
import {
  RiCalendar2Line,
  RiCloseCircleLine,
  RiQuestionLine,
} from "react-icons/ri";
import { IoMdDownload } from "react-icons/io";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { BsFillEyeFill } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";

import { useUserTicket } from "services/Hook/User";
import { useTabsTicketsAndPurchases } from "contexts/TabsTicketsAndPurchasesContext";

import { Colors } from "constants/colors";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";
import { date } from "utils/masks";

import { Purchase as PurchaseType } from "types";

import {
  Container,
  ContainerItems,
  ContainerAllItems,
  ContainerAllItemsMobile,
  CardTicket,
  ListAccordion,
  CardAccordion,
  DataDropDown,
  ContainerBorder,
  ContainerButtons,
  ButtonVisualizationTicket,
  ButtonDownload,
  ContainerHeader,
  ContainerFilters,
  ContainerDrawer,
  InputContainer,
  ModalStyled,
  ContainerModalTicketDetail,
  BackgroundTicket,
  ContainerContentModalTicket,
  DivLogo,
  Center,
  DivEmpty,
  CenterTitle,
  DivBorder,
  ContainerBottom,
  ButtonPrintTicket,
  ContainerButtonBack,
  ButtonBack,
} from "./styles";

import Popup from "containers/Popup";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { TicketForPrint } from "components/TicketForPrint";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { SidebarProfile } from "components/SidebarProfile";
import { Pagination } from "components/Pagination";
import { Title } from "components/Title";

import ticket_profile from "assets/ticket-profile.svg";
import print_ticket_icon from "assets/print-ticket-icon.svg";
import event_ticket from "assets/event-ticket.svg";
import TabsTicketsAndPurchases from "../TabTicketAndPurchase";

const { Option } = Select;

interface PurchaseInteface {
  id: number;
  purchase_code: number;
}

interface Event {
  id: string;
  name: string;
  thumb: string;
  photo: string;
  address_name: string;
  address_street: string;
  begin_date: string;
  formattedDate: string;
  formattedHour: string;
}

interface Ticket {
  id: string;
  holder_name: string;
  holder_email: string;
  event: Event;
  purchase: PurchaseInteface;
  isOpen: boolean;
  formattedDate: string;
  ticket: {
    name: string;
  };
}

type Meta = {
  total: number;
  last_page: number;
};

interface PurchaseInteface {
  data: PurchaseType[];
  meta: Meta;
}

interface TicketsInterface {
  data: Ticket[];
  meta: Meta;
}

export default function MyTickets() {
  const {
    selectedTicketMobile,
    selectedPurchaseMobile,
    closeTicket,
    closePurchase,
  } = useTabsTicketsAndPurchases();

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const [pageTicket, setPageTicket] = useState(1);

  const [visibleMobile, setVisibleMobile] = useState(false);

  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);

  const componentRef = useRef(null);

  const { mutate } = useSWRConfig();

  const { data: dataTickets, partialUrl } =
    useUserTicket<TicketsInterface>(pageTicket);

  const handleOpenDropTicketEvent = (ticket: Ticket) => {
    setSelectedTicket(ticket);

    mutate(
      [partialUrl, 1],
      () => {
        const newMapTickets = dataTickets?.data.map((ticketMap) =>
          ticketMap.id === ticket.id
            ? { ...ticketMap, isOpen: !ticketMap.isOpen }
            : ticketMap
        );

        return {
          ...dataTickets,
          data: newMapTickets,
        };
      },
      false
    );
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  const onClose = () => {
    setVisible(false);
  };

  const onCloseMobile = () => {
    setVisibleMobile(false);
  };

  const handleKeyUpDate = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      date(e);
    },
    []
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModalTicket = (ticket: Ticket) => {
    setSelectedTicket(ticket);
    showModal();
  };

  const onBeforeGetContentResolve = useRef<(() => void) | null>(null);

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("Some cool text from the parent");

  const handleOnBeforeGetContent = useCallback(() => {
    setLoading(true);
    setText("Loading new text...");

    return new Promise<void>((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current, setSelectedTicket, selectedTicket]);

  const handleDownload = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
    copyStyles: true,
    print: async (printIframe: HTMLIFrameElement) => {
      const document = printIframe.contentDocument;

      if (document) {
        const html = document.getElementById("print-ticket");

        if (html) {
          const exporter = new Html2Pdf(html, {
            filename: `${selectedTicket?.ticket.name}.pdf`,
            html2canvas: {
              scale: 2,
              logging: true,
              dpi: 192,
              letterRendering: true,
              useCORS: true,
            },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          });

          await exporter.getPdf(true);
        }
      }
    },
  });

  useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const metaTags = {
    title: `Meus ingressos - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
    description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
    noIndex: true,
  };

  return (
    <>
      {!selectedTicketMobile && !selectedPurchaseMobile ? (
        <Header isProfile metaTags={metaTags} />
      ) : null}

      {selectedTicketMobile && !selectedPurchaseMobile ? (
        <Header isProfile customBack={closeTicket} metaTags={metaTags} />
      ) : null}

      {selectedPurchaseMobile && !selectedTicketMobile ? (
        <Header isProfile customBack={closePurchase} metaTags={metaTags} />
      ) : null}

      <Container>
        <ContainerItems>
          <ListBreadcrumb
            data={[
              { name: "Home", link: "/" },
              { name: "Perfil", link: "/" },
            ]}
          />

          <Title />

          <ContainerAllItems>
            <SidebarProfile />

            <CardTicket>
              <ContainerHeader>
                <h2>Meus ingressos</h2>

                <ContainerFilters></ContainerFilters>
              </ContainerHeader>

              <ListAccordion>
                {!dataTickets ? (
                  <Center>
                    <Spin spinning indicator={antIcon} />
                  </Center>
                ) : null}

                {dataTickets?.data.length === 0 ? (
                  <DivEmpty>
                    <h2 className="empty">Não há nada por aqui :(</h2>

                    <p className="empty">Não há Ingressos</p>
                  </DivEmpty>
                ) : null}

                {dataTickets?.data &&
                  dataTickets?.data.length > 0 &&
                  dataTickets?.data?.map((ticket) => {
                    if (ticket.event === null) {
                      return null;
                    }
                    return (
                      <div key={ticket.id}>
                        <CardAccordion
                          active={ticket?.isOpen}
                          onClick={() => handleOpenDropTicketEvent(ticket)}
                        >
                          <div className="content">
                            <div className="image">
                              <img
                                className="ticket"
                                src={ticket_profile}
                                alt="Logo"
                                width={25}
                                height={22.5}
                              />
                            </div>

                            <div className="texts">
                              <h2>
                                {ticket.event
                                  ? ticket?.event?.name
                                  : "Não Informado"}
                              </h2>
                              <p>
                                {ticket.ticket.name} {"\u00B7"}{" "}
                                {ticket.formattedDate}
                              </p>
                            </div>
                          </div>

                          {ticket.isOpen ? (
                            <GoChevronDown
                              size={22}
                              color={Colors.secondary50}
                            />
                          ) : (
                            <GoChevronUp size={22} color={Colors.secondary50} />
                          )}
                        </CardAccordion>

                        {ticket.isOpen ? (
                          <DataDropDown>
                            <ContainerBorder>
                              <div className="all">
                                <div className="content">
                                  <div className="column">
                                    <h2>Nome do titular</h2>

                                    <p>{ticket.holder_name}</p>
                                  </div>
                                  <div className="column">
                                    <h2>E-mail do titular</h2>

                                    <p>{ticket.holder_email}</p>
                                  </div>
                                  <div className="column">
                                    <h2>Código da compra</h2>

                                    <p>{ticket.purchase.purchase_code}</p>
                                  </div>
                                </div>
                                <div className="container-image">
                                  <img
                                    className="ticket"
                                    src={ticket?.event?.thumb}
                                    alt="Logo"
                                    width="195px"
                                    height="99px"
                                  />
                                </div>
                              </div>
                            </ContainerBorder>

                            <ContainerButtons>
                              <ButtonVisualizationTicket
                                onClick={() => showModalTicket(ticket)}
                              >
                                <BsFillEyeFill size={18} color={Colors.white} />
                                <span>Visualizar ingresso</span>
                              </ButtonVisualizationTicket>

                              <ButtonDownload
                                onClick={() => {
                                  setSelectedTicket(ticket);

                                  handleDownload();
                                }}
                              >
                                <IoMdDownload
                                  size={18}
                                  color={Colors.primary100}
                                />
                                <span> Fazer download</span>
                              </ButtonDownload>
                            </ContainerButtons>
                          </DataDropDown>
                        ) : null}
                      </div>
                    );
                  })}
              </ListAccordion>

              <Pagination
                totalCountOfRegisters={dataTickets?.meta.total}
                currentPage={pageTicket}
                onPageChange={setPageTicket}
                last_page={dataTickets?.meta.last_page}
              />
            </CardTicket>
          </ContainerAllItems>

          <ContainerAllItemsMobile>
            {!selectedPurchaseMobile ? (
              <div className="header">
                <h1> Meus ingressos</h1>

                <Link to="/faq" target="_blank" rel="noopener noreferrer">
                  <RiQuestionLine size={24} color={Colors.gray30} />
                </Link>
              </div>
            ) : null}

            <TabsTicketsAndPurchases isTickets />
          </ContainerAllItemsMobile>
        </ContainerItems>

        <Drawer
          title="Filtros"
          className="custom-drawer"
          width={420}
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          <ContainerDrawer>
            <div className="data-drawer">
              <div className="data">
                <h1>Data</h1>

                <InputContainer>
                  <div>
                    <RiCalendar2Line size={18} color={Colors.secondary40} />
                  </div>
                  <input
                    type="text"
                    placeholder="Selecione uma data"
                    onKeyUp={handleKeyUpDate}
                  />
                </InputContainer>
              </div>
            </div>

            <div className="next-drawer">
              <div className="next">
                <h1>Categorias</h1>

                <div>
                  <Checkbox
                    style={{ marginLeft: "12px", marginTop: "16px" }}
                    onChange={(e) => {
                      return;
                    }}
                  >
                    Próximos eventos
                  </Checkbox>
                  <Checkbox
                    style={{ marginLeft: "12px", marginTop: "16px" }}
                    onChange={(e) => {
                      return;
                    }}
                  >
                    Eventos que já aconteceram
                  </Checkbox>
                </div>
              </div>
            </div>

            <div className="select-drawer">
              <div className="select">
                <h1>Tipo de ingresso</h1>

                <div>
                  <Select
                    defaultValue="Selecione uma opção"
                    style={{ width: "100%" }}
                    onChange={() => {}}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                      Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>
                </div>
              </div>
            </div>

            <div className="buttons-drawer">
              <button className="first">Limpar</button>

              <button className="second">Aplicar</button>
            </div>
          </ContainerDrawer>
        </Drawer>

        <Drawer
          title="Filtros"
          className="custom-drawer"
          width={278}
          drawerStyle={{ color: Colors.secondary100 }}
          headerStyle={{
            paddingLeft: "14px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
            color: Colors.secondary100,
            fontWeight: "bold",
          }}
          closable={false}
          bodyStyle={{ padding: 0 }}
          placement="right"
          onClose={onCloseMobile}
          visible={visibleMobile}
        >
          <ContainerDrawer>
            <div className="data-drawer">
              <div className="data">
                <h1>Data</h1>

                <InputContainer>
                  <div>
                    <RiCalendar2Line size={18} color={Colors.secondary40} />
                  </div>
                  <input
                    type="text"
                    placeholder="Selecione uma data"
                    onKeyUp={handleKeyUpDate}
                  />
                </InputContainer>
              </div>
            </div>

            <div className="next-drawer">
              <div className="next">
                <h1>Categorias</h1>

                <div>
                  <Checkbox
                    style={{ marginLeft: "12px", marginTop: "16px" }}
                    onChange={(e) => {
                      return;
                    }}
                  >
                    Próximos eventos
                  </Checkbox>
                  <Checkbox
                    style={{ marginLeft: "12px", marginTop: "16px" }}
                    onChange={(e) => {
                      return;
                    }}
                  >
                    Eventos que já aconteceram
                  </Checkbox>
                </div>
              </div>
            </div>

            <div className="select-drawer">
              <div className="select">
                <h1>Tipo de ingresso</h1>

                <div>
                  <Select
                    defaultValue="Selecione uma opção"
                    style={{ width: "100%" }}
                    onChange={() => {}}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                      Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>
                </div>
              </div>
            </div>

            <div className="buttons-drawer">
              <button className="first">Limpar</button>

              <button className="second">Aplicar</button>
            </div>
          </ContainerDrawer>
        </Drawer>

        <ModalStyled
          width={705}
          visible={isModalVisible}
          onOk={handleOk}
          style={{ marginTop: "-60px" }}
          onCancel={handleCancel}
          closeIcon={
            <RiCloseCircleLine
              style={{ marginTop: "14px", marginRight: "34px" }}
              size={43}
              color={Colors.black40}
            />
          }
          footer={null}
        >
          <>
            <CenterTitle>
              <h1 className="visualization">Visualizar ingresso</h1>
            </CenterTitle>

            <ContainerModalTicketDetail>
              <BackgroundTicket>
                <img src={selectedTicket?.event?.thumb} alt="Logo" />

                <div className="content">
                  <h3>{selectedTicket?.event?.name}</h3>

                  <div className="text">
                    <FiMapPin size={18} color={Colors.white} />
                    <h4>
                      {selectedTicket?.event?.address_name}{" "}
                      {selectedTicket?.event?.address_street
                        ? `| ${selectedTicket?.event?.address_street}`
                        : ""}
                    </h4>
                  </div>

                  <div className="text">
                    <RiCalendar2Line size={18} color={Colors.white} />
                    <p>
                      {selectedTicket?.event?.formattedDate},{" "}
                      {selectedTicket?.event?.formattedHour}
                    </p>
                  </div>
                </div>
              </BackgroundTicket>

              <DivBorder>
                <ContainerContentModalTicket>
                  <div className="data">
                    <h3 className="data">Dados do ingresso</h3>

                    <div className="table">
                      <h5>Nome do comprador</h5>
                      <p>{selectedTicket?.holder_name}</p>
                    </div>

                    <div className="table">
                      <h5>Ingresso</h5>
                      <p>{selectedTicket?.ticket?.name}</p>
                    </div>

                    <div className="table">
                      <h5>Código da compra</h5>
                      <p>{selectedTicket?.purchase?.purchase_code}</p>
                    </div>
                  </div>

                  <div className="qr">
                    <QRCode size={150} value={selectedTicket?.id || ""} />

                    <h1>{selectedTicket?.id}</h1>
                  </div>

                  <div className="qr-mobile">
                    <QRCode size={90} value={selectedTicket?.id || ""} />
                  </div>
                </ContainerContentModalTicket>
                <DivLogo>
                  <div className="all-content">
                    <img src={event_ticket} alt="Logo" />
                    <span>A VIDA É UM EVENTO!</span>
                  </div>
                </DivLogo>
              </DivBorder>

              <ContainerBottom>
                <ReactToPrint
                  documentTitle={
                    selectedTicket?.event && selectedTicket?.event?.name
                      ? selectedTicket?.event.name
                      : ""
                  }
                  trigger={() => (
                    <ButtonPrintTicket>
                      <img
                        src={print_ticket_icon}
                        width="18px"
                        height="18px"
                        alt="print"
                      />

                      <span>Imprimir ingresso</span>
                    </ButtonPrintTicket>
                  )}
                  content={() => componentRef?.current}
                />
              </ContainerBottom>

              <ContainerButtonBack>
                <ButtonBack onClick={handleOk}>
                  <span>Voltar</span>
                </ButtonBack>
              </ContainerButtonBack>
            </ContainerModalTicketDetail>
          </>
        </ModalStyled>

        <div style={{ display: "none" }}>
          {selectedTicket ? (
            <TicketForPrint
              ref={componentRef}
              selectedTicket={selectedTicket}
            />
          ) : null}
        </div>
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
