import { createContext, useState, useContext } from "react";

import { Purchase as PurchaseType } from "types";

interface Event {
  id: string;
  name: string;
  thumb: string;
  photo: string;
  address_name: string;
  address_street: string;
  begin_date: string;
  formattedDate: string;
  formattedHour: string;
}
interface PurchaseInteface {
  id: number;
  purchase_code: number;
}
interface Ticket {
  id: string;
  holder_name: string;
  holder_email: string;
  event: Event;
  purchase: PurchaseInteface;
  isOpen: boolean;
  formattedDate: string;
  ticket: {
    name: string;
  };
}

type TabsTicketsAndPurchasesContextData = {
  selectedTicketMobile: Ticket | null;
  handleSelectedTicketMobile(ticket: Ticket | null): void;
  closeTicket(): void;

  selectedPurchaseMobile: PurchaseType | null;
  handleSelectedPurchaseMobile(purchase: PurchaseType | null): void;
  closePurchase(): void;
};

const TabsTicketsAndPurchasesContext =
  createContext<TabsTicketsAndPurchasesContextData>(
    {} as TabsTicketsAndPurchasesContextData
  );

export const TabsTicketsAndPurchasesProvider: React.FC = ({ children }) => {
  const [selectedTicketMobile, setSelectedTicketMobile] =
    useState<Ticket | null>(null);

  const [selectedPurchaseMobile, setSelectedPurchase] =
    useState<PurchaseType | null>(null);

  function handleSelectedTicketMobile(ticket: Ticket | null) {
    setSelectedTicketMobile(ticket);
  }

  function handleSelectedPurchaseMobile(purchase: PurchaseType | null) {
    setSelectedPurchase(purchase);
  }

  function closeTicket() {
    setSelectedTicketMobile(null);
  }

  function closePurchase() {
    setSelectedPurchase(null);
  }

  return (
    <TabsTicketsAndPurchasesContext.Provider
      value={{
        handleSelectedTicketMobile,
        selectedTicketMobile,
        closeTicket,

        selectedPurchaseMobile,
        handleSelectedPurchaseMobile,
        closePurchase,
      }}
    >
      {children}
    </TabsTicketsAndPurchasesContext.Provider>
  );
};

export function useTabsTicketsAndPurchases(): TabsTicketsAndPurchasesContextData {
  const context = useContext(TabsTicketsAndPurchasesContext);

  if (!context) {
    throw new Error(
      `useTabsTicketsAndPurchases must be used within a IntegrationsProvider`
    );
  }

  return context;
}
