import { useRef } from "react";
import { Link } from "react-router-dom";

import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";

import Slider from "react-slick";

import { useFeaturedEvent } from "services/Hook/Event";

import { Colors } from "constants/colors";
import { FeaturedEvent } from "types";

import {
  Container,
  ContainerItems,
  CardEvent,
  Content,
  CardArrowRight,
  CardArrowLeft,
  BackgroundImage,
} from "./styles";

import pin from "assets/pin.svg";
import group from "assets/group.svg";

export function FeaturedEvents() {
  const { data, error } = useFeaturedEvent<FeaturedEvent[]>();

  const slider = useRef<Slider>(null);

  const settings = {
    arrows: false,
    dots: false,
    slidesToShow: data && data?.length < 2 ? data?.length : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: data && data?.length < 2 ? 1 : 1.02,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          centerMode: false,
        },
      },
    ],
  };

  const next = () => {
    slider.current?.slickNext();
  };

  const previous = () => {
    slider.current?.slickPrev();
  };

  if (error) {
    return null;
  }

  if (data && data?.length === 0) {
    return null;
  }

  return (
    <Container style={{ width: "100%" }}>
      <ContainerItems>
        <div className="heading">
          <h2>Eventos em destaque</h2>
        </div>
        <Slider {...settings} ref={slider}>
          {data &&
            data?.map((next) => (
              <CardEvent key={next.id}>
                <div className="first">
                  <div className="image-event">
                    <Link to={"/" + next.nickname}>
                      {next.photo !== null ? (
                        <BackgroundImage img={next.photo} />
                      ) : null}
                    </Link>
                  </div>

                  <Content>
                    <div className="head">
                      <div className="image-icon">
                        <img className="img-icon" src={group} alt="" />
                      </div>
                      <div className="content">
                        <h2>{next.name}</h2>

                        <div className="date">
                          <span>
                            {next.formattedDate} {"\u00B7"} {next.formattedDay}
                          </span>
                          {next.address_city ? (
                            <div className="city">
                              <img
                                src={pin}
                                width="13px"
                                height="14px"
                                alt=""
                              />
                              <span className="date">
                                {next.address_city}, {next.address_state}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="container-description">
                      <h3>Descrição</h3>

                      <p className="description">
                        {next.description
                          ? next.description.length >= 181
                            ? `${next.description.substring(0, 181)}...`
                            : next.description
                          : "Sem descrição"}
                      </p>
                    </div>

                    <div className="list-categories">
                      {next.categories.length
                        ? next.categories.map((category) => (
                            <div className="badge" key={category.id}>
                              <span>{category.name}</span>
                            </div>
                          ))
                        : null}
                    </div>

                    <div className="footer">
                      <div>
                        <p>Por</p>
                        <strong>{next?.organizer?.name}</strong>
                      </div>
                      <Link to={"/" + next.nickname}>
                        <button>Ver detalhes</button>
                      </Link>
                    </div>
                  </Content>
                </div>
              </CardEvent>
            ))}
        </Slider>
        <nav>
          <div>
            <CardArrowLeft onClick={previous}>
              <HiOutlineArrowNarrowLeft
                fontSize={19}
                color={Colors.secondary100}
              />
            </CardArrowLeft>

            <CardArrowRight onClick={next}>
              <HiOutlineArrowNarrowRight
                fontSize={19}
                color={Colors.secondary100}
              />
            </CardArrowRight>
          </div>
        </nav>
      </ContainerItems>
    </Container>
  );
}
