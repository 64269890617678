import styled from "styled-components";

import { Colors } from "constants/colors";
import { Modal } from "antd";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    /* overflow: auto; */
    border-radius: 20px;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 44px 109px 64px 109px;

  @media (max-width: 800px) {
    padding: 24px 10px 24px 10px;
  }

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.87rem;
    margin-bottom: 0px;
    color: ${Colors.secondary100};

    @media (max-width: 800px) {
      font-size: 20px;
    }
  }

  input {
    width: 100%;
    height: 3.28rem;
    outline: 0;
    padding-left: 23px;

    background: ${Colors.white};

    caret-color: ${Colors.primary100};
    border: 1px solid ${Colors.border};
    box-sizing: border-box;
    border-radius: 0.65rem;
    margin-top: 1.87rem;

    font-family: "Open Sans";

    font-size: 0.84rem;

    @media (max-width: 800px) {
      height: 40px;
      border-radius: 8px;
      margin-top: 5px;
    }
  }

  svg {
    min-width: 24px;
    min-height: 24px;

    @media (max-width: 800px) {
      min-width: 14px;
      min-height: 14px;
    }
  }
`;

interface NormalCardBorderProps {
  active?: boolean;
}

export const MainCardBorder = styled.div<NormalCardBorderProps>`
  cursor: pointer;
  margin-top: 16px;
  width: 100%;
  height: 5.1rem;
  display: flex;
  align-items: center;

  flex-direction: row;

  background: ${(props) =>
    props.active ? `${Colors.primary20}20` : Colors.white};

  @media (max-width: 800px) {
    height: 63px;
  }

  border-top: 1px solid ${Colors.border};
  border-bottom: 1px solid ${Colors.border};

  div.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 13px;

    strong {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 0.93rem;
      line-height: 160%;

      color: ${Colors.secondary100};

      @media (max-width: 800px) {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    }

    span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 0.84rem;
      line-height: 160%;
      opacity: 0.6;

      @media (max-width: 800px) {
        color: ${Colors.gray};
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
`;

export const NormalCardBorder = styled.div<NormalCardBorderProps>`
  cursor: pointer;

  width: 100%;
  min-height: 3.37rem;

  background: ${(props) =>
    props.active ? `${Colors.primary20}20` : Colors.white};

  @media (max-width: 800px) {
    height: 47px;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid ${Colors.border};

  h3.normal {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    /* line-height: 160%; */
    margin-bottom: 0px;

    color: ${Colors.secondary100};
    margin-left: 13px;

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }
`;

export const ContainerSelect = styled.div`
  height: 400px;
  margin-top: 20px;
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    margin-top: -10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${Colors.black10};
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${Colors.primary100};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }
`;

export const Center = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
