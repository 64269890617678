import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import {
  RiCloseCircleLine,
  RiEyeLine,
  RiEyeOffLine,
  RiLock2Line,
  RiUserLine,
} from "react-icons/ri";

import { ResetPassword, VerifyCode } from "services/Hook/User";

// import { FcGoogle } from "react-icons/fc";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import api from "services/api";

import { Colors } from "constants/colors";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  AllInput,
  InputContainer,
  ButtonSignIn,
  // ButtonGoogle,
  ContainerForms,
  TabsCadaster,
  Tab,
  TabForgot,
  FormForgot,
  ModalStyled,
  ContainerModal,
  ContainerOtp,
  ButtonCadaster,
} from "styles/pages/SignIn";

import Popup from "containers/Popup";
import { Header } from "components/Header";
import { Footer } from "components/Footer";

import signin_banner from "assets/signin-banner.png";
import logo_signin from "assets/logo-signin.png";
import signup_success from "assets/signup-success.svg";

type Error = {
  response: {
    data: {
      code: string;
      error: string;
    };
  };
};

type ForgotFormData = {
  email: string;
};

type ResetFormData = {
  password: string;
  password_confirmation: string;
};

const forgotFormSchema = yup.object().shape({
  email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
});

const codeFormSchema = yup.object().shape({
  code: yup.string(),
});

const resetPasswordFormSchema = yup.object().shape({
  password: yup
    .string()
    .required("Senha obrigatória")
    .min(6, "No mínimo 6 caracteres"),
  password_confirmation: yup
    .string()
    .required("A Senha de confirmação é obrigatória")
    .oneOf([null, yup.ref("password")], "As senhas precisam ser iguais"),
});

export default function ForgotPassword() {
  const [step, setStep] = useState(0);

  const [codeUser, setCodeUser] = useState("");

  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<ForgotFormData>({
    resolver: yupResolver(forgotFormSchema),
  });

  const {
    handleSubmit: handleSubmit2,
    formState: { errors: errors2, isSubmitting: isSubmitting2 },
    setError: setError2,
    clearErrors: clearErrors2,
  } = useForm({
    resolver: yupResolver(codeFormSchema),
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3, isSubmitting: isSubmitting3 },
  } = useForm<ResetFormData>({
    resolver: yupResolver(resetPasswordFormSchema),
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const [otp, setOtp] = useState(new Array(6).fill(""));

  const handleChange = (element: any, index: number) => {
    if (errors2.code) {
      clearErrors2("code");
    }

    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleForgotPassword: SubmitHandler<ForgotFormData> = async ({
    email,
  }) => {
    try {
      await api.post("/forgot-password", {
        email,
      });

      showModal();
      setStep(1);
    } catch (err) {
      const response = err as Error;

      const { code } = response.response.data;

      if (code === "E_ROW_NOT_FOUND") {
        setError("email", {
          type: "notMatch",
          message: "O e-mail informado está incorreto ou não existe.",
        });

        return;
      }
    }
  };

  async function handleVerifyCode() {
    const codeVerify = otp.join("");

    try {
      await VerifyCode(codeVerify);

      setCodeUser(codeVerify);
      setStep(2);
    } catch (err) {
      setError2("code", {
        type: "notMatch",
        message: "Código Inválido",
      });
    }
  }

  const handleResetPassword: SubmitHandler<ResetFormData> = async ({
    password,
  }) => {
    try {
      const credentials: any = {
        password,
        code: codeUser,
      };

      await ResetPassword(credentials);

      alert("Senha redefinida com sucesso");

      setStep(2);

      navigate("/signin");
      setStep(0);
    } catch (err) {
      setError2("code", {
        type: "notMatch",
        message: "Código Inválido",
      });
    }
  };

  const handleShowPass1 = useCallback(() => {
    setShowPass1(!showPass1);
  }, [showPass1]);

  const handleShowPass2 = useCallback(() => {
    setShowPass2(!showPass2);
  }, [showPass2]);

  return (
    <>
      <Header />
      <Container>
        <ContainerItems>
          <div className="container-image">
            <img
              src={signin_banner}
              alt=""
              width="840px"
              height="573px"
              className="image"
            />
          </div>

          <ContainerForms>
            <div className="container-logo">
              <img
                className="image-logo"
                src={logo_signin}
                alt=""
                width="251px"
                height="64.5px"
              />
              <div className="text">
                <h2>a vida é um evento!</h2>
              </div>
            </div>

            <TabsCadaster>
              <Tab>
                <TabForgot className="first">
                  <h2 className="forgot">Recuperar senha</h2>
                </TabForgot>
              </Tab>
            </TabsCadaster>

            {step === 0 ? (
              <FormForgot onSubmit={handleSubmit(handleForgotPassword)}>
                <p style={{ marginTop: 72 }} className="instructions">
                  Nos informe seu e-mail de acesso e enviaremos para você um
                  link com o passo a passo para redefinir sua senha:
                </p>

                <div className="all-inputs">
                  <AllInput>
                    <InputContainer error={!!errors.email}>
                      <div>
                        <RiUserLine size={23} color={Colors.secondary40} />
                      </div>
                      <input
                        type="email"
                        placeholder="E-mail"
                        {...register("email")}
                      />
                    </InputContainer>
                    {errors.email && <span>{errors.email.message}</span>}
                  </AllInput>
                </div>

                <div className="button-signIn">
                  <ButtonSignIn type="submit" isLoading={isSubmitting}>
                    <Spin spinning={isSubmitting} indicator={antIcon} />

                    <span>Enviar</span>
                  </ButtonSignIn>
                </div>

                <div className="new">
                  <span>Você é novo por aqui?</span>

                  <div className="cadaster-web">
                    <Link to="/signin?page=signup">
                      <p>Cadastre-se</p>
                    </Link>
                  </div>

                  <div className="cadaster-mobile">
                    <Link to="/signin?page=signup">
                      <ButtonCadaster>Cadastre-se</ButtonCadaster>
                    </Link>
                  </div>
                </div>

                {/* <div className="or">
                  <div className="line" />
                  <span>Ou</span>
                  <div className="line" />
                </div>

                <div className="enter">
                  <span>Entre com suas redes sociais</span>
                </div>

                <div className="div-google">
                  <ButtonGoogle>
                    <FcGoogle size={18} />

                    <span>Continue com o Google</span>
                  </ButtonGoogle>
                </div> */}
              </FormForgot>
            ) : null}

            {step === 1 ? (
              <FormForgot onSubmit={handleSubmit2(handleVerifyCode)}>
                <p style={{ marginTop: 72 }} className="instructions">
                  Insira abaixo o código que foi enviado em seu e-mail:
                </p>

                <div className="all-inputs">
                  <ContainerOtp>
                    {otp.map((data, index) => {
                      return (
                        <input
                          className="otp-field"
                          type="text"
                          name="otp"
                          maxLength={1}
                          key={index}
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      );
                    })}
                  </ContainerOtp>

                  {errors2.code && (
                    <span className="error">Codigo inválido</span>
                  )}
                </div>

                <div className="new-code">
                  <span>Ainda não recebeu o código?</span>

                  <p>Clique aqui</p>
                </div>

                <div className="button-signIn">
                  <ButtonSignIn type="submit" isLoading={isSubmitting2}>
                    <Spin spinning={isSubmitting2} indicator={antIcon} />

                    <span>Enviar</span>
                  </ButtonSignIn>
                </div>

                <div className="new">
                  <span>Você é novo por aqui?</span>

                  <div className="cadaster-web">
                    <Link to="/signin">
                      <p>Cadastre-se</p>
                    </Link>
                  </div>

                  <div className="cadaster-mobile">
                    <Link to="/signin">
                      <ButtonCadaster>Cadastre-se</ButtonCadaster>
                    </Link>
                  </div>
                </div>

                {/* <div className="or">
                  <div className="line" />
                  <span>Ou</span>
                  <div className="line" />
                </div>

                <div className="enter">
                  <span>Entre com suas redes sociais</span>
                </div>

                <div className="div-google">
                  <ButtonGoogle>
                    <FcGoogle size={18} />

                    <span>Continue com o Google</span>
                  </ButtonGoogle>
                </div> */}
              </FormForgot>
            ) : null}

            {step === 2 ? (
              <FormForgot onSubmit={handleSubmit3(handleResetPassword)}>
                <p style={{ marginTop: 72 }} className="instructions">
                  Preencha os campos abaixo para criar a sua nova senha de
                  acesso:
                </p>

                <div className="all-inputs">
                  <AllInput>
                    <InputContainer>
                      <div>
                        <RiLock2Line size={23} color={Colors.secondary40} />
                      </div>
                      <input
                        type={showPass1 ? "text" : "password"}
                        placeholder="Nova senha"
                        {...register3("password")}
                      />
                      <div>
                        {showPass1 ? (
                          <RiEyeLine
                            onClick={handleShowPass1}
                            size={23}
                            color={Colors.secondary40}
                          />
                        ) : (
                          <RiEyeOffLine
                            onClick={handleShowPass1}
                            size={23}
                            color={Colors.secondary40}
                          />
                        )}
                      </div>
                    </InputContainer>
                    {errors3.password ? (
                      <span>Por favor, insira a senha.</span>
                    ) : null}
                  </AllInput>

                  <AllInput>
                    <InputContainer>
                      <div>
                        <RiLock2Line size={23} color={Colors.secondary40} />
                      </div>
                      <input
                        type={showPass2 ? "text" : "password"}
                        placeholder="Repita sua nova senha"
                        {...register3("password_confirmation")}
                      />
                      <div>
                        {showPass2 ? (
                          <RiEyeLine
                            onClick={handleShowPass2}
                            size={23}
                            color={Colors.secondary40}
                          />
                        ) : (
                          <RiEyeOffLine
                            onClick={handleShowPass2}
                            size={23}
                            color={Colors.secondary40}
                          />
                        )}
                      </div>
                    </InputContainer>
                    {errors3.password_confirmation ? (
                      <span>{errors3.password_confirmation.message}</span>
                    ) : null}
                  </AllInput>
                </div>

                <div className="new-code">
                  <span>Ainda não recebeu o código?</span>

                  <p>Clique aqui</p>
                </div>

                <div className="button-signIn">
                  <ButtonSignIn type="submit" isLoading={isSubmitting3}>
                    <Spin spinning={isSubmitting3} indicator={antIcon} />

                    <span>Redefinir minha senha</span>
                  </ButtonSignIn>
                </div>

                <div className="new">
                  <span>Você é novo por aqui?</span>

                  <div className="cadaster-web">
                    <Link to="/signin">
                      <p>Cadastre-se</p>
                    </Link>
                  </div>

                  <div className="cadaster-mobile">
                    <Link to="/signin">
                      <ButtonCadaster>Cadastre-se</ButtonCadaster>
                    </Link>
                  </div>
                </div>

                {/* <div className="or">
                  <div className="line" />
                  <span>Ou</span>
                  <div className="line" />
                </div>

                <div className="enter">
                  <span>Entre com suas redes sociais</span>
                </div>

                <div className="div-google">
                  <ButtonGoogle>
                    <FcGoogle size={18} />

                    <span>Continue com o Google</span>
                  </ButtonGoogle>
                </div> */}
              </FormForgot>
            ) : null}
          </ContainerForms>
        </ContainerItems>

        <ModalStyled
          width={857}
          visible={isModalVisible}
          onOk={handleOk}
          style={{ borderRadius: "20px" }}
          bodyStyle={{ paddingTop: 60 }}
          onCancel={handleCancel}
          closeIcon={
            <RiCloseCircleLine
              style={{ marginTop: "14px", marginRight: "34px" }}
              size={43}
              color={Colors.black40}
            />
          }
          footer={null}
        >
          <ContainerModal>
            <div className="logo-qr-code">
              <img src={signup_success} alt="Logo" width={275} height={253} />
            </div>

            <div className="content">
              <h2>Solicitação enviada!</h2>

              <p>
                Você vai receber um código para redefinição de senha em seu
                e-mail.
              </p>
            </div>

            <div className="ok-button">
              <button onClick={handleOk}>Ok</button>
            </div>
          </ContainerModal>
        </ModalStyled>
      </Container>
      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
