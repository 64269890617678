import styled from "styled-components";
import { Colors } from "constants/colors";

export const BlockContainer = styled.div`
  width: 100%;
  background-color: #66588A;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const IconContainer = styled.div`
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 14px;
  background-color: rgba(233,231,238, .1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4rem;
  svg{
    width: 1.8rem;
    height: 1.8rem;
    color: rgba(233,231,238);
  }
  @media screen and (max-width: 450px){
    display: none;
  }
`
export const ContactUsLabel = styled.p`
  color: ${Colors.white};
  font-weight: bold;
  a{
    color: ${Colors.white};
    text-decoration: underline;
  }
  @media screen and (max-width: 550px){
  }
`
