import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";

import Categories from "pages/Category";
import MyEvents from "pages/MyEvent";
import MyEvent from "pages/MyEvent/Event";
import MyEventIntegration from "pages/MyEvent/Event/Integration";
import AccessData from "pages/Profile/AcessData";
import Address from "pages/Profile/Address";
import MyPurchase from "pages/Profile/MyPurchase";
import MyTicket from "pages/Profile/MyTicket";
import PersonalData from "pages/Profile/PersonalData";
import Search from "pages/Search";
import SignInPage from "pages/SignIn";
import Home from "../pages/Home";

import ForgotPassword from "pages/ForgotPassword";
import Profile from "pages/Profile";

import EventDetail from "pages/EventDetail";
import Faq from "pages/FAQ";
import HelpArticle from "pages/HelpArticle";
import SwitchTerm from "pages/Term/SwitchTerm";
import Term from "pages/Term";
import AddressCreate from "pages/Profile/Address/New";
import AddressEdit from "pages/Profile/Address/Edit";
import ConfirmedEmail from "pages/ConfirmedEmail";
import TermsCancel from "pages/TermCancel";
import ContactUs from "pages/ContactUs";
import Index from "pages/EventxLandPage";
import QrcodeTicket from "pages/QrcodeTicket";

export default function RoutesPage() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/confirmed/:verify_token" element={<ConfirmedEmail />} />

      <Route path="/organize-seu-evento" element={<Index />} />

      <Route
        path="/signin"
        element={
          <GuestRoute>
            <SignInPage />
          </GuestRoute>
        }
      />

      <Route
        path="/forgot-password"
        element={
          <GuestRoute>
            <ForgotPassword />
          </GuestRoute>
        }
      />

      <Route path="/category/:id" element={<Categories />} />
      <Route path="/search" element={<Search />} />

      <Route path="/my-event" element={<PrivateOutlet />}>
        <Route index element={<MyEvents />} />
        <Route path=":id" element={<MyEvent />} />
        <Route path=":id/:ref" element={<MyEvent />} />
        <Route path=":nickname/integration" element={<MyEventIntegration />} />
      </Route>

      <Route path="profile" element={<PrivateOutlet />}>
        <Route index element={<Profile />} />
        <Route path="personal-data" element={<PersonalData />} />
        <Route path="access-data" element={<AccessData />} />
        <Route path="my-ticket" element={<MyTicket />} />
        <Route path="my-shopping" element={<MyPurchase />} />

        <Route path="terms-cancel/:purchase_id" element={<TermsCancel />} />

        <Route path="address">
          <Route index element={<Address />} />
          <Route path="create" element={<AddressCreate />} />
          <Route path="edit/:id" element={<AddressEdit />} />
        </Route>
      </Route>

      <Route path="/fale-conosco" element={<ContactUs />} />

      <Route path="/faq" element={<Faq />} />

      <Route path="/qr-code-ticket/:id" element={<QrcodeTicket />} />

      <Route path="/faq/:category/:id" element={<HelpArticle />} />
      <Route path="/term" element={<Term />} />
      <Route path="/term/:type_user/:id" element={<SwitchTerm />} />

      <Route path="/:id" element={<EventDetail />} />
    </Routes>
  );
}

interface GuestRouteProps {
  children: JSX.Element;
}

const GuestRoute = ({ children }: GuestRouteProps) => {
  const isAuthenticated = !!localStorage.getItem("@ventX-user_token");

  return !isAuthenticated ? children : <Navigate to="/" />;
};

function PrivateOutlet() {
  const isAuthenticated = localStorage.getItem("@ventX-user_token");
  let location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return <Outlet />;
}
