import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";

import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

import { useEventWithDiscount } from "services/Hook/Event";

import { Colors } from "constants/colors";

import { DiscountedEvents as DiscountedEventsType } from "types";
import { formatPrice } from "utils/formatPrice";

import {
  Container,
  ContainerItems,
  CardArrowLeft,
  CardArrowRight,
  Dot,
  Background,
  DiscountedCardStyled,
} from "./styles";

export function DiscountedEvents() {
  const { data, error } = useEventWithDiscount<DiscountedEventsType>();

  const slider = useRef<Slider>(null);

  const [active, setActive] = useState({ activeSlide: 0 });

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow:
      data && data?.data && data?.data.length < 4 ? data?.data.length : 2,

    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: false,
    beforeChange: (_: number, next: number) => setActive({ activeSlide: next }),
    responsive: [
      {
        breakpoint: 1380,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow:
            data && data?.data && data?.data.length < 4 ? data?.data.length : 2,

          slidesToScroll: 1,
          arrows: false,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1098,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 2.4,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow:
            data && data?.data.length < 3 ? data?.data.length : 2.08,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          centerMode: false,
        },
      },
    ],
  };

  const next = () => {
    slider.current?.slickNext();
  };

  const previous = () => {
    slider.current?.slickPrev();
  };

  if (error) {
    return null;
  }

  if (data?.data.length === 0) {
    return null;
  }

  return (
    <Container>
      <ContainerItems>
        <div className="header">
          {data?.data && data?.data.length > 0 ? (
            <h3>Eventos com deconto</h3>
          ) : null}

          <nav>
            <CardArrowLeft onClick={previous}>
              <IoChevronBackOutline fontSize={19} color={Colors.secondary100} />
            </CardArrowLeft>

            <div className="dots">
              {[0, 1, 2, 3, 4].map((dot, index) => {
                return <Dot key={dot} active={active.activeSlide === index} />;
              })}
            </div>

            <CardArrowRight onClick={next}>
              <IoChevronForwardOutline
                fontSize={19}
                color={Colors.secondary100}
              />
            </CardArrowRight>
          </nav>
        </div>

        <div
          style={{
            maxWidth:
              data && data.data && data.data.length > 0 && data.data.length < 5
                ? `${data.data.length * 320}px`
                : "",
          }}
        >
          <Slider className="slider" {...settings} ref={slider}>
            {data?.data &&
              data?.data.map((event) => (
                <DiscountedCardStyled key={event.id}>
                  <Link to={`/${event.nickname}`}>
                    <Background photo={event.thumb}>
                      <div className="badge-discounted">
                        <span>
                          {Math.floor(
                            (100 * event.tickets[0].discount) /
                              event.tickets[0].price
                          )}{" "}
                          %
                        </span>
                      </div>
                    </Background>

                    <div className="texts">
                      <h2>{event.name}</h2>

                      <div className="list-categories">
                        {event.categories.length
                          ? event.categories.map((category) => (
                              <div className="badge" key={category.id}>
                                <span>{category.name}</span>
                              </div>
                            ))
                          : null}
                      </div>

                      <div className="prices">
                        <strong>
                          {formatPrice(
                            event?.tickets[0]?.price -
                              event?.tickets[0]?.discount
                          )}
                        </strong>{" "}
                        <small>{formatPrice(event?.tickets[0]?.price)}</small>
                      </div>
                    </div>
                  </Link>
                </DiscountedCardStyled>
              ))}
          </Slider>
        </div>
      </ContainerItems>
    </Container>
  );
}
