import api from "../../api";

import useSWR from "swr";

import {
  transformerDateToBr,
  transformerDateToSend,
} from "utils/transformerDate";
import { authClient } from "../../authClient";

import { ptBR } from "date-fns/locale";
import { format, parseISO, isAfter, addDays } from "date-fns";

import { Purchase } from "types/index";
import moment from "moment";

export async function VerifyCode(code: string) {
  try {
    const url: string = `/verify-code-password/${code}`;

    const response = await api.get(url);

    return response.data;
  } catch (err) {
    throw new Error();
  }
}

interface ResetPasswordProps {
  credentials: {
    password: string;
    code: string;
  };
}

export async function ResetPassword(credentials: ResetPasswordProps) {
  try {
    const url: string = "/reset-password";

    const response = await api.put(url, credentials);

    return response.data;
  } catch (err) {
    throw new Error();
  }
}

export function useUser<Data = any, Error = any>(
  id: number | string | undefined
) {
  const url: string = `/users/show/${id}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newResponse = {
      ...response.data,
      formattedBirthdate: response?.data.birthdate
        ? transformerDateToBr(response?.data.birthdate)
        : response?.data.birthdate,
    };

    return newResponse;
  });

  return { data, error, mutate };
}

export function useUserPurchase<Data = any, Error = any>(
  page: number | string
) {
  const partialUrl: string = "/users/purchases";

  const { data, error, mutate, isValidating } = useSWR<Data, Error>(
    [partialUrl, page],
    async () => {
      const url: string = `${partialUrl}?page=${page}&limit=10`;

      const response = await authClient.get(url);

      const newMapData = response.data.data.map((purchase: Purchase) => {
        if (purchase === null) {
          return {
            purchase,
          };
        }

        const createdAt = moment(purchase.created_at);

        const createdAtComponent = createdAt.utc().format("DD/MM/YYYY");
        const createdAtTimeComponent = createdAt.utc().format("HH:mm");

        return {
          ...purchase,
          isAfter: purchase.event
            ? isAfter(parseISO(purchase?.event?.begin_date), Date.now())
            : false,
          formattedDate: format(
            parseISO(purchase.created_at),
            "eee', 'dd' 'MMMM' 'yyyy'",
            { locale: ptBR }
          ),
          formattedDay: createdAtComponent,
          formattedHour: createdAtTimeComponent,
        };
      });

      const newResponse = { ...response.data, data: newMapData };

      return newResponse;
    }
  );

  return { data, error, mutate, isValidating, partialUrl };
}

interface Event {
  id: string;
  name: string;
  photo: string;
  address_name: string;
  address_street: string;
  begin_date: string;
  begin_hour: string;
  formattedDate: string;
  formattedHour: string;
}

interface Ticket {
  id: string;
  created_at: string;
  holder_name: string;
  holder_email: string;
  event: Event;
  isOpen: boolean;
  formattedDate: string;
  ticket: {
    name: string;
  };
}

export function useUserTicket<Data = any, Error = any>(page: number | string) {
  const partialUrl: string = "/users/tickets";

  const { data, error, mutate } = useSWR<Data, Error>(
    [partialUrl, page],
    async () => {
      const url: string = `${partialUrl}?page=${page}`;

      const response = await authClient.get(url);

      const newMapData = response.data.data.map((ticket: Ticket) => {
        if (ticket.event === null) {
          return {
            ...ticket,
            event: null,
            formattedDate: format(
              addDays(parseISO(ticket.created_at), 1),
              "dd/MM/yy",
              { locale: ptBR }
            ),

            isOpen: false,
          };
        }

        return {
          ...ticket,
          event: {
            ...ticket.event,
            formattedDate: format(
              addDays(parseISO(ticket.event.begin_date), 1),
              "dd' de 'MMMM'",
              { locale: ptBR }
            ),
            formattedHour: `${ticket.event.begin_hour.split(":")[0]}:${
              ticket.event.begin_hour.split(":")[1]
            }h`,
          },
          formattedDate: format(
            addDays(parseISO(ticket.created_at), 1),
            "dd/MM/yy",
            { locale: ptBR }
          ),

          isOpen: false,
        };
      });

      const newResponse = { ...response.data, data: newMapData };

      return newResponse;
    }
  );

  return { data, error, mutate, partialUrl };
}

interface RegisterProps {
  name: string;
  email: string;
  cell_phone: string;
  password: string;
}

export async function Register({
  name,
  email,
  cell_phone,
  password,
}: RegisterProps) {
  const url: string = "/register";

  const response = await api.post(url, {
    email,
    name,
    cell_phone,
    password,
  });

  return response.data;
}

interface UpdateUserProps {
  id: string;
  name: string;
  birthdate: string;
  cell_phone: string;
  document: string;
  gender: string;
}

export async function UserUpdate({
  id,
  name,
  birthdate,
  cell_phone,
  document,
  gender,
}: UpdateUserProps) {
  const url: string = `/users/update/${id}`;

  const response = await authClient.put(url, {
    name,
    birthdate: birthdate ? transformerDateToSend(birthdate) : birthdate,
    cell_phone,
    document,
    gender,
  });

  return response.data;
}

interface UpdatePasswordProps {
  password_old: string;
  password_new: string;
}

export async function UserUpdatePassword({
  password_old,
  password_new,
}: UpdatePasswordProps) {
  const url: string = "/users/update-password";

  const response = await authClient.put(url, {
    password_old,
    password_new,
  });

  return response.data;
}
