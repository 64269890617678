import styled from "styled-components";
import { Tag } from "antd";
import { Colors } from "constants/colors";

export const StyledTag = styled(Tag)`
  width: fit-content;
  border-radius: 2em;
  background: ${Colors.lightgray};
  border: 2px solid ${Colors.lightgray};

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.6em 1em;

  color: ${Colors.secondary100};
`;
