import {
  BadgeStatusTicketAvailability,
  BadgeStatusTicketUnavailability,
} from "./styles";

import ticket_availability from "assets/ticket-availability.svg";
import ticket_unavailability from "assets/ticket-unavailability.svg";

interface BadgeTickeStatusProps {
  active: boolean;
  wrap?: boolean;
}

export function BadgeTicketStatus({ active, wrap }: BadgeTickeStatusProps) {
  return (
    <>
      {active ? (
        <BadgeStatusTicketAvailability wrap={wrap}>
          <img className="ticket-icon" src={ticket_availability} alt="" />

          <span>Ingressos disponíveis!</span>
        </BadgeStatusTicketAvailability>
      ) : null}

      {!active ? (
        <BadgeStatusTicketUnavailability wrap={wrap}>
          <img className="ticket-icon" src={ticket_unavailability} alt="" />

          <span>Ingressos esgotados!</span>
        </BadgeStatusTicketUnavailability>
      ) : null}
    </>
  );
}
