import { Link } from "react-router-dom";

import Slider, { CustomArrowProps } from "react-slick";
import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

import { useEventSlider } from "services/Hook/Event";

import { Colors } from "constants/colors";
import { Events } from "types";

import {
  Container,
  ContainerItems,
  CardArrowLeft,
  CardArrowRight,
  Center,
} from "./styles";

export function SliderComponent() {
  const { data, error } = useEventSlider<Events>();

  const ArrowLeft = ({ style, onClick }: CustomArrowProps) => {
    return (
      <CardArrowLeft onClick={onClick} style={style}>
        <IoChevronBackOutline
          style={{ marginTop: 5, marginRight: 3 }}
          fontSize={18}
          color={Colors.primary100}
        />
      </CardArrowLeft>
    );
  };

  const ArrowRight = ({ style, onClick }: CustomArrowProps) => {
    return (
      <CardArrowRight onClick={onClick} style={style}>
        <IoChevronForwardOutline
          style={{ marginTop: 5, marginLeft: 3 }}
          fontSize={18}
          color={Colors.primary100}
        />
      </CardArrowRight>
    );
  };

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slideToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: data && data?.data.length < 2 ? 1 : 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: data && data?.data.length < 2 ? 1 : 1.08,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          centerMode: false,
        },
      },
    ],
  };

  if (error) {
    return null;
  }

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  if (data === undefined) {
    return (
      <Container>
        <ContainerItems hasOne={false}>
          <Center>
            <Spin spinning indicator={antIcon} />
          </Center>
        </ContainerItems>
      </Container>
    );
  }

  return (
    <Container>
      <ContainerItems hasOne={data.data.length > 1}>
        <Slider {...settings}>
          {data &&
            data?.data.map((slider) => (
              <Link key={slider.id} to={"/" + slider.nickname}>
                <div className="content">
                  {slider.photo !== null ? (
                    <img
                      className="image"
                      src={slider.photo}
                      alt={slider.name}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  ) : null}
                </div>
              </Link>
            ))}
        </Slider>
      </ContainerItems>
    </Container>
  );
}
