import { useEffect, useState } from "react";

import { Spin } from "antd";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { LoadingOutlined } from "@ant-design/icons";
import { FaPen } from "react-icons/fa";
import { MdOutlineEmail, MdSave } from "react-icons/md";
import { RiEyeLine, RiEyeOffLine, RiLock2Line } from "react-icons/ri";

import { useAuth } from "contexts/AuthContext";

import { Colors } from "constants/colors";

import { UserUpdatePassword } from "services/Hook/User";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  ContainerAllItems,
  CardProfile,
  ButtonEdit,
  InputsForm,
  InputsFormNormal,
  FormLabel,
  FormFull,
  ButtonCancel,
  ButtonSave,
  ContainerInputPassword,
} from "./styles";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { SidebarProfile } from "components/SidebarProfile";
import { Title } from "components/Title";

type Error = {
  response: {
    data: {
      code: string;
      error: string;
    };
  };
};

type SignUpFormData = {
  password_old: string;
  password_new: string;
  password_new_confirmation: string;
};

const createUserFormSchema = yup.object().shape({
  password_old: yup
    .string()
    .required("Senha atual obrigatória")
    .min(8, "No mínimo 8 caracteres"),
  password_new: yup
    .string()
    .required("Senha obrigatória")
    .min(8, "No mínimo 8 caracteres"),
  password_new_confirmation: yup
    .string()
    .required("A Senha de confirmação é obrigatória")
    .oneOf([null, yup.ref("password_new")], "As senhas precisam ser iguais"),
});

export default function AccessData() {
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const metaTags = {
    title: `Dados de acesso - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
    description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
    noIndex: true,
  };

  const { user, signOut } = useAuth();

  const [isEditing, setIsEditing] = useState(false);

  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [showPass3, setShowPass3] = useState(false);

  const { register, handleSubmit, formState, setError, reset } =
    useForm<SignUpFormData>({
      resolver: yupResolver(createUserFormSchema),
    });

  const { errors, isSubmitting } = formState;

  const handleResetPassword: SubmitHandler<SignUpFormData> = async ({
    password_old,
    password_new,
    password_new_confirmation,
  }) => {
    try {
      await UserUpdatePassword({
        password_old,
        password_new,
      });

      alert("Senha atualizada com sucesso");

      signOut();
    } catch (err) {
      const response = err as Error;

      const { code, error } = response.response.data;

      if (code === "E_UNAUTHORIZED_ACCESS") {
        setError("password_old", {
          type: "notMatch",
          message: error,
        });

        return;
      }
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const handleShowPass1 = () => {
    setShowPass1(!showPass1);
  };

  const handleShowPass2 = () => {
    setShowPass2(!showPass2);
  };

  const handleShowPass3 = () => {
    setShowPass3(!showPass3);
  };

  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <ListBreadcrumb
            data={[
              { name: "Home", link: "/" },
              { name: "Perfil", link: "/" },
            ]}
          />

          <Title />

          <ContainerAllItems>
            <SidebarProfile />

            <CardProfile
              autoComplete="off"
              onSubmit={handleSubmit(handleResetPassword)}
            >
              <div className="content-profile">
                <h2 className="title">Dados de acesso</h2>

                <InputsForm>
                  <FormFull>
                    <ContainerInputPassword>
                      <FormLabel>
                        <MdOutlineEmail size={23} color={Colors.secondary40} />
                        <h2>E-mail</h2>
                      </FormLabel>

                      <div className="container-password">
                        <input disabled defaultValue={user?.email} />
                      </div>
                    </ContainerInputPassword>

                    <ContainerInputPassword error={!!errors.password_old}>
                      <FormLabel>
                        <RiLock2Line size={23} color={Colors.secondary40} />
                        <h2>Senha</h2>
                      </FormLabel>

                      <div className="container-password">
                        <input
                          type={showPass1 ? "text" : "password"}
                          autoComplete="off"
                          id="prevent_autofill"
                          {...register("password_old")}
                          disabled={!isEditing}
                        />

                        {showPass1 && isEditing ? (
                          <RiEyeLine
                            onClick={handleShowPass1}
                            size={23}
                            color={Colors.secondary40}
                          />
                        ) : null}

                        {!showPass1 && isEditing ? (
                          <RiEyeOffLine
                            onClick={handleShowPass1}
                            size={23}
                            color={Colors.secondary40}
                          />
                        ) : null}
                      </div>

                      {errors.password_old ? (
                        <span className="error">
                          {errors.password_old.message}
                        </span>
                      ) : null}
                    </ContainerInputPassword>
                  </FormFull>
                </InputsForm>

                <InputsFormNormal>
                  <FormFull>
                    <ContainerInputPassword error={!!errors.password_new}>
                      <FormLabel>
                        <RiLock2Line size={23} color={Colors.secondary40} />
                        <h2>Nova Senha</h2>
                      </FormLabel>

                      <div className="container-password">
                        <input
                          type={showPass1 ? "text" : "password"}
                          autoComplete="off"
                          {...register("password_new")}
                          disabled={!isEditing}
                        />

                        {showPass2 && isEditing ? (
                          <RiEyeLine
                            onClick={handleShowPass2}
                            size={23}
                            color={Colors.secondary40}
                          />
                        ) : null}

                        {!showPass2 && isEditing ? (
                          <RiEyeOffLine
                            onClick={handleShowPass2}
                            size={23}
                            color={Colors.secondary40}
                          />
                        ) : null}
                      </div>

                      {errors.password_new ? (
                        <span className="error">
                          {errors.password_new.message}
                        </span>
                      ) : null}
                    </ContainerInputPassword>

                    <ContainerInputPassword
                      error={!!errors.password_new_confirmation}
                    >
                      <FormLabel>
                        <RiLock2Line size={23} color={Colors.secondary40} />
                        <h2>Repita a nova Senha</h2>
                      </FormLabel>

                      <div className="container-password">
                        <input
                          type={showPass3 ? "text" : "password"}
                          autoComplete="off"
                          {...register("password_new_confirmation")}
                          disabled={!isEditing}
                        />

                        {showPass3 && isEditing ? (
                          <RiEyeLine
                            onClick={handleShowPass3}
                            size={23}
                            color={Colors.secondary40}
                          />
                        ) : null}

                        {!showPass3 && isEditing ? (
                          <RiEyeOffLine
                            onClick={handleShowPass3}
                            size={23}
                            color={Colors.secondary40}
                          />
                        ) : null}
                      </div>

                      {errors.password_new_confirmation ? (
                        <span className="error">
                          {errors?.password_new_confirmation?.message}
                        </span>
                      ) : null}
                    </ContainerInputPassword>
                  </FormFull>
                </InputsFormNormal>
              </div>

              {isEditing ? (
                <div className="full-button-save">
                  <ButtonCancel
                    type="button"
                    onClick={() => {
                      setIsEditing(false);
                      reset();
                    }}
                  >
                    <span>Cancelar</span>
                  </ButtonCancel>

                  <ButtonSave type="submit">
                    {!isSubmitting ? (
                      <MdSave
                        className="icon-d"
                        size={23}
                        color={Colors.white}
                      />
                    ) : (
                      <Spin spinning={isSubmitting} indicator={antIcon} />
                    )}
                    <span>Salvar</span>
                  </ButtonSave>
                </div>
              ) : (
                <div className="full-button">
                  <ButtonEdit type="button" onClick={() => setIsEditing(true)}>
                    <div className="icon">
                      <FaPen size={18} color={Colors.primary100} />
                    </div>

                    <span>Editar</span>
                  </ButtonEdit>
                </div>
              )}
            </CardProfile>
          </ContainerAllItems>
        </ContainerItems>
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
