import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import FirstSection from "./FirstSection";
import GridCards from "./GridCards";
import Questions from "./Questions";
import CardMenu from "./CardMenu";
import Results from "./Results";

import Popup from "containers/Popup";
import ContactUsContainer from "components/ContactUsContainer";
import { Footer } from "components/Footer";
import { Header } from "components/Header";

const FAQ: React.FC = () => {
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState();
  const [visibleCard, setVisibleCard] = useState<Object>("");

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleCards = (card: any) => {
    setVisibleCard(card);
  };
  const handleSearching = (search: boolean) => {
    setIsSearch(search);
  };
  const handleLoading = (load: boolean) => {
    setIsLoading(load);
  };
  const handleSearchData = (searchData: any) => {
    setSearchData(searchData);
  };

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  return (
    <>
      <Header />
      <FirstSection
        handleLoading={handleLoading}
        handleSearchData={handleSearchData}
        handleSearching={handleSearching}
      />
      {isSearch && (
        <Results
          isSearching={handleSearching}
          searchData={searchData}
          isLoading={isLoading}
        />
      )}
      {!isSearch && visibleCard === "" && (
        <GridCards handleCards={handleCards} />
      )}
      {!isSearch && visibleCard && (
        <CardMenu handleCards={handleCards} card={visibleCard} />
      )}
      <Questions />
      <ContactUsContainer faq />
      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
};

export default React.memo(FAQ);
