import { authClient } from "services/authClient";
import { useFetchAuth } from "services/useFetch";

export function useAddress<Data = any, Error = any>() {
  const url: string = "/users/address";

  const { data, error, mutate } = useFetchAuth<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}

export async function DeleteAddress(id: string) {
  const url: string = `/users/delete-address/${id}`;

  const response = await authClient.delete(url);

  return response.data;
}
