import { Modal } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";
import {
  Container as DefaultContainer,
  Section as DefaultSection,
} from "../styles";

export const Section = styled(DefaultSection)`
  display: flex;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const QuestionsContainer = styled.div`
  background-color: #fff;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 80px;
  border-radius: 2rem;
  box-shadow: 0px 15px 30px 0px #1219220d;
  margin-bottom: 32px;

  .breadcrumb {
    width: 100%;
    display: flex;
    margin-bottom: 3rem;
  }

  .icon-container {
    display: flex;
    width: fit-content;
    padding: 16px;
    border-radius: 20px;
    margin-right: 42px;
    background: #e9e7ee;

    svg {
      width: 25px;
      height: 25px;
      color: ${(props) => props.theme.colors.purple};
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 40px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    border-radius: 0;
  }
`;
export const Container = styled(DefaultContainer)`
  margin-top: -180px;
  width: 100%;

  @media screen and (max-width: 1024px) {
    &.first-container {
      height: fit-content;
    }
  }

  @media screen and (max-width: 850px) {
    margin-top: -380px;
  }
`;

export const ActionButton = styled.button`
  width: 150px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 14px;
  background-color: ${(props) => props.theme.colors.pink};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  font: 700 18px Cairo, sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    background-color: ${(props) => props.theme.colors.pink};
    font: 700 18px Cairo, sans-serif;
    color: #fff;
    outline: none;
  }
  svg {
    margin-right: 12px;
  }
  @media screen and (max-width: 1150px) {
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e7ee;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 28px;
  svg {
    width: 32px;
    height: 32px;
  }
`;

export const Header = styled.div`
  display: inline-flex;
  max-width: 90%;
  align-items: center;
`;

export const WrapperRecaptcha = styled.div`
  display: flex;
  justify-content: center;
`;
export const FlexTitle = styled.div`
  text-align: left;
  width: 100%;
  margin-bottom: 48px;
`;
export const FormTitle = styled.div`
  text-align: left;
  width: 100%;
  margin-top: 28px;
  margin-bottom: 28px;
  font: 600 18px Poppins, "Poppins", sans-serif;
  color: ${(props) => props.theme.colors.purple};
`;

export const HeadingContact = styled.div`
  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.87rem;

    color: ${Colors.secondary100};
    margin-top: 3.75rem;
    margin-bottom: 0.93rem;
  }

  .subtitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.03rem;

    color: ${Colors.secondary70};
    margin-bottom: 2.81rem;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerModal = styled.div`
  margin-top: 53px;

  max-width: 75.89rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  div.texts-confirm {
    margin-top: 29px;

    h3 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;

      font-size: 1.5rem;
      margin-bottom: 0px;
      text-align: center;

      color: ${Colors.secondary100};
    }

    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      /* font-size: 20px; */
      font-size: 0.93rem;

      text-align: center;
      color: ${Colors.black90};
    }
  }

  div.buttons {
    margin-top: 17.5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const ButtonConfirm = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;

  width: 12.32rem;
  height: 3.84rem;

  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 14px;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 1.21rem;
    text-align: center;

    color: ${Colors.white};
  }
`;
