import { useEffect, useState } from "react";

import { Footer } from "components/Footer";
import { Header } from "components/Header";
import Popup from "containers/Popup";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";
import { Form } from "./Form";

export default function AddressNew() {
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const metaTags = {
    title: `Criar endereço - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
    description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
    noIndex: true,
  };

  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Form />

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
