import React from "react";

import { RiUser3Line, RiStoreLine } from "react-icons/ri";

import {
  Container,
  Section,
  CardQuestion,
  CardsContainer,
  IconContainer,
  CardQuestionHeader,
} from "./styles";

interface Props {
  handleItem: (card: any) => void;
}

const TypeUserGridCard: React.FC<Props> = ({ handleItem }) => {
  const cards = [
    { category: "1", title: "Consumidor", icon: <RiUser3Line /> },
    { category: "2", title: "Organizador", icon: <RiStoreLine /> },
  ];

  return (
    <Container className="first-container">
      <Section>
        <div className="content">
          <CardsContainer>
            {cards.map((item, index) => {
              return (
                <CardQuestion
                  key={index}
                  onClick={() => {
                    handleItem(item);
                  }}
                >
                  <CardQuestionHeader>
                    <IconContainer>{item.icon}</IconContainer>
                    <p className="card-title">{item.title}</p>
                  </CardQuestionHeader>
                </CardQuestion>
              );
            })}
          </CardsContainer>
        </div>
      </Section>
    </Container>
  );
};

export default TypeUserGridCard;
