import styled from "styled-components";

import { Colors } from "constants/colors";

export const Container = styled.header`
  position: fixed;
  display: flex;
  width: 100%;
  background: ${Colors.secondary100};
  display: flex;
  justify-content: center;
  margin: 0 auto;
  z-index: 99;

  /* @media (max-width: 1024px) {
    flex-direction: column;
    background-color: transparent;
    z-index: 3;
  } */
`;

export const ContainerItems = styled.div`
  display: flex;
  max-width: 1280px;
  width: 100%;
  height: 48px;

  z-index: 99;

  padding-right: 28px;
  padding-left: 28px;

  div.header-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h3.item {
      color: ${Colors.white};
      font-weight: bold;
      font-size: 18px;
      line-height: 34px;
      /* margin-left: 75px; */
      margin-bottom: 0px;
    }
  }
`;
