import React from "react";

import { RiCloseCircleLine } from "react-icons/ri";

import { StyledModal } from "./styles";

interface Props {
  title?: string;
  visible: boolean;
  width?: string | number;
  onOkClick?: (e: any) => void;
  onCancel?: (e: any) => void;
  children?: React.ReactNode;
  itemList?: {
    actionName?: string;
    cancelName?: string;
  }[];
}

const Modal: React.FC<Props> = ({
  title,
  visible,
  onOkClick,
  onCancel,
  width,
  children,
}) => {
  return (
    <StyledModal
      visible={visible}
      title={title}
      onOk={onOkClick}
      onCancel={onCancel}
      width={width}
      closeIcon={<RiCloseCircleLine className="custom-close-icon" />}
      footer={null}
    >
      {children}
    </StyledModal>
  );
};

export default Modal;
