import styled from "styled-components";
import { Button, Collapse, Modal as AntdModal, Steps } from "antd";
import { Colors } from "constants/colors";

export const StyledModal = styled(AntdModal)`
  // .ant-modal-mask class -> global style
  width: calc(100vw - 300px) !important;
  top: 30px;
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px;
  }
  .custom-close-icon {
    color: #11142d;
    opacity: 0.3;
    font-size: 32px;
  }
  .ant-modal-content {
    width: 100%;
    background-color: ${Colors.white};
    padding: 32px;
    border-radius: 20px;
    .ant-modal-close-x {
      color: ${Colors.black};
    }
    .ant-modal-header {
      .ant-modal-title {
        color: ${Colors.black};
        text-align: center;
        P {
          margin-top: 32px;
        }
      }
      background-color: ${Colors.white};
    }
    .ant-modal-body {
      padding: 30px 22px;
      color: ${Colors.black};
    }
    .ant-modal-footer {
      border-top: none;
      display: flex;
      justify-content: center;
      button {
        margin: 0 12px;
      }
    }
  }
  @media screen and (max-width: 884px) {
    width: 90% !important;
  }
  @media screen and (max-width: 450px) {
    .ant-modal-content {
      padding: 32px 16px;
    }
    .ant-modal-body {
      padding: 16px !important;
    }
  }
`;
export const PurchaseHeader = styled.div`
  .title {
    text-align: center;
    font: 600 4rem/160%, sans-serif;
    color: ${Colors.secondary100};
    margin-bottom: 6rem;
  }
`;
export const StyledSteps = styled(Steps)`
  .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot,
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after,
  .ant-steps-item-finish
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background-color: ${Colors.primary100};
  }
  margin-bottom: 2rem;
  &.steps-mobile {
    display: none;
  }
  @media screen and (max-width: 580px) {
    &.steps-desktop {
      display: none;
    }
    &.steps-mobile {
      display: block;
    }
  }
`;
export const CardForm = styled.div`
  border: 1px solid #e7e7ea;
  padding: 5rem;
  border-radius: 2rem;
  @media screen and (max-width: 580px) {
    padding: 1.6rem;
    border-radius: 1rem;
  }
  .heading {
    .title {
      /* font: 3.2rem/160%, sans-serif; */
      font-size: 26px;
      font-weight: bold;
      color: ${Colors.secondary100};
      margin-bottom: 0px;
    }
    .description {
      /* font: 400 2.2rem/160%, sans-serif; */
      color: #585b6c;

      font-weight: 400;
      font-size: 22px;
      line-height: 160%;
      /* or 35px */
      margin-top: 20px;
      color: #585b6c;
    }
  }
  .form-section {
    .title {
      /* font: 600 2.2rem/160%, sans-serif; */
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      margin-bottom: 5px;
      color: ${Colors.secondary100};
    }
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 64px;
`;
export const PrimaryButton = styled(Button)`
  min-width: 6rem;
  height: 4rem;
  border-radius: 8px;
  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  font: 700 1.2rem Cairo, sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: 0;
  @media screen and (max-width: 540px) {
    min-width: 6rem;
    height: 3rem;
    font: 700 1rem Cairo, sans-serif;
  }
  :hover,
  :active,
  :focus {
    background-color: ${Colors.primary100};
    font: 700 1.2rem Cairo, sans-serif;
    color: #fff;
    border-color: ${Colors.secondary100};
  }
`;
export const SecondaryButton = styled(Button)`
  min-width: 6rem;
  height: 4rem;
  border-radius: 8px;
  background-color: #fbe5ed;
  font: 700 1.2rem Cairo, sans-serif;
  color: ${Colors.primary100};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: 0;
  @media screen and (max-width: 540px) {
    min-width: 4rem;
    height: 3rem;
    font: 700 1rem Cairo, sans-serif;
  }
  :hover,
  :active,
  :focus {
    background-color: #fbe5ed;
    font: 700 1.2rem Cairo, sans-serif;
    color: ${Colors.primary100};
    border-color: ${Colors.tertiary100};
  }
  :disabled {
    background-color: #e7e7ea;
    color: #b8b9c0;
  }
`;

export const CardTicket = styled(Collapse)`
  background-color: #fff;
  margin-top: 24px;
  overflow: visible;
  border: none;
`;
const { Panel } = Collapse;

export const CardTicketPanel = styled(Panel)`
  border-bottom: none !important;
  border: 1px solid #e7e7ea !important;
  border-radius: 20px !important;
  margin-bottom: 18px;
  & .ant-collapse-header {
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
  }
  &.ant-collapse-item-active {
    border-radius: 20px !important;

    .ant-collapse-header {
      border: 1px solid #e7e7ea !important;
      border-radius: 20px !important;
      transition: none;
    }
  }
  & .ant-collapse-content {
    border-top: none !important;
    border-radius: 20px;
  }
`;
export const CardPanelHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .container-info {
    display: flex;
    align-items: center;
  }
  .container-info svg {
    width: 2.6rem;
    height: 2.6rem;
    color: #d3cfde;
    margin-right: 12px;
    margin-bottom: 16px;
  }
  .ticket-name {
    font: bold 2.2rem/160%, sans-serif;
    font-weight: bold;
    line-height: 0.6rem;
    color: #241058;
  }
  .ticket-value {
    font: 700 2rem/160%, sans-serif;
    line-height: 0.6rem;
    color: #a79fbc;
  }
  .ticket-description {
    font: 500 2rem/160%, sans-serif;
    line-height: 0.6rem;
    color: #a79fbc;
  }
  svg {
    width: 2rem;
    height: 2rem;
    color: #9187ab;
  }
`;
