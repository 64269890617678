import React, { useEffect, useState } from "react";

import { LdsSpinner, StyledModal } from "./styles";

const ModalRedirect: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    setTimeout(() => {}, 3000);
    setTimeout(() => {
      setIsVisible(false);
    }, 3000);
  });
  return (
    <StyledModal footer={null} visible={isVisible}>
      <LdsSpinner>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LdsSpinner>
      <p className="title">Carregando...</p>
      <p className="description">
        Você está sendo redirecionado para a <b>Pxpay</b>, nossa plataforma de
        pagamentos.
      </p>
    </StyledModal>
  );
};

export default ModalRedirect;
