import styled from "styled-components";

import { Colors } from "constants/colors";
import { Modal } from "antd";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 44px 109px 64px 109px;

  @media (max-width: 800px) {
    padding: 24px 10px 24px 10px;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.87rem;

    color: ${Colors.secondary100};
    font-family: "Poppins";
    margin-bottom: 0px;
    @media (max-width: 800px) {
      font-size: 20px;
    }
  }

  h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.03rem;

    margin-top: 0.93rem;

    color: ${Colors.black70};
  }

  input {
    width: 100%;
    height: 70px;
    outline: 0;
    padding-left: 23px;

    background: ${Colors.white};

    caret-color: ${Colors.primary100};
    border: 1px solid ${Colors.border};
    box-sizing: border-box;
    border-radius: 14px;
    padding-bottom: 6px;

    @media (max-width: 800px) {
      height: 40px;
      border-radius: 8px;
      margin-top: 5px;
    }
  }

  svg {
    min-width: 24px;
    min-height: 24px;

    @media (max-width: 800px) {
      min-width: 14px;
      min-height: 14px;
    }
  }
`;

export const NormalCardBorder = styled.div`
  width: 100%;
  height: 3.46rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  div.circle-icon {
    width: 1.59rem;
    height: 1.59rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    background: ${Colors.secondary10};

    img {
      filter: invert(180%) sepia(190%) saturate(190%) hue-rotate(200deg)
        brightness(92%) contrast(116%);
    }
  }

  @media (max-width: 800px) {
    height: 47px;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid ${Colors.border};

  h3.normal {
    font-family: "Open Sans";
    font-weight: 900;
    font-size: 0.93rem;
    color: ${Colors.secondary100};
    margin-left: 0.56rem;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }
`;

export const ContainerSelect = styled.div`
  height: 400px;
  margin-top: 2.15rem;
  overflow-y: scroll;

  border-top: 1px solid ${Colors.border};

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    margin-top: -10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${Colors.black10};
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${Colors.primary100};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }

  > * {
    &:first-child {
      border-top: 1px solid ${Colors.border};
    }
  }
`;
