import React from "react";

import { Container, Section } from "./styles";

interface Props {
  backgroundPhoto: string;
}

const FirstSection: React.FC<Props> = ({ backgroundPhoto }) => {
  return (
    <div style={{ overflow: "hidden" }}>
      <Container className="first-container" bg={backgroundPhoto}>
        <Section>
          <div className="content"></div>
        </Section>
      </Container>
    </div>
  );
};

export default FirstSection;
