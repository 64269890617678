import { Colors } from "constants/colors";
import styled from "styled-components";

interface BadgeStatusProps {
  wrap?: boolean;
}

export const BadgeStatusTicketAvailability = styled.div<BadgeStatusProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;

  margin-top: ${(props) => (props.wrap ? "8px" : "0px")};

  img.ticket-icon {
    width: 0.75rem;
    height: 0.7rem;
  }

  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 0.65rem;
    color: ${Colors.success};
    margin-left: 0.23rem;
    margin-bottom: 0px;
  }
`;

export const BadgeStatusTicketUnavailability = styled.div<BadgeStatusProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;

  margin-top: ${(props) => (props.wrap ? "8px" : "0px")};

  img.ticket-icon {
    width: 0.75rem;
    height: 0.7rem;
  }

  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 0.65rem;
    color: ${Colors.error};
    margin-left: 0.23rem;
    margin-bottom: 0px;
  }
`;
