import Data from "constants/Datas";

import { HeaderFilters } from "./styles";

import { SelectHeaderFilter } from "components/SelectHeaderFilter";
import { SelectCityOrState } from "components/SelectCityOrState";
import { SelectCategory } from "components/SelectCategory";

interface HeaderFiltersEventProps {
  filters: string[];
}

export function HeaderFilterEvent({ filters }: HeaderFiltersEventProps) {
  return (
    <HeaderFilters>
      <h3 className="filter">Filtrar por:</h3>

      <div className="filters">
        {filters.map((filter) => {
          if (filter === "data") {
            return (
              <SelectHeaderFilter
                name={filter}
                options={Data.CategoriesSearchDates}
              />
            );
          }

          if (filter === "price") {
            return (
              <SelectHeaderFilter
                name={filter}
                options={Data.CategoriesSearchPrices}
              />
            );
          }

          if (filter === "localization") {
            return <SelectCityOrState />;
          }

          if (filter === "categories") {
            return <SelectCategory />;
          }

          return null;
        })}
      </div>
    </HeaderFilters>
  );
}
