import Form from "antd/lib/form/Form";
import React, { useState } from "react";

import { Button, Col, Divider, Row } from "antd";

import { MdAdd, MdRemove } from "react-icons/md";

import {
  RiCloseCircleLine,
  RiCoupon2Line,
  RiTicket2Line,
} from "react-icons/ri";

import {
  ActionButtonGroup,
  CardFooter,
  CardForm,
  SecondaryButton,
  StyledModal,
  TicketContainer,
} from "./styles";

import { PrimaryButton } from "../ModalAuth/styles";

import { FormItem } from "components/Form/FormItem";
import { Input } from "components/Form/Input";
import { PrimaryButton as ApplyButton } from "components/Form/Button";

interface Props {
  handleCart: () => void;
}
const ModalCart: React.FC<Props> = ({ handleCart }) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  const RowTickets = ({ item }: any) => {
    const [quantity, setQuantity] = useState(item.quantity);

    const addItem = () => {
      setQuantity(quantity + 1);
    };
    const removeItem = () => {
      quantity > 0 && setQuantity(quantity - 1);
    };
    return (
      <>
        <TicketContainer key={item?.id}>
          <div className="icon-container">
            <RiTicket2Line />
            <div className="name-and-price">
              <div className={`heading-info ${item.coupon && "has-coupon"}`}>
                <p className="name">{item?.name}</p>
                {item.coupon && <hr className="name-divider" />}
                <p className="event">{item?.event}</p>
              </div>
              <p className="price">{formatter.format(item?.price)}</p>
              {item.coupon && (
                <div className="coupon">
                  <RiTicket2Line />
                  <p>Cupom "{item.coupon}" aplicado</p>
                </div>
              )}
            </div>
          </div>
          <ActionButtonGroup>
            <Input readOnly value={quantity + "x"} />
            <Button className="add" onClick={addItem}>
              <MdAdd />
            </Button>
            <Button className="remove" onClick={removeItem}>
              <MdRemove />
            </Button>
          </ActionButtonGroup>
        </TicketContainer>
      </>
    );
  };
  return (
    <StyledModal
      visible={true}
      footer={null}
      onCancel={handleCart}
      closeIcon={<RiCloseCircleLine className="custom-close-icon" />}
    >
      <p className="title">Resumo do pedido</p>
      <CardForm>
        <div className="form-section">
          <p className="title">Seu pedido (3 itens)</p>
          <div className="tickets-container">
            <RowTickets
              item={{
                name: "Evento com nome mt extenso",
                price: 12,
                quantity: 3,
                event: "Evento tal com nome longo",
                coupon: "Evento",
              }}
            />
            <Divider />
            <RowTickets
              item={{
                name: "Evento com nome mt longo",
                price: 12,
                quantity: 3,
                event: "Evento tal",
              }}
            />
            <Divider />
            <RowTickets
              item={{
                name: "Evento com nome mt longo",
                price: 12,
                quantity: 3,
                event: "Evento tal",
              }}
            />
          </div>
        </div>

        <div className="form-section">
          <p className="title">Cupom de desconto</p>
          <Form layout="vertical">
            <Row gutter={20}>
              <Col lg={20} md={16} sm={12} xs={24}>
                <FormItem
                  label={
                    <>
                      <RiCoupon2Line />
                      Cupom de desconto
                    </>
                  }
                  name="coupon"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecione o estado.",
                    },
                  ]}
                >
                  <Input type="password" placeholder="Insira o bairro" />
                </FormItem>
              </Col>
              <Col lg={4} md={8} sm={12} xs={24}>
                <ApplyButton>Aplicar</ApplyButton>
              </Col>
            </Row>
          </Form>
        </div>
      </CardForm>
      <CardFooter>
        <SecondaryButton disabled>Voltar</SecondaryButton>
        <PrimaryButton>Prosseguir</PrimaryButton>
      </CardFooter>
    </StyledModal>
  );
};

export default ModalCart;
