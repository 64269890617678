import { Spin } from "antd";

import QRCode from "react-qr-code";

import { LoadingOutlined } from "@ant-design/icons";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { FiMapPin } from "react-icons/fi";
import { RiCalendar2Line, RiCloseCircleLine } from "react-icons/ri";

import { useTabsTicketsAndPurchases } from "contexts/TabsTicketsAndPurchasesContext";

import { Colors } from "constants/colors";

import { useDisclosure } from "hooks/useDisclosure";

import {
  BackgroundTicket,
  ButtonMoreItems,
  ButtonVisualizationTicketMobile,
  CardAccordionMobile,
  ContainerButtonMobile,
  ContainerContentModalTicket,
  ContainerModalTicketDetail,
  ContainerQrCodeMobile,
  ContainerTicketsMobile,
  DataDropDownMobile,
  FooterItems,
  ModalStyled,
  Center,
} from "./styles";

import logo from "assets/logo.svg";
import ticket_profile from "assets/ticket-profile.svg";
import { useSearchTicket } from "hooks/useSearchTicket";

export function TicketTabPane() {
  const { selectedTicketMobile, handleSelectedTicketMobile } =
    useTabsTicketsAndPurchases();

  const { handleOk, handleCancel, isModalVisible, showModal } = useDisclosure();

  const {
    data,
    handleLoadMoreTicketsMobile,
    handleOpenDropTicketEventMobile,
    hasMoreItems,
  } = useSearchTicket();

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  return (
    <>
      {selectedTicketMobile === null ? (
        <ContainerTicketsMobile>
          <div className="header-filter">
            <h1>todos os ingressos</h1>
          </div>

          {!data ? (
            <Center>
              <Spin spinning indicator={antIcon} />
            </Center>
          ) : null}

          {data?.data.map((ticket) => (
            <div key={ticket.id} className="accordion">
              <CardAccordionMobile
                active={ticket.isOpen}
                onClick={() => handleOpenDropTicketEventMobile(ticket.id)}
              >
                <div className="content">
                  <div className="image">
                    <img
                      className="ticket"
                      src={ticket_profile}
                      alt="Logo"
                      width={25}
                      height={22.5}
                    />
                  </div>

                  <div className="texts">
                    <div className="first">
                      <h2 className="text">
                        {ticket.event ? ticket?.event?.name : "Não Informado"}
                      </h2>
                    </div>

                    <span>
                      {ticket?.ticket
                        ? ticket?.ticket?.name.length > 27
                          ? `${ticket?.ticket?.name.substring(0, 27)}...`
                          : ticket?.ticket?.name
                        : "Não informado"}{" "}
                      {"\u00B7"} {ticket?.formattedDate}
                    </span>
                  </div>
                </div>

                {ticket.isOpen ? (
                  <GoChevronDown size={14} color={Colors.secondary50} />
                ) : (
                  <GoChevronUp size={14} color={Colors.secondary50} />
                )}
              </CardAccordionMobile>

              {ticket.isOpen ? (
                <DataDropDownMobile>
                  <div className="content-main">
                    <div className="texts-ticket">
                      <div className="data">
                        <h2 className="text-data">Email</h2>
                        <span>{ticket.holder_email}</span>
                      </div>

                      <div className="data">
                        <h2 className="text-data">Código da compra</h2>
                        <span>{ticket.purchase.purchase_code}</span>
                      </div>
                    </div>

                    <div className="container-image">
                      <img
                        className="ticket"
                        src={ticket?.event?.thumb}
                        alt="Logo"
                        width="68px"
                        height="68px"
                      />
                    </div>
                  </div>

                  <ContainerButtonMobile>
                    <ButtonVisualizationTicketMobile
                      onClick={() => handleSelectedTicketMobile(ticket)}
                    >
                      <span>Ver ingresso completo</span>
                    </ButtonVisualizationTicketMobile>
                  </ContainerButtonMobile>
                </DataDropDownMobile>
              ) : null}
            </div>
          ))}

          <FooterItems>
            <h3>
              Mostrando {data?.data.length} de {data?.meta.total}
            </h3>

            <ButtonMoreItems
              disabled={!hasMoreItems}
              onClick={handleLoadMoreTicketsMobile}
            >
              <span>Carregar mais</span>
            </ButtonMoreItems>
          </FooterItems>
        </ContainerTicketsMobile>
      ) : null}

      {selectedTicketMobile !== null ? (
        <ContainerModalTicketDetail>
          <BackgroundTicket>
            <img
              src={selectedTicketMobile?.event?.photo ?? "/assets/banner.jpg"}
              alt="Logo"
            />

            <div className="content">
              <h3>{selectedTicketMobile?.event?.name}</h3>

              <div className="text">
                <FiMapPin size={22} color={Colors.white} />
                <h4>
                  {selectedTicketMobile?.event?.address_name}{" "}
                  {selectedTicketMobile?.event?.address_street
                    ? `| ${selectedTicketMobile?.event?.address_street}`
                    : ""}
                </h4>
              </div>

              <div className="text">
                <RiCalendar2Line size={22} color={Colors.white} />
                <p>
                  {selectedTicketMobile?.event?.formattedDate},{" "}
                  {selectedTicketMobile?.event?.formattedHour}
                </p>
              </div>
            </div>
          </BackgroundTicket>

          <ContainerContentModalTicket>
            <div className="data">
              <h3 className="data">Dados do ingresso</h3>

              <div className="table">
                <h5>Nome do comprador</h5>
                <p>{selectedTicketMobile?.holder_name}</p>
              </div>

              <div className="table">
                <h5>Ingresso</h5>
                <p>{selectedTicketMobile?.ticket?.name}</p>
              </div>

              <div className="table">
                <h5>Código da compra</h5>
                <p>{selectedTicketMobile?.purchase?.purchase_code}</p>
              </div>
            </div>

            <div className="qr-mobile">
              <QRCode size={84} value={selectedTicketMobile?.id || ""} />

              <button onClick={showModal}>Ver QR Code</button>
            </div>
          </ContainerContentModalTicket>
        </ContainerModalTicketDetail>
      ) : null}

      <ModalStyled
        width={1200}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={
          <RiCloseCircleLine
            style={{ marginTop: "14px", marginRight: "14px" }}
            size={28}
            color={Colors.gray30}
          />
        }
        footer={null}
      >
        <>
          <img
            style={{ marginTop: -18 }}
            src={logo}
            alt="logo"
            width={109}
            height={29}
          />

          <ContainerQrCodeMobile>
            <QRCode size={280} value={selectedTicketMobile?.id || ""} />
          </ContainerQrCodeMobile>

          <h5 className="code">{selectedTicketMobile?.id}</h5>
        </>
      </ModalStyled>
    </>
  );
}
