// import { parseCookies, setCookie } from "nookies";
import axios from "axios";

export function setupAuthClient() {
  // let cookies = parseCookies(ctx);
  const token = localStorage.getItem("@ventX-user_token");

  const auth = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  // auth.defaults.headers.common[
  //   "Authorization"
  // ] = `Bearer ${cookies["Eventx.token"]}`;

  auth.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return auth;
}
