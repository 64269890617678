import { Colors } from "constants/colors";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (max-width: 800px) {
    display: none;
  }

  div.all-index {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

interface BreadStyledProps {
  active: boolean;
}

export const BreadStyled = styled.h3<BreadStyledProps>`
  font-family: "Poppins";
  font-weight: ${(props) => (props.active ? "bold" : 600)};
  font-size: 14px;
  line-height: 160%;

  /* text-decoration-line: ${(props) =>
    props.active ? "underline" : "none"}; */
    ${(props) =>
      props.active &&
      css`
        &:hover {
          text-decoration-line: underline;
        }
      `}

  cursor: ${(props) => (props.active ? "pointer" : "normal")};

  color: ${(props) => (props.active ? Colors.primary100 : Colors.black30)};
  margin-bottom: 0px;
`;
