import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  margin-top: 130px;

  @media (max-width: 800px) {
    margin-top: 28px;
    margin-bottom: 0px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContainerItems = styled.div`
  max-width: 1280px;

  padding-left: 28px;
  padding-right: 28px;

  width: 100%;
  /* margin-bottom: 150px; */

  /* @media (max-width: 1280px) {
    max-width: 1080px;
  }

  */

  @media (max-width: 800px) {
    padding: 0px 25.19px 0px 25.19px;
    margin-bottom: 0px;
  }

  img.banner-publish {
    border-radius: 24px;

    height: 17.15rem;

    @media (max-width: 800px) {
      border-radius: 8px;

      height: 98px;
    }
  }
`;
