import { memo } from "react";

import { RiCalendarEventLine, RiLiveLine, RiMapPinLine } from "react-icons/ri";

import { Event } from "types";

import { Colors } from "constants/colors";

import {
  ButtonEmptyEventMobile,
  CardEvent,
  Container,
  ContainerEmptyEvent,
  ContainerList,
  ContentCardEvent,
  ListCards,
} from "./styles";

import { PaginationEmpty } from "components/PaginationOrganizerEmpty";

interface ListEventProps {
  events: Event[];
}

function ListEventComponent({ events }: ListEventProps) {
  function formatData(data: string) {
    const date = data.split("T")[0];

    const month = date.split("-")[1];
    const day = date.split("-")[2];
    const year = date.split("-")[0];
    return `${day}/${month}/${year}`;
  }

  return (
    <Container>
      {events.length > 0 ? (
        <ContainerList>
          <ListCards>
            {events.map((itemEvent: any, index: number) => (
              <CardEvent key={index}>
                <a href={"/" + itemEvent.nickname}>
                  <img
                    className="icon"
                    width="100%"
                    src={itemEvent.thumb}
                    alt=""
                  />
                  <ContentCardEvent>
                    <div className="content">
                      <h2>
                        {itemEvent?.name?.length > 36
                          ? `${itemEvent?.name?.substring(0, 36)}...`
                          : itemEvent?.name}
                      </h2>

                      <div className="date">
                        <span>
                          {formatData(itemEvent?.begin_date)} -{" "}
                          {itemEvent?.begin_hour
                            .split(":")
                            .slice(0, -1)
                            .join(":")}
                          h
                        </span>
                        <div className="city">
                          {itemEvent?.type_event === 1 ? (
                            <>
                              <RiMapPinLine
                                style={{
                                  color: Colors.primary100,
                                  fontSize: 18,
                                }}
                              />
                              <span>
                                {itemEvent?.address_city},{" "}
                                {itemEvent?.address_state}
                              </span>
                            </>
                          ) : null}
                          {itemEvent?.type_event === 2 ||
                          itemEvent?.type_event === 3 ? (
                            <>
                              <RiLiveLine
                                style={{
                                  color: Colors.primary100,
                                  fontSize: 18,
                                }}
                              />
                              <span>ONLINE</span>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    {itemEvent.categories.map(
                      (itemCategory: any, indexCategory: number) => {
                        return (
                          <div className="badge">
                            <span className="badge" key={indexCategory}>
                              {itemCategory.name}
                            </span>
                          </div>
                        );
                      }
                    )}
                  </ContentCardEvent>
                </a>
              </CardEvent>
            ))}
          </ListCards>
        </ContainerList>
      ) : (
        <>
          <ContainerEmptyEvent>
            <h2>Não há nada por aqui :(</h2>
            <p>
              Não há eventos disponíveis no momento. Mas você pode encontrar
              outros eventos de seu interesse abaixo:
            </p>

            <a href="/">
              <ButtonEmptyEventMobile>
                <RiCalendarEventLine size={18} color={Colors.primary100} />
                <span>Ir para a página de eventos</span>
              </ButtonEmptyEventMobile>
            </a>
          </ContainerEmptyEvent>
          <PaginationEmpty />
        </>
      )}
    </Container>
  );
}

export const ListEvent = memo(ListEventComponent);
