import styled from 'styled-components';

import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { Colors } from "constants/colors";

export const Breadcrumb = styled(AntdBreadcrumb)`
  font: 600 1.2rem/1.2rem Poppins, sans-serif;
  display: flex;
  align-items: center;
  .ant-breadcrumb-link,
  .ant-breadcrumb-link a{
    font-weight: 700;
    color: ${Colors.primary100};
  }
  .ant-breadcrumb-separator{
    font-weight: 700;
    color: ${Colors.secondary100};
  }
  & > span:not(:last-child)>.ant-breadcrumb-link{
    text-decoration: underline;
    cursor: pointer;

  }
  & > span:last-child>.ant-breadcrumb-link{
    color: ${Colors.secondary100};
    max-width: 30vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    height: 21px;
    padding-top: 4px;
  }

`;
