import styled from "styled-components";
import { Colors } from "constants/colors";

interface NumberProps {
  active?: boolean;
}

export const Number = styled.button<NumberProps>`
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 47px;

  background: ${(props) => (props.active ? Colors.primary100 : "transparent")};
  border-radius: 14px;

  h2.number-pagination {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-top: 5px;

    color: ${(props) => (props.active ? Colors.white : Colors.black100)};
  }
`;
