import {
  Container,
  ContainerBorderAvailability,
  ContentCardEvent,
} from "./styles";

import { FeaturedEvent } from "types";

import { BadgeTicketStatus } from "components/BadgeTicketStatus";

import pin from "assets/pin.svg";

interface SearchCardProps {
  data: FeaturedEvent;
}

export function SearchCard({ data }: SearchCardProps) {
  return (
    <Container key={data.id}>
      <img className="thumb-search" src={data.thumb} alt="" />

      <ContentCardEvent>
        <div className="content">
          <h2>
            {data?.name?.length > 36
              ? `${data?.name?.substring(0, 36)}...`
              : data?.name}
          </h2>

          <div className="date">
            <span>
              {data.formattedDate} {"\u00B7"} {data.formattedDay}
            </span>

            {data?.type_event !== 2 ? (
              <div className="city">
                <img src={pin} width="13px" height="14px" alt="pin" />

                <span>
                  {data?.address_city}, {data?.address_state}
                </span>
              </div>
            ) : null}
          </div>
        </div>

        <div className="list-categories">
          {data?.categories?.length
            ? data?.categories?.map((category) => (
                <div className="badge" key={category.id}>
                  <span>{category.name}</span>
                </div>
              ))
            : null}
        </div>

        <ContainerBorderAvailability>
          <div className="description">
            <span>Por</span>
            <strong>{data?.organizer?.name}</strong>
          </div>

          <BadgeTicketStatus
            active={data?.tickets?.length > 0}
            wrap={data?.organizer?.name?.length > 8}
          />
        </ContainerBorderAvailability>
      </ContentCardEvent>
    </Container>
  );
}
