import React from "react";
import { useNavigate, Link } from "react-router-dom";

import {
  RiArrowDropRightLine,
  RiQuestionLine,
  RiQuestionnaireLine,
} from "react-icons/ri";

import {
  Container,
  Section,
  FlexTitle,
  QuestionsContainer,
  ContactUs,
  ContactIcon,
  SearchItem,
  Question,
  IconContainer,
  NoResultLabel,
} from "./styles";

import { Breadcrumb } from "components/Breadcrumb";
import { Flex } from "containers/Flex";

interface Props {
  searchData: any;
  isLoading: boolean;
  isSearching: (search: any) => void;
}

const Results: React.FC<Props> = ({ searchData, isSearching }) => {
  let navigate = useNavigate();

  const handleQuestion = (id: any, category: any) => {
    navigate(`/faq/${category}/${id}`);
  };
  return (
    <Container className="first-container">
      <Section>
        <div className="content">
          <QuestionsContainer>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <span onClick={() => isSearching(false)}>Início</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Resultados da pesquisa</Breadcrumb.Item>
            </Breadcrumb>

            <FlexTitle>
              <p className="title">Resultados da pesquisa</p>
            </FlexTitle>
            <SearchItem>
              {searchData !== false && searchData?.length > 0 ? (
                searchData?.map((item: any, index: any) => {
                  return (
                    <Question
                      key={index}
                      onClick={() => handleQuestion(item.id, item.category)}
                    >
                      <Flex alignItems="center">
                        <IconContainer>
                          <RiQuestionLine />
                        </IconContainer>
                        {item.title}
                      </Flex>
                      <RiArrowDropRightLine
                        style={{ flexShrink: 0 }}
                        fontSize={32}
                      />
                    </Question>
                  );
                })
              ) : (
                <NoResultLabel>Nenhum resultado foi encontrado</NoResultLabel>
              )}
            </SearchItem>

            <ContactUs>
              <ContactIcon>
                <RiQuestionnaireLine />
              </ContactIcon>
              <p>
                Não solucionou sua dúvida?{" "}
                <Link to="/fale-conosco">Fale conosco</Link>
              </p>
            </ContactUs>
          </QuestionsContainer>
        </div>
      </Section>
    </Container>
  );
};

export default Results;
