import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const ContainerItems = styled.div`
  margin-top: 7.96rem;

  max-width: 1280px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 28px;
  padding-left: 28px;

  @media (max-width: 800px) {
    margin-top: 85px;
  }

  div.header-title {
    h1.title {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 160%;

      color: ${Colors.black100};
      margin-top: 54px;
    }

    @media (max-width: 800px) {
      display: none;
    }
  }
`;

export const ContainerAllItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.4rem;

  @media (max-width: 800px) {
    margin-top: 0px;
  }
`;

export const CardProfile = styled.div`
  max-width: 1480px;
  min-height: 441px;
  width: 100%;

  display: flex;

  flex-direction: column;
  justify-content: flex-start;

  border: 1px solid ${Colors.black10};
  padding: 19px 41px 25px 42px;
  box-shadow: 0px 4px 10px rgba(18, 25, 34, 0.05);
  border-radius: 20px;

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    box-shadow: none;
    padding: 0;
    border: 0;
  }

  h2.title {
    font-family: "Cairo";
    font-weight: 900;
    font-size: 1.5rem;
    margin-bottom: 0px;
    color: ${Colors.black100};

    @media (max-width: 800px) {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      margin-bottom: 0px;

      color: ${Colors.black100};
    }
  }

  div.full-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 25px;
  }
`;

export const ListAddresses = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 800px) {
    margin-top: 25px;
  }

  > div + div {
    margin-top: 18px;
  }
`;

export const CardAddress = styled.div`
  width: 100%;
  min-height: 249px;
  padding: 14px 40px 24px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(18, 25, 34, 0.05);
  border-radius: 14px;

  @media (min-width: 800px) {
    div.icon {
      /* display: none; */
      position: relative;

      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 800px) {
    box-shadow: 0px 18px 33px rgba(108, 93, 211, 0.1);
    border-radius: 14px;
    border: 1px solid ${Colors.border20};
    min-height: 165px;
    padding: 22px 18px;
  }

  div.content {
    h2 {
      font-weight: bold;
      font-size: 22px;
      line-height: 160%;
      color: ${Colors.secondary100};

      @media (max-width: 800px) {
        font-size: 14px;
      }
    }

    nav.texts {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      p {
        margin-top: -6px;
        font-weight: normal;
        font-size: 20px;
        color: ${Colors.black100};

        @media (max-width: 800px) {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }

  div.buttons {
    display: flex;
    flex-direction: row;
  }
`;

export const ButtonEditAddress = styled.button`
  border: 0;
  cursor: pointer;
  width: 153px;
  height: 60px;

  margin-right: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${Colors.white20};
  border-radius: 14px;

  @media (max-width: 800px) {
    width: 25.73px;
    height: 25.73px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.icon {
    @media (max-width: 800px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div.text {
    @media (max-width: 800px) {
      display: none;
    }

    span {
      font-weight: bold;
      font-size: 20px;
      line-height: 160%;
      color: ${Colors.green10};
    }
  }
`;

export const ButtonRemoveAddress = styled.button`
  border: 0;
  cursor: pointer;
  width: 153px;
  height: 60px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${Colors.white20};
  border-radius: 14px;

  @media (max-width: 800px) {
    width: 25.73px;
    height: 25.73px;
    border-radius: 50%;
  }

  div.icon {
    @media (max-width: 800px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div.text {
    @media (max-width: 800px) {
      display: none;
    }

    span {
      font-weight: bold;
      font-size: 20px;
      line-height: 160%;

      color: ${Colors.red10};
    }
  }
`;

export const ButtonEdit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;

  border-radius: 14px;
  width: 262px;
  height: 60px;
  background-color: ${Colors.primary10};

  @media (max-width: 1200px) {
    width: 100%;
    margin-top: 30px;
  }

  span {
    margin-left: 10px;
    color: ${Colors.primary100};
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
  }

  @media (max-width: 800px) {
    height: 38px;
    border-radius: 8px;

    span {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 0px;

      color: ${Colors.primary100};
    }
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-left: 13px;
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;
  }
`;

export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div.all-input {
    width: 94%;
  }
`;

export const DivEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  h2.empty {
    font-weight: bold;
    font-size: 26px;
    line-height: 160%;
    /* identical to box height, or 42px */

    color: ${Colors.gray10};
  }

  p.empty {
    font-weight: normal;
    font-size: 22px;
    line-height: 160%;
    /* or 35px */

    text-align: center;

    color: ${Colors.gray20};
  }
`;
