import { Colors } from "constants/colors";
import styled from "styled-components";

import { Drawer } from "antd";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const DrawerStyled = styled(Drawer)``;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  padding-right: 38px;
  padding-left: 38px;

  margin-top: 9.14rem;

  width: 100%;

  @media (max-width: 1024px) {
    display: none;
  }

  h1 {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 1.87rem;

    margin-top: 2.53rem;

    color: ${Colors.black100};
    margin-bottom: 0px;

    @media (max-width: 1024px) {
      margin-top: 54px;
    }
  }

  div.bread {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const Background = styled.div`
  height: 320px;

  @media (max-width: 1200px) {
    height: 390px;
  }

  width: 100%;

  display: flex;
  justify-content: center;
  margin: 0 auto;

  background: url(${"/assets/capa-organizer.png"});
  background-repeat: no-repeat;

  @media (max-width: 800px) {
    width: 100%;
    height: 115px;
    margin-top: 65px;

    background: url(${"/assets/capa-organizer-mobile.png"});
    background-repeat: no-repeat;

    background-size: cover;
  }
`;

export const ContainerHeading = styled.div`
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    height: 100%;
  }

  @media (max-width: 1320px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  div.content-header {
    display: flex;
    flex-direction: row;

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Heading = styled.header`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 240px;
    height: 240px;
    border-radius: 50%;
  }

  @media (max-width: 800px) {
    width: 115px;
    height: 115px;
    margin-top: 25px;
  }
`;

export const Content = styled.div`
  margin-left: 24px;

  h2 {
    color: ${Colors.black100};
    font-weight: bold;
    font-size: 40px;
    line-height: 160%;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ContainerAllEvents = styled.div`
  display: flex;
  flex-direction: row;

  div.events-d {
    display: flex;
    flex-direction: row;
    align-items: center;

    div.badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background: ${Colors.secondary10};
      border-radius: 12px;
    }

    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 160%;
      color: ${Colors.secondary100};
      margin-top: 8px;
      margin-left: 12px;
    }
  }

  div.events-r {
    margin-left: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;

    div.badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background: ${Colors.secondary10};
      border-radius: 12px;
    }

    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 160%;
      color: ${Colors.secondary100};
      margin-top: 8px;
      margin-left: 12px;
    }
  }
`;

export const ContentMobile = styled.div`
  @media (min-width: 800px) {
    display: none;
  }

  margin-top: 12px;

  h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    color: ${Colors.secondary100};
    text-align: center;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
`;

export const ContainerAllEventsMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div.events-d {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;

    div {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: ${Colors.secondary70};
        margin-top: 6px;
        margin-left: 7px;
      }
    }

    h4 {
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      color: ${Colors.secondary40};
    }
  }

  div.events-r {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: ${Colors.secondary70};
        margin-top: 6px;
        margin-left: 7px;
      }
    }

    h4 {
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      color: ${Colors.secondary40};
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1200px) {
    margin-top: 30px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  width: 262px;
  height: 60px;
  margin-left: 10px;
  background: ${Colors.primary10};
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    color: ${Colors.primary100};
    margin-left: 12px;
  }
`;

export const ContainerTabs = styled.div`
  .ant-tabs-tab-active {
    color: ${Colors.primary100} !important;
  }
  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }
  /* .ant-tabs-tab-btn {
    margin-left: 20px;
  } */

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Colors.primary100} !important;
    font-weight: 500;
  }
  @media (max-width: 1230px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ContainerTabsMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  width: 100%;

  background: ${Colors.white};

  min-height: 400px;

  @media (min-width: 800px) {
    display: none;
  }

  .ant-tabs-nav {
    width: 100% !important;
    padding-left: 28px;
    padding-right: 28px;
  }

  .ant-tabs-tab {
    display: block;
    flex: 1;
    text-align: center;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }

  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Colors.primary100} !important;
    font-weight: 500;
  }
`;

export const AboutMobile = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 18px 20px;
  border: 1px solid ${Colors.border60};
  border-radius: 10px;

  h2 {
    margin-bottom: 14px;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.black100};
  }

  p + p {
    margin-top: 12px;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    color: ${Colors.gray10};
  }
`;

export const CardIntegration = styled.div`
  width: 24.98rem;
  height: 12.28rem;

  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  box-shadow: 0px 4px 23px rgba(108, 93, 211, 0.1);
  border-radius: 0.65rem;

  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.header-integration {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;

    .ant-switch-checked {
      background-color: ${Colors.primary100};
    }
  }

  div.content-integration {
    margin-top: 0.98rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    div.badge {
      cursor: pointer;
      width: 2.1rem;
      height: 2.1rem;

      display: flex;
      align-items: center;
      justify-content: center;

      background: ${Colors.primary10};
      border-radius: 0.56rem;
    }

    div.texts {
      width: 67%;
      h2 {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 1.03rem;
        margin-bottom: 0px;
        color: ${Colors.black80};
      }

      span {
        font-family: "Open Sans";
        font-weight: normal;
        font-size: 0.84rem;
        margin-top: 0.46rem;
        margin-bottom: 0px;
        color: ${Colors.black50};
      }
    }
  }
`;

export const ContentCardEvent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  div.content {
    display: flex;
    flex-direction: column;

    h2 {
      font-weight: bold;
      font-size: 26px;
      line-height: 160%;
      color: ${Colors.black100};
      margin-bottom: -1px;

      @media (max-width: 800px) {
        font-size: 11px;
        line-height: 21px;
        margin-top: 5px;
        margin-bottom: -2px;
      }
    }

    div.date {
      display: flex;
      align-items: center;

      span {
        color: ${Colors.primary100};
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;

        @media (max-width: 800px) {
          font-size: 11px;
          line-height: 21px;
          font-weight: normal;
          font-size: 8px;
          line-height: 11px;
        }
      }

      div.city {
        margin-left: 28.5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 800px) {
          display: none;
        }

        span {
          margin-left: 7px;
        }
      }
    }
  }

  div.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74px;
    height: 26px;
    background: ${Colors.black10};
    border-radius: 14px;
    margin-top: 20px;

    @media (max-width: 800px) {
      margin-top: 15px;
    }

    span {
      color: ${Colors.secondary100};
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;

      @media (max-width: 800px) {
        font-weight: 600;
        font-size: 8px;
        line-height: 11px;
      }
    }

    @media (max-width: 800px) {
      width: 52px;
      height: 14px;
    }
  }

  div.description {
    margin-top: 25px;
    display: flex;
    flex-direction: column;

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 160%;
      color: ${Colors.black30};
    }

    strong {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: ${Colors.secondary100};
    }
  }
`;

export const ListCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  > div + div {
    margin-left: 10px;
  }
`;

export const ListCardsMobile = styled.div``;

export const DivSelect = styled.div`
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
  margin-top: 31px;
`;

export const ContainerAboutMobile = styled.div`
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
`;

export const ContactMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: ${Colors.gray20};
    opacity: 0.75;
    margin-top: 34px;
  }

  div.cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    div.card {
      width: 140px;
      height: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid ${Colors.border20};
      box-shadow: 0px 2px 10px rgba(108, 93, 211, 0.05);
      border-radius: 8px;

      h3 {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: ${Colors.black100};
        margin-top: 10px;
      }
    }
  }
`;

export const FooterItems = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 38px;

  h3 {
    font-weight: 600;
    font-size: 11px;
    line-height: 21px;
    color: ${Colors.black50};
  }
`;

export const ButtonMoreItems = styled.button`
  width: 100%;
  height: 38px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 34px;

  border: 0;
  background: ${Colors.primary100};
  /* box-shadow: 0px 5px 30px rgba(212, 0, 80, 0.25); */
  border-radius: 8px;

  span {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: ${Colors.white};
  }
`;

export const ContainerList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`;

export const ContainerListMobile = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 28px 10px 28px;
  margin-top: 26px;

  background: linear-gradient(
    180deg,
    ${Colors.black2} 89.75%,
    rgba(248, 248, 250, 0) 101.35%
  );

  h2.header {
    font-weight: bold;
    font-size: 9px;
    line-height: 12px;
    text-transform: uppercase;
    color: ${Colors.gray10};
    opacity: 0.75;
    margin-top: 12px;
  }
`;

export const CardEventMobile = styled.div`
  width: 100%;
  min-height: 113px;
  margin-top: 15px;
  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  box-shadow: 0px 5px 15px rgba(108, 93, 211, 0.15);
  border-radius: 5px;
  border-radius: 14px;
  padding: 5px 3px 9px 3px;
  display: flex;
  flex-direction: row;

  img.productive {
    border-radius: 14px;
    max-width: 157px;
  }
`;

export const ContentCardEventMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-left: 13px;

  div.head {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  div.content {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 21px;

      color: ${Colors.secondary100};

      @media (max-width: 800px) {
        font-size: 11px;
        line-height: 21px;
        margin-bottom: -2px;
      }
    }

    div.date {
      display: flex;
      align-items: center;

      span {
        color: ${Colors.primary100};
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;

        @media (max-width: 800px) {
          font-size: 11px;
          line-height: 21px;
          font-weight: normal;
          font-size: 8px;
          line-height: 11px;
        }
      }

      div.city {
        margin-left: 28.5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 800px) {
          display: none;
        }

        span {
          margin-left: 7px;
        }
      }
    }
  }

  div.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74px;
    height: 26px;
    background: ${Colors.black10};
    border-radius: 14px;
    margin-top: 20px;

    @media (max-width: 800px) {
      margin-top: 15px;
    }

    span {
      color: ${Colors.secondary100};
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;

      @media (max-width: 800px) {
        font-weight: 600;
        font-size: 8px;
        line-height: 11px;
      }
    }

    @media (max-width: 800px) {
      width: 52px;
      height: 14px;
    }
  }

  div.description {
    margin-top: 18px;
    display: flex;
    flex-direction: column;

    span {
      font-weight: 600;
      font-size: 7px;
      line-height: 10px;
      color: ${Colors.gray};
    }

    strong {
      font-weight: 600;
      font-size: 8px;
      line-height: 11px;
      color: ${Colors.secondary100};
    }
  }
`;

export const HeaderSearching = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 1.54rem;

  width: 100%;
  height: 63px;
  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  border-radius: 14px;

  div.filters {
    width: 18%;
    height: 100%;
    display: flex;
    border-left: 1px solid ${Colors.border30};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 31px 0px 40px;

    @media (max-width: 1180px) {
      width: 28%;
    }
  }
`;

export const HeaderEventBar = styled.div`
  width: 80%;
  display: flex;

  padding-left: 25px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div.icons {
    width: 7%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1180px) {
      width: 10%;
      margin-right: 10px;
    }
  }

  div.buttons {
    button {
      cursor: pointer;
      background: transparent;
      border: 0;
      margin-left: 38px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 34px;
      color: ${Colors.black30};
    }

    button.first {
      margin-left: 0px;
    }

    button.active {
      font-weight: bold;
      font-size: 18px;
      line-height: 34px;

      color: ${Colors.black100};
    }
  }
`;

export const InformationsOfOrganizer = styled.div`
  padding: 40px 60px;

  h2 {
    font-weight: bold;
    font-size: 32px;
    line-height: 160%;
    color: ${Colors.black100};
  }

  p {
    margin-top: 30px;
    font-weight: normal;
    font-size: 22px;
    line-height: 160%;
    color: ${Colors.black70};
  }
`;

export const ContainerEmptyEvent = styled.div`
  margin-top: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 900;
    font-size: 1.21rem;
    margin-bottom: 0px;
    color: ${Colors.gray10};
    opacity: 0.75;
  }

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.03rem;
    margin-bottom: 0px;
    text-align: center;

    color: #adadad;
    margin-top: 0.93rem;
    width: 50%;
  }
`;

export const ContainerEmptyEventMobile = styled.div`
  padding-top: 45px;
  padding-bottom: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: ${Colors.gray10};
    opacity: 0.75;
  }

  p {
    font-family: "Open Sans";
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    color: ${Colors.gray20};
  }
`;

export const ButtonEmptyEvent = styled.button`
  cursor: pointer;
  border: 0;
  width: 362px;
  height: 60px;
  background: ${Colors.primary10};
  border-radius: 14px;

  margin-top: 2.9rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    color: ${Colors.primary100};
    margin-left: 10px;
  }
`;

export const ButtonEmptyEventMobile = styled.button`
  cursor: pointer;
  border: 0;
  width: 100%;
  height: 38px;
  background: ${Colors.primary10};
  border-radius: 8px;
  margin-top: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: ${Colors.primary100};
    margin-left: 10px;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-left: 13px;
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;

export const ContainerItemsMobile = styled.div`
  max-width: 1280px;
  padding-left: 28px;
  padding-right: 28px;

  margin-top: 95px;
  width: 100%;

  @media (min-width: 1024px) {
    display: none;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    div.first {
      h1 {
        font-weight: bold;
        font-size: 16px;
        line-height: 25px;

        color: ${Colors.black100};
      }
    }

    div.box {
      width: 25.73px;
      height: 25.73px;
      border-radius: 50%;
      background: ${Colors.white20};

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
    }
  }
`;

export const ContainerBorderMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  border-top: 1px solid ${Colors.secondary10};

  padding-top: 24px;
  margin-top: 23px;

  h3 {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;

    color: ${Colors.gray20};
    opacity: 0.75;
  }

  div.integration {
    width: 100%;
    min-height: 91px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: ${Colors.white};
    border: 1px solid ${Colors.border20};
    border-radius: 5px;

    margin-top: 10px;
    padding: 9px 17px 13px 17px;

    div.first {
      display: flex;
      flex-direction: column;

      div.row {
        display: flex;
        flex-direction: row;
        align-items: center;

        h3 {
          font-weight: bold;
          font-size: 14px;
          line-height: 26px;

          color: ${Colors.black100};
          margin-left: 22px;
          margin-bottom: 0px;
        }
      }

      p {
        width: 90%;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;

        color: ${Colors.black50};
        margin-top: 10px;
      }
    }

    div.second {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      .ant-switch-checked {
        background-color: ${Colors.primary100};
      }
    }
  }
`;

export const ButtonNewIntegration = styled.div`
  cursor: pointer;
  margin-top: 30px;
  border: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 38px;

  height: 38px;

  background: ${Colors.primary10};
  border-radius: 8px;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;

    margin-left: 9px;
    color: ${Colors.primary100};
  }
`;

export const ContainerBottom = styled.div`
  height: 90px;
  margin-top: 30px;
  padding-left: 28px;

  > div + div {
      margin-top: 19px;
    }


  div.row {
    display: flex;
    flex-direction: row;
    align-items:center;


    h3.edit {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      color:${Colors.black50};
      margin-bottom: 0px;
      margin-left: 10px;
    }

    h3.remove {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      color:${Colors.pink30};
      margin-bottom: 0px;
      margin-left: 10px;
    }

    div.badge-edit {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background:${Colors.secondary10};
    }

    div.badge-remove {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: ${Colors.primary5};
    }
  }
`;

export const ContainerListIntegrationsMobile = styled.div`
  max-width: 1280px;
  padding-left: 28px;
  padding-right: 28px;

  margin-top: 72px;
  width: 100%;

  @media (min-width: 1024) {
    display: none;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    div.first {
      h1 {
        font-family: "Cairo";
        font-weight: 900;
        font-size: 16px;
        line-height: 25px;

        color: ${Colors.black100};
      }
    }

    div.box {
      width: 25.73px;
      height: 25.73px;
      border-radius: 50%;
      background: ${Colors.white20};

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
    }
  }
`;

export const ContainerSearch = styled.div`
  margin-top: 23px;

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 38px;
    border: 1px solid ${Colors.border};

    border-radius: 8px;

    div.searchInput {
      width: 100%;
      height: 100%;
      input {
        height: 38px;
        width: 90%;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0px 2px 0px 28px;
        font-size: 14px;
        caret-color: ${Colors.primary100};
      }
    }

    div.search {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 47px;
      height: 100%;
      border-left: 1px solid ${Colors.border};
    }
  }
`;

export const ContainerListAllIntegrationsMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  > div + div {
    margin-top: 10px;
  }

  div.integration {
    width: 100%;
    min-height: 91px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: ${Colors.white};
    border: 1px solid ${Colors.border20};
    border-radius: 5px;

    padding: 9px 17px 13px 17px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;

      color: ${Colors.black50};
      margin-top: 10px;
    }

    div.row {
      /* width: 100%; */
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      div.badge-active-integration {
        width: 44px;
        height: 15px;
        margin-top: 0px;

        background: ${Colors.primary100};
        border-radius: 4px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 4px;

        p.badge {
          font-weight: bold;
          font-size: 8px;

          color: ${Colors.white};
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }

      div.badge-inative-integration {
        width: 52px;
        height: 15px;

        background: ${Colors.gray55};
        border-radius: 4px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 4px;

        p.badge {
          font-weight: bold;
          font-size: 8px;

          color: ${Colors.gray15};
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }

      div.first {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        h3 {
          margin-bottom: 0px;
          font-weight: bold;
          font-size: 14px;
          line-height: 26px;

          /* Preto */

          color: ${Colors.black100};
          margin-left: 22px;
        }
      }
    }
  }
`;

export const ContainerActiveIntegrationMobile = styled.form`
  max-width: 1280px;
  padding-left: 28px;
  padding-right: 28px;

  margin-top: 70px;
  width: 100%;

  @media (min-width: 1024) {
    display: none;
  }

  div.border-activated {
    height: 94px;
    margin-top: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${Colors.secondary10};
    border-bottom: 1px solid ${Colors.secondary10};

    h2.active {
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;

      color: ${Colors.black100};
    }

    .ant-switch-checked {
      background-color: ${Colors.primary100};
    }
  }

  div.container-description-integration {
    width: 100%;
    min-height: 133px;

    background: ${Colors.white};
    border: 1px solid ${Colors.border20};
    border-radius: 5px;
    padding: 9px 17px 13px 17px;

    div.row {
      /* width: 100%; */
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      div.first {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        h3 {
          margin-bottom: 0px;
          font-weight: bold;
          font-size: 14px;
          line-height: 26px;

          color: ${Colors.black100};
          margin-left: 22px;
        }
      }
    }

    p {
      margin-top: 10px;
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;

      color: ${Colors.black50};

      a {
        color: ${Colors.primary100};
      }
    }
  }
`;

export const FormIntegratedKeys = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 34px;

  h3.information {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;

    color: ${Colors.black100};
  }

  div.all-input-first {
    margin-top: 30px;
    width: 100%;

    span {
      color: ${Colors.red10};
      font-size: 16px;
      margin-top: 4px;
    }
  }

  div.all-input {
    width: 94%;

    span {
      color: ${Colors.red10};
      font-size: 16px;
      margin-top: 4px;
    }
  }

  input {
    width: 96%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid ${Colors.secondary20};
    color: ${Colors.secondary70};
    font-size: 20px;
    line-height: 160%;
    padding: 0px 0px 4px 23px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    flex-direction: column;

    div.all-input {
      width: 100%;
    }
  }
`;

export const ButtonCancelMobile = styled.button`
  border: 0;
  width: 100%;

  height: 38px;

  background: ${Colors.primary10};
  border-radius: 8px;

  font-weight: 600;
  font-size: 12px;
  line-height: 22px;

  color: ${Colors.primary100};
  margin-top: 59px;
`;

export const ButtonSaveMobile = styled.button`
  border: 0;
  width: 100%;

  height: 38px;

  background: ${Colors.primary100};

  box-shadow: 0px 5px 30px rgba(212, 0, 80, 0.25);
  border-radius: 8px;

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: ${Colors.white};
  }

  margin-top: 30px;
`;

export const ButtonConnect = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 5.1rem;
  height: 2.25rem;

  background: ${Colors.primary100};
  border-radius: 0.46rem;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    margin-bottom: 0px;
    color: ${Colors.white};
  }
`;
