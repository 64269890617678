import React from "react";
import { useNavigate } from "react-router-dom";

import { Spin } from "antd";

import { RiArrowDropRightLine, RiQuestionLine } from "react-icons/ri";

import { useFaq } from "services/Hook/Faq";

import {
  Container,
  Section,
  FlexTitle,
  QuestionsContainer,
  TitleIconContainer,
  IconContainer,
  ContainerQuestions,
  Question,
  MenuDescription,
} from "./styles";

import { Flex } from "containers/Flex";
import { Breadcrumb } from "components/Breadcrumb";

interface Props {
  card: any;
  handleCards: (card: any) => void;
}

const CardMenu: React.FC<Props> = ({ handleCards, card }) => {
  const navigate = useNavigate();

  const { isValidating, data } = useFaq();

  const handleQuestion = (id: any, category: any) => {
    navigate(`/faq/${category}/${id}`);
  };

  return (
    <Container className="first-container">
      <Section>
        <div className="content">
          <QuestionsContainer>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <span
                  onClick={() => {
                    handleCards("");
                  }}
                >
                  Início
                </span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{card?.title}</Breadcrumb.Item>
            </Breadcrumb>

            <FlexTitle>
              <TitleIconContainer>{card?.icon}</TitleIconContainer>
              <p className="title">{card?.title}</p>
            </FlexTitle>
            <MenuDescription>{card?.description}</MenuDescription>
            <ContainerQuestions>
              {}
              {isValidating && (
                <>
                  <Spin />
                </>
              )}
              {data?.data?.map((item: any, index: any) => {
                if (item?.category === card.category) {
                  return (
                    <Question
                      key={index}
                      onClick={() => handleQuestion(item.id, item.category)}
                    >
                      <Flex alignItems="center">
                        <IconContainer>
                          <RiQuestionLine />
                        </IconContainer>
                        {item.title}
                      </Flex>
                      <RiArrowDropRightLine fontSize={32} />
                    </Question>
                  );
                }
                return "";
              })}
            </ContainerQuestions>
          </QuestionsContainer>
        </div>
      </Section>
    </Container>
  );
};

export default CardMenu;
