/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

import { useEventByNickName } from "services/Hook/Event";
import { useOrganizerByNickName } from "services/Hook/Organizer";

import { Event } from "types";

import { Section, Wrapper } from "./styles";

import FirstSection from "./FirstSection";
import ImageContainer from "./ImageContainer";
import DescriptionContainer from "./DescriptionContainer";
import Popup from "containers/Popup";
import OrganizerDetail from "../OrganizerDetail";
import NoEventFound from "./NoEventFound";
import BottomCollapse from "./BottomCollapse";
import ModalPurchase from "./ModalPurchase";
import ModalPayment from "./ModalPayment";
import ModalSuccess from "./ModalSuccess";
import ModalAuth from "./ModalAuth";
import ModalCart from "./ModalCart";
import ModalForgot from "./ModalForgot";

import { Footer } from "components/Footer";
import { Header } from "components/Header";
import ContactUsContainer from "components/ContactUsContainer";

import logo_eventx_preto from "assets/logo_eventx_preto.svg";

const EventDetail: React.FC = (props) => {
  const { id } = useParams();
  const [loadType, setLoadType] = useState(0);

  const [isVisible, setIsVisible] = useState(false);
  const [isPurchase, setIsPurchase] = useState(false);
  const [isModalForgot, setIsModalForgot] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isCart, setIsCart] = useState(false);

  const [load, setLoad] = useState(false);

  const [organizer, setOrganizer] = useState({
    name: "",
    description: "",
    nickname: "",
  });

  const { data: dataEvent, error: errorDataEvent } = useEventByNickName<Event>(
    id ?? ""
  );

  const { data: dataOrganizer, error: errorDataOrganizer } =
    useOrganizerByNickName(id ?? "");

  const handleCookies = () => {
    setIsVisible(false);
  };
  const handlePurchase = () => {
    setIsPurchase(false);
  };
  const handleSuccess = () => {
    setIsSuccess(true);
  };
  const handlePayment = () => {
    setIsPayment(false);
  };
  const handleAuth = () => {
    setIsAuth(false);
  };
  const handleCart = () => {
    setIsCart(false);
  };

  /* Cart CODE */
  interface IformDataTickets {
    ticket_id: string;
    price: number;
    quantity: number;
    discount: number;
    absorb_rate: boolean;
    name: string;
    description: string;
    event_id: string;
    user_quantity_max: number;
    user_quantity_min: number;
  }

  const [cart, setCart] = useState<IformDataTickets[]>([]);
  const [organizerFee, setOrganizerFee] = useState<number>(0);

  const [carrinhoQuantityTotal, setCarrinhoQuantityTotal] = useState(0);
  const [carrinhoTotal, setCarrinhoTotal] = useState(0);

  const [purchase, setPurchase] = useState("");
  const [urlPayment, setUrlPayment] = useState("");
  const changeUrlPayment = (purchase: string, url: string) => {
    clearCart();
    setIsPurchase(false);
    setUrlPayment(url);
    setPurchase(purchase);
    setIsPayment(true);
  };

  const setPaymentSuccess = () => {
    setIsPayment(false);
    setIsSuccess(true);
  };

  async function changeCart(ticket_id: string, quantity: number) {
    const newArray: any = [];

    let quantityTotal = 0;
    let total = 0;

    cart.map((itemArray: any, indexArray: number) => {
      let subtotal = 0;
      let new_quantity = 0;

      if (itemArray.ticket_id === ticket_id) {
        new_quantity = quantity;
      } else {
        new_quantity = itemArray.quantity;
      }

      quantityTotal = quantityTotal + new_quantity;

      newArray.push({
        ticket_id: itemArray.ticket_id,
        name: itemArray.name,
        description: itemArray.description,
        price: itemArray.price,
        guest: itemArray.guest,
        guest_quantity: itemArray.guest_quantity,
        quantity: new_quantity,
        discount: itemArray.discount,
        absorb_rate: itemArray.absorb_rate,
        event_id: itemArray.event_id,
        user_quantity_max: itemArray.user_quantity_max,
        user_quantity_min: itemArray.user_quantity_min,
      });

      subtotal =
        new_quantity *
        (itemArray.discount === 0
          ? itemArray.price
          : itemArray.price - itemArray.discount);

      if (!itemArray.absorb_rate) {
        const rate = subtotal / 100;
        const rate2 = rate * organizerFee;

        subtotal = subtotal + rate2;
      }

      total = total + subtotal;
    });

    setCarrinhoQuantityTotal(quantityTotal);
    setCarrinhoTotal(total);

    setCart(newArray);

    const saveArray = { event_id: dataEvent?.id, itens: newArray };
    localStorage.setItem("@ventX-web_cart", JSON.stringify(saveArray));
  }

  async function clearCart() {
    const eventTickets = dataEvent?.tickets;
    const newFormData: any = [];
    eventTickets?.map((item: any, index: any) => {
      newFormData.push({
        ticket_id: item.id,
        name: item.name,
        description: item.description,
        price: item.price,
        quantity: 0,
        discount: item.discount,
        absorb_rate: item.absorb_rate,
        event_id: item.event_id,
        user_quantity_max: item.user_quantity_max,
        user_quantity_min: item.user_quantity_min,
      });
    });

    setCarrinhoQuantityTotal(0);
    setCarrinhoTotal(0);
    setCart(newFormData);
    localStorage.removeItem("@ventX-web_cart");
  }

  async function startScreen(eventTickets: any) {
    const newFormData: any = [];

    eventTickets.tickets.map((item: any) => {
      newFormData.push({
        ticket_id: item.id,
        name: item.name,
        description: item.description,
        price: item.price,
        guest: item.guest,
        guest_quantity: item.guest_quantity,
        quantity: 0,
        discount: item.discount,
        absorb_rate: item.absorb_rate,
        event_id: item.event_id,
        user_quantity_max: item.user_quantity_max,
        user_quantity_min: item.user_quantity_min,
      });
    });

    setCart(newFormData);
    setOrganizerFee(eventTickets.organizer.fee);

    var storedCart = localStorage.getItem("@ventX-web_cart");

    if (storedCart !== null && storedCart !== "") {
      const arrayCart = JSON.parse(storedCart);

      if (arrayCart.event_id === eventTickets.id) {
        let quantityTotal = 0;
        let total = 0;

        arrayCart.itens.map((itemArray: any, indexArray: number) => {
          let subtotal = 0;

          quantityTotal = quantityTotal + itemArray.quantity;
          subtotal =
            itemArray.quantity *
            (itemArray.discount === 0
              ? itemArray.price
              : itemArray.price - itemArray.discount);

          if (!itemArray.absorb_rate) {
            const rate = subtotal / 100;
            const rate2 = rate * eventTickets.organizer.fee;

            subtotal = subtotal + rate2;
          }

          total = total + subtotal;
        });

        setCarrinhoQuantityTotal(quantityTotal);
        setCarrinhoTotal(total);
        setCart(arrayCart.itens);
      }
    }
  }

  const openSteps = () => {
    setIsAuth(false);
    setIsPurchase(true);
  };

  const openLogin = () => {
    setIsAuth(true);
  };

  useEffect(() => {
    setLoadType(1);

    if (errorDataEvent !== undefined) {
      setLoadType(2);
    }

    if (!!dataOrganizer) {
      setLoadType(2);
    }
  }, [dataOrganizer, errorDataEvent, id]);

  useEffect(() => {
    if (dataEvent !== undefined) {
      startScreen(dataEvent);
      setLoad(true);

      checkIntegrationsEvent(dataEvent);
    }
  }, [dataEvent, errorDataEvent]);

  useEffect(() => {
    if (dataOrganizer !== undefined) {
      setOrganizer(dataOrganizer);
      setLoad(true);
    }
  }, [dataEvent, dataOrganizer, errorDataEvent]);

  const checkIntegrationsEvent = async (responseData: any) => {
    if (responseData.integrations) {
      responseData.integrations.map((integration: any, index: number) => {
        if (integration.type === 1) {
          ReactGA.initialize(integration.key);
          ReactGA.pageview(window.location.pathname + window.location.search);
        }

        if (integration.type === 2) {
          const options: any = {
            autoConfig: true,
            debug: true,
          };
          ReactPixel.init(integration.key, options);
          ReactPixel.pageView();
        }
      });
    }
  };

  const categoriesKeyword =
    dataEvent?.categories &&
    dataEvent?.categories &&
    dataEvent?.categories?.length > 0
      ? dataEvent.categories.map((category) => category.name).toString()
      : "";

  const metaTags = {
    title: `${loadType === 1 ? dataEvent?.name : organizer?.name} -${" "}
        ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
    description:
      loadType === 1 ? dataEvent?.description : organizer?.description,
    keywords: `${process.env.REACT_APP_EVENTX_META_KEYWORDS} ${
      categoriesKeyword !== "" ? `,${categoriesKeyword}` : ""
    }`,
    ogTitle: loadType === 1 ? dataEvent?.name : organizer?.name,
    ogDescription:
      loadType === 1 ? dataEvent?.description : organizer?.description,
    ogImage: loadType === 1 ? dataEvent?.thumb : logo_eventx_preto,
    ogType: "website",
    ogUrl:
      loadType === 1
        ? "https://www.eventx.com.br/" + dataEvent?.nickname
        : "https://www.eventx.com.br/" + organizer?.nickname,
  };

  return (
    <React.StrictMode>
      {load ? (
        <>
          {load ? (
            <Header
              cart={loadType === 1 ? cart : null}
              changeCart={changeCart}
              quantityTotal={carrinhoQuantityTotal}
              valueTotal={carrinhoTotal}
              openLogin={openLogin}
              openSteps={openSteps}
              rate={organizerFee}
              metaTags={metaTags}
            />
          ) : null}
          <Wrapper>
            <Section></Section>
            {load && loadType === 1 ? (
              <>
                <FirstSection backgroundPhoto={dataEvent?.photo || ""} />
                <ImageContainer data={dataEvent} />
                <DescriptionContainer
                  data={dataEvent}
                  cart={cart}
                  changeCart={changeCart}
                  rate={organizerFee}
                />
              </>
            ) : null}
            {load && loadType === 2 ? (
              <OrganizerDetail data={dataOrganizer} />
            ) : null}
            {load && loadType === 0 ? <NoEventFound /> : null}
            <ContactUsContainer />
            {isVisible && <Popup handleVisible={handleCookies} />}

            {isAuth && (
              <ModalAuth
                handleAuth={handleAuth}
                openSteps={openSteps}
                openForgot={() => setIsModalForgot(true)}
              />
            )}
            {isPurchase && (
              <ModalPurchase
                handlePurchase={handlePurchase}
                event={dataEvent}
                cart={cart}
                setUrlPayment={changeUrlPayment}
                setPaymentSuccess={setPaymentSuccess}
                afiliado={null}
              />
            )}
            {isPayment && (
              <ModalPayment
                handleSuccess={handlePayment}
                event={dataEvent}
                cart={cart}
                purchase={purchase}
                url={urlPayment}
              />
            )}
            {isModalForgot && (
              <ModalForgot closeModal={() => setIsModalForgot(false)} />
            )}
            {isSuccess && (
              <ModalSuccess
                handleSuccess={handleSuccess}
                event={dataEvent}
                cart={cart}
              />
            )}
            {isCart && <ModalCart handleCart={handleCart} />}
          </Wrapper>
          <Footer cart={loadType === 1 ? cart : null} />
          {load && loadType === 1 ? (
            <BottomCollapse
              cart={cart}
              changeCart={changeCart}
              quantityTotal={carrinhoQuantityTotal}
              valueTotal={carrinhoTotal}
              openLogin={openLogin}
              openSteps={openSteps}
              rate={organizerFee}
            />
          ) : null}
        </>
      ) : null}
    </React.StrictMode>
  );
};
export default EventDetail;
