import styled from 'styled-components';

interface FlexProps{
  alignItems?: 'center' | 'flex-start' | 'flex-end' 
  justifyContent?: 'center' | 'flex-start' | 'flex-end' 
  flexDirection?: 'column' | 'row' 
  flexWrap?: 'wrap' | 'no-wrap' 
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  align-items: ${props=>props.alignItems? props.alignItems : "unset" };
  justify-content: ${props=>props.justifyContent? props.justifyContent : "unset"};
  flex-direction: ${props=>props.flexDirection? props.flexDirection : "unset"};
  flex-wrap: ${props=>props.flexWrap? props.flexWrap : "unset"};
`;
