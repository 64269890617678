import styled from "styled-components";

export const Container = styled.div`
  margin-top: 3.4em;
  @media screen and (max-width: 1024px) {
    padding: 0 2em;
    h1,
    p {
      align-items: center;
      text-align: center;
    }
  }
`;
