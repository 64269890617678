import React from "react";

import { Container, Section } from "./styles";

const FirstSection: React.FC = () => {
  return (
    <Container className="first-container">
      <Section>
        <div className="content">
          <div className="heading">
            <p className="title">Termos e condições</p>
          </div>
        </div>
      </Section>
    </Container>
  );
};

export default FirstSection;
