import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useSWRConfig } from "swr";

import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import { IoMdAdd } from "react-icons/io";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import { Colors } from "constants/colors";

import { DeleteAddress, useAddress } from "services/Hook/User/Address";
import { Addresses as AddressesType } from "types";

import {
  Container,
  ContainerItems,
  ContainerAllItems,
  CardProfile,
  ButtonEdit,
  ListAddresses,
  CardAddress,
  ButtonEditAddress,
  ButtonRemoveAddress,
  DivEmpty,
} from "./styles";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { SidebarProfile } from "components/SidebarProfile";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { Title } from "components/Title";

import edit_address_icon from "assets/edit-address-icon.svg";
import remove_address_icon from "assets/remove-address-icon.svg";

export default function Addresses() {
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const { data } = useAddress<AddressesType>();

  const { mutate } = useSWRConfig();

  const handleRemoveAddress = useCallback(
    async (id) => {
      try {
        await DeleteAddress(id);

        alert("Endereço removido com sucesso");
        mutate("/users/address");
      } catch {}
    },
    [mutate]
  );

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const metaTags = {
    title: `Meus endereços - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
    description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
    noIndex: true,
  };

  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <ListBreadcrumb
            data={[
              { name: "Home", link: "/" },
              { name: "Perfil", link: "/" },
            ]}
          />

          <Title />

          <ContainerAllItems>
            <SidebarProfile />

            <CardProfile>
              <h2 className="title">Meus endereços</h2>

              {!data ? <Spin spinning indicator={antIcon} /> : null}

              {data?.addresses.length === 0 ? (
                <DivEmpty>
                  <h2 className="empty">Não há nada por aqui :(</h2>

                  <p className="empty">Não há Endereços</p>
                </DivEmpty>
              ) : null}

              {data ? (
                <ListAddresses>
                  {data.addresses.map((address) => (
                    <CardAddress key={address.id}>
                      <div className="content">
                        <h2>{address.address_title}</h2>
                        <nav className="texts">
                          <p>{address.address_street}</p>
                          <p>{address.address_district}</p>

                          <p>{address.address_city}</p>
                          <p>{address.address_zipcode}</p>
                        </nav>
                      </div>

                      <div className="buttons">
                        <Link
                          style={{ height: "60px" }}
                          to={`/profile/address/edit/${address.id}`}
                        >
                          <ButtonEditAddress>
                            <div className="icon">
                              <img src={edit_address_icon} alt="edit" />
                            </div>
                            <div className="text">
                              <span>Editar </span>
                            </div>
                          </ButtonEditAddress>
                        </Link>

                        <ButtonRemoveAddress
                          onClick={() => {
                            const confirmBox = window.confirm(
                              "Deseja realmente remover esse Endereço?"
                            );
                            if (confirmBox === true) {
                              handleRemoveAddress(address.id);
                            }
                          }}
                        >
                          <div className="icon">
                            <img
                              src={remove_address_icon}
                              alt="remove"
                              width="12px"
                              height="12px"
                            />
                          </div>
                          <div className="text">
                            <span>Excluir</span>
                          </div>
                        </ButtonRemoveAddress>
                      </div>
                    </CardAddress>
                  ))}
                </ListAddresses>
              ) : null}

              <div className="full-button">
                <Link style={{ width: "100%" }} to="/profile/address/create">
                  <ButtonEdit>
                    <IoMdAdd size={18} color={Colors.primary100} />
                    <span>Adicionar novo</span>
                  </ButtonEdit>
                </Link>
              </div>
            </CardProfile>
          </ContainerAllItems>
        </ContainerItems>
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
