import { ContainerTabs } from "./styles";

import { Tabs } from "antd";

import { useTabsTicketsAndPurchases } from "contexts/TabsTicketsAndPurchasesContext";

import { TicketTabPane } from "./TicketTabPane";
import { PurchaseTabPane } from "./PurchaseTabPane";

const { TabPane } = Tabs;

interface TabsTicketsAndPurchasesProps {
  isTickets?: boolean;
}

export default function TabsTicketsAndPurchases({
  isTickets,
}: TabsTicketsAndPurchasesProps) {
  const { selectedPurchaseMobile } = useTabsTicketsAndPurchases();

  if (selectedPurchaseMobile) {
    return <PurchaseTabPane />;
  }

  if (isTickets) {
    return (
      <ContainerTabs>
        <Tabs>
          <TabPane tab="INGRESSOS" key="1">
            <TicketTabPane />
          </TabPane>

          <TabPane tab="MINHAS COMPRAS" key="2">
            <PurchaseTabPane />
          </TabPane>
        </Tabs>
      </ContainerTabs>
    );
  }

  return (
    <ContainerTabs>
      <Tabs>
        <TabPane tab="MINHAS COMPRAS" key="1">
          <PurchaseTabPane />
        </TabPane>

        <TabPane tab="INGRESSOS" key="2">
          <TicketTabPane />
        </TabPane>
      </Tabs>
    </ContainerTabs>
  );
}
