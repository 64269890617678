import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 28px;
`;

export const ContainerList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ListCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(12rem, 1fr));
  column-gap: 0.45rem;
  row-gap: 1.5rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 100%);
    row-gap: 0px;
    column-gap: 0px;
  }
`;

export const CardEvent = styled.div`
  width: 100%;
  max-height: 22.5rem;
  padding: 1.17rem !important;

  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  box-shadow: 0px 15px 33px rgba(108, 93, 211, 0.1);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img.icon {
    border-radius: 14px;
    width: 100%;
    height: 148px;

    object-fit: cover;

    @media (max-width: 800px) {
      height: 118px !important;
      max-height: 128px;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 15px;
    max-height: none;
  }

  @media (max-width: 1200px) {
    width: 98%;
  }
`;

export const ContentCardEvent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  div.content {
    display: flex;
    flex-direction: column;

    h2 {
      font-weight: bold;
      font-size: 26px;
      line-height: 120%;
      color: #11142d;

      @media (max-width: 800px) {
        font-size: 11px;
        line-height: 21px;
        margin-top: 5px;
      }
    }

    div.date {
      display: flex;
      align-items: flex-start;

      span {
        color: ${Colors.primary100};
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;

        @media (max-width: 800px) {
          font-size: 11px;
          line-height: 21px;
          font-weight: normal;
          font-size: 8px;
          line-height: 11px;
        }
      }

      div.city {
        margin-left: 28.5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 800px) {
          display: none;
        }

        span {
          margin-left: 7px;
        }
      }
    }
  }

  div.badge {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    height: 26px;
    border-radius: 14px;
    margin-top: 20px;

    @media (max-width: 800px) {
      margin-top: 15px;
    }

    span {
      color: ${Colors.secondary100};
      font-weight: bold;
      font-size: 12px;
      line-height: 19px;

      color: ${Colors.secondary100};
      padding: 0.3em 0.8em;
      border-radius: 14px;
      background-color: ${Colors.secondary10};

      @media (max-width: 800px) {
        font-weight: 600;
        font-size: 8px;
        line-height: 11px;
      }
    }

    @media (max-width: 800px) {
      width: 52px;
      height: 14px;
    }
  }

  div.description {
    margin-top: 25px;
    display: flex;
    flex-direction: column;

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 160%;
      color: ${Colors.black30};
    }

    strong {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: ${Colors.secondary100};
    }
  }
`;

export const ContainerEmptyEvent = styled.div`
  margin-top: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: bold;
    font-size: 26px;
    line-height: 160%;
    color: ${Colors.gray10};
    opacity: 0.75;
  }

  p {
    font-weight: normal;
    font-size: 22px;
    line-height: 160%;
    text-align: center;
    color: ${Colors.gray20};
    margin-top: 20px;
    text-align: center;
    width: 50%;
  }
`;

export const ButtonEmptyEventMobile = styled.button`
  cursor: pointer;
  border: 0;
  width: 100%;
  height: 38px;
  background: ${Colors.primary10};
  border-radius: 8px;
  margin-top: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: ${Colors.primary100};
    margin-left: 10px;
  }
`;
