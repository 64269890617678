import { Link } from "react-router-dom";

import { BsThreeDots } from "react-icons/bs";
import { IoMdCloseCircle } from "react-icons/io";
import { MdCheckCircle } from "react-icons/md";

import { Colors } from "constants/colors";
import { EventTypeOrganizer } from "types";

import {
  BadgeMy,
  CategoryMy,
  Container,
  InativeBadgeMy,
  Box,
  DropDownEvent,
} from "./styles";

import eyes_event_icon from "assets/eyes-event-icon.svg";
import integrations_event_icon from "assets/integrations-event-icon.svg";
import share_event_icon from "assets/share-event-icon.svg";

interface ListTableProps {
  events: EventTypeOrganizer[] | undefined;
  handleOpenDropCardEvent(event: EventTypeOrganizer | null): void;
  eventSelected: EventTypeOrganizer | null;
  showModalShare(): void;
}

export function ListTable({
  events,
  handleOpenDropCardEvent,
  eventSelected,
  showModalShare,
}: ListTableProps) {
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>EVENTO E AUTOR</th>
            <th>DATA E HORÁRIO</th>
            <th>LOCAL</th>
            <th>ORGANIZADOR</th>
            <th>CATEGORIA</th>
            <th>STATUS</th>
          </tr>
        </thead>

        <tbody>
          {events?.map((event) => {
            return (
              <tr style={{ borderRadius: 20 }} key={event?.id}>
                <td>
                  <div className="row">
                    <Link to={`/my-event/${event?.nickname}`}>
                      <img src={event?.thumb} alt="" width={87} height={46.5} />
                    </Link>

                    <div className="column">
                      <Link to={`/my-event/${event?.nickname}`}>
                        <h5>{event?.name}</h5>
                      </Link>

                      <h6>{event?.creator?.name}</h6>
                    </div>
                  </div>
                </td>

                <td>
                  <div className="date">
                    <h5>{event?.formattedDate}</h5>

                    <h6>{event?.formattedHour}</h6>
                  </div>
                </td>

                <td>
                  <h3>
                    {event?.address_city}, {event?.address_state}
                  </h3>
                </td>

                <td>
                  <h3>{event?.organizer?.name}</h3>
                </td>

                <td>
                  {event.categories.map((category, index) => (
                    <CategoryMy hasUp={index > 0}>
                      <span>{category.name}</span>
                    </CategoryMy>
                  ))}
                </td>

                <td>
                  {!!event?.status ? (
                    <BadgeMy>
                      <MdCheckCircle color={Colors.white} />

                      <span>ATIVO</span>
                    </BadgeMy>
                  ) : null}

                  {!event?.status ? (
                    <InativeBadgeMy>
                      <IoMdCloseCircle color={Colors.black50} />

                      <span>INATIVO</span>
                    </InativeBadgeMy>
                  ) : null}
                </td>

                <td>
                  <div className="div-icon-three">
                    <Box
                      active={event?.isOpen}
                      onClick={() => handleOpenDropCardEvent(event)}
                    >
                      <BsThreeDots
                        style={{ cursor: "pointer" }}
                        size={22}
                        color={Colors.black100}
                      />
                    </Box>

                    {eventSelected?.id === event.id ? (
                      <DropDownEvent>
                        <ul>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/my-event/${event.nickname}`}
                          >
                            <li>
                              <div className="circle-icon">
                                <img src={eyes_event_icon} alt="eyes" />
                              </div>
                              <span>Visualizar evento</span>
                            </li>
                          </Link>

                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/${event.nickname}`}
                          >
                            <li>
                              <div className="circle-icon">
                                <img src={eyes_event_icon} alt="eyes" />
                              </div>
                              <span>Visualizar Link do Checkout</span>
                            </li>
                          </Link>

                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/my-event/${event.nickname}/integration`}
                          >
                            <li>
                              <div className="circle-icon">
                                <img src={integrations_event_icon} alt="edit" />
                              </div>
                              <span>Gerenciar integrações</span>
                            </li>
                          </Link>

                          <li className="last" onClick={showModalShare}>
                            <div className="circle-icon">
                              <img src={share_event_icon} alt="duplicate" />
                            </div>
                            <span>Compartilhar evento</span>
                          </li>
                        </ul>
                      </DropDownEvent>
                    ) : null}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
}
