import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContainerItems = styled.div`
  max-width: 1280px;
  width: 100%;

  margin-top: 130px;
  padding-left: 28px;

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 38px;
  }

  div.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 800px) {
      padding-right: 28px;
    }

    h3 {
      font-family: "Cairo";
      color: ${Colors.secondary100};
      font-weight: bold;
      font-size: 2.34rem;
      line-height: 94px;
      margin-bottom: 24px;

      @media (max-width: 800px) {
        font-weight: bold;
        font-size: 16px;
        line-height: 30px;
      }
    }

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media (min-width: 800px) {
        display: none;
      }

      div.dots {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 21px;
      }
    }
  }
`;

export const ListItems = styled.div`
  margin-top: 40px;
`;

export const Item = styled.div`
  display: flex;
  width: 209px;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  list-style: none;

  img {
    border-radius: 20px;
    position: relative;

    @media (max-width: 800px) {
      max-width: 152.34px;
      max-height: 108.62px;
    }
  }

  display: flex;
  text-align: center;

  h3 {
    color: ${Colors.secondary100};

    font-weight: bold;
    font-size: 20px;
    margin-top: 17px;

    @media (max-width: 800px) {
      font-size: 12px;
    }
  }
`;

export const CardArrowLeft = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 24px;
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;

  background-color: ${Colors.secondary10};

  @media (max-width: 800px) {
    display: none;
  }
`;

export const CardArrowRight = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  width: 32px;
  border-radius: 50%;

  background-color: ${Colors.secondary10};
  border: 0;

  @media (max-width: 800px) {
    height: 24px;
    width: 24px;
    margin-bottom: 17px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

interface DotProps {
  active: boolean;
}

export const Dot = styled.div<DotProps>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? Colors.secondary100 : Colors.secondary20};
  margin-right: 16px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const DiscountedCardStyled = styled.div`
  display: flex;
  max-width: 16.87rem;
  margin-left: 0px;

  background: ${(props) => props.color};

  flex-direction: column;

  @media (max-width: 800px) {
    margin-left: 14px;
  }

  div.texts {
    h2 {
      font-family: "Cairo";
      font-weight: 900;
      font-size: 0.93rem;
      color: ${Colors.secondary100};
      margin-top: 9px;
      margin-bottom: 0px;

      @media (max-width: 800px) {
        font-weight: bold;
        font-size: 10px;
        line-height: 19px;
      }
    }

    div.list-categories {
      display: flex;
      flex-direction: row;
      align-items: center;

      div.badge {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 74px;
        height: 26px;
        background: ${Colors.black10};
        border-radius: 14px;
        margin-top: 6px;
        padding: 8px;
        margin-right: 8px;

        span {
          font-family: "Open Sans";
          color: ${Colors.secondary100};
          font-weight: 600;
          font-size: 0.65rem;
        }

        @media (max-width: 800px) {
          display: none;
        }
      }
    }
  }

  div.prices {
    margin-top: 0.65rem;

    @media (max-width: 800px) {
      margin-top: 0px;
    }

    strong {
      font-family: "Cairo";
      font-weight: 900;
      font-size: 0.93rem;
      margin-bottom: 0px;
      color: ${Colors.secondary100};

      @media (max-width: 800px) {
        font-weight: bold;
        font-size: 10.8px;
        line-height: 20px;
      }
    }

    small {
      font-family: "Cairo";
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 30px;
      color: ${Colors.black20};
      margin-left: 12px;
      text-decoration-line: line-through;

      @media (max-width: 800px) {
        font-weight: 600;
        font-size: 8px;
        line-height: 15px;
      }
    }
  }
`;

interface BackgroundProps {
  photo: string;
}

export const Background = styled.div<BackgroundProps>`
  height: 16.87rem;
  width: 100%;

  border-radius: 0.65rem;

  background: url(${(props) => props.photo});
  background-repeat: no-repeat;

  background-position: center top;

  @media (max-width: 800px) {
    width: 120px;
    height: 129px;
    background: url(${(props) => props.photo});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }

  div.badge-discounted {
    display: flex;

    width: 4.45rem;
    height: 2.53rem;

    margin-top: 24px;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0.93rem 0.93rem 0px;
    background-color: ${Colors.primary100};
    position: absolute;

    span {
      font-family: "Poppins";
      color: ${Colors.white};
      font-weight: 600;
      font-size: 1.21rem;
      /* line-height: 39px; */
      margin-bottom: 0px;

      @media (max-width: 800px) {
        font-weight: 600;
        font-size: 9px;
        /* line-height: 13px; */
      }
    }

    @media (max-width: 800px) {
      width: 34px;
      height: 14px;
      margin-top: 14px;
      border-radius: 0px 20px 20px 0px;
    }
  }
`;

export const ContainerSlider = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
`;
