import React, { useEffect, useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { FaHome } from "react-icons/fa";

import api from "services/api";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import { Container, AllApps, ActionButton } from "./styles";

import Popup from "containers/Popup";

import logo from "assets/logo_eventx.svg";
import AppGoogle from "assets/app_google_play.svg";

const ConfirmedEmail: React.FC = (props) => {
  const { verify_token } = useParams();

  const getTicketCode = useCallback(async () => {
    try {
      await api.get(`/verify-email/${verify_token}`);
    } catch (error) {}
  }, [verify_token]);

  useEffect(() => {
    getTicketCode();
  }, [getTicketCode]);

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img src={logo} alt="" />
        <h1
          style={{
            textAlign: "center",
          }}
        >
          Conta confirmada! Você já pode acessar o app EventX ou nossa
          plataforma, fazer login e aproveitar!
        </h1>
        <p>Em breve para iOS</p>

        <AllApps>
          {/* <p>Baixe nosso app</p> */}
          <div className="img-wrapper">
            <a
              href="https://play.google.com/store/apps/details?id=com.mobile.eventx"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppGoogle} alt="" width="300" height="50" />
            </a>
            {/* <p>Em breve...</p>
            <a href='#' target='_blank' rel='noopener noreferrer'>
              <img src={AppApple} alt='' width='200' height='50' />
            </a> */}
          </div>
        </AllApps>
        <Link to="/">
          <ActionButton>
            <FaHome /> Versão web
          </ActionButton>
        </Link>
      </div>

      {visible && <Popup handleVisible={handleCookies} />}
    </Container>
  );
};

export default ConfirmedEmail;
