import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Spin } from "antd";

import ReCAPTCHA from "react-google-recaptcha";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { LoadingOutlined } from "@ant-design/icons";
import { MdCheckCircle } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { RiLock2Line, RiUserLine } from "react-icons/ri";
import { AiOutlineMail } from "react-icons/ai";
import { CgSmartphone } from "react-icons/cg";

import api from "services/api";

import { useDisclosure } from "hooks/useDisclosure";

import { Colors } from "constants/colors";

import {
  ButtonSignIn,
  FormSignUp,
  ButtonPrev,
  ButtonFinal,
} from "styles/pages/SignIn";

import {
  ContainerModal,
  ContainerPasswordRequeriments,
  ModalStyled,
  Requeriment,
  WrapperRecaptcha,
} from "./styles";

import { InputForm } from "components/Form/InputForm";
import { InputPassword } from "components/Form/InputPassword";
import { infoNotification } from "components/Notification";

import signup_success from "assets/signup-success-new.svg";

type SignUpFormData = {
  name: string;
  email: string;
  email_confirmation: string;
  cell_phone: string;
};

type SignUpFormDataFinal = {
  password: string;
  password_confirmation?: string;
};

type Error = {
  response: {
    data: {
      code: string;
    };
  };
};

const pwdValidationMessages = {
  required: "A Senha é obrigatória",
  lowerCase: "Uma letra minúscula",
  upperCase: "Uma letra maiúscula",
  number: "Números",
  special: "Caracteres especiais",
  min: "Mínimo 8 caracteres",
};

const lowerCaseRegex = new RegExp(".*[a-z].*");
const upperCaseRegex = new RegExp(".*[A-Z].*");
const numberRegex = new RegExp(".*\\d.*");
const specialCharacterRegex = new RegExp(
  ".*[`~<>?,./!@#$%^&*()\\-_+=\"'|{}\\[\\];:\\\\].*"
);

const minLength = 8;

const signUpFormSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
  cell_phone: yup.string().required("Telefone obrigatório"),
  email_confirmation: yup
    .string()
    .required("O Email de confirmação é obrigatório")
    .oneOf([null, yup.ref("email")], "Os emails precisam ser iguais"),
});

const signUpFinalFormSchema = yup.object().shape({
  password: yup
    .string()
    .matches(upperCaseRegex, pwdValidationMessages.upperCase)
    .matches(lowerCaseRegex, pwdValidationMessages.lowerCase)
    .matches(numberRegex, pwdValidationMessages.number)
    .matches(specialCharacterRegex, pwdValidationMessages.special)
    .min(minLength, pwdValidationMessages.min)
    .required(pwdValidationMessages.required),
  password_confirmation: yup
    .string()
    .required("A Senha de confirmação é obrigatória")
    .oneOf([null, yup.ref("password")], "As senhas precisam ser iguais"),
});

interface SignupProps {
  handleChangeTab(value: number): void;
}

export function Signup({ handleChangeTab }: SignupProps) {
  const [step, setStep] = useState(0);
  const [token, setToken] = useState(null);

  const { showModal, handleOk, isModalVisible } = useDisclosure();

  const [firstInformations, setFirstInformations] = useState({
    email: "",
    name: "",
    cell_phone: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
  } = useForm<SignUpFormData>({
    resolver: yupResolver(signUpFormSchema),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    trigger: trigger2,
    formState: { errors: errors2, isSubmitting: isSubmitting2 },
    reset: reset2,
  } = useForm<SignUpFormDataFinal>({
    resolver: yupResolver(signUpFinalFormSchema),
    criteriaMode: "all",
  });

  const handleSignupFirst: SubmitHandler<SignUpFormData> = async ({
    ...data
  }) => {
    try {
      setFirstInformations({ ...data });

      setStep(1);
    } catch (err) {
      const response = err as Error;

      const { code } = response.response.data;

      if (code === "E_VALIDATION") {
        setError("email", {
          type: "notMatch",
          message: "Esse email já possui cadastro",
        });

        return;
      }

      alert("Alguns dos seus dados estão invalidos");
    }
  };

  const handleSignupFinal: SubmitHandler<SignUpFormDataFinal> = async ({
    password,
  }) => {
    try {
      const { name, email, cell_phone } = firstInformations;

      if (!token) {
        return infoNotification(
          "Verifique se não é um robô.",
          "Por favor, caso não seja um robô, marque o reCAPTCHA."
        );
      }

      await api.post("/register", {
        email,
        name,
        cell_phone,
        password,
      });

      setStep(0);

      reset();
      reset2();
      showModal();
    } catch (err) {
      const response = err as Error;

      const { code } = response.response.data;

      if (code === "E_VALIDATION") {
        setError("email", {
          type: "notMatch",
          message: "Esse email já possui cadastro",
        });

        setStep(0);

        return;
      }

      alert("Alguns dos seus dados estão invalidos");
    }
  };

  const handleOkModal = () => {
    handleOk();
    handleChangeTab(0);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const watchPassword = watch2("password");

  useEffect(() => {
    trigger2("password");
  }, [trigger2, watchPassword]);

  const onVerifyPassword = (message: string) => {
    if (Array.isArray(errors2.password?.types?.matches)) {
      return errors2.password?.types?.matches.includes(message);
    }

    return message === errors2.password?.types?.matches;
  };

  return (
    <>
      {step === 0 ? (
        <FormSignUp onSubmit={handleSubmit(handleSignupFirst)}>
          <InputForm
            isFull
            icon={<RiUserLine size={23} color={Colors.secondary40} />}
            type="text"
            label="Nome completo"
            {...register("name")}
            error={errors.name}
          />

          <InputForm
            isFull
            icon={<AiOutlineMail size={23} color={Colors.secondary40} />}
            type="text"
            label="E-mail"
            {...register("email")}
            error={errors.email}
          />

          <InputForm
            isFull
            icon={<AiOutlineMail size={23} color={Colors.secondary40} />}
            type="text"
            label="Repita seu e-mail"
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            {...register("email_confirmation")}
            error={errors.email_confirmation}
          />

          <InputForm
            isFull
            icon={<CgSmartphone size={23} color={Colors.secondary40} />}
            type="text"
            label={"Telefone"}
            {...register("cell_phone")}
            error={errors.cell_phone}
            mask="phone"
          />

          <div className="cadaster">
            <span>Ao se cadastrar, você concorda com nossos </span>

            <Link to="/term">
              <p>Termos de Uso</p>
            </Link>
          </div>

          <div className="button-signIn">
            <ButtonSignIn isLoading={isSubmitting} type="submit">
              <Spin spinning={isSubmitting} indicator={antIcon} />

              <span>Prosseguir</span>
            </ButtonSignIn>
          </div>

          <Link to="/signin?page=signin">
            <div className="new">
              <span>Já possui uma conta?</span>

              <p> Entrar</p>
            </div>
          </Link>
        </FormSignUp>
      ) : null}

      {step === 1 ? (
        <FormSignUp onSubmit={handleSubmit2(handleSignupFinal)}>
          <ContainerPasswordRequeriments>
            <h3>Senha de acesso</h3>

            <p>Sua senha de acesso deve possuir:</p>

            <div className="requeriments">
              <Requeriment>
                <MdCheckCircle
                  size={15}
                  color={
                    !onVerifyPassword(pwdValidationMessages.number)
                      ? Colors.green10
                      : Colors.black10
                  }
                />
                <h4>Números</h4>
              </Requeriment>

              <Requeriment>
                <MdCheckCircle
                  size={15}
                  color={
                    !!errors2.password?.types?.min
                      ? Colors.black10
                      : Colors.green10
                  }
                />
                <h4>Mínimo 8 caracteres</h4>
              </Requeriment>

              <Requeriment>
                <MdCheckCircle
                  size={15}
                  color={
                    !onVerifyPassword(pwdValidationMessages.special)
                      ? Colors.green10
                      : Colors.black10
                  }
                />
                <h4>Caracteres especiais</h4>
              </Requeriment>

              <Requeriment>
                <MdCheckCircle
                  size={15}
                  color={
                    !onVerifyPassword(pwdValidationMessages.upperCase) &&
                    !onVerifyPassword(pwdValidationMessages.lowerCase)
                      ? Colors.green10
                      : Colors.black10
                  }
                />
                <h4>Letras maiúsculas e minúsculas</h4>
              </Requeriment>
            </div>
          </ContainerPasswordRequeriments>

          <InputPassword
            isFull
            id="message"
            autoComplete="new-password"
            label="Senha"
            icon={<RiLock2Line size={23} color={Colors.secondary40} />}
            {...register2("password")}
            error={errors2.password}
            showMessagePassword={false}
          />

          <InputPassword
            isFull
            id="message1"
            autoComplete="new-password"
            label="Repita sua senha"
            icon={<RiLock2Line size={23} color={Colors.secondary40} />}
            {...register2("password_confirmation")}
            error={errors2.password_confirmation}
          />

          <WrapperRecaptcha>
            <ReCAPTCHA
              sitekey="6LdsNDgcAAAAAKaOglZNbGUUzyNEGW5Uwe4nrzU4"
              onChange={(token: any) => setToken(token)}
              onExpired={() => setToken(null)}
              theme="light"
              size="normal"
              hl="pt-BR"
            />
          </WrapperRecaptcha>

          <div className="cadaster">
            <span>Ao se cadastrar, você concorda com nossos </span>

            <Link to="/term">
              <p>Termos de Uso</p>
            </Link>
          </div>

          <div className="button-signUp">
            <ButtonPrev type="button" onClick={() => setStep(0)}>
              <span>Voltar</span>
            </ButtonPrev>

            <ButtonFinal isLoading={isSubmitting2} type="submit">
              <Spin spinning={isSubmitting2} indicator={antIcon} />

              <span>Cadastrar</span>
            </ButtonFinal>
          </div>

          <Link to="/signin?page=signin">
            <div className="new">
              <span>Já possui uma conta?</span>

              <p> Entrar</p>
            </div>
          </Link>
        </FormSignUp>
      ) : null}

      <ModalStyled
        width={857}
        visible={isModalVisible}
        onOk={handleOkModal}
        style={{ borderRadius: "20px" }}
        bodyStyle={{ paddingTop: 60 }}
        onCancel={handleOkModal}
        closeIcon={
          <RiCloseCircleLine
            style={{ marginTop: "14px", marginRight: "34px" }}
            size={43}
            color={Colors.black40}
          />
        }
        footer={null}
      >
        <ContainerModal>
          <div className="logo-qr-code">
            <img src={signup_success} width={275} height={253} alt="signup" />
          </div>

          <div className="content">
            <h2>Cadastro realizado!</h2>

            <p>Uma confirmação foi enviada para seu e-mail.</p>
          </div>

          <div className="ok-button">
            <button onClick={handleOkModal}>Ok</button>
          </div>
        </ContainerModal>
      </ModalStyled>
    </>
  );
}
