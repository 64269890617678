import React from "react";

import { Container, Section } from "./styles";

const FirstSection: React.FC = () => {
  return (
    <Container className="first-container">
      <Section>
        <div className="content">
          <div className="heading">
            <h2>Central de ajuda</h2>
            <h4>
              Nos envie uma solicitação explicando o problema encontrado e
              ficaremos felizes em ajudá-lo (a)!
            </h4>
          </div>
        </div>
      </Section>
    </Container>
  );
};

export default FirstSection;
