import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 19.81rem;
  width: 100%;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ContainerItems = styled.div`
  max-width: 17.81rem;
  width: 100%;
`;

export const ProfileCard = styled.div`
  max-width: 17.81rem;
  min-height: 6.56rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0.7rem 1.92rem 0.7rem 1.4rem;

  border: 1px solid ${Colors.black10};
  box-shadow: 0px 2px 6px rgba(18, 25, 34, 0.05);
  border-radius: 0.65rem;
`;

interface CardProps {
  active?: boolean;
}

export const Card = styled.div<CardProps>`
  cursor: pointer;
  max-width: 17.81rem;
  height: 4.73rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding: 0px 44px 0px 32px; */
  /* margin-top: 18px; */
  margin-top: 0.84rem;

  padding: 0px 5px 0px 1.4rem;

  border: 1px solid
    ${(props) => (props.active ? Colors.primary40 : Colors.black10)};

  box-shadow: 0px 2px 6px rgba(18, 25, 34, 0.05);
  border-radius: 0.65rem;

  svg.icon {
    color: ${(props) =>
      props.active ? Colors.primary100 : Colors.secondary40};
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    h2 {
      font-family: "Poppins";
      color: ${Colors.black100};
      font-weight: normal;
      font-size: 1.03rem;
      /* line-height: 160%; */
      /* margin-top: 7px; */
      margin-bottom: 0px;
      margin-left: 14px;
    }

    div.background {
      display: none;
    }

    div.normal {
      width: 2.1rem;
      height: 2.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div.active {
    div.background {
      width: 2.1rem;
      height: 2.1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: ${Colors.primary10};
      border-radius: 0.56rem;
    }

    div.normal {
      display: none;
    }

    h2 {
      font-family: "Poppins";
      color: ${Colors.primary100};
      font-weight: bold;
      font-size: 1.03rem;
      /* margin-top: 7px; */
      margin-bottom: 0px;
      margin-left: 14px;
    }
  }
`;

export const Avatar = styled.div`
  /* width: 96px;
  height: 96px; */

  width: 4.5rem;
  height: 4.5rem;

  position: relative;
  background: ${Colors.white};

  img {
    /* width: 96px;
    height: 96px; */

    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
  }

  .badge {
    cursor: pointer;
    border: 0;
    outline: 0;
    position: absolute;

    /* width: 34px;
    height: 34px; */

    width: 1.59rem;
    height: 1.59rem;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    right: 0;
    bottom: 0;

    background: ${Colors.white};

    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    font-size: 12px;

    color: ${Colors.primary100};
  }

  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.84rem;

  h2 {
    font-family: "Cairo";
    color: ${Colors.secondary100};
    font-weight: bold;
    font-size: 1.03rem;
    margin-bottom: 0.46rem;
  }

  span {
    font-family: "Poppins";
    color: ${Colors.black50};
    font-weight: normal;
    font-size: 0.75rem;
    margin-bottom: 6px;
  }
`;
