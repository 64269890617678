/* eslint-disable array-callback-return */
import { useCallback, useState } from "react";

import {
  Link,
  useSearchParams,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";

import { Helmet } from "react-helmet";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Button, Divider, Dropdown, Input, notification } from "antd";
import { Drawer } from "antd";

import {
  RiCalendar2Line,
  RiCloseCircleLine,
  RiHome4Line,
} from "react-icons/ri";

import { AiOutlineUser } from "react-icons/ai";
import { MdAdd, MdRemove } from "react-icons/md";
import { RiTicket2Line, RiShoppingCart2Fill } from "react-icons/ri";

import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";
import { GoChevronLeft } from "react-icons/go";

import { VscChevronDown, VscChevronUp } from "react-icons/vsc";
import { BiSearch } from "react-icons/bi";
import { FiMapPin } from "react-icons/fi";

import { useAuth } from "contexts/AuthContext";
import { useSearchCityAndState } from "contexts/SearchCityAndState";

import { Colors } from "constants/colors";
import Constants from "constants/Datas";
import { useDisclosure } from "hooks/useDisclosure";

import {
  Container,
  ContainerItems,
  DropDown,
  SearchItem,
  Localization,
  MainCardBorder,
  NormalCardBorder,
  Sidebar,
  AccountDropDown,
  AccountDropDownOrganizer,
  ButtonOrganizer,
  ButtonAccount,
  ButtonLoginIn,
  ButtonAccountCadaster,
  DropdownButton,
  DropdownMenu,
  TicketContainer,
  ButtonsContainer,
  ActionButtonGroup,
  ContainerModal,
  ModalStyled,
} from "./styles";

import { Badge } from "components/Badge";
import {
  limitNotification,
  successNotification,
} from "components/Notification";
import { ModalCity } from "../ModalCity";

import selo_google_play from "assets/selo-google-play.jpg";
import selo_app_store from "assets/selo-app-store.jpg";

import logo from "assets/logo.svg";
import left_icon_mobile from "assets/left-icon-mobile.svg";
import logo_mobile from "assets/logo-mobile.svg";
import search_icon_mobile from "assets/search-icon-mobile.svg";
import exit_icon_mobile from "assets/exit-icon-mobile.svg";

interface MetaTags {
  title?: string;
  description?: string;
  keywords?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogType?: string;
  ogUrl?: string;
  noIndex?: boolean;
}

interface HeaderProps {
  isProfile?: boolean;
  isSignin?: boolean;
  cart?: any;
  changeCart?: any;
  quantityTotal?: number;
  valueTotal?: number;
  openLogin?: any;
  openSteps?: any;
  rate?: number;
  customBack?(): void;
  metaTags?: MetaTags;
}

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const searchFormSchema = yup.object().shape({
  title: yup.string(),
});

export function Header({
  isProfile,
  cart,
  changeCart,
  quantityTotal,
  valueTotal,
  openLogin,
  openSteps,
  rate,
  isSignin = false,
  customBack,
  metaTags,
}: HeaderProps) {
  const { user, signOut, hasOrganizer } = useAuth();

  const { showModalSearch } = useSearchCityAndState();

  const navigate = useNavigate();

  const location = useLocation();

  const [activeDrop, setActiveDrop] = useState(false);
  const [activeDropOrganizer, setActiveDropOrganizer] = useState(false);

  const [activeDropAccount, setActiveDropAccount] = useState(false);
  const [visible, setVisible] = useState(false);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [searchParams] = useSearchParams();

  const { handleCancel, handleOk, isModalVisible, showModal } = useDisclosure();

  const address_city = searchParams.get("address_city");

  const address_state = searchParams.get("address_state");

  type SearchFormData = {
    title: string;
  };

  const { register, handleSubmit, setFocus } = useForm<SearchFormData>({
    resolver: yupResolver(searchFormSchema),
  });

  const onOpenDrawer = useCallback(() => {
    setVisible(true);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setVisible(false);
  }, []);

  const searchOrRouter: SubmitHandler<SearchFormData> = ({ title }) => {
    if (title === "") {
      alert("Digite o nome que deseja buscar");

      setFocus("title");
      return;
    }

    if (location.pathname === "/search") {
      navigate({
        pathname: "/search",
        search: `?${createSearchParams({
          name: title,
        })}`,
      });
    }

    navigate({
      pathname: "/search",
      search: `?${createSearchParams({
        name: title,
      })}`,
    });
  };

  const RowTickets = ({ item, rate }: any) => {
    function countMore() {
      if (item.user_quantity_max === item.quantity) {
        limitNotification(
          "Você atingiu a quantidade limite!",
          `O ingresso selecionado está limitado a quantidade de ${item.user_quantity_max} por venda, não é possível adicionar mais.`,
          4.5
        );

        return;
      }

      changeCart(item.ticket_id, item.quantity + 1);
      notification.destroy();
      successNotification(
        "Item Adicionado",
        `O item ${item?.name} foi adicionado ao carrinho!`,
        1
      );
    }
    function countLess() {
      changeCart(item.ticket_id, item.quantity > 0 ? item.quantity - 1 : 0);
    }
    return (
      <TicketContainer key={"h_t_" + item.ticket_id}>
        <div className="icon-container">
          <RiTicket2Line />
          <div className="name-and-price">
            <p>{item?.name}</p>
            <p>
              {formatter.format(
                item.discount !== 0 ? item.price - item.discount : item.price
              )}
              {!item.absorb_rate ? (
                <span style={{ color: "#999", fontSize: 12 }}>
                  {" "}
                  + taxa{" "}
                  {formatter.format(
                    ((item.discount !== 0
                      ? item.price - item.discount
                      : item.price) /
                      100) *
                      rate
                  )}
                </span>
              ) : null}
            </p>
          </div>
        </div>
        <ActionButtonGroup>
          <Input
            style={{ maxWidth: "50px", marginRight: 10 }}
            readOnly
            value={item.quantity + "x"}
          />
          <Button
            className="remove"
            onClick={countLess}
            icon={<MdRemove size={16} />}
          />
          <Button
            className="add"
            onClick={countMore}
            icon={<MdAdd size={16} />}
          />
        </ActionButtonGroup>
      </TicketContainer>
    );
  };

  const checkNextAction = () => {
    setIsDropdownVisible(false);

    let qty: number = quantityTotal || 0;

    if (qty < 1) {
      alert("Seu carrinho está vazio!");
    } else {
      if (user) {
        openSteps();
      } else {
        openLogin();
      }
    }
  };

  const menu = (
    <DropdownMenu>
      <p className="your-order">Seu pedido</p>
      {cart?.map((item: any, index: number) => {
        if (item.quantity > 0)
          return (
            <>
              <RowTickets item={item} rate={rate} />
              <Divider style={{ margin: "8px 0" }} />
            </>
          );
      })}
      <div className="subtotal">
        <p className="label">Subtotal</p>
        <p className="value">{formatter.format(valueTotal || 0)}</p>
      </div>
      <Button className="confirm-button" onClick={() => checkNextAction()}>
        Confirmar compra
      </Button>
    </DropdownMenu>
  );

  const handleEnabledLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      // api += `lat=${position.coords.latitude}&lon=${position.coords.longitude}`;
      // this.fetchWeather(api);
    });
  };

  const renderDistrict = (district: string) => {
    const findState = Constants.States.find(
      (value) => String(value.value) === district
    );

    if (findState) {
      return findState.label;
    }

    return district;
  };

  const handleChangeDropOrganizer = useCallback(() => {
    setActiveDropOrganizer(!activeDropOrganizer);
  }, [activeDropOrganizer]);

  const handleChangeDropAccount = useCallback(() => {
    setActiveDropAccount(!activeDropAccount);
  }, [activeDropAccount]);

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        {metaTags?.title && <title>{metaTags.title}</title>}

        {metaTags?.description && (
          <meta
            name="description"
            content={metaTags.description}
            data-rh="true"
          />
        )}

        {metaTags?.keywords && (
          <meta name="keywords" content={metaTags.keywords} data-rh="true" />
        )}

        {metaTags?.ogTitle && (
          <meta property="og:title" content={metaTags.ogTitle} />
        )}

        {metaTags?.ogDescription && (
          <meta property="og:description" content={metaTags.ogDescription} />
        )}

        {metaTags?.ogImage && (
          <meta
            property="og:image"
            itemProp="image"
            content={metaTags.ogImage}
          />
        )}

        {metaTags?.ogType && (
          <meta property="og:type" content={metaTags.ogType} />
        )}

        {metaTags?.ogUrl && <meta property="og:url" content={metaTags.ogUrl} />}

        {metaTags?.noIndex && <meta name="robots" content="noindex,nofollow" />}
      </Helmet>

      <ContainerItems>
        <div className="content">
          <Link to="/">
            <div className="logo-main">
              <img src={logo} alt="logo" width={182.14} height={126.06} />
            </div>
          </Link>

          <form onSubmit={handleSubmit(searchOrRouter)}>
            <div className="dropdown" onClick={showModalSearch}>
              <FiMapPin size={14} color={Colors.primary100} />
              <div className="text">
                {!address_city && !address_state ? (
                  <h3>Qualquer lugar</h3>
                ) : null}

                {!!address_city ? <h3>{address_city}</h3> : null}

                {!!address_state ? (
                  <h3>{renderDistrict(address_state)}</h3>
                ) : null}
              </div>
              <div className="icon">
                <VscChevronDown
                  className="down"
                  size={16}
                  color={Colors.primary100}
                />
              </div>
            </div>
            <div className="searchInput">
              <input
                placeholder="Pesquise eventos, locais, serviços..... web"
                {...register("title")}
              />
            </div>

            <button className="search" type="submit">
              <BiSearch size={18} color={Colors.primary100} />
            </button>
          </form>

          <div className="buttons">
            {!user && !cart && !isSignin ? (
              <Link to="/organize-seu-evento">Organize seu Evento</Link>
            ) : null}

            {!!user && !hasOrganizer && !isSignin ? (
              <Link to="/organize-seu-evento">Organize seu Evento</Link>
            ) : null}

            {isSignin ? <Link to="/faq">Precisa de Ajuda</Link> : null}

            {!!user && hasOrganizer ? (
              <div className="account-container">
                <ButtonOrganizer
                  active={activeDropOrganizer}
                  onClick={handleChangeDropOrganizer}
                >
                  <RiCalendar2Line size={20} color={Colors.primary100} />
                  Organizador
                  {activeDropOrganizer ? (
                    <IoChevronUpSharp size={20} />
                  ) : (
                    <IoChevronDownSharp size={20} />
                  )}
                </ButtonOrganizer>

                {activeDropOrganizer ? (
                  <AccountDropDownOrganizer>
                    <ul>
                      <Link to="/my-event">
                        <li>
                          <span>Meus Eventos</span>
                        </li>
                      </Link>
                      <li className="last" onClick={showModal}>
                        <span>Bilheteria</span>
                      </li>
                    </ul>
                  </AccountDropDownOrganizer>
                ) : null}
              </div>
            ) : null}

            {cart ? (
              <ButtonsContainer>
                <Dropdown
                  visible={isDropdownVisible}
                  overlay={menu}
                  placement="bottomRight"
                >
                  <Badge
                    count={quantityTotal}
                    offset={[0, 12]}
                    overflowCount={9}
                  >
                    <DropdownButton
                      onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                    >
                      <RiShoppingCart2Fill />
                    </DropdownButton>
                  </Badge>
                </Dropdown>
              </ButtonsContainer>
            ) : null}

            {!user && !isSignin ? (
              <Link to="/signin?page=signin">
                <ButtonLoginIn>
                  <span>Entrar</span>
                </ButtonLoginIn>
              </Link>
            ) : null}

            {!user && !isSignin ? (
              <Link to="/signin?page=signup">
                <ButtonAccountCadaster>
                  <AiOutlineUser size={20} color={Colors.white} />
                  <span> Cadastre-se</span>
                </ButtonAccountCadaster>
              </Link>
            ) : null}

            {!!user ? (
              <div className="account-container">
                <ButtonAccount
                  active={activeDropAccount}
                  onClick={handleChangeDropAccount}
                >
                  <AiOutlineUser size={20} />
                  Minha conta
                  {activeDropAccount ? (
                    <VscChevronUp size={20} />
                  ) : (
                    <VscChevronDown size={20} />
                  )}
                </ButtonAccount>

                {activeDropAccount ? (
                  <AccountDropDown userLogged={!!user}>
                    <ul>
                      <Link to="/profile/personal-data">
                        <li>
                          <span>Meu Perfil</span>
                        </li>
                      </Link>

                      <Link to="/profile/my-shopping">
                        <li>
                          <span>Compras</span>
                        </li>
                      </Link>

                      <Link to="/profile/my-ticket">
                        <li>
                          <span>Ingressos</span>
                        </li>
                      </Link>

                      <Link to="/faq">
                        <li>
                          <span>Suporte</span>
                        </li>
                      </Link>

                      <li onClick={signOut} className="exit">
                        <span>Sair</span>
                      </li>
                    </ul>
                  </AccountDropDown>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>

        <div className="mobile">
          {!isProfile && !visible ? (
            <img
              src={left_icon_mobile}
              onClick={onOpenDrawer}
              alt="left-icon"
              width="20px"
              height="31px"
            />
          ) : null}

          {!isProfile && visible ? (
            <img
              src={exit_icon_mobile}
              onClick={onCloseDrawer}
              width="23px"
              height="31px"
              alt=""
            />
          ) : null}

          {isProfile ? (
            <div className="icons">
              {customBack === undefined ? (
                <GoChevronLeft
                  className="left"
                  size={22}
                  color={Colors.secondary100}
                  onClick={() => navigate(-1)}
                />
              ) : null}

              {customBack ? (
                <GoChevronLeft
                  className="left"
                  size={22}
                  color={Colors.secondary100}
                  onClick={customBack}
                />
              ) : null}

              <Link to="/">
                <RiHome4Line
                  className="home"
                  size={22}
                  color={Colors.secondary100}
                />
              </Link>
            </div>
          ) : null}

          <Link to="/">
            <img
              src={logo_mobile}
              alt="logo-mobile"
              width="34px"
              height="34px"
            />
          </Link>

          {activeDrop ? (
            <img
              src={exit_icon_mobile}
              onClick={() => setActiveDrop(!activeDrop)}
              width="23px"
              height="31px"
              alt=""
            />
          ) : (
            <img
              onClick={() => setActiveDrop(!activeDrop)}
              src={search_icon_mobile}
              width="23px"
              height="31px"
              alt="search-icon-mobile"
            />
          )}
        </div>
      </ContainerItems>

      <Drawer
        title="Basic Drawer"
        width={200}
        placement="left"
        onClose={onCloseDrawer}
        visible={visible && !user}
        style={{ zIndex: 96, padding: 0 }}
        bodyStyle={{ zIndex: 96, padding: 0, background: Colors.white }}
      >
        <Sidebar>
          <div className="main">
            <div className="links">
              <div>
                <Link to="/">
                  <h2>Eventos</h2>
                </Link>

                <Link
                  style={{ textDecoration: "none" }}
                  to="/organize-seu-evento"
                >
                  <h2>Organize seu evento</h2>
                </Link>

                <Link to="/faq">
                  <h2>Suporte</h2>
                </Link>
              </div>
            </div>
            <div className="login">
              <Link to="/signin">
                <button className="cart">Login</button>
              </Link>
              <Link to="/signin">
                <button className="account">
                  <AiOutlineUser size={14} />
                  Cadastre-se
                </button>
              </Link>
            </div>
          </div>
        </Sidebar>
      </Drawer>

      <Drawer
        title="Basic Drawer"
        width={200}
        placement="left"
        onClose={onCloseDrawer}
        visible={visible && !!user}
        style={{ zIndex: 96, padding: 0 }}
        bodyStyle={{ zIndex: 96, padding: 0, background: Colors.white }}
      >
        <Sidebar>
          <div className="main">
            <div className="links">
              <div>
                <Link to="/">
                  <h2>Eventos</h2>
                </Link>

                <Link to="/profile">
                  <h2>Perfil</h2>
                </Link>

                <Link to="/profile/my-shopping">
                  <h2>Minhas compras</h2>
                </Link>

                <Link to="/profile/my-ticket">
                  <h2>Meus ingressos</h2>
                </Link>

                <h2>O melhor da cidade</h2>

                <h2>Serviços oferecidos</h2>

                <Link
                  style={{ textDecoration: "none" }}
                  to="/organize-seu-evento"
                >
                  <h2>Organize seu evento</h2>
                </Link>

                <Link to="/faq">
                  <h2>Suporte</h2>
                </Link>
              </div>
            </div>
          </div>
        </Sidebar>
      </Drawer>

      {activeDrop ? (
        <DropDown>
          {true ? (
            <SearchItem>
              <form>
                <div className="dropdown" onClick={showModalSearch}>
                  <div>
                    <FiMapPin size={14} color={Colors.primary100} />
                    <h3>Qualquer lugar</h3>
                  </div>
                  <VscChevronDown size={16} color={Colors.primary100} />
                </div>
              </form>

              <form onSubmit={handleSubmit(searchOrRouter)}>
                <div className="searchInput">
                  <input
                    {...register("title")}
                    placeholder="Pesquise eventos, locais, organizadores..."
                  />
                </div>

                <button className="search" type="submit">
                  <BiSearch size={20} color={Colors.primary100} />
                </button>
              </form>
            </SearchItem>
          ) : null}

          {false ? (
            <Localization>
              <h2>Localização</h2>

              <input type="text" placeholder="Insira o local desejado..." />

              <MainCardBorder onClick={handleEnabledLocation}>
                <div className="icon">
                  <FiMapPin size={16} color={Colors.primary100} />
                </div>
                <div className="content">
                  <strong>Usar minha localização atual</strong>

                  <span>Encontre eventos acontecendo perto de você!</span>
                </div>
              </MainCardBorder>
              {[0, 1, 2, 3, 4, 5, 6, 7].map((t) => (
                <NormalCardBorder key={t}>
                  <FiMapPin size={16} color={Colors.primary100} />
                  <h3 className="normal">São Paulo</h3>
                </NormalCardBorder>
              ))}
            </Localization>
          ) : null}
        </DropDown>
      ) : null}

      <ModalCity />

      <ModalStyled
        width={857}
        visible={isModalVisible}
        onOk={handleOk}
        style={{ borderRadius: "20px" }}
        onCancel={handleCancel}
        closeIcon={
          <RiCloseCircleLine
            style={{ marginTop: "14px", marginRight: "34px" }}
            size={43}
            color={Colors.black40}
          />
        }
        footer={null}
      >
        <ContainerModal>
          <div className="logo-qr-code"></div>

          <div className="content">
            <h2>App EventX</h2>

            <p>
              Escaneie o QR Code para fazer o download do nosso app direto em
              seu celular e <strong>editar seu evento.</strong>
            </p>
          </div>

          <div className="or">
            <div className="line"></div>
            <span>Ou</span>
            <div className="line"></div>
          </div>

          <div className="download-app">
            <h5>Faça o download clicando em umas das opções abaixo</h5>

            <div className="row">
              <img src={selo_google_play} alt="Logo" width={223} height={78} />

              <img src={selo_app_store} alt="Logo" width={223} height={78} />
            </div>
          </div>
        </ContainerModal>
      </ModalStyled>
    </Container>
  );
}
