import { useEffect, useState } from "react";

import { Tabs, Select } from "antd";

import {
  RiCalendarCheckFill,
  RiCalendarEventFill,
  RiMailSendLine,
} from "react-icons/ri";

import { MdEmail } from "react-icons/md";

import { Colors } from "constants/colors";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  Background,
  Heading,
  Content,
  ContainerTabs,
  ContainerTabsMobile,
  HeaderSearching,
  InformationsOfOrganizer,
  ContainerHeading,
  ContainerAllEvents,
  ContainerButtons,
  Button,
  HeaderEventBar,
  ContentMobile,
  ContainerAllEventsMobile,
  AboutMobile,
  ContainerListMobile,
  DivSelect,
  ContainerAboutMobile,
  ContactMobile,
} from "./styles";

import Popup from "containers/Popup";

import { ListEvent } from "./ListEvent";
import { ListEventMobile } from "./ListEventMobile";

import OrganizadorBg from "assets/capa-organizer.png";

const { Option } = Select;
const { TabPane } = Tabs;

interface Props {
  data: any;
}

const OrganizerDetail: React.FC<Props> = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [valueSelected, setValueSelected] = useState({ value: 0 });

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  function handleChange(value: number) {
    setValueSelected({ value });
  }

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  return (
    <Container>
      <Background style={{ backgroundImage: OrganizadorBg }}>
        <ContainerHeading>
          <div className="content-header">
            {data.thumb ? (
              <Heading>
                <img
                  className="organizer"
                  src={data.thumb}
                  alt="Logo"
                  width="140px"
                  height="140px"
                />
              </Heading>
            ) : null}
            <Content>
              <h2>{data.name}</h2>

              <ContainerAllEvents>
                <div className="events-d">
                  <div className="badge">
                    <RiCalendarEventFill size={22} color={Colors.secondary40} />
                  </div>
                  <h3>{`${data.events_pending.length} eventos disponíveis`}</h3>
                </div>
                <div className="events-r">
                  <div className="badge">
                    <RiCalendarCheckFill size={22} color={Colors.secondary40} />
                  </div>
                  <h3>{`${data.events_old.length} eventos realizados`}</h3>
                </div>
              </ContainerAllEvents>
            </Content>
          </div>

          <ContainerButtons>
            {data.agent_email ? (
              <a href={"mailto: " + data.agent_email}>
                <Button>
                  <MdEmail size={22} color={Colors.primary100} />
                  <span>E-mail</span>
                </Button>
              </a>
            ) : null}
          </ContainerButtons>
        </ContainerHeading>
      </Background>

      <ContainerItems>
        <ContentMobile>
          <h2>{data.name}</h2>

          <ContainerAllEventsMobile>
            <div className="events-d">
              <div>
                <RiCalendarEventFill size={14} color={Colors.secondary40} />
                <h3>{`${data.events_pending.length}`}</h3>
              </div>
              <h4>{`eventos disponíveis`}</h4>
            </div>
            <div className="events-r">
              <div>
                <RiCalendarCheckFill size={14} color={Colors.secondary40} />
                <h3>{`${data.events_old.length}`}</h3>
              </div>
              <h4>{`eventos realizados`}</h4>
            </div>
          </ContainerAllEventsMobile>
        </ContentMobile>

        <ContainerTabs>
          <Tabs>
            <TabPane tab="Eventos do organizador" key="1">
              <HeaderSearching>
                <HeaderEventBar>
                  <div className="buttons">
                    <button
                      onClick={() => setActiveTab(0)}
                      className={activeTab === 0 ? "first active" : "first"}
                    >
                      Eventos disponíveis
                    </button>
                    <button
                      onClick={() => setActiveTab(1)}
                      className={activeTab === 1 ? "active" : ""}
                    >
                      Eventos realizados
                    </button>
                  </div>
                </HeaderEventBar>
              </HeaderSearching>

              {activeTab === 0 ? (
                <ListEvent events={data.events_pending} />
              ) : null}

              {activeTab === 1 ? <ListEvent events={data.events_old} /> : null}
            </TabPane>

            <TabPane tab="Sobre o organizador" key="2">
              <InformationsOfOrganizer>
                <h2>Sobre o organizador</h2>

                <p>{data.description}</p>
              </InformationsOfOrganizer>
            </TabPane>
          </Tabs>
        </ContainerTabs>

        <ContainerTabsMobile>
          <Tabs tabBarStyle={{ borderColor: "#111", paddingLeft: "28px" }}>
            <TabPane className="foo" tab="EVENTOS" key="1">
              <DivSelect>
                <Select
                  style={{ width: "100%", borderRadius: "5px" }}
                  value={valueSelected.value}
                  onChange={handleChange}
                >
                  {[
                    { option: "Eventos disponíveis", value: 0 },
                    { option: "Eventos realizados", value: 1 },
                  ].map((op) => (
                    <Option key={op.value} value={op.value}>
                      {op.option}
                    </Option>
                  ))}
                </Select>
              </DivSelect>

              <ContainerListMobile>
                {valueSelected.value === 0 ? (
                  <h2 className="header">
                    {data.events_pending.length} evento
                    {data.events_pending.length > 1 ? "s" : null} disponíve
                    {data.events_pending.length > 1 ? "is" : "l"}{" "}
                  </h2>
                ) : null}

                {valueSelected.value === 0 ? (
                  <ListEventMobile events={data?.events_pending} />
                ) : null}

                {valueSelected.value === 1 ? (
                  <ListEventMobile events={data.events_old} />
                ) : null}
              </ContainerListMobile>
            </TabPane>

            <TabPane className="foo" tab="SOBRE" key="2">
              <ContainerAboutMobile>
                <AboutMobile>
                  <h2> Sobre o organizador</h2>
                  <p>{data.description}</p>
                </AboutMobile>

                <ContactMobile>
                  <h2>entre em contato conosco</h2>
                  <div className="cards">
                    {data.agent_email ? (
                      <a href={"mailto: " + data.agent_email}>
                        <div className="card">
                          <RiMailSendLine size={18} color={Colors.primary100} />
                          <h3>E-mail</h3>
                        </div>
                      </a>
                    ) : null}
                  </div>
                </ContactMobile>
              </ContainerAboutMobile>
            </TabPane>
          </Tabs>
        </ContainerTabsMobile>
      </ContainerItems>

      {visible && <Popup handleVisible={handleCookies} />}
    </Container>
  );
};

export default OrganizerDetail;
