import { Link } from "react-router-dom";

import { RiCloseCircleLine } from "react-icons/ri";
import QRCode from "react-qr-code";

import { useDisclosure } from "hooks/useDisclosure";

import { Colors } from "constants/colors";

import {
  Banner,
  Container,
  ContainerItems,
  ContainerModal,
  ModalStyled,
} from "./styles";

import smarthphones from "assets/smarthphones.svg";
import event_available from "assets/event_available.svg";
import selo_google_play from "assets/selo-google-play.jpg";
import selo_app_store from "assets/selo-app-store.jpg";

export function BannerOrganizerYourEvent() {
  const { handleCancel, handleOk, isModalVisible, showModal } = useDisclosure();

  return (
    <Container>
      <ContainerItems>
        <Banner>
          <div className="content">
            <h3>Organize e venda seu evento com as menores taxas!</h3>

            <h4>
              O parceiro EventX tem a melhor taxa do mercado para alavancar os
              seus resultados.
            </h4>

            <div className="buttons">
              <button className="first" onClick={showModal}>
                <img src={event_available} alt="" />
                <span>Organize seu evento agora!</span>
              </button>

              <Link to="/organize-seu-evento">
                <button className="second">
                  <span>Conheça mais sobre a eventx</span>
                </button>
              </Link>
            </div>
          </div>

          <img src={smarthphones} className="smarth" alt="" />
        </Banner>
      </ContainerItems>

      <ModalStyled
        width={857}
        visible={isModalVisible}
        onOk={handleOk}
        style={{ borderRadius: "20px" }}
        onCancel={handleCancel}
        closeIcon={
          <RiCloseCircleLine
            style={{ marginTop: "14px", marginRight: "34px" }}
            size={43}
            color={Colors.black40}
          />
        }
        footer={null}
      >
        <ContainerModal>
          <div className="logo-qr-code">
            <QRCode size={190} value="https://eventx.com.br/download" />
            {/* <img src={qr_code} width={189} height={189} alt="" /> */}
          </div>

          <div className="content">
            <h2>App EventX</h2>

            <p>
              Escaneie o QR Code para fazer o download do nosso app direto em
              seu celular
            </p>
          </div>

          <div className="or">
            <div className="line"></div>
            <span>Ou</span>
            <div className="line"></div>
          </div>

          <div className="download-app">
            <h5>Faça o download clicando em umas das opções abaixo</h5>

            <div className="row">
              <a
                href="https://play.google.com/store/apps/details?id=com.mobile.eventx"
                aria-label="Baixe agora para Google Play!"
                target="_blank"
                rel="noreferrer"
              >
                <img src={selo_google_play} width={223} height={78} alt="" />
              </a>

              <a
                href="https://apps.apple.com/us/app/eventx-eventos-e-ingressos/id1584406908"
                aria-label="Baixe agora para Apple Store!"
                target="_blank"
                rel="noreferrer"
              >
                <img src={selo_app_store} width={223} height={78} alt="" />
              </a>
            </div>
          </div>
        </ContainerModal>
      </ModalStyled>
    </Container>
  );
}
