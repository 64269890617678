import styled from "styled-components";
import {
  Container as DefaultContainer,
  Section as DefaultSection,
} from "../../EventDetail/styles";
import FirstSectionBg from "assets/background_faq.svg";
import { Colors } from "constants/colors";

export const Section = styled(DefaultSection)`
  padding-top: 210px;
  padding-left: 80px;
  padding-right: 80px;

  display: flex;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .heading {
    width: 80%;
    margin: 0 auto;
    color: ${Colors.white};
    .title {
      font: 600 2.2rem/8.32rem Cairo, sans-serif;
      line-height: 100%;
    }
    .subtitle {
      font: 400 1.2rem/5.12rem Open Sans, sans-serif;
      line-height: 100%;
    }
  }
  .search {
    background-color: #fff;
    width: 80%;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 24px 32px;
    border-radius: 2rem;
    margin-top: 32px;
    box-shadow: 0px 8px 30px 0px #12192226;
    svg {
      font-size: 1.8rem;
      color: ${Colors.gray60};
    }
    label {
      width: 100%;
      display: flex;
      input {
        width: 100%;
        border: none;
        padding: 0 22px;
        font: 400 1rem/2rem Poppins, sans-serif;
        color: ${Colors.gray20};
        ::placeholder {
          color: ${Colors.gray60};
        }
        :focus {
          outline: none;
        }
      }
    }
    @media screen and (max-width: 850px) {
      flex-direction: column;
      height: 140px;
    }
  }
  .questions {
    background-color: #fff;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 80px;
    border-radius: 2rem;
    margin-top: 108px;
    box-shadow: 0px 8px 30px 0px #12192226;
    .title {
      font: 600 4rem/6.4rem Poppins, sans-serif;
      color: ${Colors.secondary100};
    }
    .icon-container {
      display: flex;
      width: fit-content;
      padding: 16px;
      border-radius: 20px;
      margin-right: 42px;
      background: #e9e7ee;

      svg {
        width: 25px;
        height: 25px;
        color: ${Colors.secondary100};
      }
    }
  }
  @media screen and (max-width: 1024px) {
    padding-top: 148px;
  }
  @media screen and (max-width: 850px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (max-width: 450px) {
    .search {
      width: 90%;
    }
  }
`;

export const Container = styled(DefaultContainer)`
  &.first-container {
    background: url(${FirstSectionBg});
    background-size: cover;
    background-repeat: no-repeat;
    height: 640px;
  }
`;
export const ActionButton = styled.button`
  width: 150px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 14px;
  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  font: 700 18px Cairo, sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    background-color: ${Colors.primary100};
    font: 700 18px Cairo, sans-serif;
    color: #fff;
    outline: none;
  }
  svg {
    margin-right: 12px;
  }
  @media screen and (max-width: 850px) {
    margin-top: 20px;
    width: 100%;
  }
`;
