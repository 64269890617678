import { useLocation, useNavigate } from "react-router-dom";

import { MdEdit } from "react-icons/md";
import { RiArrowRightSLine } from "react-icons/ri";

import { useAuth } from "contexts/AuthContext";

import { authClient } from "services/authClient";

import {
  Avatar,
  Card,
  Container,
  ContainerItems,
  ProfileCard,
  Content,
} from "./styles";

import { successNotification } from "components/Notification";

import meus_ingressos_active from "assets/meus-ingressos-active.svg";
import ticket_profile from "assets/ticket-profile.svg";
import minhas_compras_active from "assets/minhas-compras-active.svg";
import minhas_compras from "assets/minhas-compras.svg";
import personal_data_active from "assets/personal-data-active.svg";
import personal_data from "assets/personal-data.svg";
import data_access_active from "assets/data-access-active.svg";
import data_access from "assets/data-access.svg";
import address_active from "assets/address-active.svg";
import address_profile from "assets/address-profile.svg";
import user_avatar from "assets/user-avatar.svg";

export function SidebarProfile() {
  const { user, hydratationUser } = useAuth();

  const navigate = useNavigate();

  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const changeRouter = (route: string) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(route);
  };

  const onSelectFile = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    try {
      let formData1 = new FormData();
      if (e.target.files[0]) {
        formData1.append("avatar", e.target.files[0]);
      }

      await authClient.post(`/users/upload-photo/${user?.id}`, formData1, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      hydratationUser();

      successNotification(
        "Usuario atualizado",
        `Seu avatar foi atualizado com sucesso!`,
        1
      );
    } catch (err) {}
  };

  return (
    <Container>
      <ContainerItems>
        <ProfileCard>
          <Avatar>
            <img src={user?.avatar ?? user_avatar} alt={user?.name} />

            <input
              id="file"
              type="file"
              style={{ display: "none" }}
              accept="image/png, image/gif, image/jpeg"
              onChange={onSelectFile}
            />

            <label className="badge" htmlFor="file">
              <MdEdit size={18} />
            </label>
          </Avatar>

          <Content>
            <h2>{`${user?.name ?? ""} ${user?.last_name ?? ""} `}</h2>
            <span>{user?.email}</span>
            <span>{user?.cell_phone}</span>
          </Content>
        </ProfileCard>

        <Card
          active={splitLocation[2] === "my-ticket"}
          onClick={() => changeRouter("/profile/my-ticket")}
        >
          <div className={splitLocation[2] === "my-ticket" ? "active" : ""}>
            <div className="background">
              <img
                className="normal"
                src={meus_ingressos_active}
                width={18.75}
                height={16.87}
                alt="Logo2"
              />
            </div>

            <div className="normal">
              <img
                alt="Logo"
                src={ticket_profile}
                className="normal"
                width={18.75}
                height={16.87}
              />
            </div>

            <h2>Meus Ingressos</h2>
          </div>

          <RiArrowRightSLine className="icon" size={25} />
        </Card>

        <Card
          active={splitLocation[2] === "my-shopping"}
          onClick={() => changeRouter("/profile/my-shopping")}
        >
          <div className={splitLocation[2] === "my-shopping" ? "active" : ""}>
            <div className="background">
              <img
                className="normal"
                src={minhas_compras_active}
                alt="Logo2"
                width={25}
                height={22.5}
              />
            </div>

            <div className="normal">
              <img
                className="nomal"
                src={minhas_compras}
                alt="Logo"
                width={25}
                height={22.5}
              />
            </div>

            <h2>Minhas Compras</h2>
          </div>

          <RiArrowRightSLine className="icon" size={25} />
        </Card>

        <Card
          active={splitLocation[2] === "personal-data"}
          onClick={() => changeRouter("/profile/personal-data")}
        >
          <div className={splitLocation[2] === "personal-data" ? "active" : ""}>
            <div className="background">
              <img
                className="normal"
                src={personal_data_active}
                alt="Logo2"
                width={25}
                height={22.5}
              />
            </div>

            <div className="normal">
              <img
                className="nomal"
                src={personal_data}
                alt="Logo"
                width={25}
                height={22.5}
              />
            </div>

            <h2>Dados pessoais</h2>
          </div>

          <RiArrowRightSLine className="icon" size={25} />
        </Card>

        <Card
          active={splitLocation[2] === "access-data"}
          onClick={() => changeRouter("/profile/access-data")}
        >
          <div className={splitLocation[2] === "access-data" ? "active" : ""}>
            <div className="background">
              <img
                className="normal"
                src={data_access_active}
                alt="Logo2"
                width={25}
                height={22.5}
              />
            </div>

            <div className="normal">
              <img
                className="nomal"
                src={data_access}
                alt="Logo"
                width={25}
                height={22.5}
              />
            </div>

            <h2>Dados de acesso</h2>
          </div>

          <RiArrowRightSLine className="icon" size={25} />
        </Card>

        <Card
          active={splitLocation[2] === "address"}
          onClick={() => changeRouter("/profile/address")}
        >
          <div className={splitLocation[2] === "address" ? "active" : ""}>
            <div className="background">
              <img
                className="active"
                src={address_active}
                alt="Logo2"
                width={29}
                height={27.5}
              />
            </div>

            <div className="normal">
              <img
                className="normal"
                src={address_profile}
                alt="Logo"
                width={25}
                height={22.5}
              />
            </div>

            <h2>Meus endereços</h2>
          </div>

          <RiArrowRightSLine className="icon" size={25} />
        </Card>
      </ContainerItems>
    </Container>
  );
}
