import React, { useState } from "react";

import { AiOutlineClockCircle } from "react-icons/ai";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdAdd, MdRemove } from "react-icons/md";
import {
  RiInformationLine,
  RiMailLine,
  RiMapPinLine,
  RiLiveLine,
  RiTicket2Line,
  RiWhatsappLine,
} from "react-icons/ri";
import { IoMdAlert } from "react-icons/io";

import QRCode from "react-qr-code";

import {
  Col,
  Row,
  Divider as AntdDivider,
  Button,
  Input,
  notification,
} from "antd";

import {
  Container,
  Section,
  BoxContainer,
  Title,
  Divider,
  DetailsContainer,
  IconContainer,
  AllDetailsBox,
  Box,
  ParentalRatingContainer,
  ParentalRatingIcon,
  LocationContainer,
  TicketContainer,
  OrganizerHeader,
  ContactItem,
  ContactItemIcon,
  OrganizerText,
  DownloadSection,
  ModalTitle,
  ModalDescription,
  OrDivider,
  Subtitle,
  MobileContact,
  MobileContactButton,
  ActionButtonGroup,
  Description,
} from "./styles";

import { Flex } from "containers/Flex";
import Modal from "components/DownloadModal";
import {
  limitNotification,
  successNotification,
} from "components/Notification";

function formatData(data: string) {
  const date = data.split("T")[0];

  const month = date.split("-")[1];
  const day = date.split("-")[2];
  const year = date.split("-")[0];
  return `${day}/${month}/${year}`;
}

interface Props {
  data: any;
  cart: any;
  changeCart: any;
  rate: number;
}

const DescriptionContainer: React.FC<Props> = ({
  data,
  cart,
  changeCart,
  rate,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const parentalRating = [
    { age: null, color: "#fff" },
    { age: "L", color: "#00EE6D" },
    { age: "10", color: "#08A6FF" },
    { age: "12", color: "#DFC900" },
    { age: "14", color: "#EA8D02" },
    { age: "16", color: "#BC1C1C" },
    { age: "18", color: "#000000" },
  ];

  const Streamings = [
    { value: "1", label: "Pandastreaming" },
    { value: "2", label: "Youtube" },
    { value: "3", label: "Facebook" },
    { value: "4", label: "Twitter" },
    { value: "5", label: "Instagram" },
    { value: "6", label: "Vímeo" },
    { value: "7", label: "Microsoft Teams" },
    { value: "8", label: "Zoom" },
    { value: "9", label: "Whatsapp" },
    { value: "10", label: "Outra plataforma" },
  ];

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const switchEventType = () => {
    switch (data?.type_event) {
      case 1:
        return "Presencial";
      case 2:
        return "Online";
      case 3:
        return "Híbrido (presencial + online)";
    }
  };

  const RowTickets = ({ item, index, rate }: any) => {
    function countMore() {
      if (item.user_quantity_max === item.quantity) {
        limitNotification(
          "Você atingiu a quantidade limite!",
          `O ingresso selecionado está limitado a quantidade de ${item.user_quantity_max} por venda, não é possível adicionar mais.`,
          4.5
        );

        return;
      }

      changeCart(item.ticket_id, item.quantity + 1);
      notification.destroy();
      successNotification(
        "Item Adicionado",
        `O item ${item?.name} foi adicionado ao carrinho!`,
        1
      );
    }
    function countLess() {
      changeCart(item.ticket_id, item.quantity > 0 ? item.quantity - 1 : 0);
    }
    return (
      <>
        <TicketContainer key={item?.ticket_id}>
          <div className="icon-container">
            <RiTicket2Line />
            <div className="name-and-price">
              <p>{item?.name}</p>
              <p>
                {formatter.format(
                  item.discount != 0 ? item.price - item.discount : item.price
                )}
                {!item.absorb_rate ? (
                  <span style={{ color: "#999", fontSize: 12 }}>
                    {" "}
                    + taxa{" "}
                    {formatter.format(
                      ((item.discount != 0
                        ? item.price - item.discount
                        : item.price) /
                        100) *
                        rate
                    )}
                  </span>
                ) : null}
              </p>
            </div>
          </div>
          <ActionButtonGroup>
            {item.quantity > 0 ? (
              <>
                <Input readOnly value={item.quantity + "x"} />
                <Button
                  className="remove"
                  onClick={countLess}
                  icon={<MdRemove size={16} />}
                />
              </>
            ) : null}
            <Button
              className="add"
              onClick={countMore}
              icon={<MdAdd size={16} />}
            />
          </ActionButtonGroup>
        </TicketContainer>
        {item.description ? (
          <Description style={{}}>
            <IoMdAlert />
            <p>{item.description}</p>
          </Description>
        ) : null}
        {index !== data?.tickets.length - 1 && <Divider />}
      </>
    );
  };

  return (
    <>
      <Container className="first-container">
        <Section>
          <div className="content">
            <Row gutter={16}>
              <Col xl={16} lg={14} md={24} style={{ width: "100%" }}>
                <BoxContainer style={{ marginBottom: "2.5em" }}>
                  <Title>Descrição</Title>
                  <p id="description">{data?.description}</p>

                  <Divider />

                  <AllDetailsBox>
                    <DetailsContainer>
                      <IconContainer>
                        <FaRegCalendarAlt />
                      </IconContainer>
                      <div className="info-container">
                        <p>Data</p>
                        <p>{formatData(data?.begin_date)}</p>
                      </div>
                    </DetailsContainer>

                    <DetailsContainer>
                      <IconContainer>
                        <AiOutlineClockCircle />
                      </IconContainer>
                      <div className="info-container">
                        <p>Horário</p>
                        <p>
                          {data?.begin_hour.split(":").slice(0, -1).join(":")}h
                          às {data?.end_hour.split(":").slice(0, -1).join(":")}h
                        </p>
                      </div>
                    </DetailsContainer>

                    <DetailsContainer>
                      <IconContainer>
                        <RiInformationLine />
                      </IconContainer>
                      <div className="info-container">
                        <p>Tipo de evento</p>
                        <p>{switchEventType()}</p>
                      </div>
                    </DetailsContainer>
                  </AllDetailsBox>

                  <Divider />

                  <Title className="desktop">
                    Ingressos{cart.length > 0 ? " disponíveis" : null}
                  </Title>
                  <Title className="mobile">Ingressos</Title>
                  <Box>
                    {cart.length == 0 ? (
                      <p className="organizer-name">
                        Não há ingressos disponíveis no momento.
                      </p>
                    ) : (
                      <>
                        {cart.map((item: any, index: number) => {
                          return (
                            <RowTickets
                              key={index}
                              item={item}
                              index={index}
                              rate={rate}
                            />
                          );
                        })}
                      </>
                    )}
                  </Box>
                  <Title>Organizador</Title>
                  <Box className="organizer-desktop">
                    <OrganizerHeader>
                      {/* <img
                          className="organizer-photo"
                          src={
                            "https://images.unsplash.com/photo-1430990480609-2bf7c02a6b1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                          }
                          alt=""
                        /> */}
                      <OrganizerText>
                        <a
                          href={
                            data?.organizer.nickname !== null
                              ? `/${data?.organizer.nickname}`
                              : `/${data?.organizer.id}`
                          }
                        >
                          <p className="organizer-name">
                            {data?.organizer.name}
                          </p>
                        </a>
                        <div className="contact-container">
                          {data?.support_whatsapp !== "" &&
                            data?.support_whatsapp !== null && (
                              <>
                                <ContactItem>
                                  <ContactItemIcon>
                                    <RiWhatsappLine />
                                  </ContactItemIcon>
                                  <a
                                    style={{ flex: 1 }}
                                    href={`https://api.whatsapp.com/send?phone=${
                                      data?.support_whatsapp.includes("+")
                                        ? data?.support_whatsapp
                                        : `+55 ${data?.support_whatsapp}`
                                    }&text=Oi,%20estou%20visitando%20a%20*EventX*,%20poderia%20me%20ajudar?`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="organizer-contact"
                                  >
                                    {data?.support_whatsapp}
                                  </a>
                                </ContactItem>
                              </>
                            )}
                          {data?.support_email !== "" &&
                            data?.support_email !== null && (
                              <>
                                <AntdDivider
                                  className="vertical-divider"
                                  type="vertical"
                                />
                                <ContactItem>
                                  <ContactItemIcon>
                                    <RiMailLine />
                                  </ContactItemIcon>
                                  <a
                                    href={`mailto:${data?.support_email}`}
                                    className="organizer-contact"
                                  >
                                    {data?.support_email}
                                  </a>
                                </ContactItem>
                              </>
                            )}

                          {data?.support_email !== "" &&
                            data?.support_email !== null && (
                              <>
                                <AntdDivider
                                  className="vertical-divider"
                                  type="vertical"
                                />
                                <ContactItem>
                                  <ContactItemIcon>
                                    <RiMailLine />
                                  </ContactItemIcon>
                                  <a
                                    href={`mailto:${data?.support_email}`}
                                    className="organizer-contact"
                                  >
                                    {data?.support_email}
                                  </a>
                                </ContactItem>
                              </>
                            )}
                        </div>
                      </OrganizerText>
                    </OrganizerHeader>
                    <AntdDivider style={{ marginBottom: 0 }} />
                    <Subtitle>Sobre o organizador</Subtitle>
                    <p id="description">{data?.organizer.description}</p>
                  </Box>

                  <Box className="organizer-mobile">
                    <OrganizerHeader>
                      <a href={"/" + data?.organizer?.id}>
                        <OrganizerText>
                          <p className="organizer-name">
                            {data?.organizer.name}
                          </p>
                        </OrganizerText>
                      </a>
                    </OrganizerHeader>
                    <AntdDivider style={{ marginBottom: 0 }} />
                    <Subtitle>SOBRE O ORGANIZADOR</Subtitle>
                    <p id="description">{data?.organizer.description}</p>
                    <br />
                    {data?.support_whatsapp !== "" &&
                      data?.support_whatsapp !== null &&
                      data?.support_email !== "" &&
                      data?.support_email !== null && (
                        <Subtitle>ENTRE EM CONTATO</Subtitle>
                      )}
                    <MobileContact>
                      {data?.support_whatsapp !== "" &&
                        data?.support_whatsapp !== null && (
                          <>
                            <MobileContactButton
                              href={`https://api.whatsapp.com/send?phone=${
                                data?.support_whatsapp.includes("+")
                                  ? data?.support_whatsapp
                                  : `+55 ${data?.support_whatsapp}`
                              }&text=Oi,%20estou%20visitando%20a%20*EventX*,%20poderia%20me%20ajudar?`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <RiWhatsappLine /> Whatsapp
                            </MobileContactButton>
                          </>
                        )}
                      {data?.support_email !== "" &&
                        data?.support_email !== null && (
                          <>
                            <MobileContactButton
                              href={`mailto:${data?.support_email}`}
                              rel="noreferrer"
                            >
                              <RiMailLine /> E-mail
                            </MobileContactButton>
                          </>
                        )}
                    </MobileContact>
                  </Box>
                </BoxContainer>
              </Col>

              <Col xl={8} lg={10} md={24} style={{ width: "100%" }}>
                <BoxContainer>
                  {data?.type_event == 1 ? <Title>Localização</Title> : null}
                  {data?.type_event == 3 ? (
                    <Title>Localização e transmissão</Title>
                  ) : null}
                  {data?.type_event == 2 ? <Title>Transmissão</Title> : null}
                  {data?.type_event == 1 ? (
                    <>
                      <div style={{ width: "100%" }}>
                        <iframe
                          width="100%"
                          height="152px"
                          id="gmap_canvas"
                          src={`https://maps.google.com/maps?q=${data?.address_zipcode}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                          style={{
                            border: 0,
                            borderRadius: "10px",
                            filter:
                              "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08))",
                            overflow: "hidden",
                          }}
                          // loading="lazy"
                          allowFullScreen={true}
                          title="Localização do evento"
                        ></iframe>
                      </div>
                      <LocationContainer>
                        <Flex alignItems="center">
                          <RiMapPinLine />
                          <p className="location-title">{data?.address_name}</p>
                        </Flex>
                        <p className="location-details">
                          {data?.address_district}
                          {", " + data?.address_number} - {data?.address_street}
                          {", " + data?.address_city} -{" "}
                          {data?.address_state + ", "}
                          {data?.address_zipcode}
                        </p>
                      </LocationContainer>
                    </>
                  ) : null}
                  {data?.type_event == 2 || data?.type_event == 3 ? (
                    <>
                      <LocationContainer>
                        <Flex alignItems="center">
                          <RiLiveLine />
                          <p className="location-title">
                            {Streamings[data?.streaming_id - 1 || 0]?.label}
                          </p>
                        </Flex>
                      </LocationContainer>
                    </>
                  ) : null}
                </BoxContainer>

                <BoxContainer
                  className="age-container"
                  style={{ marginTop: "2em" }}
                >
                  <Title>Classificação indicativa</Title>
                  <ParentalRatingContainer>
                    <ParentalRatingIcon rating={parentalRating[data?.age]}>
                      <p>{parentalRating[data?.age]?.age}</p>
                    </ParentalRatingIcon>
                    {data?.age && data?.age !== 1 ? (
                      <p>
                        O evento é restrito para maiores de{" "}
                        {parentalRating[data?.age]?.age} anos de idade.
                      </p>
                    ) : null}

                    {data?.age && data?.age === 1 ? (
                      <p>L - Livre para todas as idades.</p>
                    ) : null}
                  </ParentalRatingContainer>
                </BoxContainer>
              </Col>
            </Row>
          </div>
        </Section>
      </Container>

      <Modal
        visible={isModalVisible}
        title={undefined}
        onCancel={() => setIsModalVisible(false)}
        onOkClick={() => setIsModalVisible(false)}
      >
        <>
          <QRCode size={190} value="https://eventx.com.br/download" />
          <ModalTitle>App EventX</ModalTitle>
        </>
        <ModalDescription>
          Escaneie o QR Code para fazer o download do nosso app direto em seu
          celular e <b>continuar sua compra</b>.
        </ModalDescription>
        <OrDivider>
          <Divider />
          <span style={{ margin: "0 12px" }}>Ou</span>
          <Divider />
        </OrDivider>
        <DownloadSection>
          <p className="download">
            Faça o download clicando em umas das opções abaixo
          </p>
          <div className="container-download">
            <a
              href="https://play.google.com/store/apps/details?id=com.mobile.eventx"
              aria-label="Baixe agora para Google Play!"
              target="_blank"
              rel="noreferrer"
            >
              <div className="download-button google-play" />
            </a>
            <a
              href="https://apps.apple.com/us/app/eventx-eventos-e-ingressos/id1584406908"
              aria-label="Baixe agora para Apple Store!"
              target="_blank"
              rel="noreferrer"
            >
              <div className="download-button app-store" />
            </a>
          </div>
        </DownloadSection>
      </Modal>
    </>
  );
};

export default DescriptionContainer;
