import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useCallback,
  useState,
} from "react";

import { FieldError } from "react-hook-form";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import { ContainerInputFull, FormLabel } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  isEditing?: boolean;
  label?: string;
  error?: FieldError;
  isFull?: boolean;
  icon?: JSX.Element;
  showMessagePassword?: boolean;
}

export const InputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = (
  {
    name,
    isEditing = true,
    showMessagePassword = true,
    label,
    error = null,
    icon,
    isFull,
    ...rest
  },
  ref
) => {
  const [showPass, setShowPass] = useState(false);

  const handleShowPass = useCallback(() => {
    setShowPass(!showPass);
  }, [showPass]);

  return (
    <ContainerInputFull isFull={isFull} error={!!error}>
      <FormLabel>
        {true && !!icon ? icon : icon}
        <h2>{label}</h2>
      </FormLabel>

      <div className="container-password">
        <input
          type={showPass ? "text" : "password"}
          name={name}
          {...rest}
          ref={ref}
          disabled={!isEditing}
        />

        {showPass && isEditing ? (
          <RiEyeLine
            onClick={handleShowPass}
            size={23}
            color={Colors.secondary40}
          />
        ) : null}

        {!showPass && isEditing ? (
          <RiEyeOffLine
            onClick={handleShowPass}
            size={23}
            color={Colors.secondary40}
          />
        ) : null}
      </div>

      {!!error && showMessagePassword ? (
        <span className="error">{error.message}</span>
      ) : null}
    </ContainerInputFull>
  );
};

export const InputPassword = forwardRef(InputBase);
