import React, { useState } from "react";

import { RiCloseCircleLine } from "react-icons/ri";

import { DownloadSection, StyledModal } from "./styles";

import SuccessPurchase from "assets/success_purchase.svg";

interface Props {
  handleSuccess: () => void;
  event: any;
  cart: any;
}

const ModalSuccess: React.FC<Props> = ({ handleSuccess, event, cart }) => {
  const [userEmail, setUserEmail] = useState("");

  return (
    <StyledModal
      visible={true}
      footer={null}
      onCancel={handleSuccess}
      closeIcon={<RiCloseCircleLine className="custom-close-icon" />}
    >
      <img src={SuccessPurchase} alt="" />
      <p className="title">Compra finalizada!</p>
      <p className="description">
        Sua compra foi realizada com sucesso! O ingresso foi enviado para o
        e-mail: <b>{userEmail}</b>.
      </p>
      <p className="description">
        Você tabém pode acessá-lo no app EventX, fazendo o download abaixo:
      </p>
      <DownloadSection>
        <p className="download">
          Faça o download clicando em umas das opções abaixo
        </p>
        <div className="container-download">
          <a
            href="https://play.google.com/store/apps/details?id=com.mobile.eventx"
            aria-label="Baixe agora para Google Play!"
            target="_blank"
            rel="noreferrer"
          >
            <div className="download-button google-play" />
          </a>
          <a
            href="https://apps.apple.com/us/app/eventx-eventos-e-ingressos/id1584406908"
            aria-label="Baixe agora para Apple Store!"
            target="_blank"
            rel="noreferrer"
          >
            <div className="download-button app-store" />
          </a>
        </div>
      </DownloadSection>
    </StyledModal>
  );
};

export default ModalSuccess;
