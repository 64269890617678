import { Select } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background: ${Colors.white};
`;

export const ContainerSearch = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background: linear-gradient(
    180deg,
    #f8f8fa 75.1%,
    rgba(248, 248, 250, 0) 101.35%
  );
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  padding-right: 38px;
  padding-left: 38px;

  margin-top: 9.14rem;

  width: 100%;

  @media (max-width: 800px) {
    margin-top: 64px;
    padding-left: 28px;
    padding-right: 28px;
  }

  div.main-header {
    @media (max-width: 1024px) {
      display: none;
    }

    h1 {
      font-weight: bold;
      font-size: 40px;
      line-height: 160%;
      margin-top: 54px;
      color: ${Colors.black100};
    }
  }

  div.header-filter-mobile {
    margin-top: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 1024px) {
      display: none;
    }

    h1 {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;

      color: ${Colors.black100};
    }

    div.box {
      width: 25.73px;
      height: 25.73px;
      border-radius: 50%;
      background: ${Colors.white20};

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
    }
  }

  div.bread {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const ContainerMainItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  padding-right: 38px;
  padding-left: 38px;
  margin-top: 0px;
  width: 100%;

  h2.search-text {
    font-weight: bold;
    font-size: 35px;
    line-height: 160%;

    color: ${Colors.secondary100};
    margin-bottom: 50px;
  }

  @media (max-width: 800px) {
    margin-top: 30px;
    padding-left: 28px;
    padding-right: 28px;
  }
`;

export const ContainerMainPagination = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  padding-right: 38px;
  padding-left: 38px;
  margin-top: 50px;
  width: 100%;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ContainerMainPaginationMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;
  padding-right: 28px;
  padding-left: 28px;
  margin-top: 0px;
  margin-bottom: 0.93rem;
  width: 100%;
`;

export const ContainerMainTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  padding-right: 38px;
  padding-left: 38px;

  margin-top: 4.68rem;
  width: 100%;

  h2.search-text {
    font-family: "Cairo";
    font-weight: 700;
    font-size: 1.87rem;

    color: ${Colors.secondary100};
    margin-bottom: 50px;

    @media (max-width: 800px) {
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 800px) {
    margin-top: 40px;
    padding-left: 28px;
    padding-right: 28px;
  }
`;

interface CategoryBackgroundProps {
  image: string;
  imageMobile: string;
}

export const CategoryBackground = styled.div<CategoryBackgroundProps>`
  height: 25.12rem;
  width: 100%;
  margin-top: 1.26rem;
  padding: 0px 4.4rem 4.4rem 4.4rem;

  @media (max-width: 800px) {
    margin-top: 0px;
    height: 190px;

    padding: 0px 28px 2.4rem 1.31rem;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  background: url(${(props) => props.image ?? "/assets/eventos-online.jpg"});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 45% 29%;
  object-fit: scale-down;

  @media (max-width: 800px) {
    background: url(${(props) => props.imageMobile});
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
`;

export const Div = styled.div``;

export const ContainerCategory = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  @media (max-width: 800px) {
    margin-top: 60px;
  }

  div.all {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      div.logo-img {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 800px) {
          img {
            max-width: 30px;
            max-height: 30px;
          }
        }
      }

      h3 {
        font-family: "Cairo";
        font-weight: bold;
        font-style: 900;
        font-size: 1.87rem;
        color: ${Colors.white};
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
        margin-left: 24px;
        margin-bottom: 7px;

        @media (max-width: 800px) {
          font-size: 16px;
          margin-left: 14px;
        }
      }

      h4 {
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 1.21rem;

        width: 90%;
        color: ${Colors.gray60};
        margin-top: 1.07rem;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
        margin-bottom: 0px;

        @media (max-width: 800px) {
          font-size: 10px;
          line-height: 160%;
          margin-top: 14px;
        }
      }
    }
  }
`;

export const HeaderSearching = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 63px;
  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  border-radius: 14px;

  div.filters {
    width: 18%;
    height: 100%;
    display: flex;
    border-left: 1px solid ${Colors.border30};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 31px 0px 40px;

    @media (max-width: 1180px) {
      width: 28%;
    }
  }
`;

export const StyledChangeCategory = styled(Select)`
  width: 262px;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background: rgba(0, 0, 0, 0.15);

  border: 2px solid ${Colors.white};
  box-sizing: border-box;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
  border-radius: 14px;

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  @media (max-width: 800px) {
    min-width: 102px;
    height: 30px;
    border-radius: 8px;

    img {
      max-width: 80px;
      max-height: 8px;
    }
  }

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;

    color: ${Colors.white};
    margin-left: 10px;

    @media (max-width: 800px) {
      font-size: 8px;
    }
  }
`;

export const ButtonChangeCategory = styled.button`
  width: 12.28rem;
  height: 2.81rem;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background: rgba(0, 0, 0, 0.15);

  border: 2px solid ${Colors.white};
  box-sizing: border-box;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
  border-radius: 0.65rem;

  @media (max-width: 800px) {
    min-width: 102px;
    height: 30px;
    border-radius: 8px;

    img {
      max-width: 80px;
      max-height: 8px;
    }
  }

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    line-height: 160%;

    color: ${Colors.white};
    margin-left: 10px;

    @media (max-width: 800px) {
      font-size: 8px;
    }
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;

  @media (max-width: 800px) {
    padding-left: 28px;
    padding-right: 28px;
    margin-top: 70px;
  }
`;

export const ButtonMoreEvents = styled.button`
  border: 0;
  cursor: pointer;
  width: 534px;
  height: 60px;

  background: ${Colors.primary10};
  border-radius: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;

    color: ${Colors.primary100};
    margin-left: 10px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 60px;
    border-radius: 8px;
  }
`;

export const Center = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListSearchWeb = styled.div`
  width: 100%;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ListSearchMobile = styled.div`
  width: 100%;

  @media (min-width: 800px) {
    display: none;
  }
`;

export const FooterItems = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 38px;

  @media (min-width: 800px) {
    display: none;
  }

  h3 {
    font-weight: 600;
    font-size: 11px;
    line-height: 21px;
    color: ${Colors.black50};
  }
`;

export const ButtonMoreItems = styled.button`
  width: 100%;
  height: 38px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 34px;

  :disabled {
    opacity: 0.6;
  }

  border: 0;
  background: ${Colors.primary100};
  border-radius: 8px;

  span {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: ${Colors.white};
  }
`;
