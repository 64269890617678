import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  padding-right: 38px;
  padding-left: 38px;

  margin-top: 40px;
  width: 100%;

  @media (max-width: 800px) {
    margin-top: 20px;
    padding-left: 28px;
    padding-right: 28px;
  }

  div.results {
    h4 {
      font-weight: bold;
      font-size: 20px;
      line-height: 160%;

      color: ${Colors.black40};

      span {
        color: ${Colors.black60};
      }

      @media (max-width: 800px) {
        font-size: 14px;
      }
    }
  }

  div.main-header {
    @media (max-width: 1024px) {
      display: none;
    }

    h1 {
      font-weight: bold;
      font-size: 40px;
      line-height: 160%;
      margin-top: 54px;
      color: ${Colors.black100};
    }
  }

  div.header-filter-mobile {
    margin-top: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 1024px) {
      display: none;
    }

    h1 {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;

      color: ${Colors.black100};
    }

    div.box {
      width: 25.73px;
      height: 25.73px;
      border-radius: 50%;
      background: ${Colors.white20};

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
    }
  }

  div.bread {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const ListCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(12rem, 1fr));
  column-gap: 0.45rem;
  row-gap: 1.5rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 100%);
    row-gap: 0px;
    column-gap: 0px;
  }
`;

export const ContainerList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
