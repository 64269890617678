import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-height: 22.5rem;
  padding: 1.17rem !important;

  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  box-shadow: 0px 15px 33px rgba(108, 93, 211, 0.1);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img.thumb-search {
    border-radius: 14px;
    width: 100%;
    height: 148px;

    object-fit: cover;

    @media (max-width: 800px) {
      height: 118px !important;
      max-height: 128px;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 15px;
    max-height: none;
  }

  @media (max-width: 1200px) {
    width: 98%;
  }
`;

export const ContentCardEvent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.93rem;

  div.content {
    display: flex;
    flex-direction: column;

    h2 {
      font-family: "Cairo";
      font-weight: bold;
      font-size: 1.21rem;
      color: ${Colors.black100};
      margin-bottom: 0px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 800px) {
        font-size: 14px;
        margin-top: 5px;
      }
    }

    div.date {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        font-family: "Open Sans";
        color: ${Colors.primary100};
        font-weight: normal;
        font-size: 0.75rem;
        text-transform: uppercase;

        line-height: 22px;

        @media (max-width: 800px) {
          font-size: 12px;
          line-height: 21px;
          font-weight: normal;
          margin-top: 4px;
          line-height: 11px;
        }
      }

      div.city {
        margin-left: 28.5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 800px) {
          display: none;
        }

        span {
          margin-left: 7px;
          text-transform: capitalize;
          line-height: 22px;
          margin-bottom: 0px;
        }
      }
    }
  }

  div.list-categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    margin-top: 0.93rem;

    div.badge {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 74px;
      height: 26px;
      background: ${Colors.black10};
      border-radius: 14px;
      margin-top: 3px;
      margin-bottom: 4px;
      padding: 8px;
      margin-right: 8px;

      span {
        font-family: "Open Sans";
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 0.65rem;

        @media (max-width: 800px) {
          font-size: 11px;
        }
      }
    }
  }
`;

export const ContainerBorderAvailability = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;

  border-top: 1px solid ${Colors.border40};
  margin-top: 0.75rem;
  padding-top: 0.84rem;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;

  div.description {
    display: flex;
    flex-direction: column;

    span {
      font-family: "Open Sans";
      font-weight: 600;
      font-size: 0.65rem;
      margin-bottom: 0px;
      color: ${Colors.black30};

      @media (max-width: 800px) {
        font-size: 12px;
      }
    }

    strong {
      font-family: "Open Sans";
      font-weight: 600;
      font-size: 0.75rem;
      margin-bottom: 0px;
      color: ${Colors.secondary100};

      @media (max-width: 800px) {
        font-size: 12px;
        margin-right: 4px;
      }
    }
  }
`;
