interface paramsStringProps {
  price?: string;
  data?: string;
  categories?: string;
  id?: string;
  address_city?: string;
  address_state?: string;
  name?: string;
}

export function paramsToString({
  id,
  name,
  price,
  data,
  categories,
  address_city,
  address_state,
}: paramsStringProps) {
  let params = {};

  if (id) {
    params = { ...params, categories: id };
  }

  if (categories) {
    params = { ...params, categories };
  }

  if (name) {
    params = { ...params, name };
  }

  if (address_city) {
    params = { ...params, address_city };
  }

  if (address_state) {
    params = { ...params, address_state };
  }

  if (price) {
    switch (Number(price)) {
      case 1:
        params = { ...params, gte_price: 0, lte_price: 50 };
        break;

      case 2:
        params = { ...params, gte_price: 0, lte_price: 100 };
        break;

      case 3:
        params = { ...params, gte_price: 0, lte_price: 500 };
        break;

      default:
        break;
    }
  }

  if (data) {
    switch (Number(data)) {
      case 1:
        params = { ...params, data_type: "hoje" };
        break;

      case 2:
        params = { ...params, data_type: "Esta semana" };
        break;

      case 3:
        params = { ...params, data_type: "este mes" };
        break;

      default:
        break;
    }
  }

  return params;
}
