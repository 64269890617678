import { useFetchBasic } from "services/useFetch";

export function useOrganizerByNickName<Data = any, Error = any>(id: string) {
  const url: string = `/organizers/organizer-by-nickname/${id}`;

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}
