import { Modal } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerPasswordRequeriments = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-bottom: 25px;

  h3 {
    font-style: normal;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 6px;

    color: ${Colors.secondary100};
  }

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;

    color: ${Colors.black70};
  }

  div.requeriments {
    width: 100%;
    height: 112px;
    padding: 12px;

    display: flex;
    flex-direction: column;

    background: ${Colors.white};
    border: 1.5px solid ${Colors.border70};
    border-radius: 8px;
  }
`;

export const Requeriment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;

  h4 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;

    margin-bottom: 0px;
    color: ${Colors.black70};
    margin-left: 9.5px;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 34px 109px 64px 109px;

  @media (max-width: 800px) {
    padding: 0px;
  }

  div.logo-qr-code {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.content {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 14px;

      text-align: center;

      color: ${Colors.black90} #292c42;

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90} #292c42;
      }
    }
  }

  div.ok-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 12px;

    button {
      cursor: pointer;
      border: 0;
      background: ${Colors.primary100};
      width: 262px;
      height: 82px;

      background: #d40050;
      box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
      border-radius: 14px;

      color: ${Colors.white};
      font-weight: bold;
      font-size: 26px;
      line-height: 160%;

      text-align: center;
    }
  }
`;

export const WrapperRecaptcha = styled.div`
  display: flex;
  justify-content: center;
`;
