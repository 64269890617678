import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { authClient } from "services/authClient";
import { useUserPurchase } from "services/Hook/User";
import { useSWRConfig } from "swr";

import { Purchase as PurchaseType } from "types";

interface PurchaseInteface {
  id: number;
  purchase_code: number;
}

type Meta = {
  total: number;
  last_page: number;
};

interface PurchaseInteface {
  data: PurchaseType[];
  meta: Meta;
}

export function useSearchPurchase() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutate } = useSWRConfig();

  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );
  const [pageMobile, setPageMobile] = useState(1);

  const [loadingMoreItems, setLoadingMoreItems] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: String(currentPage),
      });

      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [searchParams, setSearchParams]
  );

  const { data, partialUrl, isValidating } =
    useUserPurchase<PurchaseInteface>(page);

  const handleLoadMorePurchasingsMobile = () => {
    setPageMobile(pageMobile + 1);

    mutate(
      [partialUrl, 1],
      async () => {
        setLoadingMoreItems(true);

        const response = await authClient.get(
          `${partialUrl}?page=${pageMobile + 1}&limit=10`
        );

        const last_page = response.data?.meta?.last_page;

        if (pageMobile + 1 === last_page) {
          setHasMoreItems(false);
        }

        setLoadingMoreItems(false);

        const mapEvents = response.data.data.map((event: Event) => ({
          ...event,
          isOpen: false,
        }));

        let newMapEvents = data?.data;

        const mobile = data?.data;

        if (mobile) {
          newMapEvents = [...mobile, ...mapEvents];
        }

        return {
          meta: response.data.meta,
          data: newMapEvents,
        };
      },
      false
    );
  };

  return {
    data,
    page,
    loadingMoreItems,
    isValidating,
    hasMoreItems,
    onChange,
    handleLoadMorePurchasingsMobile,
  };
}
