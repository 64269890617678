import styled from "styled-components";
import { Modal as AntdModal } from "antd";
import { Colors } from "constants/colors";

export const StyledModal = styled(AntdModal)`
  // .ant-modal-mask class -> global style
  width: 857px;
  top: 30px;
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px;
  }
  .custom-close-icon {
    color: #11142d;
    opacity: 0.3;
    font-size: 32px;
  }
  .ant-modal-content {
    width: 100%;
    background-color: ${Colors.white};
    padding: 32px;
    border-radius: 20px;
    .ant-modal-close-x {
      color: ${Colors.black};
    }
    .ant-modal-header {
      .ant-modal-title {
        color: ${Colors.black};
        text-align: center;
        P {
          margin-top: 32px;
        }
      }
      background-color: ${Colors.white};
    }
    .ant-modal-body {
      padding: 30px 22px;
      color: ${Colors.black};
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .ant-modal-footer {
      border-top: none;
      display: flex;
      justify-content: center;
      button {
        margin: 0 12px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .ant-modal-content {
      padding: 32px 16px;
    }
  }
  .title{
    font: 700 3.2rem, sans-serif;
    margin-top: 20px;
    color: ${Colors.secondary100};
  }
  .description{
    font: 400 2.0rem, sans-serif;
    text-align: center;
    margin-top: 12px;
  }
`;

export const LdsSpinner = styled.div`
  & {
    color: gray;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  & div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  & div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: grey;
  }
  & div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  & div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  & div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  & div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  & div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  & div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  & div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  & div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  & div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  & div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  & div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  & div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
