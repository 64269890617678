
import styled from "styled-components";
import { Select as AntdSelect } from "antd";
import { Colors } from "constants/colors";

const { Option } = AntdSelect;

export const Select = styled(AntdSelect)`
  width: 100%;
  &:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
    text-align: left;
    padding-left: 16px;
    :focus{
      box-shadow: none;
      outline: 0;
    }
  }
  .search-dropdown{
    display: none;
  }
  .ant-select-selection-placeholder{

      font: 400 1.2rem/2.16rem Poppins, sans-serif;
      color: ${Colors.gray20};
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: none;
    border-right-width: none;
    outline: 0;
    box-shadow: none;
}

`

export const SearchOption = styled(Option)`
  .ant-select-item{
  font: 700 1.2rem/2.12rem Open Sans, sans-serif;
  }
`
export const SearchContainer = styled.div`
  background-color: #fff;
  width: 70%;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 24px 32px;
  border-radius: 2rem;
  margin-top: 32px;
  box-shadow: 0px 8px 30px 0px #12192226;
  svg{
    font-size: 1.8rem;
    color: ${Colors.gray10};
  }
  label{
    width:100%;
    display: flex;
    input{
    width:100%;
    border: none;
    padding: 0 22px;
    font: 700 1rem/2rem Open Sans, sans-serif;
    color: ${Colors.gray10};
    ::placeholder{
      color: ${Colors.gray20};
    }
    :focus{
      outline: none;
    }
  }
  }
  @media screen and (max-width: 850px){
    width: 80%;
    flex-direction: column;
    height: 140px;
  }
  @media screen and (max-width: 450px){
    width: 90%;
  }
`
export const ActionButton = styled.button`
  width: 150px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 14px;
  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  font: 700 18px Cairo, sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    background-color: ${Colors.primary100};
    font: 700 18px Cairo, sans-serif;
    color: #fff;
    outline: none;
  }
  svg {
    margin-right: 12px;
  }
  @media screen and (max-width: 850px){
    margin-top: 20px;
    width: 100%
  }

`
