import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerTabs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  width: 100%;

  background: ${Colors.white};

  min-height: 400px;

  @media(max-width: 800px){
    margin-bottom: 30px;
  }

  .ant-tabs-nav {
    font-family: "Open Sans";
    width: 100% !important;
  }

  .ant-tabs-tab {
    font-family: "Open Sans";
    display: block;
    flex: 1;
    text-align: center;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    font-family: "Open Sans";
    display: unset !important;
    width: 100% !important;
    color: #adadad;
    font-weight: 700;
  }

  .ant-tabs-tab-active {
    font-family: "Open Sans";
    color: ${Colors.primary100} !important;
  }

  .ant-tabs-ink-bar {
    font-family: "Open Sans";
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab-btn {
    font-family: "Open Sans";
    font-size: 10px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-family: "Open Sans";
    color: ${Colors.primary100} !important;
    font-weight: 700;
    font-size: 10px;
    text-transform: uppercase;
  }
`;
