import { createContext, useState, useContext, useEffect } from "react";

import { useDisclosure } from "hooks/useDisclosure";

import { useMyEventDetail } from "services/Hook/Event";
import { authClient } from "services/authClient";

import Data from "constants/Datas";

type Integration = {
  id: number;
  label: string;
  short: string;
  description: string;
  thumb: string;
  link: string;
  integrationData: {
    event_id: string;
    id: number;
    key: string;
    status: true;
    token: string;
    type: number;
    user_id: number;
  } | null;
};

type IntegrationResponse = {
  event_id: string;
  id: number;
  key: string;
  status: true;
  token: string;
  type: number;
  user_id: number;
};

type IntegrationsContextData = {
  activeIntegrations?: boolean;
  activeNewIntegration?: boolean;
  activeEditIntegration?: boolean;
  handleChangeActiveIntegrations(value: boolean): void;
  handleChangeNewIntegration(value: boolean): void;
  handleChangeEditIntegration(value: boolean): void;
  eventIntegrations: Integration[] | [];
  eventIntegrationsActive: Integration[] | [];
  eventIntegrationsInative: Integration[] | [];
  selectedIntegration: Integration | null;
  setSelectedIntegration(integration: Integration): void;
  handleShowModalEdit(type: number): void;
  idsActiveIntegrations: number[];
  open: boolean;
  setOpen(value: boolean): void;
  getIntegrations(): void;
  eventIntegrationUpdate: Integration | null;
  setEventIntegrationUpdate(value: Integration | null): void;
  handleActiveIntegrations(): void;
  setIdUpdateIntegration(value: number | null): void;
  handleActiveNewIntegration(): void;
  onDismiss(): void;
  isModalEditVisible: boolean;
  handleOkEdit(): void;
  handleCancelEdit(): void;
  idUpdateIntegration: number | null;
  handleActiveEditIntegration(value: number): void;
  setNickname(value: string): void;
};

const IntegrationsContext = createContext<IntegrationsContextData>(
  {} as IntegrationsContextData
);

export const IntegrationsProvider: React.FC = ({ children }) => {
  const [activeIntegrations, setActiveIntegrations] = useState(false);
  const [activeNewIntegration, setActiveNewIntegration] = useState(false);
  const [activeEditIntegration, setActiveEditIntegration] = useState(false);

  const [nickname, setNickname] = useState("");

  const [eventIntegrations, setEventIntegrations] = useState<
    Integration[] | []
  >([]);

  const [eventIntegrationsActive, setEventIntegrationsActive] = useState<
    Integration[] | []
  >([]);

  const [eventIntegrationsInative, setEventIntegrationsInative] = useState<
    Integration[] | []
  >([]);

  const [eventIntegrationUpdate, setEventIntegrationUpdate] =
    useState<Integration | null>(null);

  const [selectedIntegration, setSelectedIntegration] =
    useState<Integration | null>(null);

  const [idsActiveIntegrations, setIdsActiveIntegrations] = useState<number[]>(
    []
  );

  const [open, setOpen] = useState(false);

  const [idUpdateIntegration, setIdUpdateIntegration] = useState<number | null>(
    null
  );

  const handleChangeActiveIntegrations = (value: boolean) => {
    setActiveIntegrations(value);
  };

  const handleChangeNewIntegration = (value: boolean) => {
    setActiveNewIntegration(value);
  };

  const handleChangeEditIntegration = (value: boolean) => {
    setActiveEditIntegration(value);
  };

  const { data } = useMyEventDetail<Event>(nickname ?? "");

  const {
    isModalVisible: isModalEditVisible,
    handleOk: handleOkEdit,
    handleCancel: handleCancelEdit,
    showModal: showModalEdit,
  } = useDisclosure();

  async function getIntegrations() {
    try {
      const response = await authClient.get(
        `/integration?page=1&limit=99&event=${data?.id}`
      );

      const responseData: IntegrationResponse[] = response.data.data;

      const mapIntegrations = Data.EventIntegrations.map((integration) => {
        const findIntegration = responseData.find(
          (old) => old.type === integration.id
        );

        if (findIntegration) {
          return {
            ...integration,
            integrationData: { ...findIntegration },
          };
        }

        return {
          ...integration,
          integrationData: null,
        };
      });

      setEventIntegrations(mapIntegrations);

      const filterActive = mapIntegrations.filter(
        (integration) => integration?.integrationData?.status === true
      );

      const filterInative = mapIntegrations.filter(
        (integration) =>
          !integration?.integrationData?.status &&
          integration.integrationData !== null
      );

      setEventIntegrationsActive(filterActive);

      setEventIntegrationsInative(filterInative);

      const allIntegrationsIds = responseData?.map(
        (integration) => integration.type
      );

      setIdsActiveIntegrations(allIntegrationsIds);
    } catch (err) {}
  }

  useEffect(() => {
    async function getIntegrations() {
      try {
        const response = await authClient.get(
          `/integration?page=1&limit=99&event=${data?.id}`
        );

        const responseData: IntegrationResponse[] = response.data.data;

        const mapIntegrations = Data.EventIntegrations.map((integration) => {
          const findIntegration = responseData.find(
            (old) => old.type === integration.id
          );

          if (findIntegration) {
            return {
              ...integration,
              integrationData: { ...findIntegration },
            };
          }

          return {
            ...integration,
            integrationData: null,
          };
        });

        setEventIntegrations(mapIntegrations);

        const filterActive = mapIntegrations.filter(
          (integration) => integration?.integrationData?.status === true
        );

        const filterInative = mapIntegrations.filter(
          (integration) =>
            !integration?.integrationData?.status &&
            integration.integrationData !== null
        );

        setEventIntegrationsActive(filterActive);

        setEventIntegrationsInative(filterInative);

        const allIntegrationsIds = responseData?.map(
          (integration) => integration.type
        );

        setIdsActiveIntegrations(allIntegrationsIds);
      } catch (err) {}
    }

    getIntegrations();
  }, [data?.id, nickname]);

  const handleShowModalEdit = (type: number) => {
    const getEventIntegrationByType = eventIntegrations.find(
      (integration) => integration.id === type
    );

    if (getEventIntegrationByType) {
      setEventIntegrationUpdate(getEventIntegrationByType);
      showModalEdit();
    }
  };

  function onDismiss() {
    setOpen(false);
  }

  const handleActiveIntegrations = () => {
    handleChangeActiveIntegrations(true);
  };

  const handleActiveNewIntegration = () => {
    handleChangeActiveIntegrations(false);
    handleChangeEditIntegration(false);
    handleChangeNewIntegration(true);
    onDismiss();
  };

  const handleActiveEditIntegration = (type: number) => {
    const getEventIntegrationByType = eventIntegrations.find(
      (integration) => integration.id === type
    );

    if (getEventIntegrationByType) {
      setEventIntegrationUpdate(getEventIntegrationByType);

      handleChangeActiveIntegrations(false);
      handleChangeNewIntegration(false);
      handleChangeEditIntegration(true);

      onDismiss();
    }
  };

  return (
    <IntegrationsContext.Provider
      value={{
        activeIntegrations,
        activeNewIntegration,
        activeEditIntegration,
        handleChangeActiveIntegrations,
        handleChangeNewIntegration,
        handleChangeEditIntegration,
        eventIntegrations,
        eventIntegrationsActive,
        eventIntegrationsInative,
        selectedIntegration,
        setSelectedIntegration,
        handleShowModalEdit,
        idsActiveIntegrations,
        open,
        setOpen,
        getIntegrations,
        eventIntegrationUpdate,
        setEventIntegrationUpdate,
        handleActiveIntegrations,
        setIdUpdateIntegration,
        handleActiveNewIntegration,
        onDismiss,
        isModalEditVisible,
        handleOkEdit,
        handleCancelEdit,
        handleActiveEditIntegration,
        idUpdateIntegration,
        setNickname,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};

export function useIntegrations(): IntegrationsContextData {
  const context = useContext(IntegrationsContext);

  if (!context) {
    throw new Error(
      `useIntegratons must be used within a IntegrationsProvider`
    );
  }

  return context;
}
