import { Colors } from "constants/colors";
import styled from "styled-components";

export const HeaderFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  h3.filter {
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;

    display: flex;
    align-items: center;

    color: ${Colors.secondary40};
    margin-bottom: 0px;
  }

  div.filters {
    width: 100%;
    margin-top: 4px;
    display: grid;
    grid-template-columns: repeat(4, minmax(6rem, 1fr));

    @media (max-width: 990px) {
      grid-template-columns: repeat(3, minmax(6rem, 1fr));
    }

    @media (max-width: 740px) {
      grid-template-columns: repeat(2, minmax(6rem, 1fr));
      row-gap: 0.4rem;
    }

    @media (max-width: 440px) {
      grid-template-columns: repeat(1, minmax(6rem, 1fr));
      row-gap: 0.4rem;
    }
  }
`;

export const FirstRow = styled.div`
  margin-top: 8px;
`;
