import { memo } from "react";

import { RiCalendarEventLine, RiLiveLine, RiMapPinLine } from "react-icons/ri";
import { Colors } from "constants/colors";

import {
  ButtonEmptyEventMobile,
  CardEventMobile,
  Container,
  ContainerEmptyEventMobile,
  ContentCardEventMobile,
  ListCards,
} from "./styles";

import GroupImg from "assets/group.svg";

interface ListEventProps {
  events: Event[];
}

function ListEventMobileComponent({ events }: ListEventProps) {
  function formatData(data: string) {
    const date = data.split("T")[0];

    const month = date.split("-")[1];
    const day = date.split("-")[2];
    const year = date.split("-")[0];
    return `${day}/${month}/${year}`;
  }

  return (
    <Container>
      {events?.length > 0 ? (
        <ListCards>
          {events?.map((itemEvent: any, index: number) => (
            <a href={"/" + itemEvent.nickname}>
              <CardEventMobile key={index}>
                {itemEvent.thumb ? (
                  <img
                    className="productive"
                    width="202px"
                    src={itemEvent.thumb}
                    alt=""
                  />
                ) : null}
                <ContentCardEventMobile>
                  <div className="head">
                    <img width="15px" height="15x" src={GroupImg} alt="" />
                    <div className="content">
                      <h2>{itemEvent.name}</h2>
                      <div className="date">
                        <span>
                          {formatData(itemEvent?.begin_date)} .{" "}
                          {itemEvent?.begin_hour
                            .split(":")
                            .slice(0, -1)
                            .join(":")}
                          h
                        </span>
                        <div className="city">
                          {itemEvent?.type_event === 1 ? (
                            <>
                              <RiMapPinLine
                                style={{
                                  color: Colors.primary100,
                                  fontSize: 22,
                                }}
                              />
                              <span>
                                {itemEvent?.address_city},{" "}
                                {itemEvent?.address_state}
                              </span>
                            </>
                          ) : null}
                          {itemEvent?.type_event === 2 ||
                          itemEvent?.type_event === 3 ? (
                            <>
                              <RiLiveLine
                                style={{
                                  color: Colors.primary100,
                                  fontSize: 18,
                                }}
                              />
                              <span>ONLINE</span>
                            </>
                          ) : null}
                        </div>
                      </div>

                      {itemEvent.categories.map(
                        (itemCategory: any, indexCategory: number) => {
                          return (
                            <div className="badge">
                              <span key={indexCategory}>
                                {itemCategory.name}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </ContentCardEventMobile>
              </CardEventMobile>
            </a>
          ))}
        </ListCards>
      ) : (
        <ContainerEmptyEventMobile>
          <h2>Não há nada por aqui :(</h2>
          <p>
            Não há eventos disponíveis no momento. Mas você pode encontrar
            outros eventos de seu interesse abaixo:
          </p>

          <a href="/">
            <ButtonEmptyEventMobile>
              <RiCalendarEventLine size={18} color={Colors.primary100} />
              <span>Ir para a página de eventos</span>
            </ButtonEmptyEventMobile>
          </a>
        </ContainerEmptyEventMobile>
      )}
    </Container>
  );
}

export const ListEventMobile = memo(ListEventMobileComponent);
