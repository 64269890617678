import { forwardRef, ForwardRefRenderFunction } from "react";

import { Badge as AntdBadge, BadgeProps } from "antd";
import styled from "styled-components";
import { Colors } from "constants/colors";

export const BadgeStyled = styled(AntdBadge)`
  & sup {
    background-color: ${Colors.primary100};
    font: 700 1rem/100% Cairo, "Cairo", sans-serif;
    padding-top: 1px;
  }
`;

export const Badge: ForwardRefRenderFunction<HTMLDivElement, BadgeProps> = (
  { children, ...rest },
  ref
) => {
  return <BadgeStyled {...rest}>{children}</BadgeStyled>;
};

export const InputForm = forwardRef(Badge);
