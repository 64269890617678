import { useFetchBasic } from "services/useFetch";

export function useCategories<Data = any, Error = any>() {
  const url: string = "/no-auth/categories?page=1&limit=99";

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}
