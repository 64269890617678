import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { authClient } from "services/authClient";
import { useUserTicket } from "services/Hook/User";
import { useSWRConfig } from "swr";

interface Event {
  id: string;
  name: string;
  thumb: string;
  photo: string;
  address_name: string;
  address_street: string;
  begin_date: string;
  formattedDate: string;
  formattedHour: string;
}

interface PurchaseInteface {
  id: number;
  purchase_code: number;
}

interface Ticket {
  id: string;
  holder_name: string;
  holder_email: string;
  event: Event;
  purchase: PurchaseInteface;
  isOpen: boolean;
  formattedDate: string;
  ticket: {
    name: string;
  };
}

type Meta = {
  total: number;
  last_page: number;
};

interface TicketsInterface {
  data: Ticket[];
  meta: Meta;
}

export function useSearchTicket() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate } = useSWRConfig();

  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );
  const [pageMobile, setPageMobile] = useState(1);

  const [loadingMoreItems, setLoadingMoreItems] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(false);

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: String(currentPage),
      });

      window.scrollTo({ top: 660, behavior: "smooth" });
    },
    [searchParams, setSearchParams]
  );

  const { data, partialUrl } = useUserTicket<TicketsInterface>(1);

  useEffect(() => {
    if (data?.meta.last_page === page) {
      setHasMoreItems(false);
    }
  }, [data?.meta.last_page, page]);

  const handleOpenDropTicketEventMobile = (id: string) => {
    mutate(
      [partialUrl, 1],
      () => {
        const newMapTickets = data?.data.map((ticket) =>
          ticket.id === id ? { ...ticket, isOpen: !ticket.isOpen } : ticket
        );

        return {
          ...data,
          data: newMapTickets,
        };
      },
      false
    );
  };

  const handleLoadMoreTicketsMobile = () => {
    setPageMobile(pageMobile + 1);

    mutate(
      [partialUrl, 1],
      async () => {
        setLoadingMoreItems(true);

        const response = await authClient.get(
          `${partialUrl}?page=${pageMobile}&limit=10`
        );

        const last_page = response.data?.meta?.last_page;

        if (pageMobile + 1 === last_page) {
          setHasMoreItems(false);
        }

        setLoadingMoreItems(false);

        const mapEvents = response.data.data.map((event: Event) => ({
          ...event,
          isOpen: false,
        }));

        let newMapEvents = data?.data;

        const mobile = data?.data;

        if (mobile) {
          newMapEvents = [...mobile, ...mapEvents];
        }

        return {
          meta: response.data.meta,
          data: newMapEvents,
        };
      },
      false
    );
  };

  return {
    data,
    page,
    loadingMoreItems,
    hasMoreItems,
    onChange,
    handleOpenDropTicketEventMobile,
    handleLoadMoreTicketsMobile,
  };
}
