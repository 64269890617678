import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import QRCode from "react-qr-code";

import Slider from "react-slick";

import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { RiCloseCircleLine } from "react-icons/ri";

import { useEventSlider } from "services/Hook/Event";

import { useCategories } from "services/Hook/Event/Category";

import { useDisclosure } from "hooks/useDisclosure";

import { Categories as CategoryInteface, Events } from "types";

import { Colors } from "constants/colors";

import {
  Container,
  ContainerItems,
  Item,
  CardArrowLeft,
  CardArrowRight,
  Dot,
  ModalStyled,
  ContainerModal,
  Center,
} from "./styles";

import download_app from "assets/download-app.svg";
import selo_google_play from "assets/selo-google-play.jpg";
import selo_app_store from "assets/selo-app-store.jpg";

export function Category() {
  const { data: dataSliders } = useEventSlider<Events>();

  const { data } = useCategories<CategoryInteface>();

  const navigate = useNavigate();

  const slider = useRef<Slider>(null);

  const [active, setActive] = useState({ activeSlide: 0 });

  const [swiped, setSwiped] = useState(false);

  const { isModalVisible, handleCancel, handleOk, showModal } = useDisclosure();

  const handleBeforeChange = useCallback((_: number, next: number) => {
    setActive({ activeSlide: next });
  }, []);

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5.2,
    slidesToScroll: 5,
    centerMode: true,
    initialSlide: 0,
    beforeChange: handleBeforeChange,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 4.2,
          slidesToScroll: 5,
          arrows: false,
          dots: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 910,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 3.18,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 6.18,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 3.18,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          centerMode: false,
        },
      },
    ],
  };

  const next = useCallback(() => {
    slider.current?.slickNext();
  }, []);

  const previous = useCallback(() => {
    slider.current?.slickPrev();
  }, []);

  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const handleOnItemClick = useCallback(
    (e, route) => {
      if (swiped) {
        e.stopPropagation();
        e.preventDefault();
        setSwiped(false);
        return;
      }

      navigate(`/category/${route}`);
    },
    [navigate, swiped]
  );

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  if (data === undefined) {
    return (
      <Container>
        <ContainerItems>
          <Center>
            <Spin spinning indicator={antIcon} />
          </Center>
        </ContainerItems>
      </Container>
    );
  }

  return (
    <Container>
      <ContainerItems
        haveSliders={dataSliders && dataSliders?.data?.length > 0}
      >
        <div className="header">
          <h3>Categorias</h3>

          <nav>
            <CardArrowLeft onClick={previous}>
              <IoChevronBackOutline fontSize={19} color={Colors.secondary100} />
            </CardArrowLeft>

            {!!data?.data && (
              <div className="dots">
                {data?.data.map((dot, index) => {
                  return (
                    <Dot key={dot.id} active={active.activeSlide === index} />
                  );
                })}
              </div>
            )}

            <CardArrowRight onClick={next}>
              <IoChevronForwardOutline
                fontSize={19}
                color={Colors.secondary100}
              />
            </CardArrowRight>
          </nav>
        </div>

        <Slider {...settings} ref={slider} onSwipe={handleSwiped}>
          {data &&
            data?.data.map((category) => (
              <Item
                key={category.id}
                onClick={(e) => handleOnItemClick(e, category.id)}
              >
                <img className="image" src={category.thumb} alt="" />

                <div className="title">
                  <h4 className="name">{category.name}</h4>
                </div>
              </Item>
            ))}
        </Slider>

        <div className="img-download-app">
          <img
            onClick={showModal}
            src={download_app}
            width="159px"
            height="211px"
            alt=""
          />
        </div>
      </ContainerItems>

      <ModalStyled
        width={857}
        visible={isModalVisible}
        onOk={handleOk}
        style={{ borderRadius: "20px" }}
        onCancel={handleCancel}
        closeIcon={
          <RiCloseCircleLine
            style={{ marginTop: "14px", marginRight: "34px" }}
            size={43}
            color={Colors.black40}
          />
        }
        footer={null}
      >
        <ContainerModal>
          <div className="logo-qr-code">
            <QRCode size={190} value="https://eventx.com.br/download" />
          </div>

          <div className="content">
            <h2>App EventX</h2>

            <p>
              Escaneie o QR Code para fazer o download do nosso app direto em
              seu celular
            </p>
          </div>

          <div className="or">
            <div className="line"></div>
            <span>Ou</span>
            <div className="line"></div>
          </div>

          <div className="download-app">
            <h5>Faça o download clicando em umas das opções abaixo</h5>

            <div className="row">
              <a
                href="https://play.google.com/store/apps/details?id=com.mobile.eventx"
                aria-label="Baixe agora para Google Play!"
                target="_blank"
                rel="noreferrer"
              >
                <img src={selo_google_play} width={223} height={78} alt="" />
              </a>

              <a
                href="https://apps.apple.com/us/app/eventx-eventos-e-ingressos/id1584406908"
                aria-label="Baixe agora para Apple Store!"
                target="_blank"
                rel="noreferrer"
              >
                <img src={selo_app_store} width={223} height={78} alt="" />
              </a>
            </div>
          </div>
        </ContainerModal>
      </ModalStyled>
    </Container>
  );
}
