import styled from "styled-components";
import { Container as DefaultContainer } from "../styles";
import FirstSectionBg from "assets/background_faq.svg";
import { Colors } from "constants/colors";

export const Section = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 11.26rem;

  padding-left: 80px;
  padding-right: 80px;

  @media (max-width: 1024px) {
    padding-top: 5.26rem;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (max-width: 344px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  display: flex;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .heading {
    color: ${(props) => props.theme.colors.white};
    width: 60%;
    margin: 0 auto;
    height: 100%;

    @media (max-width: 1024px) {
      width: 100%;
    }

    h2 {
      font: ${(props) => props.theme.text.h1_semibold_cairo};
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 2.34rem;
      text-align: center;
      color: ${Colors.white};
      line-height: 80px;
      margin-bottom: 0px !important;

      @media (max-width: 850px) {
        font-size: 1.34rem;
      }
    }

    h4 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      text-align: center;
      color: ${Colors.white};
      margin-top: 0px !important;

      @media (max-width: 850px) {
        font-size: 0.9rem;
      }
    }
  }
`;

export const Container = styled(DefaultContainer)`
  &.first-container {
    background: url(${FirstSectionBg});
    background-size: cover;
    background-repeat: no-repeat;
    height: 640px;
  }
`;
