import styled from "styled-components";
import { Colors } from "constants/colors";

import {
  Container as DefaultContainer,
  Section as DefaultSection,
} from "../styles";

import { Collapse as AntdCollapse, Button } from "antd";

export const Section = styled(DefaultSection)`
  display: flex;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .tag-and-share {
    display: flex;

    .tag-elements {
      display: flex;
      width: 100%;
      align-self: flex-start;
      padding: 0 8%;
    }
  }

  @media screen and (max-width: 475px) {
    .tag-and-share {
      .tag-elements {
        padding: 0 5%;
      }
    }
  }
`;

export const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 0 15%;
  width: 100%; */
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: -80px;

  p {
    color: ${Colors.secondary70};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    font-family: "Poppins";
  }

  .share-btn {
    display: flex;
    align-self: center;
    margin-bottom: 18px;
    button {
      margin: 0 8px;
    }
  }
  .responsive-share {
    display: none;
    align-self: center;
    margin-bottom: 54px;
    button {
      margin: 0 2px;
    }
  }
  @media screen and (max-width: 475px) {
    bottom: -77px;

    .share-btn,
    .label-share {
      display: none;
    }
    .responsive-share {
      display: flex;
    }
  }
`;
interface ImageProps {
  bg: string;
  thumb: string;
}

export const Image = styled.div<ImageProps>`
  background: url(${(p) => p.bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  filter: drop-shadow(0px 10px 33px rgba(36, 16, 88, 0.15));
  box-shadow: 0px 15px 30px 0px #1219220d;
  position: relative;
  width: 85%;
  height: 470px;
  margin: 0 auto;

  border-radius: 1rem;
  margin-bottom: 24px;

  @media screen and (max-width: 850px) {
    padding: 40px;
    margin-top: 160px;
    height: 300px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 270px;
    height: 182px;
  }

  @media screen and (max-width: 475px) {
    padding: 40px 28px;
    width: 90%;
    margin-top: 290px;
    height: 152px;
  }
`;

export const Container = styled(DefaultContainer)`
  margin-top: -420px;

  @media screen and (max-width: 1024px) {
    &.first-container {
      height: fit-content;
    }
  }
`;

export const ShareButton = styled(Button)`
  width: 50px;
  height: 50px;
  margin: 0 5px;

  border-color: white;
  box-shadow: 0px 2px 10px rgba(36, 16, 88, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${Colors.primary100};
  }

  :hover {
    border-color: ${Colors.primary100};
  }
`;

export const Title = styled.div`
  text-align: left;
  width: 100%;
  margin-top: 28px;
  margin-bottom: 28px;
  padding: 0 8%;
  font: 600 2rem Poppins, "Poppins", sans-serif;
  color: ${Colors.secondary100};

  @media screen and (max-width: 475px) {
    padding: 0 5%;
  }
`;

export const ActionButton = styled.button`
  width: 150px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 14px;
  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  font: 700 18px Cairo, "Cairo", sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    background-color: ${Colors.primary100};
    font: 700 18px Cairo, sans-serif;
    color: #fff;
    outline: none;
  }
  svg {
    margin-right: 12px;
  }
  @media screen and (max-width: 1150px) {
    width: 100%;
  }
`;
export const FlexTitle = styled.div`
  text-align: left;
  width: 100%;
  margin-bottom: 4rem;

  .title {
    font: 600 4rem/6.4rem Poppins, "Poppins", sans-serif;
    color: ${Colors.tertiary100};
  }
`;

export const HourContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2em;

  svg,
  p {
    color: ${Colors.gray40};
    margin: 0 2px;
    margin-bottom: 1em;
  }
`;

export const Box = styled.div`
  width: 100%;

  .description {
    text-align: justify;

    color: ${Colors.gray60};
    font: 400 2.4rem/4.8rem, sans-serif;
  }
`;

export const Collapse = styled(AntdCollapse)`
  width: 100%;
  padding: 0;
  border-radius: 10px;
  background-color: #fff;
  .ant-collapse-item {
    border: 1px solid #bdb7cd;
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: #fff;
    &:last-child {
      border-radius: inherit;
    }
    .ant-collapse-header {
      text-align: left;
      font: 400 3.2rem/5.12rem Poppins, "Poppins", sans-serif;
      color: ${Colors.tertiary100};
      padding-top: 20px;
      padding-left: 32px !important;
      p {
        display: inline-block;
      }
    }
    .ant-collapse-content .ant-collapse-content-box {
      text-align: left;
      font: 400 2.4rem/4.8rem, sans-serif;
      padding: 32px;
      padding-top: 12px;
    }
  }
`;
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e7ee;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 28px;
  svg {
    width: 32px;
    height: 32px;
  }
`;
export const Header = styled.div`
  display: inline-flex;
  max-width: 90%;
  align-items: center;
`;
export const ContactIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e7ee;
  border-radius: 8px;
  width: 38px;
  height: 38px;
  margin-right: 20px;
  svg {
    width: 24px;
    height: 24px;
    color: ${Colors.tertiary100};
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
export const ContactUs = styled.div`
  border: 1px solid #e7e7ea;
  display: flex;
  align-items: center;
  align-self: center;
  padding: 20px 32px;
  border-radius: 8px;
  margin-top: 108px;
  p {
    font: 500 3.2rem/5.12rem Cairo, "Cairo", sans-serif;
    color: ${Colors.tertiary100};
  }
  a {
    font: 500 3.2rem/5.12rem Cairo, "Cairo", sans-serif;
    color: ${Colors.primary100};
    text-decoration: underline;
  }
  @media screen and (max-width: 600px) {
    margin-top: 64px;
  }
`;
