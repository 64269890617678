import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import { useCategories } from "services/Hook/Event/Category";

import { useSearchEvent } from "hooks/useSearchEvent";

import { useDisclosure } from "hooks/useDisclosure";

import { Category as CategoryItem } from "types";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import { Colors } from "constants/colors";

import {
  ButtonChangeCategory,
  CategoryBackground,
  Container,
  ContainerCategory,
  ContainerItems,
  ContainerMainItems,
  ContainerMainTitle,
  ContainerSearch,
  Center,
  ContainerMainPagination,
  ListSearchMobile,
  ListSearchWeb,
  FooterItems,
  ButtonMoreItems,
  ContainerMainPaginationMobile,
} from "./styles";

import { ListSearch } from "./ListSearch";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { ModalCategory } from "components/ModalCategory";
import { Pagination } from "components/Pagination";
import { EmptySearchComponent } from "components/EmptySearchComponent";
import { HeaderFilterEvent } from "components/HeaderFilterEvent";

import sync_alt from "assets/sync_alt.svg";

interface CategoriesInterface {
  data: CategoryItem[];
}

export default function Categories() {
  let { id } = useParams();

  const {
    eventsData,
    page,
    onChange,
    handleLoadMoreItemsMobile,
    hasMoreItems,
    loadingMoreItems,
  } = useSearchEvent();

  const { data } = useCategories<CategoriesInterface>();

  const { showModal, handleCancel, handleOk, isModalVisible } = useDisclosure();

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");
  const [categoryLocal, setCategoryLocal] = useState<CategoryItem>();

  useEffect(() => {
    function getCategory() {
      if (data?.data) {
        const category = data?.data.find(
          (category) => String(category.id) === id
        );

        setCategoryLocal(category);
      }
    }

    getCategory();
  }, [data?.data, id]);

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  return (
    <>
      <Header
        cart={[]}
        changeCart={null}
        quantityTotal={0}
        valueTotal={0}
        openLogin={null}
        openSteps={null}
        rate={0}
      />

      <>
        <Container>
          <ContainerItems>
            <>
              <div className="bread">
                <ListBreadcrumb
                  data={[
                    { name: "Home", link: "/" },
                    { name: "Categorias", link: "/" },
                    { name: categoryLocal?.name ?? "", link: "/" },
                  ]}
                />
              </div>
            </>
          </ContainerItems>
          <CategoryBackground
            image={categoryLocal?.cover ?? ""}
            imageMobile={categoryLocal?.cover_thumb ?? ""}
          >
            <ContainerCategory>
              <div className="all">
                <div>
                  <div className="logo-img">
                    {categoryLocal?.icon ? (
                      <img
                        src={categoryLocal?.icon}
                        alt="Logo"
                        width={33}
                        height={31}
                      />
                    ) : null}

                    <h3>{categoryLocal?.name ?? ""}</h3>
                  </div>

                  <h4>{categoryLocal?.description ?? ""}</h4>
                </div>

                <ButtonChangeCategory onClick={showModal}>
                  <img src={sync_alt} alt="Logo" width={18} height={18} />

                  <span>Alterar categoria</span>
                </ButtonChangeCategory>
              </div>
            </ContainerCategory>
          </CategoryBackground>

          <ContainerMainItems>
            <HeaderFilterEvent filters={["data", "price"]} />
          </ContainerMainItems>
        </Container>

        {eventsData?.data && eventsData?.data.length === 0 ? (
          <Container>
            <EmptySearchComponent />
          </Container>
        ) : null}

        {eventsData?.data === undefined ? (
          <Center>
            <Spin spinning indicator={antIcon} />
          </Center>
        ) : null}

        {eventsData?.data && eventsData?.data.length > 0 ? (
          <>
            <Container>
              <ContainerMainTitle>
                <h2 className="search-text">Encontre {categoryLocal?.name}</h2>
              </ContainerMainTitle>
            </Container>
            <ContainerSearch>
              <ListSearchWeb>
                <ListSearch
                  title={categoryLocal?.name ?? ""}
                  events={eventsData.data}
                  total={eventsData?.meta.total}
                />
              </ListSearchWeb>

              {loadingMoreItems ? (
                <Center>
                  <Spin spinning indicator={antIcon} />
                </Center>
              ) : null}

              <ListSearchMobile>
                <ListSearch
                  title={categoryLocal?.name ?? ""}
                  events={eventsData?.data}
                  total={eventsData?.meta.total}
                />
              </ListSearchMobile>

              {eventsData?.data && eventsData?.data.length > 0 ? (
                <ContainerMainPaginationMobile>
                  <FooterItems>
                    <h3>
                      Mostrando {eventsData?.data.length} de{" "}
                      {eventsData?.meta.total}
                    </h3>

                    {hasMoreItems ? (
                      <ButtonMoreItems
                        disabled={!hasMoreItems}
                        onClick={handleLoadMoreItemsMobile}
                      >
                        <span>Carregar mais</span>
                      </ButtonMoreItems>
                    ) : null}
                  </FooterItems>
                </ContainerMainPaginationMobile>
              ) : null}

              <ContainerMainPagination>
                <Pagination
                  totalCountOfRegisters={eventsData?.meta.total}
                  currentPage={page}
                  onPageChange={onChange}
                  last_page={eventsData?.meta.last_page}
                  registersPerPage={10}
                />
              </ContainerMainPagination>
            </ContainerSearch>
          </>
        ) : null}

        <ModalCategory
          data={data}
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          handleOk={handleOk}
        />
      </>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
