import styled from "styled-components";
import { Layout, Button, Tabs } from "antd";
import { Colors } from "constants/colors";

const { Header } = Layout;

export const StyledHeader = styled(Header)`
  position: absolute;
  z-index: 99;
  width: 100%;
  max-width: 1366px;
  margin: 30px auto;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 0 84px;

  @media screen and (max-width: 1366px) {
    padding: 0 34px;
  }
`;

export const Logo = styled.img`
  float: left;
  width: auto;
  height: 70px;
  cursor: pointer;
  padding-left: 4px;

  @media screen and (max-width: 800px) {
    width: 150px;
    height: auto;
  }
`;

export const MenuLinks = styled.div`
  a {
    margin-right: 70px;
    font: 700 26px Cairo, sans-serif;
    color: ${(props) => props.theme.colors.white};
    :last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const ButtonDrawer = styled(Button)`
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.white};
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: none;
  :hover,
  :focus,
  :active {
    background-color: transparent;
  }
  svg {
    font-size: 32px;
  }
  @media screen and (max-width: 1200px) {
    display: block;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export const Section = styled.div`
  width: 100%;
  width: 100%;

  max-width: 1366px;
`;

export const FluxSection = styled.div`
  width: 100%;
  width: 100%;
  margin: 0 auto;
`;

export const Container = styled.div`
  width: 100%;

  position: relative;

  .odd-section,
  .even-section {
    background-color: white;
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    .description {
      @media (max-width: 440px) {
        padding: 1rem;
        padding-top: 2rem;
      }

      .create-event-btn {
        margin: 0;
        margin-bottom: 3em;
        display: none;
        justify-content: center;

        a {
          margin-top: 0;
          width: 100%;
        }
      }

      h2 {
        color: ${Colors.primary100};

        @media (max-width: 440px) {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;

          color: ${Colors.primary100};
        }
      }
    }
  }

  .odd-section {
    margin: 0;
  }
  .odd-section .description {
    padding-right: 80px;
  }

  .even-section {
    margin-right: 0;
  }

  .even-section .description {
    padding-left: 80px;
    padding-right: 0;
  }

  @media screen and (max-width: 1024px) {
    .odd-section:nth-child(1) {
      margin-top: 2em;

      .description {
        margin-top: 3em;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .odd-section {
      flex-direction: column-reverse;

      .description {
        padding: 6rem;
        padding-top: 10rem;

        @media (max-width: 440px) {
          padding: 1rem;
          padding-top: 2rem;
        }
      }
    }

    .even-section {
      flex-direction: column;

      .description {
        padding: 6rem;

        @media (max-width: 440px) {
          padding: 1rem;
          padding-top: 2rem;
        }
      }
    }

    .odd-section:nth-child(1) {
      margin-top: 0;
      .description {
        margin-top: 0;
      }
    }

    .odd-section,
    .even-section {
      .description {
        .create-event-btn {
          display: flex;
          @media screen and (max-width: 350px) {
            margin: 0;
            margin-bottom: 3em;
          }
        }
      }
    }
  }
`;

export const GlobalTitle = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 2.43rem;
  margin-bottom: 0px;

  color: ${Colors.secondary100};

  @media (max-width: 440px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
  }
`;

interface SpaceProps {
  size: string | number;
}

export const Space = styled.div<SpaceProps>`
  height: ${(props) => props.size};
`;

export const ActionButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: fit-content;
  height: 55px;
  margin-top: 40px;

  border: 2px solid ${(props) => props.theme.colors.pink};
  border-radius: 14px;

  background-color: ${(props) => props.theme.colors.pink};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);

  font: 700 18px Cairo, sans-serif;
  color: #fff;

  padding: 0 30px;
  transition: 0.25s;

  :hover,
  :active,
  :focus {
    border: 2px solid;
    background-color: ${(props) => props.theme.colors.pink};
    font: 700 18px Cairo, sans-serif;
    color: #fff;
  }
  svg {
    margin-right: 12px;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;
export const InvisibleTab = styled(Tabs)`
  .ant-tabs-nav {
    display: none;
  }
`;
export const VisibleTab = styled(Tabs)`
  width: 100%;
  .ant-tabs-nav {
    display: flex;
  }

  .ant-tabs-tab {
    font: ${(p) => p.theme.text.h4_poppins};
    color: ${(props) => props.theme.colors.gray70};
    padding: 3px 0;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font: ${(p) => p.theme.text.h4_semibold_poppins} !important;
    color: ${(props) => props.theme.colors.pink};
    text-shadow: 0 0 0.25px ${(props) => props.theme.colors.pink};
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: ${(props) => props.theme.colors.pink};
    pointer-events: none;
    border-radius: 50px;
  }
`;
