import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  margin: 0 auto;
`;

interface ContainerItemsProps {
  hasOne: boolean;
}

export const ContainerItems = styled.div<ContainerItemsProps>`
  max-width: 1150px;
  width: 100%;

  /* margin-top: 140px; */
  z-index: 2;

  div.content {
    display: flex;
    width: 100%;
    /* height: 685px; */

    height: 34.03rem;

    img {
      width: 100%;
      margin-top: 120px;
      /* height: 455px; */
      height: 30.03rem;
      object-fit: cover;

      @media (max-width: 800px) {
        margin-top: 0px;
      }

      border-radius: 14px !important;
    }
  }

  @media (max-width: 1160px) {
    padding: 0px 28px 0px 28px;
  }

  @media (max-width: 800px) {
    margin-top: 90px;
    width: 100%;

    padding: 0px 0px 0px 20px;

    div.content {
      width: 100%;
      height: 545px;

      img {
        width: 100%;
        border-radius: 10px !important;
        object-fit: scale-down;
      }
    }

    img {
      /* width: 100%; */

      max-height: 163px;
      border-radius: 13px;
    }

    > div + div {
      margin-right: -10px;
    }

    div.content {
      max-width: calc(100% - 15px);
      height: 127px;
      margin-left: ${(props) => (props.hasOne ? "30px" : "0px")};
      margin-right: 0px;

      img.image {
        /* max-width: calc(100% - 100px);*/
        border-radius: 14px;
        object-fit: fill;
      }
    }

    /* div.content + div.content {
      margin-left: 30px;
    } */
  }
`;

export const Content = styled.div`
  min-height: 400px;
  background-color: red;
`;

export const CardArrowLeft = styled.button`
  display: flex;
  align-items: center !important;
  justify-content: center !important;

  z-index: 98;
  cursor: pointer;
  /* height: 50px;
  width: 50px; */

  height: 2.34rem;
  width: 2.34rem;
  border: 0;
  border-radius: 50%;
  /* box-shadow: -10px 10px 33px rgba(108, 93, 211, 0.25); */
  background-color: ${Colors.white};
  position: absolute;
  top: 58%;

  display: block;
  left: -20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
`;

export const CardArrowRight = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 98;
  /* height: 50px;
  width: 50px; */
  height: 2.34rem;
  width: 2.34rem;
  border-radius: 50%;
  /* box-shadow: 10px 10px 33px rgba(108, 93, 211, 0.25); */
  background-color: ${Colors.white};
  position: absolute;
  top: 58%;
  border: 0;
  display: block;
  right: -20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
`;

export const Center = styled.div`
  width: 100%;
  height: 400px;

  margin-top: 170px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    margin-top: 0px;
    height: 90px;
  }
`;
