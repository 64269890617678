import styled from "styled-components";
import {
  Container as DefaultContainer,
  Section as DefaultSection,
} from "../../EventDetail/styles";
import { Colors } from "constants/colors";

export const Section = styled(DefaultSection)`
  display: flex;
  .content {
    width: 80%;
    margin: 50px auto;
    margin-top: -120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .heading {
    color: ${Colors.white};
    .title {
      font: 600 2.2rem/4.32rem Cairo, sans-serif;
    }
    .subtitle {
      font: 400 1.2rem/2.12rem Open Sans, sans-serif;
    }
  }
  @media screen and (max-width: 450px) {
    .content {
      width: 90%;
      margin-top: -190px;
    }
  }
`;

export const CardQuestion = styled.div`
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  padding: 32px;
  border-radius: 1rem;
  box-shadow: 0px 15px 30px 0px #1219220d;
`;
export const CardQuestionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .card-title {
    font: 600 1.8rem/2.4rem Poppins, sans-serif;
    color: ${Colors.secondary100};
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-column-start: 1;

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

export const Container = styled(DefaultContainer)`
  @media screen and (max-width: 1024px) {
    &.first-container {
      height: fit-content;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e7ee;
  border-radius: 15px;
  width: 72px;
  height: 72px;
  margin-right: 24px;
  padding: 16px;
  svg {
    width: 100%;
    height: 100%;
    color: ${Colors.secondary100};
  }
`;
export const CardQuestionBody = styled.div`
  text-align: left;
  padding-bottom: 8px;
  color: ${Colors.gray60};
  font: 400 1.2rem/2.16rem Poppins, sans-serif;
`;
