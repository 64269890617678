import styled from "styled-components";
import { Button, Modal as AntdModal } from "antd";
import { Colors } from "constants/colors";

export const StyledModal = styled(AntdModal)`
  // .ant-modal-mask class -> global style
  width: calc(100vw - 300px) !important;
  top: 30px;
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px;
  }
  .custom-close-icon {
    color: #11142d;
    opacity: 0.3;
    font-size: 32px;
  }
  .ant-modal-content {
    width: 100%;
    background-color: ${Colors.white};
    padding: 32px;
    border-radius: 20px;
    .ant-modal-close-x {
      color: ${Colors.black};
    }
    .ant-modal-header {
      .ant-modal-title {
        color: ${Colors.black};
        text-align: center;
        P {
          margin-top: 32px;
        }
      }
      background-color: ${Colors.white};
    }
    .ant-modal-body {
      padding: 30px 22px;
      color: ${Colors.black};
    }
    .ant-modal-footer {
      border-top: none;
      display: flex;
      justify-content: center;
      button {
        margin: 0 12px;
      }
    }
  }
  .title {
    text-align: center;
    font: 600 4rem/160%, sans-serif;
    color: ${Colors.secondary100};
    margin-bottom: 6rem;
  }
  @media screen and (max-width: 884px) {
    width: 90% !important;
  }
  @media screen and (max-width: 450px) {
    .ant-modal-content {
      padding: 32px 16px;
    }
    .ant-modal-body {
      padding: 16px !important;
    }
  }
`;

export const CardForm = styled.div`
  border-radius: 2rem;
  .heading {
    .title {
      font: 600 2.6rem/160%, sans-serif;
      color: ${Colors.secondary100};
    }
    .description {
      font: 600 2.6rem/160%, sans-serif;
      color: #585b6c;
    }
  }
  .form-section {
    .title {
      font: 600 2.6rem/160%, sans-serif;
      color: ${Colors.secondary100};
      width: 100%;
      text-align: left;
      margin-bottom: 0;
    }
    .tickets-container {
      padding: 3rem 4rem;
      border: 1px solid #e7e7ea;
      border-radius: 20px;
      margin: 24px 0;
    }
  }
  @media screen and (max-width: 580px){
    .heading .tickets-container {
      padding: 10px 40px;
    }
  }
`;
export const TicketContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  .icon-container {
    display: flex;
    margin-right: 64px;
    svg {
      margin-top: 8px;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      color: ${Colors.gray10};
    }
    .name-and-price {
      text-align: start;

      .name {
        font-weight: 500;
        font-size: 16px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${Colors.secondary100};
      }
      .event {
        font: 400 2rem, sans-serif;
        margin: 5px 0;
        color: #707281;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .name-divider {
        width: 4px;
        margin: 0 12px;
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        color: lightgray;
      }
      .heading-info {
        display: flex;
        flex-direction: column;
        &.has-coupon {
          flex-direction: row;
          align-items: center;
        }
      }

      .price {
        font-weight: 600;
        font-size: 14px;

        color: ${Colors.black};
      }
      .coupon {
        display: flex;
        align-items: center;

        svg {
          width: 2.2rem;
          height: 2.2rem;
          margin: 0;
          margin-right: 4px;
          color: #66588a;
        }
        p {
          font: 700 2rem, sans-serif;
          color: #66588a;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    .icon-container {
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;
      margin-right: 0;
      .name-and-price {
        text-align: center;
        .coupon {
          justify-content: center;
        }
        .heading-info {
          .name-divider {
            display: none;
          }
          &.has-coupon {
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }

  @media screen and (max-width: 475px) {
    .name-and-price {
      p:first-child {
        font-size: 20px;

        color: ${Colors.secondary100};
      }

      p:last-child {
        font-size: 16px;

        color: ${Colors.black};
      }
    }
  }
`;
export const ActionButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-input {
    width: 8rem;
    height: 6rem;
    padding: 4px 8px;
    border: none;
    background-color: #f6f6f6;
    &:focus {
      box-shadow: none;
    }
  }
  button {
    width: 6rem;
    height: 6rem;
    flex-shrink: 0;
    border-radius: 1.4rem;
    background-color: ${Colors.primary100};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    padding: 0 1rem;
    border: none;
    cursor: pointer;
    margin-left: 8px;
    :hover,
    :active,
    :focus {
      background-color: ${Colors.primary100};
      color: #fff;
      outline: none;
    }
    &.add {
      background-color: ${Colors.green10};
    }
    &.remove {
      background-color: ${Colors.red10};
    }
  }
`;
export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 64px;
`;
export const OkButton = styled(Button)`
  min-width: 24rem;
  height: 8rem;
  border-radius: 8px;
  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: 0;
  :hover,
  :active,
  :focus {
    background-color: ${Colors.primary100};
    color: #fff;
    border-color: ${Colors.tertiary100};
  }
`;
export const SecondaryButton = styled(Button)`
  min-width: 24rem;
  height: 8rem;
  border-radius: 8px;
  background-color: #fbe5ed;
  color: ${Colors.primary100};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: 0;
  :hover,
  :active,
  :focus {
    background-color: #fbe5ed;
    color: ${Colors.primary100};
    border-color: ${Colors.tertiary100};
  }
  :disabled {
    background-color: #e7e7ea;
    color: #b8b9c0;
  }
`;
