import { AuthProvider } from "contexts/AuthContext";
import { IntegrationsProvider } from "contexts/IntegrationsContext";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import theme from "./styles/theme";
import RoutesPage from "./routes";

import { SearchCityAndStateProvider } from "contexts/SearchCityAndState";
import { TabsTicketsAndPurchasesProvider } from "contexts/TabsTicketsAndPurchasesContext";
import GlobalStyle from "styles/GlobalStyle";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />

      <ThemeProvider theme={theme}>
        <AuthProvider>
          <SearchCityAndStateProvider>
            <IntegrationsProvider>
              <TabsTicketsAndPurchasesProvider>
                <RoutesPage />
              </TabsTicketsAndPurchasesProvider>
            </IntegrationsProvider>
          </SearchCityAndStateProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
