import styled from "styled-components";
import {
  Container as DefaultContainer,
  Section as DefaultSection,
} from "../../EventDetail/styles";

import FirstSectionBg from "assets/background_faq.svg";
import { Colors } from "constants/colors";

export const Section = styled(DefaultSection)`
  padding-top: 210px;
  padding-left: 80px;
  padding-right: 80px;
  display: flex;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .heading {
    color: ${Colors.white};
    .title {
      font: 600 2.2rem/2.32rem Cairo, sans-serif;
    }
    .subtitle {
      font: 400 1.2rem/1.12rem Open Sans, sans-serif;
    }
  }
`;

export const Container = styled(DefaultContainer)`
  &.first-container {
    background: url(${FirstSectionBg});
    background-size: cover;
    background-repeat: no-repeat;
    height: 640px;
  }
`;
