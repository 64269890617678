import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ListCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(12rem, 1fr));
  column-gap: 0.45rem;
  row-gap: 1.5rem;

  @media (max-width: 820px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 727px) {
    grid-template-columns: repeat(1, 100%);
    row-gap: 0px;
    column-gap: 0px;
  }
`;

export const CardEventMobile = styled.div`
  width: 100%;
  min-height: 113px;
  margin-top: 15px;
  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  box-shadow: 0px 5px 15px rgba(108, 93, 211, 0.15);
  border-radius: 5px;
  padding: 5px 3px 9px 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  img.productive {
    border-radius: 8px;
    height: 96.35px;
    width: 50%;
    object-fit: cover;
  }
`;

export const ContentCardEventMobile = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  margin-left: 13px;

  div.head {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  div.content {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 21px;

      color: ${Colors.secondary100};

      @media (max-width: 800px) {
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 2px;
      }
    }

    div.date {
      display: flex;
      align-items: center;

      span {
        color: ${Colors.primary100};
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;

        @media (max-width: 800px) {
          font-size: 11px;
          line-height: 21px;
          font-weight: normal;
          font-size: 8px;
          line-height: 11px;
        }
      }

      a {
        color: ${Colors.primary100};
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
      }

      div.city {
        margin-left: 28.5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 800px) {
          display: none;
        }

        span {
          margin-left: 7px;
        }
      }
    }
  }

  div.badge {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    margin-top: 20px;

    @media (max-width: 800px) {
      margin-top: 15px;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;

      color: ${Colors.secondary100};
      padding: 0.3em 0.8em;
      border-radius: 14px;
      background-color: ${Colors.secondary10};

      @media (max-width: 800px) {
        font-weight: 600;
        font-size: 8px;
        line-height: 11px;
      }
    }
  }

  div.description {
    margin-top: 18px;
    display: flex;
    flex-direction: column;

    span {
      font-weight: 600;
      font-size: 7px;
      line-height: 10px;
      color: ${Colors.gray};
    }

    strong {
      font-weight: 600;
      font-size: 8px;
      line-height: 11px;
      color: ${Colors.secondary100};
    }
  }
`;

export const ContainerEmptyEventMobile = styled.div`
  padding-top: 45px;
  padding-bottom: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: ${Colors.gray10};
    opacity: 0.75;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    color: ${Colors.gray20};
  }
`;

export const ButtonEmptyEventMobile = styled.button`
  cursor: pointer;
  border: 0;
  width: 100%;
  height: 38px;
  background: ${Colors.primary10};
  border-radius: 8px;
  margin-top: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: ${Colors.primary100};
    margin-left: 10px;
  }
`;
