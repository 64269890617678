import { forwardRef, ForwardRefRenderFunction } from "react";

import { FiMapPin } from "react-icons/fi";
import { RiCalendar2Line } from "react-icons/ri";

import { Colors } from "constants/colors";

import {
  BackgroundTicket,
  Container,
  ContainerContentModalTicket,
  DivBorder,
  DivLogo,
} from "./styles";

import event_ticket from "assets/event-ticket.svg";
import QRCode from "react-qr-code";

interface PurchaseInteface {
  id: number;
  purchase_code: number;
}

interface Event {
  id: string;
  name: string;
  thumb: string;
  photo: string;
  address_name: string;
  address_street: string;
  begin_date: string;
  formattedDate: string;
  formattedHour: string;
}

interface Ticket {
  id: string;
  holder_name: string;
  holder_email: string;
  event: Event;
  purchase: PurchaseInteface;
  isOpen: boolean;
  formattedDate: string;
  ticket: {
    name: string;
  };
}

interface TicketForPrintComponentProps {
  selectedTicket?: Ticket;
}

export const TicketForPrintComponent: ForwardRefRenderFunction<
  HTMLDivElement,
  TicketForPrintComponentProps
> = ({ selectedTicket, ...rest }, ref) => {
  return (
    <Container ref={ref} {...rest} id="print-ticket">
      <BackgroundTicket>
        {selectedTicket?.event?.thumb ? (
          <img src={selectedTicket.event.thumb} alt="Logo" loading="eager" />
        ) : null}

        <div className="content">
          <h2 className="title">{selectedTicket?.event?.name}</h2>

          <div className="text">
            <FiMapPin size={18} color={Colors.white} />
            <h4>
              {selectedTicket?.event?.address_name}{" "}
              {selectedTicket?.event?.address_street
                ? `| ${selectedTicket?.event?.address_street}`
                : ""}
            </h4>
          </div>

          <div className="text-last">
            <RiCalendar2Line size={18} color={Colors.white} />
            <p>
              {selectedTicket?.event?.formattedDate},{" "}
              {selectedTicket?.event?.formattedHour}
            </p>
          </div>
        </div>
      </BackgroundTicket>

      <DivBorder>
        <ContainerContentModalTicket>
          <div className="data">
            <h3 className="data">Dados do ingresso 1</h3>

            <div className="table">
              <h5>Nome do comprador</h5>
              <p>{selectedTicket?.holder_name}</p>
            </div>

            <div className="table">
              <h5>Ingresso</h5>
              <p>{selectedTicket?.ticket?.name}</p>
            </div>

            <div className="table">
              <h5>Código da compra</h5>
              <p>{selectedTicket?.purchase?.purchase_code}</p>
            </div>
          </div>

          <div className="qr">
            {/* <img
              src={`https://qr-code-generator-r4kvm.ondigitalocean.app/?data=${selectedTicket?.id}&size=6`}
              alt="Qrcode"
              width="201px"
              height="201px"
            /> */}

            <QRCode size={150} value={selectedTicket?.id || ""} />

            <h1>{selectedTicket?.id}</h1>
          </div>
        </ContainerContentModalTicket>

        <DivLogo>
          <div className="all-content">
            <img className="eventx-logo-ticket" src={event_ticket} alt="Logo" />
            <span>A VIDA É UM EVENTO!</span>
          </div>
        </DivLogo>
      </DivBorder>
    </Container>
  );
};

export const TicketForPrint = forwardRef(TicketForPrintComponent);
