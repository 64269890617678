import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerItems = styled.div`
  max-width: 1300px;
  width: 100%;

  display: flex;
  flex-direction: row;

  margin-top: 170px;

  @media (max-width: 800px) {
    margin-top: 90px;
    margin-bottom: 50px;
  }

  justify-content: center;
`;

export const CardProfile = styled.div`
  max-width: 1180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-left: 40px;

  border: 1px solid ${Colors.black10};
  padding: 40px 60px;
  box-shadow: 0px 4px 10px rgba(18, 25, 34, 0.05);
  border-radius: 20px;

  h2 {
    color: ${Colors.black100};
    font-weight: bold;
    font-size: 32px;
    line-height: 160%;
  }

  div.full-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const InputsForm = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  input {
    width: 96%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid ${Colors.secondary20};
    color: ${Colors.secondary70};
    font-size: 20px;
    line-height: 160%;
    padding: 0px 0px 4px 23px;
  }

  input.w-100 {
    width: 100vh;
  }

  div.all-input {
    margin-top: 20px;
  }
`;

export const ButtonEdit = styled.button`
  display: flex;
  cursor: pointer;
  border: 0;
  align-items: center;
  justify-content: center;

  border-radius: 14px;
  width: 262px;
  height: 60px;
  background-color: ${Colors.primary10};
  color: ${Colors.primary100};
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-left: 13px;
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;
  }
`;

export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div.all-input {
    width: 94%;
  }
`;
