import styled from "styled-components";
import {
  Container as DefaultContainer,
  Section as DefaultSection,
} from "../../EventDetail/styles";
import { Colors } from "constants/colors";

export const Section = styled(DefaultSection)`
  display: flex;
  .content {
    width: 80%;
    margin: 50px auto;
    margin-top: -120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  @media screen and (max-width: 450px) {
    .content {
      width: 90%;
      margin-top: -100px;
    }
  }
`;

export const CardQuestion = styled.div`
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  padding: 1.2rem;
  border-radius: 1rem;
  box-shadow: 0px 15px 30px 0px #1219220d;
  .title {
    font: 600 2.2rem/4.32rem Poppins, sans-serif;
    color: ${Colors.secondary100};
  }
`;
export const CardQuestionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .card-title {
    font: 700 1.2rem/2.12rem Poppins, sans-serif;
    color: ${Colors.secondary100};
    padding-top: 15px;
  }
`;
export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-column-start: 1;
  @media screen and (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

export const Container = styled(DefaultContainer)`
  @media screen and (max-width: 1024px) {
    &.first-container {
      height: fit-content;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e7ee;
  border-radius: 15px;
  width: 3rem;
  height: 3rem;
  margin-right: 1.2rem;
  svg {
    width: 1.8rem;
    height: 1.8rem;
    color: ${Colors.secondary100};
  }
`;
export const CardQuestionBody = styled.div`
  text-align: left;
  color: ${Colors.gray10};
  font: 400 1.1rem/140% Open Sans, sans-serif;
`;
