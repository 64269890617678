import React from "react";
import { Link } from "react-router-dom";

import { Skeleton, Divider } from "antd";

import { RiTimer2Line } from "react-icons/ri";

import {
  Container,
  Section,
  FlexTitle,
  QuestionsContainer,
  HourContainer,
  Box,
} from "./styles";

import { Breadcrumb } from "components/Breadcrumb";

interface Props {
  article: any;
  isLoading: boolean;
}

const HelpArticle: React.FC<Props> = ({ article, isLoading }) => {
  const handleDate = (date: any) => {
    date = new Date(date);

    var day = ("0" + date.getDate()).slice(-2);
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();

    return day + "/" + month + "/" + year;
  };
  const indexToCategory = [
    "Compras",
    "Pagamento",
    "Reembolso",
    "Ingressos",
    "Eventos",
    "Minha conta",
  ];

  return (
    <Container className="first-container">
      <Section>
        {/* <Slide bottom cascade> */}
        <div className="content">
          <QuestionsContainer>
            <div className="breadcrumb">
              {!isLoading && (
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>
                    <Link to="/faq">Início</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/faq">
                      {indexToCategory[article?.category - 1]}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{article?.title}</Breadcrumb.Item>
                </Breadcrumb>
              )}
            </div>

            <Box>
              <FlexTitle>
                {isLoading ? (
                  <Skeleton active paragraph={{ rows: 0 }} />
                ) : (
                  <p className="title">{article?.title}</p>
                )}
                <HourContainer>
                  <RiTimer2Line />
                  {isLoading ? (
                    <Skeleton active paragraph={{ rows: 0 }} />
                  ) : (
                    <p>{handleDate(article?.updated_at)} - Atualizado</p>
                  )}
                </HourContainer>
                <Divider />
              </FlexTitle>

              {isLoading ? (
                <Skeleton active paragraph={{ rows: 15 }} />
              ) : (
                <div className="description">{article?.description}</div>
              )}
            </Box>
          </QuestionsContainer>
        </div>
        {/* </Slide> */}
      </Section>
    </Container>
  );
};

export default HelpArticle;
