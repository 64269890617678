import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";
// import { setCookie, destroyCookie, parseCookies } from "nookies";
import { authClient } from "services/authClient";
// import { HeadersDefaults } from "axios";

export type User = {
  id: number;
  email: string;
  name: string;
  last_name: string;
  avatar: string;
  cell_phone: string;
  birthdate: string;
  document: string;
  gender: string;
  type_document: string;
  type_user: number;
};

type SignInCredentials = {
  email: string;
  password: string;
  stayOnPage?: boolean;
};

type AuthContextData = {
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  hydratationUser(): void;
  user: User | undefined;
  isAuthenticated: boolean;
  hasOrganizer: boolean;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  let navigate = useNavigate();

  const [user, setUser] = useState<User | undefined>();

  const signOut = useCallback(() => {
    // destroyCookie(undefined, "Eventx.token");
    // destroyCookie(undefined, "Eventx.userId");
    localStorage.removeItem("@ventX-user_token");
    localStorage.removeItem("userId");
    localStorage.removeItem("hasOrganizer");

    setUser(undefined);
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    async function getUser() {
      // const { "Eventx.token": token } = parseCookies();
      // const { "Eventx.userId": userId } = parseCookies();

      const token = localStorage.getItem("@ventX-user_token");
      const userId = localStorage.getItem("userId");

      if (token) {
        try {
          const { data } = await authClient.get(`/users/show/${userId}`);

          if (data) {
            setUser({
              id: data.id,
              name: data.name,
              last_name: data.last_name,
              avatar: data.avatar,
              cell_phone: data.cell_phone,
              email: data.email,
              birthdate: data.birthdate,
              document: data.document,
              gender: data.gender,
              type_document: data.type_document,
              type_user: data.type_user,
            });
          }
        } catch (err) {
          signOut();
        }
      }
    }

    getUser();
  }, [signOut]);

  async function hydratationUser() {
    // const { "Eventx.token": token } = parseCookies();
    // const { "Eventx.userId": userId } = parseCookies();
    const token = localStorage.getItem("@ventX-user_token");
    const userId = localStorage.getItem("userId");

    if (token) {
      try {
        const { data } = await authClient.get(`/users/show/${userId}`);

        if (data) {
          setUser({
            id: data.id,
            name: data.name,
            last_name: data.last_name,
            avatar: data.avatar,
            cell_phone: data.cell_phone,
            email: data.email,
            birthdate: data.birthdate,
            document: data.document,
            gender: data.gender,
            type_document: data.type_document,
            type_user: data.type_user,
          });
        }
      } catch (err) {
        signOut();
      }
    }
  }

  async function signIn({ email, password, stayOnPage }: SignInCredentials) {
    const response = await api.post("/login", {
      email,
      password,
    });

    const { token, user } = response.data;

    if (user.organizer !== null && user.organizer.length > 0) {
      localStorage.setItem("hasOrganizer", user.organizer[0].id);
    }

    localStorage.setItem("userId", user.id);

    localStorage.setItem("@ventX-user_token", token);

    authClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    setUser({
      id: user.id,
      name: user.name,
      last_name: user.last_name,
      email: user.email,
      avatar: user.avatar,
      cell_phone: user.cell_phone,
      birthdate: user.birthdate,
      document: user.document,
      gender: user.gender,
      type_document: user.type_document,
      type_user: user.type_user,
    });

    if (stayOnPage) {
      return;
    }

    navigate(-1);
  }

  const userHasOrganizer = localStorage.getItem("hasOrganizer");

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        user,
        isAuthenticated: !!user,
        hydratationUser,
        hasOrganizer: !!userHasOrganizer,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}
