import styled from "styled-components";

import { Collapse as AntdCollapse } from "antd";
import { Colors } from "constants/colors";

export const DefaultSection = styled.div`
  width: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 1366px;
`;

export const DefaultContainer = styled.div`
  width: 100%;
  position: relative;
  .odd-section,
  .even-section {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .description {
      .create-event-btn {
        margin: 0;
        margin-bottom: 3em;
        display: none;
        justify-content: center;
        a {
          margin-top: 0;
          width: 100%;
        }
      }
      h2 {
        color: ${(props) => Colors.primary100};
      }
    }
  }
  .odd-section {
    margin: 0;
  }
  .odd-section .description {
    padding-right: 80px;
  }
  .even-section {
    margin-right: 0;
  }
  .even-section .description {
    padding-left: 80px;
    padding-right: 0;
  }
  @media screen and (max-width: 1024px) {
    .odd-section:nth-child(1) {
      margin-top: 2em;
      .description {
        margin-top: 3em;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .odd-section {
      flex-direction: column-reverse;
      .description {
        padding: 6rem;
        padding-top: 10rem;
      }
    }
    .even-section {
      flex-direction: column;
      .description {
        padding: 6rem;
      }
    }
    .odd-section:nth-child(1) {
      margin-top: 0;
      .description {
        margin-top: 0;
      }
    }
    .odd-section,
    .even-section {
      .description {
        .create-event-btn {
          display: flex;
          @media screen and (max-width: 350px) {
            margin: 0;
            margin-bottom: 3em;
          }
        }
      }
    }
  }
`;

export const Section = styled(DefaultSection)`
  display: flex;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .heading {
    color: ${Colors.white};
    .title {
    }
    .subtitle {
    }
  }
  @media screen and (max-width: 450px) {
    .content {
      margin-top: -100px;
    }
  }
`;

export const QuestionsContainer = styled.div`
  background-color: #fff;
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 80px;
  border-radius: 2rem;
  box-shadow: 0px 15px 30px 0px #1219220d;
  margin-bottom: 32px;

  .breadcrumb {
    width: 100%;
    display: flex;
    margin-bottom: 3rem;
  }

  .title {
  }
  .icon-container {
    display: flex;
    width: fit-content;
    padding: 16px;
    border-radius: 20px;
    margin-right: 42px;
    background: #e9e7ee;

    svg {
      width: 25px;
      height: 25px;
    }
  }
  @media screen and (max-width: 800px) {
    padding: 40px;
  }
  @media screen and (max-width: 450px) {
    padding: 40px 28px;
    width: 90%;
  }
`;
export const Container = styled(DefaultContainer)`
  @media screen and (max-width: 1024px) {
    &.first-container {
      height: fit-content;
    }
  }
`;
export const ActionButton = styled.button`
  width: 150px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 14px;
  /* background-color: ${(props) => props.theme.colors.pink}; */
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  font: 700 18px Cairo, sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    /* background-color: ${(props) => props.theme.colors.pink}; */
    font: 700 18px Cairo, sans-serif;
    color: #fff;
    outline: none;
  }
  svg {
    margin-right: 12px;
  }
  @media screen and (max-width: 1150px) {
    width: 100%;
  }
`;
export const FlexTitle = styled.div`
  text-align: left;
  width: 100%;
  margin-bottom: 4rem;

  .title {
    /* font: ${(props) => props.theme.text.h2_semibold_poppins};
    color: ${(props) => props.theme.colors.purple}; */
  }
`;

export const HourContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2em;

  svg,
  p {
    /* color: ${(p) => p.theme.colors.gray4}; */
    margin: 0 2px;
    margin-bottom: 1em;
  }
`;

export const Box = styled.div`
  width: 100%;

  .description {
    text-align: justify;

    /* color: ${(p) => p.theme.colors.gray70};
    font: ${(p) => p.theme.text.text_large_regular_open}; */
  }
`;

export const Collapse = styled(AntdCollapse)`
  width: 100%;
  padding: 0;
  border-radius: 10px;
  background-color: #fff;
  .ant-collapse-item {
    border: 1px solid #bdb7cd;
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: #fff;
    &:last-child {
      border-radius: inherit;
    }
    .ant-collapse-header {
      text-align: left;
      /* font: ${(props) => props.theme.text.h3_poppins};
      color: ${(props) => props.theme.colors.purple}; */
      padding-top: 20px;
      padding-left: 32px !important;
      p {
        display: inline-block;
      }
    }
    .ant-collapse-content .ant-collapse-content-box {
      text-align: left;
      /* font: ${(props) => props.theme.text.text_large_regular_open}; */
      padding: 32px;
      padding-top: 12px;
    }
  }
`;
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e7ee;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 28px;
  svg {
    width: 32px;
    height: 32px;
  }
`;
export const Header = styled.div`
  display: inline-flex;
  max-width: 90%;
  align-items: center;
`;
export const ContactIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e7ee;
  border-radius: 8px;
  width: 38px;
  height: 38px;
  margin-right: 20px;
  svg {
    width: 24px;
    height: 24px;
    /* color: ${(props) => props.theme.colors.purple}; */
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
export const ContactUs = styled.div`
  border: 1px solid #e7e7ea;
  display: flex;
  align-items: center;
  align-self: center;
  padding: 20px 32px;
  border-radius: 8px;
  margin-top: 108px;
  p {
    /* font: ${(props) => props.theme.text.h3_semibold_cairo};
    color: ${(props) => props.theme.colors.purple}; */
  }
  a {
    /* font: ${(props) => props.theme.text.h3_semibold_cairo};
    color: ${(props) => props.theme.colors.pink}; */
    text-decoration: underline;
  }
  @media screen and (max-width: 600px) {
    margin-top: 64px;
  }
`;
