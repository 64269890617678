import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 3.4em;

  @media screen and (max-width: 1024px) {
    padding: 0 2em;
    h1,
    p {
      align-items: center;
      text-align: center;
    }
  }
`;

export const AllApps = styled.div`
  p {
    font: ${(props) => props.theme.text.text_medium_bold};
  }
  .img-wrapper {
    margin-top: 24px;
    img {
      margin-bottom: 16px;
      width: 200px;
      height: 60px;
    }
  }

  @media screen and (max-width: 600px) {
    img {
      max-width: 130px !important;
    }
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 60px;

  border: 2px solid ${Colors.primary100};
  border-radius: 8px;

  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);

  font: 700 18px Cairo, sans-serif;
  color: #fff;

  padding: 0 20px;
  transition: 0.25s;

  :hover,
  :active,
  :focus {
    border: 1.5px solid ${Colors.tertiary100};
    background-color: ${(props) => props.theme.colors.pink};
    font: 700 18px Cairo, sans-serif;
    color: #fff;
  }
  svg {
    margin-right: 12px;
  }
`;
