import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Tabs, Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import { useCategories } from "services/Hook/Event/Category";

import { Category as CategoryItem } from "types";

import Constants from "constants/Datas";
import { dataPrice, dataOptions } from "constants/searchOptions";
import { Colors } from "constants/colors";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  ButtonMoreItems,
  ContainerTabs,
  FooterItems,
  ContainerSearch,
  ContainerMainPagination,
  Center,
  ContainerSearchMobile,
  ContainerMainPaginationMobile,
} from "./styles";

import { ListSearch } from "./ListSearch";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { Pagination } from "components/Pagination";
import { EmptySearchComponent } from "components/EmptySearchComponent";
import { HeaderFilterEvent } from "components/HeaderFilterEvent";
import { useSearchEvent } from "hooks/useSearchEvent";

const { TabPane } = Tabs;

interface CategoriesInterface {
  data: CategoryItem[];
}

export default function Search() {
  const [searchParams] = useSearchParams();
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const address_city = searchParams.get("address_city") ?? "";

  const address_state = searchParams.get("address_state") ?? "";

  const { data } = useCategories<CategoriesInterface>();

  const mapDataCategories = useMemo(() => {
    return data?.data?.map((item) => ({
      option: item.name,
      value: item.id,
    }));
  }, [data?.data]);

  const name = searchParams.get("name") ?? "";

  const {
    eventsData,
    handleLoadMoreItemsMobile,
    hasMoreItems,
    loadingMoreItems,
    onChange,
    page,
  } = useSearchEvent();

  const [activeTab] = useState("1");

  const [valueSelected1, setValueSelected1] = useState({
    value: 0,
    label: "",
  });

  const [valueSelected2, setValueSelected2] = useState({
    value: 0,
    label: "",
  });

  const [valueSelected3, setValueSelected3] = useState({
    value: 0,
    label: "",
  });

  const [valueSelected4, setValueSelected4] = useState({
    value: "",
    label: "",
  });

  useEffect(() => {
    const address_state = searchParams.get("address_state");

    if (address_state) {
      const findState = Constants.States?.find(
        (value) => String(value.value) === address_state
      );

      if (!findState) {
        setValueSelected4({
          value: "",
          label: address_state,
        });
        return;
      }

      setValueSelected4({
        value: findState.value,
        label: findState.label,
      });
    }
  }, [mapDataCategories, searchParams]);

  useEffect(() => {
    const address_city = searchParams.get("address_city");

    if (address_city) {
      setValueSelected4({
        value: address_city,
        label: address_city,
      });
      return;
    }
  }, [mapDataCategories, searchParams]);

  useEffect(() => {
    const categoryId = searchParams.get("categories");
    if (categoryId) {
      const findCategory = mapDataCategories?.find(
        (value) => String(value.value) === categoryId
      );

      if (!findCategory) {
        return;
      }

      setValueSelected1({
        value: findCategory.value,
        label: findCategory.option,
      });
    }
  }, [mapDataCategories, searchParams]);

  useEffect(() => {
    const data = searchParams.get("date_type");

    if (data) {
      const findData = dataOptions.find(
        (value) => String(value.value) === data
      );

      if (!findData) {
        return;
      }

      setValueSelected2({ value: findData.value, label: findData.option });
    }
  }, [searchParams]);

  useEffect(() => {
    const price = searchParams.get("price");
    if (price) {
      const findPrice = dataPrice.find(
        (value) => String(value.value) === price
      );

      if (!findPrice) {
        return;
      }

      setValueSelected3({ value: findPrice.value, label: findPrice.option });
    }
  }, [searchParams]);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  const antIconWhite = (
    <LoadingOutlined style={{ fontSize: 18, color: Colors.white }} spin />
  );

  function renderStateForSigla() {
    if (address_state) {
      const findState = Constants.States?.find(
        (value) => String(value.value) === address_state
      );

      if (!findState) {
        return address_state;
      }

      return findState.label;
    }
  }

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const renderSearchTitle = () => {
    if (address_city) {
      return `Encontre eventos em ${address_city} - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`;
    }

    if (address_state) {
      return `Encontre eventos em ${renderStateForSigla()} - ${
        process.env.REACT_APP_EVENTX_META_TITLE_NAME
      }`;
    }

    return `Encontre eventos - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`;
  };

  const metaTags = {
    title: renderSearchTitle(),
    description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  };

  return (
    <>
      <Header metaTags={metaTags} />

      <>
        <Container>
          <ContainerItems>
            <>
              <div className="bread">
                <ListBreadcrumb
                  data={[
                    { name: "Home", link: "/" },
                    { name: "Pesquisar", link: "/" },
                  ]}
                />
              </div>
              <div className="main-header">
                <h1>Encontre eventos</h1>
              </div>

              <div className="header-filter-mobile">
                <h1>Encontre eventos</h1>
              </div>
            </>

            <ContainerTabs>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Eventos" key="1">
                  <HeaderFilterEvent
                    filters={["localization", "categories", "data", "price"]}
                  />
                </TabPane>
              </Tabs>
            </ContainerTabs>
          </ContainerItems>
        </Container>

        {activeTab === "1" ? (
          <>
            <ContainerSearch>
              {eventsData?.data && eventsData?.data.length === 0 ? (
                <Container>
                  <EmptySearchComponent />
                </Container>
              ) : null}

              {eventsData?.data === undefined ? (
                <Center>
                  <Spin spinning indicator={antIcon} />
                </Center>
              ) : null}

              {eventsData?.data && eventsData?.data.length > 0 ? (
                <ListSearch
                  total={eventsData?.meta.total}
                  name={name}
                  address_city={valueSelected4.label}
                  category={valueSelected1.label}
                  date={valueSelected2.label}
                  price={valueSelected3.label}
                  events={eventsData?.data}
                />
              ) : null}

              <ContainerMainPagination>
                {eventsData?.data && eventsData?.data.length > 0 ? (
                  <Pagination
                    totalCountOfRegisters={eventsData?.meta.total}
                    currentPage={Number(page)}
                    onPageChange={onChange}
                    last_page={eventsData?.meta.last_page}
                    registersPerPage={6}
                  />
                ) : null}
              </ContainerMainPagination>
            </ContainerSearch>

            <ContainerSearchMobile>
              {eventsData?.data && eventsData?.data.length === 0 ? (
                <Container>
                  <EmptySearchComponent />
                </Container>
              ) : null}

              {eventsData?.data === undefined ? (
                <Center>
                  <Spin spinning indicator={antIcon} />
                </Center>
              ) : null}

              {eventsData?.data && eventsData?.data.length > 0 ? (
                <ListSearch
                  total={eventsData?.meta.total}
                  name={name}
                  address_city={valueSelected4.label}
                  category={valueSelected1.label}
                  date={valueSelected2.label}
                  price={valueSelected3.label}
                  events={eventsData?.data}
                />
              ) : null}

              <ContainerMainPaginationMobile>
                {eventsData?.data && eventsData?.data.length > 0 ? (
                  <FooterItems>
                    <h3>
                      Mostrando {eventsData?.data.length} de{" "}
                      {eventsData?.meta.total}
                    </h3>

                    {hasMoreItems ? (
                      <ButtonMoreItems
                        active={hasMoreItems}
                        disabled={!hasMoreItems || loadingMoreItems}
                        onClick={handleLoadMoreItemsMobile}
                      >
                        {!loadingMoreItems ? (
                          <span>Carregar mais</span>
                        ) : (
                          <Spin spinning indicator={antIconWhite} />
                        )}
                      </ButtonMoreItems>
                    ) : null}
                  </FooterItems>
                ) : null}
              </ContainerMainPaginationMobile>
            </ContainerSearchMobile>
          </>
        ) : null}
      </>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
