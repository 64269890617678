import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Select } from "antd";

import { IoCloseCircleSharp } from "react-icons/io5";

import { useCategories } from "services/Hook/Event/Category";

import { Colors } from "constants/colors";

import { Category as CategoryItem } from "types";

import { BorderSelect, Container, DivIsSelected, SelectStyled } from "./styles";

const { Option } = Select;

interface CategoriesInterface {
  data: CategoryItem[];
}

export function SelectCategory() {
  const [searchParams, setSearchParams] = useSearchParams();

  const categories = searchParams.get("categories");

  const { data } = useCategories<CategoriesInterface>();

  const mapDataCategories = useMemo(() => {
    return data?.data?.map((item) => ({
      option: item.name,
      value: item.id,
    }));
  }, [data?.data]);

  const [textLabel, setTextLabel] = useState("");

  // const labelInUrl = searchParams.get(name);

  // useEffect(() => {
  //   const getLabel = options.find((value) => value.value === labelInUrl);

  //   setTextLabel(getLabel ? getLabel.label : "");
  // }, [labelInUrl, options]);

  useEffect(() => {
    const categoryId = searchParams.get("categories");

    if (categoryId) {
      const findCategory = mapDataCategories?.find(
        (value) => String(value.value) === categoryId
      );

      if (!findCategory) {
        return;
      }

      setTextLabel(findCategory.option);
    }
  }, [mapDataCategories, searchParams]);

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        categories: value.value,
      });

      setTextLabel(value.label);
    },
    [searchParams, setSearchParams]
  );

  const clearId = useCallback(() => {
    searchParams.delete("categories");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [searchParams, setSearchParams]);

  return (
    <Container>
      {categories === null ? (
        <BorderSelect>
          <SelectStyled
            bordered={false}
            labelInValue
            size="large"
            defaultValue={{ value: 0, label: "Categoria" }}
            onChange={handleSelectedIdFilter}
          >
            {mapDataCategories ? (
              <>
                {[
                  ...[{ option: "Categorias", value: 0 }],
                  ...mapDataCategories,
                ].map((op) => (
                  <Option key={op.value} value={op.value}>
                    {op.option}
                  </Option>
                ))}
              </>
            ) : null}
          </SelectStyled>
        </BorderSelect>
      ) : (
        <DivIsSelected>
          <h3 className="selected">{textLabel}</h3>
          <IoCloseCircleSharp
            onClick={clearId}
            style={{ cursor: "pointer" }}
            size={18}
            color={Colors.white}
          />
        </DivIsSelected>
      )}
    </Container>
  );
}
