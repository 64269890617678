import React, { FormEvent, useCallback, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import {
  RiCloseCircleLine,
  RiLockPasswordLine,
  RiMailLine,
  RiSmartphoneLine,
  RiUser2Line,
} from "react-icons/ri";

import { Divider, Form, Row, Col } from "antd";

import ReCAPTCHA from "react-google-recaptcha";

import api from "services/api";

import { useDisclosure } from "hooks/useDisclosure";
import { Colors } from "constants/colors";

import {
  Container,
  Section,
  QuestionsContainer,
  WrapperRecaptcha,
  FormTitle,
  HeadingContact,
  ContainerModal,
  ModalStyled,
  ButtonConfirm,
} from "./styles";

import { errorNotification, infoNotification } from "components/Notification";
import { FormItem } from "components/Form/FormItem";
import { Input, TextArea } from "components/Form/Input";
import { Select } from "components/Form/Select";
import { PrimaryButton } from "components/Form/Button";
import { Checkbox } from "components/Form/Checkbox";
import { Breadcrumb } from "components/Breadcrumb";

import mail_feedback from "assets/mail-feedback.svg";
import { phone } from "utils/masks";

const { Option } = Select;

const ContactForm: React.FC = () => {
  const [form] = Form.useForm();

  const [token, setToken] = useState(null);
  const [formType, setFormType] = useState<any>();
  const [isFormEditable, setIsFormEditable] = useState<any>(true);

  const navigate = useNavigate();

  const { handleCancel, handleOk, isModalVisible, showModal } = useDisclosure();

  const indexToTransaction = [
    "Tive um problema com a venda",
    "Tive um problema com o App",
    "O valor está errado",
    "Essa transação está repetida",
    "Eu não autorizei esta transação",
    "Outros",
  ];

  const onFinish = async (body: any) => {
    try {
      if (!token) {
        return infoNotification(
          "Verifique se não é um robô.",
          "Por favor, caso não seja um robô, marque o reCAPTCHA."
        );
      }

      setIsFormEditable(false);

      let message = "";
      if (formType === 1) {
        message = body.name + " - " + body.phone + " / " + body.email;
        message +=
          "\nDeseja atendimento referente a compra ID: " +
          body.sell_code +
          ", realizada atravez do e-mail: " +
          body.used_email;
        message += "\nDetalhes: " + body.details;
      } else if (formType === 2) {
        message = body.name + " - " + body.phone + " / " + body.email;
        message += "\nDeseja atendimento referente ao Aplicativo";
        message += "\nDetalhes: " + body.details;
      } else if (formType === 3) {
        message = body.name + " - " + body.phone + " / " + body.email;
        message +=
          "\nDeseja atendimento referente a problemas financeiros do tipo: " +
          body.financial_problems.map((problem: any) => {
            return `\n${indexToTransaction[problem - 1]}`;
          });
        message += "\nDetalhes: " + body.details;
      } else if (formType === 4) {
        message = body.name + " - " + body.phone + " / " + body.email;
        message += "\nDeseja atendimento referente a outros assuntos";
        message += "\nDetalhes: " + body.details;
      }

      api
        .post("/messages/send-no-auth", {
          title: "Novo atendimento (web)",
          message: message,
          for: 1,
        })
        .then((res) => {
          // successNotification(
          //   "Formulário enviado com sucesso!",
          //   `Seu pedido foi registrado e será analisado pelo suporte.`
          // );
          // infoNotification(
          //   "Iremos te redirecionar em 5s para a página principal.",
          //   `Aperte os seus cintos e vamos lá!`
          // );
          showModal();

          // setTimeout(() => {
          // history.push("/landing-v2");

          // }, 5000);
        })
        .catch((err) => {
          errorNotification(
            "Oops! Aconteceu um erro.",
            "Por favor, tente novamente mais tarde."
          );

          setIsFormEditable(true);
        });
    } catch (err) {
      errorNotification(
        "Oops! Aconteceu um erro.",
        "Por favor, tente novamente mais tarde."
      );
    }
  };

  const handleKeyUp = useCallback((e: FormEvent<HTMLInputElement>) => {
    phone(e);
  }, []);

  const switchSubjectType = () => {
    switch (formType) {
      case 1:
        return (
          <>
            <FormTitle>Insira as informações referentes à sua compra</FormTitle>
            <Row gutter={24}>
              <Col style={{ width: "100%" }} lg={12} md={24}>
                <FormItem
                  label={
                    <>
                      <RiMailLine />
                      Email utilizado na compra
                    </>
                  }
                  name="used_email"
                  required
                  rules={[
                    {
                      required: true,
                      message:
                        "Por favor, insira o seu e-mail usado na compra.",
                    },
                    {
                      type: "email",
                      message: "O e-mail usado não é um e-mail válido.",
                    },
                  ]}
                >
                  <Input disabled={!isFormEditable} maxLength={100} />
                </FormItem>
              </Col>
              <Col style={{ width: "100%" }} lg={12} md={24}>
                <FormItem
                  label={
                    <>
                      <RiLockPasswordLine />
                      Código da compra
                    </>
                  }
                  name="sell_code"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira o seu código de venda.",
                    },
                  ]}
                >
                  <Input disabled={!isFormEditable} maxLength={100} />
                </FormItem>
              </Col>
            </Row>
            <Divider />
            <FormTitle>Escreva aqui sua mensagem</FormTitle>
            <FormItem
              name="details"
              required
              rules={[
                {
                  required: true,
                  message: "Por favor, insira os detalhes do seu problema.",
                },
              ]}
            >
              <TextArea
                disabled={!isFormEditable}
                maxLength={5000}
                autoSize={{ minRows: 3 }}
                showCount
              />
            </FormItem>
            <Divider />
          </>
        );
      case 2:
        return (
          <>
            <FormTitle>Escreva aqui sua mensagem</FormTitle>
            <FormItem
              name="details"
              required
              rules={[
                {
                  required: true,
                  message: "Por favor, insira os detalhes do seu problema.",
                },
              ]}
            >
              <TextArea
                disabled={!isFormEditable}
                maxLength={5000}
                autoSize={{ minRows: 3 }}
                showCount
              />
            </FormItem>
            <Divider />
          </>
        );
      case 3:
        return (
          <>
            <FormTitle>
              Qual o problema encontrado com essa transação?
            </FormTitle>
            <FormItem name="financial_problems">
              <Checkbox.Group disabled={!isFormEditable}>
                <Checkbox value={1}>Tive um problema com a venda</Checkbox>
                <br />
                <Checkbox value={2}>Tive um problema com o App</Checkbox>
                <br />
                <Checkbox value={3}>O valor está errado</Checkbox>
                <br />
                <Checkbox value={4}>Essa transação está repetida</Checkbox>
                <br />
                <Checkbox value={5}>Eu não autorizei esta transação</Checkbox>
                <br />
                <Checkbox value={6}>Outro</Checkbox>
                <br />
              </Checkbox.Group>
            </FormItem>
            <Divider />
            <FormTitle>Descreva aqui o problema encontrado</FormTitle>
            <FormItem
              label="Escreva aqui sua mensagem"
              name="details"
              required
              rules={[
                {
                  required: true,
                  message: "Por favor, insira os detalhes do seu problema.",
                },
              ]}
            >
              <TextArea
                disabled={!isFormEditable}
                maxLength={5000}
                autoSize={{ minRows: 3 }}
                showCount
              />
            </FormItem>
            <Divider />
          </>
        );
      case 4:
        return (
          <>
            <FormTitle>Escreva aqui sua mensagem</FormTitle>
            <FormItem
              name="details"
              required
              rules={[
                {
                  required: true,
                  message: "Por favor, insira os detalhes do seu problema.",
                },
              ]}
            >
              <TextArea
                disabled={!isFormEditable}
                maxLength={5000}
                autoSize={{ minRows: 3 }}
                showCount
              />
            </FormItem>
            <Divider />
          </>
        );
    }
  };
  return (
    <Container className="first-container">
      <Section>
        {/* <Slide bottom cascade> */}
        <div className="content">
          <QuestionsContainer>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/faq">Início</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Central de ajuda</Breadcrumb.Item>
            </Breadcrumb>
            <HeadingContact>
              <p className="title">Fale conosco</p>
              <p className="subtitle">
                Preencha os campos abaixo e envie sua solicitação à nossa
                central de ajuda.
              </p>
            </HeadingContact>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              scrollToFirstError
              style={{ width: "100%" }}
            >
              <FormItem
                label={
                  <>
                    <RiUser2Line />
                    Nome
                  </>
                }
                name="name"
                required
                rules={[
                  {
                    required: true,
                    message: "Por favor, insira o seu nome.",
                  },
                ]}
              >
                <Input
                  disabled={!isFormEditable}
                  placeholder="Insira seu nome"
                  maxLength={100}
                />
              </FormItem>
              <Row gutter={24}>
                <Col style={{ width: "100%" }} lg={12} md={24}>
                  <FormItem
                    label={
                      <>
                        <RiMailLine />
                        Endereço de email
                      </>
                    }
                    name="email"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o seu e-mail.",
                      },
                      {
                        type: "email",
                        message: "O e-mail usado não é um e-mail válido.",
                      },
                    ]}
                  >
                    <Input
                      disabled={!isFormEditable}
                      maxLength={100}
                      placeholder="Insira o email utilizado na EventX"
                    />
                  </FormItem>
                </Col>
                <Col style={{ width: "100%" }} lg={12} md={24}>
                  <FormItem
                    label={
                      <>
                        <RiSmartphoneLine />
                        Telefone
                      </>
                    }
                    name="phone"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o seu telefone.",
                      },
                    ]}
                  >
                    <Input
                      disabled={!isFormEditable}
                      className="masked-input"
                      placeholder="Digite seu número de telefone..."
                      onKeyUp={handleKeyUp}
                    />
                  </FormItem>
                </Col>
              </Row>

              <FormItem
                label="Assunto"
                name="subject"
                required
                rules={[
                  {
                    required: true,
                    message: "Por favor, selecione o assunto.",
                  },
                ]}
              >
                <Select
                  disabled={!isFormEditable}
                  placeholder="Selecione uma opção"
                  onChange={(value: any) => setFormType(value)}
                >
                  <Option value={1}>Problemas com ingressos</Option>
                  <Option value={2}>Problemas com o aplicativo</Option>
                  <Option value={3}>Problemas com financeiro</Option>
                  <Option value={4}>Outros</Option>
                </Select>
              </FormItem>
              <Divider />
              {switchSubjectType()}
              <WrapperRecaptcha>
                <ReCAPTCHA
                  sitekey="6LdsNDgcAAAAAKaOglZNbGUUzyNEGW5Uwe4nrzU4"
                  onChange={(token: any) => setToken(token)}
                  onExpired={() => setToken(null)}
                  theme="light"
                  size="normal"
                  hl="pt-BR"
                />
              </WrapperRecaptcha>
              <PrimaryButton disabled={!isFormEditable} htmlType="submit">
                Enviar
              </PrimaryButton>
            </Form>
          </QuestionsContainer>
        </div>
        {/* </Slide> */}
      </Section>

      <ModalStyled
        width={642}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => {
          handleCancel();
          navigate("/");
        }}
        closeIcon={
          <RiCloseCircleLine
            style={{ marginTop: "14px", marginRight: "34px" }}
            size={43}
            color={Colors.black40}
          />
        }
        footer={null}
      >
        <ContainerModal>
          <img
            src={mail_feedback}
            width={189}
            height={189}
            alt="confirm-cancel"
          />

          <div className="texts-confirm">
            <h3>Mensagem enviada!</h3>

            <p>
              Sua mensagem foi enviada e logo menos entraremos em contato com
              você através do e-mail que nos foi informado. Agradecemos o seu
              contato!
            </p>
          </div>

          <div className="buttons">
            <ButtonConfirm
              onClick={() => {
                handleOk();
                navigate(`/`);
              }}
            >
              <span>Ok</span>
            </ButtonConfirm>
          </div>
        </ContainerModal>
      </ModalStyled>
    </Container>
  );
};

export default ContactForm;
