import { useEffect, useState } from "react";
import { useLocation, Link, useParams } from "react-router-dom";

import { Tabs, Spin, Tooltip } from "antd";
import { useSWRConfig } from "swr";
import CopyToClipboard from "react-copy-to-clipboard";

import { LoadingOutlined } from "@ant-design/icons";
import { IoMdSettings } from "react-icons/io";
import { MdAdd, MdEdit } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";
import { RiCalendar2Line, RiCloseCircleLine } from "react-icons/ri";
import { GoChevronUp } from "react-icons/go";
import { FaTrash, FaWhatsapp } from "react-icons/fa";
import { BiChevronRight, BiPlus } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";

import { useMyEventDetail } from "services/Hook/Event";

import { useTicketEvent } from "services/Hook/Event/Ticket";

import { Colors } from "constants/colors";
import Constants from "constants/Datas";
import { Event, TicketEvent } from "types";

import { formatPrice } from "utils/formatPrice";
import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  HeaderEvent,
  ContainerTabs,
  ContainerDetailEvent,
  ContainerInformations,
  ContainerDescription,
  ContainerCards,
  CardTicket,
  CardAccordion,
  DataDropDown,
  ContainerBorder,
  ContainerButtons,
  ButtonDownload,
  ButtonPrint,
  ContainerModal,
  ContainerAboutOrganizerMobile,
  ContainerBorderMobile,
  RowMobileFirst,
  RowMobile,
  RowMobileBorder,
  ModalStyled,
  Footer,
  Center,
  ParentalRatingContainer,
  ParentalRatingIcon,
} from "./styles";

import Popup from "containers/Popup";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { EmptyTicketComponent } from "components/EmptyTicketComponent";

import selo_google_play from "assets/selo-google-play.jpg";
import selo_app_store from "assets/selo-app-store.jpg";
import lock_open from "assets/lock-open.svg";
import lock_closed from "assets/lock-closed.svg";
import copy_icon from "assets/copy-icon.svg";
import ticket_t from "assets/ticket-t.svg";
import billet from "assets/billet.svg";
import ticket_profile from "assets/ticket-profile.svg";
import banner_event from "assets/banner-event.jpg";

const { TabPane } = Tabs;

interface TicketsEventInterface {
  tickets: TicketEvent[];
}

export default function MyEvent() {
  const location = useLocation();

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const { id } = useParams();

  const { data } = useMyEventDetail<Event>(id ?? "");

  const { mutate } = useSWRConfig();

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const { data: dataTickets } = useTicketEvent<TicketsEventInterface>(
    data?.id ?? ""
  );

  const [activeKey, setActiveKey] = useState("1");

  const handleActiveTab = (activeKey: string) => {
    setActiveKey(activeKey);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOpenTicketEvent = (ticketId: number) => {
    mutate(
      `/tickets/events/${data?.id}`,
      () => {
        const newMapEvents = dataTickets?.tickets.map((ticket) =>
          ticket.id === ticketId
            ? { ...ticket, isOpen: !ticket.isOpen }
            : ticket
        );

        return {
          ...dataTickets,
          tickets: newMapEvents,
        };
      },
      false
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const metaTags = {
    title: `${data?.name ?? ""} - ${
      process.env.REACT_APP_EVENTX_META_TITLE_NAME
    }`,
    noIndex: true,
  };

  return (
    <>
      <Header metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <div className="bread">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Meus eventos", link: "/my-event" },
                { name: data?.name ?? "", link: "/" },
              ]}
            />
          </div>
          <HeaderEvent>
            <h1>Visualizar evento</h1>

            {activeKey === "1" ? (
              <div className="buttons">
                <button onClick={showModal}>
                  <MdEdit size={22} color={Colors.primary100} />

                  <span>Editar evento</span>
                </button>

                <Link to={`/my-event/${data?.nickname}/integration`}>
                  <button className="second">
                    <IoMdSettings size={22} color={Colors.primary100} />

                    <span>Gerenciar integrações</span>
                  </button>
                </Link>
              </div>
            ) : null}

            {activeKey === "2" ? (
              <div className="buttons">
                <button onClick={showModal}>
                  <MdAdd size={22} color={Colors.primary100} />

                  <span>Adicionar novo</span>
                </button>
              </div>
            ) : null}
          </HeaderEvent>

          <ContainerTabs>
            <Tabs onChange={handleActiveTab} activeKey={activeKey}>
              <TabPane tab="Detalhes do evento" key="1">
                {!data ? (
                  <Center>
                    <Spin spinning indicator={antIcon} />
                  </Center>
                ) : null}

                {data ? (
                  <ContainerDetailEvent>
                    <h2 className="resume">RESUMO DO EVENTO</h2>

                    <div className="container-img">
                      <img src={data?.photo ?? banner_event} alt="Logo" />
                    </div>

                    <div className="event-privacity">
                      {data?.privacity === "1" ? (
                        <>
                          <img src={lock_open} alt="open" />
                          <h3>EVENTO PÚBLICO</h3>
                        </>
                      ) : (
                        <>
                          <img src={lock_closed} alt="open" />
                          <h3>EVENTO PRIVADO</h3>
                        </>
                      )}
                    </div>

                    <ContainerInformations>
                      <h2>{data?.name}</h2>

                      <div className="publicate">
                        <div className="status"></div>
                        <h3>Publicado</h3>
                      </div>

                      <div className="local">
                        <FiMapPin size={18} color={Colors.primary100} />
                        <h4>
                          {data?.address_name ? `${data?.address_name} |` : ""}{" "}
                          {data?.address_street}, {1705} -{" "}
                          {data?.address_district}, {data?.address_city} -{" "}
                          {data?.address_state}, {data?.address_zipcode}
                        </h4>
                      </div>

                      <div className="data">
                        <RiCalendar2Line size={18} color={Colors.primary100} />

                        <h4>
                          {data.formattedDate}, {data.formattedHour}
                        </h4>
                      </div>

                      <div className="link-event">
                        <h4 className="link">Link do evento</h4>

                        <nav>
                          <div className="searchInput">
                            <input
                              placeholder={`${window.location.protocol}${window.location.host}/${data?.nickname}`}
                            />
                          </div>

                          <div className="search">
                            <CopyToClipboard
                              text={`${window.location.protocol}${window.location.host}/${data?.nickname}`}
                              onCopy={() => {
                                setIsLinkCopied(true);
                                setTimeout(() => {
                                  setIsLinkCopied(false);
                                }, 5000);
                              }}
                            >
                              <Tooltip
                                placement="topLeft"
                                title={
                                  isLinkCopied
                                    ? "Link copiado para a área de transferência!"
                                    : "Copiar link"
                                }
                              >
                                <div className="copy">
                                  <img
                                    src={copy_icon}
                                    alt=""
                                    width={10}
                                    height={10}
                                  />
                                </div>
                              </Tooltip>
                            </CopyToClipboard>
                          </div>
                        </nav>
                      </div>
                    </ContainerInformations>

                    <ContainerDescription>
                      <div className="title-description">
                        <h2>Descrição</h2>

                        <span>{data?.description}</span>
                      </div>

                      <div className="classification">
                        <h4>Classificação indicativa</h4>

                        <ParentalRatingContainer>
                          <ParentalRatingIcon
                            rating={Constants.ParentalRating[data?.age]}
                          >
                            <p className="inside">
                              {Constants.ParentalRating[data?.age]?.age}
                            </p>
                          </ParentalRatingIcon>
                          {data?.age && data?.age !== 1 ? (
                            <p className="normal">
                              O evento é restrito para maiores de{" "}
                              {Constants.ParentalRating[data?.age]?.age} anos de
                              idade.
                            </p>
                          ) : null}

                          {data?.age && data?.age === 1 ? (
                            <p className="normal">
                              L - Livre para todas as idades.
                            </p>
                          ) : null}
                        </ParentalRatingContainer>
                      </div>

                      <div className="organizer">
                        <h2 className="title-organizer">Organizador</h2>

                        <div className="border">
                          <div className="header">
                            <div className="avatar"></div>

                            <div className="content">
                              <h4>{data?.organizer?.name}</h4>

                              <div className="informations-content">
                                <div className="row">
                                  <div className="square">
                                    <FaWhatsapp
                                      size={14}
                                      color={Colors.primary100}
                                    />
                                  </div>

                                  <h5>{data?.creator?.cell_phone}</h5>
                                </div>

                                <div className="row-left">
                                  <div className="square">
                                    <AiOutlineMail
                                      size={14}
                                      color={Colors.primary100}
                                    />
                                  </div>
                                  <h5>{data?.creator?.email}</h5>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="informations">
                            <h5>Sobre o organizador</h5>

                            <span>{data?.organizer?.description}</span>
                          </div>
                        </div>
                      </div>
                    </ContainerDescription>

                    <ContainerAboutOrganizerMobile>
                      <h3 className="organizer">Sobre o organizador</h3>

                      <span className="organizer">
                        {data?.organizer?.description}
                      </span>
                    </ContainerAboutOrganizerMobile>
                  </ContainerDetailEvent>
                ) : null}

                <Footer>
                  <h1>OUTRAS OPÇÕES</h1>

                  <div className="drop" onClick={showModal}>
                    <div className="first">
                      <div className="badge">
                        <img
                          className="ticket"
                          src={ticket_t}
                          alt="badge"
                          width={20}
                          height={21}
                        />
                      </div>
                      <h2>Bilheteria</h2>
                    </div>

                    <BiChevronRight
                      className="icon"
                      color={Colors.primary100}
                      size={28}
                    />
                  </div>

                  <div className="drop" onClick={showModal}>
                    <div className="first">
                      <div className="badge">
                        <img
                          className="ticket"
                          src={billet}
                          alt="badge"
                          width={20}
                          height={21}
                        />
                      </div>
                      <h2>Financeiro</h2>
                    </div>

                    <BiChevronRight
                      className="icon"
                      color={Colors.primary100}
                      size={28}
                    />
                  </div>
                </Footer>
              </TabPane>
              <TabPane tab="Ingressos e cupons" key="2">
                <ContainerCards>
                  <CardTicket>
                    <h2 className="created">INGRESSOS SALVOS</h2>

                    {dataTickets?.tickets &&
                    dataTickets?.tickets.length === 0 ? (
                      <div className="container-card">
                        <EmptyTicketComponent
                          title="Você não cadastrou ingressos ainda :("
                          subtitle="Você não possui ingressos cadastrados, aproveite para cadastrar um agora!"
                        />

                        <div className="border">
                          <div className="line"></div>

                          <button onClick={showModal}>
                            <BiPlus size={18} color={Colors.primary100} />
                            <span>Cadastrar um ingresso</span>
                          </button>
                        </div>
                      </div>
                    ) : null}

                    {dataTickets?.tickets &&
                      dataTickets.tickets.map((ticket) => (
                        <div className="container-card" key={ticket.id}>
                          <CardAccordion
                            active={ticket.isOpen}
                            onClick={() => handleOpenTicketEvent(ticket.id)}
                          >
                            <div className="content">
                              <div className="image">
                                <img
                                  className="ticket"
                                  src={ticket_profile}
                                  alt="Logo"
                                  width={25}
                                  height={22.5}
                                />
                              </div>

                              <div className="texts">
                                <h2>{ticket?.name}</h2>
                                <p>{formatPrice(ticket?.price)}</p>
                              </div>
                            </div>

                            <GoChevronUp size={22} color={Colors.secondary50} />
                          </CardAccordion>

                          {ticket.isOpen ? (
                            <DataDropDown>
                              <ContainerBorder>
                                <div className="content">
                                  <div className="column">
                                    <h2>Data de criação</h2>

                                    <p>{ticket.formattedDate}</p>
                                  </div>
                                  <div className="column">
                                    <h2>Meia entrada</h2>

                                    <p>-</p>
                                  </div>
                                  <div className="column">
                                    <h2>Valor do ingresso</h2>

                                    <p>{formatPrice(ticket.price)}</p>
                                  </div>

                                  <div className="column">
                                    <h2>Custo da venda</h2>

                                    <p>-</p>
                                  </div>
                                  <div className="column">
                                    <h2>Valor total</h2>

                                    <p>{formatPrice(ticket.price)}</p>
                                  </div>

                                  <div className="column">
                                    <h2>Cupons aplicados</h2>

                                    <p>-</p>
                                  </div>
                                </div>
                              </ContainerBorder>

                              <ContainerBorderMobile>
                                <RowMobileFirst>
                                  <h6 className="row">Data de criação:</h6>

                                  <span className="row">
                                    {ticket.formattedDate}
                                  </span>
                                </RowMobileFirst>

                                <RowMobile>
                                  <h6 className="row">Meia entrada:</h6>

                                  <span className="row">-</span>
                                </RowMobile>

                                <RowMobile>
                                  <h6 className="row">Valor do ingresso:</h6>

                                  <span className="row">
                                    {formatPrice(ticket.price)}
                                  </span>
                                </RowMobile>

                                <RowMobile>
                                  <h6 className="row">Custo da venda:</h6>

                                  <span className="row">-</span>
                                </RowMobile>

                                <RowMobileBorder>
                                  <h6 className="row">Valor total:</h6>

                                  <span className="row">
                                    {formatPrice(ticket.price)}
                                  </span>
                                </RowMobileBorder>

                                <RowMobile>
                                  <h6 className="row">Cupons aplicados:</h6>

                                  <span className="row">-</span>
                                </RowMobile>
                              </ContainerBorderMobile>

                              <ContainerButtons>
                                <ButtonDownload onClick={showModal}>
                                  <MdEdit size={20} color={Colors.green10} />

                                  <div className="text">
                                    <span>Editar Ingresso</span>
                                  </div>
                                </ButtonDownload>
                                <ButtonPrint onClick={showModal}>
                                  <FaTrash size={14} color={Colors.red10} />

                                  <div className="text">
                                    <span>Excluir ingresso</span>
                                  </div>
                                </ButtonPrint>
                              </ContainerButtons>
                            </DataDropDown>
                          ) : null}
                        </div>
                      ))}
                  </CardTicket>

                  {true ? (
                    <CardTicket>
                      <h2 className="created">CUPONS CRIADOS</h2>

                      <div className="container-card">
                        <EmptyTicketComponent
                          title="Você não cadastrou cupons ainda :("
                          subtitle="Você não possui cupons de desconto cadastrados, aproveite para cadastrar um agora!"
                        />

                        <div className="border">
                          <div className="line"></div>

                          <button onClick={showModal}>
                            <BiPlus size={18} color={Colors.primary100} />
                            <span>Cadastrar um cupom</span>
                          </button>
                        </div>
                      </div>

                      {/* <div className="container-card">
                      <CardAccordion onClick={() => setActiveDrop(!activeDrop)}>
                        <div className="content">
                          <div className="image">
                            <img
                              className="ticket"
                              src={ticket_profile}
                              alt="Logo"
                              width={25}
                              height={22.5}
                            />
                          </div>

                          <div className="texts">
                            <h2>Cupom 5%</h2>
                            <p>ID: EVENTO5</p>
                          </div>
                        </div>

                        <GoChevronUp size={22} color={Colors.secondary50} />
                      </CardAccordion>

                      {activeDrop ? (
                        <DataDropDown>
                          <ContainerBorder>
                            <div className="content">
                              <div className="column">
                                <h2>Data de criação</h2>

                                <p>23/01/2021</p>
                              </div>
                              <div className="column">
                                <h2>Valor do desconto</h2>

                                <p>5%</p>
                              </div>
                              <div className="column">
                                <h2>ID do cupom</h2>

                                <p>EVENTO5</p>
                              </div>

                              <div className="column">
                                <h2>Ingressos aplicados:</h2>

                                <p>Ingresso Padrão ; Ingresso Camarote</p>
                              </div>
                            </div>
                          </ContainerBorder>
                          <ContainerBorderMobile>
                            <RowMobileFirst>
                              <h6 className="row">Data de criação:</h6>

                              <span className="row">23/01/2021</span>
                            </RowMobileFirst>

                            <RowMobile>
                              <h6 className="row">Valor do desconto:</h6>

                              <span className="row">5%</span>
                            </RowMobile>

                            <RowMobileBorder>
                              <h6 className="row">Valor total:</h6>

                              <span className="row">R$33,00</span>
                            </RowMobileBorder>

                            <RowMobile>
                              <h6 className="row">Ingressos aplicados:</h6>

                              <span className="row">Ingresso Padrão</span>
                            </RowMobile>
                          </ContainerBorderMobile>

                          <ContainerButtons>
                            <ButtonVisualizationTicket>
                              <IoMdEye size={24} color={Colors.secondary100} />

                              <div className="text">
                                <span>Visualizar ingresso</span>
                              </div>
                            </ButtonVisualizationTicket>
                            <ButtonDownload>
                              <MdEdit size={24} color={Colors.green10} />

                              <div className="text">
                                <span>Editar Ingresso</span>
                              </div>
                            </ButtonDownload>
                            <ButtonPrint>
                              <FaTrash size={18} color={Colors.red10} />

                              <div className="text">
                                <span>Excluir ingresso</span>
                              </div>
                            </ButtonPrint>
                          </ContainerButtons>
                        </DataDropDown>
                      ) : null}
                    </div> */}
                    </CardTicket>
                  ) : null}
                </ContainerCards>
              </TabPane>
            </Tabs>
          </ContainerTabs>
        </ContainerItems>

        <ModalStyled
          width={857}
          visible={isModalVisible}
          onOk={handleOk}
          style={{ borderRadius: "20px" }}
          onCancel={handleCancel}
          closeIcon={
            <RiCloseCircleLine
              style={{ marginTop: "14px", marginRight: "34px" }}
              size={43}
              color={Colors.black40}
            />
          }
          footer={null}
        >
          <ContainerModal>
            <div className="logo-qr-code"></div>

            <div className="content">
              <h2>App EventX</h2>

              <p>
                Escaneie o QR Code para fazer o download do nosso app direto em
                seu celular e <strong>editar seu evento.</strong>
              </p>
            </div>

            <div className="or">
              <div className="line"></div>
              <span>Ou</span>
              <div className="line"></div>
            </div>

            <div className="download-app">
              <h5>Faça o download clicando em umas das opções abaixo</h5>

              <div className="row">
                <img
                  src={selo_google_play}
                  alt="Logo"
                  width={223}
                  height={78}
                />

                <img src={selo_app_store} alt="Logo" width={223} height={78} />
              </div>
            </div>
          </ContainerModal>
        </ModalStyled>
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
