import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Select } from "antd";

import { IoCloseCircleSharp } from "react-icons/io5";

import { Colors } from "constants/colors";

import { BorderSelect, Container, DivIsSelected, SelectStyled } from "./styles";

const { Option } = Select;

type OptionType = {
  label: string;
  value: string | number;
};

interface SelectHeaderFilterProps {
  name: string;
  options: OptionType[];
}

export function SelectHeaderFilter({ name, options }: SelectHeaderFilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [textLabel, setTextLabel] = useState("");

  const labelInUrl = searchParams.get(name);

  useEffect(() => {
    const getLabel = options.find((value) => value.value === labelInUrl);

    setTextLabel(getLabel ? getLabel.label : "");
  }, [labelInUrl, options]);

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        [name]: value.value,
      });

      setTextLabel(value.label);
    },
    [name, searchParams, setSearchParams]
  );

  const clearId = useCallback(() => {
    searchParams.delete(name);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [name, searchParams, setSearchParams]);

  return (
    <Container>
      {labelInUrl === null ? (
        <BorderSelect>
          <SelectStyled
            size="large"
            labelInValue
            bordered={false}
            defaultValue={{ value: 0, label: options[0].label }}
            onChange={handleSelectedIdFilter}
          >
            {options.map((op) => (
              <Option key={op.value} value={op.value}>
                {op.label}
              </Option>
            ))}
          </SelectStyled>
        </BorderSelect>
      ) : (
        <DivIsSelected>
          <h3 className="selected">{textLabel}</h3>
          <IoCloseCircleSharp
            onClick={clearId}
            style={{ cursor: "pointer" }}
            size={18}
            color={Colors.white}
          />
        </DivIsSelected>
      )}
    </Container>
  );
}
