import { Container, ContainerItems, CardEvent } from "./styles";
import { Link } from "react-router-dom";

import play_icon from "assets/play-icon.svg";
import calendar_icon from "assets/calendar-icon.svg";

export function ManageEvents() {
  return (
    <Container>
      <ContainerItems>
        <CardEvent>
          <div className="icon">
            <img src={play_icon} width="345px" height="400px" alt="" />
          </div>

          <div className="content">
            <nav>
              <div className="badge">
                <img src={play_icon} alt="" />
              </div>

              <h3 className="content-title">Crie eventos online</h3>
            </nav>
            <h3 className="full">Crie eventos online</h3>
            <p>
              Criar um evento online pode ser uma oportunidade de expandir o seu
              negócio e de se conectar com pessoas. Crie, venda e transmita o
              seu evento online, em um só lugar!
            </p>
            <Link to="/organize-seu-evento">
              <button>Ver mais</button>
            </Link>
          </div>
        </CardEvent>

        <CardEvent>
          <div className="icon">
            <img src={calendar_icon} width="345px" height="400px" alt="" />
          </div>

          <div className="content">
            <nav>
              <div className="badge">
                <img src={calendar_icon} width="345px" height="400px" alt="" />
              </div>

              <h3 className="content-title">Gerencie eventos presenciais</h3>
            </nav>
            <h3 className="full">Gerencie eventos presenciais</h3>
            <p>
              A Eventx é uma plataforma completa para a venda de ingressos e
              gestão de eventos de modo self-service, na qual você tem total
              autonomia para cadastrar, gerenciar e acompanhar as informações de
              seu evento.
            </p>

            <Link to="/organize-seu-evento">
              <button>Ver mais</button>
            </Link>
          </div>
        </CardEvent>
      </ContainerItems>
    </Container>
  );
}
