import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Select, Spin } from "antd";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { LoadingOutlined } from "@ant-design/icons";
import { IoMdAdd } from "react-icons/io";

import axios from "axios";
import { authClient } from "services/authClient";
import { useCityByUf } from "services/Hook/City";
import { Colors } from "constants/colors";
import { ufs } from "constants/ufs";

import { City } from "types";

import { cep } from "utils/masks";

import {
  Container,
  ContainerItems,
  ContainerAllItems,
  CardProfile,
  ButtonEdit,
  InputsForm,
  FormLabel,
  FormFull,
  ContainerInput,
  StyledSelect,
} from "./styles";

import { Title } from "components/Title";
import { SidebarProfile } from "components/SidebarProfile";
import { ListBreadcrumb } from "components/ListBreadcrumb";

const { Option } = Select;

type AddressFormData = {
  address_zipcode: string;
  address_street: string;
  address_district: string;
  address_number: string;
  address_state: string;
  address_city: string;
  address_complement: string;
  address_title: string;
  // default: boolean;
};

const addressFormSchema = yup.object().shape({
  address_title: yup.string().required("Titulo obrigatório"),
  address_street: yup.string().required("Rua obrigatória"),
  address_city: yup.string().required("Cidade obrigatória"),
  address_district: yup.string().required("Senha obrigatória"),
  address_zipcode: yup.string().required("Cep obrigatório"),
  address_state: yup.string().required("Estado obrigatória"),
  address_number: yup.number().notRequired(),
  address_complement: yup.string().notRequired(),
  // default: yup.boolean,
});

export function Form() {
  const [stateByUserSelected, setStateByUserSelected] = useState("");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState,
    watch,
    setError,
    setValue,
    control,
  } = useForm<AddressFormData>({
    resolver: yupResolver(addressFormSchema),
  });

  const { data: dataCities } = useCityByUf<City[]>(stateByUserSelected);

  const { errors } = formState;

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 15, color: Colors.primary100 }} spin />
  );

  const handleCreateAddress: SubmitHandler<AddressFormData> = async ({
    address_city,
    address_complement,
    address_district,
    address_number,
    address_state,
    address_street,
    address_title,
    address_zipcode,
  }) => {
    try {
      await authClient.post("/users/add-address", {
        address_city,
        address_complement,
        address_district,
        address_number,
        address_state,
        address_street,
        address_title,
        address_zipcode,
        address_country: "BR",
      });

      alert("Endereço criado com sucesso");

      navigate("/profile/address");
    } catch (err) {
      alert("Endereço invalido");
    }
  };

  const handleKeyUpCep = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    cep(e);
  }, []);

  const addressZipCode = watch("address_zipcode");

  const handleSearchAddress = useCallback(async () => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        try {
          const response = await axios.get(
            `https://viacep.com.br/ws/${addressZipCode}/json/`
          );

          const { logradouro, bairro, localidade, uf } = response.data;

          if (bairro === undefined) {
            setError("address_zipcode", {
              type: "manual",
              message: "Não foi possivel encontrar o Cep",
            });
            return;
          }
          setValue("address_street", logradouro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_district", bairro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_city", localidade, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_state", uf, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } catch (err) {
          setError("address_zipcode", {
            type: "required",
            message: "Não foi possivel encontrar o Cep",
          });
        }
      }
    }
  }, [addressZipCode, setError, setValue]);

  useEffect(() => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        handleSearchAddress();
      }
    }
  }, [addressZipCode, handleSearchAddress]);

  return (
    <Container>
      <ContainerItems>
        <ListBreadcrumb
          data={[
            { name: "Home", link: "/" },
            { name: "Perfil", link: "/" },
          ]}
        />

        <Title />

        <ContainerAllItems>
          <SidebarProfile />

          <CardProfile onSubmit={handleSubmit(handleCreateAddress)}>
            <h2>Criar endereço</h2>

            <InputsForm>
              <ContainerInput error={!!errors?.address_title}>
                <FormLabel>
                  <h2>Endereço padrão</h2>
                </FormLabel>

                <input
                  type="text"
                  className="w-100"
                  {...register("address_title")}
                />

                {errors.address_title ? (
                  <span className="error">
                    {errors?.address_title?.message}
                  </span>
                ) : null}
              </ContainerInput>

              <FormFull>
                <ContainerInput
                  className="all-input"
                  error={!!errors.address_zipcode}
                >
                  <FormLabel>
                    <h2>CEP</h2>
                  </FormLabel>

                  <input
                    type="text"
                    {...register("address_zipcode")}
                    onKeyUp={handleKeyUpCep}
                  />

                  {errors.address_zipcode ? (
                    <span className="error">
                      {errors?.address_zipcode?.message}
                    </span>
                  ) : null}
                </ContainerInput>

                <ContainerInput
                  className="all-input"
                  error={!!errors.address_street}
                >
                  <FormLabel>
                    <h2>Endereço</h2>
                  </FormLabel>

                  <input type="text" {...register("address_street")} />

                  {errors.address_street ? (
                    <span className="error">
                      {errors?.address_street?.message}
                    </span>
                  ) : null}
                </ContainerInput>
              </FormFull>

              <FormFull>
                <div className="all-input">
                  <FormLabel>
                    <h2>Número</h2>
                  </FormLabel>

                  <input type="text" {...register("address_number")} />
                </div>

                <div className="all-input">
                  <FormLabel>
                    <h2>Complemento</h2>
                  </FormLabel>

                  <input type="text" {...register("address_complement")} />
                </div>
              </FormFull>

              <FormFull>
                <ContainerInput
                  className="all-input"
                  error={!!errors.address_district}
                >
                  <FormLabel>
                    <h2>Bairro</h2>
                  </FormLabel>

                  <input type="text" {...register("address_district")} />

                  {errors.address_district ? (
                    <span className="error">Forneça um Endereço padrão</span>
                  ) : null}
                </ContainerInput>

                <ContainerInput
                  className="all-input"
                  error={!!errors.address_city}
                >
                  <FormLabel>
                    <h2>Cidade</h2>
                  </FormLabel>

                  <div className="select">
                    <Controller
                      control={control}
                      name="address_city"
                      rules={{ required: "Salutation is required" }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <StyledSelect
                          bordered={false}
                          labelInValue
                          placeholder="Escolha a cidade"
                          value={value}
                          onChange={(e: any) => {
                            onChange(e.value);
                          }}
                          style={{
                            width: "100%",
                            height: "38px",
                            border: "none",
                            outline: 0,
                          }}
                        >
                          {dataCities?.map((city) => {
                            return (
                              <Option value={city.nome} key={city.nome}>
                                {city.nome}
                              </Option>
                            );
                          })}
                        </StyledSelect>
                      )}
                    />
                  </div>

                  {errors.address_city ? (
                    <span className="error">Forneça um Endereço padrão</span>
                  ) : null}
                </ContainerInput>
              </FormFull>

              <ContainerInput className="last" error={!!errors.address_state}>
                <FormLabel>
                  <h2>Estado</h2>
                </FormLabel>

                <div className="select">
                  <Controller
                    control={control}
                    name="address_state"
                    rules={{ required: "Salutation is required" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <StyledSelect
                        bordered={false}
                        labelInValue
                        placeholder="Escolha o estado"
                        value={value}
                        onChange={(e: any) => {
                          onChange(e.value);
                          setStateByUserSelected(e.value);
                        }}
                        style={{
                          width: "100%",
                          height: "38px",
                          border: "none",
                          outline: 0,
                        }}
                      >
                        {ufs.map((uf) => {
                          return (
                            <Option value={uf.sigla} key={uf.id}>
                              {uf.sigla}
                            </Option>
                          );
                        })}
                      </StyledSelect>
                    )}
                  />
                </div>

                {errors.address_state ? (
                  <span className="error">Forneça um Endereço padrão</span>
                ) : null}
              </ContainerInput>
            </InputsForm>

            <div className="full-button">
              <ButtonEdit type="submit">
                {formState.isSubmitting ? (
                  <Spin spinning={formState.isSubmitting} indicator={antIcon} />
                ) : (
                  <IoMdAdd size={18} color={Colors.primary100} />
                )}

                <span>Criar</span>
              </ButtonEdit>
            </div>
          </CardProfile>
        </ContainerAllItems>
      </ContainerItems>
    </Container>
  );
}
