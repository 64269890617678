import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const ContainerItems = styled.div`
  max-width: 1200px;
  width: 100%;
  margin-top: 153px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div.pages {
    h2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;

      color: #585b6c;
    }
  }

  div.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ButtonPrev = styled.button`
  cursor: pointer;
  border: 0;
  height: 60px;
  width: 147px;

  margin-right: 18px;

  background: #e7e7ea;
  border-radius: 14px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    margin-left: 10px;
    color: #b8b9c0;
  }
`;

export const ButtonNext = styled.button`
  border: 0;
  cursor: pointer;
  width: 147px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 18px;

  background: #e7e7ea;

  border-radius: 14px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    color: #b8b9c0;
    margin-right: 10px;
  }
`;

export const Numbers = styled.div`
  width: 169px;
  height: 60px;

  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 14px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface NumberProps {
  active: boolean;
}

export const Number = styled.button<NumberProps>`
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;

  background: ${(props) => (props.active ? "#d40050" : "transparent")};
  border-radius: 14px;

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-top: 5px;

    color: ${(props) => (props.active ? "#ffffff" : "#11142d")};
  }
`;
