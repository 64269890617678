import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { authClient } from "services/authClient";

import { Address } from "types";
import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import { Form } from "./Form";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";

export default function AddressEdit() {
  const [address, setAddress] = useState<Address | undefined>();

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  let { id } = useParams();

  useEffect(() => {
    async function getAddress() {
      try {
        const { data } = await authClient.get(`/users/address`);

        const { addresses } = data;

        const address = addresses.find(
          (address: Address) => address.id === Number(id)
        );

        setAddress(address);
      } catch (err) {}
    }

    getAddress();
  }, [id]);

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const metaTags = {
    title: `Editar endereço - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
    description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
    noIndex: true,
  };

  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Form address={address} />

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
