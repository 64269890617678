import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${Colors.black10}40;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 130px;

  @media (max-width: 800px) {
    background: ${Colors.black10};
    margin-top: 28px;
  }
`;

export const ContainerItems = styled.div`
  max-width: 1280px;

  padding-left: 38px;
  padding-right: 38px;

  width: 100%;

  /* padding: 40px 0px 40px 150px; */

  /* min-height: 383px; */
  min-height: 19.07rem;

  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;

  @media (max-width: 800px) {
    height: 100%;
    padding: 28px 28px;
    flex-direction: column;
  }
`;

export const CardEvent = styled.div`
  width: 48%;

  display: flex;
  flex-direction: row;
  margin-top: 60px;
  /* background: #111; */

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 42px;
    width: 100%;
  }

  div.icon {
    min-width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Colors.secondary20};

    border-radius: 14px;
    margin-top: 10px;

    img {
      max-width: 38px;
      max-height: 38px;
    }

    @media (max-width: 800px) {
      display: none;
    }
  }

  div.content {
    display: flex;
    flex-direction: column;
    margin-left: 36px;

    @media (max-width: 800px) {
      margin-left: 0px;
    }

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;

      h3 {
        margin-left: 12px;
        color: ${Colors.secondary100};
        font-weight: bold;
        font-size: 16px;
        line-height: 160%;
      }

      div.badge {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background: ${Colors.secondary30};
        border-radius: 10px;

        img {
          max-width: 22px;
          max-height: 22px;
        }
      }

      @media (min-width: 800px) {
        display: none;
      }
    }

    h3.full {
      color: ${Colors.black100};
      font-weight: bold;
      /* font-size: 30px; */
      /* line-height: 24px; */
      font-size: 1.4rem;
      margin-bottom: 0px;

      @media (max-width: 800px) {
        display: none;
      }
    }

    p {
      color: ${Colors.black100};
      font-weight: normal;
      /* font-size: 20px; */
      /* line-height: 27px; */
      /* width: 80%; */
      /* margin-top: 20px; */
      font-size: 0.93rem;
      margin-top: 0.73rem;
      opacity: 0.65;
      margin-bottom: 0px;

      @media (max-width: 800px) {
        font-weight: normal;
        font-size: 12px;
        margin-top: 16px;
        line-height: 160%;
        color: ${Colors.black70};
      }
    }

    button {
      cursor: pointer;
      width: 126px;
      height: 43px;
      color: ${Colors.secondary100};
      border: 2px solid ${Colors.secondary100};
      box-sizing: border-box;
      border-radius: 12px;
      /* margin-top: 30px; */
      margin-top: 1.4rem;
      font-weight: bold;
      font-size: 18px;
      line-height: 34px;

      a {
        width: 100%;
      }

      @media (max-width: 800px) {
        width: 144px;
        height: 33px;
        font-size: 12px;
        font-weight: bold;
        line-height: 160%;
        border-radius: 6px;
        margin-top: 20px;
        border: 1px solid ${Colors.secondary100};
        background-color: transparent;
      }
    }
  }
`;
