import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import loadable from "@loadable/component";

import { Section, Wrapper } from "./styles";

import { checkUserPlatform } from "utils/plaftformDetect";
import hasCookiesEnabled from "utils/hasCookiesEnabled";

import FirstSection from "./FirstSection";
import Popup from "containers/Popup";

import Header from "./Header";

const PlatformMission = loadable(() => import("./PlatformMission"));
const TicketsOnYourHand = loadable(() => import("./TicketsOnYourHand"));
const GlobalEcosystem = loadable(() => import("./GlobalEcosystem"));
const LastSection = loadable(() => import("./LastSection"));
const LayoutFooter = loadable(() => import("./Footer"));

const EventxLandPage: React.FC = () => {
  const { pathname } = useLocation();

  const [userPlatform, setUserPlatform] = useState("");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const metaTags = {
    title: process.env.REACT_APP_EVENTX_META_TITLE,
    description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
    keywords: process.env.REACT_APP_EVENTX_META_KEYWORDS,
  };

  return (
    <Wrapper>
      <Section>
        <Header metaTags={metaTags} />
      </Section>

      <FirstSection />
      <PlatformMission userPlatform={userPlatform} />
      <TicketsOnYourHand userPlatform={userPlatform} />
      <GlobalEcosystem userPlatform={userPlatform} />
      <LastSection />
      {visible && <Popup handleVisible={handleCookies} />}

      <LayoutFooter />
    </Wrapper>
  );
};

export default EventxLandPage;
