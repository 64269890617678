import { Select } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const BorderSelect = styled.div`
  border: 2px solid ${Colors.secondary20};
  width: 100%;
  height: 2.81rem;
  border-radius: 0.37rem;
  margin-bottom: 0.45rem;
  margin-right: 0.51rem;
  display: flex;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 0.45rem;

    height: 32px;
    border-radius: 6px;
  }
`;

export const SelectStyledWithoutDrop = styled(Select)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  span.ant-transfer-list-header-dropdown {
    display: none;
  }

  .ant-select-selection-item {
    font-family: "Poppins";
    font-size: 0.93rem;
    color: ${Colors.secondary40};
  }
`;

export const DivIsSelected = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 42px;
  margin-bottom: 10px;

  background: ${Colors.primary100};
  border-radius: 8px;
  margin-right: 10px;
  padding: 0px 0.92rem 0px 0.92rem;

  @media (max-width: 800px) {
    width: 100%;
    height: 32px;
    padding: 0px 6px 0px 12px;
    border-radius: 6px;
    margin-bottom: 0px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  h3.selected {
    font-weight: bold;
    font-size: 0.93rem;
    line-height: 160%;
    font-family: "Poppins";

    color: ${Colors.white};
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-size: 12px;
      font-weight: normal;
    }
  }
`;
