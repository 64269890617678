import { useParams } from "react-router-dom";

import QRCode from "react-qr-code";

import { useTicketCode } from "services/Hook/Event/Ticket";

import { Container } from "./styles";

import logo from "assets/logo_eventx.svg";

const QrcodeTicket: React.FC = () => {
  const { id } = useParams();

  const { data } = useTicketCode(id ?? "");

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "150%",
          }}
        >
          Apresente esse QR-Code na entrada do evento para ser escaneado!
        </h1>
        {data.ticket_code && (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
            <QRCode size={320} value={data.ticket_code.toString() || ""} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "150%",
                }}
              >
                {data?.ticket_name}
              </h1>
            </div>
          </div>
        )}

        {!data.ticket_code && (
          <h1
            style={{
              textAlign: "center",
              fontSize: "150%",
            }}
          >
            Error, Ingresso não encontrado
          </h1>
        )}

        <img src={logo} alt="" />
      </div>
    </Container>
  );
};

export default QrcodeTicket;
