import React from "react";
import { Link } from "react-router-dom";

import { Container, NotFoundHeading, Section, ActionButton } from "./styles";

const NoEventFound: React.FC = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <Container className="first-container">
        <Section>
          <NotFoundHeading>
            <p className="title">Nenhum evento ou organizador encontrado =/</p>
            <p className="subtitle">Verifique se o link está correto.</p>
          </NotFoundHeading>
          <Link to="/">
            <ActionButton>Voltar ao início</ActionButton>
          </Link>
        </Section>
      </Container>
    </div>
  );
};

export default NoEventFound;
