import { Divider } from "antd";
import { Flex } from "containers/Flex";
import React from "react";
import { RiInformationLine } from "react-icons/ri";
import { Link } from "react-router-dom";

import {
  IconContainer,
  TextContainer,
  Wrapper,
  ActionButton,
  ResponsiveHeader,
  ResponsiveIconContainer,
} from "./style";

interface Props {
  handleVisible: () => void;
}

const Popup: React.FC<Props> = ({ handleVisible }) => {
  const handleAccept = () => {
    localStorage.setItem("cookies", "1");
    document.cookie = "cookies=1";
    handleVisible();
  };
  return (
    <>
      <Wrapper>
        <ResponsiveHeader>
          <Flex alignItems="center">
            <ResponsiveIconContainer>
              <RiInformationLine />
            </ResponsiveIconContainer>
            <p>Política de privacidade</p>
          </Flex>
          <Divider />
        </ResponsiveHeader>
        <Flex>
          <IconContainer>
            <RiInformationLine />
          </IconContainer>

          <TextContainer>
            <p>
              Nós usamos cookies e outras tecnologias semelhantes para melhorar
              a sua experiência em nossos serviços, personalizar publicidade e
              recomendar conteúdo de seu interesse. Ao utilizar nossos serviços,
              você concorda com tal monitoramento, bem como nossa{" "}
              <Link to="/term">Política de Privacidade</Link>.
            </p>
          </TextContainer>
        </Flex>
        <ActionButton onClick={handleAccept}>Aceitar</ActionButton>
      </Wrapper>
    </>
  );
};

export default Popup;
