import { Colors } from "constants/colors";
import { Select } from "antd";

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerItems = styled.div`
  margin-top: 7.96rem;

  max-width: 1280px;
  width: 100%;
  height: 100%;
  padding-left: 28px;
  padding-right: 28px;

  @media (max-width: 800px) {
    padding-left: 28px;
    padding-right: 28px;
    margin-top: 85px;
  }

  display: flex;
  flex-direction: column;

  div.header-title {
    h1.title {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 160%;

      color: ${Colors.black100};
      margin-top: 54px;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-disabled.ant-select .ant-select-selection-item {
    font-size: 12px;
  }

  .ant-select-selection.ant-select-selection--single {
    font-size: 12px;
  }

  p {
    @media (max-width: 800px) {
      font-size: 14px;
    }
  }
`;

export const ContainerAllItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.4rem;

  @media (max-width: 800px) {
    margin-top: 0px;
  }
`;

export const CardProfile = styled.form`
  max-width: 1480px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  border: 1px solid ${Colors.black10};
  padding: 19px 41px 25px 42px;

  h2.title {
    font-family: "Cairo";
    font-weight: 900;
    font-size: 1.5rem;
    margin-bottom: 0px;
    color: ${Colors.black100};

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }

  box-shadow: 0px 4px 10px rgba(18, 25, 34, 0.05);
  border-radius: 20px;

  @media (max-width: 800px) {
    margin-left: 0px;
    box-shadow: none;
    padding: 0;
    border: 0;
  }

  div.full-button {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;

    margin-top: 60px;
    padding-top: 60px;

    border-top: 1px solid #e9e7ee;

    @media (max-width: 800px) {
      padding-top: 0px;
    }
  }

  div.full-button-save {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 60px;
    padding-top: 60px;

    border-top: 1px solid #e9e7ee;

    align-items: center;
    justify-content: space-between;

    @media (max-width: 800px) {
      padding-top: 20px;
      flex-direction: column;
    }

    @media (max-width: 1140px) {
      margin-top: 20px;
    }
  }
`;

export const InputsForm = styled.div`
  width: 100%;

  margin-top: 1.87rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-bottom: 0px;

  @media (max-width: 800px) {
    h2 {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;

      color: ${Colors.black100};
    }
  }

  @media (max-width: 800px) {
    margin-top: 30px;
  }

  div.type-document {
    margin-top: 23px;

    h3 {
      margin-bottom: 0px;
    }

    div.checks {
      margin-top: 20px;

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: red;
        border-color: transparent;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;

        color: ${Colors.secondary70};
      }
    }
  }

  div.input-full {
    @media (max-width: 800px) {
      width: 100%;

      img {
        max-width: 12px;
        max-height: 12px;
      }
    }
  }

  input {
    width: 96%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid ${Colors.secondary20};
    color: ${Colors.secondary70};
    font-size: 20px;
    line-height: 160%;
    padding: 0px 0px 4px 23px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  input.w-100 {
    width: 100%;
  }

  div.all-input {
    margin-top: 20px;
  }
`;

export const ButtonEdit = styled.button`
  display: flex;
  cursor: pointer;
  border: 0;
  align-items: center;
  justify-content: center;

  border-radius: 14px;
  width: 262px;
  height: 60px;
  background-color: ${Colors.primary10};
  color: ${Colors.primary100};
  font-weight: bold;
  font-size: 20px;

  span {
    margin-left: 10px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 38px;
    margin-top: 53px;

    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    border-radius: 8px;

    color: ${Colors.primary100};

    div.icon {
      display: none;
    }
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    font-family: "Poppins";
    margin-left: 13px;
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;

export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div.all-input {
    width: 94%;

    div.select {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      /* identical to box height, or 32px */

      /* Secundaria/secundaria 70 */

      color: #66588a;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    flex-direction: column;

    div.all-input {
      width: 100%;
    }
  }
`;

interface ContainerInputProps {
  error?: boolean;
}

export const ContainerInput = styled.div<ContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  min-height: 110px;

  @media (max-width: 800px) {
    min-height: 40px;
  }

  input {
    font-family: "Poppins";
    width: 96%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 20px;
    line-height: 160%;
    padding: 0px 0px 4px 23px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
    /* margin-bottom: 0px;
      margin-top: 23px; */
  }
`;

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 20px;
    line-height: 160%;
    padding: 0px 0px 4px 23px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
    /* margin-bottom: 0px;
      margin-top: 23px; */
  }
`;

export const ButtonCancel = styled.button`
  cursor: pointer;
  border: 0;
  width: 262px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${Colors.primary10};
  border-radius: 14px;
  margin-bottom: 0px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;

    text-align: center;

    color: ${Colors.primary100};

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  @media (max-width: 1140px) {
    width: 100%;
  }
`;

export const ButtonSave = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 262px;
  height: 60px;

  background: ${Colors.primary100};
  /* box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15); */
  border-radius: 14px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;

    color: ${Colors.white};
    margin-left: 10px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  @media (max-width: 1140px) {
    width: 100%;
  }

  @media (max-width: 880px) {
    margin-top: 20px;
  }
`;
