import { useEffect, useState, useCallback } from "react";

import { Radio, Select, Spin } from "antd";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { LoadingOutlined } from "@ant-design/icons";
import { RiCalendar2Line, RiUserLine } from "react-icons/ri";
import { BsPhone } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import { MdSave } from "react-icons/md";

import { useAuth } from "contexts/AuthContext";

import { useUser } from "services/Hook/User";

import { authClient } from "services/authClient";

import { Colors } from "constants/colors";

import { UserType } from "types";

import { transformerDateToSend } from "utils/transformerDate";
import { cnpj, cpf, date, phone } from "utils/masks";
import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  ContainerAllItems,
  CardProfile,
  ButtonEdit,
  InputsForm,
  FormLabel,
  FormFull,
  ContainerInput,
  ContainerInputFull,
  StyledSelect,
  ButtonSave,
  ButtonCancel,
} from "./styles";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { Title } from "components/Title";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { SidebarProfile } from "components/SidebarProfile";

const { Option } = Select;

type AddressFormData = {
  name: string;
  cell_phone: string;
  birthdate: string;
  gender: string;
  document: string;
};

const addressFormSchema = yup.object().shape({
  name: yup.string().required("E-mail obrigatório"),
  // cell_phone: yup.string().required("Senha obrigatória"),
  // birthdate: yup.string().required("Senha obrigatória"),
  // gender: yup.string().required("Senha obrigatória"),
  // document: yup.string().required("Senha obrigatória"),
  // default: yup.string().required("Senha obrigatória"),
});

const metaTags = {
  title: `Dados pessoais - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

export default function PersonalData() {
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const { user, hydratationUser } = useAuth();

  const [isEditing, setIsEditing] = useState(false);

  const { register, handleSubmit, formState, reset } = useForm<AddressFormData>(
    {
      resolver: yupResolver(addressFormSchema),
    }
  );

  const { data } = useUser<UserType>(user?.id ?? "");

  const [gender, setGender] = useState(data?.gender);

  const { errors, isSubmitting } = formState;

  useEffect(() => {
    let userObject = {
      name: data?.name,
      cell_phone: data?.cell_phone,
      birthdate: data?.formattedBirthdate,
      gender: data?.gender,
      document: data?.document,
    };

    reset(userObject);
  }, [reset, data]);

  useEffect(() => {
    setGender(user?.gender);
  }, [user]);

  const handleKeyUpPhone = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      phone(e);
    },
    []
  );

  const handleKeyUpDate = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      date(e);
    },
    []
  );

  const [value, setValue] = useState("1");

  useEffect(() => {
    if (user && user?.type_document !== null) {
      setValue(user?.type_document);
    }
  }, [user, user?.type_document]);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const handleKeyUpDocument = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (user?.type_document === "2" || value === "2") {
        cnpj(e);
        return;
      }

      cpf(e);
    },
    [user?.type_document, value]
  );

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const handleUpdateUser: SubmitHandler<AddressFormData> = async ({
    name,
    birthdate,
    cell_phone,
    document,
  }) => {
    try {
      await authClient.put(`/users/update/${user?.id}`, {
        name,
        birthdate: birthdate ? transformerDateToSend(birthdate) : birthdate,
        cell_phone,
        document,
        gender,
      });

      hydratationUser();
      setIsEditing(false);
      alert("Usuario atualizado com sucesso");
    } catch (err) {
      alert("os Dados do usuario são invalidos");
    }
  };

  const handleChange = useCallback((value) => {
    setGender(value);
  }, []);

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <ListBreadcrumb
            data={[
              { name: "Home", link: "/" },
              { name: "Perfil", link: "/" },
            ]}
          />

          <Title />

          <ContainerAllItems>
            <SidebarProfile />
            <CardProfile onSubmit={handleSubmit(handleUpdateUser)}>
              <h2 className="title">Dados pessoais</h2>

              <InputsForm>
                <ContainerInputFull
                  className="input-full"
                  error={!!errors?.name}
                >
                  <FormLabel>
                    <RiUserLine size={23} color={Colors.secondary40} />
                    {value === "1" ? <h2>Nome</h2> : <h2>Razão social</h2>}
                  </FormLabel>

                  <input
                    className="w-100"
                    {...register("name")}
                    defaultValue={data?.name}
                    disabled={!isEditing}
                  />

                  {errors.name ? (
                    <span className="error">Insira um nome</span>
                  ) : null}
                </ContainerInputFull>

                {/* RiCalendar2Line */}

                <FormFull>
                  <ContainerInput
                    className="all-input"
                    error={!!errors.cell_phone}
                  >
                    <FormLabel>
                      <BsPhone size={23} color={Colors.secondary40} />
                      <h2>Telefone</h2>
                    </FormLabel>

                    <input
                      {...register("cell_phone")}
                      defaultValue={data?.cell_phone}
                      onKeyUp={handleKeyUpPhone}
                      disabled={!isEditing}
                    />
                  </ContainerInput>

                  <ContainerInput className="all-input">
                    {value === "1" ? (
                      <>
                        <FormLabel>
                          <RiCalendar2Line
                            size={23}
                            color={Colors.secondary40}
                          />
                          <h2>Data de nascimento</h2>
                        </FormLabel>

                        <input
                          {...register("birthdate")}
                          defaultValue={data?.birthdate}
                          onKeyUp={handleKeyUpDate}
                          disabled={!isEditing}
                        />
                      </>
                    ) : null}
                  </ContainerInput>
                </FormFull>

                {user?.document === null ? (
                  <div className="type-document">
                    <h3>Escolha um tipo de identificação</h3>

                    <div className="checks">
                      <Radio.Group onChange={onChange} value={value}>
                        <Radio value={"1"}>CPF</Radio>
                        <Radio value={"2"}>CNPJ</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                ) : null}

                <FormFull>
                  <div className="all-input">
                    <FormLabel>
                      {value === "1" ? <h2>CPF</h2> : <h2>CNPJ</h2>}
                    </FormLabel>

                    <input
                      defaultValue={user?.document}
                      {...register("document")}
                      onKeyUp={handleKeyUpDocument}
                      disabled={!isEditing}
                    />
                  </div>

                  <div className="all-input">
                    {value === "1" ? (
                      <>
                        <FormLabel>
                          <h2>Gênero</h2>
                        </FormLabel>

                        <div className="select">
                          <StyledSelect
                            placeholder={"Escolha um gênero"}
                            loading={!user}
                            value={gender}
                            bordered={false}
                            disabled={!isEditing}
                            style={{
                              width: "100%",
                              borderTop: "1px solid transparent",
                              borderRight: "1px solid transparent",
                              borderLeft: "1px solid transparent",
                              borderBottom: `2px solid ${Colors.secondary20}`,
                              outline: 0,
                              color: `${Colors.secondary70}`,
                              fontSize: "1em",
                              marginTop: 3,
                            }}
                            onChange={handleChange}
                          >
                            <Option value="1">
                              <p>Feminino</p>
                            </Option>
                            <Option value="2">
                              <p>Masculino</p>
                            </Option>
                            <Option value="3">
                              <p>Não informar</p>
                            </Option>
                          </StyledSelect>
                        </div>
                      </>
                    ) : null}
                  </div>
                </FormFull>
              </InputsForm>

              {isEditing ? (
                <div className="full-button-save">
                  <ButtonCancel
                    type="button"
                    onClick={() => setIsEditing(false)}
                  >
                    <span>Cancelar</span>
                  </ButtonCancel>

                  <ButtonSave type="submit">
                    {!isSubmitting ? (
                      <MdSave
                        className="icon-d"
                        size={23}
                        color={Colors.white}
                      />
                    ) : (
                      <Spin spinning={isSubmitting} indicator={antIcon} />
                    )}
                    <span>Salvar</span>
                  </ButtonSave>
                </div>
              ) : (
                <div className="full-button">
                  <ButtonEdit type="button" onClick={() => setIsEditing(true)}>
                    <div className="icon">
                      <FaPen size={18} color={Colors.primary100} />
                    </div>

                    <span>Editar</span>
                  </ButtonEdit>
                </div>
              )}
            </CardProfile>
          </ContainerAllItems>
        </ContainerItems>
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
