import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { RiArrowDropRightLine, RiInformationLine } from "react-icons/ri";

import {
  Container,
  Section,
  FlexTitle,
  QuestionsContainer,
  TitleIconContainer,
  IconContainer,
  ContainerQuestions,
  Question,
} from "./styles";

import { Flex } from "containers/Flex";
import { Breadcrumb } from "components/Breadcrumb";

interface Props {
  handleCards: (card: any) => void;
  card: any;
}

const CardMenu: React.FC<Props> = ({ handleCards, card }) => {
  const navigate = useNavigate();

  const [questions] = useState([
    {
      category: "1",
      id: "termos_usuario",
      title: "Termos de uso gerais",
    },
    {
      category: "1",
      id: "privacidade_usuario",
      title: "Política de privacidade",
    },
    {
      category: "1",
      id: "cookies_usuario",
      title: "Política de Cookies",
    },
    {
      category: "2",
      id: "termos_organizador",
      title: "Termos de uso gerais",
    },
    {
      category: "2",
      id: "organizador_obrigatoriedade",
      title: "Obrigatoriedades Legais",
    },
    {
      category: "2",
      id: "organizador_processamento_de_dados",
      title: "Acordo de processamento de dados",
    },
    {
      category: "2",
      id: "privacidade_organizador",
      title: "Política de privacidade",
    },
    {
      category: "2",
      id: "cookies_organizador",
      title: "Política de Cookies",
    },
  ]);

  const handleQuestion = (id: any, category: any) => {
    navigate(`/term/${category}/${id}`);
  };

  return (
    <Container className="first-container">
      <Section>
        <div className="content">
          <QuestionsContainer>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <span
                  onClick={() => {
                    handleCards("");
                  }}
                >
                  Início
                </span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{card?.title}</Breadcrumb.Item>
            </Breadcrumb>

            <FlexTitle>
              <TitleIconContainer>{card?.icon}</TitleIconContainer>
              <div className="title">{card?.title}</div>
            </FlexTitle>
            <ContainerQuestions>
              {questions.map((item: any, index: any) => {
                if (item?.category === card.category) {
                  return (
                    <Question
                      key={index}
                      onClick={() => handleQuestion(item.id, item.category)}
                    >
                      <Flex alignItems="center">
                        <IconContainer>
                          <RiInformationLine />
                        </IconContainer>
                        {item.title}
                      </Flex>
                      <RiArrowDropRightLine fontSize={32} />
                    </Question>
                  );
                }
                return "";
              })}
            </ContainerQuestions>
          </QuestionsContainer>
        </div>
      </Section>
    </Container>
  );
};

export default CardMenu;
