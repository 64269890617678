import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-bottom: 100px;
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
`;

export const Background = styled.div`
  height: 320px;

  @media (max-width: 1200px) {
    height: 390px;
  }

  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 100px;

  background: url(${"https://eventximages.nyc3.digitaloceanspaces.com/capa-organizer.png"});
  background-repeat: no-repeat;

  @media (max-width: 800px) {
    width: 100%;
    height: 115px;
    margin-top: 65px;
    background: url(${"https://eventximages.nyc3.digitaloceanspaces.com/capa-organizer-mobile.png"});
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const ContainerHeading = styled.div`
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    height: 100%;
  }

  @media (max-width: 1320px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  div.content-header {
    display: flex;
    flex-direction: row;

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Heading = styled.header`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
  }

  @media (max-width: 800px) {
    width: 115px;
    height: 115px;
    margin-top: 25px;
  }
`;

export const Content = styled.div`
  margin-left: 24px;

  h2 {
    color: ${Colors.black100};
    font-weight: bold;
    font-size: 40px;
    line-height: 160%;
  }

  span {
    color: ${Colors.black100};
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ContainerAllEvents = styled.div`
  display: flex;
  flex-direction: row;

  div.events-d {
    display: flex;
    flex-direction: row;
    align-items: center;

    div.badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background: ${Colors.secondary10};
      border-radius: 12px;
    }

    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 160%;
      color: ${Colors.secondary100};
      margin-top: 8px;
      margin-left: 12px;
    }
  }

  div.events-r {
    margin-left: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;

    div.badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background: ${Colors.secondary10};
      border-radius: 12px;
    }

    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 160%;
      color: ${Colors.secondary100};
      margin-top: 8px;
      margin-left: 12px;
    }
  }
`;

export const ContentMobile = styled.div`
  @media (min-width: 800px) {
    display: none;
  }

  margin-top: 12px;

  h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    color: ${Colors.secondary100};
    text-align: center;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
`;

export const ContainerAllEventsMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div.events-d {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;

    div {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: ${Colors.secondary70};
        margin-top: 6px;
        margin-left: 7px;
      }
    }

    h4 {
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      color: ${Colors.secondary40};
    }
  }

  div.events-r {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: ${Colors.secondary70};
        margin-top: 6px;
        margin-left: 7px;
      }
    }

    h4 {
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      color: ${Colors.secondary40};
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1200px) {
    margin-top: 30px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  width: 262px;
  height: 60px;
  margin-left: 10px;
  background: ${Colors.primary10};
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    color: ${Colors.primary100};
    margin-left: 12px;
  }
`;

export const ContainerTabs = styled.div`
  .ant-tabs-tab-active {
    color: ${Colors.primary100} !important;
  }
  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Colors.primary100} !important;
    font-weight: 500;
  }
  @media (max-width: 1230px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ContainerTabsMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  width: 100%;

  background: ${Colors.white};
  min-height: 400px;

  @media (min-width: 800px) {
    display: none;
  }

  .ant-tabs-nav {
    width: 100% !important;
    padding-left: 28px;
    padding-right: 28px;
  }

  .ant-tabs-tab {
    display: block;
    flex: 1;
    text-align: center;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }

  .ant-tabs-tab-active {
    color: ${Colors.primary100} !important;
  }
  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Colors.primary100} !important;
    font-weight: 500;
  }
`;

export const AboutMobile = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 18px 20px;
  border: 1px solid ${Colors.border60};
  border-radius: 10px;

  h2 {
    margin-bottom: 14px;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: #11142d;
  }

  p + p {
    margin-top: 12px;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    color: ${Colors.gray10};
  }
`;

export const ListCardsMobile = styled.div``;

export const DivSelect = styled.div`
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
  margin-top: 31px;
`;

export const ContainerAboutMobile = styled.div`
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
`;

export const ContactMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: ${Colors.gray20};
    opacity: 0.75;
    margin-top: 34px;
  }

  div.cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    div.card {
      width: 140px;
      height: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid ${Colors.border20};
      box-shadow: 0px 2px 10px rgba(108, 93, 211, 0.05);
      border-radius: 8px;

      h3 {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: ${Colors.black100};
        margin-top: 10px;
      }
    }
  }
`;

export const FooterItems = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 38px;

  h3 {
    font-weight: 600;
    font-size: 11px;
    line-height: 21px;
    color: ${Colors.black50};
  }
`;

export const ButtonMoreItems = styled.button`
  width: 100%;
  height: 38px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 34px;

  border: 0;
  background: ${Colors.primary100};
  box-shadow: 0px 5px 30px rgba(212, 0, 80, 0.25);
  border-radius: 8px;

  span {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: ${Colors.white};
  }
`;

export const ContainerListMobile = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 28px 10px 28px;
  margin-top: 26px;

  background: linear-gradient(
    180deg,
    #f8f8fa 89.75%,
    rgba(248, 248, 250, 0) 101.35%
  );

  h2.header {
    font-weight: bold;
    font-size: 9px;
    line-height: 12px;
    text-transform: uppercase;
    color: ${Colors.gray10};
    opacity: 0.75;
    margin-top: 12px;
  }
`;

export const HeaderSearching = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 63px;
  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  border-radius: 14px;

  div.filters {
    width: 18%;
    height: 100%;
    display: flex;
    border-left: 1px solid ${Colors.border30};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 31px 0px 40px;

    @media (max-width: 1180px) {
      width: 28%;
    }
  }
`;

export const HeaderEventBar = styled.div`
  width: 80%;
  display: flex;

  padding-left: 25px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div.icons {
    width: 7%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1180px) {
      width: 10%;
      margin-right: 10px;
    }
  }

  div.buttons {
    button {
      cursor: pointer;
      background: transparent;
      border: 0;
      margin-left: 38px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 34px;
      color: ${Colors.black30};
    }

    button.first {
      margin-left: 0px;
    }

    button.active {
      font-weight: bold;
      font-size: 18px;
      line-height: 34px;

      color: ${Colors.black100};
    }
  }
`;

export const InformationsOfOrganizer = styled.div`
  padding: 40px 60px;

  h2 {
    font-weight: bold;
    font-size: 32px;
    line-height: 160%;
    color: ${Colors.black100};
  }

  p {
    margin-top: 30px;
    font-weight: normal;
    font-size: 22px;
    line-height: 160%;
    color: ${Colors.black70};
  }
`;

export const ButtonEmptyEvent = styled.button`
  cursor: pointer;
  border: 0;
  width: 362px;
  height: 60px;
  background: ${Colors.primary10};
  border-radius: 14px;
  margin-top: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    color: ${Colors.primary100};
    margin-left: 10px;
  }
`;
