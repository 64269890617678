import { Colors } from "constants/colors";
import styled from "styled-components";

import { Modal } from "antd";

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ModalStyledTicket = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerItems = styled.div`
  margin-top: 170px;
  max-width: 1280px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 28px;
  padding-left: 28px;

  @media (max-width: 1024px) {
    margin-top: 104px;
  }

  div.header-title {
    h1.title {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 160%;

      color: ${Colors.black100};
      margin-top: 54px;
    }

    @media (max-width: 800px) {
      display: none;
    }
  }
`;

export const ContainerAllItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardTerms = styled.div`
  width: 100%;
  min-height: 541px;
  display: flex;

  flex-direction: column;
  margin-top: 2.34rem;

  border: 1px solid ${Colors.black10};
  padding: 2.34rem 6.37rem;

  @media (max-width: 682px) {
    padding: 2.34rem 1.37rem;
  }

  box-shadow: 0px 4px 10px rgba(18, 25, 34, 0.05);
  border-radius: 20px;

  p.text-content {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.03rem;
    margin-top: 2.62rem;
    margin-bottom: 0px;

    text-align: justify;
    color: ${Colors.black70};
  }

  div.header {
    h4 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 1.31rem;
      margin-bottom: 0px;

      color: ${Colors.black100};
    }

    div.date-header {
      margin-top: 1.4rem;
    }

    border-bottom: 1px solid ${Colors.black10};
    padding-bottom: 0.6rem;
  }
`;

export const ButtonClose = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  width: 12.32rem;
  height: 2.81rem;

  background: ${Colors.primary10};
  border-radius: 14px;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    text-align: center;
    color: ${Colors.primary100};
  }
`;

export const ButtonConfirm = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;

  width: 12.32rem;
  height: 2.81rem;

  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 14px;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;

    font-size: 0.93rem;
    text-align: center;

    color: ${Colors.white};
  }
`;

export const ContainerModalConfirmReverse = styled.div`
  margin-top: 53px;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  div.texts-confirm {
    margin-top: 29px;

    h3 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;

      font-size: 1.5rem;
      margin-bottom: 0px;
      text-align: center;

      color: ${Colors.secondary100};
    }

    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 0.93rem;

      text-align: center;
      color: ${Colors.black90};
      margin-top: 0.65rem;
    }
  }

  div.buttons {
    margin-top: 17.5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerButtonsCancel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 4.7rem;

  @media (max-width: 682px) {
    flex-direction: column;
  }

  button.cancel {
    cursor: pointer;
    outline: 0;
    border: 0;

    width: 18.23rem;
    height: 3.84rem;

    background: ${Colors.primary10};
    border-radius: 14px;

    @media (max-width: 682px) {
      width: 100%;
    }

    span {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 1.21rem;
      text-align: center;
      color: ${Colors.primary100};
    }
  }

  button.confirm {
    cursor: pointer;
    outline: 0;
    border: 0;

    width: 18.23rem;
    height: 3.84rem;

    @media (max-width: 682px) {
      width: 100%;
      margin-top: 20px;
    }

    background: ${Colors.primary100};
    box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
    border-radius: 14px;

    span {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 1.21rem;
      text-align: center;

      color: ${Colors.white};
    }
  }
`;

export const ArticleContP = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.03rem;
  margin-top: 2.62rem;
  margin-bottom: 0px;

  text-align: justify;
  color: ${Colors.black70};
  text-indent: 50px;
`;

export const ArticleContPLast = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.03rem;
  margin-top: 1.62rem;
  margin-bottom: 0px;

  text-align: justify;
  color: ${Colors.black70};
  text-indent: 50px;
`;

export const ArticleContSpan = styled.p`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 1.03rem;
  margin-top: 2.62rem;
  margin-bottom: 0px;

  text-align: justify;
  color: ${Colors.black90};
`;
