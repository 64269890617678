import { useCallback, useEffect, useRef, useState } from "react";

import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import axios from "axios";

import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import PlacesAutocomplete from "react-places-autocomplete";

import { RiCloseCircleLine } from "react-icons/ri";
import { FiMapPin } from "react-icons/fi";

import { useSearchCityAndState } from "contexts/SearchCityAndState";
import Constats from "constants/Datas";
import { Colors } from "constants/colors";

import {
  ContainerModal,
  ContainerSelect,
  ModalStyled,
  Center,
  MainCardBorder,
  NormalCardBorder,
} from "./styles";

import arrow_location from "assets/arrow-location.svg";

interface City {
  value: string;
  label: string;
}

interface AddressOptions {
  city?: string;
  state?: string;
}

interface SuggestionsType {
  formattedSuggestion: {
    mainText: string;
    secondaryText: string;
  };
}

export function ModalCity() {
  const {
    handleCancelSearch,
    handleOkSearch,
    isModalVisibleSearch,
    showModalSearch,
  } = useSearchCityAndState();

  const inputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const locationPath = useLocation();

  const { pathname } = locationPath;

  const splitLocation = pathname.split("/");

  const [address, setAddress] = useState("");

  const [citiesMap, setCitiesMap] = useState<City[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [addressOptions, setAddressOptions] = useState<AddressOptions | null>(
    null
  );

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 1);
  }, [isModalVisibleSearch, showModalSearch]);

  const address_city = searchParams.get("address_city");

  useEffect(() => {
    if (address_city) {
    }
  }, [address_city, searchParams]);

  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: 0, lng: 0 },
  });

  useEffect(() => {
    setCitiesMap(Constats.States);
  }, []);

  useEffect(() => {
    let filterTimeout: NodeJS.Timeout;

    const doCityFilter = () => {
      clearTimeout(filterTimeout);
      if (!address) return setCitiesMap(Constats.States);

      filterTimeout = setTimeout(async () => {
        setCitiesMap(
          Constats.States.filter((city) =>
            city.label.toLowerCase().includes(address.toLowerCase())
          )
        );
      }, 500);
    };

    if (address) {
      doCityFilter();
    }
  }, [address]);

  useEffect(() => {
    async function getLocation() {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          setLocation({
            loaded: true,
            coordinates: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
        }
      );

      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.coordinates.lat},${location.coordinates.lng}&key=${process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY}`
        );

        const { data } = response;

        const [firstResult] = data.results;

        const { address_components } = firstResult;

        const [_t, _d, _g, city, state] = address_components;

        const { long_name } = city;

        const { long_name: state_name } = state;

        setAddressOptions({
          city: long_name,
          state: state_name,
        });

        localStorage.setItem("city_search", long_name);
      } catch (err) {}
    }

    const manual_city = localStorage.getItem("manual_city_search");

    if (navigator.geolocation && manual_city !== "true") {
      getLocation();
    }
  }, [location.coordinates.lat, location.coordinates.lng]);

  async function handlePermission() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          setLocation({
            loaded: true,
            coordinates: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
        }
      );

      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.coordinates.lat},${location.coordinates.lng}&key=${process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY}`
        );

        const { data } = response;

        const [firstResult] = data.results;

        const { address_components } = firstResult;

        const [_t, _d, _g, city] = address_components;

        const { long_name } = city;

        navigate({
          pathname: "/search",
          search: `?${createSearchParams({
            address_city: long_name,
            page: "1",
          })}`,
        });

        handleOkSearch();
      } catch (err) {}
    }
  }

  function handleAddCitySearch(city: string) {
    handleOkSearch();

    if (city.length === 2) {
      if (splitLocation[1] === "search") {
        setSearchParams({
          ...Object.fromEntries([...searchParams]),
          address_state: city,
          page: "1",
        });

        return;
      }

      navigate(`/search?address_state=${city}&page=1`);

      return;
    }

    if (splitLocation[1] === "search") {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        address_city: city,
        page: "1",
      });

      return;
    }

    navigate(`/search?address_city=${city}&page=1`);
  }

  const city_search = localStorage.getItem("city_search");
  const manual_city_search = localStorage.getItem("manual_city_search");

  const handleSelect = useCallback(
    (address: string, placeId?: string, suggestion?: SuggestionsType) => {
      handleOkSearch();

      if (suggestion) {
        const findState = Constats.States.find(
          (state) => state.label === suggestion?.formattedSuggestion?.mainText
        );

        if (findState) {
          navigate(`/search?address_state=${findState.value}&page=1`);

          return;
        }

        navigate(
          `/search?address_city=${suggestion?.formattedSuggestion?.mainText}&page=1`
        );

        return;
      }

      const split1 = address.split(/[,-]+/);

      if (split1[0]) {
        navigate(`/search?address_city=${split1[0]}&page=1`);
      }
    },
    [handleOkSearch, navigate]
  );

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  const handleOnchange = useCallback((address) => {
    setAddress(address);
  }, []);

  return (
    <ModalStyled
      width={857}
      visible={isModalVisibleSearch}
      onOk={handleOkSearch}
      style={{ borderRadius: "20px" }}
      onCancel={handleCancelSearch}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Localização</h1>

        <PlacesAutocomplete
          value={address}
          onChange={handleOnchange}
          onSelect={handleSelect}
          shouldFetchSuggestions
          debounce={1000}
          searchOptions={{
            types: ["locality"],
            componentRestrictions: { country: ["br"] },
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                autoFocus
                ref={inputRef}
                {...getInputProps({
                  placeholder: "Insira o local desejado...",
                  className: "location-search-input",
                })}
              />

              <ContainerSelect>
                <MainCardBorder
                  active={
                    !!manual_city_search &&
                    !!city_search &&
                    manual_city_search === "false"
                      ? true
                      : false
                  }
                  onClick={handlePermission}
                >
                  <div className="icon">
                    <img src={arrow_location} alt="location-icon" />
                  </div>

                  {addressOptions === null ? (
                    <div className="content">
                      <strong className="content">
                        Usar minha localização atual
                      </strong>

                      <span>Encontre eventos acontecendo perto de você!</span>
                    </div>
                  ) : null}

                  {addressOptions !== null ? (
                    <div className="content">
                      <strong className="content">{addressOptions.city}</strong>

                      <span>{addressOptions.state}</span>
                    </div>
                  ) : null}
                </MainCardBorder>

                <div className="autocomplete-dropdown-container">
                  {loading && (
                    <Center>
                      <Spin spinning indicator={antIcon} />
                    </Center>
                  )}

                  {suggestions.map((suggestion, index) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";

                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <NormalCardBorder
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={index}
                      >
                        <FiMapPin size={16} color={Colors.primary100} />
                        <h3 className="normal">{suggestion.description}</h3>
                      </NormalCardBorder>
                    );
                  })}
                </div>

                {citiesMap.map((t, index) => (
                  <NormalCardBorder
                    key={index}
                    onClick={() => handleAddCitySearch(t.value)}
                  >
                    <FiMapPin size={16} color={Colors.primary100} />
                    <h3 className="normal">{t.label}</h3>
                  </NormalCardBorder>
                ))}
              </ContainerSelect>
            </div>
          )}
        </PlacesAutocomplete>
      </ContainerModal>
    </ModalStyled>
  );
}
