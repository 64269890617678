import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import TypeUserGridCard from "./TypeUserGridCard";
import FirstSection from "./FirstSection";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import CardMenu from "./CardMenu";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";

const PrivacyTerms: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [visibleItem, setVisibleItem] = useState<Object>("");

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleCookies = () => {
    setIsVisible(false);
  };

  const handleItem = (item: any) => {
    setVisibleItem(item);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setIsVisible(true);
  }, [setIsVisible]);

  return (
    <>
      <Header />
      <FirstSection />
      {visibleItem == "" && <TypeUserGridCard handleItem={handleItem} />}
      {visibleItem && <CardMenu handleCards={handleItem} card={visibleItem} />}
      {isVisible && <Popup handleVisible={handleCookies} />}
      <Footer />
    </>
  );
};

export default React.memo(PrivacyTerms);
