import React, { useState } from "react";

import { Tooltip } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { ImFacebook, ImTwitter } from "react-icons/im";
import { RiShareFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { BiLike } from "react-icons/bi";

import {
  Container,
  Section,
  Image,
  ShareButton,
  Title,
  ShareContainer,
} from "./styles";

import { StyledTag } from "components/Tag";

interface Props {
  data: any;
}

const ImageContainer: React.FC<Props> = ({ data }) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  return (
    <Container className="first-container">
      <Section>
        <div className="content">
          <Image bg={data?.photo} thumb={data?.thumb}>
            <ShareContainer>
              <div className="share-btn">
                {/* <ShareButton
                    href="http://www.pudim.com.br"
                    target="_blank"
                    shape="circle"
                    icon={<RiInstagramFill />}
                  /> */}
                <ShareButton
                  href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                  target="_blank"
                  shape="circle"
                  icon={<ImFacebook />}
                />
                <ShareButton
                  href={`https://twitter.com/intent/tweet?url=${window.location.href}&text=Achei%20esse%20evento%20aqui%20muito%20daora!%20Todo%20mundo%20deveria%20vir%20comigo%20nessa%20%F0%9F%98%89!`}
                  target="_blank"
                  shape="circle"
                  icon={<ImTwitter />}
                />
                <ShareButton
                  href={`mailto:info@example.com?&subject=&cc=&bcc=&body=${window.location.href}%0AAchei%20esse%20evento%20aqui%20muito%20daora!%20Todo%20mundo%20deveria%20vir%20comigo%20nessa%20%F0%9F%98%89!`}
                  target="_blank"
                  shape="circle"
                  icon={<MdEmail />}
                />
              </div>
              <div className="responsive-share">
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={() => {
                    setIsLinkCopied(true);
                    setTimeout(() => {
                      setIsLinkCopied(false);
                    }, 5000);
                  }}
                >
                  <Tooltip
                    placement="left"
                    title={
                      isLinkCopied ? "Link copiado com sucesso!" : "Copiar link"
                    }
                  >
                    <ShareButton
                      shape="circle"
                      icon={isLinkCopied ? <BiLike /> : <RiShareFill />}
                    />
                  </Tooltip>
                </CopyToClipboard>
              </div>
              <p className="label-share">Compartilhe!</p>
            </ShareContainer>
          </Image>

          <div className="tag-and-share">
            <div className="tag-elements">
              {data?.categories.map((item: any, index: number) => {
                return <StyledTag key={index}>{item.name}</StyledTag>;
              })}
            </div>
          </div>

          <Title>{data?.name}</Title>
        </div>
      </Section>
    </Container>
  );
};

export default ImageContainer;
