import { Link } from "react-router-dom";

import { FeaturedEvent } from "types";

import {
  Container,
  ContainerItems,
  ContainerList,
  ListCards,
  CardEvent,
  ContentCardEvent,
  ContainerBorderAvailability,
} from "./styles";

import { BadgeTicketStatus } from "components/BadgeTicketStatus";

import pin from "assets/pin.svg";

interface ListSearchProps {
  title: string;
  events: FeaturedEvent[] | undefined;
  total?: number;
}

export function ListSearch({ title, events, total }: ListSearchProps) {
  return (
    <Container>
      <ContainerItems>
        <div className="results">
          <h4>
            <span>{total} RESULTADOS</span>
          </h4>
        </div>

        <ContainerList>
          <ListCards>
            {events?.map((item) => (
              <Link to={`/${item?.nickname}`}>
                <CardEvent key={item.id}>
                  <img
                    className="icon"
                    src={item.thumb}
                    width="472px"
                    height="188px"
                    alt=""
                  />

                  <ContentCardEvent>
                    <div className="content">
                      <h2>
                        {item?.name?.length > 36
                          ? `${item?.name?.substring(0, 36)}...`
                          : item?.name}
                      </h2>

                      <div className="date">
                        <span>
                          {item.formattedDate} {"\u00B7"} {item.formattedDay}
                        </span>
                        <div className="city">
                          <img src={pin} width="13px" height="14px" alt="pin" />

                          <span>
                            {item.address_city}, {item.address_state}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="list-categories">
                      {item.categories.length
                        ? item.categories.map((category) => (
                            <div className="badge" key={category.id}>
                              <span>{category.name}</span>
                            </div>
                          ))
                        : null}
                    </div>

                    <ContainerBorderAvailability>
                      <div className="description">
                        <span>Por</span>
                        <strong>{item?.organizer?.name}</strong>
                      </div>

                      <BadgeTicketStatus active={item?.tickets?.length > 0} />
                    </ContainerBorderAvailability>
                  </ContentCardEvent>
                </CardEvent>
              </Link>
            ))}
          </ListCards>
        </ContainerList>
      </ContainerItems>
    </Container>
  );
}
