import { Colors } from "constants/colors";
import { Modal } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const ContainerItems = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  padding-right: 38px;
  padding-left: 38px;
  margin-top: 9.04rem;

  div.container-image {
    height: 900px;
    min-width: 840px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    img {
      /* object-fit: fill; */
      /* zoom: 0.7; */
    }

    margin-top: 0px;

    @media (max-width: 1180px) {
      display: none;
    }
  }
`;

export const FormForgot = styled.form`
  max-width: 363px;

  height: 100%;

  /* padding-top: 60px; */
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: 1200px) {
    width: 100%;

    div.all-inputs {
      width: 100%;

      span.error {
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;

        color: ${Colors.error20};
      }
    }

    div.button-signIn {
      width: 100%;
    }

    div.or {
      width: 100%;
      div.line {
        min-width: 120px !important;
      }
    }

    div.div-google {
      width: 100%;
    }
  }

  div.cadaster {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      font-weight: normal;
      font-size: 14px;

      color: ${Colors.black100};
    }

    p {
      font-weight: normal;
      font-size: 14px;
      margin-top: 12px;
      margin-left: 10px;
      text-decoration-line: underline;

      color: ${Colors.primary100};
    }
  }

  div.or {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    span {
      padding: 0px 11px;
    }

    div.line {
      min-width: 160px;
      width: 100%;
      border: 1px solid ${Colors.secondary100};
      opacity: 0.2;
    }
  }

  div.enter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    @media (max-width: 800px) {
      display: none;
    }

    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;

      color: ${Colors.black100};
    }
  }

  div.new {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;

    @media (max-width: 800px) {
      flex-direction: column;
      margin-top: 48px;
    }

    div.cadaster-mobile {
      width: 100%;
      margin-top: 16px;
      @media (min-width: 800px) {
        display: none;
      }
    }

    div.cadaster-web {
      @media (max-width: 800px) {
        display: none;
      }
    }

    span {
      font-weight: normal;

      font-size: 14px;

      color: ${Colors.black100};
    }

    p {
      cursor: pointer;
      font-weight: normal;
      font-size: 14px;
      margin-top: 12px;
      margin-left: 10px;
      text-decoration-line: underline;

      color: ${Colors.primary100};
    }
  }

  div.new-code {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 47px;

    span {
      font-weight: normal;

      font-size: 11px;

      color: ${Colors.black100};
      margin-bottom: 0px;
    }

    p {
      cursor: pointer;
      font-weight: normal;
      font-size: 11px;
      margin-bottom: 0px;
      margin-left: 10px;
      text-decoration-line: underline;

      color: ${Colors.primary100};
    }
  }

  div.forgot {
    margin-top: 50px;
    width: 100%;

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    input {
      margin-right: 10px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    a {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;

      text-decoration-line: underline;

      color: ${Colors.primary100};
    }
  }
`;

export const FormSignIn = styled.form`
  max-width: 363px;

  height: 100%;

  padding-top: 60px;
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: 1200px) {
    width: 100%;

    div.all-inputs {
      width: 100%;
    }

    div.button-signIn {
      width: 100%;
    }

    div.or {
      width: 100%;
      div.line {
        min-width: 120px !important;
      }
    }

    div.div-google {
      width: 100%;
    }
  }

  div.cadaster {
    margin-top: 39px;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      font-weight: normal;
      font-size: 14px;

      color: ${Colors.black100};
    }

    p {
      font-weight: normal;
      font-size: 14px;
      margin-top: 12px;
      margin-left: 10px;
      text-decoration-line: underline;

      color: ${Colors.primary100};
    }
  }

  div.or {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    span {
      padding: 0px 11px;
    }

    div.line {
      min-width: 160px;
      width: 100%;
      border: 1px solid ${Colors.secondary100};
      opacity: 0.2;
    }
  }

  div.enter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    @media (max-width: 800px) {
      display: none;
    }

    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;

      color: ${Colors.black100};
    }
  }

  div.new {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;

    @media (max-width: 800px) {
      flex-direction: column;
      margin-top: 48px;
    }

    div.cadaster-mobile {
      width: 100%;
      margin-top: 16px;
      @media (min-width: 800px) {
        display: none;
      }
    }

    div.cadaster-web {
      @media (max-width: 800px) {
        display: none;
      }
    }

    span {
      font-weight: normal;

      font-size: 14px;

      color: ${Colors.black100};
    }

    p {
      cursor: pointer;
      font-weight: normal;
      font-size: 14px;
      margin-top: 12px;
      margin-left: 10px;
      text-decoration-line: underline;

      color: ${Colors.primary100};
    }
  }

  div.forgot {
    margin-top: 50px;
    width: 100%;

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    input {
      margin-right: 10px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    a {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;

      text-decoration-line: underline;

      color: ${Colors.primary100};
    }
  }
`;

export const FormSignUp = styled.form`
  max-width: 363px;
  width: 100%;
  height: 100%;

  padding-top: 60px;
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  div.button-signUp {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    div.all-inputs {
      width: 100%;
    }

    div.button-signIn {
      width: 100%;
    }

    div.or {
      width: 100%;
      div.line {
        min-width: 120px !important;
      }
    }

    div.div-google {
      width: 100%;
    }
  }

  div.cadaster {
    margin-top: 39px;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      font-weight: normal;
      font-size: 14px;

      color: ${Colors.black100};
    }

    p {
      font-weight: normal;
      font-size: 14px;
      margin-top: 12px;
      margin-left: 10px;
      text-decoration-line: underline;

      color: ${Colors.primary100};
    }
  }

  div.or {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    span {
      padding: 0px 11px;
    }

    div.line {
      min-width: 160px;
      width: 100%;
      border: 1px solid ${Colors.secondary100};
      opacity: 0.2;
    }
  }

  div.enter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;

      color: ${Colors.black100};
    }
  }

  div.new {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;

    span {
      font-weight: normal;
      font-size: 14px;

      color: ${Colors.black100};
    }

    p {
      cursor: pointer;
      font-weight: normal;
      font-size: 14px;
      margin-top: 12px;
      margin-left: 10px;
      text-decoration-line: underline;

      color: ${Colors.primary100};
    }
  }

  div.forgot {
    margin-top: 50px;
    width: 100%;

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    input {
      margin-right: 10px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    a {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;

      text-decoration-line: underline;

      color: ${Colors.primary100};
    }
  }
`;

interface InputContainerProps {
  error?: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  width: 363px;

  margin-top: 33px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 2px solid
    ${(props) => (props.error ? Colors.red10 : Colors.secondary20)};

  @media (max-width: 1200px) {
    width: 100%;
  }

  input {
    border: 0;
    outline: 0;
    margin-left: 24px;
    margin-bottom: 5px;
    width: 100%;
  }
`;

export const ContainerForms = styled.div`
  width: 100%;
  display: flex;
  /* margin-top: 125px; */
  flex-direction: column;

  align-items: center;

  @media (min-width: 1200px) {
    div.text {
      h2 {
        font-weight: 600;
        font-size: 9.5px;
        line-height: 10px;
        letter-spacing: 0.55em;
        text-transform: uppercase;
        color: ${Colors.secondary100};
        text-align: center;
      }

      display: none;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
    /* margin-top: 135px; */

    div.container-logo {
      img {
        max-width: 185px;
        max-height: 48px;
      }

      div.text {
        h2 {
          font-weight: 600;
          font-size: 9.5px;
          line-height: 10px;
          letter-spacing: 0.55em;
          text-transform: uppercase;
          color: ${Colors.secondary100};
          text-align: center;
        }
      }
    }
  }
`;

export const TabsCadaster = styled.div`
  display: flex;
  width: 169px;
  flex-direction: column;
  margin-top: 3.51rem;

  align-items: center;

  @media (max-width: 800px) {
    margin-top: 31px;
  }
`;

export const Tab = styled.div`
  width: 169px;
  height: 3px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TabForgot = styled.div`
  cursor: pointer;
  width: 100%;
  height: 3px;
  background: ${Colors.primary100};

  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  h2.forgot {
    margin-top: 40px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    color: ${Colors.primary100};
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

interface TabSignInProps {
  active: boolean;
}

export const TabSignIn = styled.div<TabSignInProps>`
  cursor: pointer;
  width: 50%;
  height: 3px;
  background: ${(props) =>
    props.active ? Colors.primary100 : Colors.secondary10};

  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    margin-top: 40px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    color: ${(props) => (props.active ? Colors.primary100 : Colors.black100)};
  }

  @media (max-width: 1200px) {
    width: 50%;
    justify-content: flex-start;
  }
`;

interface TabSignUpProps {
  active: boolean;
}

export const TabSignUp = styled.div<TabSignUpProps>`
  cursor: pointer;
  width: 50%;
  background: ${(props) =>
    props.active ? Colors.primary100 : Colors.secondary10};

  height: 3px;
  border-radius: 50px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h2 {
    margin-top: 40px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${(props) => (props.active ? Colors.primary100 : Colors.black100)};
  }

  @media (max-width: 1200px) {
    width: 50%;
  }
`;

interface ButtonSignInPros {
  isLoading?: boolean;
}

export const ButtonPrev = styled.button`
  cursor: pointer;
  width: 170px;
  height: 60px;
  outline: 0;
  border: 0;

  background: ${Colors.primary10};
  border-radius: 14px;

  span {
    font-family: "Cairo";
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    color: ${Colors.primary100};
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const ButtonFinal = styled.button<ButtonSignInPros>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;

  width: 170px;

  height: 60px;

  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 14px;

  color: ${Colors.white};
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;

  span {
    margin-left: ${(props) => (props.isLoading ? "5px" : 0)};
  }

  @media (max-width: 1200px) {
    width: 100%;
    margin-top: 18px;
  }
`;

export const ButtonSignIn = styled.button<ButtonSignInPros>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  margin-top: 18px;

  width: 363px;

  height: 60px;

  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 14px;

  color: ${Colors.white};
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;

  span {
    margin-left: ${(props) => (props.isLoading ? "5px" : 0)};
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const ButtonGoogle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  margin-top: 27px;

  width: 363px;

  height: 60px;

  background-color: ${Colors.white};
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 14px;

  @media (max-width: 1200px) {
    width: 100%;
  }

  span {
    color: ${Colors.black100};
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    margin-left: 19px;
  }
`;

export const AllInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  span {
    color: ${Colors.red10};
    font-size: 11px;
    margin-top: 4px;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    /* overflow: auto; */
    border-radius: 20px;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 34px 109px 64px 109px;

  @media (max-width: 800px) {
    padding: 0px;
  }

  div.logo-qr-code {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.content {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 14px;

      text-align: center;

      color: ${Colors.black90} #292c42;

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90} #292c42;
      }
    }
  }

  div.ok-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 12px;

    button {
      cursor: pointer;
      border: 0;
      background: ${Colors.primary100};
      width: 262px;
      height: 82px;

      background: #d40050;
      box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
      border-radius: 14px;

      color: ${Colors.white};
      font-weight: bold;
      font-size: 26px;
      line-height: 160%;

      text-align: center;
    }
  }
`;

export const ContainerOtp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 8px;

  input {
    width: 42px;
    height: 52px;
    outline: none;

    background: ${Colors.white};
    border: 1px solid ${Colors.secondary20};
    box-sizing: border-box;
    border-radius: 8px;

    padding-left: 14px;

    font-weight: normal;
    font-size: 20px;
    line-height: 24px;

    color: ${Colors.secondary70};
  }
`;

export const ButtonCadaster = styled.button`
  border: 0;
  width: 100%;
  height: 60px;

  background: ${Colors.primary10};
  border-radius: 14px;

  font-weight: bold;
  font-size: 18px;
  line-height: 34px;

  color: ${Colors.primary100};
`;
