import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useFaqById } from "services/Hook/Faq";

import Article from "./Article";
import Results from "../FAQ/Results";

import Popup from "containers/Popup";
import ContactUsContainer from "components/ContactUsContainer";
import { Footer } from "components/Footer";
import { Header } from "components/Header";

const HelpArticle: React.FC = (props) => {
  const { id } = useParams();

  const { pathname } = useLocation();

  const { data, isValidating } = useFaqById(id);

  const [isVisible, setIsVisible] = useState(false);

  const [searchData, setSearchData] = useState();

  const [isSearch, setIsSearch] = useState(false);

  const handleCookies = () => {
    setIsVisible(false);
  };
  const handleSearching = (search: boolean) => {
    setIsSearch(search);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />

      <div style={{ paddingTop: 140 }}>
        {isSearch ? (
          <Results
            isSearching={handleSearching}
            searchData={searchData}
            isLoading={!!isValidating}
          />
        ) : null}
      </div>

      {!isSearch && <Article article={data} isLoading={isValidating} />}

      <ContactUsContainer faq />

      {isVisible && <Popup handleVisible={handleCookies} />}
      <Footer />
    </>
  );
};

export default React.memo(HelpArticle);
