import packageJson from "../../../package.json";

import { Link } from "react-router-dom";

import { FiMapPin } from "react-icons/fi";
import { Colors } from "constants/colors";

import {
  Container,
  ContainerItems,
  AllItems,
  BaseBoard,
  SocialItem,
  Title,
  FooterMobile,
} from "./styles";

import logo3 from "assets/logo3.png";

import youtube from "assets/youtube.svg";
import twitter from "assets/twitter.svg";
import linkedin from "assets/linkedin.svg";
import instagram from "assets/instagram.svg";

import googleplay from "assets/googleplay.jpg";
import applestore from "assets/applestore.jpg";

import logo_footer_mobile from "assets/logo-footer-mobile.svg";
import selo_google_play from "assets/selo-google-play.svg";
import selo_app_store from "assets/selo-app-store.svg";

interface FooterProps {
  cart?: any;
}

export function Footer({ cart }: FooterProps) {
  return (
    <Container>
      <div className="content-all" style={{ marginTop: cart ? 0 : 100 }}>
        <ContainerItems>
          <AllItems>
            <div className="company">
              <img src={logo3} alt="Logo" width={192.14} height={170.06} />

              <p>
                Oferecemos a solução mais completa para a gestão do seu evento e
                venda de ingressos. Revolucione suas vendas e viva uma nova
                experiência!
              </p>

              <nav>
                <h4>Siga-nos</h4>
                <div className="socials">
                  <SocialItem background={Colors.youtubeBackground}>
                    <a href={process.env.REACT_APP_EVENTX_YOUTUBE}>
                      <img src={youtube} alt="youtube" width={20} height={20} />
                    </a>
                  </SocialItem>

                  <SocialItem background={Colors.twitterBackground}>
                    <a href={process.env.REACT_APP_EVENTX_TWITTER}>
                      <img src={twitter} alt="twitter" width={20} height={20} />
                    </a>
                  </SocialItem>

                  <SocialItem background={Colors.linkedinBackground}>
                    <a href={process.env.REACT_APP_EVENTX_LINKEDIN}>
                      <img
                        src={linkedin}
                        alt="linkedin"
                        width={20}
                        height={20}
                      />
                    </a>
                  </SocialItem>

                  <SocialItem background={Colors.instagramBackground}>
                    <a href={process.env.REACT_APP_EVENTX_INSTAGRAM}>
                      <img
                        src={instagram}
                        alt="instagram"
                        width={20}
                        height={20}
                      />
                    </a>
                  </SocialItem>
                </div>
              </nav>
            </div>

            <>
              <ul>
                <li>
                  <h2>Menu</h2>

                  <Link to="/signin">
                    <h4>Entrar</h4>
                  </Link>

                  <Link to="/signin?page=signup">
                    <h4>Cadastre-se</h4>
                  </Link>

                  <Link to="/">
                    <h4>Eventos</h4>
                  </Link>
                  <Link to="/my-event">
                    <h4>Organizador</h4>
                  </Link>
                </li>
              </ul>

              <ul>
                <li>
                  <h2>Institucional</h2>

                  <h4>Quem somos</h4>

                  <h4>Missão</h4>

                  <h4>Propósito</h4>
                </li>
              </ul>

              <ul>
                <li>
                  <h2>Políticas</h2>

                  <Link to="/term">
                    <h4>Privacidade e Segurança</h4>
                  </Link>

                  <Link to="/term">
                    <h4>Termo de uso</h4>
                  </Link>

                  <Link to="/faq">
                    <h4>FAQ</h4>
                  </Link>
                </li>
              </ul>
            </>

            <div className="download-app">
              <h2>BAIXE NOSSO APP</h2>

              <div>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.mobile.eventx"
                  rel="noreferrer"
                >
                  <img
                    src={googleplay}
                    width={169.14}
                    height={50.06}
                    alt="googleplay"
                  />
                </a>
              </div>

              <a
                target="_blank"
                href="https://apps.apple.com/us/app/eventx-eventos-e-ingressos/id1584406908"
                rel="noreferrer"
              >
                <img
                  src={applestore}
                  width={169.14}
                  height={50.06}
                  alt="applestore"
                />
              </a>
            </div>
          </AllItems>
        </ContainerItems>
        <BaseBoard>
          <div className="content">
            <p>
              Eventx © 2022 by XGrow - Todos os Direitos Reservados -{" "}
              <span>
                v{packageJson.version} - {process.env.REACT_APP_AMBIENT}
              </span>
            </p>
            <div className="footer">
              <div>
                <FiMapPin size={18} color={Colors.primary100} />
              </div>
              <p>
                Endereço: Alameda Tocantins 956 - Alphaville - Barueri - SP.
                CEP: 06400-000
              </p>
            </div>
          </div>
        </BaseBoard>
      </div>

      <FooterMobile>
        <div className="footer-mobile">
          <div className="download-app">
            <Link to="/">
              <img
                src={logo_footer_mobile}
                alt="logo-footer-mobile"
                width="945px"
                height="380px"
              />
            </Link>
            <Title>BAIXE NOSSO APP</Title>
            <nav>
              <a href="https://play.google.com/store/apps/details?id=com.mobile.eventx">
                <img
                  src={selo_google_play}
                  alt="logo-footer-mobile"
                  width={492.14}
                  height={186.06}
                />
              </a>
              <a href="https://apps.apple.com/us/app/eventx-eventos-e-ingressos/id1584406908">
                <img
                  src={selo_app_store}
                  alt="selo-app-store"
                  width={492.14}
                  height={186.06}
                />
              </a>
            </nav>
          </div>

          <div className="util-links">
            <Title>LINKS ÚTEIS</Title>

            <nav className="links">
              <Link to="/">
                <h3>Início</h3>
              </Link>
              <Link to="/organize-seu-evento">
                <h3>Conheça a plataforma</h3>
              </Link>

              <Link to="/faq" className="last">
                <h3>FAQ</h3>
              </Link>
            </nav>

            <nav className="links">
              <Link to="/term">
                <h3>Termos</h3>
              </Link>

              <Link to="/term">
                <h3>Privacidade</h3>
              </Link>
            </nav>
          </div>

          <nav className="footer-social">
            <Title>SIGA-NOS</Title>
            <div className="socials">
              <SocialItem>
                <a
                  href="https://www.youtube.com/channel/UCngPYAKBWTjFK-g8rXqSODA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={youtube} alt="youtube" width={20} height={20} />
                </a>
              </SocialItem>

              <SocialItem>
                <a
                  href="https://twitter.com/eventx_com_br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="twitter" width={20} height={20} />
                </a>
              </SocialItem>

              <SocialItem>
                <a
                  href="https://www.linkedin.com/company/eventxeventos/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="linkedin" width={20} height={20} />
                </a>
              </SocialItem>

              <SocialItem>
                <a
                  href="https://www.instagram.com/eventx/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="instagram" width={20} height={20} />
                </a>
              </SocialItem>
            </div>
          </nav>
        </div>
        <div className="support">
          <span>
            Suporte ao cliente:{" "}
            <a href="mailto:suporte@eventx.com.br">
              <strong>suporte@eventx.com.br</strong>
            </a>
          </span>
        </div>

        <div className="contact">
          <span>
            © Copyright 2022 | v{packageJson.version} -{" "}
            {process.env.REACT_APP_AMBIENT} - A EVENTX é um produto da XGROW
            TECNOLOGIA LTDA - 40.190.903/0001-05
          </span>
        </div>
      </FooterMobile>
    </Container>
  );
}
