import { useFetchAuth, useFetchBasic } from "services/useFetch";

import {
  Event,
  EventTypeOrganizer,
  FeaturedEvent,
  Meta,
  TicketEvent,
} from "types";
import eoLocale from "date-fns/locale/pt-BR";
import { format, parseISO, addDays } from "date-fns";
import { ptBR } from "date-fns/locale";
import useSWR from "swr";
import { authClient } from "services/authClient";

export function useEventWithDiscount<Data = any, Error = any>() {
  const url: string = "/no-auth/with-discount?page=1&limit=20";

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}

interface ResponseFeaturedEvents {
  data: FeaturedEvent[];
}

export function useFeaturedEvent<Data = any, Error = any>() {
  const url: string = "/no-auth/next-events?page=1&limit=10";

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  const dataResponse = data as unknown;

  const newData = dataResponse as ResponseFeaturedEvents;

  const newResponse = newData?.data.map((featured: FeaturedEvent) => {
    return {
      ...featured,
      formattedDate: format(
        parseISO(featured.begin_date),
        "eeeeee', 'dd' 'MMM'",
        {
          locale: eoLocale,
        }
      ),
      formattedDay: `${featured.begin_hour.split(":")[0]}:${
        featured.begin_hour.split(":")[1]
      }H`,
    };
  });

  return { data: newResponse, error, mutate };
}

export function useEventByNickName<Data = any, Error = any>(id: string) {
  const url: string = `/no-auth/event-by-nickname/${id}`;

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}

interface useEventSearchProps {
  page: string;
  paramsString?: string;
}

interface ResponseCategoryEventsFilters {
  data: FeaturedEvent[];
  meta: Meta;
}

export function useEventSearch<Data = any, Error = any>({
  page,
  paramsString,
}: useEventSearchProps) {
  const partialUrl = "/no-auth/search-event";

  const url: string = `${partialUrl}?${paramsString}`;

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    key: [`${partialUrl}?${paramsString}`, page],
    method: "get",
    url,
  });

  const dataResponse = data as unknown;

  const newData = dataResponse as ResponseCategoryEventsFilters;

  const newMapData = newData?.data.map((featured: FeaturedEvent) => {
    return {
      ...featured,
      formattedDate: format(
        parseISO(featured.begin_date),
        "eeeeee', 'dd' 'MMM'",
        {
          locale: eoLocale,
        }
      ),
      formattedDay: `${featured.begin_hour.split(":")[0]}:${
        featured.begin_hour.split(":")[1]
      }H`,
    };
  });

  const newResponse = { ...newData, data: newMapData };

  return { data: newResponse, error, mutate, partialUrl };
}

export function useEventSlider<Data = any, Error = any>() {
  const url: string = "/no-auth/last-insert?page=1&limit=12";

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}

export function useMyEventDetail<Data = any, Error = any>(nickname: string) {
  const url: string = `/no-auth/event-by-nickname/${nickname}`;

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  const dataResponse = data as unknown;

  const newData = dataResponse as Event;

  const mapTickets = newData?.tickets?.map((ticket: TicketEvent) => ({
    ...ticket,
    isOpen: false,
    formattedDate: format(addDays(parseISO(ticket.created_at), 1), "dd/MM/yy", {
      locale: ptBR,
    }),
  }));

  const newMap = {
    ...newData,
    formattedDate: newData?.begin_date
      ? format(addDays(parseISO(newData?.begin_date), 1), "dd' de 'MMMM'", {
          locale: ptBR,
        })
      : "",
    formattedHour: newData?.begin_hour
      ? `${newData?.begin_hour.split(":")[0]}:${
          newData.begin_hour.split(":")[1]
        }h`
      : "",
    tickets: mapTickets,
  };

  return { data: newMap, error, mutate };
}

export function useMyEvent<Data = any, Error = any>(page: string | number) {
  const partialUrl: string = "/users/events";

  const { data, error, mutate } = useSWR<Data, Error>(
    [partialUrl, page],
    async () => {
      const url: string = `${partialUrl}?limit=10&page=${page}`;

      const response = await authClient.get(url);

      const newMapData = response.data.data.map((event: EventTypeOrganizer) => {
        return {
          ...event,
          isOpen: false,
          formattedDate: format(parseISO(event.begin_date), "dd'/'MM'/'y'", {
            locale: ptBR,
          }),
          formattedHour: `${event.begin_hour.split(":")[0]}:${
            event.begin_hour.split(":")[1]
          }h`,
        };
      });

      const newResponse = { ...response.data, data: newMapData };

      return newResponse;
    }
  );

  return { data, error, mutate, partialUrl };
}

export function useVerifyPurchase<Data = any, Error = any>(
  purchase_id: string
) {
  const url: string = `/events/verify-purchase/${purchase_id}`;

  const { data, error, mutate } = useFetchAuth<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}
