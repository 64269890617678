import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;

  img {
    width: 18.56rem;
    height: 18.56rem;
    margin-top: 3.26rem;
  }

  @media (max-width: 800px) {
    margin-top: 3.26rem;
    margin-bottom: 90px;

    img {
      max-width: 210px;
      max-height: 210px;
    }
  }

  h1 {
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 1.21rem;
    line-height: 160%;
    color: ${Colors.gray10};

    opacity: 0.75;

    @media (max-width: 800px) {
      font-size: 18px;
    }
  }

  p {
    font-family: "Open Sans";
    font-weight: normal;
    font-size: 1.03rem;
    line-height: 160%;
    text-align: center;
    width: 55%;
    color: ${Colors.gray20};

    @media (max-width: 800px) {
      font-size: 16px;
      width: 85%;
    }
  }
`;
