import { Colors } from "constants/colors";
import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`


.slick-dots li button:before {
  font-size: 12px;
  line-height: 12px;
}


@media(max-width: 1024px) {
  .slick-dots li button:before {
    margin-top: 0px;
  }
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color:${Colors.black10} !important;
  outline: none;
  background: ${Colors.black10} !important;
}


 .slick-dots li.slick-active button:before {
  color: ${Colors.secondary100} !important;
  opacity: 1;
}




 * {
    margin: 0;
    padding: 0;
    font-family: 'Cairo', sans-serif;
  }


  body {
    background: ${Colors.white};
    -webkit-font-smoothing: antialised;
    font-family: 'Cairo', sans-serif;
  }

`;
