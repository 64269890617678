import React from "react";
import { Link } from "react-router-dom";

import { Divider } from "antd";

import { RiTimer2Line } from "react-icons/ri";

import {
  Container,
  Section,
  FlexTitle,
  QuestionsContainer,
  HourContainer,
  Box,
} from "./styles";

import { Breadcrumb } from "components/Breadcrumb";

interface Props {
  article: any;
}

const HelpArticle: React.FC<Props> = ({ article }) => {
  return (
    <Container className="first-container">
      <Section>
        <div className="content">
          <QuestionsContainer>
            <div className="breadcrumb">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <Link to="/term">Início</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{article?.title}</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <Box>
              <FlexTitle>
                <p className="title">{article?.title}</p>
                <HourContainer>
                  <RiTimer2Line />
                  {<p>{article?.updated_at} - Atualizado</p>}
                </HourContainer>
                <Divider />
              </FlexTitle>

              {<div className="description">{article?.description}</div>}
            </Box>
          </QuestionsContainer>
        </div>
      </Section>
    </Container>
  );
};

export default HelpArticle;
