import React from "react";

import Slide from "react-reveal/Slide";

import { Container, Section } from "./styles";

import WomanPhone from "assets/mulher_celular.svg";
import Evento from "assets/evento_heading.svg";

import BigDots from "assets/big_dots.svg";
import SmallDots from "assets/small_dots.svg";

import AppStore from "assets/selo_app_store.svg";
import GooglePlay from "assets/selo_google_play.svg";

const FirstSection: React.FC = () => {
  return (
    <Container className="first-container">
      <img src={BigDots} alt="" className="dots" />
      <img src={SmallDots} alt="" className="dots" />
      <Section className="first-section">
        <Slide left cascade>
          <div className="content">
            <p className="life-is">A VIDA É UM</p>

            <img src={Evento} className="evento-heading" alt="" />

            <p className="description">
              A <b>EventX</b> oferece a solução mais completa para a gestão do
              seu evento e venda de ingressos. Revolucione suas vendas e viva
              uma nova experiência!
            </p>

            <div className="download-section">
              <p className="download">Faça download do App</p>
              <div className="container-download">
                <a
                  href="https://play.google.com/store/apps/details?id=com.mobile.eventx"
                  aria-label="Baixe agora para Google Play!"
                >
                  {/* <div className="download-button google-play" /> */}
                  <img className="download-button" src={GooglePlay} alt="" />
                </a>
                <a
                  href="https://apps.apple.com/us/app/eventx-eventos-e-ingressos/id1584406908"
                  aria-label="Baixe agora para Apple Store!"
                >
                  <img className="download-button" src={AppStore} alt="" />
                </a>
              </div>
            </div>
          </div>
        </Slide>
        <div className="photo">
          <Slide right>
            <img
              src={WomanPhone}
              alt=""
              className="woman-phone"
              width="524px"
              height="570px"
              loading="lazy"
            />
          </Slide>
        </div>
      </Section>
    </Container>
  );
};

export default FirstSection;
