import { Colors } from "constants/colors";
import { MdChevronRight } from "react-icons/md";
import { Container, BreadStyled } from "./styles";

import { Link } from "react-router-dom";

type BreadcrumbType = {
  link: string;
  name: string;
};

interface ListBreadcrumbProps {
  data: BreadcrumbType[];
}

export function ListBreadcrumb({ data }: ListBreadcrumbProps) {
  return (
    <Container>
      {data.map((bread, index) => {
        return (
          <div className="all-index" key={index}>
            {index + 1 < data.length ? (
              <Link to={`${bread.link}`}>
                <BreadStyled active>{bread.name}</BreadStyled>
              </Link>
            ) : null}

            {index + 1 === data.length ? (
              <BreadStyled active={false}>{bread.name}</BreadStyled>
            ) : null}

            {data.length === index + 1 ? null : (
              <MdChevronRight color={Colors.black50} />
            )}
          </div>
        );
      })}
    </Container>
  );
}
