import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
`;

export const ContainerItems = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin-top: 130px;
  padding-left: 28px;

  @media (max-width: 800px) {
    margin-top: 34px;
    width: 100%;
  }

  nav {
    /* margin-top: 70px; */

    margin-top: 3.28rem;

    background: ${Colors.white};
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    @media (max-width: 800px) {
      display: none;
    }
  }

  div.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 800px) {
      align-items: flex-start;
      justify-content: flex-start;
    }

    h2 {
      color: ${Colors.black100};
      font-weight: bold;
      font-size: 50px;
      margin-bottom: 0px;

      @media (max-width: 800px) {
        font-size: 16px;
        margin-top: 0px;
        /* line-height: 30px; */
      }
    }

    p {
      color: ${Colors.black70};
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      width: 40%;
      @media (max-width: 800px) {
        display: none;
      }
    }
  }
`;

export const CardEvent = styled.div`
  width: 100%;

  padding: 0.7rem;
  height: 22.59rem;
  margin-top: 51px;
  margin-bottom: 20px;
  box-shadow: 0px 12px 12px rgba(108, 93, 211, 0.1);
  display: flex;
  justify-content: space-between;

  border-radius: 14px;
  margin: 51px 20px 20px 0px;

  @media (max-width: 800px) {
    width: 304px;
    height: 143px;
    margin-top: 12px;
    box-shadow: none;
    box-shadow: 0px 5px 15px rgba(108, 93, 211, 0.15);
    border-radius: 5px;

    align-items: center;
  }

  @media (max-width: 500px) {
    height: 153px;
  }

  div.first {
    height: 100%;
    width: 100%;
    padding-left: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 800px) {
      height: 113px;
    }
  }

  div.image-event {
    width: 76%;
    height: 100%;

    /* img {
      border-radius: 20px;
      width: 571px;
      height: 19.26rem;

      object-fit: contain;

      @media (max-width: 800px) {
        width: 126.97px;
        height: 96px;
        border-radius: 8px;
      }
    } */
  }

  div.list-categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    @media (max-width: 800px) {
      margin-left: 5px;
      margin-top: 15px;
    }

    div.badge {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 74px;
      height: 26px;
      background: ${Colors.black10};
      border-radius: 14px;
      margin-right: 5px;
      padding: 8px;

      @media (max-width: 800px) {
        margin-bottom: 6px;
      }

      span {
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;

        @media (max-width: 800px) {
          font-weight: 600;
          font-size: 8px;
        }
      }

      @media (max-width: 800px) {
        min-width: 52px;
        height: 14px;
        margin-right: 4px;
      }
    }
  }

  div.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 800px) {
      margin-top: 8px;
      margin-left: 5px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;


    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      p {
        color: ${Colors.gray};
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;

        @media (max-width: 800px) {
          margin-top: 0px;
          font-weight: 600;
          font-size: 7px;
          line-height: 10px;
        }
      }

      strong {
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;

        @media (max-width: 800px) {
          font-weight: 600;
          font-size: 8px;
          line-height: 11px;
        }
      }
    }

    button {
      cursor: pointer;
      border: none;
      width: 175px;
      height: 60px;
      background-color: ${Colors.primary100};
      border-radius: 14px;

      color: ${Colors.white};
      font-weight: bold;
      font-size: 18px;
      line-height: 34px;

      @media (max-width: 800px) {
        display: none;
      }
    }
  }
`;

export const Content = styled.div`
  width: 88%;

  padding-left: 1.68rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1200px) {
    width: 88%;
  }

  h3 {
    color: ${Colors.secondary100};
    font-weight: 600;
    font-size: 18px;

    @media (max-width: 800px) {
      display: none;
    }
  }

  p.description {
    color: ${Colors.black70};
    font-weight: normal;
    font-size: 16px;
    /* margin-top: 15px; */

    margin-top: 0.7rem;

    @media (max-width: 800px) {
      display: none;
    }
  }

  div.head {
    display: flex;
    flex-direction: row;
    align-items: center;

    div.image-icon {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      img.img-icon {
        width: 49px !important;
        height: 59px !important;

        @media (max-width: 800px) {
         width: 8.75px !important;
         height: 10.58px !important;
        }
      }
    }

    div.content {
      display: flex;
      flex-direction: column;
      margin-top: 0px;
      margin-left: 18px;

      @media (max-width: 800px) {
        margin-left: 5px;
        width: 100%;
      }

      h2 {
        color: ${Colors.secondary100};
        font-weight: bold;
        font-size: 1.4rem;

        margin-top: 0px;
        margin-bottom: 0px;

        @media (max-width: 800px) {
          font-size: 11px;
          line-height: 13px;
          /* margin-top: 5px; */
          margin-bottom: -2px;
        }
      }

      div.date {
        display: flex;
        align-items: center;
        flex-direction: row;

        @media (max-width: 800px) {
          margin-top: 8px;
        }

        span.date {
          margin-left: 8px;
          text-transform: none;
        }

        span {
          color: ${Colors.primary100};
          font-weight: normal;
          /* font-size: 16px; */

          font-size: 0.75rem;
          line-height: 22px;
          text-transform: uppercase;

          @media (max-width: 800px) {
            font-size: 11px;
            line-height: 21px;
            font-weight: normal;
            font-size: 8px;
            line-height: 11px;
          }
        }

        div.city {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 28.5px;

          @media (max-width: 800px) {
            display: none;
          }
        }
      }
    }

    /* img {
      max-width: 77px;
      max-height: 77px;

      @media (max-width: 800px) {
        max-width: 27px;
        max-height: 27px;
      }
    } */
  }
`;

export const CardArrowLeft = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 24px;
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.border30};
`;

export const CardArrowRight = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  width: 32px;
  border-radius: 50%;

  background-color: ${Colors.secondary10};
  border: 0;
`;

// const Content = styled.div`
//   border: 1px solid #000;
//   background-image: url(${img});
//   width: 2000px;
//   height: 2000px;
// `;

interface BackgroundImageProps {
  img?: string;
}

export const BackgroundImage = styled.div<BackgroundImageProps>`
  background-image: url(${(props) => props.img ?? ""});
  background-repeat: no-repeat;

  background-size: cover;
  background-position: center center;

  border-radius: 20px;
  width: 571px;
  height: 100%;

  @media (max-width: 1200px) {
    width: 471px;
    height: 100%;
    border-radius: 8px;
    padding-right: 2px;
  }

  @media (max-width: 1040px) {
    width: 100%;
    border-radius: 8px;
    padding-right: 2px;
  }

  /* @media (max-width: 840px) {
    width: 271px;
    border-radius: 8px;
    padding-right: 2px;
  } */

  @media (max-width: 800px) {
    /* background-position-y: -20px; */
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;
