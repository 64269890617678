import React from "react";

import { Container, Section } from "./styles";

import SearchSelect from "components/SearchSelect";

interface Props {
  handleSearching: (search: boolean) => void;
  handleSearchData: (searchData: any) => void;
  handleLoading: (loading: any) => void;
}

const FirstSection: React.FC<Props> = ({
  handleSearching,
  handleSearchData,
  handleLoading,
}) => {
  return (
    <Container className="first-container">
      <Section>
        <div className="content">
          <div className="heading">
            <p className="title">Como podemos te ajudar?</p>
            <p className="subtitle">
              Tem alguma dúvida? Pesquise abaixo em nossos artigos
            </p>
          </div>

          <SearchSelect
            handleLoading={handleLoading}
            handleSearchData={handleSearchData}
            handleSearching={handleSearching}
          />
        </div>
      </Section>
    </Container>
  );
};

export default FirstSection;
