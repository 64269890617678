import { useFetchBasic } from "services/useFetch";

export function useFaq<Data = any, Error = any>() {
  const url: string = "/faq";

  const { isValidating, data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { isValidating, data, error, mutate };
}

export function useFaqById<Data = any, Error = any>(id: string | undefined) {
  const url: string = `/faq/show/${id}`;

  const { isValidating, data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { isValidating, data, error, mutate };
}
