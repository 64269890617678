import "react-spring-bottom-sheet/dist/style.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Switch, Spin } from "antd";

import { BottomSheet } from "react-spring-bottom-sheet";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { LoadingOutlined } from "@ant-design/icons";
import { RiKeyLine } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { BiCodeAlt, BiSearch } from "react-icons/bi";
import { IoMdAdd, IoMdCloseCircle } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";

import { Event } from "types";

import { authClient } from "services/authClient";

import { useMyEventDetail } from "services/Hook/Event";

import { useIntegrations } from "contexts/IntegrationsContext";

import { useDisclosure } from "hooks/useDisclosure";

import Data from "constants/Datas";
import { Colors } from "constants/colors";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  HeaderSearching,
  ContainerList,
  ListCards,
  CardIntegration,
  ContainerEmptyEvent,
  ButtonEmptyEvent,
  HeaderEventBar,
  FormLabel,
  ContainerItemsMobile,
  ContainerBorderMobile,
  ButtonNewIntegration,
  ContainerBottom,
  ContainerListIntegrationsMobile,
  ContainerSearch,
  ContainerListAllIntegrationsMobile,
  ContainerActiveIntegrationMobile,
  FormIntegratedKeys,
  ButtonCancelMobile,
  ButtonSaveMobile,
  ButtonConnect,
} from "./styles";

import { HeaderIntegration } from "./HeaderIntegration";
import { ModalAddIntegration } from "./ModalAddIntegration";
import { ModalUpdateIntegration } from "./ModalUpdateIntegration";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { PaginationEmpty } from "components/PaginationOrganizerEmpty";
import { successNotification } from "components/Notification";
import { ListBreadcrumb } from "components/ListBreadcrumb";

import edit_integration from "assets/edit-integration.svg";
import remove_integration from "assets/remove-integration.svg";

type Integration = {
  id: number;
  label: string;
  short: string;
  description: string;
  thumb: string;
  link: string;
  integrationData: {
    event_id: string;
    id: number;
    key: string;
    status: true;
    token: string;
    type: number;
    user_id: number;
  } | null;
};

type IntegrationResponse = {
  event_id: string;
  id: number;
  key: string;
  status: true;
  token: string;
  type: number;
  user_id: number;
};

type IntegrationFormData = {
  key: string;
  token: string;
};

const integrationFormSchema = yup.object().shape({
  key: yup.string().required("Key obrigatória"),
  token: yup.string().required("Token obrigatório"),
});

export default function MyEventIntegration() {
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const [activeTab, setActiveTab] = useState(0);

  const [activeSwitch, setActiveSwitch] = useState(true);

  const { nickname } = useParams();

  const { data } = useMyEventDetail<Event>(nickname ?? "");

  const { isModalVisible, handleOk, handleCancel, showModal } = useDisclosure();

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const {
    activeIntegrations,
    activeNewIntegration,
    activeEditIntegration,
    handleChangeNewIntegration,
    handleChangeEditIntegration,
    eventIntegrations,
    eventIntegrationsActive,
    eventIntegrationsInative,
    selectedIntegration,
    setSelectedIntegration,
    handleShowModalEdit,
    idsActiveIntegrations,
    open,
    setOpen,
    getIntegrations,
    eventIntegrationUpdate,
    setEventIntegrationUpdate,
    handleActiveIntegrations,
    setIdUpdateIntegration,
    handleActiveNewIntegration,
    onDismiss,
    isModalEditVisible,
    handleCancelEdit,
    handleOkEdit,
    idUpdateIntegration,
    handleActiveEditIntegration,
    setNickname,
  } = useIntegrations();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<IntegrationFormData>({
    resolver: yupResolver(integrationFormSchema),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1, isSubmitting: isSubmitting1 },
  } = useForm<IntegrationFormData>({
    resolver: yupResolver(integrationFormSchema),
  });

  useEffect(() => {
    setNickname(nickname ?? "");
  }, [nickname, setNickname]);

  const createIntegration: SubmitHandler<IntegrationFormData> = async ({
    key,
    token,
  }) => {
    try {
      const dataRequest = {
        type: selectedIntegration?.id,
        event_id: data?.id,
        key,
        token,
        status: activeSwitch,
      };

      await authClient.post("/integration/create", {
        ...dataRequest,
      });

      reset();

      getIntegrations();

      handleChangeNewIntegration(false);

      successNotification(
        "Configurações salvas! ",
        `As configurações da integração ${selectedIntegration?.label} foram salvas com sucesso.`,
        2
      );
    } catch (err) {
      alert("erro ao adicionar a integração");
    }
  };

  useEffect(() => {
    reset1({
      key: eventIntegrationUpdate?.integrationData?.key,
      token: eventIntegrationUpdate?.integrationData?.token,
    });

    setActiveSwitch(eventIntegrationUpdate?.integrationData?.status ?? true);
  }, [eventIntegrationUpdate, reset1]);

  const updatedIntegration: SubmitHandler<IntegrationFormData> = async ({
    key,
    token,
  }) => {
    try {
      const formData = {
        type: selectedIntegration?.id,
        event_id: data?.id,
        key,
        token,
        status: activeSwitch,
      };

      await authClient.put(
        `/integration/edit/${eventIntegrationUpdate?.integrationData?.id}`,
        {
          ...formData,
        }
      );

      await getIntegrations();

      reset1();

      handleChangeEditIntegration(false);

      setEventIntegrationUpdate(null);

      successNotification(
        "Configurações salvas! ",
        `As configurações da integração ${selectedIntegration?.label} foram salvas com sucesso.`,
        2
      );
    } catch (err) {
      alert("erro ao atualizar a integração");
    }
  };

  async function handleUpdateStatusIntegration(
    e: boolean,
    integration: Integration
  ) {
    try {
      const formData = {
        type: integration?.integrationData?.type,
        event_id: integration?.integrationData?.event_id,
        key: integration?.integrationData?.key,
        token: integration?.integrationData?.token,
        status: e,
      };

      await authClient.put(
        `/integration/edit/${integration?.integrationData?.id}`,
        {
          ...formData,
        }
      );

      // dar mutate no swr

      await getIntegrations();

      successNotification(
        "Configurações salvas! ",
        `As configurações da integração ${integration.label} foram salvas com sucesso.`,
        2
      );
    } catch (err) {}
  }

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  return (
    <>
      {!activeIntegrations &&
      !activeNewIntegration &&
      !activeEditIntegration ? (
        <Header />
      ) : null}

      {activeIntegrations || activeNewIntegration || activeEditIntegration ? (
        <HeaderIntegration />
      ) : null}

      <Container>
        <ContainerItems>
          <div className="bread">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                {
                  name: String(nickname) ?? "",
                  link: `/my-event/${nickname}`,
                },
                { name: "Integrações", link: "/" },
              ]}
            />
          </div>

          <h1>Integrações</h1>

          <HeaderSearching>
            <HeaderEventBar>
              <div className="buttons">
                <button
                  onClick={() => setActiveTab(0)}
                  className={activeTab === 0 ? "first active" : "first"}
                >
                  Todas
                </button>
                <button
                  onClick={() => setActiveTab(1)}
                  className={activeTab === 1 ? "active" : ""}
                >
                  Ativas
                </button>
                <button
                  onClick={() => setActiveTab(2)}
                  className={activeTab === 2 ? "active" : ""}
                >
                  Inativas
                </button>
              </div>
            </HeaderEventBar>
          </HeaderSearching>

          {activeTab === 0 ? (
            <ContainerList>
              <ListCards>
                {eventIntegrations.map((integration) => (
                  <CardIntegration key={integration.id}>
                    <div className="header-integration">
                      <img
                        src={integration.thumb}
                        width={50}
                        height={50}
                        alt="analytics"
                      />

                      {integration.integrationData === null ? (
                        <ButtonConnect
                          onClick={() => {
                            setSelectedIntegration(integration);
                            showModal();
                          }}
                        >
                          <span>Conectar</span>
                        </ButtonConnect>
                      ) : null}

                      {integration.integrationData !== null ? (
                        <div className="switch">
                          <Switch
                            checked={
                              integration.integrationData?.status ?? false
                            }
                            onChange={(e) => {
                              handleUpdateStatusIntegration(e, integration);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>

                    <div className="content-integration">
                      <div className="texts">
                        <h2>{integration.label}</h2>

                        <span>{integration.short}</span>
                      </div>

                      {integration.integrationData !== null ? (
                        <div
                          onClick={() => {
                            setSelectedIntegration(integration);
                            handleShowModalEdit(integration.id);
                          }}
                          className="badge"
                        >
                          <MdEdit size={22} color={Colors.primary100} />
                        </div>
                      ) : null}
                    </div>
                  </CardIntegration>
                ))}
              </ListCards>
            </ContainerList>
          ) : null}

          {activeTab === 1 ? (
            <>
              {eventIntegrationsActive.length === 0 ? (
                <ContainerEmptyEvent>
                  <h2>Não há nada por aqui :(</h2>
                  <p>Não há integrações ativas no momento.</p>

                  <ButtonEmptyEvent onClick={() => setActiveTab(0)}>
                    <span>Ir para todas as integrações</span>
                  </ButtonEmptyEvent>
                </ContainerEmptyEvent>
              ) : null}

              <ContainerList>
                {eventIntegrationsActive.length > 0 ? (
                  <ListCards>
                    {eventIntegrationsActive.map((integration) => (
                      <CardIntegration key={integration.id}>
                        <div className="header-integration">
                          <img
                            src={integration.thumb}
                            width={50}
                            height={50}
                            alt="analytics"
                          />

                          {!idsActiveIntegrations?.includes(integration.id) ? (
                            <ButtonConnect
                              onClick={() => {
                                setSelectedIntegration(integration);
                                showModal();
                              }}
                            >
                              <span>Conectar</span>
                            </ButtonConnect>
                          ) : null}

                          {idsActiveIntegrations.includes(integration.id) ? (
                            <div className="switch">
                              <Switch
                                checked={
                                  integration.integrationData?.status ?? false
                                }
                                onChange={(e) => {
                                  handleUpdateStatusIntegration(e, integration);
                                }}
                              />
                            </div>
                          ) : null}
                        </div>

                        <div className="content-integration">
                          <div className="texts">
                            <h2>{integration.label}</h2>

                            <span>{integration.short}</span>
                          </div>

                          {idsActiveIntegrations?.includes(integration.id) ? (
                            <div
                              onClick={() => {
                                setSelectedIntegration(integration);
                                handleShowModalEdit(integration.id);
                              }}
                              className="badge"
                            >
                              <MdEdit size={22} color={Colors.primary100} />
                            </div>
                          ) : null}
                        </div>
                      </CardIntegration>
                    ))}
                  </ListCards>
                ) : null}
              </ContainerList>
            </>
          ) : null}

          {activeTab === 2 ? (
            <>
              {eventIntegrationsInative.length === 0 ? (
                <ContainerEmptyEvent>
                  <h2>Não há nada por aqui :(</h2>
                  <p>Não há integrações ativas no momento.</p>

                  <ButtonEmptyEvent onClick={() => setActiveTab(0)}>
                    <span>Ir para todas as integrações</span>
                  </ButtonEmptyEvent>
                </ContainerEmptyEvent>
              ) : null}

              <ContainerList>
                {eventIntegrationsInative.length > 0 ? (
                  <ListCards>
                    {eventIntegrationsInative.map((integration) => (
                      <CardIntegration key={integration.id}>
                        <div className="header-integration">
                          <img
                            src={integration.thumb}
                            width={50}
                            height={50}
                            alt="analytics"
                          />

                          {!idsActiveIntegrations?.includes(integration.id) ? (
                            <ButtonConnect
                              onClick={() => {
                                setSelectedIntegration(integration);
                                showModal();
                              }}
                            >
                              <span>Conectar</span>
                            </ButtonConnect>
                          ) : null}

                          {idsActiveIntegrations.includes(integration.id) ? (
                            <div className="switch">
                              <Switch
                                checked={
                                  integration.integrationData?.status ?? false
                                }
                                onChange={(e) => {
                                  handleUpdateStatusIntegration(e, integration);
                                }}
                              />
                            </div>
                          ) : null}
                        </div>

                        <div className="content-integration">
                          <div className="texts">
                            <h2>{integration.label}</h2>

                            <span>{integration.short}</span>
                          </div>

                          {idsActiveIntegrations?.includes(integration.id) ? (
                            <div
                              onClick={() => {
                                setSelectedIntegration(integration);
                                handleShowModalEdit(integration.id);
                              }}
                              className="badge"
                            >
                              <MdEdit size={22} color={Colors.primary100} />
                            </div>
                          ) : null}
                        </div>
                      </CardIntegration>
                    ))}
                  </ListCards>
                ) : null}
              </ContainerList>
            </>
          ) : null}

          {activeTab === 9 ? (
            <>
              <ContainerEmptyEvent>
                <h2>Não há nada por aqui :(</h2>
                <p>
                  Não há eventos disponíveis no momento. Mas você pode encontrar
                  outros eventos de seu interesse abaixo:
                </p>

                <ButtonEmptyEvent onClick={() => setActiveTab(0)}>
                  <span>Ir para todas as integrações</span>
                </ButtonEmptyEvent>
              </ContainerEmptyEvent>
            </>
          ) : null}

          {activeTab === 0 ? <PaginationEmpty /> : null}
        </ContainerItems>

        {!activeNewIntegration &&
        !activeEditIntegration &&
        !activeIntegrations ? (
          <ContainerItemsMobile>
            <header>
              <div className="first">
                <h1>Integrações</h1>
              </div>
            </header>

            <ContainerBorderMobile>
              <h3>INTEGRAÇÕES ATIVAS</h3>

              {eventIntegrationsActive.length > 0 ? (
                <>
                  {eventIntegrationsActive.map((integration) => (
                    <div className="integration">
                      <div className="first">
                        <div className="row">
                          <img
                            src={integration.thumb}
                            width={26}
                            height={29}
                            alt={integration.label}
                          />
                          <h3>{integration.label}</h3>
                        </div>
                        <p>{integration.short}</p>
                      </div>

                      <div className="second">
                        {integration.integrationData !== null ? (
                          <div className="switch">
                            <Switch
                              checked={
                                integration.integrationData?.status ?? false
                              }
                              onChange={(e) => {
                                handleUpdateStatusIntegration(e, integration);
                              }}
                            />
                          </div>
                        ) : null}

                        <BsThreeDots
                          onClick={() => {
                            setIdUpdateIntegration(integration.id);
                            setSelectedIntegration(integration);
                            setOpen(true);
                          }}
                          style={{ cursor: "pointer" }}
                          size={16}
                          color={Colors.black100}
                        />
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </ContainerBorderMobile>

            <ButtonNewIntegration onClick={handleActiveIntegrations}>
              <IoMdAdd size={18} color={Colors.primary100} />
              <span>Adicionar nova integração</span>
            </ButtonNewIntegration>

            <ContainerBorderMobile>
              <h3>INTEGRAÇÕES INATIVAS</h3>

              {eventIntegrationsInative.length > 0 ? (
                <>
                  {eventIntegrationsInative.map((integration) => (
                    <div className="integration">
                      <div className="first">
                        <div className="row">
                          <img
                            src={integration.thumb}
                            width={26}
                            height={29}
                            alt={integration.label}
                          />
                          <h3>{integration.label}</h3>
                        </div>
                        <p>{integration.short}</p>
                      </div>

                      <div className="second">
                        {integration.integrationData !== null ? (
                          <div className="switch">
                            <Switch
                              checked={
                                integration.integrationData?.status ?? false
                              }
                              onChange={(e) => {
                                handleUpdateStatusIntegration(e, integration);
                              }}
                            />
                          </div>
                        ) : null}

                        <BsThreeDots
                          onClick={() => {
                            setIdUpdateIntegration(integration.id);
                            setSelectedIntegration(integration);
                            setOpen(true);
                          }}
                          style={{ cursor: "pointer" }}
                          size={16}
                          color={Colors.black100}
                        />
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </ContainerBorderMobile>
          </ContainerItemsMobile>
        ) : null}

        {activeIntegrations &&
        !activeNewIntegration &&
        !activeEditIntegration ? (
          <ContainerListIntegrationsMobile>
            <header>
              <div className="first">
                <h1>Integrações disponíveis</h1>
              </div>
            </header>

            <ContainerSearch>
              <nav>
                <div className="searchInput">
                  <input placeholder="Pesquise..." />
                </div>

                <div className="search">
                  <BiSearch size={18} color={Colors.primary100} />
                </div>
              </nav>
            </ContainerSearch>

            <ContainerListAllIntegrationsMobile>
              {eventIntegrations.map((integration) => (
                <div
                  onClick={() => {
                    if (integration.integrationData !== null) {
                      return;
                    }

                    setSelectedIntegration(integration);
                    handleActiveNewIntegration();
                  }}
                  className="integration"
                  key={integration.id}
                >
                  <div className="row">
                    <div className="first">
                      <img
                        src={integration.thumb}
                        width={26}
                        height={29}
                        alt="google"
                      />
                      <h3>{integration.label}</h3>
                    </div>

                    {integration.integrationData !== null ? (
                      <>
                        {integration.integrationData.status === true ? (
                          <div className="badge-active-integration">
                            <FaCheckCircle size={9} color={Colors.white} />

                            <div>
                              <p className="badge">ATIVO</p>
                            </div>
                          </div>
                        ) : null}

                        {!integration.integrationData.status ? (
                          <div className="badge-inative-integration">
                            <IoMdCloseCircle size={9} color={Colors.gray15} />

                            <div>
                              <p className="badge">INATIVO</p>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                  <p>{integration.short}</p>
                </div>
              ))}
            </ContainerListAllIntegrationsMobile>
          </ContainerListIntegrationsMobile>
        ) : null}

        {activeNewIntegration &&
        selectedIntegration &&
        !activeIntegrations &&
        !activeEditIntegration ? (
          <ContainerActiveIntegrationMobile
            onSubmit={handleSubmit(createIntegration)}
          >
            <div className="container-description-integration">
              <div className="row">
                <div className="first">
                  <img
                    src={selectedIntegration.thumb}
                    width={26}
                    height={29}
                    alt={selectedIntegration.label}
                  />
                  <h3>{selectedIntegration.label}</h3>
                </div>
              </div>
              <p>
                {selectedIntegration?.description}{" "}
                <a
                  target="_blank"
                  href={selectedIntegration?.link}
                  rel="noreferrer"
                >
                  Saiba mais sobre o {selectedIntegration?.label}.
                </a>
              </p>
            </div>

            <div className="border-activated">
              <h2 className="active">Ativar integração</h2>

              <Switch
                defaultChecked={true}
                checked={activeSwitch}
                onChange={(e) => {
                  setActiveSwitch(e);
                }}
              />
            </div>

            <FormIntegratedKeys>
              <h3 className="information">Informe os dados abaixo</h3>

              <div className="all-input-first">
                <FormLabel>
                  <RiKeyLine size={23} color={Colors.secondary40} />
                  <h2>ID do {selectedIntegration.label}</h2>
                </FormLabel>

                <input type="text" {...register("key")} />

                {errors.key && <span>{errors.key.message}</span>}
              </div>

              <div className="all-input">
                <FormLabel>
                  <BiCodeAlt size={23} color={Colors.secondary40} />
                  <h2>Token de acesso</h2>
                </FormLabel>

                <input type="text" {...register("token")} />

                {errors.token && <span>{errors.token.message}</span>}
              </div>

              <ButtonCancelMobile
                onClick={() => handleChangeNewIntegration(false)}
                type="button"
              >
                Cancelar
              </ButtonCancelMobile>
            </FormIntegratedKeys>

            <ButtonSaveMobile type="submit">
              {isSubmitting ? (
                <Spin spinning={isSubmitting} indicator={antIcon} />
              ) : null}

              {!isSubmitting ? <span>Salvar integração</span> : null}
            </ButtonSaveMobile>
          </ContainerActiveIntegrationMobile>
        ) : null}

        {activeEditIntegration &&
        selectedIntegration &&
        !activeIntegrations &&
        !activeNewIntegration ? (
          <ContainerActiveIntegrationMobile
            onSubmit={handleSubmit1(updatedIntegration)}
          >
            <div className="container-description-integration">
              <div className="row">
                <div className="first">
                  <img
                    src={selectedIntegration.thumb}
                    width={26}
                    height={29}
                    alt={selectedIntegration.label}
                  />
                  <h3>{selectedIntegration.label}</h3>
                </div>
              </div>
              <p>
                {selectedIntegration?.description}{" "}
                <a
                  target="_blank"
                  href={selectedIntegration?.link}
                  rel="noreferrer"
                >
                  Saiba mais sobre o {selectedIntegration?.label}.
                </a>
              </p>
            </div>

            <div className="border-activated">
              <h2 className="active">Ativar integração</h2>

              <Switch
                checked={activeSwitch}
                onChange={(e) => {
                  setActiveSwitch(e);
                }}
              />
            </div>

            <FormIntegratedKeys>
              <h3 className="information">Informe os dados abaixo</h3>

              <div className="all-input-first">
                <FormLabel>
                  <RiKeyLine size={23} color={Colors.secondary40} />
                  <h2>ID do {selectedIntegration.label}</h2>
                </FormLabel>

                <input type="text" {...register1("key")} />

                {errors1.key && <span>{errors1.key.message}</span>}
              </div>

              <div className="all-input">
                <FormLabel>
                  <BiCodeAlt size={23} color={Colors.secondary40} />
                  <h2>Token de acesso</h2>
                </FormLabel>

                <input type="text" {...register1("token")} />

                {errors1.token && <span>{errors1.token.message}</span>}
              </div>

              <ButtonCancelMobile
                onClick={() => handleChangeEditIntegration(false)}
                type="button"
              >
                Cancelar
              </ButtonCancelMobile>
            </FormIntegratedKeys>

            <ButtonSaveMobile type="submit">
              {isSubmitting1 ? (
                <Spin spinning={isSubmitting1} indicator={antIcon} />
              ) : null}

              {!isSubmitting1 ? <span>Salvar integração</span> : null}
            </ButtonSaveMobile>
          </ContainerActiveIntegrationMobile>
        ) : null}

        <BottomSheet
          open={open}
          onDismiss={onDismiss}
          style={{ borderRadius: 20 }}
        >
          <ContainerBottom>
            <div
              className="row"
              onClick={() => {
                if (idUpdateIntegration) {
                  handleActiveEditIntegration(idUpdateIntegration);
                }
              }}
            >
              <div className="badge-edit">
                <img src={edit_integration} width={13} height={13} alt="edit" />
              </div>
              <h3 className="edit">Editar integração</h3>
            </div>

            <div className="row">
              <div className="badge-remove">
                <img
                  src={remove_integration}
                  width={13}
                  height={13}
                  alt="remove"
                />
              </div>
              <h3 className="remove">Excluir integração</h3>
            </div>
          </ContainerBottom>
        </BottomSheet>

        {data?.id ? (
          <ModalAddIntegration
            isModalVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            selectedIntegration={selectedIntegration}
            event_id={data?.id}
            getIntegrations={getIntegrations}
          />
        ) : null}

        {data?.id ? (
          <ModalUpdateIntegration
            isModalVisible={isModalEditVisible}
            handleOk={handleOkEdit}
            handleCancel={handleCancelEdit}
            selectedIntegration={selectedIntegration}
            setEventIntegrationUpdate={setEventIntegrationUpdate}
            eventIntegrationUpdate={eventIntegrationUpdate}
            event_id={data?.id}
            getIntegrations={getIntegrations}
          />
        ) : null}
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
