import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Col, Divider, Row, Space, Form } from "antd";

import {
  RiCloseCircleLine,
  RiContactsLine,
  RiLock2Line,
  RiSmartphoneLine,
  RiUser2Line,
} from "react-icons/ri";

import api from "services/api";
import { useAuth } from "contexts/AuthContext";

import { Colors } from "constants/colors";

import {
  CardFooter,
  CardForm,
  PurchaseHeader,
  StyledModal,
  PrimaryButton,
  SecondaryButton,
  StyledRadioGroup,
  RadioButton,
  FormOptions,
} from "./styles";

import { Checkbox } from "components/Form/Checkbox";
import { FormItem } from "components/Form/FormItem";
import { Input } from "components/Form/Input";
import { Radio } from "components/Form/Radio";
import { cnpj, cpf, phone } from "utils/masks";

interface ModalAuthProps {
  handleAuth: () => void;
  openSteps: () => void;
  openForgot: (item: boolean) => void;
}

interface IUserProps {
  id: string;
  name: string;
  email: string;
  document: string;
  type_document: string;
  birthdate: string;
  gender: string;
  cell_phone: string;
  avatar: string;
  verify_token: string;
  organizer: any;
}

type HasError = {
  response: {
    data: {
      error: string;
    };
  };
};

const ModalAuth: React.FC<ModalAuthProps> = ({
  handleAuth,
  openSteps,
  openForgot,
}) => {
  const { user, signIn } = useAuth();
  const [value, setValue] = useState("cadastrado");
  const [formEditable, setFormEditable] = useState<boolean>(true);
  const [formRemember, setFormRemember] = useState(false);

  const checkLogin = async (values: any) => {
    const loginFormValue = values;
    const email = loginFormValue.email.toLowerCase() || "";
    const password = loginFormValue.password || "";

    setFormEditable(false);

    try {
      await signIn({
        email,
        password,
        stayOnPage: true,
      });

      openSteps();
    } catch (err) {
      alert("Email ou senha invalidos");
      setFormEditable(true);
    }
  };

  const forgotPassword = () => {
    openForgot(true);
  };

  const handleKeyUpPhone = useCallback((e: FormEvent<HTMLInputElement>) => {
    phone(e);
  }, []);

  const handleKeyUpCpf = useCallback((e: FormEvent<HTMLInputElement>) => {
    cpf(e);
  }, []);

  const handleKeyUpCnpj = useCallback((e: FormEvent<HTMLInputElement>) => {
    cnpj(e);
  }, []);

  const Login = () => {
    return (
      <>
        <Form
          name="loginForm"
          onFinish={checkLogin}
          layout="vertical"
          initialValues={{ remember: true }}
        >
          <Row gutter={20}>
            <Col lg={12} xs={24}>
              <FormItem
                label={
                  <>
                    <RiUser2Line />
                    Email
                  </>
                }
                name="email"
                required
                rules={[
                  {
                    required: true,
                    message: "Por favor, insira o Email.",
                  },
                ]}
              >
                <Input placeholder="Insira o email" />
              </FormItem>
            </Col>
            <Col lg={12} xs={24}>
              <FormItem
                label={
                  <>
                    <RiLock2Line />
                    Senha
                  </>
                }
                name="password"
                required
                rules={[
                  {
                    required: true,
                    message: "Por favor, insira a senha.",
                  },
                ]}
              >
                <Input type="password" placeholder="*********" />
              </FormItem>
            </Col>
            <FormOptions>
              <Checkbox>Lembrar de mim</Checkbox>
              <Link to="/forgot-password">Esqueci minha senha</Link>
            </FormOptions>
          </Row>
          <CardFooter>
            <SecondaryButton>Voltar</SecondaryButton>
            <PrimaryButton htmlType="submit" loading={!formEditable}>
              Prosseguir
            </PrimaryButton>
          </CardFooter>
        </Form>
      </>
    );
  };

  const Register = () => {
    const [documentType, setDocumentType] = useState("1");
    const [adressData, setAdressData] = useState<{
      logradouro: string;
      bairro: string;
      localidade: string;
    }>();
    const [formRef] = Form.useForm();

    const checkRegister = async (values: any) => {
      const loginFormValue = values;
      const expression =
        /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

      if (
        expression.test(String(loginFormValue.email).toLowerCase()) == false
      ) {
        alert("E-mail incorreto, por favor verifique!");
      } else if (loginFormValue.password.length < 8) {
        alert(
          "Sua senha deve ter no mínimo oito caracteres, por favor verifique!"
        );
      } else if (loginFormValue.password != loginFormValue.retype_password) {
        alert("A senhas não conferem, por favor verifique!");
      } else {
        putRegister(values);
      }
    };

    const putRegister = async (values: any) => {
      const loginFormValue = values;

      const credentials = {
        email: loginFormValue.email.toLowerCase(),
        password: loginFormValue.password,
        name: loginFormValue.name,
        cell_phone: loginFormValue.cell_phone,
        type_document: documentType,
        document:
          documentType == "1" ? loginFormValue.cpf : loginFormValue.cnpj,
        cpf: loginFormValue.cpf,
        cnpj: loginFormValue.cnpj,
      };

      localStorage.setItem(
        "@ventX-formRegisterValues",
        JSON.stringify(credentials)
      );

      try {
        setFormEditable(false);
        const response = await api.post("/complete-register", credentials);
        const user = response;

        localStorage.setItem("@ventX-formRegisterValues", "");

        checkLogin({
          email: loginFormValue.email.toLowerCase(),
          password: loginFormValue.password,
        });
      } catch (err) {
        const response = err as HasError;

        alert(response.response.data.error);
        setFormEditable(true);
      }
    };

    var formRegisterValues = localStorage.getItem("@ventX-formRegisterValues");
    var formRegisterArray = [];

    if (formRegisterValues !== null && formRegisterValues !== "") {
      formRegisterArray = JSON.parse(formRegisterValues);
    }

    useEffect(() => {
      formRef.setFieldsValue({
        address: adressData?.logradouro,
        address_district: adressData?.bairro,
        address_city: adressData?.localidade,
      });
    }, [adressData]);
    return (
      <>
        <Form
          name="loginForm"
          onFinish={checkRegister}
          layout="vertical"
          scrollToFirstError
          style={{ width: "100%" }}
          initialValues={formRegisterArray}
          form={formRef}
        >
          <div className="form-section">
            <p className="title">Dados pessoais e de acesso</p>

            <Row gutter={20}>
              <Col xs={24}>
                <FormItem
                  label={
                    <>
                      <RiUser2Line />
                      Nome
                    </>
                  }
                  name="name"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira seu nome.",
                    },
                  ]}
                >
                  <Input placeholder="Insira o nome" />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col lg={12} xs={24}>
                <FormItem
                  label={
                    <>
                      <RiUser2Line />
                      Email
                    </>
                  }
                  name="email"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira o Email.",
                    },
                  ]}
                >
                  <Input placeholder="Insira o email" />
                </FormItem>
              </Col>
              <Col lg={12} xs={24}>
                <FormItem
                  label={
                    <>
                      <RiSmartphoneLine />
                      Telefone
                    </>
                  }
                  name="cell_phone"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira a senha.",
                    },
                  ]}
                >
                  <Input
                    className="masked-input"
                    placeholder="(00) 0 0000-0000"
                    style={{ color: Colors.secondary70 }}
                    onKeyUp={handleKeyUpPhone}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col lg={12} xs={24}>
                <FormItem
                  label={
                    <>
                      <RiLock2Line />
                      Senha
                    </>
                  }
                  name="password"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira a senha.",
                    },
                  ]}
                >
                  <Input type="password" placeholder="*********" />
                </FormItem>
              </Col>
              <Col lg={12} xs={24}>
                <FormItem
                  label={
                    <>
                      <RiLock2Line />
                      Repita a senha
                    </>
                  }
                  name="retype_password"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira a senha.",
                    },
                  ]}
                >
                  <Input type="password" placeholder="*********" />
                </FormItem>
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="form-section">
            <p className="minor-title">Escolha um tipo de identificação</p>
            <Radio.Group
              defaultValue={documentType}
              onChange={(event) => setDocumentType(event.target.value)}
            >
              <Space direction="horizontal" size="large">
                <Radio value={"1"}>CPF</Radio>
                <Radio value={"2"}>CNPJ</Radio>
              </Space>
            </Radio.Group>
            <Row>
              <Col xs={24}>
                {(() => {
                  switch (documentType) {
                    case "1":
                      return (
                        <FormItem
                          label={
                            <>
                              <RiContactsLine />
                              CPF
                            </>
                          }
                          name="cpf"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira o CPF.",
                            },
                          ]}
                        >
                          <Input
                            className="masked-input"
                            placeholder="000.000.000-00"
                            style={{ color: Colors.secondary70 }}
                            onKeyUp={handleKeyUpCpf}
                          />
                        </FormItem>
                      );
                    case "2":
                      return (
                        <FormItem
                          label={
                            <>
                              <RiContactsLine />
                              CNPJ
                            </>
                          }
                          name="cnpj"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira o cnpj.",
                            },
                          ]}
                        >
                          <Input
                            className="masked-input"
                            placeholder="XX.XXX.XXX/0001-XX"
                            onKeyUp={handleKeyUpCnpj}
                          />
                        </FormItem>
                      );
                  }
                })()}
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="form-section">
            <CardFooter>
              <SecondaryButton>Voltar</SecondaryButton>
              <PrimaryButton htmlType="submit" loading={!formEditable}>
                Prosseguir
              </PrimaryButton>
            </CardFooter>
          </div>
        </Form>
      </>
    );
  };
  return (
    <StyledModal
      visible={true}
      footer={null}
      onCancel={handleAuth}
      closeIcon={<RiCloseCircleLine className="custom-close-icon" />}
    >
      <PurchaseHeader>
        <h1 className="title">Usuário EventX</h1>
      </PurchaseHeader>
      <CardForm>
        <p className="title">Escolha uma opção para continuar sua compra:</p>
        <StyledRadioGroup
          onChange={(event) => setValue(event.target.value)}
          defaultValue={value}
          size="large"
        >
          <RadioButton value="cadastrado">Já sou cadastrado</RadioButton>
          <RadioButton value="nao_cadastrado">Não sou cadastrado</RadioButton>
        </StyledRadioGroup>
        {(() => {
          switch (value) {
            case "cadastrado":
              return <Login />;
            case "nao_cadastrado":
              return <Register />;
            default:
              break;
          }
        })()}
      </CardForm>
    </StyledModal>
  );
};

export default ModalAuth;
