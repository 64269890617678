import React from "react";

const UserCookies: React.FC = () => {
  return (
    <>
      <h2>Introdu&ccedil;&atilde;o</h2>
      <p>Ol&aacute;!</p>
      <p>
        Seja bem-vindo &agrave; Plataforma XGROW - EVENTX um produto exclusivo
        da XGROW!
      </p>
      <p>&nbsp;</p>
      <p>
        A &ldquo;Pol&iacute;tica de Cookies&rdquo; a seguir &eacute; baseada em
        nossos princ&iacute;pios e valores, pois levamos muito a s&eacute;rio a
        sua privacidade e, por isso, protegemos os seus dados pessoais.
      </p>
      <p>&nbsp;</p>
      <p>
        A XGROW - EVENTX utiliza tecnologias de rastreamento como cookies, pixel
        tags, armazenamento local ou outros identificadores, de dispositivos
        m&oacute;veis ou n&atilde;o, ou tecnologias semelhantes (&ldquo;cookies
        e outras tecnologias&rdquo;), para uma variedade de
        fun&ccedil;&otilde;es. Essas tecnologias nos ajudam a autenticar a sua
        conta, promover e aperfei&ccedil;oar os servi&ccedil;os da XGROW -
        EVENTX, personalizar a sua experi&ecirc;ncia e avaliar a efic&aacute;cia
        da nossa comunica&ccedil;&atilde;o e publicidade.
      </p>
      <p>
        Como condi&ccedil;&atilde;o para acesso e utiliza&ccedil;&atilde;o da
        plataforma XGROW - EVENTX e suas funcionalidades, voc&ecirc; declara que
        realizou a leitura completa e atenta das regras deste documento, estando
        plenamente ciente.
      </p>
      <br />

      <h2>RESUMO</h2>
      <p>
        O seu tempo vale ouro e sabemos disso, e, por isso, colocamos abaixo os
        principais t&oacute;picos que, em nossa opini&atilde;o, s&atilde;o
        absolutamente indispens&aacute;veis para o uso da nossa plataforma.
        Por&eacute;m, n&atilde;o deixe de ler o documento por completo,
        combinado?
      </p>
      <p>
        A presente Pol&iacute;tica de Cookies tem por escopo estabelecer as
        regras e diretrizes para utiliza&ccedil;&atilde;o de tecnologias de
        coleta e monitoramento de dados pessoais no uso da plataforma;
      </p>
      <p>
        A XGROW - EVENTX trata as informa&ccedil;&otilde;es obtidas por meio de
        cookies e outras tecnologias como informa&ccedil;&otilde;es
        n&atilde;o-pessoais. Entretanto, na medida em que o endere&ccedil;o IP
        ou identificadores semelhantes sejam considerados
        informa&ccedil;&otilde;es pessoais pela legisla&ccedil;&atilde;o
        aplic&aacute;vel, a XGROW - EVENTX tratar&aacute; esses identificadores
        como informa&ccedil;&atilde;o pessoal;
      </p>
      <p>
        A XGROW - EVENTX utiliza cookies e outras tecnologias com o
        prop&oacute;sito de: autentica&ccedil;&atilde;o de usu&aacute;rios,
        comunica&ccedil;&atilde;o de an&uacute;ncios, medi&ccedil;&atilde;o e
        an&aacute;lise de dados, seguran&ccedil;a da plataforma, fornecimento de
        conte&uacute;do personalizado;
      </p>
      <p>
        O usu&aacute;rio poder&aacute; gerenciar as suas prefer&ecirc;ncias de
        cookies e outras tecnologias semelhantes a partir da
        configura&ccedil;&atilde;o de seu navegador ou dispositivo;
      </p>
      <p>
        Em caso de d&uacute;vidas, a comunica&ccedil;&atilde;o entre a XGROW -
        EVENTX e o usu&aacute;rio dever&aacute; ser realizada pelos canais de
        atendimento indicados e disponibilizados na plataforma XGROW - EVENTX.
      </p>
      <p>&nbsp;</p>
      <h2>VERS&Atilde;O COMPLETA</h2>
      <ol style={{ marginLeft: "3em" }}>
        <li>Gloss&aacute;rio</li>
      </ol>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <u>Armazenamento local</u>: tecnologia padr&atilde;o da
          ind&uacute;stria que permite que um website ou aplicativo armazene e
          recupere dados do computador, celular ou outro dispositivo de um
          usu&aacute;rio.
        </li>
        <li>
          <u>Identificador de dispositivo</u>
          <em>:</em>c&oacute;digos que possibilitam a
          identifica&ccedil;&atilde;o de um dispositivo m&oacute;vel, seja de
          maneira persistente ou transit&oacute;ria, tais como o ID de
          Publicidade ou o ID do sistema operacional.
        </li>
        <li>
          <u>Pixel tag</u>
          <em>:</em>pequenos blocos de c&oacute;digo em uma p&aacute;gina da web
          que permitem que elas realizem a&ccedil;&otilde;es como ler e
          armazenar cookies e transmitir informa&ccedil;&otilde;es para a XGROW
          - EVENTX. A conex&atilde;o resultante pode incluir
          informa&ccedil;&otilde;es como o endere&ccedil;o de IP de um
          dispositivo, a hora em que uma pessoa visualizou o pixel, um
          identificador associado ao navegador ou dispositivo e o tipo de
          navegador em uso.
        </li>
        <li>
          <u>Plataforma XGROW - EVENTX</u>
          <em>:</em>designa a plataforma tecnol&oacute;gica dispon&iacute;vel
          para organizadores e casas de eventos, facilitando a venda de
          ingressos, inscri&ccedil;&atilde;o para eventos, cobran&ccedil;a de
          contribui&ccedil;&otilde;es para eventos e a gest&atilde;o de
          participantes.
        </li>
        <li>
          <u>XGROW - EVENTX</u>
          <em>: </em>&Eacute; um produto da empresa XGROW TECNOLOGIA LTDA,
          pessoa jur&iacute;dica de direito privado, inscrita no CNPJ/ME sob o
          n&ordm;. 40.190.903/0001-05, sediada em Barueri, Estado de S&atilde;o
          Paulo, na Alameda Tocantins, n&ordm; 956, Alphaville Industrial, CEP
          06455-020.
        </li>
        <li>
          <u>Tratamento de dados pessoais</u>
          <em>:</em>toda opera&ccedil;&atilde;o realizada com dados pessoais,
          como as que se referem a coleta, produ&ccedil;&atilde;o,
          recep&ccedil;&atilde;o, classifica&ccedil;&atilde;o,
          utiliza&ccedil;&atilde;o, acesso, reprodu&ccedil;&atilde;o,
          transmiss&atilde;o, distribui&ccedil;&atilde;o, processamento,
          arquivamento, armazenamento, elimina&ccedil;&atilde;o,
          avalia&ccedil;&atilde;o ou controle da informa&ccedil;&atilde;o,
          modifica&ccedil;&atilde;o, comunica&ccedil;&atilde;o,
          transfer&ecirc;ncia, difus&atilde;o ou extra&ccedil;&atilde;o.
        </li>
      </ul>
      <p>&nbsp;</p>
      <h2>2. Considera&ccedil;&otilde;es sobre Cookies</h2>
      <p>
        2.1. Cookies s&atilde;o arquivos que cont&eacute;m um identificador (uma
        sequ&ecirc;ncia de letras e n&uacute;meros) enviados por um servidor
        para determinado navegador (browser) que o armazena. O identificador
        &eacute; ent&atilde;o enviado de volta ao servidor sempre que o
        navegador tenta acessar uma p&aacute;gina do servidor.
      </p>
      <p>
        2.2. Os cookies podem ser &ldquo;persistentes&rdquo; ou &ldquo;de
        sess&atilde;o&rdquo;. Um cookie persistente ser&aacute; armazenado por
        um navegador e permanecer&aacute; v&aacute;lido at&eacute; sua data de
        validade, salvo se deletado pelo usu&aacute;rio antes desta data. Um
        cookie de sess&atilde;o, por outro lado, ir&aacute; expirar ao final de
        uma sess&atilde;o de navega&ccedil;&atilde;o do usu&aacute;rio, quando o
        navegador &eacute; encerrado.
      </p>
      <p>
        2.3. A XGROW - EVENTX trata as informa&ccedil;&otilde;es obtidas por
        meio de cookies e outras tecnologias como informa&ccedil;&otilde;es
        n&atilde;o-pessoais. Entretanto, na medida em que o endere&ccedil;o IP
        ou identificadores semelhantes sejam considerados
        informa&ccedil;&otilde;es pessoais pela legisla&ccedil;&atilde;o
        aplic&aacute;vel, a XGROW - EVENTX tratar&aacute; esses identificadores
        como informa&ccedil;&atilde;o pessoal.
      </p>
      <br />

      <h2>3. Utiliza&ccedil;&atilde;o de cookies</h2>
      <p>
        3.1. A XGROW - EVENTX utiliza cookies e outras tecnologias com as
        seguintes finalidades:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          a) Autentica&ccedil;&atilde;o: indica que o usu&aacute;rio est&aacute;
          conectado, para que seja poss&iacute;vel oferecer os recursos
          apropriados, bem como entender como este utiliza e acessa a plataforma
          XGROW - EVENTX;
        </li>
        <li>
          b) An&uacute;ncios e medi&ccedil;&atilde;o: ajuda a analisar
          comportamentos de navega&ccedil;&atilde;o, a fim de veicular e medir
          an&uacute;ncios, torn&aacute;-los mais relevantes e analisar o
          conte&uacute;do e uso de produtos e servi&ccedil;os;
        </li>
        <li>
          c) Seguran&ccedil;a e integridade: ajuda a manter a seguran&ccedil;a
          dos servi&ccedil;os, oferecendo suporte ou ativando recursos de
          seguran&ccedil;a e ajudando a detectar atividades que violam nossas
          pol&iacute;ticas legais;
        </li>
        <li>
          d) Recursos e servi&ccedil;os: ajuda a fornecer produtos e
          servi&ccedil;os e conte&uacute;do personalizado;
        </li>
        <li>
          e) Desempenho: ajuda a proporcionar a melhor experi&ecirc;ncia
          poss&iacute;vel, por exemplo, ajudando a rotear o tr&aacute;fego entre
          servidores e perceber com que velocidade o servi&ccedil;o &eacute;
          carregado para diferentes pessoas. Ocasionalmente, poder&atilde;o ser
          armazenadas informa&ccedil;&otilde;es no navegador ou dispositivo para
          que os recursos em uso carreguem e respondam rapidamente.
        </li>
        <li>
          f) An&aacute;lises e pesquisas: ajuda a entender, aprimorar e
          pesquisar produtos e servi&ccedil;os quando voc&ecirc; acessa a
          plataforma XGROW - EVENTX.
        </li>
      </ol>
      <p>
        3.2. O usu&aacute;rio poder&aacute; gerenciar as suas prefer&ecirc;ncias
        de cookies e outras tecnologias semelhantes a partir da
        configura&ccedil;&atilde;o de seu navegador ou dispositivo, recusando ou
        excluindo determinados cookies e outras tecnologias. Contudo, isso
        poder&aacute; comprometer a presta&ccedil;&atilde;o dos servi&ccedil;os
        ou impedir o funcionamento de determinadas funcionalidades que os
        integram. Abaixo, listamos alguns endere&ccedil;os eletr&ocirc;nicos
        onde o usu&aacute;rio poder&aacute; gerenciar tais prefer&ecirc;ncias:
      </p>
      <ul style={{ marginLeft: "3em" }}>
        <li>
          <a href="https://support.google.com/chrome/answer/95647">
            <strong>
              <u>https://support.google.com/chrome/answer/95647</u>
            </strong>
          </a>
          (Chrome);
        </li>
        <li>
          <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
            <strong>
              <u>
                ttps://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
              </u>
            </strong>
          </a>
          (Firefox);
        </li>
        <li>
          <a href="https://www.opera.com/help/tutorials/security/cookies/">
            <strong>
              <u>https://www.opera.com/help/tutorials/security/cookies/</u>
            </strong>
          </a>
          (Opera);
        </li>
        <li>
          <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies%20">
            <strong>
              <u>
                https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies{" "}
              </u>
            </strong>
          </a>
          (Internet Explorer);
        </li>
        <li>
          <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">
            <strong>
              <u>https://support.apple.com/pt-br/guide/safari/sfri11471/mac</u>
            </strong>
          </a>
          (Safari);
        </li>
        <li>
          <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">
            <strong>
              <u>
                https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
              </u>
            </strong>
          </a>
          (Edge).
        </li>
      </ul>
      <br />

      <h2>4. Cookies de terceiros</h2>
      <p>
        4.1. Os provedores de servi&ccedil;os utilizados pela XGROW - EVENTX
        poder&atilde;o utilizar cookies e outras tecnologias de sua propriedade
        para identificar o navegador e dispositivo utilizados, de modo a
        oferecer publicidade direcionada da XGROW - EVENTX quando o
        usu&aacute;rio acessa websites ou aplicativos de terceiros. Esses
        terceiros podem tamb&eacute;m fornecer &agrave; XGROW - EVENTX
        informa&ccedil;&otilde;es acerca do desempenho das campanhas de
        marketing desenvolvidas.
      </p>
      <p>
        4.2. O usu&aacute;rio poder&aacute; realizar o "opt-out"
        (exclus&atilde;o volunt&aacute;ria) dos servi&ccedil;os de publicidade
        de terceiro acima mencionados atrav&eacute;s das ferramentas:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          a) "YourAdChoices":
          <a href="http://optout.aboutads.info/?c=2&amp;lang=EN">
            <strong>
              <u>http://optout.aboutads.info/?c=2&amp;lang=EN</u>
            </strong>
          </a>
        </li>
      </ol>
      <p>
        b)"Evidon":
        <a href="http://info.evidon.com/companies?cc=pt_br&amp;external_opt_out=true&amp;opt_out=true&amp;opt_out_only=true">
          <strong>
            <u>
              ttp://info.evidon.com/companies?cc=pt_br&amp;external_opt_out=true&amp;opt_out=true&amp;opt_out_only=true
            </u>
          </strong>
        </a>
      </p>
      <br />

      <h2>5. Tecnologias de rastreamento</h2>
      <p>
        5.1. A XGROW - EVENTX poder&aacute; utilizar outras tecnologias de
        rastreamento, como web beacons (&agrave;s vezes chamados de "pixels de
        rastreamento", &ldquo;pixel tags&rdquo; ou "gifs n&iacute;tidos"). Essas
        tecnologias tamb&eacute;m ajudam os usu&aacute;rios Organizadores a
        saber mais sobre o interesse em determinados eventos. Em muitos casos,
        essas tecnologias dependem dos cookies para funcionar corretamente.
      </p>
      <p>
        5.2. A XGROW - EVENTX poder&aacute; utilizar uma "URL click-through"
        vinculada ao conte&uacute;do da plataforma XGROW - EVENTX. Ao clicar em
        uma dessas URL&rsquo;s, os usu&aacute;rios poder&atilde;o ter dados
        pessoais tratados, com base no leg&iacute;timo interesse da XGROW -
        EVENTX, para entendermos o interesse em determinados t&oacute;picos e
        avaliar a efic&aacute;cia das comunica&ccedil;&otilde;es com os
        usu&aacute;rios.
      </p>
      <br />

      <h2>6. Disposi&ccedil;&otilde;es gerais</h2>
      <p>
        6.1. Ao navegar pela plataforma XGROW - EVENTX e utilizar suas
        funcionalidades, os usu&aacute;rios aceitam todo o disposto nesta
        Pol&iacute;tica e demais pol&iacute;ticas legais que se encontram
        vigentes na data de acesso. Por isso, &eacute; recomend&aacute;vel que
        os usu&aacute;rios se mantenham atualizados.
      </p>
      <p>
        6.2. A presente Pol&iacute;tica de Cookies est&aacute; sujeita a
        constante melhoria e aprimoramento. Portanto, a XGROW - EVENTX se
        reserva o direito de modific&aacute;-la a qualquer momento, conforme a
        finalidade da pr&oacute;pria plataforma XGROW - EVENTX, tal qual para
        adequa&ccedil;&atilde;o e conformidade legal de disposi&ccedil;&atilde;o
        de lei ou norma que tenha for&ccedil;a jur&iacute;dica equivalente,
        cabendo aos usu&aacute;rios verific&aacute;-la sempre que efetuar o
        acesso &agrave; plataforma XGROW - EVENTX.
      </p>
      <p>
        6.3. A eventual toler&acirc;ncia quanto a qualquer
        viola&ccedil;&atilde;o dos termos e condi&ccedil;&otilde;es do contido
        neste dom&iacute;nio ser&aacute; considerada mera liberalidade e
        n&atilde;o ser&aacute; interpretada como nova&ccedil;&atilde;o,
        precedente invoc&aacute;vel, ren&uacute;ncia a direitos,
        altera&ccedil;&atilde;o t&aacute;cita dos termos contratuais, direito
        adquirido ou altera&ccedil;&atilde;o contratual.
      </p>
      <p>
        6.4. Caso alguma disposi&ccedil;&atilde;o desta Pol&iacute;tica for
        julgada inaplic&aacute;vel ou sem efeito, o restante das normas
        continuam a viger, sem a necessidade de medida judicial que declare tal
        assertiva. Os termos aqui descritos ser&atilde;o interpretados segundo a
        legisla&ccedil;&atilde;o brasileira.
      </p>
      <p>
        6.5. A comunica&ccedil;&atilde;o entre a XGROW - EVENTX e o
        usu&aacute;rio dever&aacute; ser realizada pelos canais de atendimento
        indicados e disponibilizados na plataforma XGROW e XGROW - EVENTX.
      </p>
      <p>
        6.6. Sempre que poss&iacute;vel, potenciais diverg&ecirc;ncias entre o
        usu&aacute;rio e a XGROW - EVENTX ser&atilde;o resolvidas de forma
        amig&aacute;vel. Quando todos os esfor&ccedil;os neste sentido forem
        esgotados, voc&ecirc; concorda, desde j&aacute;, que fica eleito o foro
        da&nbsp;comarca de Barueri - S&atilde;o Paulo, para resolver
        controv&eacute;rsias ou queixas oriundas da utiliza&ccedil;&atilde;o de
        nossa plataforma ou relacionadas a esta Pol&iacute;tica de Cookies.
      </p>
    </>
  );
};

export default UserCookies;
