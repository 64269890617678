import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { RiCloseCircleLine } from "react-icons/ri";

import ReactPixel from "react-facebook-pixel";

import { useVerifyPurchase } from "services/Hook/Event";
import { authClient } from "services/authClient";

import { StyledIframe, StyledModal } from "./styles";

type PurchaseVerify = {
  id: number;
  purchase_status: "PENDING" | "PAID";
  type_payment: "boleto" | "credit-card" | "pix";
};

interface Props {
  handleSuccess: () => void;
  event: any;
  cart: any;
  purchase: string;
  url: string;
}

const ModalSuccess: React.FC<Props> = ({
  handleSuccess,
  event,
  cart,
  purchase,
  url,
}) => {
  const [purchaseStatus, setPurchaseStatus] = useState("");
  const [purchasePaymentType, setPurchasePaymentType] = useState("");
  const [checkoutPaymentLoad, setCheckoutPaymentLoad] = useState(false);

  const { data } = useVerifyPurchase<PurchaseVerify>(purchase);

  const navigate = useNavigate();

  async function checkCheckoutPayment() {
    setCheckoutPaymentLoad(false);
    try {
      const response = await authClient.get(
        `/events/verify-purchase/${purchase}`
      );
      const responseData = response.data;

      setPurchaseStatus(responseData.purchase_status);
      setPurchasePaymentType(responseData.type_payment);

      let total: number = 0;
      const itens: any = [];

      cart.map(
        (item: {
          ticket_id: string;
          name: string;
          description: string;
          price: number;
          discount: number;
          quantity: number;
        }) => {
          itens.push({
            id: item.ticket_id,
            quantity: 1,
            item_price:
              item.discount != 0 ? item.price - item.discount : item.price,
          });

          total =
            total + item.discount != 0
              ? item.price - item.discount
              : item.price;
        }
      );

      if (responseData.purchase_status === "PAID") {
        checkIntegrationsEvent(event, itens, total);
        localStorage.setItem("@ventX-web_cart", "");
        navigate("/profile/my-shopping");
      } else if (responseData.purchase_status === "PENDING") {
        if (
          responseData.type_payment === "boleto" ||
          responseData.type_payment === "pix"
        ) {
          checkIntegrationsEvent(event, itens, total);
          localStorage.setItem("@ventX-web_cart", "");
        }

        setCheckoutPaymentLoad(true);
      }
    } catch (err: any) {}
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!checkoutPaymentLoad) {
        checkCheckoutPayment();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const checkIntegrationsEvent = async (
    event: any,
    itens: any,
    total: number
  ) => {
    if (event.integrations) {
      event.integrations.map((integration: any) => {
        if (integration.type === 2) {
          ReactPixel.pageView();
          ReactPixel.track("Purchase", {
            value: total,
            currency: "BRL",
            contents: itens,
            content_type: "product",
          });
        }
      });
    }
  };

  const closeModal = () => {
    if (data?.type_payment === "boleto") {
      handleSuccess();
    } else {
      alert(
        "Esta janela fechara automaticamente quando o pagamento for confirmado."
      );
    }
  };

  return (
    <StyledModal
      visible={true}
      footer={null}
      onCancel={() => closeModal()}
      closeIcon={<RiCloseCircleLine className="custom-close-icon" />}
    >
      <StyledIframe src={url} />
    </StyledModal>
  );
};

export default ModalSuccess;
