import { Modal, Select } from "antd";
import { Colors } from "constants/colors";
import styled, { css } from "styled-components";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;



export const ContainerModalReverse = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  div.header-texts {
    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 1.87rem;
      margin-bottom: 0px;
      text-align: center;

      color: ${Colors.secondary100};
    }

    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 1.03rem;
      margin-top: 0.93rem;
      margin-bottom: 0px;
      text-align: center;

      color: ${Colors.black70};
    }
  }

  div.container-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3.75rem;
  }

  div.button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3.75rem;
  }
`;


export const ContainerBorderSelected = styled.div`
  width: 100%;
  min-height: 14.15rem;
  display: flex;
  flex-direction: column;
  margin-top: 2.67rem;

  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  padding: 2.34rem;

  > div + div {
    margin-top: 0.93rem;
  }
`;


interface CardSelectedProps {
  active?: boolean;
}

export const CardSelected = styled.div<CardSelectedProps>`
  cursor: pointer;
  width: 100%;
  min-height: 4.5rem;
  display: flex;
  flex-direction: row;

  background: ${(props) => (props.active ? Colors.tertyary10 : Colors.white)};

  border: 1px solid
    ${(props) => (props.active ? Colors.primary100 : Colors.black10)};
  border-radius: 0.42rem;
  padding: 0.84rem 0.98rem;

  div.icon {
  }

  div.column {
    display: flex;
    flex-direction: column;
    padding-left: 0.98rem;

    h6 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 0.75rem;
      margin-top: 0px;
      margin-bottom: 0px;

      color: ${(props) =>
        props.active ? Colors.primary100 : Colors.secondary40};
    }

    h5 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: bold;
      font-size: 0.98rem;
      margin-bottom: 0px;

      color: ${(props) =>
        props.active ? Colors.primary100 : Colors.secondary40};
    }
  }
`;


interface ButtonNextProps {
  active?: boolean;
}

export const ButtonNext = styled.button<ButtonNextProps>`
  cursor: pointer;
  outline: 0;
  border: 0;

  width: 12.32rem;
  height: 3.84rem;

  background: ${(props) => (props.active ? Colors.primary100 : Colors.black10)};

  border-radius: 14px;

  ${(props) =>
    props.active &&
    css`
      box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
    `}

  ${(props) =>
    !props.active &&
    css`
      cursor: not-allowed;
    `}

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 1.21rem;
    text-align: center;

    color: ${(props) => (props.active ? Colors.white : Colors.black30)};
  }
`;


export const ContainerBorder = styled.div`
  width: 100%;
  min-height: 14.15rem;
  display: flex;
  flex-direction: column;
  margin-top: 2.67rem;

  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  padding: 2.34rem;

  h5.title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.03rem;
    margin-bottom: 0px;

    color: ${Colors.secondary100};
  }

  div.container-select {
    margin-top: 1.68rem;

    h6.title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 0.93rem;
      margin-bottom: 0px;
      color: ${Colors.secondary40};
    }
  }

  div.select {
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;
    color: ${Colors.secondary70};
    margin-top: 0.65rem;

    div.row {
      width: 100%;
      display: flex;
      flex-direction: row;

      h5 {
        color: ${Colors.primary100};
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-left: 10px;
      }
    }
  }
`;


export const StyledSelect = styled(Select)``;


export const ButtonPrev = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;

  width: 12.32rem;
  height: 3.84rem;
  background: ${Colors.primary10};

  border-radius: 14px;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 1.21rem;
    text-align: center;

    color: ${Colors.primary100};
  }
`;


export const FormAccount = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;


export const ContainerBorderAccountForm = styled.div`
  width: 100%;
  min-height: 14.15rem;
  display: flex;
  flex-direction: column;
  margin-top: 2.67rem;

  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  padding: 2.34rem;

  div.header-title {
    width: 100%;
    padding-bottom: 0.93rem;
    border-bottom: 1px solid ${Colors.border40};

    h5.title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 1.03rem;
      margin-bottom: 0px;

      color: ${Colors.secondary100};
    }
  }

  div.informations {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.68rem;

    @media (max-width: 800px) {
      flex-direction: column;
      justify-content: none;
    }
  }

  div.radios {
    margin-top: 1.87rem;

    span.label {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 1.03rem;
      margin-bottom: 0px;

      color: ${Colors.secondary100};
    }

    div.radio {
      margin-top: 2.1rem;

      .ant-radio-checked .ant-radio-inner {
        border-color: ${Colors.primary100} !important ;
      }

      .ant-radio-checked .ant-radio-inner:after {
        background-color: ${Colors.primary100};
      }

      .ant-radio:hover .ant-radio-inner {
        border-color: ${Colors.primary100};
      }

      .ant-radio-checked .ant-radio-inner:focus {
        background: ${Colors.primary100};
      }
    }
  }
`;



export const FormFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.white};

  div.all-input {
    width: 92%;

    div.select {
      font-weight: normal;
      font-size: 20px;

      color: ${Colors.secondary70};
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;


interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
}

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: 12px;
    padding: 0px 0px 4px 4px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 12px;
  }
`;


export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    color: ${Colors.secondary40};
    font-weight: 400;
    font-size: 0.93rem;
    margin-left: 4px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-weight: normal;
      font-size: 11px;
      line-height: 24px;
      margin-top: 5px;
      margin-left: 3px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }
  }

  @media (max-width: 800px) {
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
`;


export const CardSelectedHasCard = styled.div<CardSelectedProps>`
  cursor: pointer;
  width: 100%;
  min-height: 8.29rem;
  display: flex;
  flex-direction: row;

  background: ${(props) => (props.active ? Colors.tertyary10 : Colors.white)};

  border: 1px solid
    ${(props) => (props.active ? Colors.primary100 : Colors.black10)};
  border-radius: 0.42rem;
  padding: 0.84rem 0.98rem;

  div.icon {
  }

  div.column {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 0.98rem;

    div.border {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 0.65rem;

      border-bottom: 1px solid
        ${(props) => (props.active ? Colors.primary30 : Colors.border40)};

      h6 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        margin-top: 0px;
        margin-bottom: 0px;

        color: ${(props) =>
          props.active ? Colors.primary100 : Colors.secondary40};
      }

      h5 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: bold;
        font-size: 0.98rem;
        margin-bottom: 0px;

        color: ${(props) =>
          props.active ? Colors.primary100 : Colors.secondary40};
      }
    }

    div.second {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 0.65rem;

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 0.55rem;
        margin-bottom: 0px;

        color: ${(props) =>
          props.active ? Colors.primary100 : Colors.secondary40};
      }

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        margin-bottom: 0px;
        margin-top: 0.37rem;
        color: ${(props) =>
          props.active ? Colors.primary100 : Colors.secondary40};
      }
    }
  }
`;
