import React from "react";

import { RiCloseCircleLine } from "react-icons/ri";

import { DownloadSection, StyledModal } from "./styles";

interface Props {
  closeModal: () => void;
}
const ModalSuccess: React.FC<Props> = ({ closeModal }) => {
  return (
    <StyledModal
      visible={true}
      footer={null}
      onCancel={closeModal}
      closeIcon={<RiCloseCircleLine className="custom-close-icon" />}
    >
      <p className="title">Esqueceu sua senha?</p>
      <p className="description">
        Para solicitar a redefinição de uma nova senha para sua conta, baixe o
        app mobile EventX.
      </p>
      <DownloadSection>
        <p className="download">
          Faça o download clicando em umas das opções abaixo
        </p>
        <div className="container-download">
          <a
            href="https://play.google.com/store/apps/details?id=com.mobile.eventx"
            aria-label="Baixe agora para Google Play!"
            target="_blank"
            rel="noreferrer"
          >
            <div className="download-button google-play" />
          </a>
          <a
            href="https://apps.apple.com/us/app/eventx-eventos-e-ingressos/id1584406908"
            aria-label="Baixe agora para Apple Store!"
            target="_blank"
            rel="noreferrer"
          >
            <div className="download-button app-store" />
          </a>
        </div>
      </DownloadSection>
    </StyledModal>
  );
};

export default ModalSuccess;
