import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import { Spin } from "antd";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { LoadingOutlined } from "@ant-design/icons";
import {
  RiEyeLine,
  RiEyeOffLine,
  RiLock2Line,
  RiUserLine,
} from "react-icons/ri";

import { useAuth } from "contexts/AuthContext";
import { Colors } from "constants/colors";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  FormSignIn,
  AllInput,
  InputContainer,
  ButtonSignIn,
  ContainerForms,
  TabsCadaster,
  Tab,
  TabSignIn,
  TabSignUp,
  ButtonCadaster,
} from "./styles";

import { Signup } from "../SignUp";

import Popup from "containers/Popup";
import { Header } from "components/Header";
import { Footer } from "components/Footer";

import signin_banner from "assets/signin-banner.png";
import signup_banner from "assets/signup-banner.png";
import logo_signin from "assets/logo-signin.png";

type SignInFormData = {
  email: string;
  password: string;
};

const signInFormSchema = yup.object().shape({
  email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
  password: yup.string().required("Senha obrigatória"),
});

export default function SignInPage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInFormData>({
    resolver: yupResolver(signInFormSchema),
  });

  const [tabActive, setTabActive] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { signIn } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page");

  useEffect(() => {
    if (!page) {
      return;
    }

    if (page === "signin") {
      setTabActive(0);
    }

    if (page === "signup") {
      setTabActive(1);
    }
  }, [page]);

  const handleChangeTab = useCallback(
    (tab) => {
      if (tab === 0) {
        setTabActive(0);
        setSearchParams({ page: "signin" });
        return;
      }

      if (tab === 1) {
        setTabActive(1);
        setSearchParams({ page: "signup" });
        return;
      }
    },
    [setSearchParams]
  );

  const handleSignIn: SubmitHandler<SignInFormData> = async ({
    email,
    password,
  }) => {
    try {
      await signIn({
        email,
        password,
      });
    } catch (err) {
      alert("Email ou senha invalidos");
    }
  };

  const [showPass1, setShowPass1] = useState(false);

  const handleShowPass1 = () => {
    setShowPass1(!showPass1);
  };

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  return (
    <>
      <Header isSignin />
      <Container>
        <ContainerItems>
          {tabActive === 0 ? (
            <div className="container-image">
              <img
                src={signin_banner}
                alt="signin-banner"
                width="840px"
                height="573px"
              />
            </div>
          ) : null}

          {tabActive === 1 ? (
            <div className="container-image">
              <img
                src={signup_banner}
                alt="signin-banner"
                width="761.25px"
                height="795px"
              />
            </div>
          ) : null}

          <ContainerForms>
            <div className="container-logo">
              <img
                src={logo_signin}
                alt="logo-signin"
                width="251px"
                height="64.5px"
              />
              <div className="text">
                <h2>a vida é um evento!</h2>
              </div>
            </div>

            <TabsCadaster>
              <Tab>
                <TabSignIn
                  active={tabActive === 0}
                  onClick={() => handleChangeTab(0)}
                  className="first"
                >
                  <h2>Login</h2>
                </TabSignIn>
                <TabSignUp
                  active={tabActive === 1}
                  onClick={() => handleChangeTab(1)}
                  className="second"
                >
                  <h2>Cadastre-se</h2>
                </TabSignUp>
              </Tab>
            </TabsCadaster>

            {tabActive === 0 ? (
              <FormSignIn onSubmit={handleSubmit(handleSignIn)}>
                <div className="all-inputs">
                  <AllInput>
                    <InputContainer error={!!errors?.email}>
                      <div>
                        <RiUserLine size={23} color={Colors.secondary40} />
                      </div>
                      <input
                        type="email"
                        placeholder="E-mail"
                        {...register("email")}
                      />
                    </InputContainer>
                    {errors.email && <span>Por favor, insira o Email.</span>}
                  </AllInput>

                  <AllInput>
                    <InputContainer error={!!errors.password}>
                      <div>
                        <RiLock2Line size={23} color={Colors.secondary40} />
                      </div>
                      <input
                        type={showPass1 ? "text" : "password"}
                        placeholder="Senha"
                        {...register("password")}
                      />

                      <div>
                        {showPass1 ? (
                          <RiEyeLine
                            onClick={handleShowPass1}
                            size={23}
                            color={Colors.secondary40}
                          />
                        ) : (
                          <RiEyeOffLine
                            onClick={handleShowPass1}
                            size={23}
                            color={Colors.secondary40}
                          />
                        )}
                      </div>
                    </InputContainer>
                    {errors.password ? (
                      <span>Por favor, insira a senha.</span>
                    ) : null}
                  </AllInput>
                </div>

                <div className="forgot">
                  <label>
                    <input type="checkbox" />
                    Lembrar de mim
                  </label>

                  <Link to="/forgot-password">Esqueci minha senha</Link>
                </div>

                <div className="button-signIn">
                  <ButtonSignIn type="submit" isLoading={isSubmitting}>
                    <Spin spinning={isSubmitting} indicator={antIcon} />

                    <span>Entrar</span>
                  </ButtonSignIn>
                </div>

                <div className="new">
                  <span>Você é novo por aqui?</span>

                  <div className="cadaster-web">
                    <Link to="/signin?page=signup">
                      <p>Cadastre-se</p>
                    </Link>
                  </div>

                  <div className="cadaster-mobile">
                    <ButtonCadaster>Cadastre-se</ButtonCadaster>
                  </div>
                </div>
              </FormSignIn>
            ) : null}

            {tabActive === 1 ? (
              <Signup handleChangeTab={handleChangeTab} />
            ) : null}
          </ContainerForms>
        </ContainerItems>
      </Container>
      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
