import styled from "styled-components";
import { Colors } from "constants/colors";

export const BottomCollapseContainer = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${Colors.secondary100};
  padding: 12px 28px;
  .price-info {
    color: #fff;
    .price {
      font: 700 14px Cairo, sans-serif;
    }
    .resume-purchase {
      font: 700 14px Cairo, sans-serif;
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 580px) {
    display: flex;
  }
`;
export const PurchaseMenu = styled.div`
  display: none;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  overflow-y: scroll;
  @media screen and (max-width: 580px) {
    display: flex;
  }
`;
export const MenuHeader = styled.div`
  width: 100%;
  height: fit-content;
  color: #fff;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 28px;
  z-index: 999;
  background-color: ${Colors.tertiary100};
  font: 700 18px Cairo, sans-serif;
  svg {
    font-size: 24px;
  }
`;
export const DropdownMenu = styled.div`
  border-radius: 14px;
  min-height: 100%;
  height: fit-content;
  background-color: #fff;
  width: 100%;
  padding: 30px;
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .your-order {
    font: 600 3rem, sans-serif;
    color: ${Colors.tertiary100};
    margin-bottom: 2rem;
  }
  .subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
    color: ${Colors.black};
    .label {
      font: 400 2rem, sans-serif;
    }
    .value {
      font: 700 2.6rem, sans-serif;
    }
  }
  .confirm-button {
    width: 100%;
    height: 38px;
    border-radius: 1.4rem;
    background-color: ${Colors.primary100};
    box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
    color: #fff;
    border: none;
    margin-bottom: 32px;
    :hover,
    :active,
    :focus {
      background-color: ${Colors.primary100};
      color: #fff;
      border: none;
    }
  }
`;
export const TicketContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .icon-container {
    display: flex;
    margin-right: 64px;
    svg {
      margin-top: 8px;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      color: ${Colors.gray10};
    }
    .name-and-price {
      text-align: start;

      p {
        margin: 5px 0;
      }

      p:first-child {
        font-weight: 500;
        font-size: 16px;
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${Colors.tertiary100};
      }

      p:last-child {
        font-weight: 600;
        font-size: 14px;

        color: ${Colors.black};
      }
    }
  }
`;
export const ActionButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-input {
    width: 8rem;
    height: 6rem;
    padding: 4px 8px;
    border: none;
    background-color: #f6f6f6;
    &:focus {
      box-shadow: none;
    }
  }
  button {
    width: 32px;
    height: 32px;
    /* flex-shrink: 0; */
    /* border-radius: 1.4rem; */
    background-color: ${Colors.primary100};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 3rem; */
    /* padding: 0 1rem; */
    border: none;
    cursor: pointer;
    margin-left: 8px;
    :hover,
    :active,
    :focus {
      background-color: ${Colors.primary100};
      color: #fff;
      outline: none;
    }
    &.add {
      background-color: ${Colors.green10};
      font-size: 22px;
      svg {
        min-width: 16px;
        min-height: 16px;
      }
    }
    &.remove {
      background-color: ${Colors.red10};
      svg {
        min-width: 16px;
        min-height: 16px;
      }
    }
  }
`;
