import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const DivLogo = styled.div`
  width: 100%;
  height: 8.57rem;
  margin-top: 40px;

  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 30px;

  img {
    width: 9.79rem;
    height: 2.53rem;
  }

  div.all-content {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    border-top: 1px solid ${Colors.secondary10};

    img {
      width: 271px;
      height: 71.25px;
    }
  }

  span {
    font-size: 11px;
    color: ${Colors.gray50};
    letter-spacing: 6px;
  }
`;

export const BackgroundTicket = styled.div`
  width: 100%;
  height: 303px !important;

  position: relative;

  position: relative;
  overflow: hidden;

  border-radius: 1.12rem 1.12rem 0px 0px;
  padding: bottom;
  display: flex;
  flex-direction: column;

  justify-content: flex-end;

  img {
    filter: brightness(25%);
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  div.content {
    width: 100%;
    z-index: 2;
    position: relative;
    padding-left: 2.81rem !important;
    padding-bottom: 2.34rem !important;

    div.text {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.84rem !important;

      svg {
        margin-right: 10px;
      }
    }

    div.text-last {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }

    h2.title {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 1.87rem !important;
      margin-bottom: 1.3rem !important;

      color: ${Colors.white};

      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    }

    h4 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 1.21rem !important;

      color: ${Colors.white};
      margin-bottom: 0px;
    }

    p {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 1.21rem !important;
      margin-bottom: 0px;

      color: ${Colors.white};
      margin-bottom: 0px;
    }
  }
`;

export const DivBorder = styled.div`
  width: 100%;
  height: 520px;
  display: flex;
  flex-direction: column;

  border: 1px solid ${Colors.border20};
  border-radius: 0px 0px 1.12rem 1.12rem;
`;

export const ContainerContentModalTicket = styled.div`
  width: 100%;
  padding: 2.34rem 2.81rem 0px 2.81rem !important;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div.data {
    h3.data {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 900;
      font-size: 1.5rem;
      margin-bottom: 0px;

      color: ${Colors.black100};
    }

    div.table {
      display: flex;
      flex-direction: column;
      margin-top: 0.93rem;

      h5 {
        font-weight: 700;
        font-size: 1.21rem;
        margin-bottom: 0px;
        font-family: "Cairo";

        color: ${Colors.black100};
        margin-bottom: 0px;
      }

      p {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 1.12rem;
        margin-bottom: 0px;

        color: ${Colors.black60};
      }
    }
  }

  div.qr {
    width: 240px;
    height: 293px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background: ${Colors.white};
    border: 2px solid ${Colors.border20};
    box-sizing: border-box;
    border-radius: 0.46rem;
    padding: 0.33rem 0.93rem 20px 0.93rem;

    h1 {
      font-weight: bold;
      font-size: 0.93rem;
      margin-bottom: 0px;
      letter-spacing: 0.1em;
      color: ${Colors.black100};
    }
  }
`;
