import { Link, useSearchParams } from "react-router-dom";

import { FeaturedEvent } from "types";

import { Container, ContainerItems, ContainerList, ListCards } from "./styles";

import { SearchCard } from "components/SearchCard";

interface ListSearchProps {
  name: string;
  address_city: string;
  category: string;
  date: string;
  price: string;
  events: FeaturedEvent[] | undefined;
  total?: number;
}

export function ListSearch({
  name,
  address_city,
  category,
  date,
  price,
  total,
  events,
}: ListSearchProps) {
  const [searchParams] = useSearchParams();

  const address_state = searchParams.get("address_state") ?? "";

  return (
    <Container>
      <ContainerItems>
        <div className="results">
          <h4>
            {total} RESULTADOS{" "}
            {category || name || address_city || date ? "PARA" : null}{" "}
            {name ? <span>Nome: "{name}"</span> : null}
            {category ? <span>Categoria: "{category}"</span> : null}
            {address_city ? (
              <span>
                , {address_state ? "Estado" : "Cidade"}: "{address_city}"
              </span>
            ) : null}
            {date ? <span>, Data: "{date}"</span> : null}
            {price ? <span>, Preço: "{price}"</span> : null}
          </h4>
        </div>

        <ContainerList>
          <ListCards>
            {events?.map((item) => (
              <Link to={`/${item?.nickname}`}>
                <SearchCard data={item} />
              </Link>
            ))}
          </ListCards>
        </ContainerList>
      </ContainerItems>
    </Container>
  );
}
