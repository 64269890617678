import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { IoCloseCircleSharp } from "react-icons/io5";

import { useSearchCityAndState } from "contexts/SearchCityAndState";

import { Colors } from "constants/colors";

import Constants from "constants/Datas";

import {
  BorderSelect,
  Container,
  DivIsSelected,
  SelectStyledWithoutDrop,
} from "./styles";

export function SelectCityOrState() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { showModalSearch } = useSearchCityAndState();

  const [textLabel, setTextLabel] = useState("");

  const address_city = searchParams.get("address_city") ?? "";

  const address_state = searchParams.get("address_state") ?? "";

  useEffect(() => {
    if (address_state) {
      const findState = Constants.States?.find(
        (value) => String(value.value) === address_state
      );

      if (!findState) {
        setTextLabel(address_state);

        return;
      }

      if (address_city) {
        setTextLabel(address_city);

        return;
      }

      setTextLabel(findState.label);
    }
  }, [address_city, address_state]);

  const clearId = useCallback(() => {
    searchParams.delete("address_city");

    searchParams.delete("address_state");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [searchParams, setSearchParams]);

  return (
    <Container>
      {address_city === "" && address_state === "" ? (
        <BorderSelect>
          <SelectStyledWithoutDrop
            bordered={false}
            labelInValue
            size="large"
            defaultValue={{ value: 0, label: "Localização" }}
            open={false}
            onClick={showModalSearch}
          />
        </BorderSelect>
      ) : (
        <DivIsSelected>
          <h3 className="selected">{textLabel}</h3>
          <IoCloseCircleSharp
            onClick={clearId}
            style={{ cursor: "pointer" }}
            size={18}
            color={Colors.white}
          />
        </DivIsSelected>
      )}
    </Container>
  );
}
