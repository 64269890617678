import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";

import {
  RiCalendarCheckFill,
  RiCloseCircleLine,
  RiMenu2Line,
} from "react-icons/ri";
import { AiOutlineUser } from "react-icons/ai";

import { Divider } from "antd";

import { useAuth } from "contexts/AuthContext";
import { checkUserPlatform, redirectToProperLink } from "utils/plaftformDetect";

import {
  StyledHeader,
  Logo,
  MenuLinks,
  ButtonDrawer,
  ActionButton,
  Drawer,
  ContainerItems,
  ContainerFooterButtons,
  ContainerLinks,
} from "./styles";

import LogoEventxBranca from "assets/logo_eventx_white.png";
import LogoEventx from "assets/logo_eventx.svg";

interface MetaTags {
  title?: string;
  description?: string;
  keywords?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogType?: string;
  ogUrl?: string;
  noIndex?: boolean;
}

interface HeaderProps {
  metaTags?: MetaTags;
}

const Header: React.FC<HeaderProps> = ({ metaTags }) => {
  const { user } = useAuth();

  const [visible, setVisible] = useState(false);
  const [activeTab] = useState(0);

  const link = redirectToProperLink(checkUserPlatform());

  const navigate = useNavigate();

  const showDrawer = () => {
    setVisible(!visible);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <StyledHeader
      style={{
        backgroundColor: visible ? "#fff" : "transparent",
        position: visible ? "fixed" : "absolute",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        {metaTags?.title && <title>{metaTags.title}</title>}

        {metaTags?.description && (
          <meta
            name="description"
            content={metaTags.description}
            data-rh="true"
          />
        )}

        {metaTags?.keywords && (
          <meta name="keywords" content={metaTags.keywords} data-rh="true" />
        )}

        {metaTags?.ogTitle && (
          <meta property="og:title" content={metaTags.ogTitle} />
        )}

        {metaTags?.ogDescription && (
          <meta property="og:description" content={metaTags.ogDescription} />
        )}

        {metaTags?.ogImage && (
          <meta
            property="og:image"
            itemProp="image"
            content={metaTags.ogImage}
          />
        )}

        {metaTags?.ogType && (
          <meta property="og:type" content={metaTags.ogType} />
        )}

        {metaTags?.ogUrl && <meta property="og:url" content={metaTags.ogUrl} />}

        {metaTags?.noIndex && <meta name="robots" content="noindex,nofollow" />}
      </Helmet>

      <ContainerItems>
        <ButtonDrawer
          type="primary"
          onClick={showDrawer}
          aria-label="Abrir menu com links"
        >
          {visible ? <RiCloseCircleLine color="#241058" /> : <RiMenu2Line />}
        </ButtonDrawer>

        <Logo
          src={visible ? LogoEventx : LogoEventxBranca}
          alt=""
          width="150px"
          height="100%"
          onClick={() => navigate("/")}
        />
        <MenuLinks active={activeTab}>
          <Link to="/">Início</Link>
          <Link to="/faq">FAQ</Link>
        </MenuLinks>
        <ActionButton target="_blank" rel="noreferrer" href={`${link}`}>
          <RiCalendarCheckFill />
          Organize seu evento!
        </ActionButton>

        <Drawer
          title={<RiCloseCircleLine className="close-icon" onClick={onClose} />}
          placement="left"
          closable={false}
          onClose={onClose}
          visible={visible}
          mask
        >
          {!user ? (
            <ContainerLinks>
              <Link to="/">Eventos</Link>
              <Link to="/faq">
                <span>Suporte</span>
              </Link>
            </ContainerLinks>
          ) : null}

          {!!user ? (
            <ContainerLinks>
              <Link to="/">
                <span>Eventos</span>
              </Link>
              <Link to="/profile">
                <span>Perfil</span>
              </Link>
              <Link to="/profile/my-shopping">
                <span>Minhas compras</span>
              </Link>
              <Link to="/profile/my-ticket">
                <span>Meus ingressos</span>
              </Link>
            </ContainerLinks>
          ) : null}

          <Divider />

          <ContainerFooterButtons>
            {!user ? (
              <div className="login">
                <Link to="/signin">
                  <button className="cart">Login</button>
                </Link>
                <Link to="/signin">
                  <button className="account">
                    <AiOutlineUser size={14} />
                    Cadastre-se
                  </button>
                </Link>
              </div>
            ) : null}

            <div>
              <ActionButton
                className="vertical"
                target="_blank"
                rel="noreferrer"
                href={`${link}`}
              >
                <RiCalendarCheckFill />
                Organize seu evento!
              </ActionButton>
            </div>
          </ContainerFooterButtons>
        </Drawer>
      </ContainerItems>
    </StyledHeader>
  );
};

export default React.memo(Header);
