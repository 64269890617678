import React from "react";

const OrganizerObligations = () => {
  return (
    <>
      <h2>Introdu&ccedil;&atilde;o</h2>
      <p>Ol&aacute;!</p>
      <p>
        Seja bem-vindo &agrave; Plataforma XGROW - EVENTX um produto exclusivo
        da Empresa XGROW!
      </p>
      <p>
        A Pol&iacute;tica de Privacidade a seguir &eacute; baseada em nossos
        princ&iacute;pios e valores.
      </p>
      <p>
        Saiba que levamos muito a s&eacute;rio a sua privacidade e, por isso,
        protegemos os seus dados pessoais.
      </p>
      <p>
        Durante a utiliza&ccedil;&atilde;o da plataforma XGROW - EVENTX, os
        dados pessoais &nbsp;e &ldquo;dados n&atilde;o pessoais&rdquo; dos
        usu&aacute;rios poder&atilde;o ser coletados, como por exemplo, ao criar
        uma conta ou se inscrever em um evento. Para cada uma dessas
        informa&ccedil;&otilde;es coletadas, existe um n&iacute;vel de
        prote&ccedil;&atilde;o adequado que adotamos, assim como diferentes
        tipos de utiliza&ccedil;&atilde;o.
      </p>
      <p>
        Este aviso define os princ&iacute;pios de privacidade que seguimos em
        rela&ccedil;&atilde;o &agrave; transfer&ecirc;ncia de dados pessoais que
        recebemos de indiv&iacute;duos que residem no pa&iacute;s ou que visitam
        nossos sites ou aplicativos da web e dispositivos m&oacute;veis e / ou
        que usam qualquer um de nossos servi&ccedil;os ou interagem com a gente
        ("voc&ecirc;").
      </p>
      <p>
        A XGROW - EVENTX cumpre os termos da Lei n&ordm; 13.709/2018 - Lei Geral
        de Prote&ccedil;&atilde;o de Dados Pessoais, bem como os segue os termos
        definidos em sua Pol&iacute;tica de Privacidade.
      </p>
      <p>
        Como condi&ccedil;&atilde;o para acesso e utiliza&ccedil;&atilde;o da
        plataforma XGROW - EVENTX e suas funcionalidades, voc&ecirc; declara que
        realizou a leitura completa e atenta das regras deste documento, estando
        plenamente ciente.
      </p>
      <p>&nbsp;</p>

      <h2>Resumo</h2>
      <p>
        Elencamos abaixo os principais t&oacute;picos que, em nossa
        opini&atilde;o, s&atilde;o absolutamente indispens&aacute;veis para o
        uso da nossa plataforma. Por&eacute;m, n&atilde;o deixe de ler o
        documento por completo.
      </p>
      <p>
        A presente Pol&iacute;tica de Privacidade tem por finalidade estabelecer
        as regras e diretrizes para tratamento de dados pessoais dos
        usu&aacute;rios da plataforma XGROW - EVENTX, tendo como pilares a
        transpar&ecirc;ncia e o controle dos usu&aacute;rios sobre os seus dados
        pessoais;
      </p>
      <p>
        As atividades de tratamento de dados pessoais realizadas pela XGROW -
        EVENTX seguem os princ&iacute;pios da finalidade,
        adequa&ccedil;&atilde;o, necessidade, livre acesso, qualidade dos dados,
        transpar&ecirc;ncia, seguran&ccedil;a, preven&ccedil;&atilde;o,
        n&atilde;o discrimina&ccedil;&atilde;o, responsabiliza&ccedil;&atilde;o
        e presta&ccedil;&atilde;o de contas;
      </p>
      <p>
        Os usu&aacute;rios poder&atilde;o solicitar &agrave; XGROW - EVENTX, de
        forma f&aacute;cil e acess&iacute;vel, atrav&eacute;s do nosso{" "}
        <a href="/privacidade/contato">
          <strong>
            <u>formul&aacute;rio web</u>
          </strong>
        </a>
        &nbsp;no Portal de Privacidade ou do email dpo@eventx.com.br (i) a
        confirma&ccedil;&atilde;o da exist&ecirc;ncia de tratamento de dados
        pessoais; (ii) o acessos aos dados pessoais; (iii) a
        corre&ccedil;&atilde;o de dados incompletos, inexatos ou desatualizados;
        (iv) anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o
        dos dados desnecess&aacute;rios ou excessivos; (v) portabilidade dos
        dados, dentro dos limites legais.
      </p>
      <p>
        A XGROW - EVENTX aplica os seus melhores esfor&ccedil;os para respeitar
        e proteger as informa&ccedil;&otilde;es pessoais dos usu&aacute;rios
        contra perda, roubo ou qualquer modalidade de uso indevido, bem como
        contra acesso n&atilde;o autorizado, divulga&ccedil;&atilde;o,
        altera&ccedil;&atilde;o e destrui&ccedil;&atilde;o;
      </p>
      <p>
        A XGROW - EVENTX coletar&aacute; os dados pessoais de todos os
        usu&aacute;rios sempre que estes fornecerem tais
        informa&ccedil;&otilde;es de forma volunt&aacute;ria, por exemplo,
        quando navegarem, se cadastrarem ou utilizarem a plataforma XGROW -
        EVENTX;
      </p>
      <p>
        A XGROW - EVENTX utilizar&aacute; os dados pessoais para: (i) fins
        internos (auditoria, an&aacute;lise de dados, pesquisas para aprimorar
        os produtos, servi&ccedil;os e comunica&ccedil;&otilde;es com os
        clientes, e gera&ccedil;&atilde;o de an&aacute;lises
        estat&iacute;sticas, incluindo tend&ecirc;ncias de consumo); (ii) enviar
        avisos e notifica&ccedil;&otilde;es importantes; (iii) aprimorar a
        seguran&ccedil;a e melhorar os servi&ccedil;os e as ofertas da XGROW -
        EVENTX; (iv) enviar publicidade, bem como outros materiais promocionais
        (v) para cumprimento de certas obriga&ccedil;&otilde;es legais;
      </p>
      <p>
        A XGROW - EVENTX n&atilde;o comercializa os dados pessoais de seus
        usu&aacute;rios a terceiros, por&eacute;m existem
        situa&ccedil;&otilde;es nas quais a XGROW - EVENTX poder&aacute;
        divulgar, transferir ou compartilhar os dados pessoais com terceiros, em
        raz&atilde;o de interesse leg&iacute;timo ou cumprimento de
        obriga&ccedil;&atilde;o legal, ou ainda, atrav&eacute;s do seu
        consentimento v&aacute;lido;
      </p>
      <p>
        A XGROW - EVENTX armazena dados pessoais t&atilde;o somente pelo tempo
        necess&aacute;rio para alcan&ccedil;ar as finalidades leg&iacute;timas
        previstas neste documento;
      </p>
      <p>
        Em caso de d&uacute;vidas, a comunica&ccedil;&atilde;o entre a XGROW -
        EVENTX e o usu&aacute;rio dever&aacute; ser realizada pelos canais de
        atendimento indicados e disponibilizados na plataforma XGROW - EVENTX,
        sobretudo atrav&eacute;s do endere&ccedil;o de contato@XGROW -
        EVENTX.com.br.
      </p>
      <p>&nbsp;</p>
      <h2>1.Gloss&aacute;rio</h2>
      <p>
        <em>Consumidor:</em>&nbsp;termo utilizado para designar pessoas que
        adquirem ingressos para os eventos criados pelos pr&oacute;prios
        organizadores.
      </p>
      <p>
        <em>Controlador de dados pessoais:</em>&nbsp;pessoa natural ou
        jur&iacute;dica, de direito p&uacute;blico ou privado, a quem competem
        as decis&otilde;es referentes ao tratamento de dados pessoais.
      </p>
      <p>
        <em>Cookies:</em>&nbsp;arquivos que t&ecirc;m a finalidade de
        identificar um computador e obter dados de acesso, como p&aacute;ginas
        navegadas ou links clicados, permitindo, desta forma, personalizar a
        navega&ccedil;&atilde;o destes, de acordo com o perfil do
        usu&aacute;rio.
      </p>
      <p>
        <em>Dado Pessoal:</em>&nbsp;informa&ccedil;&atilde;o relacionada a
        pessoa natural identificada ou identific&aacute;vel.
      </p>
      <p>
        <em>Dado N&atilde;o Pessoal</em>: quaisquer informa&ccedil;&otilde;es
        que n&atilde;o se relacionem com uma pessoa e/ou n&atilde;o possam ser
        usados para identificar uma pessoa.
      </p>
      <p>
        <em>Operador de dados pessoais:</em>&nbsp;pessoa natural ou
        jur&iacute;dica, de direito p&uacute;blico ou privado, que realiza o
        tratamento de dados pessoais em nome do controlador.
      </p>
      <p>
        <em>Organizador:</em>&nbsp;termo utilizado para designar pessoas
        (f&iacute;sicas ou jur&iacute;dicas) que criam eventos pr&oacute;prios
        atrav&eacute;s da Plataforma XGROW - EVENTX e os divulgam para
        Consumidores.
      </p>
      <p>
        <em>Participantes:</em>&nbsp;termo utilizado para designar pessoas que
        usufruem dos ingressos adquiridos pelos Consumidores. Caso os
        Consumidores adquiram ingressos em nome pr&oacute;prio ser&atilde;o
        tamb&eacute;m Participantes. Por outro lado, caso o Consumidor adquira
        ingressos em proveito de terceiros, estes ser&atilde;o considerados
        t&atilde;o somente Participantes, e n&atilde;o Consumidores.
      </p>
      <p>
        <em>Plataforma XGROW - EVENTX:</em>&nbsp;designa a plataforma
        tecnol&oacute;gica dispon&iacute;vel para organizadores e casas de
        eventos, facilitando a venda de ingressos, inscri&ccedil;&atilde;o para
        eventos, cobran&ccedil;a de contribui&ccedil;&otilde;es para eventos e a
        gest&atilde;o de participantes, acess&iacute;vel no endere&ccedil;o
        eletr&ocirc;nico www.XGROW - EVENTX.com.br, ou por meio dos nossos
        aplicativos oficiais.
      </p>
      <p>
        <em>XGROW - EVENTX:</em>&nbsp;&Eacute; um produto da empresa XGROW
        TECNOLOGIA LTDA, pessoa jur&iacute;dica de direito privado, inscrita no
        CNPJ/ME sob o n&ordm;. 40.190.903/0001-05, sediada em Barueri, Estado de
        S&atilde;o Paulo, na Alameda Tocantins, n&ordm; 956, Alphaville
        Industrial, CEP 06455-020.
      </p>
      <p>
        <em>Tratamento de dados pessoais:</em>&nbsp;toda opera&ccedil;&atilde;o
        realizada com dados pessoais, como as que se referem a coleta,
        produ&ccedil;&atilde;o, recep&ccedil;&atilde;o,
        classifica&ccedil;&atilde;o, utiliza&ccedil;&atilde;o, acesso,
        reprodu&ccedil;&atilde;o, transmiss&atilde;o,
        distribui&ccedil;&atilde;o, processamento, arquivamento, armazenamento,
        elimina&ccedil;&atilde;o, avalia&ccedil;&atilde;o ou controle da
        informa&ccedil;&atilde;o, modifica&ccedil;&atilde;o,
        comunica&ccedil;&atilde;o, transfer&ecirc;ncia, difus&atilde;o ou
        extra&ccedil;&atilde;o.
      </p>
      <p>
        <em>Usu&aacute;rio:</em>&nbsp;termo utilizado para designar, quando
        referidos em conjunto, Organizadores, Consumidores e pessoas que navegam
        na Plataforma XGROW - EVENTX.
      </p>
      <h2>
        2. Obten&ccedil;&atilde;o de informa&ccedil;&otilde;es pessoais e
        n&atilde;o pessoais
      </h2>
      <p>
        2.1. Ao utilizar a plataforma XGROW - EVENTX, ser&atilde;o coletadas
        algumas informa&ccedil;&otilde;es, inclusive dados pessoais. A XGROW -
        EVENTX coletar&aacute; tais informa&ccedil;&otilde;es em nome
        pr&oacute;prio ou, em nome do Organizador, que utiliza a plataforma
        XGROW - EVENTX para cria&ccedil;&atilde;o, gest&atilde;o e
        divulga&ccedil;&atilde;o de evento pr&oacute;prio. Esta
        distin&ccedil;&atilde;o &eacute; importante para diferenciar as
        situa&ccedil;&otilde;es em que a XGROW - EVENTX atua na
        condi&ccedil;&atilde;o de controladora ou operadora de dados pessoais.
      </p>
      <p>
        2.2. A XGROW - EVENTX coletar&aacute; os dados pessoais de todos os
        usu&aacute;rios sempre que estes fornecerem tais
        informa&ccedil;&otilde;es de forma volunt&aacute;ria, por exemplo,
        quando navegarem ou utilizarem a plataforma XGROW - EVENTX ou qualquer
        de suas funcionalidades, ou quando entrarem em contato com a XGROW -
        EVENTX. Os dados pessoais que podemos coletar incluem: nome, data de
        nascimento, n&uacute;mero do CPF, endere&ccedil;o eletr&ocirc;nico,
        endere&ccedil;o de correspond&ecirc;ncia, senha, n&uacute;mero de
        telefone e prefer&ecirc;ncias de contato, entre outros
        necess&aacute;rios para atingir os prop&oacute;sitos dispostos nesta
        Pol&iacute;tica.
      </p>
      <p>
        2.3. Ao se cadastrar na plataforma XGROW - EVENTX, o usu&aacute;rio
        poder&aacute; utilizar sua conta pessoal de redes sociais, tal como o
        Facebook. Caso utilize tal op&ccedil;&atilde;o, o usu&aacute;rio
        permitir&aacute; que a XGROW - EVENTX acesse as
        informa&ccedil;&otilde;es pessoais em sua conta do Facebook, tais como:
        nome, endere&ccedil;o de e-mail, g&ecirc;nero, idade e telefone (caso o
        tenha cadastrado no Facebook). As informa&ccedil;&otilde;es que a XGROW
        - EVENTX poder&aacute; obter, neste caso, dependem das
        configura&ccedil;&otilde;es de privacidade definidas pelo pr&oacute;prio
        usu&aacute;rio junto ao servi&ccedil;o de rede social.
      </p>
      <p>
        2.4. Al&eacute;m das informa&ccedil;&otilde;es acima mencionadas, o
        usu&aacute;rio poder&aacute; fornecer outros tipos de
        informa&ccedil;&otilde;es, tais como, dados de pagamento,
        informa&ccedil;&otilde;es de geolocaliza&ccedil;&atilde;o, comportamento
        de uso do aplicativo ou seus produtos e, informa&ccedil;&otilde;es
        referentes ao dispositivo computacional utilizado para navegar ou
        utilizar a plataforma XGROW - EVENTX, como por exemplo: endere&ccedil;os
        IP, tipo de navegador e idioma, provedor de servi&ccedil;os de Internet,
        p&aacute;ginas de consulta e sa&iacute;da, sistema operacional,
        informa&ccedil;&otilde;es sobre data e hor&aacute;rio, dados sobre a
        sequ&ecirc;ncia de cliques, fabricante do dispositivo, operadora,
        modelo, redes Wi-Fi e n&uacute;mero de telefone.
      </p>
      <p>
        2.5. A XGROW - EVENTX poder&aacute; tamb&eacute;m obter
        informa&ccedil;&otilde;es n&atilde;o pessoais e
        informa&ccedil;&otilde;es sobre as atividades dos usu&aacute;rios na
        plataforma XGROW - EVENTX. Tais informa&ccedil;&otilde;es poder&atilde;o
        ser agregadas e usadas para ajudar a XGROW - EVENTX a fornecer
        informa&ccedil;&otilde;es para os Organizadores e entender quais partes
        dos produtos e servi&ccedil;os atraem mais interesse dos Consumidores.
        Dados agregados s&atilde;o considerados informa&ccedil;&otilde;es
        n&atilde;o-pessoais para fins desta Pol&iacute;tica de Privacidade.
      </p>
      <p>
        2.5.1. Caso a XGROW - EVENTX combine informa&ccedil;&otilde;es
        n&atilde;o-pessoais com informa&ccedil;&otilde;es pessoais, a
        informa&ccedil;&atilde;o combinada ser&aacute; tratada como
        informa&ccedil;&atilde;o pessoal enquanto essa informa&ccedil;&atilde;o
        permanecer combinada.
      </p>
      <p>
        2.5.1.1. Caso a informa&ccedil;&atilde;o combinada seja considerada
        sens&iacute;vel, ou seja, capaz de revelar dados relacionados &agrave;
        origem racial ou &eacute;tnica, convic&ccedil;&atilde;o religiosa,
        opini&atilde;o pol&iacute;tica, filia&ccedil;&atilde;o a sindicato ou a
        organiza&ccedil;&atilde;o de car&aacute;ter religioso, filos&oacute;fico
        ou pol&iacute;tico, dado referente &agrave; sa&uacute;de ou &agrave;
        vida sexual, dado gen&eacute;tico ou biom&eacute;trico, o tratamento
        dessas informa&ccedil;&otilde;es ser&aacute; realizada t&atilde;o
        somente mediante o consentimento do titular. Tais
        informa&ccedil;&otilde;es ser&atilde;o armazenadas de maneira segura e
        n&atilde;o ser&atilde;o compartilhadas com terceiros, salvo ap&oacute;s
        processo de anonimiza&ccedil;&atilde;o.
      </p>
      <p>
        2.6. A XGROW - EVENTX poder&aacute; coletar informa&ccedil;&otilde;es
        adicionais de Organizadores, tais como informa&ccedil;&otilde;es
        financeiras, de modo a garantir determinados pagamentos e emiss&atilde;o
        de Nota Fiscal.
      </p>
      <p>
        2.7. A XGROW - EVENTX poder&aacute; coletar informa&ccedil;&otilde;es
        adicionais de consumidores, por exemplo, quando adquirirem ingressos
        pagos, caso em que o usu&aacute;rio fornecer&aacute; dados pessoais
        financeiros (ex.: n&uacute;mero de cart&atilde;o de cr&eacute;dito e
        data de expira&ccedil;&atilde;o, endere&ccedil;o de cobran&ccedil;a
        etc.).
      </p>
      <p>
        2.7.1. Os Organizadores poder&atilde;o configurar, a seu exclusivo
        crit&eacute;rio, a p&aacute;gina de seus eventos para coletar outras
        informa&ccedil;&otilde;es pessoais dos consumidores. Nestes casos, a
        XGROW - EVENTX, na condi&ccedil;&atilde;o de operadora de dados
        pessoais, n&atilde;o interferir&aacute; no processo de tratamento de
        dados realizado pelo Organizador, nem sobre quais dados pessoais
        ser&atilde;o coletados ou como ser&atilde;o utilizados por este.
      </p>
      <p>
        2.7.2. Os dados pessoais coletados em nome dos Organizadores ficam
        dispon&iacute;veis para estes. Dessa maneira, os Organizadores, na
        condi&ccedil;&atilde;o de controladores de dados pessoais, s&atilde;o
        respons&aacute;veis pelas opera&ccedil;&otilde;es de tratamento que
        realizam sobre estes dados, tais como, compartilhamento, an&aacute;lise,
        armazenamento, entre outros.
      </p>
      <p>
        2.7.3. A XGROW - EVENTX possui documento espec&iacute;fico
        (&ldquo;Acordo de Processamento de Dados&rdquo;), que estabelece seus
        direitos e obriga&ccedil;&otilde;es, na condi&ccedil;&atilde;o de
        Operadora de dados pessoais, referente &agrave;s opera&ccedil;&otilde;es
        de tratamento realizadas em nome dos Organizadores.
      </p>
      <p>
        2.7.4. Os titulares de dados pessoais dever&atilde;o exercer os direitos
        previstos no item &ldquo;7&rdquo; deste documento perante os
        Organizadores, podendo a XGROW - EVENTX operacionalizar tais
        requisi&ccedil;&otilde;es em determinados casos.
      </p>
      <p>
        2.8. A XGROW - EVENTX poder&aacute;, tamb&eacute;m, obter
        informa&ccedil;&otilde;es de seus usu&aacute;rios a partir de fontes
        diversas, tais como sites de terceiros, institui&ccedil;&otilde;es
        banc&aacute;rias, processadores de pagamento, e outras empresas ou
        ag&ecirc;ncias de prote&ccedil;&atilde;o de cr&eacute;dito. Nesta
        atividade ser&atilde;o observados as regras contidas nas
        legisla&ccedil;&otilde;es aplic&aacute;veis.
      </p>
      <p>
        2.9. Para fins do disposto na Lei n&ordm; 13.709/2018 (&ldquo;Lei Geral
        de Prote&ccedil;&atilde;o de Dados Pessoais&rdquo;), a XGROW - EVENTX
        poder&aacute; atuar tanto na qualidade de controladora de dados pessoais
        (ex.: recomenda&ccedil;&atilde;o de eventos na plataforma XGROW -
        EVENTX), quanto operadora de dados pessoais (ex.: armazenamento de dados
        pessoais coletados a partir do formul&aacute;rio personalizado do
        Organizador), a depender das circunst&acirc;ncias e caso concreto.
      </p>
      <h2>
        3. Utiliza&ccedil;&atilde;o das informa&ccedil;&otilde;es pessoais
      </h2>
      <p>
        3.1. Os dados pessoais ser&atilde;o utilizados de forma
        compat&iacute;vel com o disposto nesta Pol&iacute;tica de Privacidade,
        com objetivo de prover, desenvolver e melhorar os servi&ccedil;os
        prestados ou produtos oferecidos, inclusive aqueles a serem criados.
      </p>
      <p>
        3.2. A XGROW - EVENTX utilizar&aacute; os dados pessoais das seguintes
        maneiras:
      </p>
      <ol style={{ marginLeft: "3em" }}>
        <li>
          a) fins internos e gerenciais, tais como auditoria, an&aacute;lise de
          dados e pesquisas para aprimorar os produtos, servi&ccedil;os e
          comunica&ccedil;&otilde;es com os clientes, e gera&ccedil;&atilde;o de
          an&aacute;lises estat&iacute;sticas com respeito ao uso dos nossos
          servi&ccedil;os, incluindo tend&ecirc;ncias de consumo;
        </li>
        <li>
          b) enviar avisos e notifica&ccedil;&otilde;es importantes, como
          comunicados sobre compras, altera&ccedil;&otilde;es em prazos,
          condi&ccedil;&otilde;es e pol&iacute;ticas. Como estas
          informa&ccedil;&otilde;es s&atilde;o importantes para
          intera&ccedil;&atilde;o com a XGROW - EVENTX, o usu&aacute;rio
          n&atilde;o poder&aacute; optar por n&atilde;o receber esse tipo de
          comunica&ccedil;&atilde;o, j&aacute; que s&atilde;o inerentes ao uso
          da plataforma XGROW - EVENTX;
        </li>
        <li>
          c) aprimorar a seguran&ccedil;a e melhorar os servi&ccedil;os e as
          ofertas da XGROW - EVENTX, bem como analisar e solucionar problemas
          t&eacute;cnicos, al&eacute;m de identificar e coibir fraudes na
          utiliza&ccedil;&atilde;o da plataforma XGROW - EVENTX;
        </li>
        <li>
          d) enviar publicidade, bem como outros materiais promocionais voltados
          ao marketing de nossos servi&ccedil;os e de novidades para
          usu&aacute;rios, o que inclui campanhas digitais (tais como marketing
          direcionado em redes sociais e notifica&ccedil;&otilde;es) e
          n&atilde;o digitais (como divulga&ccedil;&atilde;o em
          radiodifus&atilde;o, outdoors, panfletos, entre outros).
        </li>
      </ol>
      <p>
        3.2.1. A XGROW - EVENTX realizar&aacute; o tratamento dos dados pessoais
        com base nas seguintes hip&oacute;teses legais: cumprimento de
        obriga&ccedil;&atilde;o legal, execu&ccedil;&atilde;o de contrato,
        exerc&iacute;cio regular de direitos, atendimento de interesses
        leg&iacute;timos. A base legal do tratamento poder&aacute; variar
        conforme a atividade realizada.
      </p>
      <p>
        3.3. A XGROW - EVENTX permite que os Organizadores usem a plataforma
        XGROW - EVENTX e suas funcionalidades para entrarem em contato com os
        consumidores e outros usu&aacute;rios em rela&ccedil;&atilde;o aos seus
        eventos. Essa ferramenta funciona como uma caixa de e-mails
        padr&atilde;o, onde o Organizador pode inserir os destinat&aacute;rios
        automaticamente (atrav&eacute;s das listas de e-mail associadas aos seus
        eventos hospedados na plataforma XGROW - EVENTX) ou manualmente
        (atrav&eacute;s de listas de e-mails coletados fora da plataforma XGROW
        - EVENTX) e ent&atilde;o efetuar os envios. Os comunicados ser&atilde;o
        enviados pelo remetente convite@eventx.com.br.
      </p>
      <p>
        3.3.1. O Organizador &eacute; exclusivamente respons&aacute;vel pelo
        conte&uacute;do e envio dessas comunica&ccedil;&otilde;es, cabendo a
        este tamb&eacute;m cessar tal envio, sempre que solicitado pelo
        destinat&aacute;rio. O Organizador tamb&eacute;m &eacute;
        respons&aacute;vel pelo tratamento dos dados pessoais dos titulares
        destinat&aacute;rios dessas comunica&ccedil;&otilde;es.
      </p>
      <p>
        3.3.2. O Consumidor fica ciente que o descadastramento da lista de email
        de um Organizador n&atilde;o impede o recebimento de
        comunica&ccedil;&otilde;es enviadas por outros Organizadores
        atrav&eacute;s da plataforma XGROW - EVENTX. Para tanto, os Consumidores
        dever&atilde;o bloquear o remetente convite@eventx.com.br em seu
        provedor de email.
      </p>
      <p>
        3.4. Caso a XGROW - EVENTX utilize quaisquer dados pessoais de forma
        diversa das estabelecidas nesta Pol&iacute;tica de Privacidade, o
        usu&aacute;rio ser&aacute; previamente informado sobre essa nova
        utiliza&ccedil;&atilde;o, antes ou na data em que os dados pessoais
        forem recebidos.
      </p>
      <h2>4. Compartilhamento de informa&ccedil;&otilde;es pessoais</h2>
      <p>
        4.1. A XGROW - EVENTX n&atilde;o comercializa os dados pessoais de seus
        usu&aacute;rios a terceiros, por&eacute;m existem
        situa&ccedil;&otilde;es nas quais a XGROW - EVENTX poder&aacute;
        divulgar, transferir ou compartilhar os dados pessoais com terceiros,
        conforme disposto neste documento e na legisla&ccedil;&atilde;o
        brasileira.
      </p>
      <p>
        4.2. A XGROW - EVENTX poder&aacute; vender ou comprar outras empresas ou
        ativos. Em caso de venda, fus&atilde;o, reorganiza&ccedil;&atilde;o,
        dissolu&ccedil;&atilde;o da empresa ou outra opera&ccedil;&atilde;o
        societ&aacute;ria semelhante, os dados pessoais poder&atilde;o fazer
        parte dos ativos intang&iacute;veis compartilhados ou transferidos.
      </p>
      <p>
        4.2.1. A XGROW - EVENTX poder&aacute; realizar o compartilhamento de
        dados pessoais com empresas do mesmo grupo econ&ocirc;mico, com as
        seguintes finalidades:
      </p>
      <p>(i) o desenvolvimento de novos produtos e servi&ccedil;os;</p>
      <p>
        (ii) a oferta de produtos e servi&ccedil;os que melhor atendam aos
        interesses dos usu&aacute;rios; (iii) gera&ccedil;&atilde;o de dados
        estat&iacute;sticos e agregados acerca do uso de produtos e
        servi&ccedil;os e perfis dos usu&aacute;rios.
      </p>
      <p>
        4.3. A XGROW - EVENTX poder&aacute; compartilhar os dados pessoais de
        seus usu&aacute;rios com contratados e prestadores de servi&ccedil;os
        que tratam tais dados em nome da XGROW - EVENTX, de modo a desempenhar
        determinadas fun&ccedil;&otilde;es relacionadas com as atividades da
        XGROW - EVENTX, tais como, mas n&atilde;o somente, ag&ecirc;ncias de
        marketing, prestadores de servi&ccedil;os de bancos de dados,
        prestadores de servi&ccedil;os de recupera&ccedil;&atilde;o ap&oacute;s
        desastres e de c&oacute;pias de seguran&ccedil;a, prestadores de
        servi&ccedil;os de e-mail, processadores de pagamentos e outros.
      </p>
      <p>
        4.4. A XGROW - EVENTX permite aos usu&aacute;rios se cadastrarem na
        plataforma XGROW - EVENTX atrav&eacute;s de contas pessoais em
        servi&ccedil;os de terceiros, como Facebook. Nesse caso, a XGROW -
        EVENTX poder&aacute; coletar, utilizar, divulgar, transferir e armazenar
        ou reter informa&ccedil;&otilde;es relacionadas com a sua conta junto a
        esses servi&ccedil;os de terceiros, em conformidade com esta
        Pol&iacute;tica de Privacidade.
      </p>
      <p>
        4.5. A XGROW - EVENTX poder&aacute; compartilhar as
        informa&ccedil;&otilde;es de seus usu&aacute;rios com autoridades
        policiais ou judiciais, autoridades p&uacute;blicas competentes ou
        outros terceiros, dentro e fora do Brasil, caso seja requerido pela
        legisla&ccedil;&atilde;o aplic&aacute;vel, por decis&atilde;o judicial e
        por requisi&ccedil;&atilde;o de autoridades, ou se necess&aacute;rio
        para responder a processos judiciais ou para participar em eventuais
        lit&iacute;gios ou disputas de qualquer natureza.
      </p>
      <p>
        4.5.1. Nestas situa&ccedil;&otilde;es, a XGROW - EVENTX cooperar&aacute;
        com as autoridades competentes na medida em que, discricionariamente,
        entenda necess&aacute;rio e adequado em rela&ccedil;&atilde;o a qualquer
        investiga&ccedil;&atilde;o de il&iacute;citos, infra&ccedil;&otilde;es a
        direitos de propriedade industrial ou intelectual, ou outra atividade
        que seja ilegal ou que possa expor a XGROW - EVENTX ou seus
        usu&aacute;rios a qualquer responsabilidade legal ou lhes impor riscos,
        ressalvadas hip&oacute;teses de sigilo de informa&ccedil;&otilde;es
        constantes na legisla&ccedil;&atilde;o aplic&aacute;vel.
      </p>
      <p>
        4.6. A XGROW - EVENTX se reserva o direito de compartilhar
        informa&ccedil;&otilde;es sobre seus usu&aacute;rios a terceiros quando
        houver motivos suficientes para considerar que a atividade de um
        usu&aacute;rio &eacute; suspeita, ilegal ou prejudicial &agrave; XGROW -
        EVENTX ou a terceiros.
      </p>
      <p>&nbsp;</p>
      <h2>5. Armazenamento de informa&ccedil;&otilde;es pessoais</h2>
      <p>
        5.1. As informa&ccedil;&otilde;es pessoais coletadas pela XGROW - EVENTX
        ser&atilde;o armazenadas em servi&ccedil;os de nuvem confi&aacute;veis,
        providos por parceiros que podem estar localizados no Brasil ou em
        outros pa&iacute;ses, tais como Estados Unidos da Am&eacute;rica (EUA) e
        em pa&iacute;ses da Uni&atilde;o Europeia.
      </p>
      <p>
        5.1.1. Na contrata&ccedil;&atilde;o desses servi&ccedil;os, a XGROW -
        EVENTX buscar&aacute; empresas que empregam alto n&iacute;vel de
        seguran&ccedil;a no armazenamento de suas informa&ccedil;&otilde;es,
        estabelecendo contratos que n&atilde;o violam as
        defini&ccedil;&otilde;es de privacidade previstas nesta Pol&iacute;tica.
      </p>
      <p>
        5.2. A XGROW - EVENTX armazena as informa&ccedil;&otilde;es dos
        usu&aacute;rios t&atilde;o somente pelo per&iacute;odo necess&aacute;rio
        para as finalidades apresentadas nos Termos de Uso e nesta
        Pol&iacute;tica de Privacidade, respeitando sempre o per&iacute;odo de
        reten&ccedil;&atilde;o de dados determinado pela
        legisla&ccedil;&atilde;o aplic&aacute;vel.
      </p>
      <p>
        5.2.1. Caso o usu&aacute;rio solicite a exclus&atilde;o de sua conta, as
        informa&ccedil;&otilde;es pessoais fornecidas durante a
        utiliza&ccedil;&atilde;o da plataforma XGROW - EVENTX ser&atilde;o
        anonimizadas ou exclu&iacute;das definitivamente, salvo se tais
        informa&ccedil;&otilde;es forem necess&aacute;rias para cumprimento de
        obriga&ccedil;&atilde;o legal pela XGROW - EVENTX, atendimento de
        interesses leg&iacute;timos, ou para exerc&iacute;cio regular de
        direitos em processo judicial, administrativo ou arbitral.
      </p>
      <p>
        5.3. A XGROW - EVENTX emprega seus melhores esfor&ccedil;os para
        respeitar e proteger as informa&ccedil;&otilde;es pessoais dos
        usu&aacute;rios contra perda, roubo ou qualquer modalidade de uso
        indevido, bem como contra acesso n&atilde;o autorizado,
        divulga&ccedil;&atilde;o, altera&ccedil;&atilde;o e
        destrui&ccedil;&atilde;o.
      </p>
      <p>
        5.3.1. A XGROW - EVENTX realizar&aacute; o tratamento de dados pessoais
        mediante alto grau de seguran&ccedil;a, implementando as melhores
        pr&aacute;ticas em uso na ind&uacute;stria para a prote&ccedil;&atilde;o
        de dados, tais como t&eacute;cnicas de criptografia,
        monitora&ccedil;&atilde;o e testes de seguran&ccedil;a
        peri&oacute;dicos, firewall, entre outros. Contudo, n&atilde;o &eacute;
        poss&iacute;vel garantir completamente a n&atilde;o ocorr&ecirc;ncia de
        intercepta&ccedil;&otilde;es e viola&ccedil;&otilde;es dos sistemas e
        bases de dados, uma vez que a internet possui sua estrutura de
        seguran&ccedil;a em permanente aperfei&ccedil;oamento.
      </p>
      <p>
        5.3.2. Em caso de incidente de seguran&ccedil;a da
        informa&ccedil;&atilde;o que resulte na destrui&ccedil;&atilde;o, perda,
        altera&ccedil;&atilde;o, acesso n&atilde;o-autorizado, ou vazamento de
        dados pessoais, a XGROW - EVENTX ir&aacute; avaliar, imediatamente, os
        riscos &agrave;s liberdades civis e aos direitos fundamentais dos
        titulares dos dados pessoais. A comunica&ccedil;&atilde;o aos titulares
        ou &agrave; Autoridade Nacional de Prote&ccedil;&atilde;o de Dados
        ser&aacute; realizada conforme o caso concreto, ap&oacute;s
        avalia&ccedil;&atilde;o dos riscos mencionados.
      </p>
      <h2>6. Transfer&ecirc;ncia Internacional de dados</h2>
      <p>&nbsp;</p>
      <p>
        6.1. A XGROW - EVENTX poder&aacute; realizar transfer&ecirc;ncias
        internacionais de dados para outros pa&iacute;ses, tais como Estados
        Unidos da Am&eacute;rica e para pa&iacute;ses da Uni&atilde;o Europeia,
        a fim de realizar algumas das atividades envolvidas nos servi&ccedil;os
        prestados aos usu&aacute;rios, bem como para poder obter
        informa&ccedil;&otilde;es que possam contribuir para o
        aperfei&ccedil;oamento e seguran&ccedil;a dos nossos servi&ccedil;os.
      </p>
      <p>
        6.2. Na hip&oacute;tese de compartilhamento com parceiros localizados em
        outros pa&iacute;ses, estabelecemos contratualmente que o parceiro
        possua padr&atilde;o de prote&ccedil;&atilde;o de dados e
        seguran&ccedil;a da informa&ccedil;&atilde;o compat&iacute;vel com esta
        pol&iacute;tica de privacidade, a fim de que os dados sejam sempre
        protegidos nestes termos.
      </p>
      <h2>7. Direitos dos usu&aacute;rios</h2>
      <p>
        7.1. Os usu&aacute;rios poder&atilde;o solicitar &agrave; XGROW -
        EVENTX, de forma f&aacute;cil e acess&iacute;vel, atrav&eacute;s do
        nosso formul&aacute;rio web no Portal de Privacidade ou do
        contato@eventx.com.br (i) a confirma&ccedil;&atilde;o da
        exist&ecirc;ncia de tratamento de dados pessoais; (ii) o acessos aos
        dados pessoais; (iii) a corre&ccedil;&atilde;o de dados incompletos,
        inexatos ou desatualizados; (iv) anonimiza&ccedil;&atilde;o, bloqueio ou
        elimina&ccedil;&atilde;o dos dados desnecess&aacute;rios ou excessivos;
        (v) portabilidade dos dados, dentro dos limites legais.
      </p>
      <p>
        7.2. Se um usu&aacute;rio iniciar uma solicita&ccedil;&atilde;o de
        exclus&atilde;o de dados de sua titularidade, a XGROW - EVENTX
        estar&aacute; autorizada a excluir ou anonimizar os dados pessoais do
        solicitante, mesmo que isso signifique remover sua disponibilidade para
        o Organizador na plataforma XGROW - EVENTX. Todavia, os dados pessoais
        ainda poder&atilde;o estar dispon&iacute;veis nos pr&oacute;prios bancos
        de dados do Organizador se tiverem sido transmitidos ao Organizador
        antes da XGROW - EVENTX receber ou tomar medidas referentes &agrave;
        exclus&atilde;o ou anonimiza&ccedil;&atilde;o.
      </p>
      <h2>
        8. Utiliza&ccedil;&atilde;o da Plataforma XGROW - EVENTX por menores e
        incapazes
      </h2>
      <p>
        8.1. Ao se cadastrar na plataforma XGROW - EVENTX, o usu&aacute;rio
        garante de forma expressa que &eacute; plenamente capaz, nos termos da
        legisla&ccedil;&atilde;o vigente, para exercer e gozar de todos os
        Servi&ccedil;os. Ademais, os Usu&aacute;rios menores de 18 (dezoito)
        anos dever&atilde;o obter o consentimento expresso de seus pais, tutores
        ou representantes legais para utilizar a plataforma XGROW - EVENTX e
        suas funcionalidades, conforme as disposi&ccedil;&otilde;es dos Termos
        de Uso e da Pol&iacute;tica de Privacidade.
      </p>
      <p>
        8.2. Os pais, tutores ou representantes legais tamb&eacute;m
        ser&atilde;o plenamente respons&aacute;veis no caso de acesso &agrave;
        plataforma XGROW - EVENTX por parte de crian&ccedil;as e adolescentes,
        sem a devida obten&ccedil;&atilde;o de autoriza&ccedil;&atilde;o
        pr&eacute;via. Cabe a eles a integral responsabilidade pela
        fiscaliza&ccedil;&atilde;o das atividades e conduta dos respectivos
        menores sob sua tutela, bem como ci&ecirc;ncia da integralidade dos
        presentes Termos.
      </p>
      <h2>
        <em>9. Cookies </em>e outras tecnologias
      </h2>
      <p>
        9.1. A XGROW - EVENTX possui pol&iacute;tica pr&oacute;pria sobre o uso
        de <em>cookies </em>e outras tecnologias de rastreamento semelhantes,
        denominada &ldquo;Pol&iacute;tica de Cookies&rdquo;.
      </p>
      <h2>&nbsp;</h2>
      <h2>10. Disposi&ccedil;&otilde;es Gerais</h2>
      <p>&nbsp;</p>
      <p>
        10.1. Ao navegar pela plataforma XGROW - EVENTX e utilizar suas
        funcionalidades, os usu&aacute;rios aceitam todo o disposto nesta
        Pol&iacute;tica de Privacidade e demais pol&iacute;ticas legais que se
        encontram vigentes na data de acesso, assim, &eacute;
        recomend&aacute;vel que os usu&aacute;rios se mantenham atualizados.
      </p>
      <p>
        10.2. A presente Pol&iacute;tica de Privacidade est&aacute; sujeita a
        constantes melhorias e aprimoramentos. Assim, a XGROW - EVENTX se
        reserva no direito de modific&aacute;-la a qualquer momento, conforme a
        finalidade da plataforma XGROW - EVENTX, tal qual para
        adequa&ccedil;&atilde;o e conformidade legal de disposi&ccedil;&atilde;o
        de lei ou norma que tenha for&ccedil;a jur&iacute;dica equivalente,
        cabendo aos usu&aacute;rios verific&aacute;-la sempre que efetuar o
        acesso &agrave; plataforma XGROW - EVENTX.
      </p>
      <p>
        10.3. A eventual toler&acirc;ncia quanto a qualquer
        viola&ccedil;&atilde;o dos termos e condi&ccedil;&otilde;es contidos
        neste dom&iacute;nio ser&aacute; considerada mera liberalidade e
        n&atilde;o ser&aacute; interpretada como nova&ccedil;&atilde;o,
        precedente invoc&aacute;vel, ren&uacute;ncia a direitos,
        altera&ccedil;&atilde;o t&aacute;cita dos termos contratuais, direito
        adquirido ou altera&ccedil;&atilde;o contratual.
      </p>
      <p>
        10.4. Caso alguma disposi&ccedil;&atilde;o desta Pol&iacute;tica for
        julgada inaplic&aacute;vel ou sem efeito, o restante das normas
        continuam a viger, sem a necessidade de medida judicial que declare tal
        assertiva. Os termos aqui descritos ser&atilde;o interpretados segundo a
        legisla&ccedil;&atilde;o brasileira.
      </p>
      <p>
        10.5. A comunica&ccedil;&atilde;o entre a XGROW - EVENTX e o
        usu&aacute;rio dever&aacute; ser realizada pelos canais de atendimento
        indicados e disponibilizados na plataforma XGROW - EVENTX, sobretudo
        atrav&eacute;s do endere&ccedil;o de e-mail contato@eventx.com.br.
      </p>
      <p>
        10.6. Sempre que poss&iacute;vel, potenciais diverg&ecirc;ncias entre o
        usu&aacute;rio e a XGROW - EVENTX ser&atilde;o resolvidas de forma
        amig&aacute;vel. Quando todos os esfor&ccedil;os neste sentido forem
        esgotados, voc&ecirc; concorda, desde j&aacute;, que fica eleito o foro
        da comarca de Barueri - S&atilde;o Paulo, para resolver
        controv&eacute;rsias ou queixas oriundas da utiliza&ccedil;&atilde;o de
        nossa plataforma ou relacionadas a esta Pol&iacute;tica de Privacidade.
      </p>
    </>
  );
};

export default OrganizerObligations;
