import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import hasCookiesEnabled from "utils/hasCookiesEnabled";

import Popup from "containers/Popup";
import { Header } from "components/Header";
import { Footer as LayoutFooter } from "components/Footer";
import FirstSection from "./FirstSection";
import ContactForm from "./ContactForm";

const ContactUs: React.FC = () => {
  const { id } = useParams();

  const [isVisible, setIsVisible] = useState(false);
  const handleCookies = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setIsVisible(true);
  }, [setIsVisible, id]);

  const metaTags = {
    title: `Fale conosco - ${process.env.REACT_APP_EVENTX_META_TITLE}`,
    description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
    keywords: `${process.env.REACT_APP_EVENTX_META_KEYWORDS}, fale conosco, atendimento, suporte, contato, ouvidoria, chamado, falar com`,
  };

  return (
    <>
      <Header metaTags={metaTags} />

      <FirstSection />

      <ContactForm />
      {isVisible && <Popup handleVisible={handleCookies} />}
      <LayoutFooter />
    </>
  );
};
export default ContactUs;
