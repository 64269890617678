import React, { useState, useEffect, FormEvent, useCallback } from "react";

import { Col, Divider, Row, Space, Steps } from "antd";
// import { MaskedInput } from "antd-mask-input";
import Form from "antd/lib/form/Form";

import {
  RiArrowDownSLine,
  RiBuilding4Line,
  RiCloseCircleLine,
  RiContactsLine,
  RiCoupon2Line,
  RiMailLine,
  RiUser2Line,
} from "react-icons/ri";

import { cpf as cpfKeyUp, cnpj as cnpjKeyUp } from "utils/masks";

import ReactPixel from "react-facebook-pixel";

import { authClient } from "services/authClient";
import { useAuth } from "contexts/AuthContext";

import {
  CardFooter,
  CardForm,
  PurchaseHeader,
  StyledModal,
  StyledSteps,
  PrimaryButton,
  SecondaryButton,
  CardTicket,
  CardTicketPanel,
  CardPanelHeader,
} from "./styles";

import { FormItem } from "components/Form/FormItem";
import { Input } from "components/Form/Input";
import { Radio } from "components/Form/Radio";
import ModalRedirect from "../ModalRedirect";

const { Step } = Steps;

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

interface ErrorEdit {
  data: {
    error: string;
  };
}

interface ModalPurchaseStepsProps {
  setStep: (value: number) => void;
}

const Step1: React.FC<ModalPurchaseStepsProps> = ({ setStep }) => {
  const { user } = useAuth();

  const expression =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

  const [formEditable, setFormEditable] = useState<boolean>(true);

  const [selectedId, setSelectedId] = useState(
    user?.type_document == "2" ? 2 : 1
  );
  const [name] = useState(user?.name || "");
  const [bussines_name] = useState(user?.name || "");
  const [email] = useState(user?.email || "");
  const [bussines_email] = useState(user?.email || "");
  const [cpf] = useState(user?.document || "");
  const [cnpj] = useState(user?.document || "");

  const checkStep0_0 = async (values: any) => {
    const formValue = values;

    if (expression.test(String(email).toLowerCase()) == false) {
      alert("E-mail incorreto, por favor verifique!");
    } else {
      localStorage.setItem("@ventX-tk_formType", "1");
      localStorage.setItem("@ventX-tk_formName", formValue.name);
      localStorage.setItem("@ventX-tk_formDocument", formValue.cpf);
      localStorage.setItem("@ventX-tk_formMail", formValue.email);

      setStep(1);
    }
  };

  const checkStep0_1 = async (values: any) => {
    const formValue = values;

    if (expression.test(String(bussines_email).toLowerCase()) == false) {
      alert("E-mail incorreto, por favor verifique!");
    } else {
      localStorage.setItem("@ventX-tk_formType", "2");
      localStorage.setItem("@ventX-tk_formName", formValue.bussines_name);
      localStorage.setItem("@ventX-tk_formDocument", formValue.cnpj);
      localStorage.setItem("@ventX-tk_formMail", formValue.bussines_email);

      setStep(1);
    }
  };

  const handleKeyUpCpf = useCallback((e: FormEvent<HTMLInputElement>) => {
    cpfKeyUp(e);
  }, []);

  const handleKeyUpCnpj = useCallback((e: FormEvent<HTMLInputElement>) => {
    cnpjKeyUp(e);
  }, []);

  return (
    <CardForm>
      <div className="heading">
        <p className="title">1. Dados do comprador</p>
        <p className="description">
          Insira seus dados básicos de identificação referente à compra dos
          ingressos.
        </p>
      </div>
      <Divider />
      <div className="form-section">
        <p className="title">Escolha um tipo de identificação</p>
        <Radio.Group
          defaultValue={selectedId}
          onChange={(event) => setSelectedId(event.target.value)}
        >
          <Space direction="horizontal" size="large">
            <Radio value={1}>Pessoa física</Radio>
            <Radio value={2}>Empresa</Radio>
          </Space>
        </Radio.Group>
      </div>
      <Divider />
      <div className="form-section">
        <p className="title">Informe seus dados</p>
        {(() => {
          switch (selectedId) {
            case 1:
              return (
                <>
                  <Form
                    name="ste0_0FormValue"
                    onFinish={checkStep0_0}
                    layout="vertical"
                    scrollToFirstError
                    style={{ width: "100%" }}
                    initialValues={{
                      name: name,
                      document: cpf,
                      email: email,
                    }}
                  >
                    <Row>
                      <Col lg={24} xs={24}>
                        <FormItem
                          label={
                            <>
                              <RiUser2Line />
                              Nome
                            </>
                          }
                          name="name"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira o seu nome.",
                            },
                          ]}
                        >
                          <Input placeholder="Insira seu nome" />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col lg={12} xs={24}>
                        <FormItem
                          label={
                            <>
                              <RiContactsLine />
                              CPF
                            </>
                          }
                          name="document"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira o seu CPF.",
                            },
                          ]}
                        >
                          {/* <MaskedInput
                            className="masked-input"
                            mask="111.111.111-11"
                            maxLength={11}
                            placeholder="000.000.000-00"
                          /> */}

                          <Input
                            className="masked-input"
                            placeholder="000.000.000-00"
                            onKeyUp={handleKeyUpCpf}
                          />
                        </FormItem>
                      </Col>
                      <Col lg={12} xs={24}>
                        <FormItem
                          label={
                            <>
                              <RiMailLine />
                              Email
                            </>
                          }
                          name="email"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira o seu email.",
                            },
                          ]}
                        >
                          <Input placeholder="exemplo@exemplo.com" />
                        </FormItem>
                      </Col>
                    </Row>
                    <CardFooter>
                      <SecondaryButton>Voltar</SecondaryButton>
                      <PrimaryButton htmlType="submit" loading={!formEditable}>
                        Continuar
                      </PrimaryButton>
                    </CardFooter>
                  </Form>
                </>
              );
            case 2:
              return (
                <>
                  <Form
                    name="ste0_1FormValue"
                    onFinish={checkStep0_1}
                    layout="vertical"
                    scrollToFirstError
                    style={{ width: "100%" }}
                    initialValues={{
                      bussines_name: bussines_name,
                      document: cnpj.toString(),
                      bussines_email: bussines_email,
                    }}
                  >
                    <Row gutter={20}>
                      <Col lg={12} xs={24}>
                        <FormItem
                          label={
                            <>
                              <RiBuilding4Line />
                              Razão Social
                            </>
                          }
                          name="bussines_name"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira o seu nome.",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Insira seu nome"
                            defaultValue={bussines_name}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col lg={12} xs={24}>
                        <FormItem
                          label={
                            <>
                              <RiContactsLine />
                              CNPJ
                            </>
                          }
                          name="document"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira o seu CNPJ.",
                            },
                          ]}
                        >
                          {/* <MaskedInput
                            className="masked-input"
                            mask="11.111.111/1111-11"
                            maxLength={14}
                            placeholder="XX.XXX.XXX/0001-XX"
                          /> */}

                          <Input
                            className="masked-input"
                            placeholder="XX.XXX.XXX/0001-XX"
                            onKeyUp={handleKeyUpCnpj}
                          />
                        </FormItem>
                      </Col>
                      <Col lg={12} xs={24}>
                        <FormItem
                          label={
                            <>
                              <RiMailLine />
                              Email
                            </>
                          }
                          name="bussines_email"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira o seu email.",
                            },
                          ]}
                        >
                          <Input
                            placeholder="exemplo@exemplo.com"
                            defaultValue={bussines_email}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <CardFooter>
                      <SecondaryButton>Voltar</SecondaryButton>
                      <PrimaryButton htmlType="submit" loading={!formEditable}>
                        Prosseguir
                      </PrimaryButton>
                    </CardFooter>
                  </Form>
                </>
              );
          }
        })()}
      </div>
    </CardForm>
  );
};

interface Step2Props {
  setUrlPayment: (purchase: string, url: string) => void;
  setPaymentSuccess: () => void;
  setStep: any;
  event: any;
  cart: any;
  afiliado: any;
}

const Step2: React.FC<Step2Props> = ({
  setUrlPayment,
  setPaymentSuccess,
  setStep,
  event,
  cart,
  afiliado,
}) => {
  const { user } = useAuth();
  const [termsModal, setTermsModal] = useState<boolean>(false);
  const [termsAcept, setTermsAcept] = useState(false);
  const [terms, setTerms] = useState("");
  const [formLoad, setFormLoad] = useState(false);
  const [formEditable, setFormEditable] = useState<boolean>(true);
  const [selectedId, setSelectedId] = useState(1);
  const [formValues, setFormValues] = useState();
  var checkoutPaymentLoad = false;

  const [guestCart, setGuestCart] = useState(false);
  let count = 0;
  interface IformTickets {
    ticket_id: string;
    ticket_name: string;
    ticket_price: number;
    ticket_description: number;
    holder_name: string;
    holder_document: string;
    holder_mail: string;
  }
  const [formTickets, setFormTickets] = useState<IformTickets[]>([]);

  interface IformTicketsInvites {
    id: string;
    holder_name: string;
    holder_document: string;
    holder_email: string;
  }
  const [formTicketsInvites, setFormTicketsInvites] = useState<
    IformTicketsInvites[]
  >([]);

  async function ChangeTickets(
    ticket_id: string,
    ticket_index: number,
    holder_type: number,
    holder_value: string
  ) {
    const newArray = formTickets;
    newArray[ticket_index] = {
      ticket_id: ticket_id,
      holder_name:
        holder_type == 1 ? holder_value : newArray[ticket_index]?.holder_name,
      holder_document:
        holder_type == 2
          ? holder_value
          : newArray[ticket_index]?.holder_document,
      holder_mail:
        holder_type == 3 ? holder_value : newArray[ticket_index]?.holder_mail,
      ticket_name: newArray[ticket_index]?.ticket_name,
      ticket_price: newArray[ticket_index]?.ticket_price,
      ticket_description: newArray[ticket_index]?.ticket_description,
    };
    setFormTickets(newArray);
  }

  async function ChangeTicketsInvites(
    ticket_id: string,
    ticket_index: number,
    holder_type: number,
    holder_value: string
  ) {
    const newArray = formTicketsInvites;
    newArray[ticket_index] = {
      id: ticket_id,
      holder_name:
        holder_type == 1 ? holder_value : newArray[ticket_index]?.holder_name,
      holder_document:
        holder_type == 2
          ? holder_value
          : newArray[ticket_index]?.holder_document,
      holder_email:
        holder_type == 3 ? holder_value : newArray[ticket_index]?.holder_email,
    };
    setFormTicketsInvites(newArray);
  }

  const checkFormSaveInvites = () => {
    const expression =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    let checkForm = true;

    if (guestCart) {
      for (let i = 0; i < formTicketsInvites.length; i++) {
        if (formTicketsInvites[i]) {
          if (
            formTicketsInvites[i].holder_name == "" ||
            formTicketsInvites[i].holder_name == undefined
          ) {
            checkForm = false;
            alert("Por favor, preencha o Nome do convidado " + (i + 1));
            break;
          } else if (
            formTicketsInvites[i].holder_document == "" ||
            formTicketsInvites[i].holder_document == undefined
          ) {
            checkForm = false;
            alert("Por favor, preencha o CPF do convidado " + (i + 1));
            break;
          } else if (
            formTicketsInvites[i].holder_email == "" ||
            formTicketsInvites[i].holder_email == undefined
          ) {
            checkForm = false;
            alert("Por favor, preencha o E-mail do convidado " + (i + 1));
            break;
          } else if (
            expression.test(
              String(formTicketsInvites[i].holder_email).toLowerCase()
            ) == false
          ) {
            checkForm = false;
            alert(
              "E-mail incorreto, por favor verifique o e-mail do convidado " +
                (i + 1)
            );
            break;
          }
        }
      }
    }
    if (checkForm) {
      checkTermsModal();
    }
  };

  const checkTermsModal = () => {
    if (event.require_terms) {
      loadEvent();
      setTermsModal(true);
    } else {
      formSave();
    }
  };

  const checkAceptTerms = () => {
    if (termsAcept) {
      formSave();
    } else {
      alert("Você precisa estar de acordo com os termos para continuar.");
    }
  };

  const formSave = async () => {
    const newArray: {
      id: string;
      holder_name: string;
      holder_email: string;
      holder_cpf: string;
      quantity: string;
    }[] = [];

    const user_name = user?.name || "";
    const user_email = user?.email || "";
    const user_document = user?.document || "";

    //setFormEditable(false);
    if (selectedId == 1) {
      formTickets.map((item: any) => {
        newArray.push({
          id: item.ticket_id,
          holder_name: user_name,
          holder_email: user_email,
          holder_cpf: user_document,
          quantity: "1",
        });
      });
    } else {
      formTickets.map((item: any) => {
        newArray.push({
          id: item.ticket_id,
          holder_name: item.holder_name,
          holder_email: item.holder_mail,
          holder_cpf: item.holder_document,
          quantity: "1",
        });
      });
    }

    const sendData = {
      items: newArray,
      invites: formTicketsInvites,
    };

    try {
      setFormEditable(false);
      setFormLoad(true);

      const eventData = event;
      const sendData = {
        items: newArray,
        invites: formTicketsInvites,
      };

      const response = await authClient.post(
        `/events/generate-url/${eventData.id}/`,
        sendData
      );

      const user_response = response.data;

      setUrlPayment(user_response.purchase_id, user_response.url);
      setFormEditable(true);
    } catch (err) {
      const response = err as ErrorEdit;

      alert(response.data.error);

      setFormEditable(true);
    }
  };

  async function loadEvent() {
    setFormLoad(true);

    try {
      const response = await authClient.get(`events/show/${event.id}`);
      const dataResponse = await response.data;

      setTerms(dataResponse.event.use_terms);
      checkIntegrationsEvent(dataResponse.event);
      setFormLoad(false);
    } catch (err) {
      setFormLoad(false);
    }
  }

  const checkIntegrationsEvent = async (event: any) => {
    if (event.integrations) {
      event.integrations.map((integration: any) => {
        /* Facebook PIX */
        if (integration.type === 2) {
          const options: any = {
            autoConfig: true,
            debug: true,
          };

          const itens: any = [];
          let total: number = 0;

          cart.map(
            (item: {
              ticket_id: string;
              name: string;
              description: string;
              price: number;
              discount: number;
              quantity: number;
            }) => {
              for (var i = 0; i < item.quantity; i++) {
                itens.push({
                  id: item.ticket_id,
                  quantity: 1,
                  item_price:
                    item.discount != 0
                      ? item.price - item.discount
                      : item.price,
                });

                total =
                  total + item.discount != 0
                    ? item.price - item.discount
                    : item.price;
              }
            }
          );

          ReactPixel.pageView();
          ReactPixel.track("InitiateCheckout", {
            value: total,
            currency: "BRL",
            contents: itens,
            content_type: "product",
          });
        }
      });
    }
  };

  async function startScreen() {
    try {
      const user_mail = localStorage.getItem("@ventX-user_email");
      var newArray = cart;
      newArray = [];

      /* loop -> formDataTickets  */
      cart.map(
        (item: {
          ticket_id: string;
          name: string;
          description: string;
          price: number;
          discount: number;
          quantity: number;
          guest: boolean;
          guest_quantity: number;
        }) => {
          if (item.guest) {
            setGuestCart(true);
          }
          for (var i = 0; i < item.quantity; i++) {
            /* push line itens in new array */
            newArray.push({
              ticket_id: item.ticket_id,
              ticket_name: item.name,
              ticket_description: item.description,
              ticket_price:
                item.discount != 0 ? item.price - item.discount : item.price,
              guest: item.guest,
              guest_quantity: item.guest_quantity,
              holder_name: "",
              holder_document: "",
              holder_mail: user_mail !== null ? user_mail : "",
            });
          }
        }
      );

      if (newArray.length > 0) {
        setFormTickets(newArray);
      }
    } catch (e) {}
  }

  useEffect(() => {
    loadEvent();
    startScreen();
  }, []);

  const newTermsReplace = terms?.replace("\n\n", "\n");

  const replaceT = newTermsReplace?.replace("\t", "\n");

  const handleKeyUpCpf = useCallback((e: FormEvent<HTMLInputElement>) => {
    cpfKeyUp(e);
  }, []);

  const handleKeyUpCnpj = useCallback((e: FormEvent<HTMLInputElement>) => {
    cnpjKeyUp(e);
  }, []);

  return (
    <CardForm>
      {termsModal ? (
        <>
          <div className="heading">
            <p className="title">Termos do evento</p>
            {replaceT?.split("\n").map((item, idx) => (
              <p className="description">{item}</p>
            ))}
          </div>
          <Divider />
          <div className="form-section">
            <label>
              <input
                type="checkbox"
                name="aceptTerms"
                checked={termsAcept}
                onChange={(event) => setTermsAcept(!termsAcept)}
              />{" "}
              Declaro que li e estou ciente do termo do evento.
            </label>
          </div>
          <CardFooter>
            <SecondaryButton onClick={() => setTermsModal(false)}>
              Voltar
            </SecondaryButton>
            <PrimaryButton loading={formLoad} onClick={() => checkAceptTerms()}>
              Prosseguir
            </PrimaryButton>
          </CardFooter>
        </>
      ) : (
        <Form
          name="ste0_0FormValue"
          onFinish={checkFormSaveInvites}
          layout="vertical"
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <div className="heading">
            <p className="title">2. Dados do titular</p>
            <p className="description">
              Informe os dados do titular ou dos titulares de cada ingresso.
            </p>
          </div>
          <Divider />
          <div className="form-section">
            <p className="title">Quem vai utilizar o ingresso?</p>
            <Radio.Group
              defaultValue={selectedId}
              onChange={(event) => setSelectedId(event.target.value)}
            >
              <Space direction="horizontal" size="large">
                <Radio value={1}>Todos os ingressos em meu nome</Radio>
                <Radio value={2}>Especificar o titular de cada ingresso</Radio>
              </Space>
            </Radio.Group>
          </div>
          {(() => {
            switch (selectedId) {
              case 2:
                return (
                  <>
                    <Divider />
                    <div className="form-section">
                      <p className="title">Informe os dados dos titulares</p>
                      <CardTicket>
                        {formTickets.map((item: any, index: number) => {
                          return (
                            <>
                              <CardTicketPanel
                                showArrow={false}
                                header={
                                  <CardPanelHeader>
                                    <div className="container-info">
                                      <RiCoupon2Line />
                                      <div>
                                        <p className="ticket-name">
                                          {index + 1} - {item.ticket_name}
                                        </p>
                                        <p className="ticket-value">
                                          Valor:{" "}
                                          {formatter.format(item?.ticket_price)}
                                        </p>
                                        {item.ticket_description ? (
                                          <p className="ticket-description">
                                            {item.ticket_description}
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                    <RiArrowDownSLine />
                                  </CardPanelHeader>
                                }
                                key={index + "_t_h"}
                              >
                                <Row gutter={20}>
                                  <Col lg={8}>
                                    <FormItem
                                      label={
                                        <>
                                          <RiUser2Line />
                                          Nome Completo
                                        </>
                                      }
                                      name={index + "_holder_name"}
                                      required
                                      rules={[
                                        {
                                          required: true,
                                          message: "Por favor, insira o nome.",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Insira o nome"
                                        onChange={(e) =>
                                          ChangeTickets(
                                            item.ticket_id,
                                            index,
                                            1,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormItem>
                                  </Col>
                                  <Col lg={8}>
                                    <FormItem
                                      label={
                                        <>
                                          <RiContactsLine />
                                          CPF
                                        </>
                                      }
                                      name={index + "_holder_cpf"}
                                      required
                                      rules={[
                                        {
                                          required: true,
                                          message: "Por favor, insira o cpf.",
                                        },
                                      ]}
                                    >
                                      {/* <MaskedInput
                                        className="masked-input"
                                        mask="111.111.111-11"
                                        maxLength={11}
                                        placeholder="000.000.000-00"
                                        onChange={(e) =>
                                          ChangeTickets(
                                            item.ticket_id,
                                            index,
                                            2,
                                            e.target.value
                                          )
                                        }
                                      /> */}

                                      <Input
                                        className="masked-input"
                                        placeholder="000.000.000-00"
                                        onChange={(e) =>
                                          ChangeTickets(
                                            item.ticket_id,
                                            index,
                                            2,
                                            e.target.value
                                          )
                                        }
                                        onKeyUp={handleKeyUpCpf}
                                      />
                                    </FormItem>
                                  </Col>
                                  <Col lg={8}>
                                    <FormItem
                                      label={
                                        <>
                                          <RiMailLine />
                                          Email
                                        </>
                                      }
                                      name={index + "_holder_email"}
                                      required
                                      rules={[
                                        {
                                          required: true,
                                          message: "Por favor, insira o email.",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Insira o email"
                                        onChange={(e) =>
                                          ChangeTickets(
                                            item.ticket_id,
                                            index,
                                            3,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormItem>
                                  </Col>
                                </Row>
                              </CardTicketPanel>
                            </>
                          );
                        })}
                      </CardTicket>
                    </div>
                  </>
                );
            }
          })()}
          {guestCart ? (
            <>
              <Divider />
              <div className="heading">
                <p className="title">3. Dados dos convidados</p>

                <p className="description">
                  Na compra de um ou mais ingressos você terá direito a
                  acompanhantes. Caso você não tenha nenhum convidado, basta não
                  preencher os campos abaixo e prosseguir com a sua compra. Não
                  será permitido levar convidados não cadastrados no evento e
                  após a compra, não será possível adicionar ou trocar
                  convidados.
                </p>
              </div>
              {formTickets.map((item: any, index: number) => {
                const ticketsLoop = [];

                if (item.guest) {
                  for (let i = 0; i < item.guest_quantity; i++) {
                    const countFix = count;
                    count = count + 1;
                    ticketsLoop.push(
                      <CardTicketPanel
                        showArrow={false}
                        header={
                          <CardPanelHeader>
                            <div className="container-info">
                              <RiCoupon2Line />
                              <div>
                                <p className="ticket-name">
                                  {"Ingresso " +
                                    (index + 1) +
                                    " - Convidado " +
                                    (i + 1)}
                                </p>
                                <p className="ticket-value">
                                  {item.ticket_name}
                                </p>
                                {item.ticket_description ? (
                                  <p className="ticket-description">
                                    {item.ticket_description}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <RiArrowDownSLine />
                          </CardPanelHeader>
                        }
                        key={count + "_t_h"}
                      >
                        <Row gutter={20}>
                          <Col lg={8}>
                            <FormItem
                              label={
                                <>
                                  <RiUser2Line />
                                  Nome Completo
                                </>
                              }
                              name={count + "_holder_name"}
                            >
                              <Input
                                placeholder="Insira o nome"
                                onChange={(e) =>
                                  ChangeTicketsInvites(
                                    item.ticket_id,
                                    countFix,
                                    1,
                                    e.target.value
                                  )
                                }
                              />
                            </FormItem>
                          </Col>
                          <Col lg={8}>
                            <FormItem
                              label={
                                <>
                                  <RiContactsLine />
                                  CPF
                                </>
                              }
                              name={count + "_holder_cpf"}
                            >
                              {/* <MaskedInput
                                className="masked-input"
                                mask="111.111.111-11"
                                maxLength={11}
                                placeholder="000.000.000-00"
                                onChange={(e) =>
                                  ChangeTicketsInvites(
                                    item.ticket_id,
                                    countFix,
                                    2,
                                    e.target.value
                                  )
                                }
                              /> */}

                              <Input
                                className="masked-input"
                                placeholder="000.000.000-00"
                                onChange={(e) =>
                                  ChangeTicketsInvites(
                                    item.ticket_id,
                                    countFix,
                                    2,
                                    e.target.value
                                  )
                                }
                                onKeyUp={handleKeyUpCpf}
                              />
                            </FormItem>
                          </Col>
                          <Col lg={8}>
                            <FormItem
                              label={
                                <>
                                  <RiMailLine />
                                  Email
                                </>
                              }
                              name={count + "_holder_email"}
                            >
                              <Input
                                placeholder="Insira o email"
                                onChange={(e) =>
                                  ChangeTicketsInvites(
                                    item.ticket_id,
                                    countFix,
                                    3,
                                    e.target.value
                                  )
                                }
                              />
                            </FormItem>
                          </Col>
                        </Row>
                      </CardTicketPanel>
                    );
                  }
                }
                return <CardTicket>{ticketsLoop}</CardTicket>;
              })}
            </>
          ) : null}
          <CardFooter>
            <SecondaryButton>Voltar</SecondaryButton>
            <PrimaryButton htmlType="submit" loading={!formEditable}>
              Prosseguir
            </PrimaryButton>
          </CardFooter>
        </Form>
      )}
    </CardForm>
  );
};

interface ModalPurchaseProps {
  handlePurchase: () => void;
  setPaymentSuccess: () => void;
  setUrlPayment: (purchase: string, url: string) => void;
  event: any;
  cart: any;
  afiliado: any;
}

const ModalPurchase: React.FC<ModalPurchaseProps> = ({
  handlePurchase,
  setUrlPayment,
  setPaymentSuccess,
  event,
  cart,
  afiliado,
}) => {
  const [step, setStep] = useState(0);

  return (
    <StyledModal
      visible={true}
      footer={null}
      onCancel={handlePurchase}
      closeIcon={<RiCloseCircleLine className="custom-close-icon" />}
    >
      <PurchaseHeader>
        <h1 className="title">Comprar Ingresso</h1>
        <StyledSteps className="steps-desktop" progressDot current={step}>
          <Step title="Dados do comprador" />
          <Step title="Dados do Titular" />
          <Step title="Pagamento" />
        </StyledSteps>
        <StyledSteps
          className="steps-mobile"
          direction="vertical"
          progressDot
          current={step}
        >
          <Step title="Dados do comprador" />
          <Step title="Dados do Titular" />
          <Step title="Pagamento" />
        </StyledSteps>
      </PurchaseHeader>
      {(() => {
        switch (step) {
          case 0:
            return <Step1 setStep={setStep} />;
          case 1:
            return (
              <Step2
                setStep={setStep}
                event={event}
                cart={cart}
                afiliado={afiliado}
                setUrlPayment={setUrlPayment}
                setPaymentSuccess={setPaymentSuccess}
              />
            );
          case 2:
            return <ModalRedirect />;
          default:
            break;
        }
      })()}
    </StyledModal>
  );
};

export default ModalPurchase;
