import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import FirstSection from "./FirstSection";
import Article from "./Article";

import hasCookiesEnabled from "utils/hasCookiesEnabled";

// UserTerms
import UserTerms from "../TermsHub/UserTerms";
import UserPrivacy from "../TermsHub/UserTerms/privacy";
import UserCookies from "../TermsHub/UserTerms/cookies";

// Organizer terms
import OrganizerTerms from "../TermsHub/OrganizerTerms/terms";
import OrganizerLegalObligations from "../TermsHub/OrganizerTerms/legal_obligations";
import OrganizerDataProcessing from "../TermsHub/OrganizerTerms/data_processing";
import OrganizerPrivacy from "../TermsHub/OrganizerTerms/privacy";
import OrganizerCookies from "../TermsHub/OrganizerTerms/cookies";
import ContactUsContainer from "components/ContactUsContainer";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";

const HelpArticle: React.FC = (props) => {
  const { type_user, id } = useParams();

  const [isVisible, setIsVisible] = useState(false);
  const [article, setArticle] = useState<any>();

  const handleCookies = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    const handleTermsType = () => {
      switch (type_user) {
        case "1":
          switch (id) {
            case "termos_usuario":
              return setArticle({
                title: "Termos de uso gerais",
                updated_at: "30/08/21",
                description: <UserTerms />,
              });
            case "privacidade_usuario":
              return setArticle({
                title: "Política de privacidade",
                updated_at: "30/08/21",
                description: <UserPrivacy />,
              });
            case "cookies_usuario":
              return setArticle({
                title: "Política de Cookies",
                updated_at: "30/08/21",
                description: <UserCookies />,
              });
          }
          break;
        case "2":
          switch (id) {
            case "termos_organizador":
              return setArticle({
                title: "Termos de uso gerais",
                updated_at: "30/08/21",
                description: <OrganizerTerms />,
              });
            case "organizador_obrigatoriedade":
              return setArticle({
                title: "Obrigatoriedades Legais",
                updated_at: "30/08/21",
                description: <OrganizerLegalObligations />,
              });
            case "organizador_processamento_de_dados":
              return setArticle({
                title: "Acordo de processamento de dados",
                updated_at: "30/08/21",
                description: <OrganizerDataProcessing />,
              });
            case "privacidade_organizador":
              return setArticle({
                title: "Política de privacidade",
                updated_at: "30/08/21",
                description: <OrganizerPrivacy />,
              });
            case "cookies_organizador":
              return setArticle({
                title: "Política de Cookies",
                updated_at: "30/08/21",
                description: <OrganizerCookies />,
              });
          }
      }
    };
    handleTermsType();
    !hasCookiesEnabled() && setIsVisible(true);
  }, [id, type_user]);

  return (
    <>
      <Header />
      <FirstSection />
      <Article article={article} />
      <ContactUsContainer />
      {isVisible && <Popup handleVisible={handleCookies} />}
      <Footer />
    </>
  );
};

export default React.memo(HelpArticle);
