import React from "react";
import { Link } from "react-router-dom";

import { RiQuestionnaireLine } from "react-icons/ri";

import { BlockContainer, ContactUsLabel, IconContainer } from "./styles";

interface Props {
  faq?: boolean;
}

const ContactUsContainer: React.FC<Props> = ({ faq }) => {
  return (
    <>
      <BlockContainer>
        {faq ? (
          <>
            <IconContainer>
              <RiQuestionnaireLine />
            </IconContainer>
            <ContactUsLabel>
              Não solucionou a sua dúvida?{" "}
              <Link to="/fale-conosco">Fale conosco</Link>
            </ContactUsLabel>
          </>
        ) : (
          <>
            <IconContainer>
              <RiQuestionnaireLine />
            </IconContainer>
            <ContactUsLabel>
              Surgiu alguma dúvida? Acesse nossa{" "}
              <Link to="/fale-conosco"> Central de ajuda</Link>
            </ContactUsLabel>
          </>
        )}
      </BlockContainer>
    </>
  );
};

export default ContactUsContainer;
