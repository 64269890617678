import styled from "styled-components";
import {
  Container as DefaultContainer,
  Section as DefaultSection,
} from "../../../EventDetail/styles";
import { Collapse as AntdCollapse } from "antd";
import { Colors } from "constants/colors";

export const Section = styled(DefaultSection)`
  display: flex;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .heading {
    color: ${Colors.white};
    .title {
      font: 600 2.2rem/3.32rem Cairo, sans-serif;
    }
    .subtitle {
      font: 400 1.2rem/2.12rem Open Sans, sans-serif;
    }
  }
`;

export const QuestionsContainer = styled.div`
  background-color: #fff;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 80px;
  border-radius: 2rem;
  box-shadow: 0px 15px 30px 0px #1219220d;
  margin-bottom: 32px;

  .breadcrumb {
    width: 100%;
    display: flex;
    margin-bottom: 3rem;
  }

  .title {
    font: 600 2.2rem/3.32rem Poppins, sans-serif;
    color: ${Colors.secondary100};
  }
  .icon-container {
    display: flex;
    width: fit-content;
    padding: 16px;
    border-radius: 20px;
    margin-right: 42px;
    background: #e9e7ee;

    svg {
      width: 25px;
      height: 25px;
      color: ${Colors.secondary100};
    }
  }
  @media screen and (max-width: 800px) {
    padding: 40px;
  }
`;
export const Container = styled(DefaultContainer)`
  margin-top: -140px;

  @media screen and (max-width: 1024px) {
    &.first-container {
      height: fit-content;
    }
  }
`;
export const ActionButton = styled.button`
  width: 150px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 14px;
  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  font: 700 18px Cairo, sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    background-color: ${Colors.primary100};
    font: 700 18px Cairo, sans-serif;
    color: #fff;
    outline: none;
  }
  svg {
    margin-right: 12px;
  }
  @media screen and (max-width: 1150px) {
    width: 100%;
  }
`;
export const FlexTitle = styled.div`
  text-align: left;
  width: 100%;
  margin-bottom: 48px;

  .title {
    font: 600 2rem/3.4rem Poppins, sans-serif;
    color: ${Colors.secondary100};
  }
`;

export const HourContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2em;

  svg,
  p {
    color: ${Colors.gray40};
    margin: 0 2px;
    margin-bottom: 1em;
  }
`;

export const Box = styled.div`
  width: 100%;

  .description {
    text-align: justify;

    color: ${Colors.gray10};
    font: 400 1.2rem/2.4rem Open Sans, sans-serif;
  }
`;

export const Collapse = styled(AntdCollapse)`
  width: 100%;
  padding: 0;
  border-radius: 10px;
  background-color: #fff;
  .ant-collapse-item {
    border: 1px solid #bdb7cd;
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: #fff;
    &:last-child {
      border-radius: inherit;
    }
    .ant-collapse-header {
      text-align: left;
      font: 400 3.2rem/5.12rem Poppins, sans-serif;
      color: ${Colors.secondary100};
      padding-top: 20px;
      padding-left: 32px !important;
      p {
        display: inline-block;
      }
    }
    .ant-collapse-content .ant-collapse-content-box {
      text-align: left;
      font: 400 1.2rem/2.4rem Open Sans, sans-serif;
      padding: 32px;
      padding-top: 12px;
    }
  }
`;
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e7ee;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 28px;
  svg {
    width: 32px;
    height: 32px;
  }
`;
export const Header = styled.div`
  display: inline-flex;
  max-width: 90%;
  align-items: center;
`;
export const ContactIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e7ee;
  border-radius: 8px;
  width: 38px;
  height: 38px;
  margin-right: 20px;
  svg {
    width: 24px;
    height: 24px;
    color: ${Colors.secondary100};
  }
`;
export const ContactUs = styled.div`
  border: 1px solid #e7e7ea;
  display: flex;
  align-items: center;
  align-self: center;
  padding: 20px 32px;
  border-radius: 8px;
  margin-top: 108px;
  p {
    font: 500 1.2rem/2.12rem Cairo, sans-serif;
    color: ${(props) => props.theme.colors.purple};
  }
  a {
    font: 500 1.2rem/2.12rem Cairo, sans-serif;
    color: ${Colors.primary100};
    text-decoration: underline;
  }
`;
