const theme = {
  text: {
    title: "700 1.6rem/2.5rem Cairo, sans-serif",
    h1_semibold_poppins: "600 5.2rem/8.32rem Poppins, sans-serif",
    h1_semibold_cairo: "600 5.2rem/8.32rem Cairo, sans-serif",
    h2: "400 4.0rem/6.4rem Poppins, sans-serif",
    h2_semibold_poppins: "600 4.0rem/6.4rem Poppins, sans-serif",
    h2_semibold_cairo: "600 4.0rem/6.4rem Cairo, sans-serif",
    h3: "400 3.2rem/5.12rem Open Sans, sans-serif",
    h3_semibold_cairo: "500 3.2rem/5.12rem Cairo, sans-serif",
    h3_bold_cairo: "700 3.2rem/5.12rem Cairo, sans-serif",
    h3_poppins: "400 3.2rem/5.12rem Poppins, sans-serif",
    h3_bold_poppins: "700 3.2rem/5.12rem Poppins, sans-serif",
    h3_bold_open: "700 3.2rem/5.12rem Open Sans, sans-serif",
    h4_poppins: "400 2.6rem/4.16rem Poppins, sans-serif",
    h4_semibold_poppins: "600 2.6rem/4.16rem Poppins, sans-serif",
    h4_bold_poppins: "700 2.6rem/4.16rem Poppins, sans-serif",
    h4: "400 2.6rem/4.16rem Poppins, sans-serif",
    h4_bold_cairo: "700 2.6rem/4.16rem Cairo, sans-serif",
    h4_semibold_cairo: "600 2.6rem/4.16rem Cairo, sans-serif",
    h4_bold: "700 2.6rem/4.16rem Poppins, sans-serif",
    h4_semibold: "500 2.6rem/4.16rem Poppins, sans-serif",
    h5_open: "400 2.4rem/160% Open Sans, sans-serif",
    h5_bold: "700 2.4rem/2.5rem Cairo, sans-serif",
    h5_semibold_poppins: "600 2.4rem/2.5rem Poppins, sans-serif",
    h5_poppins: "400 2.4rem/2.5rem Poppins, sans-serif",
    text_small_bold: "700 1.4rem Cairo, sans-serif",
    text_small_regular: "400 1.4rem Cairo, sans-serif",
    text_small_regular_open: "400 1.4rem Open Sans, sans-serif",
    text_small_regular_poppins: "400 1.4rem Poppins, sans-serif",
    text_medium_bold: "700 1.8rem Cairo, sans-serif",
    text_medium_regular: "400 1.8rem Cairo, sans-serif",
    text_semilarge_regular: "400 2rem Cairo, sans-serif",
    text_semilarge_bold: "700 2rem Cairo, sans-serif",
    text_large_semibold: "600 2rem Poppins, sans-serif",
    text_large_regular: "400 2.4rem Cairo, sans-serif",
    text_large_regular_poppins: "400 2.4rem Poppins, sans-serif",
    text_large_regular_open: "400 2.4rem/4.8rem Open Sans, sans-serif",
    text_large_bold: "700 2.8rem Cairo, sans-serif",
    text_large_600_open: "600 2rem/4rem Open Sans, sans-serif",
    text_large_bold_open: "700 2.4rem/4.8rem Open Sans, sans-serif",
    paragraph: "300 1.2rem/17px Open Sans, sans-serif",
    subtitle_bold_cairo: "700 1.2rem/17px Cairo, sans-serif",
  },
  colors: {
    pink: "#D40050",
    magenta: "#A2004C",
    wine: "#3F092F",
    purple: "#241058",
    black: "#11142D",
    white: "#FFF",
    gray1: "#D3CFDE",
    gray2: "#A79FBC",
    gray3: "#66588A",
    gray4: "#888996",
    gray5: "#636173",
    lightgray: "#eae8ef",
    gray70: "#585B6C",
    green: "#93C77B",
    red: "#E05E5E",
  },
  shadows: {
    spotlight: "0px 25px 33px rgba(108,93,211,.1)",
    button: "0px 3px 4px rgba(0,0,0,.1)",
    button_pink: "0px 15px 20px rgba(212,0,80,.15)",
  },
};
export default theme;
