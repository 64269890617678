import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`;

export const ContainerItems = styled.div`
  max-width: 800px;
  width: 100%;

  @media (max-width: 800px) {
    padding: 0px 28px 0px 28px;
  }

  h2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;

    color: ${Colors.black100};
  }
`;

export const ProfileCard = styled.div`
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Card = styled.div`
  cursor: pointer;
  width: 100%;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 44px 0px 32px;
  margin-top: 18px;
  padding: 0px 5px 0px 19px;
  border: 1px solid ${Colors.black10};
  box-shadow: 0px 2px 6px rgba(18, 25, 34, 0.05);
  border-radius: 8px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      /* line-height: 18px; */
      /* margin-top: 5px; */
      margin-left: 13px;
      margin-bottom: 0px;
      color: ${Colors.secondary40};
    }

    div.background {
      display: none;
    }
  }

  div.active {
    div.background {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: ${Colors.primary10};
      border-radius: 12px;
    }

    div.normal {
      display: none;
    }

    h2 {
      color: ${Colors.black100};
      font-weight: bold;
      font-size: 22px;
      line-height: 160%;
      margin-top: 7px;
      margin-left: 14px;
    }
  }
`;

export const Avatar = styled.div`
  width: 89px;
  height: 89px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: ${Colors.secondary10};
  border-radius: 50%;

  /* overflow-y: hidden; */

  /* img {
    border-radius: 10px;
  } */

  img {
    width: 89px;
    height: 89px;
    border-radius: 50%;
  }

  .badge {
    cursor: pointer;
    border: 0;
    outline: 0;
    position: absolute;

    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    right: 0;
    bottom: 0;

    background: ${Colors.white};

    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    font-size: 12px;

    color: ${Colors.primary100};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 34px;

  h2 {
    color: ${Colors.secondary100};
    font-weight: bold;
    font-size: 14px;
    line-height: 160%;
  }

  span {
    color: ${Colors.black50};
    font-weight: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const ButtonExit = styled.button`
  cursor: pointer;
  border: 0;
  width: 100%;
  height: 38px;
  margin-top: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${Colors.primary10};
  border-radius: 8px;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;

    color: ${Colors.primary100};
  }
`;
