import styled from "styled-components";

import { Colors } from "constants/colors";

const Container = styled.div`
  margin-top: 2.53rem;

  h1.title-header {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 800;
    font-size: 1.87rem;
    margin-bottom: 0px;

    color: ${Colors.black100} #11142d;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export function Title() {
  return (
    <Container>
      <h1 className="title-header">Meu perfil</h1>
    </Container>
  );
}
