import styled from "styled-components";
import { Button, Drawer as antdDrawer } from "antd";
import { Colors } from "constants/colors";

export const StyledHeader = styled.header`
  width: 100%;
  position: absolute;
  z-index: 101;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerItems = styled.div`
  width: 100%;
  max-width: 1366px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 84px 0px 84px !important;

  @media screen and (max-width: 1366px) {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  @media screen and (max-width: 440px) {
    margin: 0;
    padding: 20px 10px 0px 10px !important;
  }
`;

export const Logo = styled.img`
  float: left;
  width: 270px;
  height: auto;
  cursor: pointer;
  padding-left: 4px;

  @media screen and (max-width: 1024px) {
    width: 140px;
  }
`;

export const ActionButton = styled.a`
  width: auto;
  height: 60px;
  min-width: 12rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 14px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0px;
  color: ${Colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-right: 8px;

  &.vertical {
    display: flex !important;
    padding: 0 10px;
    margin-right: 0;
    svg {
      margin-right: 6px;
    }
  }

  :hover,
  :active,
  :focus {
    background-color: ${(props) => props.theme.colors.pink};
    color: #fff;
    border-color: ${(props) => props.theme.colors.magenta};
  }

  svg {
    margin-right: 12px;
  }

  @media screen and (max-width: 1024px) {
    display: none;

    &.vertical {
      display: flex !important;
    }
  }

  @media (max-width: 440px) {
    width: 151px;
    height: 32px;
    border-radius: 6px;
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    color: ${Colors.white};
    position: relative;
  }
`;

interface IMenuLinksProps {
  active: number;
}
export const MenuLinks = styled.div<IMenuLinksProps>`
  .ant-radio {
    display: none;
  }
  .ant-radio.ant-radio-checked + span > p {
    ::after {
      content: "";
      width: 25%;
      height: 4px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.white};
      position: absolute;
      bottom: 12px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  a {
    display: inline;
    cursor: pointer;
    margin-right: 70px;
    font: 700 26px Cairo, sans-serif;
    color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.15));
    padding: 16px;
    &:nth-child(${(props) => props.active}) {
      ::after {
        content: "";
        width: 25%;
        height: 2px;
        background-color: ${(props) => props.theme.colors.white};
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    :last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
export const ButtonDrawer = styled(Button)`
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.white};
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  display: none;
  box-shadow: none;
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    box-shadow: none;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;
export const Drawer = styled(antdDrawer)`
  z-index: 100;

  & .ant-drawer-header {
    padding: 0;
    padding-bottom: 47px;
  }

  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }

  .close-icon {
    font-size: 26px;
    margin: 9px 0;
    color: ${(props) => props.theme.colors.purple};
  }

  div.login {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    button.cart {
      cursor: pointer;
      border: none;
      width: 108px;
      height: 32px;
      background: ${Colors.primary10};
      border-radius: 5px;
      font-weight: bold;
      font-size: 12px;
      line-height: 22px;
      color: ${Colors.primary100};
    }

    button.account {
      cursor: pointer;
      border: none;
      width: 118px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 17px 14px;
      background: ${Colors.primary100};
      box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
      border-radius: 5px;
      font-weight: bold;
      font-size: 12px;
      line-height: 22px;
      margin-top: 24px;
      color: ${Colors.white};
    }
  }
`;
interface IVerticalLinksProps {
  active: number;
}
export const VerticalLinks = styled.div<IVerticalLinksProps>`
  .link-item {
    height: fit-content;

    &:nth-child(${(props) => props.active}) > span {
      color: ${(props) => props.theme.colors.pink};
      ::after {
        content: "";
        width: 25%;
        height: 2px;
        background-color: ${(props) => props.theme.colors.pink};
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      @media (max-width: 1024px) {
        height: auto;
      }
    }

    span {
      padding: 16px;
      cursor: pointer;
      width: fit-content;
      font: ${(props) => props.theme.text.h3_bold_cairo};
      color: ${(props) => props.theme.colors.black};
      filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.15));
      display: block;
      :last-child {
        margin-right: 0;
      }
      :focus,
      :active {
        outline: none;
      }

      @media (max-width: 1024px) {
        font-family: "Cairo";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: ${Colors.black100};
        margin-bottom: 0px;
        padding: 0px;
        background-color: red;
      }
    }
  }
`;

export const ContainerFooterButtons = styled.div`
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContainerLinks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  a {
    font-family: "Cairo";
    color: ${Colors.secondary100};
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }
`;
