import styled from "styled-components";
import { Colors } from "constants/colors";

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export const Section = styled.div`
  width: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 1366px;
`;


export const Container = styled.div`
  width: 100%;
  position: relative;
  .odd-section,
  .even-section {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .description {
      .create-event-btn {
        margin: 0;
        margin-bottom: 3em;
        display: none;
        justify-content: center;
        a {
          margin-top: 0;
          width: 100%;
        }
      }
      h2 {
        color: ${(props) => Colors.primary100};
      }
    }
  }
  .odd-section {
    margin: 0;
  }
  .odd-section .description {
    padding-right: 80px;
  }
  .even-section {
    margin-right: 0;
  }
  .even-section .description {
    padding-left: 80px;
    padding-right: 0;
  }
  @media screen and (max-width: 1024px) {
    .odd-section:nth-child(1) {
      margin-top: 2em;
      .description {
        margin-top: 3em;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .odd-section {
      flex-direction: column-reverse;
      .description {
        padding: 6rem;
        padding-top: 10rem;
      }
    }
    .even-section {
      flex-direction: column;
      .description {
        padding: 6rem;
      }
    }
    .odd-section:nth-child(1) {
      margin-top: 0;
      .description {
        margin-top: 0;
      }
    }
    .odd-section,
    .even-section {
      .description {
        .create-event-btn {
          display: flex;
          @media screen and (max-width: 350px) {
            margin: 0;
            margin-bottom: 3em;
          }
        }
      }
    }
  }
`;
