import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.footer`
  display: flex;
  width: 100%;
  min-height: 420px;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  div.content-all {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    border-top: 1px solid ${Colors.border};

    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;

  padding-left: 38px;
  padding-right: 38px;

  justify-content: space-between;
`;

export const Title = styled.h2`
  color: ${Colors.black100};
  font-weight: bold;
  font-size: 14px;
`;

export const BaseBoard = styled.div`
  width: 100%;
  height: 30px;
  display: flex;

  padding-top: 20px;

  border-top: 1px solid ${Colors.border};

  div.content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 38px;
    padding-right: 38px;

    div.footer {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        margin-left: 13px;
        margin-top: 8px;
      }
    }
  }
`;

export const AllItems = styled.div`
  display: flex;
  width: 100%;
  height: 420px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div.company {
    width: 30%;

    img {
      min-height: 120px;
      min-width: 90px;
    }

    p {
      margin-top: -20px;
      color: ${Colors.black100};
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
    }

    nav {
      h4 {
        margin-top: 39px;
        color: ${Colors.black100};
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
      }

      div.socials {
        width: 80%;
        margin-top: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          min-width: 12px;
          min-height: 12px;
        }
      }
    }
  }

  ul {
    height: 220px;
  }

  li {
    list-style: none;

    h2 {
      color: ${Colors.black100};
      font-weight: 700;
      font-size: 16px;
      line-height: 37px;
      margin-bottom: 8px;
    }

    h4 {
      color: ${Colors.black100};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 34px;
      margin-bottom: 2px;
      cursor: pointer;
      opacity: 1;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  div.download-app {
    display: flex;
    flex-direction: column;
    height: 220px;

    > div {
      margin-bottom: 5px;
    }

    h2 {
      color: ${Colors.black100};
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 22px;
    }
  }
`;

interface SocialItemProps {
  background?: string;
}

export const SocialItem = styled.p<SocialItemProps>`
  cursor: pointer;
  width: 48px;
  height: 48px;
  border: 1px solid ${Colors.border20};
  border-radius: 14px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 200ms linear;

  :hover {
    background-color: ${(props) =>
      props.background ? props.background : Colors.primary10}20;
  }
`;

export const FooterMobile = styled.footer`
  width: 100%;

  div.footer-mobile {
    width: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${Colors.border};
    border-bottom: 1px solid ${Colors.border};

    div.download-app {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 42px;

      nav {
        width: 89%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid ${Colors.border};
        margin-top: 18px;

        img.logo {
          max-width: 98px;
          max-height: 108px;
        }
      }
    }

    div.util-links {
      margin-top: 30px;
      width: 89%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 30px;
      border-bottom: 1px solid ${Colors.border};

      nav.links {
        margin-top: 14px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        h2 {
          margin-bottom: 18px;
        }

        h3 {
          font-family: "Cairo";
          font-style: normal;
          font-weight: 900;
          color: ${Colors.primary100};
          font-weight: bold;
          font-size: 14px;
          white-space: nowrap;
        }

        .last {
          &:last-child {
            text-align: right;
          }
        }
      }
    }

    nav.footer-social {
      width: 89%;
      margin-top: 29px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 30px;
      div.socials {
        margin-top: 18px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }

    img {
      max-width: 128px;
      max-height: 68px;
    }
  }

  div.support {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    border-bottom: 1px solid ${Colors.border};

    span {
      color: ${Colors.black100};
      font-weight: normal;
      font-size: 12px;

      strong {
        color: ${Colors.black100};
        font-weight: bold;
        font-size: 12px;
      }
    }
  }

  div.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 59.7px;
    padding-left: 27px;
    padding-right: 27px;

    span {
      color: ${Colors.black70};
      font-weight: normal;
      font-size: 10px;
      text-align: center;
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;
