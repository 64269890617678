import { Modal } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContainerItems = styled.div`
  max-width: 1280px;

  padding-left: 28px;
  padding-right: 28px;

  margin-top: 130px;
  width: 100%;

  @media (max-width: 800px) {
    margin-top: 38px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
`;

export const Banner = styled.div`
  width: 100%;
  min-height: 17.15rem;

  display: flex;
  flex-direction: row;

  background: ${Colors.backgroundPink};
  border-radius: 1.12rem;
  padding: 1.35rem 2.53rem 2.57rem 2.53rem;

  @media (max-width: 800px) {
    border-radius: 0px;
  }

  img.smarth {
    width: 21.04rem;
    height: 14.39rem;

    @media (max-width: 800px) {
      display: none;
    }
  }

  div.content {
    width: 100%;
    display: flex;
    flex-direction: column;

    h3 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 1.87rem;
      line-height: 160%;

      color: ${Colors.secondary100};

      @media (max-width: 800px) {
        font-size: 20px;
      }
    }

    h4 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.21rem;
      line-height: 160%;

      color: ${Colors.black90};

      @media (max-width: 800px) {
        font-size: 14px;
      }
    }

    div.buttons {
      display: flex;
      flex-direction: row;
      margin-top: 2.34rem;
      width: 100%;

      @media (max-width: 800px) {
        flex-direction: column;
        margin-top: 16px;

        button {
          width: 100%;
        }
      }

      button.first {
        border: 0;
        outline: 0;
        cursor: pointer;
        width: 14.43rem;
        height: 2.81rem;

        background: ${Colors.primary100};
        box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
        border-radius: 0.65rem;

        @media (max-width: 800px) {
          box-shadow: none;
          width: 100%;
        }

        img {
          width: 1.12rem;
          height: 1.12rem;
          margin-bottom: 4px;
        }

        span {
          font-family: "Cairo";
          font-style: normal;
          font-weight: 700;
          font-size: 0.93rem;
          color: ${Colors.white};
          margin-left: 0.46rem;
        }
      }

      button.second {
        border: 0;
        outline: 0;
        cursor: pointer;
        width: 14.43rem;
        height: 2.81rem;
        margin-left: 0.93rem;

        background: ${Colors.primary10};
        border-radius: 0.65rem;

        @media (max-width: 800px) {
          width: 100%;
          margin-left: 0px;
          margin-top: 10px;
        }

        span {
          font-family: "Cairo";
          font-style: normal;
          font-weight: 700;
          font-size: 0.93rem;
          line-height: 160%;
          text-align: center;

          color: ${Colors.primary100};
        }
      }
    }
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 94px 109px 64px 109px;

  @media (max-width: 800px) {
    padding: 0px;
  }

  div.logo-qr-code {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;


    @media(max-width: 800px){
      display: none;
    }
  }

  div.content {
    margin-top: 51px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 14px;

      text-align: center;

      color: ${Colors.black90} #292c42;

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90} #292c42;
      }

      @media(max-width: 800px){
      display: none;
    }
    }
  }

  div.or {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    @media(max-width: 800px){
      display: none;
    }

    span {
      padding: 0px 11px;
    }

    div.line {
      min-width: 160px;
      width: 100%;
      border: 1px solid ${Colors.secondary100};
      opacity: 0.2;

      @media (max-width: 800px) {
        min-width: 40%;
      }
    }
  }

  div.download-app {
    width: 100%;
    margin-top: 36px;
    align-items: center;
    justify-content: center;

    padding-left: 10px;
    padding-right: 10px;

    @media(max-width: 800px){
      margin-top: 10px;
    }

    h5 {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      text-align: center;
      color: ${Colors.black90};
    }

    div.row {
      width: 100%;
      display: flex;
      align-items: center;

      justify-content: center;

      @media (max-width: 800px) {
        flex-direction: column;
      }

      img {
        cursor: pointer;
        min-width: 189px;
        min-height: 128px;

        @media (max-width: 800px) {
          width: 100%;
          margin-bottom: 0px;
        }
      }
    }
  }
`;
