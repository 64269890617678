export function transformerDateToBr(value: string) {
  let birthdate = "";

  if (value) {
    const split = value.split("-");

    birthdate = `${split[2]}/${split[1]}/${split[0]}`;

    if (split[1] === undefined) {
      birthdate = value;
    }
  }

  return birthdate;
}

export function transformerDateToSend(value: string) {
  let birthdate = value;

  if (value) {
    const split = value.split("/");

    birthdate = `${split[2]}-${split[1]}-${split[0]}`;
  }

  return birthdate;
}
