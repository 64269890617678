import { Link } from "react-router-dom";
import { RiCloseCircleLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import { Categories as CategoryInteface } from "types";

import {
  ContainerModal,
  ContainerSelect,
  ModalStyled,
  NormalCardBorder,
} from "./styles";

interface ModalCategoryProps {
  data: CategoryInteface | undefined;
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
}

export function ModalCategory({
  data,
  isModalVisible,
  handleOk,
  handleCancel,
}: ModalCategoryProps) {
  return (
    <ModalStyled
      width={857}
      visible={isModalVisible}
      onOk={handleOk}
      style={{ borderRadius: "20px" }}
      onCancel={handleCancel}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Categorias</h1>

        <h2>Selecione uma categoria.</h2>

        <ContainerSelect>
          {data?.data?.map((category) => (
            <Link
              key={category.id}
              onClick={handleOk}
              to={`/category/${category.id}`}
            >
              <NormalCardBorder>
                <div className="circle-icon">
                  <img
                    className="svg-icon"
                    width={13}
                    height={13}
                    src={category.icon}
                    alt=""
                  />
                </div>

                <h3 className="normal">{category.name}</h3>
              </NormalCardBorder>
            </Link>
          ))}
        </ContainerSelect>
      </ContainerModal>
    </ModalStyled>
  );
}
