export const returnStatusPurchase = (value: string) => {
  switch (value) {
    case "PAID":
      return "Pago";
    case "PENDING ":
      return "Pendente";
    case "REFUND":
      return "Estornado";

    default:
      return "Pendente";
  }
};

export const returnStatusColorPurchase = (value: string) => {
  switch (value) {
    case "PAID":
      return "green";
    case "PENDING ":
      return "yellow";
    case "REFUND":
      return "gray";

    default:
      return "yellow";
  }
};

export const returnTypePaymentPurchase = (value: string) => {
  switch (value) {
    case "credit-card":
      return "Cartão de Crédito";

    case "boleto":
      return "Boleto";

    default:
      return "Boleto";
  }
};

export const purchaseSubtitleFactor = (purchase: any) => {
  let info = 'Compra ';

  info += returnStatusPurchase(purchase.purchase_status)

  if(purchase.purchase_code){
    info += ' - CÓDIGO: ' + purchase.purchase_code
  }

  return info;
};