import { Container } from "./styles";

import survey_search from "assets/survey-search.svg";

interface EmptyTicketComponentProps {
  title?: string;
  subtitle?: string;
}

export function EmptyTicketComponent({
  title,
  subtitle,
}: EmptyTicketComponentProps) {
  return (
    <Container>
      <h1 className="title-empty">{title}</h1>

      <p className="subtitle-empty">{subtitle}</p>

      <img src={survey_search} alt="survey_search" />
    </Container>
  );
}
