export function paramsToString(
  name?: string,
  categories?: number,
  data_type?: number,
  price?: number,
  address_city?: string,
  address_state?: string
) {
  let params = {};

  if (address_city) {
    params = { ...params, address_city };
  }

  if (address_state) {
    params = { ...params, address_state };
  }

  if (name) {
    params = { ...params, name };
  }

  if (categories) {
    params = { ...params, categories };
  }

  if (data_type) {
    switch (data_type) {
      case 1:
        params = { ...params, date_type: 1 };
        break;

      case 2:
        params = { ...params, date_type: 2 };
        break;

      case 3:
        params = { ...params, date_type: 3 };
        break;

      default:
        params = { ...params };
    }
  }

  if (price) {
    switch (price) {
      case 1:
        params = { ...params, gte_price: 0, lte_price: 50 };
        break;

      case 2:
        params = { ...params, gte_price: 0, lte_price: 100 };
        break;

      case 3:
        params = { ...params, gte_price: 0, lte_price: 500 };
        break;

      default:
        params = { ...params };
    }
  }

  return params;
}

export const dataPrice = [
  { option: "Preço", value: 0 },
  { option: "até R$ 50", value: 1 },
  { option: "até R$ 100", value: 2 },
  { option: "até R$ 500", value: 3 },
  { option: "todos", value: 4 },
];

export const dataOptions = [
  { option: "Data", value: 0 },
  { option: "Hoje", value: 1 },
  { option: "Esta semana", value: 2 },
  { option: "este mês", value: 3 },
  { option: "todos", value: 4 },
];
