import styled from "styled-components";
import { Colors } from "constants/colors";

import {
  Container as DefaultContainer,
  Section as DefaultSection,
} from "../styles";

import AppStore from "assets/selo_app_store.svg";
import GooglePlay from "assets/selo_google_play.svg";

export const Container = styled(DefaultContainer)`
  width: 80%;
  margin: 0 auto;
  max-width: 1366px;

  @media screen and (max-width: 1024px) {
    &.first-container {
      height: fit-content;
    }
  }
  @media screen and (max-width: 475px) {
    width: 90%;
  }
`;

export const Section = styled(DefaultSection)`
  display: flex;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0 8%;
  }
  .heading {
    color: ${Colors.white};
    .title {
      font: 600 5.2rem/8.32rem Cairo, "Cairo", sans-serif;
    }
    .subtitle {
      font: 600 5.2rem/8.32rem Cairo, "Cairo", sans-serif;
    }
  }

  @media screen and (max-width: 1715px) {
    .content {
      padding: 0 7%;
    }
  }

  @media screen and (max-width: 1650px) {
    .content {
      padding: 0 5%;
    }
  }

  @media screen and (max-width: 1540px) {
    .content {
      padding: 0 2%;
    }
  }

  @media screen and (max-width: 1450px) {
    .content {
      padding: 0 0%;
    }
  }
`;

export const Box = styled.div`
  background-color: #fff;

  width: 100%;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  padding: 2rem;

  border-radius: 1rem;
  border: 1px solid #e7e7ea;

  &.has-shadow {
    box-shadow: 0px 15px 30px 0px #1219220d;
  }

  &.organizer-desktop {
    display: flex;
  }

  &.organizer-mobile {
    display: none;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 15px;

    &.organizer-desktop {
      display: none;
    }

    &.organizer-mobile {
      display: flex;
    }
  }
`;

export const TicketContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .icon-container {
    display: flex;

    svg {
      margin-top: 8px;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      color: ${Colors.gray10};
    }

    .name-and-price {
      text-align: start;

      p {
        font: 400 2.4rem Poppins, "Poppins", sans-serif;
        margin: 5px 0;
      }

      p:first-child {
        font-weight: 600;
        font-size: 16px;

        color: ${Colors.secondary100};
      }

      p:last-child {
        font-weight: 600;
        font-size: 14px;

        color: ${Colors.black};
      }
    }
    .description {
      p {
        width: 100%;
        text-align: center;
        padding: 10px;
        color: #66588a;
        font-size: 16px;
        background-color: #e9e7ee;
        border-radius: 8px;
      }
    }
  }

  @media screen and (max-width: 580px) {
    flex-direction: column;

    .icon-container {
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;
      svg {
        margin: 0;
      }
      .name-and-price {
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 475px) {
    .name-and-price {
      p:first-child {
        font-size: 20px;

        color: ${Colors.tertiary100};
      }

      p:last-child {
        font-size: 16px;

        color: ${Colors.black};
      }
    }
  }
`;

export const BoxContainer = styled.div`
  background-color: #fff;

  width: 100%;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  padding: 0 2rem 2rem 2rem;

  border-radius: 1rem;
  box-shadow: 0px 8px 20px 0px #1219220d;

  #description {
    color: #585b6c;
    text-align: initial;
  }

  @media screen and (max-width: 475px) {
    margin-bottom: 12px;
    /*
    :last-child {
      margin-bottom: 32px;
    } */
    /* box-shadow: none; */
  }
`;

export const AllDetailsBox = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;

  .info-container {
    display: flex;
    flex-direction: column;
    align-self: center;

    margin-right: 10px;
    text-align: left;

    p:first-child {
      font-weight: 600;
      font-size: 1rem;
      color: ${Colors.primary100};
      margin-bottom: 0;
    }

    p:last-child {
      font-weight: normal;
      font-size: 0.8rem;
      margin-bottom: 0;
      color: ${Colors.gray50};
      font-weight: 400;

      color: ${Colors.black70};
    }
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const IconContainer = styled.div`
  width: 3rem;
  height: 3rem;
  margin-right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #fbe5ed;
  border-radius: 1rem;
  flex-shrink: 0;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: #d40050;
  }
`;

export const Title = styled.div`
  text-align: left;
  width: 100%;
  margin-bottom: 1rem;
  font: 600 18px Poppins, "Poppins", sans-serif;
  color: ${Colors.secondary100};

  padding-top: 2em;

  &.desktop {
    display: flex;
  }

  &.mobile {
    display: none;
  }

  @media screen and (max-width: 600px) {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: flex;
    }
  }
`;

export const Subtitle = styled.div`
  text-align: left;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font: 600 14px Poppins, "Poppins", sans-serif;
  color: ${Colors.secondary100};
`;

export const Divider = styled.div`
  height: 1px;
  background: #e9e7ee;
  width: 100%;
  margin: 15px 0;
`;

export const ActionButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-input {
    width: 4rem;
    height: 2.4rem;
    padding: 4px 8px;
    border: none;
    background-color: #f6f6f6;
    border-radius: 12px;
    font: 600 1.2rem/1.6rem Cairo, "Cairo", sans-serif;
    &:focus {
      box-shadow: none;
    }
  }
  button {
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
    border-radius: 12px;
    background-color: ${Colors.primary100};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    margin-left: 8px;
    :hover,
    :active,
    :focus {
      background-color: ${Colors.primary100};
      color: #fff;
      outline: none;
    }
    &.add {
      background-color: ${Colors.green10};
    }
    &.remove {
      background-color: ${Colors.red10};
    }
  }
`;

export const Header = styled.div`
  display: inline-flex;
  max-width: 90%;
  align-items: center;
`;
export const ContactIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e7ee;
  border-radius: 8px;
  width: 38px;
  height: 38px;
  margin-right: 20px;
  svg {
    width: 24px;
    height: 24px;
    color: ${Colors.tertiary100};
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
export const ContactUs = styled.div`
  border: 1px solid #e7e7ea;
  display: flex;
  align-items: center;
  align-self: center;
  padding: 20px 32px;
  border-radius: 8px;
  margin-top: 108px;
  p {
    font: 500 3.2rem/5.12rem Cairo, sans-serif;
    color: ${Colors.tertiary100};
  }
  a {
    font: 500 3.2rem/5.12rem Cairo, sans-serif;
    color: ${Colors.tertiary100};
    text-decoration: underline;
  }
  @media screen and (max-width: 600px) {
    margin-top: 64px;
  }
`;

export const ParentalRatingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  text-align: left;
  font: 600 0.8rem Open Sans, "Open Sans", sans-serif;
  line-height: 1rem;
  color: #585b6c;
  border-radius: 1rem;
  border: 1px solid #e7e7ea;
`;

interface IParentalRatingProps {
  rating: any;
}

export const ParentalRatingIcon = styled.div<IParentalRatingProps>`
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  flex-shrink: 0;
  align-items: center;
  background: ${(props) => props.rating?.color};
  background: linear-gradient(
    0deg,
    ${(props) => props.rating?.color} 00%,
    rgba(255, 255, 255, 1) 200%
  );
  p {
    font: normal 22px Poppins, "Poppins", sans-serif;
    color: ${Colors.white};
    padding-top: 1.2rem;
  }
`;
export const LocationContainer = styled.div`
  margin-top: 20px;
  text-align: left;
  .location-title {
    font: 600 1rem Poppins, "Poppins", sans-serif;
    color: ${Colors.secondary100};
    margin-bottom: 0px;
  }

  .location-details {
    font: 400 0.8rem Open Sans, "Open Sans", sans-serif;
    color: #585b6c;
    text-align: left;
    margin-top: 0.7rem;
  }
  svg {
    color: ${Colors.primary100};
    margin-right: 0.8rem;
    font-size: 1.6em;
  }
`;
export const OrganizerHeader = styled.div`
  display: flex;
  .organizer-photo {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #fff;
    box-shadow: 0px 5px 10px 0px #0000001f;
    margin: 4px 0;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    width: 100%;
    .organizer-photo {
      width: 80px;
      height: 80px;
      margin: 12px 0;
    }
  }
`;

export const MobileContact = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MobileContactButton = styled.a`
  height: 32px;
  width: 47%;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  background: #fbe5ed;
  border-radius: 6px;
  color: #d40050;

  padding: 0 5px;

  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;

  svg {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }
`;

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 4px;
  .organizer-contact {
    color: ${Colors.primary100};
    text-decoration: underline;
  }
`;

export const ContactItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.6rem;
  width: 1.6rem;
  background-color: #fbe5ed;
  border-radius: 5px;
  margin-right: 8px;
  svg {
    color: ${Colors.primary100};
  }
`;
export const OrganizerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4px;
  margin-top: 4px;
  .contact-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .vertical-divider {
    margin-bottom: 0;
  }
  .organizer-name {
    margin-left: 4px;
    margin-bottom: 6px;
    font: 600 1.2rem Poppins, "Poppins", sans-serif;
    color: ${Colors.secondary100};
  }
  @media screen and (max-width: 768px) {
    .contact-container {
      flex-direction: column;
      align-items: flex-start;
      .vertical-divider {
        display: none;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .organizer-name {
      margin: 0;
    }

    .contact-container {
      flex-direction: column;
      align-items: flex-start;
      .vertical-divider {
        display: none;
      }
    }
  }
`;
export const DownloadSection = styled.div`
  position: relative;
  text-align: center;
  .download {
    font: "400 2.4rem/4.8rem Open Sans, sans-serif";
    color: ${Colors.black};
    filter: drop-shadow(0px 4px 10px hsla(0, 0%, 0%, 0.25));
    padding-left: 12px;
  }
  .container-download {
    display: flex;
    padding-left: 12px;
    justify-content: space-around;
    padding: 12px 24px;
  }
  .download-button {
    width: 26rem;
    height: 9rem;
    background-color: #fff;
  }
  .download-button.app-store {
    background: url(${AppStore});
    background-repeat: no-repeat;
    background-size: contain;
  }
  .download-button.google-play {
    background: url(${GooglePlay});
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media screen and (max-width: 450px) {
    .download {
      padding-left: 0;
    }
    .download-button {
      margin: 12px;
    }
    .container-download {
      align-items: center;
      padding: 0;
      flex-direction: column;
    }
  }
`;
export const ModalTitle = styled.p`
  color: ${Colors.tertiary100};
  font: "700 3.2rem/5.12rem Cairo, sans-serif";
  text-align: center;
  margin-top: 32px;
`;
export const ModalDescription = styled.p`
  color: ${Colors.black};
  font: "400 2.4rem/4.8rem Open Sans, sans-serif";
  text-align: center;
`;
export const OrDivider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(17, 20, 45, 0.5);
  div {
    background-color: rgba(36, 16, 88, 0.2);
  }
`;

export const Description = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 12px;

  padding: 5px 10px 5px 10px;

  width: 100%;

  color: #66588a;
  font-size: 12;
  background-color: #f4f3f7;
  border-radius: 8;

  p {
    margin-bottom: 0px;
    margin-left: 4px;
  }
`;
