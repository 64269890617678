import styled from "styled-components";
import { Colors } from "constants/colors";

export const Wrapper = styled.div`
  width: 90vw;
  height: 130px;
  background: #fff;
  border-radius: 20px;
  margin: 0 auto;
  position: fixed;
  bottom: 12px;
  left: 0;
  right: 0;
  padding: 36px 64px;
  display: flex;
  box-shadow: 0px 20px 40px 0px #0000001a;
  z-index: 999;
  @media screen and (max-width: 1150px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 28px 35px;
  }
  @media screen and (max-width: 850px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 28px 35px;
    width: 100vw;
    bottom: 0;
    border-radius: 0;
  }
`;
export const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${Colors.gray60};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  svg {
    color: ${Colors.secondary100};
    font-size: 28px;
  }
  @media screen and (max-width: 1150px) {
    display: none;
  }
`;
export const TextContainer = styled.div`
  margin: 0 28px;

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;
    line-height: 160%;

    color: ${Colors.black80};
  }

  a {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 0.93rem;

    color: ${Colors.primary100};
    text-decoration: underline;
  }

  @media screen and (max-width: 1150px) {
    margin: 0;
    margin-bottom: 32px;
  }
`;
export const ActionButton = styled.button`
  width: 150px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 14px;
  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  font: 700 18px Cairo, sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    background-color: ${Colors.primary100};
    font: 700 18px Cairo, sans-serif;
    color: #fff;
    outline: none;
  }
  svg {
    margin-right: 12px;
  }
  @media screen and (max-width: 1150px) {
    width: 100%;
  }
`;
export const Flex = styled.div`
  display: flex;
`;

export const ResponsiveHeader = styled.div`
  display: none;
  width: 100%;
  @media screen and (max-width: 1150px) {
    display: block;
  }
  p {
    color: ${Colors.primary100};
    font: 700 1.4rem Cairo, sans-serif;
    margin-bottom: 0px;
  }
`;
export const ResponsiveIconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fbe5ed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 4px;
  margin-right: 12px;
  svg {
    color: ${Colors.primary100};
    width: 100%;
    height: 100%;
  }
`;
