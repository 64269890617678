import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import { Container, ContainerItems } from "styles/pages/Home";

import { BannerOrganizerYourEvent } from "./BannerOrganizerYourEvent";
import { Category } from "./Category";
import { DiscountedEvents } from "./DiscountedEvent";
import { FeaturedEvents } from "./FeaturedEvent";
import { ManageEvents } from "./ManageEvent";
import { SliderComponent } from "./Slider";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";

import banner2 from "assets/banner2.jpg";

export default function Home() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const metaTags = {
    title: process.env.REACT_APP_EVENTX_META_TITLE,
    description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  };

  return (
    <>
      <Header
        cart={[]}
        changeCart={null}
        quantityTotal={0}
        valueTotal={0}
        openLogin={null}
        openSteps={null}
        rate={0}
        metaTags={metaTags}
      />
      <SliderComponent />
      <Category />
      <BannerOrganizerYourEvent />
      <FeaturedEvents />
      <DiscountedEvents />

      <Container>
        <ContainerItems>
          <Link to="/organize-seu-evento">
            <img
              className="banner-publish"
              src={banner2}
              alt=""
              width="100%"
              height="17.15rem"
            />
          </Link>
        </ContainerItems>
      </Container>

      <ManageEvents />

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
