import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import { Colors } from "constants/colors";

import {
  Container,
  ContainerItems,
  ButtonPrev,
  ButtonNext,
  Numbers,
} from "./styles";

import { PaginationItem } from "./PaginationItem";

interface PaginationProps {
  totalCountOfRegisters: number | undefined;
  registersPerPage?: number;
  currentPage: number;
  onPageChange: (value: number) => void;
  last_page?: number;
}

export function Pagination({
  currentPage,
  totalCountOfRegisters,
  onPageChange,
  registersPerPage = 20,
  last_page = 1,
}: PaginationProps) {
  // const [searchParams, setSearchParams] = useSearchParams();

  // useEffect(() => {
  //   setSearchParams({
  //     page: String(currentPage),
  //     ...Object.fromEntries([...searchParams]),
  //   });

  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, [currentPage, searchParams, setSearchParams]);

  return (
    <Container>
      <ContainerItems>
        {currentPage < last_page ? (
          <div className="pages">
            <h2>
              Mostrando {registersPerPage * currentPage} de{" "}
              {totalCountOfRegisters} eventos
            </h2>
          </div>
        ) : null}

        {currentPage === last_page ? (
          <div className="pages">
            <h2>
              Mostrando {totalCountOfRegisters} de {totalCountOfRegisters}{" "}
              eventos
            </h2>
          </div>
        ) : null}
        <div className="buttons">
          <ButtonPrev
            onClick={() => onPageChange(currentPage - 1)}
            active={currentPage > 1}
            disabled={currentPage === 1}
          >
            <BiChevronLeft
              size={24}
              color={currentPage > 1 ? Colors.primary100 : Colors.black30}
            />
            <span>Anterior</span>
          </ButtonPrev>

          <Numbers>
            {Array.from(Array(last_page).keys()).map((number) => (
              <PaginationItem
                key={number + 1}
                number={number + 1}
                onPageChange={onPageChange}
                isCurrent={currentPage === number + 1}
              />
            ))}
          </Numbers>

          <ButtonNext
            onClick={() => onPageChange(currentPage + 1)}
            active={currentPage < last_page}
            disabled={currentPage === last_page}
          >
            <span>Próximo</span>
            <BiChevronRight
              size={24}
              color={
                currentPage < last_page ? Colors.primary100 : Colors.black30
              }
            />
          </ButtonNext>
        </div>
      </ContainerItems>
    </Container>
  );
}
