import { Number } from "./styles";

interface PaginationItemProps {
  number: number;
  isCurrent?: boolean;
  onPageChange: (pageNumber: number) => void;
}

export function PaginationItem({
  isCurrent,
  number,
  onPageChange,
}: PaginationItemProps) {
  if (isCurrent) {
    return (
      <Number active key={number}>
        <h2 className="number-pagination">{number}</h2>
      </Number>
    );
  }

  return (
    <Number key={number} onClick={() => onPageChange(number)}>
      <h2 className="number-pagination">{number}</h2>
    </Number>
  );
}
